/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Message, theme } from "@nordcloud/gnui";
import { ValidationError } from "~/generated/graphql";

type Props = {
  errs?: ValidationError[];
};

export function ValidationErrors({ errs }: Props) {
  return (
    <>
      {errs
        ?.filter(({ field }) => field === null || field === "")
        .map(({ error }) => (
          <Message
            key={error}
            status="danger"
            background={theme.color.background.ui01}
            color={theme.color.text.error}
            image="danger"
          >
            {error}
          </Message>
        ))}
    </>
  );
}
