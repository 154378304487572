/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import ContentLoader from "react-content-loader";
import { theme } from "@nordcloud/gnui";

type Row = {
  count: number;
  height: number;
  items: {
    widthPercent: number;
    rx?: number;
    ry?: number;
  }[];
};

type Props = {
  width: number;
  height: number;
  gapY: number;
  gapX: number;
  rows: Row[];
};

export function TableGeneratorLoader({
  rows,
  width,
  height,
  gapY,
  gapX,
  ...rest
}: Props & React.ComponentPropsWithoutRef<typeof ContentLoader>) {
  const totalRows = rows.flatMap((row) => {
    return Array.from({ length: row.count }).map(() => row);
  });

  const getRowY = (currentIndex: number) => {
    return totalRows.slice(0, currentIndex).reduce((acc, curr) => {
      return acc + curr.height + gapY;
    }, 0);
  };

  return (
    <ContentLoader
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      speed={2}
      backgroundColor={theme.color.background.ui02}
      foregroundColor={theme.color.background.ui04}
      {...rest}
    >
      {totalRows.map((row, index) => {
        const y = getRowY(index);

        const getItemX = (currentIndex: number) => {
          return row.items.slice(0, currentIndex).reduce((acc, curr) => {
            return acc + width * (curr.widthPercent / 100) + gapX;
          }, 0);
        };

        return row.items.map((item, itemIndex) => {
          const totalWidth = width * (item.widthPercent / 100);
          // Skip adding gapX when there's a single item with 100% width
          const rectWidth =
            totalWidth === width ? totalWidth : totalWidth - gapX * 2;
          const itemX = getItemX(itemIndex);

          return (
            <rect
              key={`key-${itemIndex + 1}`}
              x={itemX}
              y={y}
              rx={item.rx ?? DEFAULT_RADIUS}
              ry={item.ry ?? DEFAULT_RADIUS}
              width={rectWidth}
              height={row.height}
            />
          );
        });
      })}
    </ContentLoader>
  );
}

const DEFAULT_RADIUS = 3;
