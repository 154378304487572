/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Controller, useFormContext } from "react-hook-form";
import { Label, Textarea } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { SHORT_DESCRIPTION_LIMIT } from "~/views/workflows/AddWorkflow/constants";
import { WorkflowFormData } from "~/views/workflows/types";
import { parseFieldErrors } from "./utils";

type Props = {
  isReadMode: boolean;
};

export function ShortDescriptionField({ isReadMode }: Props) {
  const {
    formState: { errors: fieldErrors },
    control,
  } = useFormContext<WorkflowFormData>();

  const { shortDescriptionError } = parseFieldErrors(fieldErrors);

  return (
    <FormGroup error={shortDescriptionError}>
      <Label
        name="Short Description"
        htmlFor="serviceNowShortDescription"
        required={!isReadMode}
      />
      <Controller
        control={control}
        name="serviceNow.shortDescription"
        render={({ field: { name, onBlur, onChange, value } }) => (
          <Textarea
            disabled={isReadMode}
            id="serviceNowShortDescription"
            maxCharCount={SHORT_DESCRIPTION_LIMIT}
            value={value}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
          />
        )}
      />
    </FormGroup>
  );
}
