/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { FlexContainer, Text, theme } from "@nordcloud/gnui";

export const OptionWrapper = styled(FlexContainer)`
  flex-direction: column;
  align-items: start;
  padding: ${theme.spacing.spacing02} ${theme.spacing.spacing03};
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &:not(:last-of-type) {
    border-bottom: solid 1px ${theme.color.border.border01};
  }
`;

export const SmallerLineHeightText = styled(Text)`
  line-height: 1.5em !important;
`;

export const TableWrapper = styled.div`
  width: 100%;
  overflow: auto;
`;
