/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Link } from "react-router-dom";
import {
  DetailsDescription,
  DetailsItem,
  DetailsTitle,
} from "~/components/Details/styles";
import { isNotNil } from "~/tools";
import { LinkedResource } from "~/views/estate/EstateDetailsPage/components/ReallocatedCostsSidebar";
import {
  getCloudResource,
  isPVCInstance,
  isStorageInstance,
} from "~/views/estate/EstateDetailsPage/utils";
import { InlineLink } from "./components";

type PVProp = {
  nordcloudId: string;
  cloudAccountId?: string;
  onChange: () => void;
  linkedResources: LinkedResource[];
};

export function PersistentVolumeDetails({
  linkedResources,
  nordcloudId,
  cloudAccountId,
  onChange,
}: PVProp) {
  const pvc = linkedResources
    ?.map(({ targetRecord }) => getCloudResource(targetRecord))
    .filter(isNotNil)
    .find(({ providerType }) => isPVCInstance(providerType ?? ""));

  const storage = linkedResources
    ?.map(({ sourceRecord }) => getCloudResource(sourceRecord))
    .filter(isNotNil)
    .find(({ providerType }) => isStorageInstance(providerType ?? ""));

  return (
    <>
      <DetailsItem>
        <DetailsTitle css={{ wordBreak: "normal" }}>
          Persistent Volume Claim
        </DetailsTitle>
        <DetailsDescription>
          <When condition={!!pvc}>
            <Link data-testid={`link-${pvc?.id}`} to={`/estate/${pvc?.id}`}>
              {pvc?.name}
            </Link>
          </When>
        </DetailsDescription>
      </DetailsItem>
      <DetailsItem>
        <DetailsTitle>Storage Resource</DetailsTitle>
        <DetailsDescription>
          <InlineLink
            nordcloudId={nordcloudId}
            onChange={onChange}
            cloudAccountId={cloudAccountId}
            cloudResource={storage}
            providerTypes={[
              "microsoft.compute/disks", // Azure
              "compute.googleapis.com/disks", // GCP
              "ec2/volume", // AWS
              "is/volume", // IBMCLOUD
            ]}
          />
        </DetailsDescription>
      </DetailsItem>
    </>
  );
}
