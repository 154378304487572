/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  FlexContainer,
  Input,
  Label,
  Message,
  Spacer,
  Text,
  theme,
} from "@nordcloud/gnui";
import { ExternalLink, FormGroup } from "~/components";
import { ContactsSelector } from "~/components/Inputs/ContactsSelector/ContactsSelector";
import { ERROR_TEXT, SUCCESS_TEXT } from "~/constants";
import { showError, showSuccess } from "~/services/toast";
import { isNotNil } from "~/tools";
import { StepContainer } from "~/views/cloudAccounts/components/StepContainer";
import {
  CLOUD_FORMATION_TEMPLATE_URL,
  CLOUD_FORMATION_URL,
  MORE_INFO_EXTERNAL_IDS_URL,
} from "~/views/cloudAccounts/constants";
import {
  AwsFormFields,
  Provider,
  ProviderTab,
} from "~/views/cloudAccounts/types";
import { AdditionalCloudAccountSettings } from "../AdditionalCloudAccountSettings";
import { AwsFormData } from "../types";
import { useAddAccount } from "../useAddAccount";
import { addAwsSchema } from "../validators";
import { AccountInfoTabs } from "./AccountInfoTabs";

type Props = {
  closeSidebar: () => void;
};

export function AwsSidebarContent({ closeSidebar }: Props) {
  const [accountIds, setAccountIds] = useState([{ id: uuidv4(), value: "" }]);

  const formMethods = useForm<AwsFormData>({
    resolver: yupResolver(addAwsSchema),
  });
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const { addAccount, loading } = useAddAccount(ProviderTab.AWS);

  useEffect(() => {
    const newValue = accountIds.map(({ value }) => value);
    setValue(AwsFormFields.ACCOUNT_IDS, newValue);
  }, [accountIds, setValue]);

  const onAddAccountSubmit = (formData: AwsFormData) => {
    if (loading) {
      return;
    }

    Promise.all(
      formData.accountIds.map((id) => {
        const input = {
          name: id,
          provider: Provider.Aws,
          providerId: id,
          ownerId: formData.accountOwner,
          displayName: formData.displayName,
          description: formData.description,
          contactIds: formData.contactPerson
            ? [formData.contactPerson]
            : undefined,
          creds: JSON.stringify({
            roles: [
              { arn: formData.roleName, externalId: formData.roleExternalID },
            ],
          }),
        };

        return addAccount({ variables: { input } });
      })
    )
      .then((results) => {
        if (
          results.every((result) => isNotNil(result.data?.addAccountV3?.nid))
        ) {
          showSuccess(SUCCESS_TEXT.accountAdded);
          closeSidebar();
        }
      })
      .catch(() => {
        showError(ERROR_TEXT.failedAddingAccount);
      });
  };

  return (
    <FormProvider {...formMethods}>
      <form id="add-AWS-form" onSubmit={handleSubmit(onAddAccountSubmit)}>
        <FlexContainer
          direction="column"
          alignItems="start"
          rowGap={theme.spacing.spacing04}
        >
          <AccountInfoTabs
            accountIds={accountIds}
            setAccountIds={setAccountIds}
          />
          <FormGroup
            customCss={{ marginBottom: 0 }}
            error={errors[AwsFormFields.ACCOUNT_OWNER] ?? []}
          >
            <Label
              required
              htmlFor={AwsFormFields.ACCOUNT_OWNER}
              name="Account Owner"
            />
            <ContactsSelector
              onChange={(selectedContact) => {
                setValue(AwsFormFields.ACCOUNT_OWNER, selectedContact);
              }}
            />
          </FormGroup>
          <AdditionalCloudAccountSettings />
          <AccountCredentialsInfo />
          <FormGroup
            error={errors[AwsFormFields.ROLE_EXTERNAL_ID]}
            customCss={{ marginBottom: 0 }}
          >
            <Label
              required
              htmlFor={AwsFormFields.ROLE_EXTERNAL_ID}
              name="Provide Role External ID"
            />
            <Input
              placeholder="e.g. 123456789012"
              {...register(AwsFormFields.ROLE_EXTERNAL_ID)}
            />
            <Spacer height={theme.spacing.spacing01} />
            <ExternalLink href={MORE_INFO_EXTERNAL_IDS_URL}>
              <Text size="sm" color={theme.color.text.info}>
                More information about External IDs
              </Text>
            </ExternalLink>
          </FormGroup>
          <FormGroup error={errors[AwsFormFields.ROLE_NAME]}>
            <StepContainer counter={3}>
              In the AWS Management Console, go to IAM and find the role by name
            </StepContainer>
            <Spacer height={theme.spacing.spacing02} />
            <Label
              required
              htmlFor={AwsFormFields.ROLE_NAME}
              name="Role Name"
            />
            <Input
              placeholder="e.g. arn:aws:iam::123456789012:role/NordcloudKlarityScannerServiceRole"
              {...register(AwsFormFields.ROLE_NAME)}
            />
          </FormGroup>
          <FlexContainer columnGap={theme.spacing.spacing03}>
            <Button
              icon="checkmark"
              initialState={loading ? "loading" : ""}
              css={{ border: 0 }}
              type="submit"
              form="add-AWS-form"
            >
              Apply
            </Button>
            <Button severity="low" type="button" onClick={closeSidebar}>
              Cancel
            </Button>
          </FlexContainer>
        </FlexContainer>
      </form>
    </FormProvider>
  );
}

function AccountCredentialsInfo() {
  return (
    <FlexContainer
      direction="column"
      alignItems="start"
      rowGap={theme.spacing.spacing03}
    >
      <Text weight="bold" mb={0}>
        Account Credentials
      </Text>
      <Message status="notification" image="info">
        <div>
          <Text
            weight="bold"
            mb={theme.spacing.spacing01}
            color={theme.color.text.info}
          >
            On-boarding process
          </Text>
          <Text color={theme.color.text.text02}>
            For the on-boarding process, each account must have an IAM role
            created with read-only permissions and trust relationship set to
            Nordcloud&apos;s AWS account. For your convenience, please use the
            provided Cloudformation template. There is no requirement for the
            deployment region, but we recommend eu-west-1.
          </Text>
        </div>
      </Message>
      <StepContainer counter={1}>
        In the AWS Management Console, go to{" "}
        <ExternalLink href={CLOUD_FORMATION_URL}>CloudFormation</ExternalLink>
      </StepContainer>
      <StepContainer counter={2}>
        Create a CloudFormation Stack based on the{" "}
        <ExternalLink href={CLOUD_FORMATION_TEMPLATE_URL}>
          CloudFormation Template
        </ExternalLink>
        <Text
          size="sm"
          mt={theme.spacing.spacing01}
          color={theme.color.text.text03}
        >
          The AWS External ID should be a randomly generated long string of
          different characters. It will work as a password between Klarity and
          the AWS account.
        </Text>
      </StepContainer>
    </FlexContainer>
  );
}
