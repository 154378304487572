/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import {
  GetApplicationAccumulatedCostsQuery,
  TimePoint,
  useGetApplicationAccumulatedCostsQuery,
} from "~/generated/graphql";
import {
  getTableGranularity,
  mapGranularity,
  CostAnalysisFields,
  Granularity,
  Period,
  TableData,
} from "~/components";
import { isNotNil } from "~/tools";

type Props = {
  appId: string;
  period: Period;
  startDate: string;
  endDate: string;
  granularity: Granularity;
};

export function useApplicationAccumulatedCostChart({
  appId,
  period,
  startDate,
  endDate,
  granularity,
}: Props) {
  const { data, loading, error } = useGetApplicationAccumulatedCostsQuery({
    variables: {
      applicationId: appId,
      startDate,
      endDate,
      granularity: mapGranularity(granularity),
      tableGranularity: getTableGranularity(granularity, period),
    },
  });

  const accumulatedCostChartData = useMemo(
    () => generateAccumulatedCostGroups(data),
    [data]
  );

  const accumulatedCostBudgetChartData = useMemo(
    () =>
      (data?.applicationAccumulatedCost?.timePoints ?? [])
        .filter(isNotNil)
        .map(mapTimePointValue),
    [data]
  );

  const accumulatedCostTimePoints = useMemo(
    () =>
      (data?.applicationAccumulatedTableCost?.timePoints ?? [])
        .filter(isNotNil)
        .map(mapTimePointValue),
    [data]
  );

  const accumulatedCostTotal = Number(
    data?.applicationAccumulatedTableCost?.total ?? 0
  );

  const accumulatedCostTableData: TableData[] = useMemo(
    () => [
      {
        field: "Cost",
        total: accumulatedCostTotal,
        ...accumulatedCostTimePoints?.reduce(
          (acc, { date, value }) => ({ ...acc, [date]: Number(value ?? 0) }),
          {}
        ),
      },
    ],
    [accumulatedCostTotal, accumulatedCostTimePoints]
  );

  return {
    accumulatedCostChartData,
    accumulatedCostTableData,
    accumulatedCostTotal,
    accumulatedCostTimePoints,
    accumulatedCostBudgetChartData,
    loading,
    error,
  };
}

function mapTimePointValue(timePoint: TimePoint) {
  return {
    ...timePoint,
    value: Number(timePoint.value),
  };
}

function generateAccumulatedCostGroups(
  data: GetApplicationAccumulatedCostsQuery | undefined
) {
  return (data?.applicationAccumulatedCost.timePoints ?? [])
    .filter(isNotNil)
    .map((timepoint) => {
      return {
        ...timepoint,
        groups: [
          {
            id: "accumulatedDailyCost",
            value: timepoint.value,
            name: CostAnalysisFields.DAILY_COST,
          },
        ],
      };
    });
}
