/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";

type Props = {
  src?: string;
};

export function PreviewImage({ src }: Props) {
  return (
    <PreviewImageWrapper>
      <img alt="Email preview" css={{ maxWidth: "40rem" }} src={src} />
    </PreviewImageWrapper>
  );
}

const PreviewImageWrapper = styled.div`
  overflow-x: hidden;
`;
