/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useAzureBillingDataQuery } from "~/generated/graphql";

export function useAzureBillingData() {
  const { data, ...mutationState } = useAzureBillingDataQuery();

  const billingData =
    data?.azureBillingData.map((item) => ({
      enrollmentNumber: item?.enrollmentNumber ?? "",
      capacitorCustomerId: item?.capacitorCustomerId ?? "",
      isActive: item?.isActive ?? false,
      isOnboarding: item?.isOnboarding ?? false,
    })) ?? [];

  return [billingData, mutationState] as const;
}
