/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import styled from "styled-components";
import { FlexContainer, Text, theme } from "@nordcloud/gnui";
import { EditButton } from "~/components";
import { Maybe, noop } from "~/tools";

type ReadModeContentsProps = {
  propertyName: string;
  value?: Maybe<string>;
  disableEdit: boolean;
  toggleEditMode?: (propertyName: string) => void;
  setIsEditMode: (editMode: boolean) => void;
};

export function ReadModeContents({
  propertyName,
  value,
  disableEdit,
  toggleEditMode = noop,
  setIsEditMode,
}: ReadModeContentsProps) {
  return (
    <FlexContainer gap={theme.spacing.spacing03}>
      <DetailsValue>{value}</DetailsValue>
      <When condition={!disableEdit}>
        <EditButton
          onClick={() => {
            setIsEditMode(true);
            toggleEditMode(propertyName);
          }}
        />
      </When>
    </FlexContainer>
  );
}

const DetailsValue = styled(Text)`
  margin: 0;
`;
