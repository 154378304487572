/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsProvider,
} from "~/generated/graphql";
import { RecommendationsBanner } from "../constants";
import { useGetCurrentTab } from "./useGetCurrentTab";

type Props = {
  type: ReservationsAndCommitmentsCoverageOfferingType;
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  potentialMonthlySaving: number;
  provider: ReservationsProvider;
  hasFilters: boolean;
};

export function useGetRecommendationBoxBannerMessage({
  provider,
  serviceUsageType,
  type,
  potentialMonthlySaving,
  hasFilters,
}: Props) {
  const { isComputeTab, isDatabaseTab, isStorageTab, isAnalyticsTab } =
    useGetCurrentTab(serviceUsageType);
  const hasPotentialSavings = potentialMonthlySaving > 0;
  const commonHeading = "No Recommendations";
  const bannerHeading =
    hasFilters &&
    type === ReservationsAndCommitmentsCoverageOfferingType.SavingsPlans
      ? "Recommendations can't be calculated"
      : commonHeading;
  const bannerText =
    provider === ReservationsProvider.Aws
      ? RecommendationsBanner.AWS_DATABASE
      : RecommendationsBanner.AZURE_DATABASE;

  const handleComputeTabMessage = (
    offeringType: ReservationsAndCommitmentsCoverageOfferingType
  ) => {
    if (provider === ReservationsProvider.Azure) {
      return {
        hasPotentialSavings,
        bannerHeading,
        bannerText: RecommendationsBanner.AZURE_COMPUTE,
      };
    }
    if (
      offeringType ===
      ReservationsAndCommitmentsCoverageOfferingType.SavingsPlans
    ) {
      return {
        hasPotentialSavings,
        bannerHeading,
        bannerText: hasFilters
          ? RecommendationsBanner.COMPUTE_SAVINGS_PLAN_WITH_FILTERS
          : RecommendationsBanner.COMPUTE_SAVINGS_PLAN_WITHOUT_FILTERS,
      };
    }
    return {
      hasPotentialSavings,
      bannerHeading: commonHeading,
      bannerText: RecommendationsBanner.COMPUTE_RESERVATIONS,
    };
  };

  switch (true) {
    case isComputeTab:
      return handleComputeTabMessage(type);

    case isDatabaseTab:
      return {
        hasPotentialSavings,
        bannerHeading: commonHeading,
        bannerText,
      };
    case isStorageTab:
      return {
        hasPotentialSavings,
        bannerHeading: commonHeading,
        bannerText: RecommendationsBanner.STORAGE,
      };
    case isAnalyticsTab:
      return {
        hasPotentialSavings,
        bannerHeading: commonHeading,
        bannerText: RecommendationsBanner.ANALYTICS,
      };
    default:
      return {
        hasPotentialSavings,
        bannerHeading: commonHeading,
        bannerText: RecommendationsBanner.MACHINE_LEARNINGS,
      };
  }
}
