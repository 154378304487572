/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useGcpBillingDataQuery } from "~/generated/graphql";

export function useGcpBillingData() {
  const { data, ...mutationState } = useGcpBillingDataQuery();

  const billingData =
    data?.gcpBillingData.map((item) => ({
      isActive: item?.isActive ?? false,
      organizationId: item?.organizationId ?? "",
      onboardedOrgId: item?.onboardedOrgId ?? "",
      projectId: item?.projectId ?? "",
      isOnboarding: item?.isOnboarding ?? false,
      bigQueryDatasetName: item?.bigQueryDatasetName ?? "",
      bigQueryTableName: item?.bigQueryTableName ?? "",
      useDetailedBillingData: item?.useDetailedBillingData ?? false,
    })) ?? [];

  return [billingData, mutationState] as const;
}
