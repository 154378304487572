/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  AzureBillingDataDocument,
  AzureBillingDataQuery,
  AzureBillingDataQueryVariables,
  useRemoveAzureBillingConfigV2Mutation,
} from "~/generated/graphql";
import { isNotNil } from "~/tools";

export function useDeleteAzureBillingData() {
  const [deleteBillingDataItem, { client, ...mutationState }] =
    useRemoveAzureBillingConfigV2Mutation();

  const removeAzureBillingData = async (data: {
    capacitorCustomerId: string;
  }) => {
    const { data: removeResponse } = await deleteBillingDataItem({
      variables: { capacitorCustomerId: data.capacitorCustomerId },
    });
    const removedId = removeResponse?.removeAzureBillingConfigV2.id;

    if (isNotNil(removedId)) {
      client.cache.updateQuery<
        AzureBillingDataQuery,
        AzureBillingDataQueryVariables
      >(
        {
          query: AzureBillingDataDocument,
        },
        (billingData) => ({
          azureBillingData: (billingData?.azureBillingData ?? []).filter(
            (item) => item?.capacitorCustomerId !== removedId
          ),
        })
      );
    }
  };

  return [removeAzureBillingData, mutationState] as const;
}
