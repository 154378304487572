/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export enum HybridFormFields {
  CLUSTER_NAME = "clusterName",
  OWNER_ID = "ownerId",
}

export type HybridFormData = {
  [HybridFormFields.CLUSTER_NAME]: string;
  [HybridFormFields.OWNER_ID]: string;
};
