/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  AwsBillingDataDocument,
  AwsBillingDataQuery,
  AwsBillingDataQueryVariables,
  useRemoveAwsBillingConfigV2Mutation,
} from "~/generated/graphql";
import { isNotNil } from "~/tools";

export function useDeleteAwsBillingData() {
  const [deleteBillingDataItem, { client, ...mutationState }] =
    useRemoveAwsBillingConfigV2Mutation();

  const removeAwsBillingData = async (data: { accountId: string }) => {
    const { data: removeResponse } = await deleteBillingDataItem({
      variables: { accountId: data.accountId },
    });
    const removedId = removeResponse?.removeAwsBillingConfigV2.id;

    if (isNotNil(removedId)) {
      client.cache.updateQuery<
        AwsBillingDataQuery,
        AwsBillingDataQueryVariables
      >(
        {
          query: AwsBillingDataDocument,
        },
        (billingData) => ({
          awsBillingData: (billingData?.awsBillingData ?? []).filter(
            (item) => item?.accountId !== removedId
          ),
        })
      );
    }
  };

  return [removeAwsBillingData, mutationState] as const;
}
