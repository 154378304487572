/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ReactNode, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  ExpandedState,
  getExpandedRowModel,
  Cell,
} from "@tanstack/react-table";
import { Text } from "@nordcloud/gnui";
import { TableData } from "~/components";
import { isEmpty } from "~/tools";
import { StyledTable, Th } from "./EstateGroupingsStyles";

type Props = {
  data: TableData[];
  columns: ColumnDef<TableData>[];
  getSubRows?: (
    originalRow: TableData,
    index: number
  ) => TableData[] | undefined;
  children: (cell: Cell<TableData>) => ReactNode;
  firstColumnWidth?: string;
};

export function EstateGroupingsTable({
  data,
  columns,
  getSubRows,
  children,
  firstColumnWidth,
}: Props) {
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    state: { expanded },
    onExpandedChange: setExpanded,
    getSubRows,
  });

  if (isEmpty(table.getRowModel().rows)) {
    return <Text>No data available.</Text>;
  }

  const columnSize = table.getAllColumns().length;

  return (
    <StyledTable mb="0">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={`header-row-${headerGroup.id}`}>
            {headerGroup.headers.map((header) => (
              <Th
                key={header.id}
                colSpan={header.colSpan}
                wide={columnSize < 10}
                firstColumnWidth={firstColumnWidth}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </Th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <>
            <tr key={`key-${row.id}`}>
              {row.getVisibleCells().map((cell) => children(cell))}
            </tr>
          </>
        ))}
      </tbody>
    </StyledTable>
  );
}
