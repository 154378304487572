/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Text, theme } from "@nordcloud/gnui";
import { FilterWrapper, SelectableListWithSearch } from "~/components";
import { isArrayFilterSelected } from "~/utils";
import { isExcluded } from "../helpers";
import { useDataOptions, useFilters } from "../hooks";
import { SidebarFilterProps } from "./types";

export function ExternalIntegrationsFilters({
  filters,
  updateFilters,
}: SidebarFilterProps) {
  const { clearExternalIntegrationFilter } = useFilters({
    filters,
    updateFilters,
  });

  const { externalIntegrations } = useDataOptions(filters);

  return (
    <>
      <Text weight="medium" marginTop={theme.spacing.spacing04}>
        External Integrations
      </Text>
      <FilterWrapper
        name="External Integrations"
        selectedOptions={filters.externalIntegration}
        isSelected={isArrayFilterSelected(filters.externalIntegration)}
        onClear={clearExternalIntegrationFilter}
      >
        <SelectableListWithSearch
          allowModeChange
          selectedOptions={filters.externalIntegration ?? []}
          selectableItems={externalIntegrations}
          onMultiSelect={(externalIntegration) =>
            updateFilters({ externalIntegration })
          }
          onModeChange={(mode) =>
            updateFilters({
              externalIntegrationExclude: isExcluded(mode),
            })
          }
        />
      </FilterWrapper>
    </>
  );
}
