/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { mapMetadata, Metadata } from "../../components";

export function useInstanceDetails(metadata: Metadata[]) {
  const mapped = mapMetadata<MappedMetadata>(metadata);

  return {
    instanceTenancy: mapped.instanceTenancy ?? "",
    isDefault: mapped.isDefault ?? false,
    state: mapped.state ?? "",
    dhcpOptionsId: mapped.dhcpOptionsId ?? "",
    cidrBlock: mapped.cidrBlock ?? "",
    region: mapped.region ?? "",
    ownerId: mapped.ownerId ?? "",
  };
}

type MappedMetadata = {
  instanceTenancy: string;
  isDefault: boolean;
  state: string;
  dhcpOptionsId: string;
  cidrBlock: string;
  region: string;
  ownerId: string;
};
