/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ChangeEvent, KeyboardEvent } from "react";
import styled from "styled-components";
import {
  Button,
  ExtendedTooltip,
  FlexContainer,
  Input,
  SVGIcon,
  theme,
} from "@nordcloud/gnui";
import { useQueryState } from "~/hooks";
import { isEmpty, isNotEmpty } from "~/tools";

type Props = {
  searchInput: string;
  placeHolder: string;
  width?: string;
  setSearchInput: (input: string) => void;
};

export function SettingsSearch({
  searchInput,
  placeHolder,
  width,
  setSearchInput,
}: Props) {
  const { state, updateQueryState, setQueryState } = useQueryState();

  const setQuery = () => {
    if (isEmpty(searchInput)) {
      const { query: ignored, ...queryState } = state;
      setQueryState({ ...queryState, page: 0 });
      return;
    }
    updateQueryState({ query: searchInput, page: 0 });
  };

  const onInput = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setQuery();
    }
  };

  const onCloseSearch = () => {
    setSearchInput("");
    const { query: ignored, ...queryState } = state;
    setQueryState({ ...queryState, page: 0 });
  };

  const isVisibleCloseButton = isNotEmpty(searchInput ?? "");

  return (
    <FlexContainer
      columnGap={theme.spacing.spacing01}
      direction="column"
      alignItems="end"
      css={{
        position: "relative",
        top: theme.spacing.spacing04,
        width: "100%",
        "> div:first-of-type": { width: "98%" },
      }}
    >
      <Input
        name="search-input"
        placeholder={placeHolder}
        type="text"
        value={searchInput}
        css={{ width }}
        onChange={(event) => onInput(event)}
        onKeyDown={(event) => onKeyDown(event)}
      />
      <ButtonContainer isVisibleCloseButton={isVisibleCloseButton}>
        <section className="close-button">
          <ExtendedTooltip
            placement="bottom"
            caption="Close Search"
            position="center"
          >
            <Button severity="low" size="md" onClick={onCloseSearch}>
              <SVGIcon name="close" size="sm" />
            </Button>
          </ExtendedTooltip>
        </section>
        <div className="search-toggle">
          <ExtendedTooltip
            placement="bottom"
            caption="Search"
            position="center"
          >
            <Button severity="low" size="md" onClick={setQuery}>
              <SVGIcon name="search" />
            </Button>
          </ExtendedTooltip>
        </div>
      </ButtonContainer>
    </FlexContainer>
  );
}

const ButtonContainer = styled.div<{
  isVisibleCloseButton: boolean;
}>`
  .close-button {
    display: ${({ isVisibleCloseButton }) =>
      isVisibleCloseButton ? "block" : "none"};
  }
  display: flex;
  position: relative;
  top: -2.4rem;
  right: 0.3rem;
  column-gap: 0.1rem;
`;
