/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { LinkedResource } from "~/views/estate/EstateDetailsPage/components/ReallocatedCostsSidebar";
import { NodeDetails } from "~/views/estate/EstateDetailsPage/NodeDetails";
import { SyntheticDetails } from "~/views/estate/EstateDetailsPage/SyntheticDetails";
import {
  isNodeInstance,
  isPodInstance,
  isPVCInstance,
  isPVInstance,
  isSyntheticInstance,
} from "~/views/estate/EstateDetailsPage/utils";
import { PersistentVolumeClaimDetails } from "./PersistentVolumeClaimDetails";
import { PersistentVolumeDetails } from "./PersistentVolumeDetails";
import { PodDetails } from "./PodDetails";

type Props = {
  providerType?: string | null;
  linkedResources?: LinkedResource[] | null;
  nordcloudId: string;
  cloudAccountId?: string;
  onChange: () => void;
};

export function KubernetesOpenshiftDetails({
  linkedResources,
  providerType,
  ...rest
}: Props) {
  const type = providerType ?? "";
  const resources = linkedResources ?? [];

  return (
    <>
      <When condition={isNodeInstance(type)}>
        <NodeDetails linkedResources={resources} {...rest} />
      </When>
      <When condition={isPodInstance(type)}>
        <PodDetails linkedResources={resources} />
      </When>
      <When condition={isPVCInstance(type)}>
        <PersistentVolumeClaimDetails linkedResources={resources} />
      </When>
      <When condition={isPVInstance(type)}>
        <PersistentVolumeDetails linkedResources={resources} {...rest} />
      </When>
      <When condition={isSyntheticInstance(type)}>
        <SyntheticDetails linkedResources={resources} />
      </When>
    </>
  );
}
