/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { FlexContainer, SVGIcon, theme } from "@nordcloud/gnui";

export const StyledFlexContainer = styled(FlexContainer)`
  column-gap: ${theme.spacing.spacing04};
  padding-left: calc(${theme.spacing.spacing08} + 0.5rem);
  background-color: ${theme.color.background.ui03};
`;

export const StyledSVGIcon = styled(SVGIcon)<{
  isOpen: boolean;
  disabled?: boolean;
}>`
  cursor: pointer;
  transition: transform 0.1s linear;
  transform: ${({ isOpen }) => (isOpen ? "rotate(0deg)" : "rotate(-90deg)")};
  visibility: ${({ disabled = false }) => (disabled ? "hidden" : "visible")};
`;
