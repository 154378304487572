/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export function getPieChartTextSize(text: string) {
  const textLength = text.length;

  if (textLength <= 3) {
    return {
      value: "lg",
      symbol: "md",
    } as const;
  }

  if (textLength <= 5) {
    return {
      value: "md",
      symbol: "sm",
    } as const;
  }

  return {
    value: "sm",
    symbol: "xs",
  } as const;
}

export function formatNumber(value: number) {
  return new Intl.NumberFormat("en-US", {
    notation: "compact",
    signDisplay: "never",
    compactDisplay: "short",
    unitDisplay: "short",
    maximumFractionDigits: 1,
  }).format(value);
}
