/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  UnpackNestedValue,
  useFormContext,
  UseFormGetValues,
  UseFormResetField,
  UseFormSetValue,
} from "react-hook-form";
import { theme } from "@nordcloud/gnui";
import { WorkflowExternalIntegrationType } from "~/generated/graphql";
import { isNil, isNotNil, isSomeEnum, PathName } from "~/tools";
import {
  ComponentType,
  ComponentConfigMap,
  WorkflowDataType,
} from "~/views/workflows/constants";
import {
  WorkflowFormData,
  getDefaultValues,
  isDefaultEmail,
  isDefaultSlackConfig,
} from "~/views/workflows/types";
import { setServiceNowShortDescription } from "./utils";

const isSendDataType = isSomeEnum(WorkflowExternalIntegrationType);

type Props = {
  initialValues?: SendDataComponentInitialValues;
  type?: WorkflowExternalIntegrationType;
};

type UseHold = {
  handleDrop: (componentType: ComponentType) => void;
  handleRemove: () => void;
  iconName: PathName;
  tagText: string;
  tagTextColor: string;
  description: string;
  title: string;
  settingsDisabled: boolean;
};

export function useHoldSendDataComponent({
  initialValues,
  type,
}: Props): UseHold {
  const { setValue, resetField, trigger, watch, getValues } =
    useFormContext<WorkflowFormData>();

  const handleRemove = getRemoveHandler({
    resetField,
    setValue,
    type,
    initialValues,
  });

  const handleDrop = (componentType: ComponentType) => {
    if (!isSendDataType(componentType)) {
      return;
    }

    handleRemove();
    setValue("sendDataComponents", [componentType], { shouldDirty: true });
    trigger("sendDataComponents");

    const selectDataComponent = getValues("selectDataComponent");
    if (isNotNil(selectDataComponent)) {
      setServiceNowShortDescription(setValue, selectDataComponent);
    }
  };

  const noSelectDataComponent = isNil(watch("selectDataComponent"));

  const textProperties = getTextProperties({
    getValues,
    type,
    initialValues,
    noSelectDataComponent,
  });

  return {
    handleDrop,
    handleRemove,
    ...textProperties,
    settingsDisabled: noSelectDataComponent,
  };
}

type GetTextPropertiesProps = {
  getValues: UseFormGetValues<WorkflowFormData>;
  initialValues?: SendDataComponentInitialValues;
  type?: WorkflowExternalIntegrationType;
  noSelectDataComponent: boolean;
};

function getTextProperties({
  type,
  initialValues,
  getValues,
  noSelectDataComponent,
}: GetTextPropertiesProps): TextProperties {
  const builder = getTextPropertiesBuilder(type);

  if (noSelectDataComponent) {
    return builder.selectDataUnconfigured();
  }

  switch (type) {
    case "SERVICE_NOW": {
      const isServiceNowSaved = getValues("serviceNowSaved");
      if (isNil(initialValues) && !isServiceNowSaved) {
        return builder.unconfigured();
      }

      return builder.setTagText("Send to ServiceNow");
    }
    case "EMAIL": {
      const [
        selectDataComponent,
        isEmailSaved,
        mailTo,
        sendToContactPersons,
        sendToAdditionalUsers,
      ] = getValues([
        "selectDataComponent",
        "emailSaved",
        "email.mailTo",
        "email.sendToContactPersons",
        "email.sendToAdditionalUsers",
      ]);

      if (
        !isEmailSaved &&
        (initialValues === undefined ||
          isDefaultEmail(initialValues) ||
          (!sendToContactPersons && !sendToAdditionalUsers))
      ) {
        return builder.unconfigured();
      }

      const tagText = getEmailTagText({
        sendToOwnersAndContacts: sendToContactPersons,
        mailTo,
        messageType: selectDataComponent,
      });

      return builder.setTagText(tagText);
    }
    case "SLACK": {
      const [isSlackSaved, slackChannel, slack] = getValues([
        "slackSaved",
        "slack.channel",
        "slack",
      ]);

      const areInitialValuesEmpty =
        isNil(initialValues) || isDefaultSlackConfig(initialValues);

      if (
        !isSlackSaved &&
        (areInitialValuesEmpty || isDefaultSlackConfig(slack))
      ) {
        return builder.unconfigured();
      }

      return builder.setTagText(`Send to #${slackChannel}`);
    }
    default:
      return builder.unconfigured();
  }
}

type GetRemoveHandlerProps = {
  resetField: UseFormResetField<WorkflowFormData>;
  setValue: UseFormSetValue<WorkflowFormData>;
  initialValues?: SendDataComponentInitialValues;
  type?: WorkflowExternalIntegrationType;
};

function getRemoveHandler({
  resetField,
  setValue,
  type,
  initialValues,
}: GetRemoveHandlerProps) {
  const defaultValues = getDefaultValues();

  return () => {
    setValue("sendDataComponents", [], { shouldDirty: true });

    switch (type) {
      case "SERVICE_NOW":
        resetField("serviceNow", {
          defaultValue: initialValues ?? defaultValues.serviceNow,
        });
        resetField("serviceNowSaved", {
          defaultValue: false,
        });
        resetField("serviceNowSubmissionSnapshot", {
          defaultValue: undefined,
        });

        break;
      case "EMAIL":
        resetField("email", {
          defaultValue: initialValues ?? defaultValues.email,
        });
        resetField("emailSaved", {
          defaultValue: false,
        });
        resetField("emailSubmissionSnapshot", {
          defaultValue: undefined,
        });

        break;
      case "SLACK":
        resetField("slack", {
          defaultValue: initialValues ?? defaultValues.slack,
        });
        resetField("slackSaved", {
          defaultValue: false,
        });
        resetField("slackSubmissionSnapshot", {
          defaultValue: undefined,
        });

        break;
      default:
        break;
    }
  };
}

type EmailTagTextProps = {
  sendToOwnersAndContacts: boolean;
  mailTo?: string;
  messageType?: WorkflowDataType;
};

function getEmailTagText({
  sendToOwnersAndContacts,
  mailTo,
  messageType,
}: EmailTagTextProps) {
  const hasSendToOwnersAndContactsOption =
    messageType !== WorkflowDataType.UNALLOCATED_RESOURCES;
  const commaSeparatedEmails = mailTo ? mailTo.replace(";", ",") : "";

  if (hasSendToOwnersAndContactsOption) {
    return `${
      sendToOwnersAndContacts || mailTo ? "Send to" : ""
    } ${handleEmailSendToOwnersTagText(sendToOwnersAndContacts, messageType)} ${
      mailTo && sendToOwnersAndContacts
        ? ` & ${commaSeparatedEmails}`
        : commaSeparatedEmails
    }`;
  }

  return mailTo ? `Send to ${commaSeparatedEmails}` : "No recipients";
}

function handleEmailSendToOwnersTagText(
  shouldSendToOwnersAndContacts: boolean,
  messageType?: WorkflowDataType
) {
  const tagText = `all selected applications, environments ${
    messageType === WorkflowDataType.ANOMALY_COSTS ? "" : "and org units "
  }owners and contacts`;

  return shouldSendToOwnersAndContacts ? tagText : "";
}

export type SendDataComponentInitialValues =
  | UnpackNestedValue<WorkflowFormData>["email"]
  | UnpackNestedValue<WorkflowFormData>["serviceNow"]
  | UnpackNestedValue<WorkflowFormData>["slack"];

type TextProperties = {
  iconName: PathName;
  tagText: string;
  tagTextColor: string;
  description: string;
  title: string;
};

function getTextPropertiesBuilder(type?: WorkflowExternalIntegrationType) {
  const staticProperties = isNotNil(type) ? ComponentConfigMap[type] : {};
  const defaultProperties = {
    description: "",
    title: "",
    iconName: "default" as const,
    tagText: "",
    tagTextColor: theme.color.text.text02,
    ...staticProperties,
  };

  return {
    setTagText: (tagText: string) => ({
      ...defaultProperties,
      tagText,
    }),
    unconfigured: () => ({
      ...defaultProperties,
      tagText: "Please configure this component",
      tagTextColor: "danger",
    }),
    selectDataUnconfigured: () => ({
      ...defaultProperties,
      tagText: "Please configure Select Data component",
      tagTextColor: "danger",
    }),
  };
}
