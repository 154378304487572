/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Path, useFormContext, Controller } from "react-hook-form";
import { WorkflowFormData } from "../../types";
import { EnhancedInput } from "../components/EnhancedInput";

type EmailInputProps = {
  disabled?: boolean;
  placeholder?: string;
  name: Path<WorkflowFormData>;
};

export function EmailInput({ name, ...others }: EmailInputProps) {
  const { control } = useFormContext<WorkflowFormData>();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, ...rest }, fieldState: { invalid } }) => (
        <EnhancedInput
          {...others}
          status={invalid ? "danger" : undefined}
          value={String(value ?? "")}
          {...rest}
        />
      )}
    />
  );
}
