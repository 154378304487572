/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Spacer, theme } from "@nordcloud/gnui";
import { CollapsibleBox, TableLoader, UniversalWrap } from "~/components";
import { ApplicationTitle } from "./ApplicationTitle";
import { EnvironmentsList } from "./EnvironmentsList";
import { useMappedEnvironments } from "./hooks";
import { MappedApplicationV2, MappedEnvironmentV2 } from "./types";

type Props = {
  application: MappedApplicationV2;
};

export function ApplicationItemV2({ application }: Props) {
  const [environments, setEnvironments] = useState<MappedEnvironmentV2[]>([]);
  const [isOpened, setIsOpened] = useState(false);

  const [getEnvironments, { loading, error }] = useMappedEnvironments({
    id: application.id,
  });

  const handleOpen = async (state: boolean) => {
    setIsOpened(state);
    if (state) {
      const envs = await getEnvironments(0, 20);

      setEnvironments(envs);
    }
  };

  return (
    <>
      <CollapsibleBox
        disabled={application.environmentsCount === 0}
        title={<ApplicationTitle application={application} />}
        opened={false}
        arrowPlacement="left"
        boxProps={{ boxStyle: "lightGrey" }}
        onStateChange={handleOpen}
      >
        <UniversalWrap
          errorProps={{ error }}
          loaderProps={{
            loading: !isOpened || loading,
            items: application.environmentsCount,
            Component: Loader,
          }}
        >
          <EnvironmentsList
            environments={environments}
            applicationId={application.id}
          />
        </UniversalWrap>
      </CollapsibleBox>
      <Spacer height={theme.spacing.spacing03} />
    </>
  );
}
type LoaderProps = {
  items: number;
};

function Loader({ items }: LoaderProps) {
  return (
    <TableLoader
      gapY={10}
      gapX={6}
      rows={[
        {
          count: Math.min(items, 10) + 1,
          height: 60,
          items: [
            {
              widthPercent: 21,
            },
            {
              widthPercent: 21,
            },
            {
              widthPercent: 12,
            },
            {
              widthPercent: 12,
            },
            {
              widthPercent: 12,
            },
            {
              widthPercent: 12,
            },
            {
              widthPercent: 10,
            },
          ],
        },
      ]}
    />
  );
}
