/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Box } from "@nordcloud/gnui";
import { SUCCESS_TEXT, ERROR_TEXT } from "~/constants";
import { showSuccess, showError } from "~/services/toast";
import { Form } from "./Form";
import { useAddAwsBillingData } from "./hooks/useAddAwsBillingData";
import { FormData } from "./validators";

export function AddBillingData({ onClose }: { onClose: () => void }) {
  const { addAwsBillingData, error, loading } = useAddAwsBillingData();

  const onSubmit = async (data: FormData) => {
    try {
      await addAwsBillingData(data);
      showSuccess(SUCCESS_TEXT.billingDataAdded);
      onClose();
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  return (
    <Box boxStyle="lightGrey">
      <Form
        showRegionDisclaimer
        showDivider
        loading={loading}
        error={error}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </Box>
  );
}
