/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useReducer } from "react";
import { useToggle, useUpdateEffect } from "react-use";
import {
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsLookbackPeriod,
  ReservationsProvider,
  ReservationsRecommendationPaymentOption,
  ReservationsRecommendationTerm,
} from "~/generated/graphql";
import { useTabsWithUrl } from "~/hooks";
import {
  reducer,
  initialStateAws,
  initialStateAzure,
  ACTION,
} from "../reducers";
import { UsageCoverageData } from "../types";
import { getDefaultPaymentOption } from "../utils";

export function useReservationState(provider: ReservationsProvider): {
  offeringType: ReservationsAndCommitmentsCoverageOfferingType;
  timePeriod: ReservationsAndCommitmentsLookbackPeriod;
  paymentOption: ReservationsRecommendationPaymentOption;
  termOption: ReservationsRecommendationTerm;
  isSidebarOpen: boolean;
  coverageDetails: UsageCoverageData;
  activeTab: number;
  handleOpenSidebar: (coverageData: UsageCoverageData) => void;
  handleToggleSidebar: () => void;
  handleUpdateTermOption: (termData: ReservationsRecommendationTerm) => void;
  handleUpdatePaymentOption: (
    paymentOptionData: ReservationsRecommendationPaymentOption
  ) => void;
  handleOfferingType: (
    offeringTypeData: ReservationsAndCommitmentsCoverageOfferingType
  ) => void;
  handleSetTimePeriod: (
    lookbackPeriod: ReservationsAndCommitmentsLookbackPeriod
  ) => void;
  setActiveTab: (key: number) => void;
} {
  const { activeTab, setActiveTab } = useTabsWithUrl();
  const defaultPaymentOption = getDefaultPaymentOption(provider);
  const initialValue = getInitialState(defaultPaymentOption, provider);
  const [state, dispatch] = useReducer(reducer, initialValue);

  const [isSidebarOpen, handleToggleSidebar] = useToggle(false);

  const {
    offeringType,
    timePeriod,
    paymentOption,
    termOption,
    coverageDetails,
  } = state;

  const handleSetTimePeriod = (
    lookbackPeriod: ReservationsAndCommitmentsLookbackPeriod
  ) => {
    dispatch({
      type: ACTION.SET_TIME_PERIOD,
      payload: { data: lookbackPeriod },
    });
  };

  const handleOfferingType = (
    offeringTypeData: ReservationsAndCommitmentsCoverageOfferingType
  ) => {
    dispatch({
      type: ACTION.SET_OFFERING_TYPE,
      payload: { data: offeringTypeData },
    });
  };

  const handleUpdatePaymentOption = (
    paymentOptionData: ReservationsRecommendationPaymentOption
  ) => {
    dispatch({
      type: ACTION.SET_PAYMENT_OPTION,
      payload: { data: paymentOptionData },
    });
  };

  const handleUpdateTermOption = (termData: ReservationsRecommendationTerm) => {
    dispatch({
      type: ACTION.SET_TERM_OPTION,
      payload: { data: termData },
    });
  };

  const setCoverageDetails = (coverageDetail: UsageCoverageData) => {
    dispatch({
      type: ACTION.SET_COVERAGE_DETAILS,
      payload: { data: coverageDetail },
    });
  };

  const handleOpenSidebar = (coverageData: UsageCoverageData) => {
    handleToggleSidebar(true);
    setCoverageDetails(coverageData);
  };

  useUpdateEffect(() => {
    handleSetTimePeriod(ReservationsAndCommitmentsLookbackPeriod.ThirtyDays);
    handleUpdatePaymentOption(defaultPaymentOption);
    handleUpdateTermOption(ReservationsRecommendationTerm.OneYear);
  }, [activeTab]);

  return {
    offeringType,
    timePeriod,
    paymentOption,
    termOption,
    isSidebarOpen,
    coverageDetails,
    activeTab,
    handleOpenSidebar,
    handleToggleSidebar,
    handleUpdateTermOption,
    handleUpdatePaymentOption,
    handleOfferingType,
    handleSetTimePeriod,
    setActiveTab,
  };
}

function getInitialState(
  paymentOption: ReservationsRecommendationPaymentOption,
  provider: ReservationsProvider
) {
  if (provider === ReservationsProvider.Aws) {
    return {
      ...initialStateAws,
      paymentOption,
    };
  }
  return {
    ...initialStateAzure,
    paymentOption,
  };
}
