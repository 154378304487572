/**
 * Copyright 2021-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { Cost } from "~/generated/graphql";
import { dateFormat } from "~/constants";
import { ROUTES } from "~/routing/routes";

export function getHeaderDate(isCurrentBillingPeriod: boolean) {
  const end = (
    isCurrentBillingPeriod
      ? dayjs()
      : dayjs().subtract(1, "month").endOf("month")
  ).format(dateFormat.dayShortMonthYear);
  return `01 - ${end}`;
}

export function getForecastData(forecast: number, previousMonth: number) {
  const diff = Math.abs(previousMonth - forecast);
  return {
    higher: forecast > previousMonth,
    diff: diff.toFixed(2),
  };
}

type Nullable<T> = { [P in keyof T]: T[P] | null };

type DataProps = Nullable<
  Partial<{
    forecast: string;
    currentMonth: string;
    previousMonth: string;
    budget: string[];
    cloudWasteCost: string;
    co2e: string;
    co2eSavings: string;
    originalCost: string;
    reservedInstanceBenefit: string;
  }>
>;

type FormatCostObjectProps = {
  data: DataProps;
  currency: string;
  isCurrentBillingPeriod?: boolean;
};

type CostObject = {
  currency: string;
  forecast: number;
  currentMonth: number;
  previousMonth: number;
  budget: number | null;
  cloudWasteCost: number;
  co2e: number;
  co2eSavings: number;
  originalCost: number;
  reservedInstanceBenefit: number;
};

export function formatCostObject({
  data,
  currency,
  isCurrentBillingPeriod = true,
}: FormatCostObjectProps): CostObject {
  return {
    currency,
    forecast: parseFloat(data.forecast ?? "0"),
    currentMonth: parseFloat(data.currentMonth ?? "0"),
    previousMonth: parseFloat(data.previousMonth ?? "0"),
    budget: getBudget(isCurrentBillingPeriod, data.budget ?? []),
    cloudWasteCost: parseFloat(data.cloudWasteCost ?? "0"),
    co2e: parseFloat(data.co2e ?? "0"),
    co2eSavings: parseFloat(data.co2eSavings ?? "0"),
    originalCost: parseFloat(data.originalCost ?? "0"),
    reservedInstanceBenefit: parseFloat(data.reservedInstanceBenefit ?? "0"),
  };
}

function getBudget(isCurrentBillingPeriod: boolean, budgets: string[] = []) {
  const subtractionIndex = isCurrentBillingPeriod ? 0 : 1;
  const monthIndex = dayjs().subtract(subtractionIndex, "month").month();
  const hasBudgetInAnyMonth = budgets.some((item) => parseFloat(item) > 0);

  return hasBudgetInAnyMonth ? parseFloat(budgets[monthIndex]) : null;
}

export function getOriginalCost(cost?: Cost | null) {
  if (cost == null) {
    return "0";
  }
  return "originalCost" in cost ? cost.originalCost?.currentMonth ?? "0" : "0";
}

type FormatSavingSuggestionsProps = {
  type: "Application" | "Environment";
  show?: boolean | null;
  appId: string;
  envId?: string;
  loading?: boolean;
  clicked: () => void;
};

export function formatSavingSuggestions({
  type,
  show,
  appId,
  envId,
  loading = false,
  clicked,
}: FormatSavingSuggestionsProps) {
  return {
    clicked,
    loading,
    show: show ?? false,
    reason: `${type} has Saving Suggestions.`,
    uri:
      type === "Environment"
        ? `${ROUTES.applications.index}/${appId}/${envId}?waste=true`
        : `${ROUTES.estateRecords.index}?application=${appId}&page=0&waste=true`,
  };
}
