/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { Else, If, Then } from "react-if";
import { JSONTree } from "react-json-tree";
import { Text } from "@nordcloud/gnui";
import { NoData } from "~/components";
import { DetailData } from "~/components/Details/Detail";
import { useScrollToFirstResult } from "~/hooks/useScrollToFirstResult";
import { isEmpty, isNotEmpty, isNotNil } from "~/tools";

type SearchableJsonProps = {
  data?: DetailData;
  isCollapsed?: boolean;
  search?: string;
  setResults: (num: number) => void;
};

enum HTML_ELEMENT_TYPE {
  EM = "em",
  SPAN = "span",
}

export function SearchableJson({
  data,
  isCollapsed,
  search,
  setResults,
}: SearchableJsonProps) {
  const itemsRef = React.useRef<(HTMLElement | null)[]>([]);

  const isPartsEqual = (part: string, highlight: string) =>
    part.toLowerCase() === highlight.toLowerCase();

  const getStyle = (part: string, highlight: string) =>
    isPartsEqual(part, highlight) ? { backgroundColor: "yellow" } : {};

  React.useEffect(() => {
    if (isEmpty(search ?? "")) {
      itemsRef.current = [];
    }
    setResults(itemsRef.current.length);
  }, [search]);

  const setRef = (
    element: HTMLSpanElement | null,
    part: string,
    highlight: string
  ) => {
    if (isPartsEqual(part, highlight) && isNotNil(element)) {
      itemsRef.current[itemsRef.current.length] = element;
    }
  };

  useScrollToFirstResult({
    trigger: () => isNotEmpty(search ?? ""),
    ref: itemsRef,
  });

  const getHighlightedText = (
    text: string,
    highlight?: string,
    htmlElementType?: HTML_ELEMENT_TYPE
  ) => {
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));

    if (highlight && parts.length > 1) {
      return (
        <span>
          {parts.map((part, indx) => (
            <>
              <If condition={htmlElementType === HTML_ELEMENT_TYPE.EM}>
                <Then>
                  <em
                    key={`em.${part}.${indx}`}
                    ref={(element) => setRef(element, part, highlight)}
                    style={getStyle(part, highlight)}
                  >
                    {part}
                  </em>
                </Then>
                <Else>
                  <span
                    key={`span.${part}.${indx}`}
                    ref={(element) => setRef(element, part, highlight)}
                    style={getStyle(part, highlight)}
                  >
                    {part}
                  </span>
                </Else>
              </If>
            </>
          ))}
        </span>
      );
    } else {
      return (
        <>
          <If condition={htmlElementType === HTML_ELEMENT_TYPE.EM}>
            <Then>
              <em>{text}</em>
            </Then>
            <Else>
              <span>{text}</span>
            </Else>
          </If>
        </>
      );
    }
  };

  return (
    <If condition={data != null}>
      <Then>
        <JSONTree
          hideRoot
          data={data?.value}
          shouldExpandNode={() => !isCollapsed}
          theme={{
            tree: ({ style }) => ({
              style: { ...style, backgroundColor: undefined }, // removing default background color from styles
            }),
          }}
          labelRenderer={([key]) =>
            getHighlightedText(key.toString(), search, HTML_ELEMENT_TYPE.SPAN)
          }
          valueRenderer={(raw) =>
            getHighlightedText(raw.toString(), search, HTML_ELEMENT_TYPE.EM)
          }
        />
      </Then>
      <Else>
        <NoData message={<Text>There is no data available.</Text>} />
      </Else>
    </If>
  );
}
