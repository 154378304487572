/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { useCloudAccountCostsByProviderQuery } from "~/generated/graphql";
import { useCustomers } from "~/services/customers";
import { isNil } from "~/tools";
import { tabDefinitions } from "../CloudAccountList/constants";
import {
  CloudSummaryTabData,
  MovedCloudAccountTotalCosts,
  Provider,
  ProviderTab,
} from "../types";
import { extractMovedCostsForProvider, sortTabData } from "../utils";

export function useCostsByProvider(movedCosts: MovedCloudAccountTotalCosts) {
  const { data, loading, error } = useCloudAccountCostsByProviderQuery({
    skip: Object.values(movedCosts).some(isNil),
  });

  const { isAdminLite } = useCustomers();

  const tabs = useMemo(() => {
    const costsByProvider =
      data?.cloudAccountCostsByProvider?.costsByProvider ?? [];

    const initialValue: CloudSummaryTabData[] = [];
    const result = tabDefinitions.reduce((visibleTabs, tabDefinition) => {
      if (
        isAdminLite &&
        ![ProviderTab.AWS, ProviderTab.AZURE, ProviderTab.GCP].includes(
          tabDefinition.name
        )
      ) {
        return visibleTabs;
      }

      const tabData = costsByProvider.find(
        (costByProvider) => costByProvider.provider === tabDefinition.name
      );

      const isVMWare = tabData?.provider === Provider.Vmware;
      const cost = isVMWare
        ? tabDefinition.cost
        : tabData?.cost || tabDefinition.cost;

      const currentTab = tabData
        ? {
            hasWarning: tabData.hasWarning,
            name: tabData.provider as ProviderTab,
            count: tabData.count,
            cost,
            ...extractMovedCostsForProvider(tabData.provider, movedCosts),
          }
        : tabDefinition;

      visibleTabs.push(currentTab);

      return visibleTabs;
    }, initialValue);

    return sortTabData(result);
  }, [data, movedCosts]);

  return {
    loading,
    error,
    tabs,
  };
}
