/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloError } from "@apollo/client";
import { Spacer, theme } from "@nordcloud/gnui";
import { BreadcrumbsBox, UniversalWrap } from "~/components";
import { DefaultEc2Header, useDefaultEc2HeaderData } from "../components";
import { Ec2InstanceApplications, EstateRecord } from "../Ec2Instance";
import { useEstateDetails } from "../hooks";
import { ApplicationProps, Costs } from "../types";
import { getResourceVisabilityDates } from "../utils";
import { Ec2SubnetDetails } from "./Ec2SubnetDetails";
import { Ec2SubnetTabs } from "./Ec2SubnetTabs";
import { useEc2SubnetDetails } from "./hooks/useEc2SubnetDetails";

type Props = {
  header: EstateRecord;
  data: ReturnType<typeof useEstateDetails>["data"];
  applications: ApplicationProps;
  loading: boolean;
  error: ApolloError | undefined;
  costs: Costs;
};

export function Ec2SubnetPage({
  header,
  data,
  applications,
  loading,
  error,
  costs,
}: Props) {
  const { metadata, breadcrumbLabels, estateRecord } = data;

  const { name } = header;
  const headerData = useDefaultEc2HeaderData(header, costs);
  const resourceLifecycleDates = getResourceVisabilityDates(estateRecord);
  const subnetDetails = useEc2SubnetDetails(metadata, resourceLifecycleDates);

  return (
    <UniversalWrap loaderProps={{ loading }} errorProps={{ error }}>
      <BreadcrumbsBox title={name} labels={breadcrumbLabels} />
      <DefaultEc2Header {...headerData} />
      <Spacer height={theme.spacing.spacing04} />
      <Ec2SubnetDetails {...subnetDetails} />
      <Spacer height={theme.spacing.spacing04} />
      <Ec2InstanceApplications {...applications} />
      <Spacer height={theme.spacing.spacing04} />
      <Ec2SubnetTabs data={data} />
    </UniversalWrap>
  );
}
