/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  BusinessContextTargetsDocument,
  BusinessContextTargetsQuery,
  BusinessContextTargetsQueryVariables,
  KpiTargetSelection,
  useAddKpiTargetMutation,
} from "~/generated/graphql";
import { showSuccess } from "~/services/toast";
import { handleError, noop, toInputError } from "~/tools";
import { KPITargetInput } from "../types";
import {
  addTargetToCache,
  getModifiedKpiName,
  invalidateKpisInCache,
  isCurrentTarget,
} from "./helpers";

export function useAddKpiTarget() {
  const [
    addKpi,
    {
      loading,
      client: { cache },
    },
  ] = useAddKpiTargetMutation();

  const addTarget = async (
    input: KPITargetInput,
    queryVariables: BusinessContextTargetsQueryVariables,
    onSuccess: () => void = noop
  ) => {
    try {
      const { data } = await addKpi({
        variables: {
          input,
        },
      });

      const newTarget = data?.addKPITarget;

      if (newTarget == null) {
        return;
      }

      const modifiedKpi = getModifiedKpiName(queryVariables);

      if (isCurrentTarget(newTarget)) {
        invalidateKpisInCache(cache, queryVariables);
      } else {
        cache.updateQuery<
          BusinessContextTargetsQuery,
          BusinessContextTargetsQueryVariables
        >(
          {
            query: BusinessContextTargetsDocument,
            variables: {
              ...queryVariables,
              selection: KpiTargetSelection.Upcoming,
            },
          },
          (bcTargets) => addTargetToCache(bcTargets, newTarget, modifiedKpi)
        );
      }

      showSuccess(`Successfully added new KPI Target ${input.name}`);
      onSuccess?.();
    } catch (error) {
      handleError(toInputError(error));
    }
  };
  return { addTarget, loading };
}
