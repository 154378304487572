/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import dayjs from "dayjs";
import {
  FlexContainer,
  MultipleSelect,
  SelectButton,
  Text,
  theme,
} from "@nordcloud/gnui";
import { DateRange, getRangeLabel } from "~/components/Charts";
import {
  DateRangeSelector,
  EstatePeriod as Period,
} from "~/components/DateRangeSelector";
import { dateFormat } from "~/constants";
import { useBillingPeriod } from "~/services/customers";
import { isNil, noop } from "~/tools";
import { usePeriodSelector } from "./usePeriodSelector";

type Props = {
  period: Period;
  onChange: (selectedPeriod: Period, selectedRange?: DateRange) => void;
  range?: DateRange;
  rangeStartDate?: string;
  rangeEndDate?: string;
  clearRange?: () => void;
  disabled?: boolean;
};

const defaultSelectButtonWidth = "3rem";
const defaultRangeLabel = "Date Range";

export function PeriodSelector({
  period,
  onChange,
  range,
  rangeStartDate,
  rangeEndDate,
  clearRange = noop,
  disabled = false,
}: Props) {
  const { isPreviousBillingPeriod } = useBillingPeriod();

  const { rangeLabel, setRangeLabel, setRange, onPeriodChange, handleClear } =
    usePeriodSelector({ onChange, clearRange });

  useEffect(() => {
    if (period === Period.RANGE || rangeStartDate) {
      setRangeLabel(getRangeLabel(range));
    }

    if (isNil(range)) {
      setRangeLabel(defaultRangeLabel);
    }
  }, [period, range, rangeStartDate, rangeEndDate, setRangeLabel]);

  const defaultPeriod = getDefaultPeriod(isPreviousBillingPeriod);

  return (
    <FlexContainer
      data-testid="period-selector"
      css={{
        paddingBottom: theme.spacing.spacing01,
        marginRight: theme.spacing.spacing07,
      }}
    >
      <Text
        size="sm"
        mb="0"
        mr={theme.spacing.spacing04}
        weight="medium"
        color={theme.color.text.text02}
      >
        Period:
      </Text>
      <MultipleSelect size="small">
        <SelectButton
          name="previous-month"
          value="0"
          labelText={formatLabel(getPreviousPeriod(defaultPeriod))}
          isActive={period === Period.PREVIOUS_MONTH}
          data-testid="prev-month"
          disabled={disabled}
          style={{
            minWidth: defaultSelectButtonWidth,
          }}
          onClick={() => onPeriodChange(Period.PREVIOUS_MONTH)}
        />
        <SelectButton
          name="default-month"
          value="1"
          labelText={formatLabel(defaultPeriod)}
          isActive={period === Period.DEFAULT_MONTH}
          data-testid="default-month"
          disabled={disabled}
          style={{ minWidth: defaultSelectButtonWidth }}
          onClick={() => onPeriodChange(Period.DEFAULT_MONTH)}
        />
        <DateRangeSelector
          showPreselects
          isActive={period === Period.RANGE}
          label={rangeLabel}
          period={period}
          range={range}
          data-testid="date-range"
          disabled={disabled}
          onApply={setRange}
          onClear={handleClear}
        />
      </MultipleSelect>
    </FlexContainer>
  );
}

function getDefaultPeriod(isPreviousBillingPeriod: boolean) {
  if (isPreviousBillingPeriod) {
    return dayjs()
      .subtract(1, "month")
      .format(dateFormat.yearMonth)
      .toUpperCase();
  }
  return dayjs().format(dateFormat.yearMonth).toUpperCase();
}

function getPreviousPeriod(defaultPeriod: string) {
  return dayjs(defaultPeriod)
    .subtract(1, "month")
    .format(dateFormat.yearMonth)
    .toUpperCase();
}

function formatLabel(period: string) {
  return dayjs(period).format(dateFormat.monthShort).toUpperCase();
}
