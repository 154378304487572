/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Legend } from "../../components";
import { costVsBudgetChartColors } from "./consts";

export function CostVsBudgetLegend() {
  return (
    <Legend
      inline
      data={[
        {
          color: costVsBudgetChartColors.budgetStroke,
          label: "Budget",
          isDotted: true,
        },
      ]}
    />
  );
}
