/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { MenuBarContext } from "./context";

type Props = {
  children: React.ReactNode;
};

export function MenuBarProvider({ children }: Props) {
  const [sidebarOpened, setSidebarOpened] = React.useState(false);

  const value = {
    sidebarOpened,
    setSidebarOpened,
  };

  return (
    <MenuBarContext.Provider value={value}>{children}</MenuBarContext.Provider>
  );
}
