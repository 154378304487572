/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import styled from "styled-components";
import { theme } from "@nordcloud/gnui";
import { dateFormat } from "~/constants";
import { isNotNil } from "~/tools";
import { Item } from "../../components";
import { Status } from "../components";

type Props = {
  imageId: string;
  name: string;
  imageLocation: string;
  owner: string;
  architecture: string;
  platformDetails: string;
  state: string;
  creationDate: string | undefined;
  deprecationTime: string | undefined;
};

export function Ec2ImageDetails({
  imageId,
  name,
  imageLocation,
  owner,
  architecture,
  platformDetails,
  state,
  creationDate,
  deprecationTime,
}: Props) {
  const created = getFormattedDate(creationDate);
  const deprecated = getFormattedDate(deprecationTime);

  const status = state ? (
    <Item
      label="Status"
      component={<Status value={state} successOn="available" />}
    />
  ) : (
    <Item label="Status" value="-" />
  );

  return (
    <GridWrapper>
      <Item label="Image ID" value={imageId} />
      <Item label="AMI Name" value={name} />
      <Item label="AMI Location" value={imageLocation} />
      <Item label="Image Owner" value={owner} />
      <Item label="Architecture" value={architecture} />
      <Item label="Platform Details" value={platformDetails} />
      {status}
      <Item label="Creation Date" value={created} />
      <Item label="Deprecation Time" value={deprecated} />
    </GridWrapper>
  );
}

function getFormattedDate(date: string | undefined) {
  return isNotNil(date)
    ? dayjs(date).format(dateFormat.awsDateTime)
    : undefined;
}

const GridWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  row-gap: ${theme.spacing.spacing02};
  column-gap: ${theme.spacing.spacing04};
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: 1.5fr 1fr 1fr;

  @media screen and (max-width: ${theme.breakpoints.lg}px) {
    grid-template-rows: repeat(9, 1fr);
    grid-template-columns: 1fr;
  }
`;
