/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { Else, If, Then } from "react-if";
import { css } from "styled-components";
import { ExtendedTooltip, SVGIcon, Table, Text, theme } from "@nordcloud/gnui";
import {
  calculateSaving,
  formatPercentage,
  formatPrice,
  getSavingsIconColor,
  getSavingsIcon,
  getIconTooltipText,
  getTypeRowBackgroundColor,
  getIconType,
  getSavingsTooltipText,
} from "./helpers";
import { ComparisonItem } from "./types";

const CELL_ALIGN_RIGHT_CSS = css({
  textAlign: "right",
});

type Props = {
  comparison: ComparisonItem[];
};

export function Ec2InstanceComparisonTable({ comparison }: Props) {
  const current = useMemo(
    () => comparison.find(({ currentType }) => currentType),
    [comparison]
  );

  const formattedComparison = useMemo(
    () =>
      [...comparison]
        .sort((a, b) => Number(a.priceHourly) - Number(b.priceHourly))
        .map((item) => ({
          ...item,
          savings: calculateSaving(
            item.priceHourly,
            current?.priceHourly ?? "0"
          ),
        })),
    [comparison, current]
  );

  return (
    <Table>
      <Table.thead>
        <Table.tr>
          <Table.th css={{ width: "1.5rem" }} />
          <Table.th>Name</Table.th>
          <Table.th css={CELL_ALIGN_RIGHT_CSS}>Memory</Table.th>
          <Table.th css={CELL_ALIGN_RIGHT_CSS}>vCPUs</Table.th>
          <Table.th>Instance Storage</Table.th>
          <Table.th>Network Performance</Table.th>
          <Table.th css={CELL_ALIGN_RIGHT_CSS}>Cost per Hour</Table.th>
          <Table.th css={CELL_ALIGN_RIGHT_CSS}>Cost per Day</Table.th>
          <Table.th css={CELL_ALIGN_RIGHT_CSS}>Cost per Month</Table.th>
          <Table.th css={CELL_ALIGN_RIGHT_CSS}>Savings Rate</Table.th>
        </Table.tr>
      </Table.thead>
      <Table.tbody>
        {formattedComparison.map((item) => (
          <Table.tr
            key={item.instanceType}
            css={{
              backgroundColor: getTypeRowBackgroundColor(
                item.isInstanceFromSavingsSuggestions
              ),
            }}
          >
            <Table.td>
              <Icon
                currentType={item.currentType}
                isInstanceFromSavingsSuggestions={
                  item.isInstanceFromSavingsSuggestions
                }
              />
            </Table.td>
            <Table.td>{item.instanceType}</Table.td>
            <Table.td css={CELL_ALIGN_RIGHT_CSS}>{item.memory}</Table.td>
            <Table.td css={CELL_ALIGN_RIGHT_CSS}>{item.vCPU}</Table.td>
            <Table.td>{item.storage}</Table.td>
            <Table.td>{item.networkPerformance}</Table.td>
            <Table.td css={CELL_ALIGN_RIGHT_CSS}>
              {formatPrice(item.priceHourly)}
            </Table.td>
            <Table.td css={CELL_ALIGN_RIGHT_CSS}>
              {formatPrice(item.priceDaily)}
            </Table.td>
            <Table.td css={CELL_ALIGN_RIGHT_CSS}>
              {formatPrice(item.priceMonthly)}
            </Table.td>
            <Table.td css={CELL_ALIGN_RIGHT_CSS}>
              <Savings currentType={item.currentType} savings={item.savings} />
            </Table.td>
          </Table.tr>
        ))}
      </Table.tbody>
    </Table>
  );
}

type SavingsProps = {
  currentType: boolean;
  savings: string;
};

function Savings({ currentType, savings }: SavingsProps) {
  const color = getSavingsIconColor(savings);

  return (
    <If condition={currentType}>
      <Then>
        <Text weight="bold">Current</Text>
      </Then>
      <Else>
        <ExtendedTooltip
          caption={getSavingsTooltipText(savings)}
          status={color}
        >
          <SVGIcon size="sm" name={getSavingsIcon(savings)} color={color} />
          <Text mb={0} ml={theme.spacing.spacing01} as="span" color={color}>
            {formatPercentage(savings)}
          </Text>
        </ExtendedTooltip>
      </Else>
    </If>
  );
}

type IconProps = {
  isInstanceFromSavingsSuggestions: boolean;
  currentType: boolean;
};

function Icon({ isInstanceFromSavingsSuggestions, currentType }: IconProps) {
  return (
    <ExtendedTooltip
      position="start"
      status={isInstanceFromSavingsSuggestions ? "success" : undefined}
      caption={getIconTooltipText(
        isInstanceFromSavingsSuggestions,
        currentType
      )}
    >
      <SVGIcon
        css={{ verticalAlign: "middle" }}
        name={getIconType(isInstanceFromSavingsSuggestions, currentType)}
      />
    </ExtendedTooltip>
  );
}
