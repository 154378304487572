/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { FlexContainer, SVGIcon, Text, theme } from "@nordcloud/gnui";
import { LinkButton } from "~/components";
import { DisplayMessageWrapper } from "../Styles";

type Props = {
  heading: string;
  subText: string;
  hasFilters: boolean;
  onClearFilters?: () => void;
};

export function RecommendationBanner({
  heading,
  subText,
  hasFilters,
  onClearFilters,
}: Props) {
  return (
    <DisplayMessageWrapper hasFilters={hasFilters}>
      <SVGIcon name="info" />
      <FlexContainer direction="column" alignItems="start">
        <Text
          weight="medium"
          as="header"
          mb={theme.spacing.spacing01}
          align="left"
        >
          {heading}
        </Text>
        <Text mb={theme.spacing.spacing02}>{subText}</Text>
        <When condition={hasFilters}>
          <Text tag="span" size="sm">
            Please{" "}
            <LinkButton onClick={onClearFilters}>clear the filters</LinkButton>{" "}
            and then click the recommendations
          </Text>
        </When>
      </FlexContainer>
    </DisplayMessageWrapper>
  );
}
