/**
 * Copyright 2021-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then } from "react-if";
import { FlexContainer, Spacer, SVGIcon, theme } from "@nordcloud/gnui";
import {
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsProvider,
} from "~/generated/graphql";
import { DataContainer, DataItem, BorderSeparator } from "~/components";
import { percentageFormat } from "~/components/Charts/utils";
import { ItemWrapper } from "~/components/Styled/ItemWrapper";
import { useCurrency } from "~/hooks/useCurrency";
import { convertToPercent, formatMoney } from "~/tools";

type Props = {
  potentialMonthlySaving: string;
  potentialYearlySaving: string;
  upfrontCost: string;
  recurringMonthlyCost: string;
  savingsRate: string;
  serviceUsageType: string;
  provider: ReservationsProvider;
};

export function ReservationsSummary({
  potentialMonthlySaving,
  potentialYearlySaving,
  upfrontCost,
  recurringMonthlyCost,
  savingsRate,
  serviceUsageType,
  provider,
}: Props) {
  const { currency } = useCurrency();

  return (
    <FlexContainer
      columnGap={theme.spacing.spacing04}
      marginTop={theme.spacing.spacing02}
      wrap="wrap"
    >
      <ItemWrapper>
        <If
          condition={
            serviceUsageType ===
              ReservationsAndCommitmentsCoverageServiceUsageType.Compute &&
            provider === ReservationsProvider.Aws
          }
        >
          <Then>
            <DataContainer
              hasBorder={false}
              marginBottom={theme.spacing.spacing02}
            >
              <SVGIcon
                name="trendDown"
                style={{
                  padding: `${theme.spacing.spacing02} ${theme.spacing.spacing02} ${theme.spacing.spacing02} 0`,
                }}
              />
              <DataItem
                label="Potential Monthly Savings"
                size="md"
                value={formatMoney(potentialMonthlySaving, currency)}
                mb={theme.spacing.spacing01}
              />
              <DataItem
                label="Potential Yearly Savings"
                size="md"
                value={formatMoney(potentialYearlySaving, currency)}
                mb={theme.spacing.spacing01}
              />
              <DataItem
                label="Savings Rate"
                size="md"
                value={percentageFormat(convertToPercent(savingsRate))}
                mb={theme.spacing.spacing01}
              />
            </DataContainer>
            <BorderSeparator />
            <DataContainer
              hasBorder={false}
              marginBottom={theme.spacing.spacing00}
            >
              <SVGIcon
                name="dollar"
                style={{
                  padding: `${theme.spacing.spacing02} ${theme.spacing.spacing02} ${theme.spacing.spacing02} 0`,
                }}
              />
              <DataItem
                label="Total Upfront Cost"
                size="md"
                value={formatMoney(upfrontCost, currency)}
                mb={theme.spacing.spacing01}
              />
              <Spacer width={theme.spacing.spacing01} />
              <DataItem
                label="Recurring Monthly Cost"
                size="md"
                value={formatMoney(recurringMonthlyCost, currency)}
                mb={theme.spacing.spacing01}
              />
              <DataItem value="" />
            </DataContainer>
          </Then>
          <Else>
            <DataContainer hasBorder marginBottom={theme.spacing.spacing02}>
              <DataItem
                label="Potential Monthly Savings"
                size="md"
                value={formatMoney(potentialMonthlySaving, currency)}
                mb={theme.spacing.spacing01}
                padding="0"
                grow={false}
                itemWidth="20%"
              />
              <DataItem
                label="Potential Yearly Savings"
                size="md"
                value={formatMoney(potentialYearlySaving, currency)}
                mb={theme.spacing.spacing01}
                grow={false}
                itemWidth="20%"
              />
              <DataItem
                label="Savings Rate"
                size="md"
                value={percentageFormat(convertToPercent(savingsRate))}
                mb={theme.spacing.spacing01}
                grow={false}
                itemWidth="20%"
              />
              <DataItem
                label="Total Upfront Cost"
                size="md"
                value={formatMoney(upfrontCost, currency)}
                mb={theme.spacing.spacing01}
                grow={false}
                itemWidth="20%"
              />
              <DataItem
                label="Recurring Monthly Cost"
                size="md"
                value={formatMoney(recurringMonthlyCost, currency)}
                mb={theme.spacing.spacing01}
                grow={false}
                itemWidth="20%"
              />
            </DataContainer>
          </Else>
        </If>
      </ItemWrapper>
    </FlexContainer>
  );
}
