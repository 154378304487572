/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { Text, theme } from "@nordcloud/gnui";

type Props = {
  subtitle: string;
  title: string;
  description: string;
};

export function TabWidget({ subtitle, title, description }: Props) {
  return (
    <>
      <Subtitle>
        <Text nowrap size="sm" mb={0} mr={theme.spacing.spacing02}>
          {subtitle}
        </Text>
      </Subtitle>
      <Title>
        <Text
          nowrap
          weight="medium"
          size="lg"
          mb={0}
          mr={theme.spacing.spacing02}
        >
          {title}
        </Text>
      </Title>
      <Description>
        <Text
          nowrap
          size="sm"
          color="text04"
          mb={0}
          mr={theme.spacing.spacing02}
        >
          {description}
        </Text>
      </Description>
    </>
  );
}

const Subtitle = styled.div`
  grid-area: subtitle;
`;

const Title = styled.div`
  grid-area: title;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const Description = styled.div`
  grid-area: description;
`;
