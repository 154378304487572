/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { SVGIcon, Table, theme } from "@nordcloud/gnui";

export const SVGIconArrow = styled(SVGIcon)<{ isRowOpen: boolean }>`
  transition: "transform .1s linear";
  transform: ${(props) =>
    props.isRowOpen ? "rotate(0deg)" : "rotate(-90deg)"};
`;

export const StyledTd = styled(Table.td)`
  text-align: left;
`;

export const StyledTr = styled(Table.tr)<{ show?: boolean }>`
  display: ${({ show }) => (show ? "table-row" : "none")};
  background: ${theme.color.background.ui03};

  &:last-child > td {
    padding-bottom: ${theme.spacing.spacing05};
  }
`;

export const TdWithMinWidth = styled(Table.td)`
  min-width: ${theme.spacing.spacing04};
  max-width: ${theme.spacing.spacing04};
  width: ${theme.spacing.spacing04};
  cursor: pointer;
`;

export const SubnetsSubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.spacing03};
  background-color: ${theme.color.background.ui01};
  border-top: 1px solid ${theme.color.border.border01};
  position: sticky;
  bottom: 0;
  margin-right: auto;
  margin-left: auto;
`;
