/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsAndCommitmentsLookbackPeriod,
  ReservationsProvider,
  useListReservationsAndCommitmentsCoverageDetailsQuery,
} from "~/generated/graphql";
import { UsageCoverageData } from "../types";

type DetailsProps = {
  resources: UsageCoverageData;
  offeringType?: ReservationsAndCommitmentsCoverageOfferingType;
  serviceUsageType?: ReservationsAndCommitmentsCoverageServiceUsageType;
  timePeriod?: ReservationsAndCommitmentsLookbackPeriod;
  provider: ReservationsProvider;
};

export function useGetCoverageDetailsData({
  resources,
  offeringType,
  serviceUsageType,
  timePeriod,
  provider,
}: DetailsProps) {
  const { data, loading } =
    useListReservationsAndCommitmentsCoverageDetailsQuery({
      variables: {
        region: resources.region ?? "",
        platform: resources.platform ?? "",
        resourceType: encodeURIComponent(resources.resource ?? ""),
        offeringType,
        serviceUsageType,
        lookbackPeriod: timePeriod,
        provider,
      },
      fetchPolicy: "network-only",
    });

  const RICoverageData =
    data?.listReservationsAndCommitmentsCoverageDetails?.resources?.map(
      (item) => ({
        totalOnDemandCost: item?.totalOnDemandCost ?? "0.00",
        coverageSavedCost: item?.coverageSavedCost ?? "0.00",
        potentialSavings: item.potentialSavings ?? "0.00",
        hasCoverageResourceList: false,
        hasRecommendations: false,
        riCostPercent: item?.riCostPercent ?? "0",
        spCostPercent: item?.spCostPercent ?? "0",
        onDemandCostPercent: item?.onDemandCostPercent ?? "100",
        resource: item?.resource ?? "",
        platform: item?.platform ?? "",
        region: item?.region ?? "",
        instanceType: item?.resource ?? "",
        provider: item?.provider ?? "",
      })
    );

  return {
    RICoverageData,
    loading,
  };
}
