/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { theme } from "@nordcloud/gnui";
import { DataItem } from "~/components/Data";
import { useCurrency } from "~/hooks";
import { formatMoney } from "~/tools";
import { generateLinks } from "../helpers";
import { ChartType, LinkParameters, TableData } from "../types";
import { chartTypesWithLinks } from "./consts";

type Props = {
  data: TableData[];
  chartTypeValue: ChartType;
  isEmptyData?: boolean;
  linkParameters?: LinkParameters;
};

export function TotalCostItems({
  data,
  chartTypeValue,
  isEmptyData = false,
  linkParameters,
}: Props) {
  const { currency } = useCurrency();

  return (
    <>
      {data.map((element: TableData) => (
        <div
          key={element.field}
          css={{
            paddingLeft: `${theme.spacing.spacing04}`,
          }}
        >
          <DataItem
            grow={false}
            weight="medium"
            size="lg"
            label={element.field?.toString()}
            value={isEmptyData ? "N/A" : formatMoney(element.total, currency)}
            padding="0"
            tooltipPosition="left"
            labelLink={
              containsLink(chartTypeValue)
                ? generateLinks(chartTypeValue, linkParameters, element)
                : undefined
            }
          />
        </div>
      ))}
    </>
  );
}

function containsLink(chartType: ChartType) {
  return chartTypesWithLinks.includes(chartType);
}
