/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { ChartType, Granularity } from "~/components";
import { costPerOrgUnitMock } from "../../hooks/mocks";
import { getChartGroups, mapChartDataCorrections } from "../helpers";

export function useEstateRecordsCostPerOrgUnit(granularity: Granularity) {
  const { data } = costPerOrgUnitMock;

  const mappedMonthlyData = mapChartDataCorrections(
    data?.chartMonthlyJob?.data
  );

  const costPerOrgUnitChartData = useMemo(
    () =>
      granularity === Granularity.DAYS
        ? mapChartDataCorrections(data?.chartJob?.data)
        : mappedMonthlyData,
    [data, granularity, mappedMonthlyData]
  );

  const costPerOrgUnitTotal = Number(data?.chartMonthlyJob.data.total ?? "0");

  const orgUnits = getChartGroups(
    mappedMonthlyData,
    ChartType.COST_PER_ORG_UNIT
  );

  return {
    costPerOrgUnitChartData,
    costPerOrgUnitTotal,
    orgUnits,
  };
}
