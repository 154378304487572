/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { ParentSize } from "@visx/responsive";
import { Else, If, Then, When } from "react-if";
import { Link } from "react-router-dom";
import {
  BrickLoader,
  FlexContainer,
  Select,
  Text,
  theme,
} from "@nordcloud/gnui";
import { LoaderWrap } from "~/components";
import { getFirstItem, isNotNil, Maybe, noop } from "~/tools";
import { getUrl } from "~/views/estate/helpers";
import { VolumeSelectionData } from "../types";
import {
  UtilizationData,
  UtilizationLegendItem,
  UtilizationTooltipItem,
} from "./types";
import { UtilizationChart } from "./UtilizationChart";
import { UtilizationLegend } from "./UtilizationLegend";

type Props = {
  seriesOneData: UtilizationData[];
  seriesTwoData?: UtilizationData[];
  legend: UtilizationLegendItem[];
  title: string;
  subtitle: string;
  emptyReason?: string;
  tooltip: UtilizationTooltipItem;
  timeTicks: string[];
  selectData?: VolumeSelectionData[];
  selectedData?: Maybe<string>;
  onSelectChanged?: (parameter: string) => void;
  isLoading?: boolean;
};

export function Utilization({
  seriesOneData,
  seriesTwoData,
  legend,
  title,
  emptyReason,
  subtitle,
  tooltip,
  timeTicks,
  selectData,
  selectedData,
  onSelectChanged,
  isLoading = false,
}: Props) {
  return (
    <FlexContainer
      direction="column"
      alignItems="start"
      css={{ minWidth: "1px" }}
    >
      <FlexContainer
        justifyContent="space-between"
        css={{ width: "100%", marginBottom: theme.spacing.spacing02 }}
      >
        <div>
          <Text weight="medium" mb={0} as="header">
            {title}
          </Text>
          <Text color={theme.color.text.text02} mb={0} size="xs">
            {subtitle}
          </Text>
        </div>
        <When condition={isNotNil(selectData)}>
          <SelectContext
            value={selectedData ?? ""}
            options={selectData ?? []}
            onChange={onSelectChanged ?? noop}
          />
        </When>
      </FlexContainer>
      <LoaderWrap
        loading={isLoading}
        Component={BrickLoader}
        css={{ height: "235px", marginTop: "20px" }}
      >
        <ParentSize>
          {(parent) => (
            <UtilizationChart
              parentWidth={parent.width}
              parentHeight={205}
              seriesOneData={seriesOneData}
              seriesTwoData={seriesTwoData}
              tooltip={tooltip}
              timeTicks={timeTicks}
              emptyReason={emptyReason}
            />
          )}
        </ParentSize>
      </LoaderWrap>
      <UtilizationLegend items={legend} />
    </FlexContainer>
  );
}

type SelectContextProps = {
  options: VolumeSelectionData[];
  value: string;
  onChange: (parameter: string) => void;
};

function SelectContext({ options, value, onChange }: SelectContextProps) {
  const formattedOptions = useMemo(
    () =>
      options?.map((data) => ({
        value: data.volumeId,
        label: data.volumeId,
        nid: data.volumeNid,
      })) ?? [],
    [options]
  );
  const defaultItem = getFirstItem(formattedOptions);
  const defaultLink = getUrl(
    defaultItem?.value ?? "",
    defaultItem?.nid ?? "",
    "ec2/volume"
  );

  return (
    <FlexContainer columnGap={theme.spacing.spacing02}>
      <Text mb={0}>Volume ID:</Text>
      <If condition={Array.isArray(options) && options.length > 1}>
        <Then>
          <Select
            options={formattedOptions}
            defaultValue={formattedOptions?.find(
              (item) => item.value === value
            )}
            styles={{
              control: (base) => ({
                ...base,
                width: "16rem",
              }),
            }}
            onChange={(option) => onChange(option?.value ?? "")}
          />
        </Then>
        <Else>
          <Link to={defaultLink}>{value}</Link>
        </Else>
      </If>
    </FlexContainer>
  );
}
