/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import {
  FlexContainer,
  theme,
  Text,
  Label,
  Input,
  Button,
} from "@nordcloud/gnui";
import {
  ExternalLink,
  FormGroup,
  FormHint,
  SquareBulletList,
} from "~/components";
import { SUCCESS_TEXT, ERROR_TEXT } from "~/constants";
import { showSuccess, showError } from "~/services/toast";
import { Provider } from "../../types";
import { IbmFormField } from "../constants";
import { useBatchUpdateIbmAccountCredentials } from "../hooks/useBatchUpdateIbmAccountCredentials";
import { UpdateHandlerVariables } from "../types";
import { ibmCredentialsSchema } from "../validators";

type Props = {
  onClose: () => void;
  nids: string[];
};

export function IbmUpdateCredentialsForm({ onClose, nids }: Props) {
  const [updateCredentials] = useBatchUpdateIbmAccountCredentials();
  const formMethods = useForm<UpdateHandlerVariables<Provider.Ibmcloud>>({
    resolver: yupResolver(ibmCredentialsSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const onSubmit = async ({
    apikey,
  }: UpdateHandlerVariables<Provider.Ibmcloud>) => {
    try {
      await updateCredentials(
        {
          apikey,
        },
        nids
      );
      showSuccess(SUCCESS_TEXT.accountCredentialsUpdated);
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form
        css={{ width: "100%" }}
        id="IBM-cred-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FlexContainer
          css={{ width: "100%" }}
          alignItems="flex-start"
          direction="column"
          gap={theme.spacing.spacing03}
        >
          <FormHint stepNumber={1}>
            <Text>
              Sign in to the{" "}
              <ExternalLink href="https://cloud.ibm.com/">
                IBM Cloud Platform
              </ExternalLink>
              , with the credentials of the main account.
            </Text>
          </FormHint>
          <FormHint stepNumber={2}>
            <Text>
              On the{" "}
              <ExternalLink href="https://cloud.ibm.com/iam/users">
                Access(IAM) Users
              </ExternalLink>
              , page, invite the proxy account.
            </Text>
          </FormHint>
          <FormHint stepNumber={3}>
            <Text>
              In a separate browser, sign in to{" "}
              <ExternalLink href="https://cloud.ibm.com/">
                IBM Cloud Platform
              </ExternalLink>{" "}
              with proxy account. Then on the{" "}
              <ExternalLink href="https://cloud.ibm.com/notifications">
                IBM Cloud Notication
              </ExternalLink>{" "}
              page, accept the invitation you sent from the main account.
            </Text>
          </FormHint>
          <FormHint stepNumber={4}>
            <FlexContainer direction="column" alignItems="flex-start">
              <Text>
                On the Users page of the main account, grant{" "}
                <Text weight="medium" tag="span">
                  Reader
                </Text>{" "}
                access and{" "}
                <Text weight="medium" tag="span">
                  Viewer
                </Text>{" "}
                access, to the proxy account in main account page
              </Text>
              <AccessProxyAccountSteps />
              <Text>
                As a result, on the{" "}
                <Text weight="medium" tag="span">
                  Access Policies
                </Text>{" "}
                tab, you now see multiple{" "}
                <Text weight="medium" tag="span">
                  Viewer
                </Text>{" "}
                roles.
              </Text>
            </FlexContainer>
          </FormHint>
          <FormHint stepNumber={5}>
            <Text>
              Switch to the page for the proxy account, and create the API Key.
              For more information, see:{" "}
              <ExternalLink href="https://cloud.ibm.com/iam/apikeys">
                API Keys document
              </ExternalLink>
              .
            </Text>
          </FormHint>
          <FormHint stepNumber={6}>
            <FlexContainer
              css={{ width: "100%" }}
              direction="column"
              alignItems="flex-start"
            >
              <Label
                required
                name="Provide The API Key From The Proxy Account"
              />
              <FormGroup error={errors[IbmFormField.API_KEY]}>
                <Input
                  {...register(IbmFormField.API_KEY)}
                  placeholder="e.g. XEogykkgnehnuMKZUEM-abFxjK7uCNF9FOdBR19-KgTMR"
                />
              </FormGroup>
            </FlexContainer>
          </FormHint>
        </FlexContainer>
        <FlexContainer gap={theme.spacing.spacing04}>
          <Button type="submit" icon="checkmark">
            Apply
          </Button>
          <Button severity="low" onClick={onClose}>
            Cancel
          </Button>
        </FlexContainer>
      </form>
    </FormProvider>
  );
}

function AccessProxyAccountSteps() {
  return (
    <SquareBulletList>
      <li>
        <Text>
          Select{" "}
          <Text weight="medium" tag="span">
            Manage
          </Text>
          , then{" "}
          <Text weight="medium" tag="span">
            Access(IAM)
          </Text>
          , and then click{" "}
          <Text weight="medium" tag="span">
            User
          </Text>{" "}
          from the left menu. Select{" "}
          <Text weight="medium" tag="span">
            proxy account
          </Text>
          , then click{" "}
          <Text weight="medium" tag="span">
            Access policies
          </Text>{" "}
          on the top tab, and then click{" "}
          <Text weight="medium" tag="span">
            Assign access
          </Text>
        </Text>
      </li>
      <li>
        <Text>
          On Assign access page, select{" "}
          <Text weight="medium" tag="span">
            IAM services
          </Text>
          , then select{" "}
          <Text weight="medium" tag="span">
            All Identity and Access enabled services
          </Text>
          . For{" "}
          <Text weight="medium" tag="span">
            How do you want to scope the access?
          </Text>
          , select{" "}
          <Text weight="medium" tag="span">
            All resources
          </Text>
          . Then select{" "}
          <Text weight="medium" tag="span">
            Viewer
          </Text>{" "}
          and{" "}
          <Text weight="medium" tag="span">
            Reader
          </Text>{" "}
          for{" "}
          <Text weight="medium" tag="span">
            Platform access
          </Text>
          ,{" "}
          <Text weight="medium" tag="span">
            Service access
          </Text>
          , and{" "}
          <Text weight="medium" tag="span">
            Resource group access
          </Text>
          . Then, click Add.
        </Text>
      </li>
      <li>
        <Text>
          Select{" "}
          <Text weight="medium" tag="span">
            Account Management
          </Text>
          , then{" "}
          <Text weight="medium" tag="span">
            All Account management Service
          </Text>
          , and then select Viewer access. Then, click Add.
        </Text>
      </li>
      <li>
        <Text>
          Select{" "}
          <Text weight="medium" tag="span">
            Cloud foundry
          </Text>
          , then select current organization, and then select{" "}
          <Text weight="medium" tag="span">
            Auditor
          </Text>{" "}
          access for the Organization role, and for the Space role. Click{" "}
          <Text weight="medium" tag="span">
            Add
          </Text>
          , then click{" "}
          <Text weight="medium" tag="span">
            Assign
          </Text>{" "}
          on the right.
        </Text>
      </li>
      <li>
        <Text>
          Select{" "}
          <Text weight="medium" tag="span">
            Class infrastructure
          </Text>
          , then click{" "}
          <Text weight="medium" tag="span">
            Manage infrastructure access
          </Text>{" "}
          page, and then select view only in Permission sets. Then, click{" "}
          <Text weight="medium" tag="span">
            Apply
          </Text>{" "}
          in the Permissions tag. Then, select all options in{" "}
          <Text weight="medium" tag="span">
            Select type
          </Text>
          , and select{" "}
          <Text weight="medium" tag="span">
            Enable future access
          </Text>{" "}
          in the Devices tag.
        </Text>
      </li>
    </SquareBulletList>
  );
}
