/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { EnvironmentType } from "~/generated/graphql";

export const ENV_TYPES = [
  {
    value: EnvironmentType.Production,
    label: "Production",
    icon: "production" as const,
  },
  { value: EnvironmentType.Staging, label: "Staging", icon: "qa" as const },
  {
    value: EnvironmentType.Development,
    label: "Development",
    icon: "settings" as const,
  },
  { value: EnvironmentType.Other, label: "Other", icon: "test" as const },
];
