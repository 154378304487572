/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { dateFormat } from "~/constants";
import { PreviousPeriod } from "./types";

export const MONTHLY_BUTTONS = [
  {
    name: "3M",
    labelText: "3 Months",
    value: "3",
    enumValue: PreviousPeriod.PREV_3_MONTHS,
  },
  {
    name: "6M",
    labelText: "6 Months",
    value: "6",
    enumValue: PreviousPeriod.PREV_6_MONTHS,
  },
  {
    name: "12M",
    labelText: "12 Months",
    value: "12",
    enumValue: PreviousPeriod.PREV_12_MONTHS,
  },
];

export const YEARLY_BUTTONS = [
  {
    name: "0Y",
    labelText: dayjs().format(dateFormat.year),
    value: "0",
    enumValue: PreviousPeriod.THIS_YEAR,
  },
  {
    name: "1Y",
    labelText: dayjs().subtract(1, "year").format(dateFormat.year),
    value: "1",
    enumValue: PreviousPeriod.PREV_YEAR,
  },
  {
    name: "2Y",
    labelText: dayjs().subtract(2, "year").format(dateFormat.year),
    value: "2",
    enumValue: PreviousPeriod.PREV_2_YEAR,
  },
];
