/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  AddSearchPresetInput,
  useAddSearchPresetMutation,
} from "~/generated/graphql";
import { ERROR_TEXT } from "~/constants";
import { showError, showSuccess } from "~/services/toast";
import { generateActionSuccessText } from "~/tools";

export function useAddSearchPreset() {
  const [addSearchPreset] = useAddSearchPresetMutation();

  return async (input: AddSearchPresetInput) => {
    try {
      const response = await addSearchPreset({
        variables: {
          input,
        },
        // Remove whole queries from cache, as we can have multitude of variables attached to them
        // so manual update and refetch are not feasible
        update: (cache) => cache.evict({ fieldName: "searchPresets" }),
      });
      if (response) {
        showSuccess(generateActionSuccessText(input.name)()("added")());
      }
    } catch (error) {
      showError(error.message ?? ERROR_TEXT.tryAgainDefault);
      throw error;
    }
  };
}
