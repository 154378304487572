/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const SETUP_NOTES = [
  "Use the same bucket name as in step one",
  "In CUR settings, include Resource IDs",
  "Set data refresh settings",
  "Use daily granularity",
  "Do not set report versioning",
  'Set the Path Prefix to CUR (no leading "/")',
  "Enable Redshift data integration with .gz compression",
];

export const cloudFormationStackLink =
  "https://docs.mca.nordcloudapp.com/docs/visibility/cloud-accounts/aws/add-billing-accounts#create-cost-and-usage-report-cur";

export const cloudFormationStackLinkLite =
  "https://docs.mca-lite.nordcloudapp.com/docs/cloud-accounts/aws/add-billing-accounts#create-cost-and-usage-report-cur";

export const costAndUsageReportLink =
  "https://docs.aws.amazon.com/cur/latest/userguide/cur-create.html";

export const awsAutoFormDefaultValues = {
  region: "us-east-1",
  reportPathPrefix: "CUR",
  reportName: "CUR-Daily",
};

export const azureEnrollmentAccessKeyGuideLink =
  "https://learn.microsoft.com/en-us/azure/cost-management-billing/manage/enterprise-rest-apis#api-key-generation";

export const azureEnrollmentNumberGuideLink =
  "https://learn.microsoft.com/en-us/azure/cost-management-billing/manage/direct-ea-administration#to-activate-an-enrollment";
