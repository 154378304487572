/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Link } from "react-router-dom";
import { Text } from "@nordcloud/gnui";
import { ROUTES } from "~/routing/routes";
import { isEmpty } from "~/tools";
import { NotificationItem } from "./NotificationItem";
import { NotificationItemWrapper, NotificationsListWrapper } from "./styles";
import { Notification } from "./types";

type Props = {
  notifications: Notification[];
  unread: number;
};

export function NotificationsList({ notifications, unread }: Props) {
  return (
    <NotificationsListWrapper>
      {notifications.map((notification) => (
        <NotificationItem key={notification.id} notification={notification} />
      ))}
      <When condition={isEmpty(notifications)}>
        <NotificationItemWrapper>
          <Text mb={0}>No recent unread notifications.</Text>
        </NotificationItemWrapper>
      </When>
      <When condition={unread > 1}>
        <NotificationItemWrapper className="show-all-link">
          <Link
            to={ROUTES.notifications.index}
            css={{
              width: "50%",
              cursor: "pointer",
              "&:hover": { textDecoration: "none" },
            }}
          >
            Show all Notifications
          </Link>
        </NotificationItemWrapper>
      </When>
    </NotificationsListWrapper>
  );
}
