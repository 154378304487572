/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Button, FlexContainer, Input, Label, theme } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { ContactsSelector } from "~/components/Inputs/ContactsSelector/ContactsSelector";
import { ERROR_TEXT, SUCCESS_TEXT } from "~/constants";
import { showError, showSuccess } from "~/services/toast";
import {
  CloudProviderType,
  Provider,
  ProviderTab,
  VMWareFormFields,
} from "../../types";
import { VMWareFormData } from "./types";
import { useAddAccount } from "./useAddAccount";
import { addVMWareSchema } from "./validators";

type Props = {
  closeSidebar: () => void;
};

export function VmWareSidebarContent({ closeSidebar }: Props) {
  const formMethods = useForm<VMWareFormData>({
    resolver: yupResolver(addVMWareSchema),
  });

  const {
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = formMethods;

  const { addAccount, loading } = useAddAccount(ProviderTab.VMWARE);

  const onAddAccountSubmit = async (formData: VMWareFormData) => {
    if (loading) {
      return;
    }

    try {
      await addAccount({
        variables: {
          input: {
            name: formData.name,
            ownerId: formData.owner,
            provider: Provider.Hybrid,
            providerType: CloudProviderType.Vmware,
          },
        },
      });

      showSuccess(SUCCESS_TEXT.accountAdded);
      closeSidebar();
    } catch {
      showError(ERROR_TEXT.failedAddingAccount);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form id="add-vmware-form" onSubmit={handleSubmit(onAddAccountSubmit)}>
        <FormGroup error={errors[VMWareFormFields.NAME]}>
          <Label required htmlFor={VMWareFormFields.NAME} name="VMWare Name" />
          <Input
            placeholder="e.g., vm name"
            {...register(VMWareFormFields.NAME)}
          />
        </FormGroup>
        <FormGroup error={errors[VMWareFormFields.OWNER] ?? []}>
          <Label
            required
            htmlFor={VMWareFormFields.OWNER}
            name="VMWare Owner"
          />
          <ContactsSelector
            onChange={(selectedContact) =>
              setValue(VMWareFormFields.OWNER, selectedContact)
            }
          />
        </FormGroup>
        <FlexContainer columnGap={theme.spacing.spacing03}>
          <Button
            icon="checkmark"
            initialState={loading ? "loading" : ""}
            css={{ border: 0 }}
            type="submit"
            form="add-vmware-form"
          >
            Apply
          </Button>
          <Button severity="low" type="button" onClick={closeSidebar}>
            Cancel
          </Button>
        </FlexContainer>
      </form>
    </FormProvider>
  );
}
