/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useFormContext } from "react-hook-form";
import { Checkbox, FlexContainer, Label, Text, theme } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { WorkflowFormData } from "~/views/workflows/types";
import { PreviewButton } from "../../EmailSettingsForm/PreviewButton";
import { parseFieldErrors } from "./utils";

type Props = {
  isReadMode: boolean;
  onPreviewClick: () => void;
};

export function CostsOptionsDataTypeField({
  isReadMode,
  onPreviewClick,
}: Props) {
  const {
    register,
    formState: { errors },
  } = useFormContext<WorkflowFormData>();
  const { costsOptionsError } = parseFieldErrors(errors);

  return (
    <>
      <FlexContainer
        paddingBottom={theme.spacing.spacing02}
        alignItems="center"
        justifyContent="space-between"
      >
        <Label
          css={{ marginBottom: theme.spacing.spacing00, textTransform: "none" }}
          name="Select Data Type(s)"
          required={!isReadMode}
        />
        <PreviewButton type="button" size="sm" onClick={() => onPreviewClick()}>
          Sample Message
        </PreviewButton>
      </FlexContainer>
      <Text size="sm" color={theme.color.text.text02}>
        Costs for each Business Context, Organizational Unit, application and
        environment will be combined. Please select one or more data types which
        will be located in a ServiceNow field.
      </Text>
      <FormGroup error={costsOptionsError}>
        <Checkbox
          disabled={isReadMode}
          {...register("serviceNow.costsOptions.totalMonthlyCost")}
          labelText="Total Monthly Cost"
        />
        <Checkbox
          disabled={isReadMode}
          {...register("serviceNow.costsOptions.totalMonthlyForecast")}
          labelText="Total Monthly Forecast"
        />
        <Checkbox
          disabled={isReadMode}
          {...register("serviceNow.costsOptions.costsByProviders")}
          labelText="Cost By Providers"
        />
        <Checkbox
          disabled={isReadMode}
          {...register("serviceNow.costsOptions.costsByCategories")}
          labelText="Cost By Categories"
        />
      </FormGroup>
    </>
  );
}
