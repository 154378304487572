/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Provider } from "~/generated/graphql";
import { isString, formatCustomDate, Maybe, MixedValue } from "~/tools";

export function isPodsVisible(providerType: Maybe<string> | undefined) {
  return providerType?.includes("Node");
}

export function isServicesVisible(providerType: Maybe<string> | undefined) {
  return providerType?.includes("project");
}

export function isRelatedResourcesForGCPVisible(
  providerType: Maybe<string> | undefined
) {
  return providerType?.includes("service");
}

export function isSkuVisible(
  provider: Maybe<string>,
  providerType: Maybe<string>
) {
  return provider?.includes(Provider.Gcp) && !providerType?.includes("project");
}

export function checkProviderAndProviderType(
  providerType: Maybe<string> | undefined
) {
  return /(\/Node|\/Pod)/.test(providerType ?? "");
}
/*
`generateDate` is used to format date for K8s statuses containing  `status-YYYYMMDDTHHmmssZ` format 
*/
export function generateDate(row: MixedValue) {
  if (isString(row)) {
    const statusDateRegx =
      /(?<year>\d{4})(?<month>\d{2})(?<day>\d{2})(t)(?<hour>\d{2})(?<minute>\d{2})(?<second>\d{2})/gi;
    const date = row?.match(statusDateRegx)?.toString() ?? "";

    if (date !== "") {
      const statusReplaceRegx = /(\d{4})(\d{2})(\d{2})(T)(\d{2})(\d{2})(\d{2})/;
      const extractedDate = date.replace(
        statusReplaceRegx,
        "$1-$2-$3T$5:$6:$7Z"
      );

      return formatCustomDate(extractedDate);
    }
  }

  return undefined;
}
