/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { Else, If, Then } from "react-if";
import { FlexContainer, Spacer, theme } from "@nordcloud/gnui";
import { UpdateTurbonomicIntegrationPluginInput } from "~/generated/graphql";
import { Maybe, noop } from "~/tools";
import { DetailsTitle } from "../DetailsTitle";
import { EditModeContents } from "./EditModeContents";
import { ReadModeContents } from "./ReadModeContents";

type Props = {
  title: string;
  propertyName: string;
  value?: Maybe<string>;
  onSubmit?: SubmitHandler<UpdateTurbonomicIntegrationPluginInput>;
  toggleEditMode?: (propertyName: string) => void;
  disableEdit?: boolean;
  isPassword?: boolean;
};

export function DetailsRow({
  title,
  propertyName,
  value,
  onSubmit = noop,
  toggleEditMode = noop,
  disableEdit = false,
  isPassword = false,
}: Props) {
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const submitForm = async (data: UpdateTurbonomicIntegrationPluginInput) => {
    setIsLoading(true);
    await onSubmit(data);
    setIsEditMode(false);
    toggleEditMode(propertyName);
    setIsLoading(false);
  };

  return (
    <>
      <FlexContainer alignItems="center">
        <DetailsTitle>{title}</DetailsTitle>
        <If condition={isEditMode}>
          <Then>
            <EditModeContents
              propertyName={propertyName}
              value={value}
              isPassword={isPassword}
              isLoading={isLoading}
              toggleEditMode={toggleEditMode}
              setIsEditMode={setIsEditMode}
              submitForm={submitForm}
            />
          </Then>
          <Else>
            <ReadModeContents
              propertyName={propertyName}
              value={value}
              disableEdit={disableEdit}
              toggleEditMode={toggleEditMode}
              setIsEditMode={setIsEditMode}
            />
          </Else>
        </If>
      </FlexContainer>
      <Spacer height={theme.spacing.spacing04} />
    </>
  );
}
