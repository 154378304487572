/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { Button, FlexContainer, theme } from "@nordcloud/gnui";

type Props = {
  limitValues: number[];
  selectedLimit: number;
  setLimit: (limit: number) => void;
};

export function WorkflowHistoryLimitButtons({
  limitValues,
  selectedLimit,
  setLimit,
}: Props) {
  return (
    <FlexContainer justifyContent="flex-end" data-testid="limit-buttons">
      <span
        css={{
          marginRight: theme.spacing.spacing04,
          color: theme.color.text.text02,
        }}
      >
        Show:
      </span>
      {limitValues.map((limit) => (
        <LimitSelectionButton
          key={limit}
          isSelected={selectedLimit === limit}
          type="button"
          onClick={() => {
            setLimit(limit);
          }}
        >
          {limit}
        </LimitSelectionButton>
      ))}
    </FlexContainer>
  );
}

const LimitSelectionButton = styled(Button)<{ isSelected: boolean }>`
  margin-right: ${theme.spacing.spacing04};
  background-color: ${(props) =>
    props.isSelected
      ? theme.color.interactive.primary
      : theme.color.background.ui03};
  color: ${(props) =>
    props.isSelected ? theme.color.text.text04 : theme.color.text.text01};
  &:hover {
    color: ${(props) =>
      props.isSelected ? theme.color.text.text03 : theme.color.text.text01};
    background-color: ${(props) =>
      props.isSelected
        ? theme.color.interactive.primaryHover
        : theme.color.interactive.secondaryHover};
  }
  &:last-child {
    margin-right: 0;
  }
`;
