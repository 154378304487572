/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Cell, Row } from "@tanstack/react-table";
import dayjs from "dayjs";
import styled from "styled-components";
import { Button, ExtendedPopover, Text, theme } from "@nordcloud/gnui";
import { ACTION, PopoverControls } from "~/components";
import { dateFormat } from "~/constants";
import {
  Currency,
  FormatMoney,
  getFirstItem,
  isNil,
  isNotEmpty,
  isNotNil,
} from "~/tools";
import { Threshold, ThresholdGranularity } from "./types";

type Props = {
  onRemoveHandler: (id: string, name: string) => void;
  currency: Currency;
};

export function ThresholdsListColumns({ onRemoveHandler, currency }: Props) {
  const popoverActions = (row: Row<Threshold>) => [
    {
      label: "Edit Threshold",
      action: ACTION.EDIT,
      onClick: () => {},
    },
    {
      label: "Delete Threshold",
      action: ACTION.DELETE,
      onClick: () => {
        if (isNotNil(row.original)) {
          onRemoveHandler(row.original?.id, row.original?.name);
        }
      },
    },
  ];

  return [
    {
      id: "name",
      accessorKey: "name",
      header: <div css={{ minWidth: "10rem" }}>Name</div>,
      cell: ({ row }: Cell<Threshold>) => {
        return <span>{row.original?.name}</span>;
      },
    },
    {
      id: "data",
      header: "Data",
      cell: ({ row }: Cell<Threshold>) => {
        const accounts = row.original?.accounts.join(", ") ?? "";
        const services = row.original?.services.join(", ") ?? "";
        const providers = row.original?.providers.join(", ") ?? "";

        const result = [accounts, services, providers]
          .filter(isNotEmpty)
          .join(", ");

        return <span>{result}</span>;
      },
    },
    {
      id: "recipients",
      header: "Recipients",
      cell: ({ row }: Cell<Threshold>) => {
        if (row.original?.userRecipients.length === 1) {
          return getFirstItem(row.original?.userRecipients);
        }
        return (
          <ExtendedPopover
            placement="top"
            content={
              <RecipientsWrapper>
                <Text weight="medium">Recipients</Text>
                {row.original?.userRecipients.map((recipient) => (
                  <Text key={recipient} mb={theme.spacing.spacing01}>
                    {recipient}
                  </Text>
                ))}
              </RecipientsWrapper>
            }
            trigger={
              <span
                css={{
                  textDecoration: "underline dashed 1px",
                  textUnderlineOffset: theme.spacing.spacing01,
                }}
              >
                {`${row.original?.userRecipients.length} Recipients`}
              </span>
            }
          />
        );
      },
    },
    {
      id: "amountGranularity",
      header: "Amount / Granularity",
      cell: ({ row }: Cell<Threshold>) => {
        const amount = row.original?.thresholdAmount;
        const granularity = formatGranularity(row.original?.granularity);

        if (isNil(amount) || isNil(granularity)) {
          return "-";
        }

        return (
          <span>
            <Text weight="medium" tag="span">
              <FormatMoney value={amount} currency={currency} />
            </Text>{" "}
            / {granularity}
          </span>
        );
      },
    },
    {
      id: "createdDate",
      accessorKey: "createdDate",
      header: "Creation Date",
      cell: ({ row }: Cell<Threshold>) => {
        return (
          <span>
            {dayjs(row.original?.createdDate).format(dateFormat.fullDateShort)}
          </span>
        );
      },
    },
    {
      id: "actions",
      header: "",
      meta: { width: "2rem" },
      cell: ({ row }: Cell<Threshold>) => {
        return (
          <PopoverControls
            items={popoverActions(row)}
            trigger={<Button severity="low" icon="menu" size="md" />}
          />
        );
      },
    },
  ];
}

const RecipientsWrapper = styled.div`
  background-color: ${theme.color.background.ui01};
  border-radius: ${theme.radiusDefault};
  border: solid 1px ${theme.color.background.ui04};
  box-shadow: rgb(31 30 47 / 10%) 12px 0 22px;
  padding: ${theme.spacing.spacing02} ${theme.spacing.spacing04};

  display: flex;
  flex-direction: column;
`;

function formatGranularity(granularity: ThresholdGranularity | undefined) {
  if (granularity === ThresholdGranularity.Daily) {
    return "Daily";
  }
  if (granularity === ThresholdGranularity.Monthly) {
    return "Monthly";
  }
}
