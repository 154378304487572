/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo, useState } from "react";
import { Box, ModalConfirm, theme } from "@nordcloud/gnui";
import { useGetWorkflowListQuery } from "~/generated/graphql";
import {
  ReactTableResizeable,
  UniversalWrap,
  GlobalSpinner,
  DataDisplayWrapper,
  UserErrorMessageHTML,
  TableLoader,
} from "~/components";
import { isNotEmpty } from "~/tools";
import {
  useActivateWorkflowAction,
  useExecuteWorkflowAction,
  useRemoveWorkflowAction,
  useCloneWorkflowAction,
  useWorkflowsListSort,
} from "../hooks";
import { CloneWorkflowSidebar } from "../WorkflowDetails/CloneWorkflowSidebar";
import { WorkflowsListColumns } from "./WorkflowsListColumns";
import { WorkflowListSort } from "./WorkflowsListSort";

export function WorkflowsList() {
  const [userError, setUserError] = useState<JSX.Element | undefined>();
  const { sortField, sortOrder, handleSortFieldChange, handleSortOrderChange } =
    useWorkflowsListSort();

  const executeAction = useExecuteWorkflowAction();
  const activateAction = useActivateWorkflowAction(setUserError);
  const removeAction = useRemoveWorkflowAction();
  const cloneAction = useCloneWorkflowAction(setUserError);

  const columns = useMemo(
    () =>
      WorkflowsListColumns({
        executeAction,
        activateAction,
        removeAction,
        cloneAction,
      }),
    [executeAction, activateAction, removeAction, cloneAction]
  );

  const {
    data,
    loading,
    error: queryError,
  } = useGetWorkflowListQuery({
    variables: {
      field: sortField,
      order: sortOrder,
    },
    fetchPolicy: "network-only",
  });

  const isExecuting =
    executeAction.loading ||
    activateAction.loading ||
    removeAction.loading ||
    cloneAction.loading;

  const tableData = data?.workflows ?? [];

  return (
    <>
      <GlobalSpinner loading={isExecuting} />
      <UserErrorMessageHTML
        html={userError}
        onClick={() => setUserError(undefined)}
      />
      <Box boxStyle="lightGrey" spacing="spacing03">
        <WorkflowListSort
          sortField={sortField}
          sortOrder={sortOrder}
          onSortFieldChange={handleSortFieldChange}
          onSortOrderChange={handleSortOrderChange}
        />
      </Box>
      <UniversalWrap
        loaderProps={{
          loading,
          Component: Loader,
        }}
        errorProps={{
          error: queryError,
        }}
      >
        <DataDisplayWrapper hasData={isNotEmpty(tableData)}>
          <ReactTableResizeable striped columns={columns} data={tableData} />
        </DataDisplayWrapper>
      </UniversalWrap>
      <ModalConfirm {...executeAction.modalProps}>
        {executeAction.contentText}
      </ModalConfirm>
      <ModalConfirm {...activateAction.modalProps}>
        {activateAction.contentText}
      </ModalConfirm>
      <ModalConfirm {...removeAction.modalProps}>
        {removeAction.contentText}
      </ModalConfirm>
      <CloneWorkflowSidebar {...cloneAction.modalProps} />
    </>
  );
}

function Loader() {
  return (
    <TableLoader
      gapY={10}
      gapX={6}
      css={{
        marginTop: theme.spacing.spacing08,
      }}
      rows={[
        {
          count: 8,
          height: 80,
          items: [
            {
              widthPercent: 28,
            },
            {
              widthPercent: 28,
            },
            {
              widthPercent: 20,
            },
            {
              widthPercent: 22,
            },
          ],
        },
      ]}
    />
  );
}
