/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Case, Default, Else, If, Switch, Then, When } from "react-if";
import { Text } from "@nordcloud/gnui";
import { Details, NoData } from "~/components";
import { DetailData } from "~/components/Details/Detail";
import { ResourceLifecycleDates } from "~/tools";
import { KEY_VALUE_COLUMNS } from "../Columns/KeyValueColumns";
import { DetailsList, KUBERNETES_TABLE_CONTENT } from "./DetailsList";
import { KUBERNETES_STATUS_COLUMNS } from "./KubernetesStatusColumns";
import { NODE_DETAILS_COLUMNS } from "./NodeDetailsColums";
import { ResourceLifeSpan } from "./ResourceLifeSpan";

const POD_TAB_LABELS = {
  status: "Pod Status",
  details: "Pod Details",
};

const NODE_TAB_LABELS = {
  capacity: "",
  nodeInfo: "Node Info",
};

type Props = {
  data: DetailData[];
  showMetaDetails: boolean;
  resourceType: string;
  resourceLifecycleDates: ResourceLifecycleDates | undefined;
};

export function DetailsTabContents({
  data,
  resourceType,
  showMetaDetails,
  resourceLifecycleDates,
}: Props) {
  return (
    <>
      <When
        condition={
          !resourceType.includes("Pod") && resourceLifecycleDates !== null
        }
      >
        <ResourceLifeSpan
          data={data}
          resourceLifecycleDates={resourceLifecycleDates}
        />
      </When>
      <If condition={showMetaDetails}>
        <Then>
          <Switch>
            <Case condition={resourceType.includes("Node")}>
              <DetailsList
                metadata={data}
                label={NODE_TAB_LABELS.capacity}
                columns={NODE_DETAILS_COLUMNS}
                tableContent={KUBERNETES_TABLE_CONTENT.nodeCapacity}
              />
              <DetailsList
                metadata={data}
                label={NODE_TAB_LABELS.nodeInfo}
                columns={KEY_VALUE_COLUMNS}
                tableContent={KUBERNETES_TABLE_CONTENT.nodeInfo}
              />
            </Case>
            <Case condition={resourceType.includes("Pod")}>
              <DetailsList
                metadata={data}
                label={POD_TAB_LABELS.status}
                columns={KUBERNETES_STATUS_COLUMNS}
                tableContent={KUBERNETES_TABLE_CONTENT.podStatus}
              />
              <DetailsList
                metadata={data}
                label={POD_TAB_LABELS.details}
                columns={KEY_VALUE_COLUMNS}
                tableContent={KUBERNETES_TABLE_CONTENT.podInfo}
              />
            </Case>
            <Default>
              <Details details={data} />
            </Default>
          </Switch>
        </Then>
        <Else>
          <NoData
            message={
              <Text weight="regular">There is no metadata available</Text>
            }
          />
        </Else>
      </If>
    </>
  );
}
