/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ButtonProps, SVGIconProps } from "@nordcloud/gnui";
import { CloudProviderType, AccountV2Query } from "~/generated/graphql";
import { getProviderTooltip } from "~/hooks/useConsoleUrl/utils";
import { getCustomer } from "~/services/customers";
import { Provider } from "~/tests/types";
import { AccountStatusIcon } from "../AccountStatusIcon";
import { getIconName } from "../AccountTypeIcon";
import {
  DEPLOYMENT_DESCRIPTOR,
  METRICS_EXPORTER,
  PROMETHEUS_DEPLOYMENT,
  VMWARE_CONFIG_FILE,
} from "../constants";
import { FILE_FORMAT_TYPE } from "../DownloadDescriptorButton";
import { AccountOptions } from "../types";
import { getAccountUrl } from "../utils";

export const getProvider = (account: AccountV2Query["accountV2"]) => {
  return account?.provider;
};

export const getProviderType = (account?: AccountV2Query["accountV2"]) => {
  return account?.providerType ?? "";
};

export const getStatus = (account?: AccountV2Query["accountV2"]) => {
  return account?.status ?? "";
};

export const getIcon = (account?: AccountV2Query["accountV2"]) => {
  const providerType = getProviderType(account);
  return (
    getIconName(
      account?.provider,
      providerType === "" ? undefined : providerType
    ) ?? "statusError"
  );
};

export const getIdDetail = (
  accountV2: AccountV2Query["accountV2"],
  idColumn?: AccountOptions["idColumn"],
  provider?: Provider | ""
) =>
  idColumn
    ? [
        {
          label: idColumn?.label || "",
          value: idColumn?.getValue
            ? idColumn?.getValue(accountV2)
            : accountV2?.providerId,
          url: getAccountUrl(accountV2?.providerId ?? "", provider || ""),
          urlTooltip: getProviderTooltip(accountV2?.provider),
          provider: accountV2?.provider,
        },
      ]
    : [];

export const showDownloadDescriptorButton = (
  account?: AccountV2Query["accountV2"]
) => {
  const allowedProviders: (Provider | "")[] = [
    Provider.Kubernetes,
    Provider.Openshift,
    Provider.Vmware,
  ];
  const allowedProviderTypes: (CloudProviderType | "")[] = [
    CloudProviderType.Kubernetes,
    CloudProviderType.Openshift,
    CloudProviderType.Vmware,
  ];

  const provider = account?.provider ?? "";
  const providerType = account?.providerType ?? "";
  const status = account?.status;

  return (
    (allowedProviders.includes(provider) ||
      allowedProviderTypes.includes(providerType)) &&
    status !== "discovered"
  );
};

export const isVMWare = (account?: AccountV2Query["accountV2"]) =>
  getProvider(account) === Provider.Vmware ||
  getProviderType(account) === CloudProviderType.Vmware;

const downloadDeployProps = (account?: AccountV2Query["accountV2"]) => {
  const metadata = JSON.parse(account?.metadata ?? "{}");
  const accountId = account?.id;
  const customer = getCustomer();

  return isVMWare(account)
    ? new Map([
        [`<CUSTOMER_ID>`, customer?.id ?? ""],
        [`<ACCOUNT_ID>`, accountId],
        [`<STORAGE_NAME>`, metadata?.storageName],
        [`<SAS_TOKEN>`, metadata?.sasToken],
      ])
    : new Map([
        [`<INSERT_SAS_TOKEN>`, metadata?.sasTokens?.primary],
        [`<INSERT_QUEUE_NAME>`, metadata?.queueName],
        [`<INSERT_SCANNER_KEY>`, metadata?.scannerKey],
        [`<INSERT_CUSTOMER_ID>`, customer?.id ?? ""],
        [`<INSERT_CLOUD_ACCOUNT>`, accountId],
        [`<INSERT_PLATFORM>`, getProviderType(account)],
        [`<INSERT_PROVIDER>`, getProvider(account)],
        [`<INSERT_NAMESPACE>`, `nordcloud-scanner`],
        [`<INSERT_STORAGE_NAME>`, metadata?.storage?.storageName],
        [`<INSERT_STORAGE_SAS_TOKEN>`, metadata?.storage?.sasToken],
      ]);
};

export const getDownloadButtonProps = (
  account?: AccountV2Query["accountV2"],
  buttonOptions?: {
    buttonName?: string;
    severity?: ButtonProps["severity"];
    icon?: SVGIconProps["name"];
  }
) => {
  const isDeployMetrics = isDeploymentWithMetrics(account);
  const isVMWareAccount = isVMWare(account);
  const defaultText = isVMWareAccount ? "Config File" : "Deployment YAML";
  const DEPLOYMENT_KUBERNETES = isDeployMetrics
    ? DEPLOYMENT_DESCRIPTOR.concat(METRICS_EXPORTER, PROMETHEUS_DEPLOYMENT)
    : DEPLOYMENT_DESCRIPTOR;

  return {
    templateFile: isVMWareAccount ? VMWARE_CONFIG_FILE : DEPLOYMENT_KUBERNETES,
    propsMap: downloadDeployProps(account),
    fileFormat: isVMWareAccount ? FILE_FORMAT_TYPE.JSON : FILE_FORMAT_TYPE.YAML,
    icon: buttonOptions?.icon,
    severity: buttonOptions?.severity,
    buttonName: buttonOptions?.buttonName
      ? buttonOptions.buttonName
      : defaultText,
    fileName: isVMWareAccount ? "config" : account?.name,
  };
};

const isDeploymentWithMetrics = (
  account?: AccountV2Query["accountV2"]
): boolean => {
  const metadata = JSON.parse(account?.metadata ?? "{}");

  return metadata?.storage !== undefined;
};

type ActivityIconProps = {
  provider?: Provider;
  providerType?: CloudProviderType;
  activated?: boolean;
  status?: string;
};

export function ActivityIcon({
  provider,
  providerType,
  activated,
  status,
}: ActivityIconProps) {
  if (
    provider === undefined ||
    activated === undefined ||
    status === undefined
  ) {
    return <></>;
  }

  return (
    <AccountStatusIcon
      provider={provider}
      providerType={providerType}
      activated={activated}
      status={status}
    />
  );
}
