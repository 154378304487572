import { datadogLogs, Logger } from "@datadog/browser-logs";
import { APP_ENV } from "~/config";

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    DD_LOGS?: Logger;
  }
}

datadogLogs.init({
  clientToken: String(process.env.REACT_APP_DD_RUM_CLIENT),
  service: "overwatch",
  site: "datadoghq.com",
  version: "1.0.0",
  env: APP_ENV,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  telemetrySampleRate: 0,
  forwardConsoleLogs: ["warn", "error"],
  beforeSend: (log) => {
    if (log.http && log.http.status_code === 404) {
      return false;
    }
  },
});

export const logger = datadogLogs.logger;

export { datadogLogs } from "@datadog/browser-logs";
