/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import {
  useBusinessContextsQuery,
  useCloudAccountsQuery,
} from "~/generated/graphql";
import {
  notificationLimit,
  useNotificationsAmount,
} from "~/context/Notifications";
import { ACTIONS, useAccess } from "~/services/auth";
import { CloudAccountStatus } from "~/tools";
import { getItems } from "./config";
import { useGetProviderAccountsCount } from "./submenus";

export function useGetMenuItems() {
  const { unread } = useNotificationsAmount();
  const { checkAccess } = useAccess();
  const { hasReservationsMenu } = useGetProviderAccountsCount();
  const hasAccountWarning = useCloudAccountsWarning();
  const notificationsConfig = useMemo(
    () => ({
      unread,
      limit: notificationLimit,
    }),
    [unread]
  );

  const { data } = useBusinessContextsQuery({
    variables: { limit: 100, page: 0 },
    skip: !checkAccess(ACTIONS.visitBusinessContexts),
  });

  const businessContexts = useMemo(
    () =>
      (data?.businessContexts?.businessContexts ?? [])
        .map(({ id, name, color, isPrimary }) => ({
          id: id ?? "",
          name: name ?? "",
          color: color ?? "",
          isPrimary: isPrimary ?? false,
        }))
        .filter(({ id }) => id),
    [data]
  );

  const items = useMemo(
    () =>
      getItems({
        businessContexts,
        notificationsConfig,
        isReservationsMenuItem: hasReservationsMenu,
        hasAccountWarning,
      }),
    [
      businessContexts,
      notificationsConfig,
      hasReservationsMenu,
      hasAccountWarning,
    ]
  );

  return { items };
}

function useCloudAccountsWarning() {
  const { data, error, loading } = useCloudAccountsQuery({
    variables: {
      limit: 1,
      filter: {
        cloudStatusV2: [
          CloudAccountStatus.NoAccess,
          CloudAccountStatus.BillingOnly,
        ],
      },
    },
  });

  if (error || loading) {
    return false;
  }

  return (data?.cloudAccounts?.count ?? 0) > 0;
}
