/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import dayjs from "dayjs";
import { useEnvironmentBudgetQuery } from "~/generated/graphql";
import {
  Period,
  TableData,
  TimePoint,
} from "~/components/Charts/CostAnalysis/types";
import {
  calculateTotalBudget,
  mapCostChartData,
  mapPeriodForCostChartData,
} from "~/components/Charts/CostAnalysis/utils";
import { dateFormat } from "~/constants";
import { isNotEmpty } from "~/tools";

type Props = {
  envId: string;
  currentYear: string;
  period: Period;
  envAccumulatedCostBudgetChartData: TimePoint[];
  startDate: string;
  endDate: string;
  envAccumulatedCostTimePoints: TimePoint[];
  envAccumulatedCostTotal: number;
};

export function useEnvCostVersusBudget({
  envId,
  currentYear,
  period,
  envAccumulatedCostBudgetChartData,
  startDate,
  endDate,
  envAccumulatedCostTimePoints,
  envAccumulatedCostTotal,
}: Props) {
  const { data, loading, error } = useEnvironmentBudgetQuery({
    variables: {
      id: envId,
      year: currentYear,
      previousYear: (parseInt(currentYear, 10) - 1).toString(),
    },
  });

  const envBudgetData = data?.environment?.budgetYearly.budgetByMonth.map(
    (value, index) => {
      const date = dayjs()
        .startOf("year")
        .add(index, "month")
        .format(dateFormat.shortDate);
      return {
        date,
        value: Number(value),
      };
    }
  );

  const envCostVersusBudgetChartData = useMemo(
    () =>
      mapCostChartData(
        mapPeriodForCostChartData(period, startDate, endDate),
        envAccumulatedCostBudgetChartData
      ),
    [period, envAccumulatedCostBudgetChartData, startDate, endDate]
  );

  const envBudgetTotal = useMemo(
    () =>
      Number(
        calculateTotalBudget(
          startDate,
          endDate,
          data?.environment?.budgetYearly
        )
      ),
    [startDate, endDate, data]
  );

  const orgUnitBudgetTotal = useMemo(
    () =>
      Number(
        calculateTotalBudget(
          startDate,
          endDate,
          data?.environment?.budgetYearly
        )
      ),
    [startDate, endDate, data]
  );

  const envBudgetTable: TableData[] = useMemo(
    () => [
      {
        field: "Cost",
        total: Number(envAccumulatedCostTotal),
        ...envAccumulatedCostTimePoints?.reduce(
          (acc, { date, value }) => ({ ...acc, [date]: Number(value ?? 0) }),
          {}
        ),
      },
      {
        field: "Budget",
        total: orgUnitBudgetTotal,
        ...data?.environment?.budgetYearly.budgetByMonth?.reduce(
          (acc, budget, index) => {
            const date = dayjs()
              .startOf("year")
              .add(index, "month")
              .format(dateFormat.shortDate);
            return { ...acc, [date]: budget };
          },
          {}
        ),
      },
    ],
    [
      envAccumulatedCostTotal,
      orgUnitBudgetTotal,
      data,
      envAccumulatedCostTimePoints,
    ]
  );

  const hasBudget =
    isNotEmpty(envBudgetData ?? []) &&
    envBudgetData?.some(({ value }) => value !== 0);

  return {
    envBudgetData,
    envCostVersusBudgetChartData,
    envBudgetTable,
    envBudgetTotal,
    hasBudget,
    loading,
    error,
  };
}
