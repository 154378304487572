/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useFormContext } from "react-hook-form";
import { When } from "react-if";
import { Sidebar } from "@nordcloud/gnui";
import { isNotNil } from "~/tools";
import { getDefaultValues, WorkflowFormData, WorkflowFormMode } from "../types";
import { getDefaultValues as getDefaultWorkflowValues } from "../WorkflowDetails/helpers";
import { WorkflowProps } from "../WorkflowPropsMapping";
import { AppsEnvsField } from "./AppsEnvsField";
import { ActionButtons } from "./components/ActionButtons";
import { OrgUnitsField } from "./OrgUnitsField";
import { AppsEnvsFieldState } from "./useAppsEnvsField";

type Props = {
  formMode: WorkflowFormMode;
  isOpen: boolean;
  onClose: () => void;
  workflow?: WorkflowProps;
  type: "costsOptions" | "savingsSuggestions";
};

export function AppsEnvsOrgsSettingsForm({
  isOpen,
  onClose,
  formMode,
  workflow,
  type,
}: Props) {
  const { resetField, watch, setValue, getValues } =
    useFormContext<WorkflowFormData>();
  const isReadMode = formMode === "read";
  const [options, snapshot] = watch([type, `${type}SubmissionSnapshot`]);

  const sidebarTitle = {
    costsOptions: "Cost Settings",
    savingsSuggestions: "Savings Suggestions Settings",
  }[type];

  const handleClose = () => {
    if (isReadMode) {
      onClose();

      return;
    }

    const getDefaultValue = () => {
      if (isNotNil(snapshot)) {
        return JSON.parse(snapshot);
      }

      if (formMode === "edit" && workflow) {
        return getDefaultWorkflowValues(workflow)[type];
      }

      return getDefaultValues()[type];
    };

    resetField(type, {
      defaultValue: getDefaultValue(),
    });
    onClose();
  };

  const handleClear = () => {
    resetField(`${type}.applications`, { defaultValue: [] });
    resetField(`${type}.environments`, { defaultValue: [] });
    resetField(`${type}.orgUnitIds`, { defaultValue: [] });
  };

  const handleSave = () => {
    setValue(`${type}SubmissionSnapshot`, JSON.stringify(options), {
      shouldDirty: true,
    });

    onClose();
  };

  const handleOrgUnitChange = (orgUnits: string[]) => {
    setValue(`${type}.orgUnitIds`, orgUnits, {
      shouldDirty: true,
    });
  };

  const handleAppsEnvsFieldChange = (state: AppsEnvsFieldState) => {
    setValue(`${type}.applications`, state.apps, {
      shouldDirty: true,
    });
    setValue(`${type}.environments`, state.envs, {
      shouldDirty: true,
    });
  };

  return (
    <Sidebar
      title={sidebarTitle}
      isOpen={isOpen}
      width="35rem"
      onClick={handleClose}
    >
      <AppsEnvsField
        compressState
        disabled={isReadMode}
        nodeBehavior="linkedToParent"
        displayTags={false}
        state={{
          apps: options.applications ?? [],
          envs: options.environments ?? [],
        }}
        onChange={handleAppsEnvsFieldChange}
      />
      <OrgUnitsField
        disabled={isReadMode}
        initialOrgUnits={getValues(`${type}.orgUnitIds`) ?? []}
        onChange={handleOrgUnitChange}
      />
      <When condition={!isReadMode}>
        <ActionButtons onClear={handleClear} onSave={handleSave} />
      </When>
    </Sidebar>
  );
}
