/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { generatePath } from "react-router-dom";
import { getBudgetValue } from "~/components/Budgets";
import { ROUTES } from "../routing/routes";
import { isNotNil } from "./filter";
import { formatMoneyWithFallback } from "./money";
import { Maybe } from "./types";

type Application = {
  name: string;
  id: string;
  hasAnomalies?: Maybe<boolean>;
  co2e?: Maybe<string>;
  budgetYearly: {
    budgetByMonth: string[];
    yearlySum: string;
  };
  cloudWasteAggregate?: Maybe<CloudWaste>;
  cost?: Maybe<Cost>;
  environmentsV2?: Maybe<{ count?: Maybe<number> }>;
  environments?: Maybe<Environment>[] | null;
  resourceCount?: number;
};

type CloudWaste = {
  hasWaste?: Maybe<boolean>;
  cost?: Maybe<string>;
  count?: Maybe<number>;
  co2e?: Maybe<string>;
};

type Cost = {
  currentMonth: string;
  previousMonth: string;
  forecast?: Maybe<string>;
};

type Environment = {
  id: string;
  name: string;
  nid: string;
  co2e?: Maybe<string>;
  resourceCount: number;
  budgetYearly: {
    budgetByMonth: string[];
  };
  cloudWasteAggregate?: Maybe<CloudWaste>;
  cost?: Maybe<Cost>;
  orgUnitsV2?: Maybe<{
    orgUnits?: Maybe<
      {
        id: string;
        nid: string;
        name: string;
        businessContext: {
          id: string;
          color?: Maybe<string>;
          name: string;
        };
      }[]
    >;
  }>;
};

function mapApplication(application: Application, currency: string) {
  const currentMonthIndex = dayjs().month();
  const environments = getEnvironments(application);

  return {
    name: application.name,
    id: application.id,
    hasAnomalies: application.hasAnomalies ?? false,
    resources: environments.reduce(
      (sum: number, curr) => sum + (curr?.resourceCount ?? 0),
      0
    ),
    budget: formatMoneyWithFallback(
      getBudgetValue(
        application.budgetYearly?.budgetByMonth,
        currentMonthIndex
      ),
      currency
    ),
    envsCount:
      "environmentsV2" in application
        ? application.environmentsV2?.count ?? 0
        : environments.length ?? 0,
    cost: {
      currentMonth: formatMoneyWithFallback(
        application.cost?.currentMonth,
        currency
      ),
      forecast: formatMoneyWithFallback(application.cost?.forecast, currency),
      forecastToPrev: appCostChange(
        parseFloat(application.cost?.forecast ?? "0"),
        parseFloat(application.budgetYearly?.yearlySum ?? "0")
      ),
      previousMonth: formatMoneyWithFallback(
        application.cost?.previousMonth,
        currency
      ),
    },
    cloudWasteAggregate: application.cloudWasteAggregate,
    co2e: application.co2e,
  };
}

export function mapApplicationWithEnvironment(
  application: Application,
  currency: string
) {
  return {
    ...mapApplication(application, currency),
    resources: application.resourceCount ?? 0,
    envs: getEnvironments(application)
      .filter(isNotNil)
      .map((environment) =>
        mapEnvironment(environment, currency, application.id)
      ),
  };
}

export function mapEnvironment(
  environment: Environment,
  currency: string,
  applicationId: string
) {
  const currentMonthIndex = dayjs().month();

  return {
    environment: environment.name ?? "",
    budget: formatMoneyWithFallback(
      getBudgetValue(
        environment.budgetYearly?.budgetByMonth,
        currentMonthIndex
      ),
      currency
    ),
    id: environment.nid ?? "",
    belongsTo: environment.orgUnitsV2?.orgUnits,
    resources: environment.resourceCount,
    url: generatePath(ROUTES.applications.environment, {
      application: applicationId,
      environment: environment.nid,
    }),
    actualCosts: formatMoneyWithFallback(
      environment.cost?.currentMonth,
      currency
    ),
    forecast: formatMoneyWithFallback(environment.cost?.forecast, currency),
    cloudWasteAggregate: environment.cloudWasteAggregate,
    co2e: environment.co2e,
  };
}

export const hasCo2e = (appOrEnv: { co2e?: Maybe<string> }) =>
  parseFloat(appOrEnv?.co2e ?? "0") > 0;

const appCostChange = (forecast: number, previous: number) =>
  previous > 0 ? (forecast / previous) * 100 : 0;

function getEnvironments(application: Application) {
  if ("environments" in application) {
    return application.environments ?? [];
  }

  return [];
}
