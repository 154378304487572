/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { NumberFormatValues, NumericFormat } from "react-number-format";
import { theme } from "@nordcloud/gnui";
import { isNotNil } from "~/tools";

type Props = {
  onChange: (value?: number) => void;
  value: number;
};

export function StyledNumberFormat({ onChange, value }: Props) {
  return (
    <NumericFormat
      placeholder="KPI Target Value, e.g. 10 %"
      suffix={isNotNil(value) ? " %" : ""}
      value={value}
      isAllowed={isAllowed}
      allowNegative={false}
      css={{
        border: `1px solid ${theme.color.border.input}`,
        width: "97%",
        height: "24px",
        background: "transparent",
        backgroundColor: `${theme.color.field.default}`,
        fontFamily: `${theme.typography.fonts.body}`,
        fontSize: `${theme.fontSizes.md}`,
        borderRadius: `${theme.radiusDefault}`,
        padding: `${theme.spacing.spacing02}`,
        color: `${theme.color.text.text01}`,
        outline: "none",
        transition: "all 0.26s ease-in-out;",
        ":focus, :hover": {
          borderColor: `${theme.color.border.focus}`,
        },
        "::placeholder": {
          color: `${theme.color.text.text03}`,
        },
      }}
      onValueChange={(newValue) => onChange(newValue.floatValue)}
    />
  );
}

function isAllowed({ floatValue: value }: NumberFormatValues) {
  return (
    value === undefined ||
    (typeof value === "number" && value >= 0 && value <= 100)
  );
}
