/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Case, Switch } from "react-if";
import { Sidebar } from "@nordcloud/gnui";
import { ProviderTab } from "../types";
import { AwsBillingDataContent } from "./aws";
import { AzureBillingDataContent } from "./azure";
import { GcpBillingDataContent } from "./gcp";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  provider?: ProviderTab;
};

export function BillingDataSidebar({ isOpen, onClose, provider }: Props) {
  return (
    <Sidebar width="40%" title="Billing Data" isOpen={isOpen} onClick={onClose}>
      <SidebarContentSwitch provider={provider} onClose={onClose} />
    </Sidebar>
  );
}

function SidebarContentSwitch({
  provider,
  onClose,
}: {
  provider?: ProviderTab;
  onClose: () => void;
}) {
  return (
    <Switch>
      <Case condition={provider === ProviderTab.AWS}>
        <AwsBillingDataContent />
      </Case>
      <Case condition={provider === ProviderTab.AZURE}>
        <AzureBillingDataContent onClose={onClose} />
      </Case>
      <Case condition={provider === ProviderTab.GCP}>
        <GcpBillingDataContent />
      </Case>
    </Switch>
  );
}
