/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When, If, Then, Else } from "react-if";
import { Text, Table } from "@nordcloud/gnui";
import { useCurrency } from "~/hooks";
import { formatMoney } from "~/tools";
import { CoverageBar } from "./CoverageBar";
import { StyledTd } from "./styles";

type Props = {
  riCostPercent: string;
  spCostPercent: string;
  onDemandCostPercent: string;
  hasBorder: boolean;
  totalOnDemandCost: string;
  coverageSavedCost: string;
  isReservedInstances: boolean;
  potentialSavings?: string;
  potentialSavingsPlaceholder?: string;
};

export function CoverageAndCostColumn({
  riCostPercent,
  spCostPercent,
  onDemandCostPercent,
  hasBorder,
  totalOnDemandCost,
  coverageSavedCost,
  isReservedInstances,
  potentialSavings,
  potentialSavingsPlaceholder,
}: Props) {
  const { currency } = useCurrency();
  const columnWidth = isReservedInstances ? "33%" : "50%";

  return (
    <>
      <StyledTd hasRightBorder withPadding style={{ textAlign: "center" }}>
        <CoverageBar
          riCostPercent={riCostPercent}
          spCostPercent={spCostPercent}
          onDemandCostPercent={onDemandCostPercent}
        />
      </StyledTd>
      <StyledTd withPadding hasRightBorder={hasBorder}>
        <Table>
          <Table.tr>
            <Table.td width={columnWidth} style={{ borderTop: "none" }}>
              <Text align="right" mb={0}>
                {formatMoney(totalOnDemandCost, currency)}
              </Text>
            </Table.td>
            <Table.td width={columnWidth} style={{ borderTop: "none" }}>
              <Text align="right" mb="0">
                {formatMoney(coverageSavedCost, currency)}
              </Text>
            </Table.td>
            <When condition={isReservedInstances}>
              <Table.td width="34%" style={{ borderTop: "none" }}>
                <If condition={potentialSavings === "0.00"}>
                  <Then>
                    <Text align="right">{potentialSavingsPlaceholder}</Text>
                  </Then>
                  <Else>
                    <Text
                      align="right"
                      color={
                        potentialSavings?.includes("N/A") ? "black" : "success"
                      }
                    >
                      {formatMoney(potentialSavings, currency)}
                    </Text>
                  </Else>
                </If>
              </Table.td>
            </When>
          </Table.tr>
        </Table>
      </StyledTd>
    </>
  );
}
