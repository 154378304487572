/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Sidebar } from "@nordcloud/gnui";
import { CloudAccount, CloudProviderType } from "../../types";
import { HybridOnboardSidebarContent } from "./HybridOnboardSidebarContent";

type Props = {
  account: CloudAccount;
  isOpen: boolean;
  onClose: () => void;
};

export function HybridOnboardSidebar({ account, isOpen, onClose }: Props) {
  const providerName =
    account.providerType === CloudProviderType.Kubernetes
      ? "Kubernetes"
      : "Openshift";

  return (
    <Sidebar
      title={`Onboard ${providerName} Clusters`}
      width="40%"
      isOpen={isOpen}
      onClick={onClose}
    >
      <HybridOnboardSidebarContent
        accountNid={account.nid}
        clusterName={account.name}
        ownerId={account?.owner?.id ?? ""}
        onClose={onClose}
      />
    </Sidebar>
  );
}
