/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  GcpBillingDataDocument,
  GcpBillingDataQuery,
  GcpBillingDataQueryVariables,
  useAddGcpBillingConfigV2Mutation,
} from "~/generated/graphql";
import { ERROR_TEXT } from "~/constants";
import { isNotNil } from "~/tools";
import { FormData } from "~/views/cloudAccounts/BillingData/gcp/types";

export function useAddGcpBillingData() {
  const [addGcpCapacity, { client, ...mutationState }] =
    useAddGcpBillingConfigV2Mutation();

  const addGcpBillingData = async (data: FormData) => {
    const response = await addGcpCapacity({ variables: { input: data } });
    const newItem = response.data?.addGCPBillingConfigV2;

    if (isNotNil(newItem)) {
      client.cache.updateQuery<
        GcpBillingDataQuery,
        GcpBillingDataQueryVariables
      >(
        {
          query: GcpBillingDataDocument,
        },
        (billingData) => ({
          gcpBillingData: [...(billingData?.gcpBillingData ?? []), newItem],
        })
      );

      return;
    }

    throw new Error(ERROR_TEXT.default);
  };

  return [addGcpBillingData, mutationState] as const;
}
