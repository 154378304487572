/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ReactNode } from "react";
import { Box, FlexContainer, theme } from "@nordcloud/gnui";

export function Details({ children }: { children: ReactNode }) {
  return (
    <Box>
      <FlexContainer gap={theme.spacing.spacing04} alignItems="flex-start">
        {children}
      </FlexContainer>
    </Box>
  );
}
