/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import {
  ChartJobQuery,
  ChartJobTableQuery,
  ChartTableSortField,
  ChartTableSortOrder,
  ChartType,
  EstateV2Filter,
  Granularity,
  SearchSortField,
  SearchSortOrder,
  useChartJobLazyQuery,
  useChartJobTableLazyQuery,
  useSubmitChartJobMutation,
} from "~/generated/graphql";
import {
  DateRange,
  EstatePeriod,
  PaginationState,
  SortDirection,
} from "~/components";
import { dateFormat } from "~/constants";
import { useFilteredQueryState, useQueryState } from "~/hooks";
import { estateV2GroupByFields } from "~/hooks/useEstateFetchV2";
import { ERROR_CONFIG, InputError, isEmpty } from "~/tools";
import { convertTags } from "~/views/estate/helpers";
import { getEndDate, getStartDate } from "../../helpers";
import { EstateChartTableQueryState } from "../types";
import {
  getDefaultSortOrderByFilter,
  isJobCompleted,
  isJobFailed,
} from "./helpers";

const POLL_INTERVAL_MS = 1000; // 1 second
const POLL_INTERVAL_ON_FAIL_MS = 30_000; // 30 seconds

type Props = {
  chartType: ChartType;
  range: DateRange;
};

type SortingProp = {
  field?: SearchSortField;
  order?: SearchSortOrder;
};

type EstateQueryState = EstateV2Filter &
  PaginationState &
  SortingProp & {
    tags: string[];
    metadata: string[];
    query: string;
  };

export function useEstateRecordsGroupByChartJob({ chartType, range }: Props) {
  const startDate =
    range.from || getStartDate(EstatePeriod.DEFAULT_MONTH, undefined);
  const endDate = range.to || getEndDate(EstatePeriod.DEFAULT_MONTH, undefined);

  const [jobId, setJobId] = useState("");

  const {
    state: { field, order },
    updateQueryState,
  } = useQueryState<EstateChartTableQueryState>();

  const { state } = useFilteredQueryState(estateV2GroupByFields);

  // @ts-expect-error ignored temporary after migration to TypeScript 4.9
  const { limit, page, query, ...restFilters }: EstateQueryState = state;

  const estateFilters = {
    filter: {
      ...restFilters,
      metadata: convertTags(restFilters.metadata),
      tags: convertTags(restFilters.tags),
    },
  };

  const onSortSelect = useCallback(
    (filter: ChartTableSortField) => {
      updateQueryState({
        field: filter,
        order: getDefaultSortOrderByFilter(filter),
      });
    },
    [updateQueryState]
  );

  const onSortOrderChange = useCallback(
    (direction: SortDirection) => {
      updateQueryState({
        field: field ?? ChartTableSortField.Cost,
        order:
          direction === SortDirection.Descending
            ? ChartTableSortOrder.Dsc
            : ChartTableSortOrder.Asc,
      });
    },
    [updateQueryState, field]
  );

  const [submitChartJob, { loading: isSubmitLoading }] =
    useSubmitChartJobMutation({
      context: {
        headers: {
          "x-billing-period": dayjs(range.from).format(dateFormat.yearMonth),
        },
      },
      onCompleted: (result) => setJobId(result?.submitChartJobV2?.jobId ?? ""),
    });

  const {
    isLoading: isChartLoading,
    data: chartData,
    error: chartError,
  } = useChartJob(jobId);

  const {
    isLoading: isTableLoading,
    data: tableData,
    error: tableError,
  } = useChartTableJob({
    jobId,
    limit,
    page,
    query,
    sortDirection: order,
    sortField: field,
    skip: chartType === ChartType.Accumulated,
  });

  type SubmitChartParams = Parameters<typeof submitChartJob>[0];

  const refetch = useCallback(
    (params?: Partial<NonNullable<SubmitChartParams>["variables"]>) => {
      void submitChartJob({
        variables: {
          filter: params?.filter,
          startDate: params?.startDate ?? "",
          endDate: params?.endDate ?? "",
          chartType: params?.chartType,
        },
      });
    },
    [submitChartJob]
  );

  useEffect(
    () => {
      refetch({
        startDate,
        endDate,
        chartType,
        ...estateFilters,
      });
    },
    // `estateFilters` might get recreated on every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startDate, endDate, chartType, JSON.stringify(estateFilters)]
  );

  const isChartDataLoading = isSubmitLoading || isChartLoading;
  const isTableDataLoading = isSubmitLoading || isTableLoading;
  const isDataLoading = isChartDataLoading && isTableDataLoading;

  return useMemo(
    () => ({
      startDate,
      endDate,
      chartData,
      chartError,
      isChartDataLoading,
      isTableDataLoading,
      isDataLoading,
      tableData,
      tableError,
      sortField: field ?? ChartTableSortField.Cost,
      sortDirection:
        order === ChartTableSortOrder.Asc
          ? SortDirection.Ascending
          : SortDirection.Descending,
      onSortSelect,
      onSortOrderChange,
      jobId,
    }),
    [
      startDate,
      endDate,
      chartData,
      chartError,
      isChartDataLoading,
      isTableDataLoading,
      isDataLoading,
      tableData,
      tableError,
      field,
      order,
      onSortOrderChange,
      onSortSelect,
      jobId,
    ]
  );
}

function useChartJob(jobId: string) {
  const [errorCount, setErrorCount] = useState(0);
  const [data, setData] = useState<ChartJobQuery | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<InputError | undefined>(undefined);

  const [fetchData, { stopPolling, startPolling }] = useChartJobLazyQuery({
    fetchPolicy: "no-cache",
    pollInterval: POLL_INTERVAL_MS,
    notifyOnNetworkStatusChange: true,
    onCompleted: (result) => {
      if (
        isJobCompleted(result.chartJob?.status) &&
        isJobCompleted(result.chartMonthlyJob?.status)
      ) {
        handleDataResponse(result);
      }

      if (
        isJobFailed(result.chartJob?.status) ||
        isJobFailed(result.chartMonthlyJob?.status)
      ) {
        handleDataResponse(result);
        setError(new Error(ERROR_CONFIG.UNKNOWN));
      }
    },
    onError: (err) => {
      stopPolling();
      if (errorCount < 4) {
        setErrorCount((prevValue) => prevValue + 1);
        startPolling(POLL_INTERVAL_ON_FAIL_MS);
      } else {
        setErrorCount(0);
        setError(err);
      }
    },
  });

  const handleDataResponse = (response: ChartJobQuery | undefined) => {
    setData(response);
    setIsLoading(false);
    stopPolling();
  };

  useEffect(() => {
    if (!isEmpty(jobId)) {
      setData(undefined);
      void fetchData({
        variables: {
          jobId,
          dailyGranularity: Granularity.Daily,
          monthlyGranularity: Granularity.Monthly,
          top: 10,
        },
      });
      setIsLoading(true);
    }
  }, [jobId, fetchData]);

  return useMemo(
    () => ({
      isLoading,
      data,
      error,
    }),
    [isLoading, data, error]
  );
}

type ChartTableProps = {
  jobId: string;
  limit: number;
  page: number;
  query?: string;
  sortField?: ChartTableSortField;
  sortDirection?: ChartTableSortOrder;
  skip?: boolean;
};

function useChartTableJob({
  jobId,
  limit,
  page,
  query,
  sortField,
  sortDirection,
  skip,
}: ChartTableProps) {
  const [errorCount, setErrorCount] = useState(0);
  const [data, setData] = useState<ChartJobTableQuery | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<InputError | undefined>(undefined);

  const [fetchData, { stopPolling, startPolling }] = useChartJobTableLazyQuery({
    // cache-first is not working with pagination
    fetchPolicy: "no-cache",
    pollInterval: POLL_INTERVAL_MS,
    notifyOnNetworkStatusChange: true,
    onCompleted: (result) => {
      if (isJobCompleted(result.chartJobTable?.status)) {
        handleResponse(result);
      }

      if (isJobFailed(result.chartJobTable?.status)) {
        handleResponse(result);
        setError(new Error(ERROR_CONFIG.UNKNOWN));
      }
    },
    onError: (err) => {
      stopPolling();
      if (errorCount < 4) {
        setErrorCount((prevValue) => prevValue + 1);
        startPolling(POLL_INTERVAL_ON_FAIL_MS);
      } else {
        setErrorCount(0);
        setError(err);
      }
    },
  });

  const handleResponse = (tableData: ChartJobTableQuery) => {
    setData(tableData);
    setIsLoading(false);
    stopPolling();
  };

  type QueryChartJobTable = Parameters<typeof fetchData>[0];

  const fetch = useCallback(
    (variables: NonNullable<QueryChartJobTable>["variables"]) => {
      void fetchData({
        variables: {
          jobId: variables?.jobId ?? "",
          limit: variables?.limit ?? 20,
          ...variables,
        },
      });
      setIsLoading(true);
    },
    [fetchData]
  );

  useEffect(() => {
    if (!isEmpty(jobId) && !skip) {
      fetch({
        jobId,
        limit,
        page,
        query,
        order: {
          order: sortDirection ?? ChartTableSortOrder.Dsc,
          field: sortField ?? ChartTableSortField.Cost,
        },
      });
    }
  }, [jobId, page, limit, query, sortField, sortDirection, fetch]);

  return useMemo(
    () => ({
      isLoading,
      data,
      error,
    }),
    [isLoading, data, error]
  );
}
