/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useUpdateAzureAccountCredentialsBatchMutation } from "~/generated/graphql";
import { Provider } from "../../types";
import { UpdateHandlerVariables } from "../types";

export function useBatchUpdateAzureAccountCredentials() {
  const [updateAzureCredentials, ...mutationState] =
    useUpdateAzureAccountCredentialsBatchMutation();

  const handleAzureCredentialsUpdate = async (
    variables: UpdateHandlerVariables<Provider.Azure>,
    selectedNids: string[]
  ) => {
    const { tenantId, appId, password } = variables;

    await updateAzureCredentials({
      variables: {
        nids: selectedNids,
        input: {
          tenantId,
          appId,
          password,
        },
      },
    });
  };

  return [handleAzureCredentialsUpdate, mutationState] as const;
}
