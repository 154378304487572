/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Cell, Row } from "react-table";
import { SVGIcon, Text } from "@nordcloud/gnui";
import { DetailData } from "~/components/Details/Detail";
import { getKubernetesPodStatus } from "~/components/Tables/Columns/StatusIconColumn";
import { formatCustomDate } from "~/tools";
import { PodStatus } from "~/views/estate/components/types";
import { generateDate } from "../../utils";

export const KUBERNETES_STATUS_COLUMNS = [
  {
    Header: "Status",
    accessor: "status",
    disableSortBy: true,
    width: "40%",
    Cell: ({ row }: Cell<DetailData>) => {
      const statusDetails = checkStatus(row);
      return (
        <div css={{ position: "relative" }}>
          <SVGIcon
            name={statusDetails.icon}
            css={{ verticalAlign: "sub", marginRight: "15px" }}
          />
          <Text tag="span">{statusDetails.status}</Text>
        </div>
      );
    },
  },
  {
    Header: "Date",
    accessor: "date",
    disableSortBy: true,
    width: "60%",
    Cell: ({ row }: Cell<DetailData>) => {
      const date = row.original.label.includes("creationTimestamp")
        ? formatCustomDate(row.original.value)
        : generateDate(row.original.label);

      return <Text tag="div">{date}</Text>;
    },
  },
];

function checkStatus(row: Row<DetailData>): PodStatus {
  const status = row.original.label.includes("status")
    ? row.original.value
    : "Created";

  return getKubernetesPodStatus(status);
}
