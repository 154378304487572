/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Text, theme } from "@nordcloud/gnui";
import { capitalize } from "~/tools";

type Props = {
  value: string;
  successOn: string;
  shouldCapitalize?: boolean;
};

export function Status({ value, successOn, shouldCapitalize = true }: Props) {
  const color =
    value === successOn
      ? theme.color.interactive.success
      : theme.color.interactive.error;

  return (
    <Text mb={0} color={color}>
      {shouldCapitalize ? capitalize(value) : value}
    </Text>
  );
}
