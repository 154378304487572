/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { components, OptionProps, SingleValue, MultiValue } from "react-select";
import { Select, Text, theme } from "@nordcloud/gnui";
import { usePeopleQuery } from "~/generated/graphql";
import { isNotNil } from "~/tools";

type Props = {
  onChange: (selectedContact: string) => void;
};

export function ContactsSelector({ onChange }: Props) {
  const { data, loading } = usePeopleQuery();

  const filteredPeople = useMemo(() => {
    const people = data?.people ?? [];

    return people.filter(isNotNil).map(({ id, name, email }) => {
      return {
        label: name,
        value: {
          id,
          email,
        },
      };
    });
  }, [data]);

  return (
    <div css={{ width: "100%" }}>
      <Select
        options={filteredPeople}
        components={{ Option }}
        isLoading={loading}
        styles={{
          option: (base) => ({
            ...base,
            padding: `${theme.spacing.spacing00} ${theme.spacing.spacing02}`,
            backgroundColor: "",
            ":active": { backgroundColor: "" },
            color: theme.color.text.text01,
          }),
        }}
        onChange={(
          selectedContact:
            | MultiValue<OptionItemType>
            | SingleValue<OptionItemType>
        ) => {
          const contact = selectedContact as OptionItemType;
          onChange(contact.value.id ?? "");
        }}
      />
    </div>
  );
}

type OptionItemType = {
  label: string;
  value: { id: string; email: string };
};

function Option(props: OptionProps<OptionItemType>) {
  return (
    <div
      css={{ padding: `${theme.spacing.spacing01} ${theme.spacing.spacing02}` }}
    >
      <components.Option {...props}>
        <div>{props.data.label}</div>
        <Text mb={0} size="sm" color={theme.color.text.text03}>
          {props.data.value.email}
        </Text>
      </components.Option>
    </div>
  );
}
