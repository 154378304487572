/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { PropsWithChildren } from "react";
import { Text } from "@nordcloud/gnui";

type Props = {
  title: string;
};

export function BillingDataList({ children, title }: PropsWithChildren<Props>) {
  return (
    <>
      <Text weight="medium">{title}</Text>
      {children}
    </>
  );
}
