/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { theme, ExtendedPopover } from "@nordcloud/gnui";
import { BarChart, TooltipWrapper } from "~/components";
import { CoverageTooltip } from "../CoverageTooltip";

type TooltipProps = {
  riCostPercent: string;
  spCostPercent: string;
  onDemandCostPercent: string;
};

export function CoverageBar({
  riCostPercent,
  spCostPercent,
  onDemandCostPercent,
}: TooltipProps) {
  const barGraphData = [
    {
      key: "Covered By RI",
      hasColor: true,
      color: theme.color.support.cyan,
      value: Number(riCostPercent),
    },
    {
      key: "Covered By SP",
      hasColor: true,
      color: theme.color.support.yellow,
      value: Number(spCostPercent),
    },
    {
      key: "On-Demand Cost",
      hasColor: true,
      color: theme.color.support.grey,
      value: Number(onDemandCostPercent),
    },
  ];

  const barsValues = barGraphData.map((barData) => ({
    color: barData.color,
    value: barData.value,
  }));

  return (
    <TooltipWrapper>
      <ExtendedPopover
        content={<CoverageTooltip values={barGraphData} />}
        triggerOn="hover"
        placement="top"
        closeOn="hover"
        position="center"
        trigger={
          <BarChart
            values={barsValues}
            css={{ marginBottom: theme.spacing.spacing00 }}
          />
        }
      />
    </TooltipWrapper>
  );
}
