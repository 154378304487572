/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { When } from "react-if";
import { theme, Text } from "@nordcloud/gnui";

export function SelectedAccountMessage({
  accountsCount,
  selectedAccounts,
  isAllAccountsSelected,
  showClearSelectionButton,
  visibleAccounts,
  onSelectAll,
  onUnselectAll,
}: {
  isAllAccountsSelected: boolean;
  showClearSelectionButton: boolean;
  accountsCount: number;
  selectedAccounts: string[];
  visibleAccounts: string[];
  onSelectAll: () => void;
  onUnselectAll: () => void;
}) {
  const isMoreThanOnePage = accountsCount > selectedAccounts.length;
  const selectedAccountsText = useMemo(
    () =>
      getAccountsSelectedText(
        isAllAccountsSelected,
        selectedAccounts,
        visibleAccounts
      ),
    [isAllAccountsSelected, selectedAccounts, visibleAccounts]
  );

  return (
    <>
      <span css={{ paddingRight: theme.spacing.spacing01 }}>
        {selectedAccountsText}
      </span>
      <When condition={isMoreThanOnePage && !showClearSelectionButton}>
        <Text
          tag="span"
          color={theme.color.text.info}
          css={{
            cursor: "pointer",
          }}
          onClick={onSelectAll}
        >
          Select all {accountsCount} Accounts accross all pages.
        </Text>
      </When>
      <When condition={showClearSelectionButton}>
        <Text
          tag="span"
          color={theme.color.text.info}
          css={{
            cursor: "pointer",
          }}
          onClick={onUnselectAll}
        >
          Clear selection
        </Text>
      </When>
    </>
  );
}

function getAccountsSelectedText(
  isAllAccountsSelected: boolean,
  selectedAccounts: string[],
  visibleAccounts: string[]
) {
  const selectedAccountsCount = selectedAccounts.length;

  if (isAllAccountsSelected) {
    return (
      <Text tag="span">
        All <strong>{selectedAccountsCount}</strong> Accounts selected.
      </Text>
    );
  }

  if (selectedAccountsCount === 1) {
    if (visibleAccounts.some((nid) => selectedAccounts.includes(nid))) {
      return <Text tag="span">One account on this page is selected.</Text>;
    }

    return <Text tag="span">One account is selected.</Text>;
  }

  if (selectedAccountsCount > 1) {
    if (selectedAccounts.every((nid) => visibleAccounts.includes(nid))) {
      return (
        <Text tag="span">
          <strong>{selectedAccountsCount}</strong> accounts on this page are
          selected.
        </Text>
      );
    }

    return (
      <Text tag="span">
        <strong>{selectedAccountsCount}</strong> accounts are selected.
      </Text>
    );
  }

  if (visibleAccounts.every((nid) => selectedAccounts.includes(nid))) {
    if (selectedAccountsCount === visibleAccounts.length) {
      return <Text tag="span">All accounts on this page are selected.</Text>;
    }

    return (
      <Text tag="span">
        <strong>{selectedAccountsCount}</strong> accounts are selected.
      </Text>
    );
  }
}
