/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

enum WorkflowDataTypeWithMarkers {
  BUDGETS = "BUDGETS",
  SAVING_SUGGESTIONS = "SAVING_SUGGESTIONS",
  UNALLOCATED_RESOURCES = "UNALLOCATED_RESOURCES",
  KPI = "KPI",
  COSTS = "COSTS",
}

type Marker = {
  title: string;
  description: string;
};

export const markerMap: Record<WorkflowDataTypeWithMarkers, Marker[]> = {
  BUDGETS: [
    {
      title: "[item_name]",
      description:
        "the name of the item with a budget. For example, the application name, the environment, or the name of the organization unit.",
    },
    {
      title: "[item_type]",
      description:
        "the type of the item. Enum: application, environment, organization unit.",
    },
    {
      title: "[item_budget]",
      description:
        "the amount of budget, with currency, for the current month for the item.",
    },
    {
      title: "[item_forecast]",
      description:
        "the amount of forecast cost, with currency, for the current month for the item.",
    },
    {
      title: "[item_cost]",
      description:
        "the amount of current cost, with currency, for the current month for the item.",
    },
    {
      title: "[item_link]",
      description:
        "link to the detailed view of the item in IBM Multicloud Accelerator.",
    },
    {
      title: "[item_contact_person]",
      description:
        "email of the contact person of the item. If the contact person is missing, the text marker is replaced with the application owner's email. Text markers will be removed from the message if both emails are missing",
    },
  ],
  UNALLOCATED_RESOURCES: [
    {
      title: "[number_of_unallocated]",
      description: "Number of new unallocated resources.",
    },
    {
      title: "[link]",
      description:
        "Link to estate records list containing new unallocated resources in IBM Multicloud Accelerator.",
    },
  ],
  SAVING_SUGGESTIONS: [
    {
      title: "[number_of_saving_suggestions]",
      description:
        "Number of saving suggestions filtered by applications and environments.",
    },
    {
      title: "[number_of_estate_records_with_saving_suggestions]",
      description:
        "Number of estate records with saving suggestions filtered by application and environments.",
    },
    {
      title: "[amount_of_saving_suggestions]",
      description:
        "Amount of top saving suggestions in your selected currency filtered by applications and environments.",
    },
    {
      title: "[link]",
      description:
        "Link to estate records list containing saving suggestions in IBM Multicloud Accelerator.",
    },
  ],
  KPI: [
    {
      title: "[kpi_cost_allocation_coverage_value]",
      description:
        "Current value of the Cost Allocation Coverage KPI with a unit (e.g.: 51% (€23,434.51)).",
    },
    {
      title: "[kpi_cost_allocation_coverage_target]",
      description:
        "Current target of the Cost Allocation Coverage KPI with a unit and a sign (e.g.: >85%).",
    },
    {
      title: "[kpi_overall_waste_value]",
      description: "Current value of the Overall Waste KPI with a unit.",
    },
    {
      title: "[kpi_overall_waste_target]",
      description: "Current target of the Overall Waste KPI with a unit.",
    },
    {
      title: "[kpi_non_prod_env_shutdown_time_value]",
      description:
        "Current value of the Non-Production Environments Shutdown Time KPI with a unit.",
    },
    {
      title: "[kpi_non_prod_env_shutdown_time_target]",
      description:
        "Current target of the Non-Production Environments Shutdown Time KPI with a unit.",
    },
    {
      title: "[link]",
      description: "Link to KPIs dashboard.",
    },
  ],
  COSTS: [
    {
      title: "[costs]",
      description: "The total amount of all costs with currency.",
    },
    {
      title:
        "[costs_prov_1st]\n[costs_prov_2nd]\n[costs_prov_3rd]\n[costs_prov_4th]\n[costs_prov_5th]",
      description:
        "The amount of costs, with currency and the provider name, for top 5 providers by costs (e.g.: 10.00 EUR AWS).",
    },
    {
      title:
        "[costs_prov_1st_link]\n[costs_prov_2nd_link]\n[costs_prov_3rd_link]\n[costs_prov_4th_link]\n[costs_prov_5th_link]",
      description:
        "Link to estate records list, in IBM Multicloud Accelerator, filtered by a provider for top 5 providers by costs.",
    },
    {
      title:
        "[costs_res_cat_1st]\n[costs_res_cat_2nd]\n[costs_res_cat_3rd]\n[costs_res_cat_4th]\n[costs_res_cat_5th]",
      description:
        "The amount of costs, with currency and the resource category name, for top 5 resource categories by costs (e.g.: 10.01 EUR Compute).",
    },
    {
      title:
        "[costs_res_cat_1st_link]\n[costs_res_cat_2nd_link]\n[costs_res_cat_3rd_link]\n[costs_res_cat_4th_link]\n[costs_res_cat_5th_link]",
      description:
        "Link to estate records list, in IBM Multicloud Accelerator, filtered by a resource category for top 5 resource categories by costs.",
    },
  ],
};
