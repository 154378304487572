/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme, Box, Text } from "@nordcloud/gnui";

export const ListContainer = styled.div`
  padding-left: ${theme.spacing.spacing02};
  padding-top: ${theme.spacing.spacing02};
  div {
    margin-bottom: ${theme.spacing.spacing01};
    label {
      margin-bottom: ${theme.spacing.spacing01};
    }
  }
`;

export const StyledBox = styled(Box)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.spacing02};
`;

export const StyledClearButton = styled(Text)`
  position: absolute;
  top: 0.75rem;
  right: 10px;
  color: ${theme.color.text.info};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
