/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import {
  useAccumulatedCostQuery,
  Granularity as APIGranularity,
  ChartGroupTimePoint,
} from "~/generated/graphql";
import {
  mapGranularity,
  CostAnalysisFields,
  Granularity,
  TableData,
  generateAccumulatedCostGroups,
  mapTimePointValue,
} from "~/components";
import { isNotNil } from "~/tools";

type Props = {
  startDate: string;
  endDate: string;
  granularity: Granularity;
};

export function useApplicationsAccumulatedCost({
  startDate,
  endDate,
  granularity,
}: Props) {
  const { data, loading, error } = useAccumulatedCostQuery({
    variables: {
      startDate,
      endDate,
      granularity: mapGranularity(granularity),
      tableGranularity: APIGranularity.Monthly,
    },
  });

  const accumulatedCostChartData = useMemo(
    () => generateAccumulatedCostGroups(data),
    [data]
  );

  const accumulatedCostTimePoints = useMemo(
    () =>
      (data?.accumulatedCostTable?.timePoints ?? [])
        .filter(isNotNil)
        .map(mapTimePointValue),
    [data]
  );

  const accumulatedCostTotal = Number(data?.accumulatedCostTable?.total ?? 0);

  const accumulatedCostTableData: TableData[] = useMemo(
    () => [
      {
        field: CostAnalysisFields.TOTAL_COST,
        total: accumulatedCostTotal,
        ...accumulatedCostTimePoints?.reduce(
          (acc, { date, value }) => ({ ...acc, [date]: Number(value ?? 0) }),
          {}
        ),
      },
    ],
    [accumulatedCostTotal, accumulatedCostTimePoints]
  );

  const accumulatedCostKeys = getAccumulatedCostKeys(accumulatedCostChartData);

  return {
    accumulatedCostKeys,
    accumulatedCostChartData,
    accumulatedCostTimePoints,
    accumulatedCostTableData,
    accumulatedCostTotal,
    loading,
    error,
  };
}

function getAccumulatedCostKeys(
  accumulatedCostChartData: ChartGroupTimePoint[]
) {
  const costItems = accumulatedCostChartData.flatMap(({ groups }) => groups);

  const isSavingsPlans =
    costItems.findIndex(
      (item) => item?.name === CostAnalysisFields.SAVINGS_PLANS
    ) !== -1;

  const isCostCorrection =
    costItems.findIndex(
      (item) => item?.name === CostAnalysisFields.CORRECTION_COST
    ) !== -1;

  return [
    CostAnalysisFields.DAILY_COST,
    isSavingsPlans ? CostAnalysisFields.SAVINGS_PLANS : null,
    isCostCorrection ? CostAnalysisFields.CORRECTION_COST : null,
  ].filter(isNotNil);
}
