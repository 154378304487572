/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

export const BorderSeparator = styled.div`
  padding: ${theme.spacing.spacing01};
  margin-bottom: ${theme.spacing.spacing02};
  border-bottom: 1px solid ${theme.color.border.input};
`;
