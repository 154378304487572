/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { When } from "react-if";
import { Link } from "react-router-dom";
import { Cell } from "react-table";
import styled from "styled-components";
import {
  SVGIcon,
  theme,
  ExtendedTooltip,
  Text,
  Button,
  FlexContainer,
} from "@nordcloud/gnui";
import { PopoverControls, ACTION } from "~/components";
import { dateFormat } from "~/constants";
import { ROUTES } from "~/routing/routes";
import { ACTIONS, Can } from "~/services/auth";
import { LastRunStatus } from "../constants";
import {
  useActivateWorkflowAction,
  useExecuteWorkflowAction,
  useRemoveWorkflowAction,
  useCloneWorkflowAction,
} from "../hooks";
import { WorkflowProps } from "../WorkflowPropsMapping";
import { TriggerList } from "./TriggerList";

type Props = {
  executeAction: ReturnType<typeof useExecuteWorkflowAction>;
  activateAction: ReturnType<typeof useActivateWorkflowAction>;
  removeAction: ReturnType<typeof useRemoveWorkflowAction>;
  cloneAction: ReturnType<typeof useCloneWorkflowAction>;
};

export function WorkflowsListColumns({
  executeAction,
  activateAction,
  removeAction,
  cloneAction,
}: Props) {
  return [
    {
      Header: "Status / Workflow Name / Description",
      accessor: "name",
      width: 150,
      Cell: ({ row }: Cell<WorkflowProps>) => {
        const isActive = row.original.active;
        const tooltipText = isActive ? "Active" : "Inactive";
        const tooltipStatus = isActive ? "notification" : "warning";
        const iconName = isActive ? "statusRunning" : "statusOnHold";

        return (
          <FlexContainer css={{ height: "100%" }}>
            <FlexContainer
              alignContent="center"
              pr={theme.spacing.spacing03}
              mt={theme.spacing.spacing02}
              css={{ height: "100%" }}
            >
              <ExtendedTooltip
                caption={<TooltipCaption text={tooltipText} />}
                status={tooltipStatus}
                css={{ position: "relative" }}
              >
                <SVGIcon name={iconName} data-testid="status" />
              </ExtendedTooltip>
            </FlexContainer>
            <FlexContainer
              direction="column"
              alignContent="flex-start"
              justifyContent="center"
              alignItems="flex-start"
              css={{ height: "100%", wordBreak: "break-all" }}
            >
              <Link to={`${ROUTES.workflows.index}/${row.original.id}`}>
                <Text color={theme.color.text.info} mb="0">
                  {row.original.name}
                </Text>
              </Link>
              <When condition={row.original.description}>
                <Text size="sm" color={theme.color.text.text02} mb="0">
                  {row.original.description}
                </Text>
              </When>
            </FlexContainer>
          </FlexContainer>
        );
      },
    },
    {
      Header: "Trigger",
      accessor: "trigger",
      width: 140,
      Cell: ({ row }: Cell<WorkflowProps>) => (
        <TriggerList
          triggers={row.original.triggers}
          active={row.original.active}
        />
      ),
    },
    {
      Header: "Contact Person",
      accessor: "contactPerson",
      width: 100,
      Cell: ({ row }: Cell<WorkflowProps>) => {
        const contactPeopleText =
          row.original.contactPersons.map((cp) => cp.name).join(", ") ||
          "Contact person missing";

        return (
          <FlexContainer
            css={{ maxWidth: "16rem", height: "100%", width: "100%" }}
            alignContent="center"
          >
            <ContactPersonsText title={contactPeopleText}>
              {contactPeopleText}
            </ContactPersonsText>
          </FlexContainer>
        );
      },
    },
    {
      Header: "Last Run Status and Date",
      accessor: "lastRunStatusAndDate",
      width: 100,
      Cell: ({ row }: Cell<WorkflowProps>) => {
        const isStatusAvailable =
          row.original.lastRunStatus !== LastRunStatus.NONE;
        const isWorkflowFailed =
          row.original.lastRunStatus === LastRunStatus.FAILED;
        const icon = isWorkflowFailed ? "statusError" : "statusSuccess";
        const caption = (
          <Text size="sm" tag="div" color={theme.colors.white}>
            {isWorkflowFailed ? "Failure" : "Success"}
          </Text>
        );
        const tooltipStatus = isWorkflowFailed ? "danger" : "success";

        return (
          <When condition={isStatusAvailable}>
            <FlexContainer
              alignContent="center"
              css={{ height: "100%", width: "100%" }}
            >
              <ExtendedTooltip status={tooltipStatus} caption={caption}>
                <FlexContainer alignContent="center">
                  <SVGIcon name={icon} />
                </FlexContainer>
              </ExtendedTooltip>
              <Text mb="0" pl={theme.spacing.spacing01}>
                {dayjs(row.original.lastRunDate).format(
                  dateFormat.fullDateShort
                )}
              </Text>
            </FlexContainer>
          </When>
        );
      },
    },
    {
      accessor: "actions",
      disableSortBy: true,
      width: 20,
      align: "right",
      Cell: ({ row }: Cell<WorkflowProps>) => {
        const activationLabel = row.original.active
          ? "Deactivate workflow"
          : "Activate workflow";
        const activationIcon = row.original.active ? "pause" : "play";

        return (
          <FlexContainer alignContent="center" css={{ height: "100%" }}>
            <Can action={ACTIONS.updateWorkflow}>
              <PopoverControls
                trigger={
                  <Button
                    severity="low"
                    icon="menu"
                    size="md"
                    data-testid="workflow-action-button"
                  />
                }
                items={[
                  {
                    label: "Force Run",
                    customIcon: "thunder",
                    action: ACTION.CUSTOM_TRIGGER,
                    onClick: () => executeAction.onClick(row.original),
                  },
                  {
                    label: activationLabel,
                    customIcon: activationIcon,
                    action: ACTION.CUSTOM_TRIGGER,
                    onClick: () => activateAction.onClick(row.original),
                  },
                  {
                    label: "Clone Workflow",
                    customIcon: "copy",
                    action: ACTION.CUSTOM_TRIGGER,
                    onClick: () => cloneAction.onClick(row.original),
                  },
                  {
                    label: "Delete",
                    customIcon: "trash",
                    action: ACTION.CUSTOM_TRIGGER,
                    onClick: () => {
                      removeAction.onClick(row.original);
                    },
                  },
                ]}
              />
            </Can>
          </FlexContainer>
        );
      },
    },
  ];
}

type TooltipCaptionProps = {
  text: string;
};

function TooltipCaption({ text }: TooltipCaptionProps) {
  return (
    <Text size="sm" color={theme.color.text.text04} mb="0">
      {text}
    </Text>
  );
}

const ContactPersonsText = styled(Text)`
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 0;
`;
