/* *
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Modal } from "@nordcloud/gnui";
import { useToggle } from "~/hooks";
import { BudgetsConditions } from "../BudgetsConditions";
import { SLACK_MSG_PREVIEW_MODAL_TITLE } from "../constants";
import { BudgetsPreviewMessage } from "../PreviewMessages";

type Props = {
  isReadMode?: boolean;
};

export function BudgetsSelectDataType({ isReadMode }: Props) {
  const [showPreview, setShowPreview] = useToggle(false);

  return (
    <>
      <BudgetsConditions
        shouldBeSelected
        isReadMode={isReadMode}
        onPreviewClick={() => setShowPreview()}
      />
      <When condition={showPreview}>
        <Modal
          isOpen={showPreview}
          actions={[]}
          contentMaxHeight="40rem"
          contentLabel={SLACK_MSG_PREVIEW_MODAL_TITLE}
          onClose={() => setShowPreview()}
        >
          <BudgetsPreviewMessage />
        </Modal>
      </When>
    </>
  );
}
