/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { Else, If, Then, When } from "react-if";
import { FlexContainer, theme, Button, Text, Input } from "@nordcloud/gnui";
import { useToggle } from "~/hooks";
import { isNotEmpty, isNotNil } from "~/tools";

type DetailsFieldProps = {
  label: string;
  value: string;
  onEdit?: (text: string) => void;
  onDelete?: () => void;
};

export function TextDetailsField({
  label,
  value,
  onEdit,
  onDelete,
}: DetailsFieldProps) {
  const [isHovered, toggleIsHovered] = useToggle();
  const [isEditable, toggleIsEditable] = useToggle();
  const [inputText, setInputText] = useState(value);

  const hasValue = isNotEmpty(value);
  const text = hasValue ? value : "No Data Provided";
  const textColor = hasValue
    ? theme.color.text.text01
    : theme.color.text.text03;

  const showEditButton = isNotNil(onEdit) && isHovered && !isEditable;
  const showDeleteButton = isNotNil(onDelete) && isHovered;

  useEffect(() => {
    setInputText(value);
  }, [value]);

  return (
    <FlexContainer
      gap={theme.spacing.spacing01}
      alignItems="center"
      onMouseEnter={toggleIsHovered}
      onMouseLeave={toggleIsHovered}
    >
      <div>
        <Text size="sm" tag="div" color={theme.color.text.text03}>
          {label}
        </Text>
        <FlexContainer>
          <If condition={isEditable}>
            <Then>
              <FlexContainer gap={theme.spacing.spacing02}>
                <Input
                  maxLength={150}
                  value={inputText}
                  height="1rem"
                  placeholder="Account Description"
                  onChange={(event) => {
                    setInputText(event.target.value);
                  }}
                />
                <Button
                  severity="low"
                  size="md"
                  icon="save"
                  onClick={() => {
                    onEdit && inputText !== value && onEdit(inputText);
                    toggleIsEditable();
                    toggleIsHovered();
                  }}
                />
                <Button
                  severity="low"
                  size="md"
                  icon="close"
                  onClick={toggleIsEditable}
                />
              </FlexContainer>
            </Then>
            <Else>
              <Text
                color={textColor}
                tag="div"
                mr={theme.spacing.spacing02}
                css={{
                  borderBottom:
                    isNotNil(onEdit) || isNotNil(onDelete)
                      ? "1px dotted black"
                      : "none",
                }}
              >
                {text}
              </Text>
            </Else>
          </If>
        </FlexContainer>
      </div>
      <Button
        severity="low"
        p={theme.spacing.spacing01}
        icon="edit"
        css={{ visibility: showEditButton ? "visible" : "hidden" }}
        onClick={toggleIsEditable}
      />
      <When condition={showDeleteButton}>
        <Button
          severity="low"
          p={theme.spacing.spacing01}
          icon="trash"
          onClick={onDelete}
        />
      </When>
    </FlexContainer>
  );
}
