/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Message } from "@nordcloud/gnui";

type Props = {
  message: string;
};

export function CloudAccountMissing({ message }: Props) {
  return (
    <Message image="danger" status="danger">
      {message}
    </Message>
  );
}
