/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ComponentProps } from "react";
import * as ReactDOM from "react-dom";
import { useWindowSize } from "react-use";
import {
  ItemsContainer,
  ItemsSection,
  MenuItem,
  NavigationBar,
} from "@nordcloud/gnui";
import { FeatureFlag, MenuItemWrapper, ProtectedBy } from "./components";
import { useGetMenuItems } from "./hooks";
import { MenuBarProvider, useSidebarVisibility } from "./sidebarVisibility";

export function MenuBar() {
  return ReactDOM.createPortal(
    <MenuBarProvider>
      <MenuBarVisual />
    </MenuBarProvider>,
    document.body
  );
}

function MenuBarVisual() {
  const { height } = useWindowSize();
  const { items } = useGetMenuItems();

  const { sidebarOpened } = useSidebarVisibility();

  const popoverConfig: PopoverConfig = {
    triggerOn: "hover",
    closeOn: sidebarOpened ? "click" : "hover",
    // Make popover sidemenu visible on screens with lower height
    position: height > 820 ? "start" : "center",
  };

  return (
    <NavigationBar popoverConfig={popoverConfig}>
      <ItemsContainer>
        <ItemsSection>
          {items.map((item, index) => (
            <ProtectedBy
              key={`${item.caption}-${index}`}
              action={item.protectedBy}
            >
              <FeatureFlag featureFlag={item.featureFlag}>
                <MenuItemWrapper
                  route={item.route}
                  triggeredPaths={item.triggeredPaths}
                  hasMenuItem={item.isMenuItemVisible}
                  alertDotOptions={item.alertDotOptions}
                >
                  <MenuItem {...item} />
                </MenuItemWrapper>
              </FeatureFlag>
            </ProtectedBy>
          ))}
        </ItemsSection>
      </ItemsContainer>
    </NavigationBar>
  );
}

type PopoverConfig = NonNullable<
  ComponentProps<typeof NavigationBar>["popoverConfig"]
>;
