/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Box } from "@nordcloud/gnui";
import { useWorkflowHistoryListSort } from "../hooks";
import { WorkflowHistoryList } from "./WorkflowHistoryList";
import { WorkflowHistoryListSort } from "./WorkflowHistoryListSort";

export function SortableWorkflowHistoryList() {
  const { sortField, sortOrder, handleSortFieldChange, handleSortOrderChange } =
    useWorkflowHistoryListSort();

  return (
    <>
      <Box boxStyle="lightGrey" spacing="spacing03">
        <WorkflowHistoryListSort
          sortField={sortField}
          sortOrder={sortOrder}
          onSortFieldChange={handleSortFieldChange}
          onSortOrderChange={handleSortOrderChange}
        />
      </Box>
      <WorkflowHistoryList sortField={sortField} sortOrder={sortOrder} />
    </>
  );
}
