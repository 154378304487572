/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { Role as ROLE } from "~/generated/graphql";

export enum SortByOrder {
  Ascending = "ASCENDING",
  Descending = "DESCENDING",
}

export enum SortByValue {
  ApiKeyDescription = "API_KEY_DESCRIPTION",
  CreationDate = "CREATION_DATE",
  ExpirationDate = "EXPIRATION_DATE",
  LastLoginDate = "LAST_LOGIN_DATE",
  UserEmailId = "USER_EMAIL_ID",
  Role = "ROLE",
}

export type SortKeysUsers = {
  field: SortByValue;
  order: SortByOrder;
};

export type QuerySort = SortKeysUsers & {
  page: number;
  limit: number;
  query: string;
  tab: number;
};

export type Account = {
  email: string;
  lastLoginDate: string;
  isOrgAdminFromPanel: boolean;
  access: {
    role: ROLE;
    applications: string[];
    applicationsWithNames: {
      nid: string;
      name: string;
    }[];
  };
};
