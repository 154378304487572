/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { If, Else, Then } from "react-if";
import {
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsProvider,
} from "~/generated/graphql";
import { UsageCoverageData } from "../../types";
import { AwsCoverageTable } from "./AwsCoverageTable";
import { AzureCoverageTable } from "./AzureCoverageTable";

type Props = {
  resources: UsageCoverageData;
  isParent: boolean;
  isExpanded?: boolean;
  offeringType?: ReservationsAndCommitmentsCoverageOfferingType;
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  provider: ReservationsProvider;
  iconClick?: () => void;
  openSidebar: (rowDetails: UsageCoverageData) => void;
};

export function TableItems({
  resources,
  isParent,
  isExpanded,
  offeringType,
  serviceUsageType,
  provider,
  openSidebar,
  iconClick,
}: Props) {
  return (
    <If condition={provider === ReservationsProvider.Aws}>
      <Then>
        <AwsCoverageTable
          resources={resources}
          iconClick={iconClick}
          isExpanded={isExpanded}
          openSidebar={openSidebar}
          offeringType={offeringType}
          serviceUsageType={serviceUsageType}
          isParent={isParent}
        />
      </Then>
      <Else>
        <AzureCoverageTable
          resources={resources}
          iconClick={iconClick}
          isExpanded={isExpanded}
          isParent={isParent}
          openSidebar={openSidebar}
          offeringType={offeringType}
          serviceUsageType={serviceUsageType}
        />
      </Else>
    </If>
  );
}
