/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { dateFormat } from "~/constants";
import { generateNumericArray, Maybe } from "~/tools";

export enum Editing {
  YEARLY,
  MONTHLY,
  GROWTH_RATE,
}

export function splitToQuarter<T>(values: T[]): T[][] {
  if (values.length !== 12) {
    console.warn("Received budget with length differing from full year.");
  }

  return generateNumericArray(4).map((_, index) => {
    const offset = 3 * index;
    return values.slice(offset, offset + 3);
  });
}

export function newMonthValues(value: number) {
  const rest = value % 12;
  const temporary = (value - rest) / 12;
  return [...generateNumericArray(11).fill(temporary), temporary + rest];
}

export function sanitizeLastYearSpend(spends: string[]) {
  return spends
    .map(Number)
    .map((spend) => (spend < 0 ? 0 : spend))
    .map(String);
}

export function generateBudget(
  lastYearSpends: string[],
  forecastSpendYearly: string[],
  year: string
) {
  const current = dayjs();
  const currentMonthIndex = current.month();
  const isNextYear = current.add(1, "year").format(dateFormat.year) === year;

  return lastYearSpends.map((spend, index) => {
    if (isNextYear && currentMonthIndex === index) {
      return (Number(forecastSpendYearly[index]) || spend).toString();
    }
    if (Number(spend) > 0) {
      return spend;
    }

    return forecastSpendYearly[index];
  });
}

export function getBudgetValue(budget: Maybe<string[]>, currentMonth: number) {
  const monthlyBudget = Number(budget?.[currentMonth] ?? 0);

  if (monthlyBudget !== 0) {
    return budget?.[currentMonth];
  }

  // return 0 if budget is set, but value for this month is 0
  if (budget?.some((value) => Number(value) !== 0)) {
    return "0";
  }

  return "N/A";
}
