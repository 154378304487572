/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  Ec2InstanceFamily,
  Ec2Tenancy,
  useEc2InstanceComparisonLazyQuery,
} from "~/generated/graphql";
import { isCloudResource, Maybe, UsageOperationType } from "~/tools";

type Props = {
  nid: string;
  region: Maybe<string>;
  type: Maybe<string>;
  usageOperation: Maybe<UsageOperationType>;
  isActivated: boolean;
  savingSuggestionTypes: string[];
  tenancy: string | undefined;
};

export function useInstanceComparison({
  nid,
  region,
  usageOperation,
  type,
  isActivated,
  savingSuggestionTypes,
  tenancy,
}: Props) {
  const [getInstanceComparison, { data, loading, error }] =
    useEc2InstanceComparisonLazyQuery();

  return {
    getInstanceComparison: async (instanceFamily: Ec2InstanceFamily) => {
      if (isActivated) {
        await getInstanceComparison({
          variables: {
            nid,
            instanceFamily,
            type: type ?? "",
            usageOperation: usageOperation ?? "",
            region: region ?? "",
            tenancy: getTenancy(tenancy),
            instancesFromSavingsSuggestions: savingSuggestionTypes,
          },
        });
      }
    },
    loading,
    error,
    comparison:
      data?.estateRecord &&
      isCloudResource(data.estateRecord) &&
      data.estateRecord.ec2InstanceComparator
        ? data.estateRecord.ec2InstanceComparator.map((item) => ({
            instanceType: item.instanceType,
            instanceFamily: item.instanceFamily,
            memory: item.memory,
            vCPU: item.vCPU,
            storage: item.storage,
            networkPerformance: item.networkPerformance,
            region: item.region,
            priceHourly: item.priceHourly,
            priceDaily: item.priceDaily,
            priceMonthly: item.priceMonthly,
            operatingSystem: item.operatingSystem,
            currentType: item.isInstanceComparedTo,
            isInstanceFromSavingsSuggestions:
              item.isInstanceFromSavingsSuggestions,
          }))
        : [],
  };
}

function getTenancy(tenancy: string | undefined) {
  switch (tenancy?.toLowerCase()) {
    case "dedicated":
      return Ec2Tenancy.Dedicated;
    case "host":
      return Ec2Tenancy.Host;
    case "shared":
      return Ec2Tenancy.Shared;
    case "default":
      return Ec2Tenancy.Default;
    default:
      return undefined;
  }
}
