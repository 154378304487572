import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { useFormContext } from "react-hook-form";
import { When } from "react-if";
import { v4 as uuidv4 } from "uuid";
import { Button, FlexContainer, Input, Label, theme } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { getFirstItem, isNotNil } from "~/tools";
import { AzureFormFields } from "~/views/cloudAccounts/types";
import { AzureFormData } from "../types";

type Props = {
  subscriptionIds: { id: string; value: string }[];
  setSubscriptionIds: Dispatch<
    SetStateAction<
      {
        id: string;
        value: string;
      }[]
    >
  >;
};

export function SubscriptionFields({
  subscriptionIds,
  setSubscriptionIds,
}: Props) {
  const {
    formState: { errors },
  } = useFormContext<AzureFormData>();

  const addSubscriptionIdInput = () => {
    setSubscriptionIds((prev) => [...prev, { id: uuidv4(), value: "" }]);
  };

  const removeSubscriptionIdInput = (id: string) => {
    const newSubscriptionIds = subscriptionIds.filter(
      ({ id: subscriptionId }) => id !== subscriptionId
    );
    setSubscriptionIds(newSubscriptionIds);
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>, id: string) => {
    const newSubscriptionIds = subscriptionIds.map((subscriptionId) => {
      if (subscriptionId.id === id) {
        return {
          id,
          value: event.target.value,
        };
      }

      return subscriptionId;
    });
    setSubscriptionIds(newSubscriptionIds);
  };

  return (
    <>
      <Label required name="Provide Subscription ID" />
      <FormGroup
        error={getFirstItem(
          (errors[AzureFormFields.SUBSCRIPTION_IDS] ?? []).filter(isNotNil)
        )}
      >
        <FlexContainer
          direction="column"
          alignItems="start"
          rowGap={theme.spacing.spacing03}
        >
          {subscriptionIds.map(({ id, value }) => (
            <SubscriptionIdInputField
              key={id}
              removeSubscriptionIdInput={removeSubscriptionIdInput}
              id={id}
              value={value}
              subscriptionIds={subscriptionIds}
              onInputChange={onInputChange}
            />
          ))}
          <Button
            severity="low"
            icon="plus"
            type="button"
            onClick={addSubscriptionIdInput}
          >
            Add Subscription
          </Button>
        </FlexContainer>
      </FormGroup>
    </>
  );
}

type SubscriptionIdInputFieldProps = {
  id: string;
  value: string;
  subscriptionIds: { id: string; value: string }[];
  onInputChange: (event: ChangeEvent<HTMLInputElement>, id: string) => void;
  removeSubscriptionIdInput: (id: string) => void;
};

function SubscriptionIdInputField({
  id,
  value,
  subscriptionIds,
  onInputChange,
  removeSubscriptionIdInput,
}: SubscriptionIdInputFieldProps) {
  return (
    <FlexContainer columnGap={theme.spacing.spacing03} css={{ width: "100%" }}>
      <div css={{ flexGrow: 1 }}>
        <Input
          value={value}
          placeholder="e.g., 291bba3f-e0a5-47bc-a099-3bdcb2a50a05"
          onChange={(event) => onInputChange(event, id)}
        />
      </div>
      <When condition={subscriptionIds.length > 1}>
        <Button
          severity="low"
          icon="trash"
          type="button"
          onClick={() => removeSubscriptionIdInput(id)}
        />
      </When>
    </FlexContainer>
  );
}
