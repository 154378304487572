/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Controller, useForm } from "react-hook-form";
import { When } from "react-if";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  Button,
  Input,
  Label,
  Spacer,
  theme,
} from "@nordcloud/gnui";
import { KpiTargetSelection } from "~/generated/graphql";
import { FormGroup } from "~/components/Forms";
import { KpiTarget } from "~/components/KPI/types";
import { dateFormat, getKpiType, KpiType, mapKpiType } from "~/constants";
import { getFirstItem, getLastItem, isEmpty } from "~/tools";
import { useKpiTargets } from "../hooks";
import { KPITargetInput } from "../types";
import { ContactPersonSelect } from "./ContactPersonSelect";
import { getMissingMonths, MonthSelector } from "./MonthSelector";
import { NonProdHoursHelper } from "./NonProdHoursHelper";
import { StyledNumberFormat } from "./StyledNumberFormat";
import { KpiFormFields, kpiFormSchema } from "./validators";
import { YearSelector } from "./YearSelector";

dayjs.extend(customParseFormat);

type Props = {
  kpiType: KpiType;
  businessContextId?: string;
  onSubmit: (values: KPITargetInput) => void;
  loading: boolean;
  currentTargetTime?: string[];
  editableTarget?: KpiTarget | null;
};

export function TargetCreationForm({
  kpiType,
  businessContextId = "",
  onSubmit,
  loading,
  currentTargetTime = [],
  editableTarget,
}: Props) {
  const { label } = getKpiType(kpiType);

  const isEditing = editableTarget != null;

  const { first, last, missingMonths } = getMissingMonths([
    dayjs(editableTarget?.startDate).format(dateFormat.monthShort),
    dayjs(editableTarget?.endDate).format(dateFormat.monthShort),
  ]);

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<TargetCreation>({
    resolver: yupResolver(kpiFormSchema),
    defaultValues: {
      name: editableTarget?.name,
      goal: editableTarget?.goal,
      contactPersonId: editableTarget?.contactPerson?.id,
      selectedYear: dayjs(editableTarget?.startDate).format(dateFormat.year),
      selectedPeriod: isEditing
        ? getSelectedPeriod(first, last, missingMonths)
        : [],
    },
  });

  const { targets, loading: targetsLoading } = useKpiTargets(
    businessContextId,
    KpiTargetSelection.Upcoming,
    kpiType
  );

  const [selectedYear, selectedPeriod] = watch([
    "selectedYear",
    "selectedPeriod",
  ]);

  const [currentYear, nextYear] = React.useMemo(() => {
    const now = dayjs();
    return [
      now.format(dateFormat.year),
      now.add(1, "year").format(dateFormat.year),
    ];
  }, []);

  React.useEffect(() => {
    setValue("selectedYear", currentYear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const period = selectedPeriod ?? [];
    const base = `${label} (${selectedYear}`;

    if (isEditing) {
      return;
    }

    if (isEmpty(period)) {
      setValue("name", `${base})`);
    }

    if (period.length === 1) {
      const [month] = getStartEnd(period);
      setValue("name", `${base} ${month})`);
    }

    if (period.length > 1) {
      const [start, end] = getStartEnd(period);
      setValue("name", `${base} ${start} - ${end})`);
    }
  }, [selectedYear, selectedPeriod, isEditing]);

  const blocked = React.useMemo(() => {
    const dayjsYear = dayjs(selectedYear, dateFormat.year);
    const startOfYear = dayjsYear.startOf("year");
    const endOfYear = dayjsYear.endOf("year");
    const blockedMonths = targets
      .filter((target) =>
        dayjs(target.startDate).isBetween(startOfYear, endOfYear, "year", "[]")
      )
      .map((target) => [
        dayjs(target.startDate).format(dateFormat.monthShort),
        dayjs(target.endDate).format(dateFormat.monthShort),
      ]);
    if (selectedYear === currentYear) {
      return [currentTargetTime, ...blockedMonths];
    }
    return blockedMonths;
  }, [selectedYear, currentTargetTime, currentYear, targets]);

  const prepareSubmit = (values: TargetCreation) => {
    const input = getCreationInput(values, kpiType, businessContextId);
    onSubmit(input);
  };

  return (
    <form onSubmit={handleSubmit(prepareSubmit)}>
      <When condition={!isEditing}>
        <FormGroup error={errors[KpiFormFields.SELECTED_YEAR]}>
          <Label required name="Select Year" />
          <Spacer height={theme.spacing.spacing02} />
          <Controller
            control={control}
            name={KpiFormFields.SELECTED_YEAR}
            render={({ field: { onChange } }) => (
              <YearSelector
                currentYear={currentYear}
                nextYear={nextYear}
                selectedYear={selectedYear}
                onChange={onChange}
              />
            )}
          />
        </FormGroup>
      </When>
      <FormGroup error={errors[KpiFormFields.SELECTED_PERIOD]}>
        <Label
          name={isEditing ? "Selected Period" : "Select Period"}
          required={!isEditing}
        />
        <Spacer height={theme.spacing.spacing02} />
        <Controller
          control={control}
          name={KpiFormFields.SELECTED_PERIOD}
          render={({ field: { value, onChange } }) => (
            <MonthSelector
              blocked={blocked}
              value={value ?? []}
              thisYear={selectedYear === currentYear}
              loading={targetsLoading}
              disabled={isEditing}
              year={selectedYear}
              onChange={onChange}
            />
          )}
        />
      </FormGroup>
      <FormGroup error={errors[KpiFormFields.NAME]}>
        <Label required name="KPI Target Name" />
        <Input
          {...register(KpiFormFields.NAME, { required: true })}
          type="text"
          placeholder="KPI Target Name"
        />
      </FormGroup>
      <FormGroup error={errors[KpiFormFields.GOAL]}>
        <Label required name="KPI Target Value" />
        <When condition={kpiType === KpiType.NON_PROD_HOURS}>
          <Accordion>
            <AccordionHeader small title="Help me configure the target">
              <AccordionItem>
                <NonProdHoursHelper />
              </AccordionItem>
            </AccordionHeader>
          </Accordion>
          <Spacer height={theme.spacing.spacing02} />
        </When>
        <Controller
          control={control}
          name={KpiFormFields.GOAL}
          render={({ field: { value, onChange } }) => (
            <StyledNumberFormat value={value} onChange={onChange} />
          )}
        />
      </FormGroup>
      <FormGroup error={errors[KpiFormFields.CONTACT_PERSON_ID]}>
        <Label required name="KPI Target Contact Person" />
        <Controller
          control={control}
          name={KpiFormFields.CONTACT_PERSON_ID}
          render={({ field: { onChange } }) => (
            <ContactPersonSelect
              contactPerson={editableTarget?.contactPerson}
              onChange={onChange}
            />
          )}
        />
      </FormGroup>

      <Spacer height={theme.spacing.spacing06} />

      <Button
        type="submit"
        initialState={loading ? "loading" : undefined}
        disabled={loading}
      >
        {isEditing ? "Edit" : "Create new"} target
      </Button>
    </form>
  );
}

type TargetCreation = {
  name: string;
  goal: number;
  contactPersonId: string;
  selectedYear: string;
  selectedPeriod: string[];
};

function getCreationInput(
  input: TargetCreation,
  type: KpiType,
  businessContextId: string
) {
  const year = input.selectedYear;
  const [start, end] = getStartEnd(input.selectedPeriod);
  const startDate = dayjs(`${start} ${year}`, dateFormat.monthYearShortNoComma)
    .startOf("month")
    .format(dateFormat.awsDateTime);
  const endDate = dayjs(`${end} ${year}`, dateFormat.monthYearShortNoComma)
    .endOf("month")
    .format(dateFormat.awsDateTime);

  return {
    name: input.name,
    goal: input.goal,
    contactPersonId: input.contactPersonId,
    type: mapKpiType(type),
    startDate,
    endDate,
    businessContextId,
  };
}

function getStartEnd(period: string[]) {
  return [getFirstItem(period), getLastItem(period)];
}

function getSelectedPeriod(
  first: string,
  last: string,
  missingMonths: string[]
) {
  return first === last ? [first] : [first, ...missingMonths, last];
}
