/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as yup from "yup";
import { REQUIRED_FIELD_WARNING } from "../../constants";

export const enum AwsFormFields {
  ACCOUNT_ID = "accountId",
  BUCKET_ID = "bucketId",
  REGION = "region",
  REPORT_NAME = "reportName",
  REPORT_PATH_PREFIX = "reportPathPrefix",
}

export type FormData = {
  [AwsFormFields.ACCOUNT_ID]: string;
  [AwsFormFields.BUCKET_ID]: string;
  [AwsFormFields.REGION]: string;
  [AwsFormFields.REPORT_NAME]: string;
  [AwsFormFields.REPORT_PATH_PREFIX]: string;
};

export const AwsFormSchema = yup.object().shape({
  [AwsFormFields.ACCOUNT_ID]: yup
    .string()
    .required(REQUIRED_FIELD_WARNING)
    .matches(/\d{12}/, "Enter a 12-digit number.")
    .min(12, "Enter a 12-digit number.")
    .max(12, "Maximum 12-digit number."),
  [AwsFormFields.BUCKET_ID]: yup
    .string()
    .required(REQUIRED_FIELD_WARNING)
    .matches(
      /^(?!xn--)(?!.*-s3alias)[\d._a-z-]+$/,
      "Only lowercase letters allowed, cannot start with the prefix `xn--`, cannot end with the suffix `-s3alias`."
    )
    .min(3, "Minimum 3 characters required.")
    .max(63, "Maximum 63 characters required."),
  [AwsFormFields.REGION]: yup.string().required(REQUIRED_FIELD_WARNING),
  [AwsFormFields.REPORT_NAME]: yup
    .string()
    .required(REQUIRED_FIELD_WARNING)
    .matches(/^(?![#./]).*/, "Unexpected leading '/' in report name.")
    .min(3, "Minimum 3 characters required."),
  [AwsFormFields.REPORT_PATH_PREFIX]: yup
    .string()
    .required(REQUIRED_FIELD_WARNING)
    .matches(/^(?![#./]).*/, "Unexpected leading '/' in report path prefix.")
    .min(3, "Minimum 3 characters required."),
});
