/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Box, Spacer, theme } from "@nordcloud/gnui";
import { Separator } from "~/components";
import { CostAllocationChartWrapper } from "./CostAllocationChartWrapper";
import { UnallocatedSummary } from "./UnallocatedSummary";

export function CostAllocationV2() {
  return (
    <Box spacing="spacing04" mb={theme.spacing.spacing04}>
      <CostAllocationChartWrapper />
      <Spacer height={theme.spacing.spacing04} />
      <Separator />
      <Spacer height={theme.spacing.spacing04} />
      <UnallocatedSummary />
    </Box>
  );
}
