/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Box, Dropdown, FlexContainer, Text, theme } from "@nordcloud/gnui";
import { WorkflowEventStatus } from "~/generated/graphql";
import { useFilterStatus, WorkflowEventStatusExtended } from "../hooks";
import { WorkflowHistoryList } from "./WorkflowHistoryList";

type Props = {
  workflowId?: string;
};

export function FilterableWorkflowHistoryList({ workflowId }: Props) {
  const { currentStatus, handleStatusChange } = useFilterStatus();

  return (
    <>
      <Box boxStyle="lightGrey" spacing="spacing03">
        <FlexContainer>
          <Text tag="div" nowrap mr={theme.spacing.spacing04}>
            Filter By:
          </Text>
          <Dropdown
            name="Select"
            options={FilterOptions}
            onChange={handleStatusChange}
            value={currentStatus}
            width="15rem"
          />
        </FlexContainer>
      </Box>
      <WorkflowHistoryList
        workflowId={workflowId}
        status={mapStatus(currentStatus)}
      />
    </>
  );
}

function mapStatus(status: WorkflowEventStatusExtended) {
  return status === "ALL" ? undefined : status;
}

const FilterOptions = [
  {
    value: "ALL",
    label: "All Status",
  },
  {
    value: WorkflowEventStatus.Completed,
    label: "Success",
  },
  {
    value: WorkflowEventStatus.Failed,
    label: "Failure",
  },
];
