/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Role } from "~/generated/graphql";
import { ChartType } from "~/components";

const applicationsChartTypeOptions = [
  { value: ChartType.ACCUMULATED_COST, label: ChartType.ACCUMULATED_COST },
  { value: ChartType.COST_PER_CATEGORY, label: ChartType.COST_PER_CATEGORY },
  { value: ChartType.COST_PER_SERVICES, label: ChartType.COST_PER_SERVICES },
];

export const getApplicationsChartTypeOptions = (role: Role) => {
  if (role === Role.AdminLite) {
    return applicationsChartTypeOptions;
  }
  return [
    {
      value: ChartType.COST_PER_APPLICATION,
      label: ChartType.COST_PER_APPLICATION,
    },
    ...applicationsChartTypeOptions,
  ];
};
