/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useFormState } from "react-hook-form";
import { Text, theme } from "@nordcloud/gnui";
import { isNotNil } from "~/tools";
import { ComponentGroup, WorkflowDataType } from "../../constants";
import { WorkflowFormMode, WorkflowFormData } from "../../types";
import { ComponentHolder } from "./ComponentHolder";
import { useHoldSelectDataComponent } from "./hooks/useHoldSelectDataComponent";

type Props = {
  formMode: WorkflowFormMode;
  isEmpty?: boolean;
  type?: WorkflowDataType;
  onSettingsClick?: () => void;
};

export function SelectDataHolder({
  formMode,
  type,
  isEmpty = false,
  onSettingsClick,
}: Props) {
  const { errors } = useFormState<WorkflowFormData>();

  const {
    handleDrop,
    handleRemove,
    iconName,
    tagText,
    tagTextColor,
    title,
    tooltipContent,
  } = useHoldSelectDataComponent({ type });

  return (
    <ComponentHolder
      formMode={formMode}
      title={title}
      tooltipCaption={tooltipContent}
      color={theme.color.support.cyan}
      componentIcon={iconName}
      timelineIcon="search"
      timelineLabel="Select Data"
      componentGroup={ComponentGroup.DATA}
      displayPlaceholder={isEmpty}
      hasError={isNotNil(errors.selectDataComponent)}
      onSettingsClick={onSettingsClick}
      onDrop={handleDrop}
      onRemoveClick={handleRemove}
    >
      <Text size="sm" color={tagTextColor}>
        {tagText}
      </Text>
    </ComponentHolder>
  );
}
