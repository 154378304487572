/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApplicationsPaginatedByApplicationNameQuery } from "~/generated/graphql";
import { isNotEmpty, isNotNil } from "~/tools";
import { AppEnvResult } from "./types";

export function formatDataObject(
  data?: ApplicationsPaginatedByApplicationNameQuery
): AppEnvResult {
  return {
    applicationsPaginated: {
      count: data?.applicationsPaginated?.count ?? 0,
      pages: data?.applicationsPaginated?.pages ?? 0,
      results:
        data?.applicationsPaginated?.results?.map((result) => ({
          ...result,
          environments: (result.environments ?? []).filter(isNotNil),
        })) ?? [],
    },
  };
}

export function isArrayFilterSelected(selectedOptions?: string[]) {
  return isNotNil(selectedOptions) && isNotEmpty(selectedOptions);
}
