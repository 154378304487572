/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { useDriftsQuery } from "~/generated/graphql";
import { useQueryState } from "~/hooks";
import { Filters, Modes } from "../types";
import { mapFilters } from "./utils";

type Props = {
  filters?: Filters | undefined;
  modes?: Modes | undefined;
};

export function useDrifts({ filters, modes }: Props) {
  const {
    state: { page, limit },
  } = useQueryState();

  const { data, error, loading } = useDriftsQuery({
    variables: {
      input: {
        limit,
        page,
        filters: mapFilters(modes, filters),
      },
    },
  });

  const drifts = useMemo(
    () => data?.estateAllocationDrifts?.records ?? [],
    [data]
  );
  const count = data?.estateAllocationDrifts?.count ?? 0;

  const allIds = useMemo(() => drifts.map((drift) => drift.id), [drifts]);

  return {
    count,
    drifts,
    allIds,
    error,
    isLoading: loading,
    limit,
  };
}
