/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useFieldArray, useFormContext } from "react-hook-form";
import { When } from "react-if";
import { Button, Box, Label, Spacer, theme, Input } from "@nordcloud/gnui";
import { isEmpty, isNotEmpty, mapInputStatus } from "~/tools";
import { WorkflowFormData } from "~/views/workflows/types";
import { TableFieldWrapper, TableRow } from "../styles";
import { TableRowHeader } from "./TableRowHeader";

type Props = {
  isReadMode: boolean;
};

export function AdditionalFieldsSection({ isReadMode }: Props) {
  const { fields, append, remove } = useFieldArray<WorkflowFormData>({
    name: "serviceNow.additionalMessageFields",
  });
  const {
    register,
    formState: { errors },
  } = useFormContext<WorkflowFormData>();

  if (isReadMode && isEmpty(fields)) {
    return null;
  }

  return (
    <>
      <Label name="Additional Fields" />
      <Spacer height={theme.spacing.spacing02} />
      <When condition={!isReadMode && isEmpty(fields)}>
        <Box boxStyle="lightGrey">
          You can add additional ServiceNow fields
          <Spacer height={theme.spacing.spacing02} />
          <Button
            severity="low"
            type="button"
            onClick={() => append({ value: "" })}
          >
            Add New Item
          </Button>
        </Box>
      </When>
      <When condition={isNotEmpty(fields)}>
        <TableRowHeader />
        {fields.map((field, index) => (
          <TableRow key={field.id}>
            <TableFieldWrapper
              width="40%"
              css={{ paddingRight: theme.spacing.spacing02 }}
            >
              <Input
                key={field.id}
                disabled={isReadMode}
                id={`serviceNowAdditionalMessageFields${index}Key`}
                placeholder="Key"
                {...register(
                  `serviceNow.additionalMessageFields.${index}.key` as const
                )}
                small
                status={
                  errors.serviceNow?.additionalMessageFields?.[index]?.key &&
                  mapInputStatus("error")
                }
              />
            </TableFieldWrapper>
            <TableFieldWrapper width="60%" css={{ display: "flex" }}>
              <Input
                key={field.id}
                disabled={isReadMode}
                id={`serviceNowAdditionalMessageFields${index}Value`}
                placeholder="Value"
                {...register(
                  `serviceNow.additionalMessageFields.${index}.value` as const
                )}
                small
                status={
                  errors.serviceNow?.additionalMessageFields?.[index]?.value &&
                  mapInputStatus("error")
                }
              />
              <When condition={!isReadMode}>
                <Button
                  severity="low"
                  icon="trash"
                  type="button"
                  size="md"
                  ml={theme.spacing.spacing02}
                  onClick={() => remove(index)}
                />
              </When>
            </TableFieldWrapper>
          </TableRow>
        ))}
      </When>
      <When condition={!isReadMode && isNotEmpty(fields)}>
        <Spacer height={theme.spacing.spacing02} />
        <Button
          severity="low"
          type="button"
          onClick={() => append({ value: "" })}
        >
          Add New Item
        </Button>
      </When>
    </>
  );
}
