/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ComponentProps } from "react";
import { theme, Box, Spacer } from "@nordcloud/gnui";
import { ReservationsProvider } from "~/generated/graphql";
import { RecommendationsSettings } from "./components";
import { ReservationsWidgets } from "./ReservationsAndCommitmentsWidgets";

type Props = {
  idText: string;
  paymentOption: ComponentProps<
    typeof RecommendationsSettings
  >["paymentOption"];
  termOption: ComponentProps<typeof RecommendationsSettings>["term"];
  serviceType: ComponentProps<typeof ReservationsWidgets>["serviceUsageType"];
  timePeriod: ComponentProps<typeof ReservationsWidgets>["timePeriod"];
  currentOfferingType: ComponentProps<
    typeof ReservationsWidgets
  >["selectedType"];
  selectedFilters: ComponentProps<typeof ReservationsWidgets>["filters"];
  paymentOptionsFilter: ComponentProps<
    typeof RecommendationsSettings
  >["paymentOptionsFilter"];
  provider: ReservationsProvider;
  hasFilters?: boolean;
  onOfferingType: ComponentProps<typeof ReservationsWidgets>["onClick"];
  onUpdatePaymentOption: ComponentProps<
    typeof RecommendationsSettings
  >["setPaymentOption"];
  onUpdateTermOption: ComponentProps<typeof RecommendationsSettings>["setTerm"];
  onClearFilters?: () => void;
};

export function RecommendationsSettingsWrapper({
  idText,
  paymentOption,
  termOption,
  serviceType,
  timePeriod,
  currentOfferingType,
  selectedFilters,
  paymentOptionsFilter,
  provider,
  hasFilters,
  onOfferingType,
  onUpdatePaymentOption,
  onUpdateTermOption,
  onClearFilters,
}: Props) {
  return (
    <div id={`uf-${idText}-recommendations-settings`}>
      <Box
        padding={theme.spacing.spacing00}
        spacing="spacing04"
        marginBottom={theme.spacing.spacing06}
      >
        <RecommendationsSettings
          paymentOption={paymentOption}
          term={termOption}
          setTerm={onUpdateTermOption}
          setPaymentOption={onUpdatePaymentOption}
          isSidebar={false}
          paymentOptionsFilter={paymentOptionsFilter}
        />
        <Spacer height={theme.spacing.spacing04} />

        <ReservationsWidgets
          paymentOptionsFilter={paymentOptionsFilter}
          provider={provider}
          serviceUsageType={serviceType}
          timePeriod={timePeriod}
          selectedType={currentOfferingType}
          filters={selectedFilters}
          paymentOption={paymentOption}
          termOption={termOption}
          hasFilters={hasFilters}
          onClick={onOfferingType}
          onClearFilters={onClearFilters}
        />
      </Box>
    </div>
  );
}
