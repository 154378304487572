/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then } from "react-if";
import { Currency, FormatMoney, isNotNil } from "~/tools";

export function CurrencyCell({
  value,
  currency,
}: {
  value?: number | string;
  currency: Currency;
}) {
  const floatValue = typeof value === "string" ? parseFloat(value) : value;

  return (
    <If condition={isNotNil(value) && floatValue !== 0}>
      <Then>
        <FormatMoney value={floatValue} currency={currency} />
      </Then>
      <Else>-</Else>
    </If>
  );
}
