/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloError } from "@apollo/client";
import { Else, If, Then } from "react-if";
import {
  BrickLoader,
  FlexContainer,
  Select,
  Spacer,
  Text,
  theme,
} from "@nordcloud/gnui";
import { Ec2InstanceFamily } from "~/generated/graphql";
import { UniversalWrap } from "~/components";
import { CloudAccountMissing } from "../../components";
import {
  FAMILY_TYPES,
  INSTANCE_COMPARISON_CAPTION,
  MISSING_CLOUD_ACCOUNT_MESSAGE,
} from "./constants";
import { Ec2InstanceComparisonTable } from "./Ec2InstanceComparisonTable";
import { OptionWrapper, SmallerLineHeightText, TableWrapper } from "./styles";
import { ComparisonItem } from "./types";

type Props = {
  comparison: ComparisonItem[];
  loading: boolean;
  isActivated: boolean;
  error: ApolloError | undefined;
  onComparisonTypeChange: (type: Ec2InstanceFamily) => void;
  type: Ec2InstanceFamily;
};

export function Ec2InstanceComparison({
  comparison,
  loading,
  isActivated,
  error,
  onComparisonTypeChange,
  type,
}: Props) {
  return (
    <If condition={isActivated}>
      <Then>
        <FlexContainer alignItems="start" columnGap={theme.spacing.spacing08}>
          <div>
            <Text nowrap weight="medium" mb={theme.spacing.spacing02}>
              Choose the appropriate instance type for your use case:
            </Text>
            <Select
              isSearchable={false}
              isDisabled={loading}
              options={FAMILY_TYPES}
              defaultValue={FAMILY_TYPES.find(({ value }) => value === type)}
              components={{ Option: ComparisonOption }}
              styles={{
                control: (base) => ({
                  ...base,
                  width: "25rem",
                }),
              }}
              onChange={(option) =>
                onComparisonTypeChange(
                  option?.value ?? Ec2InstanceFamily.GeneralPurpose
                )
              }
            />
          </div>
          <Text
            color={theme.color.text.text02}
            css={{ borderLeft: `solid 1px ${theme.color.border.border01}` }}
            pl={theme.spacing.spacing06}
          >
            {INSTANCE_COMPARISON_CAPTION}
          </Text>
        </FlexContainer>
        <Spacer height={theme.spacing.spacing06} />
        <UniversalWrap
          errorProps={{ error }}
          loaderProps={{
            loading,
            Component: BrickLoader,
            viewBox: "0 0 3.4 1",
          }}
        >
          <TableWrapper>
            <Ec2InstanceComparisonTable comparison={comparison} />
          </TableWrapper>
        </UniversalWrap>
      </Then>
      <Else>
        <CloudAccountMissing message={MISSING_CLOUD_ACCOUNT_MESSAGE} />
      </Else>
    </If>
  );
}

type DataType = (typeof FAMILY_TYPES)[number];

type ComparisonOptionProps = {
  data: DataType;
  selectOption: (value: DataType) => void;
};

function ComparisonOption({ data, selectOption }: ComparisonOptionProps) {
  return (
    <OptionWrapper onClick={() => selectOption(data)}>
      <Text mb={0} weight="bold">
        {data.label}
      </Text>
      <SmallerLineHeightText size="sm">
        {data.description}
      </SmallerLineHeightText>
    </OptionWrapper>
  );
}
