/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Button, FlexContainer, theme } from "@nordcloud/gnui";

type ActionButtonsProps = {
  onSave: () => void;
  onClear: () => void;
};

export function ActionButtons({ onSave, onClear }: ActionButtonsProps) {
  return (
    <FlexContainer columnGap={theme.spacing.spacing02}>
      <Button px={theme.spacing.spacing08} type="button" onClick={onSave}>
        Apply
      </Button>
      <Button severity="medium" type="button" onClick={onClear}>
        Clear
      </Button>
    </FlexContainer>
  );
}
