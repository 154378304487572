/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Case, Default, Switch } from "react-if";
import { Text, theme } from "@nordcloud/gnui";
import { Currency, FormatMoney, Maybe, isNil } from "~/tools";

type Props = {
  cost: Maybe<string>;
  currency: Currency;
};

export function Costs({ cost, currency }: Props) {
  return (
    <Switch>
      <Case condition={isNil(cost)}>
        <Text mb={0} size="sm" color={theme.color.text.text02}>
          Not Onboarded
        </Text>
      </Case>
      <Case condition={cost === "N/A"}>
        <Text mb={0} size="sm" color={theme.color.text.text02}>
          Cost not available
        </Text>
      </Case>
      <Default>
        <Text mb={0} size="sm" weight="medium">
          <FormatMoney value={cost} currency={currency} />
        </Text>
      </Default>
    </Switch>
  );
}
