/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { useGetVpcsSubnetsQuery } from "~/generated/graphql";
import { isNotNil } from "~/tools";
import { Vpc } from "../types";

export function useFilteredVpcs() {
  const pageSize = 20;
  const [page, setPage] = useState(0);
  const [vpcSearch, setVpcSearch] = useState("");

  const { error, loading, data } = useGetVpcsSubnetsQuery({
    variables: { limit: pageSize, page, query: vpcSearch },
  });

  const filteredVpcs: Vpc[] = (data?.vpcs?.results ?? [])
    .filter(isNotNil)
    .map((vpc) => ({
      nid: vpc.nid,
      vpcId: vpc.vpcId,
      subnets: (
        vpc.subnets?.map((subnet) => ({
          nid: subnet?.nid ?? "",
          subnetId: subnet?.subnetId ?? "",
        })) ?? []
      ).filter(isNotNil),
    }));

  return {
    vpcsCount: data?.vpcs?.count,
    error,
    loading,
    filteredVpcs,
    page,
    pageSize,
    setPage,
    setVpcSearch,
  };
}
