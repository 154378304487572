/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { SortDirection } from "~/components";
import { useQueryState } from "~/hooks";
import { SearchSortOrder } from "~/tools";
import { staticChargeTypeOptions } from "../CloudAccountList/constants";
import { CloudAccountsQueryState } from "../types";
import { getSortFieldByChargeType } from "../utils";

type CloudAccountSortProps = {
  chargeType?: string;
  order?: SearchSortOrder;
};

export function useCloudAccountSort({
  chargeType,
  order,
}: CloudAccountSortProps) {
  const { state, updateQueryState } = useQueryState<CloudAccountsQueryState>();

  const sortParams = useMemo(() => {
    return {
      field: getSortFieldByChargeType(chargeType),
      order: order || SearchSortOrder.Dsc,
      chargeType: chargeType ?? staticChargeTypeOptions[0].value,
    };
  }, [order, chargeType]);

  const onSortSelect = (filter: string) => {
    updateQueryState({
      ...state,
      chargeType: filter,
      order: SearchSortOrder.Dsc,
      page: 0,
    });
  };

  const onSortOrderChange = (direction: SortDirection) => {
    updateQueryState({
      ...state,
      chargeType,
      order:
        direction === SortDirection.Ascending
          ? SearchSortOrder.Asc
          : SearchSortOrder.Dsc,
      page: 0,
    });
  };

  return {
    sortField: sortParams.field,
    sortDirection: sortParams.order,
    chargeType: sortParams.chargeType,
    onSortSelect,
    onSortOrderChange,
  };
}
