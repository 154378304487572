/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloCache } from "@apollo/client";
import {
  ApplicationBudgetDocument,
  ApplicationBudgetQuery,
  ApplicationBudgetQueryVariables,
  SetApplicationYearlyBudgetMutation,
  useSetApplicationYearlyBudgetMutation,
} from "~/generated/graphql";
import { newMonthValues } from "~/components/Budgets";
import { showSuccess } from "~/services/toast";
import {
  generateActionSuccessText,
  handleError,
  Maybe,
  toInputError,
} from "~/tools";

export function useCreateBudget() {
  const [
    createApplicationBudgetMutation,
    {
      client: { cache },
      ...state
    },
  ] = useSetApplicationYearlyBudgetMutation();

  const create = async ({ nid, year, budgetValue, currency }: CreateProps) => {
    try {
      const { data } = await createApplicationBudgetMutation({
        variables: {
          nid,
          budget: {
            budgetByMonth: newMonthValues(budgetValue),
            year,
            currency,
          },
        },
      });

      handleCacheUpdate(data, cache, {
        id: nid,
        year,
        previousYear: (parseInt(year, 10) - 1).toString(),
      });

      showSuccess(generateActionSuccessText("Budget")()("created")());
    } catch (error) {
      handleError(toInputError(error));
    }
  };

  return [create, state] as const;
}

function handleCacheUpdate(
  data: Maybe<SetApplicationYearlyBudgetMutation>,
  cache: ApolloCache<unknown>,
  variables: ApplicationBudgetQueryVariables
) {
  cache.updateQuery<ApplicationBudgetQuery, ApplicationBudgetQueryVariables>(
    { query: ApplicationBudgetDocument, variables },
    (appData): ApplicationBudgetQuery | null => {
      if (
        appData?.application == null ||
        data?.setApplicationYearlyBudget == null
      ) {
        return null;
      }

      return {
        ...appData,
        application: {
          ...appData.application,
          budgetYearly: {
            ...data.setApplicationYearlyBudget,
          },
        },
      };
    }
  );
}

type CreateProps = {
  nid: string;
  year: string;
  budgetValue: number;
  currency: string;
};
