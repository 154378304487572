/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useRemoveIntegrationPluginMutation } from "~/generated/graphql";
import { useConfirmAction } from "~/hooks";
import { showError, showSuccess } from "~/services/toast";
import {
  generateActionConfirmText,
  generateActionSuccessText,
  removeFromApolloCache,
} from "~/tools";
import { IntegrationPluginProp } from "../models/IntegrationPlugin";

export function useRemoveIntegrationPluginAction(onComplete?: () => void) {
  const [removeIntegrationPluginMutation] =
    useRemoveIntegrationPluginMutation();

  return useConfirmAction<IntegrationPluginProp>({
    name: "delete integration plugin",
    action: async ({ id, name }) => {
      try {
        await removeIntegrationPluginMutation({
          variables: { id },
          update: (cache) =>
            removeFromApolloCache(cache, { item: `IntegrationPlugin:${id}` }),
        });
        showSuccess(generateActionSuccessText(`${name}`)()("deleted")());
        onComplete?.();
      } catch (err) {
        if (err instanceof Error) {
          showError(err.message);
        }
      }
    },
    contentText: (_, plugin) =>
      plugin?.name
        ? generateActionConfirmText(`"${plugin.name}"`)("delete")()
        : "",
    actionLabel: "Delete",
    contentLabel: "Delete Integration Plugin",
  });
}
