/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";

export function useScrollDisclosure() {
  const [isScrolled, setIsScrolled] = React.useState(false);
  const scrollToRef = React.useRef<HTMLDivElement>(null);

  const triggerScroll = (state = true) => {
    setIsScrolled(state);
  };

  return { isScrolled, setIsScrolled, triggerScroll, scrollToRef };
}
