/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useReducer, useState } from "react";
import { When } from "react-if";
import { Button, FlexContainer, Sidebar, theme } from "@nordcloud/gnui";
import { FilterHeader, FilterWrapper, Mode } from "~/components";
import { useQueryState } from "~/hooks";
import { isNotEmpty } from "~/tools";
import { ApplyFiltersHandler, CloudAccountsQueryState } from "../types";
import { isKubernetesOrOpenshiftTab } from "../utils";
import { AccountStatusCheckboxGroup } from "./AccountStatusCheckboxGroup";
import { ProviderCheckboxGroup } from "./ProviderCheckboxGroup";
import {
  getProviderFilterValues,
  getProviderReducer,
  getStatusFilterValues,
  getStatusReducer,
} from "./utils";

type Props = {
  isFiltersSidebarOpen: boolean;
  onApplyFilters: ApplyFiltersHandler;
  onHandleClose: () => void;
  onClearFilters: () => void;
};

export function CloudAccountsFilterSidebar({
  isFiltersSidebarOpen,
  onApplyFilters,
  onHandleClose,
  onClearFilters,
}: Props) {
  const {
    state: {
      status,
      provider,
      providerFilter,
      excludeStatus = false,
      excludeProvider = false,
    },
  } = useQueryState<CloudAccountsQueryState>();

  const statusValuesReducer = getStatusReducer(status);
  const providerValuesReducer = getProviderReducer(providerFilter);

  const [statusValues, updateStatusValues] = useReducer(
    statusValuesReducer,
    getStatusFilterValues(status)
  );

  const [providerValues, updateProviderValues] = useReducer(
    providerValuesReducer,
    getProviderFilterValues(providerFilter)
  );

  const [isStatusExcluded, setIsStatusExcluded] = useState(excludeStatus);

  const [isProviderExcluded, setIsProviderExcluded] = useState(excludeProvider);

  useEffect(() => {
    updateStatusValues("setValuesFromQuery");
    updateProviderValues("setValuesFromQuery");
    setIsStatusExcluded(excludeStatus);
    setIsProviderExcluded(excludeProvider);
  }, [status, provider, excludeStatus, excludeProvider]);

  const clearFilters = () => {
    updateStatusValues("clearFilterCheckboxes");
    updateProviderValues("clearFilterCheckboxes");
    setIsStatusExcluded(false);
    setIsProviderExcluded(false);
    onClearFilters();
  };

  const selectedStatusValues = Object.entries(statusValues)
    .filter(([_, value]) => value === true)
    .map(([key]) => key);
  const selectedProvidersValues = Object.entries(providerValues)
    .filter(([_, value]) => value === true)
    .map(([key]) => key);

  const isResetDisabled =
    Object.values(statusValues).every((value) => value === false) &&
    Object.values(providerValues).every((value) => value === false);

  const handleStatusFilterModeChange = (mode: Mode) => {
    setIsStatusExcluded(mode === Mode.EXCLUDE);
  };

  const handleProviderFilterModeChange = (mode: Mode) => {
    setIsProviderExcluded(mode === Mode.EXCLUDE);
  };

  return (
    <Sidebar
      title="Filters"
      isOpen={isFiltersSidebarOpen}
      onClick={onHandleClose}
    >
      <FlexContainer
        direction="column"
        alignItems="start"
        rowGap={theme.spacing.spacing04}
      >
        <FilterWrapper
          isExpandedInitially
          name="Account Onboarding Status"
          selectedOptions={
            isNotEmpty(selectedStatusValues) ? selectedStatusValues : undefined
          }
          onClear={() => {
            updateStatusValues("clearFilterCheckboxes");
          }}
        >
          <FilterHeader
            allowModeChange
            defaultMode={isExcludedToMode(excludeStatus)}
            onModeChange={handleStatusFilterModeChange}
          />
          <AccountStatusCheckboxGroup
            provider={provider}
            statusValues={statusValues}
            updateStatusValues={updateStatusValues}
          />
        </FilterWrapper>

        <When condition={isKubernetesOrOpenshiftTab(provider)}>
          <FilterWrapper
            isExpandedInitially
            name="Cloud Provider"
            selectedOptions={
              selectedProvidersValues.length > 0
                ? selectedProvidersValues
                : undefined
            }
            onClear={() => {
              updateProviderValues("clearFilterCheckboxes");
            }}
          >
            <FilterHeader
              allowModeChange
              defaultMode={isExcludedToMode(excludeProvider)}
              onModeChange={handleProviderFilterModeChange}
            />
            <ProviderCheckboxGroup
              providerValues={providerValues}
              updateProviderValues={updateProviderValues}
            />
          </FilterWrapper>
        </When>

        <FlexContainer>
          <Button
            mr={theme.spacing.spacing02}
            icon="checkmark"
            onClick={() =>
              onApplyFilters({
                statusValues,
                providerValues,
                isStatusExcluded,
                isProviderExcluded,
              })
            }
          >
            Apply filters
          </Button>
          <Button
            severity="low"
            disabled={isResetDisabled}
            onClick={clearFilters}
          >
            Clear filters &amp; reload
          </Button>
        </FlexContainer>
      </FlexContainer>
    </Sidebar>
  );
}

function isExcludedToMode(isExcluded: boolean) {
  return isExcluded ? Mode.EXCLUDE : Mode.INCLUDE;
}
