/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { useApplicationEnvironmentsPaginatedV2LazyQuery } from "~/generated/graphql";
import { dateFormat } from "~/constants";
import { useCurrency } from "~/hooks";
import { formatMoney, isNil } from "~/tools";

type Props = {
  id: string;
};

export function useMappedEnvironments({ id }: Props) {
  const { currency } = useCurrency();
  const currentMonthIndex = dayjs().month();
  const currentYear = dayjs().format(dateFormat.year);

  const [getData, { loading, error }] =
    useApplicationEnvironmentsPaginatedV2LazyQuery();

  const handleGetData = async (page: number, limit: number) => {
    const result = await getData({
      variables: {
        id,
        page,
        limit,
        year: currentYear,
      },
    });
    const envs = result.data?.application?.environmentsV2?.environments ?? [];

    return envs.map((env) => ({
      id: env.id,
      name: env.name,
      savingsSuggestion:
        Number(env.cloudWasteAggregate?.cost ?? "0") > 0
          ? formatMoney(env.cloudWasteAggregate?.cost ?? "0", currency)
          : "-",
      cost: formatMoney(env.cost?.currentMonth ?? "0", currency),
      forecast: formatMoney(env.cost?.forecast ?? "0", currency),
      budget: formatMoney(
        env.budgetYearly?.budgetByMonth[currentMonthIndex] ?? "0",
        currency
      ),
      orgUnits: (env.orgUnitsV2?.orgUnits ?? []).map((orgUnit) => ({
        name: orgUnit.name,
        id: orgUnit.id,
        parentId: orgUnit.businessContext.id,
        envName: env.name,
        color: orgUnit.businessContext.color,
      })),
    }));
  };

  return [
    handleGetData,
    { loading: isNil(loading) ? true : loading, error },
  ] as const;
}
