/**
 * Copyright 2021-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { When } from "react-if";
import { Link } from "react-router-dom";
import {
  Box,
  ExtendedTooltip,
  FlexContainer,
  Spinner,
  SVGIcon,
  Text,
  theme,
  Tooltip,
} from "@nordcloud/gnui";
import { Provider } from "~/generated/graphql";
import { NO_FORECAST_TEXT, dateFormat } from "~/constants";
import { useBillingPeriod } from "~/services/customers";
import { formatMoney } from "~/tools";
import { Co2eSavings, formatCarbonFootprint } from "../Co2e";
import { BrickLoader } from "../Loaders";
import { LoaderWrap } from "../Utils";
import { GridItemWrapper } from "./components";
import { getCustomComponentInstance } from "./customComponent.helpers";
import { getForecastData, getHeaderDate } from "./helpers";
import { useIconStyle } from "./hooks/useIconStyle";
import {
  CarbonFootprintWrapper,
  CostGrid,
  CostWrapper,
  CustomWrapper,
  ForecastWrapper,
  SavingsWrapper,
  Separator,
} from "./styles";
import { CustomComponents, HiddenComponents, LinkedRecords } from "./types";

type Props = {
  customComponent?: CustomComponents;
  customComponentConfig?: CustomComponentConfig;
  hiddenComponents?: HiddenComponents[];
  linkedRecords?: LinkedRecords;
  loading?: boolean;
  numericalValues: NumericalValues;
  provider?: Provider;
  savingSuggestions?: SavingsSuggestions;
};

export function CostBox({
  customComponent,
  customComponentConfig: { scrollToBudget } = {},
  loading = false,
  hiddenComponents = [],
  linkedRecords,
  provider,
  savingSuggestions,
  numericalValues: {
    forecast,
    currentMonth,
    previousMonth,
    currency,
    budget,
    cloudWasteCost,
    co2e,
    co2eSavings,
    originalCost,
    reservedInstanceBenefit,
  },
}: Props) {
  const { isCurrentBillingPeriod } = useBillingPeriod();

  const isHidden = (name: HiddenComponents) => hiddenComponents.includes(name);

  const hideSeparator =
    isHidden(HiddenComponents.FORECAST) &&
    (cloudWasteCost === 0 || isHidden(HiddenComponents.SAVINGS_SUGGESTIONS));

  return (
    <Box
      boxStyle="lightGrey"
      css={{ height: "100%" }}
      padding={
        loading ? theme.spacing.spacing00 : `${theme.spacing.spacing03} 0`
      }
    >
      <LoaderWrap
        loading={loading}
        Component={BrickLoader}
        viewBox="0 0 1.75 1"
      >
        <CostGrid>
          <CostWrapper hide={isHidden(HiddenComponents.COST)}>
            <Cost
              isCurrentBillingPeriod={isCurrentBillingPeriod}
              currentMonth={currentMonth}
              reservedInstanceBenefit={reservedInstanceBenefit}
              currency={currency}
            />
          </CostWrapper>
          <CarbonFootprintWrapper
            hide={isHidden(HiddenComponents.CARBON_FOOTPRINT)}
            data-testid="carbon-footprint-wrapper"
          >
            <CarbonFootprint
              co2e={co2e}
              co2eSavings={co2eSavings}
              isCurrentBillingPeriod={isCurrentBillingPeriod}
            />
          </CarbonFootprintWrapper>
          <ForecastWrapper hide={isHidden(HiddenComponents.FORECAST)}>
            <Forecast
              forecastObject={getForecastData(forecast, previousMonth)}
              currency={currency}
              forecast={forecast}
              prevMonthDate={dayjs().subtract(
                isCurrentBillingPeriod ? 1 : 2,
                "month"
              )}
            />
          </ForecastWrapper>
          <SavingsWrapper
            hide={
              cloudWasteCost === 0 ||
              isHidden(HiddenComponents.SAVINGS_SUGGESTIONS)
            }
          >
            <Savings
              currency={currency}
              cloudWasteCost={cloudWasteCost}
              metadata={savingSuggestions}
            />
          </SavingsWrapper>
          <CustomWrapper hide={customComponent == null}>
            {getCustomComponentInstance(
              {
                budget,
                currency,
                originalCost,
                linkedRecords,
                provider,
                scrollToBudget,
              },
              customComponent
            )}
          </CustomWrapper>
          <Separator hide={hideSeparator} />
        </CostGrid>
      </LoaderWrap>
    </Box>
  );
}

type CostProps = {
  isCurrentBillingPeriod: boolean;
  currentMonth: number;
  currency: string;
  reservedInstanceBenefit: number;
};

function Cost({
  isCurrentBillingPeriod,
  currentMonth,
  currency,
  reservedInstanceBenefit,
}: CostProps) {
  return (
    <GridItemWrapper
      title={`Current Costs (${getHeaderDate(isCurrentBillingPeriod)})`}
    >
      <FlexContainer css={{ gap: theme.spacing.spacing02 }}>
        <Text
          tag="div"
          size="lg"
          weight="medium"
          color={theme.color.text.text01}
        >
          {formatMoney(currentMonth, currency)}
        </Text>
      </FlexContainer>
      <FlexContainer>
        <When condition={reservedInstanceBenefit > 0}>
          <Text size="sm" tag="span">
            + {formatMoney(reservedInstanceBenefit, currency)} covered by RI
          </Text>
        </When>
      </FlexContainer>
    </GridItemWrapper>
  );
}

type CarbonFootprintProps = {
  isCurrentBillingPeriod: boolean;
  co2e: number;
  co2eSavings: number;
};

function CarbonFootprint({
  isCurrentBillingPeriod,
  co2e,
  co2eSavings,
}: CarbonFootprintProps) {
  const showCo2e = co2e > 0;

  return (
    <When condition={showCo2e}>
      <GridItemWrapper
        title={`Carbon Footprint (${getHeaderDate(isCurrentBillingPeriod)})`}
      >
        <FlexContainer css={{ gap: theme.spacing.spacing02 }}>
          <SVGIcon size="sm" name="co2" />
          <Text tag="div" weight="medium">
            {formatCarbonFootprint(co2e)}
          </Text>
        </FlexContainer>
        <Co2eSavings co2eSavings={co2eSavings} />
      </GridItemWrapper>
    </When>
  );
}

type ForecastProps = {
  forecastObject: { higher: boolean; diff: string };
  forecast: number;
  currency: string;
  prevMonthDate: dayjs.Dayjs;
};

function Forecast({
  forecastObject,
  forecast,
  currency,
  prevMonthDate,
}: ForecastProps) {
  const isValidForecast = forecast >= 0;

  const { icon, color } = useIconStyle(isValidForecast, forecastObject.higher);

  return (
    <GridItemWrapper title="Monthly Forecast">
      <FlexContainer>
        <ExtendedTooltip caption={NO_FORECAST_TEXT}>
          <SVGIcon size="sm" name={icon} color={color} />
        </ExtendedTooltip>
        <When condition={isValidForecast}>
          <Text ml={theme.spacing.spacing01} tag="span" weight="medium">
            {formatMoney(forecast, currency)}
          </Text>
        </When>
      </FlexContainer>
      <When condition={isValidForecast}>
        <FlexContainer css={{ gap: theme.spacing.spacing01 }}>
          <Text size="sm" tag="span" color={color}>
            {formatMoney(forecastObject.diff, currency)}
          </Text>
          <Text size="sm" tag="span">
            {forecastObject.higher ? " more" : " less"} than{" "}
            {prevMonthDate.format(dateFormat.monthYearShortNoComma)}
          </Text>
        </FlexContainer>
      </When>
    </GridItemWrapper>
  );
}

type SavingsProps = {
  cloudWasteCost: number;
  currency: string;
  metadata?: SavingsSuggestions;
};

function Savings({ cloudWasteCost, currency, metadata }: SavingsProps) {
  const content = (data: SavingsSuggestions) =>
    data.loading ? (
      <Spinner size="sm" />
    ) : (
      <Tooltip caption={`${data.reason}`}>
        <Link to={data.uri} onClick={() => data.clicked?.()}>
          <SVGIcon size="sm" name="info" />
        </Link>
      </Tooltip>
    );
  const savingsIconWithTooltip =
    metadata == null ? null : (
      <When condition={metadata.show}>{content(metadata)}</When>
    );

  return (
    <GridItemWrapper title="Potential Monthly Savings">
      <FlexContainer css={{ gap: theme.spacing.spacing02 }}>
        <SVGIcon size="sm" name="cloudWaste" color="success" />
        <Text tag="div" color="success" weight="medium">
          {formatMoney(cloudWasteCost ?? 0, currency)}
        </Text>
        {savingsIconWithTooltip}
      </FlexContainer>
      <FlexContainer css={{ gap: theme.spacing.spacing02 }}>
        <Text size="sm" tag="span">
          {formatMoney(12 * (cloudWasteCost ?? 0), currency)} next 12 months
          savings
        </Text>
      </FlexContainer>
    </GridItemWrapper>
  );
}

type NumericalValues = {
  forecast: number;
  currentMonth: number;
  previousMonth: number;
  currency: string;
  budget: number | null;
  cloudWasteCost: number;
  co2e: number;
  co2eSavings: number;
  originalCost: number;
  reservedInstanceBenefit: number;
};

type SavingsSuggestions = {
  show: boolean;
  reason: string;
  uri: string;
  loading: boolean;
  clicked?: () => void;
};

type CustomComponentConfig = {
  scrollToBudget?: () => void;
};
