/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { McaLightProvider, StringProvider } from "../../types";

export function useSelectProvider(defaultProvider?: StringProvider) {
  const [selectedProvider, setSelectedProvider] = useState<StringProvider>(
    defaultProvider ?? "Aws"
  );

  const providersList = Object.values(McaLightProvider);

  const onSelect = (provider: StringProvider) => {
    setSelectedProvider(provider);
  };

  return {
    selectedProvider,
    providersList,
    onSelect,
  };
}
