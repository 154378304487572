/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import styled from "styled-components";
import {
  ExtendedTooltip,
  FlexContainer,
  SVGIcon,
  Text,
  theme,
} from "@nordcloud/gnui";
import { BusinessContextBox } from "~/layout/MenuBar/submenus/styles";
import { CheckboxTree } from "./CheckboxTree";
import { TreeActionBox } from "./TreeActionBox";
import { OrgUnitsByGroups, TreeGroups, TreeHandlers, TreeState } from "./types";

type Props = {
  title: string;
  disabled: boolean;
  treeHandlers: TreeHandlers;
  treeState: TreeState;
  treeGroups: TreeGroups;
  orgUnitsByGroups: OrgUnitsByGroups;
};

export function TreeSelectorGrouped({
  title,
  disabled,
  treeHandlers,
  treeState,
  treeGroups,
  orgUnitsByGroups,
}: Props) {
  return (
    <FlexContainer alignItems="baseline">
      <BusinessContextContainer>
        <FlexContainer
          direction="column"
          rowGap={theme.spacing.spacing01}
          alignItems="flex-start"
          px={theme.spacing.spacing04}
        >
          <Text color={theme.color.text.text02} mb={theme.spacing.spacing02}>
            Business Context
          </Text>
          {orgUnitsByGroups.map(({ id, name, color, isPrimary }) => (
            <GroupRow
              key={id}
              openedGroupId={treeGroups.groupSelected}
              groupMembersSelected={treeGroups.selectedGroupsMembers}
              data={{
                id,
                name,
                color: color ?? "",
                isPrimary: isPrimary ?? false,
              }}
              onChange={treeGroups.setGroupSelected}
            />
          ))}
        </FlexContainer>
      </BusinessContextContainer>
      <TreeSelectorWrapper>
        <TreeActionBox
          title={title}
          disabled={disabled}
          onSelectAll={treeHandlers.handleSelectAll}
          onDeselectAll={treeHandlers.handleDeselectAll}
        />
        <CheckboxTree
          selected={treeState.selectedList}
          expanded={treeState.expandedList}
          composition={treeState.currentTree}
          separator="->"
          onChange={treeHandlers.setSelectedList}
          onExpand={treeHandlers.setExpandedList}
        />
      </TreeSelectorWrapper>
    </FlexContainer>
  );
}

const TreeSelectorWrapper = styled.div`
  width: 100%;
  height: 81vh;
  overflow-y: scroll;
`;

const BusinessContextContainer = styled.div`
  border-right: solid 1px ${theme.color.border.border01};
  width: 25rem;
  height: 81vh;
  overflow-y: scroll;
`;

type GroupProps = {
  data: {
    id: string;
    name: string;
    color: string;
    isPrimary: boolean;
  };
  groupMembersSelected: {
    id: string;
    selectedMembersCount?: number;
  }[];
  onChange: (id: string) => void;
  openedGroupId: string;
};

function GroupRow({
  data,
  onChange,
  openedGroupId,
  groupMembersSelected,
}: GroupProps) {
  const isSelected = data.id === openedGroupId;
  const selectedMembersCount =
    groupMembersSelected.find(({ id }) => id === data.id)
      ?.selectedMembersCount ?? 0;

  return (
    <GroupButton selected={isSelected} onClick={() => onChange(data.id)}>
      <FlexContainer columnGap={theme.spacing.spacing02}>
        <BusinessContextBox color={data.color ?? "primary"} />
        {data.name}
        <When condition={data.isPrimary}>
          <ExtendedTooltip
            caption="Primary Business Context"
            placement="top"
            zIndex={theme.zindex.topoftheworld}
          >
            <SVGIcon
              name="starFilled"
              size="sm"
              css={{ marginLeft: theme.spacing.spacing02 }}
            />
          </ExtendedTooltip>
        </When>
      </FlexContainer>
      <When condition={selectedMembersCount > 0}>
        <Counter tag="span" size="sm">
          {selectedMembersCount}
        </Counter>
      </When>
    </GroupButton>
  );
}

const GroupButton = styled.div<{ selected: boolean }>`
  align-items: center;
  box-sizing: border-box;
  border-radius: ${theme.radius.md};
  cursor: pointer;
  column-gap: ${theme.spacing.spacing02};
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing.spacing01} ${theme.spacing.spacing03};
  width: 100%;

  &:hover {
    background-color: ${theme.color.interactive.secondary};
  }

  &:active {
    background-color: ${theme.color.interactive.secondaryActive};
  }

  ${({ selected }) =>
    selected &&
    `
    background-color: ${theme.color.interactive.secondaryHover};
  `}
`;

const Counter = styled(Text)`
  color: ${theme.color.text.text04};
  background-color: ${theme.color.background.ui05};
  border-radius: ${theme.radius.xxl};
  padding: 0 ${theme.spacing.spacing02};
`;
