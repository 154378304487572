/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as yup from "yup";

export const enum KpiFormFields {
  NAME = "name",
  GOAL = "goal",
  CONTACT_PERSON_ID = "contactPersonId",
  SELECTED_YEAR = "selectedYear",
  SELECTED_PERIOD = "selectedPeriod",
}

export const kpiFormSchema = yup.object().shape({
  [KpiFormFields.NAME]: yup.string().required("KPI Target Name is required"),
  [KpiFormFields.GOAL]: yup.number().required("KPI Target Value is required"),
  [KpiFormFields.CONTACT_PERSON_ID]: yup
    .string()
    .required("KPI Target Contact Person is required"),
  [KpiFormFields.SELECTED_YEAR]: yup
    .string()
    .required("KPI Target Year is required"),
  [KpiFormFields.SELECTED_PERIOD]: yup
    .array()
    .min(1, "At least one month needs to be chosen"),
});
