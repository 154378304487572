/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  GcpBillingDataDocument,
  GcpBillingDataQuery,
  GcpBillingDataQueryVariables,
  useUpdateGcpBillingConfigV2Mutation,
} from "~/generated/graphql";
import { isNotNil } from "~/tools";
import { UpdateFormData } from "../types";

export function useUpdateGcpBillingData() {
  const [updateBillingDataItem, { client, ...mutationState }] =
    useUpdateGcpBillingConfigV2Mutation();

  const updateGcpBillingData = async (data: UpdateFormData) => {
    const response = await updateBillingDataItem({
      variables: {
        input: {
          onboardedOrgId: data.onboardedOrgId,
          serviceAccountKey: data.serviceAccountKey,
          projectId: data.projectId,
          bigQueryDataset: data.bigQueryDataset,
          bigQueryTable: data.bigQueryTable,
          useDetailedBillingData: data.useDetailedBillingData,
        },
      },
    });
    const updatedItem = response.data?.updateGcpBillingConfigV2;

    if (isNotNil(updatedItem)) {
      client.cache.updateQuery<
        GcpBillingDataQuery,
        GcpBillingDataQueryVariables
      >(
        {
          query: GcpBillingDataDocument,
        },
        (billingData) => ({
          gcpBillingData: (billingData?.gcpBillingData ?? []).map((item) =>
            item?.onboardedOrgId === updatedItem.onboardedOrgId
              ? updatedItem
              : item
          ),
        })
      );
    }
  };

  return [updateGcpBillingData, mutationState] as const;
}
