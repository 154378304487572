/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import {
  AnimatedAxis,
  AnimatedLineSeries,
  buildChartTheme,
  Grid,
  Tooltip,
  TooltipData,
  XYChart,
} from "@visx/xychart";
import { NumberValue } from "d3-scale";
import dayjs from "dayjs";
import { When } from "react-if";
import { FlexContainer, Text, theme } from "@nordcloud/gnui";
import { ChartDataDisplayWrapper, ColorBox } from "~/components";
import { dateFormat } from "~/constants";
import {
  abbreviateNumber,
  generateNumericArray,
  isNil,
  isNotEmpty,
  isNotNil,
} from "~/tools";
import { UtilizationData, UtilizationTooltipItem } from "./types";

type Props = {
  seriesOneData: UtilizationData[];
  seriesTwoData?: UtilizationData[];
  parentWidth: number;
  parentHeight: number;
  tooltip: UtilizationTooltipItem;
  timeTicks: string[];
  emptyReason?: string;
};

export function UtilizationChart({
  seriesOneData,
  seriesTwoData,
  parentWidth,
  parentHeight,
  tooltip,
  timeTicks,
  emptyReason,
}: Props) {
  const isEmpty = [...seriesOneData, ...(seriesTwoData ?? [])].every((item) =>
    isNil(item.value)
  );

  const tickLength = timeTicks.length;

  const seriesOne = useMemo(
    () =>
      isEmpty ? getPlaceholder(timeTicks, 11) : merge(timeTicks, seriesOneData),
    [isEmpty, timeTicks, seriesOneData]
  );

  const seriesTwo = useMemo(() => {
    if (Array.isArray(seriesTwoData)) {
      if (isEmpty) {
        return getPlaceholder(timeTicks, 200);
      }
      return merge(timeTicks, seriesTwoData);
    }

    return [];
  }, [isEmpty, timeTicks, seriesTwoData]);

  return (
    <ChartDataDisplayWrapper
      hasData={isEmpty === false}
      messageComponent={<NoData message={emptyReason} />}
      fallbackComponentProps={{
        size: "lg",
        renderOnTop: true,
      }}
    >
      <XYChart
        xScale={{ type: "band" }}
        yScale={{ type: "linear" }}
        height={parentHeight}
        width={parentWidth}
        margin={{ top: 20, right: 10, bottom: 20, left: 35 }}
        theme={buildChartTheme({
          backgroundColor: theme.color.interactive.primary,
          colors: [],
          gridColor: theme.color.border.focus,
          gridColorDark: theme.color.border.focus,
          tickLength: 4,
        })}
      >
        <Grid
          key="grid"
          rows
          columns={false}
          numTicks={6}
          strokeDasharray="1,3"
          strokeWidth={1}
        />
        <AnimatedAxis
          key="value-axis"
          hideZero
          hideTicks
          orientation="left"
          numTicks={6}
          tickFormat={(value: NumberValue) => abbreviateNumber(Number(value))}
          stroke={theme.color.border.focus}
          tickStroke={theme.color.border.focus}
          animationTrajectory="min"
          tickLabelProps={() => ({
            fill: theme.color.text.text02,
            fontSize: 8,
            fontFamily: theme.fonts.body,
          })}
        />
        <AnimatedAxis
          key="time-axis"
          orientation="bottom"
          stroke={theme.color.border.focus}
          strokeWidth={1}
          tickValues={timeTicks}
          animationTrajectory="min"
          tickFormat={(date: string, index) =>
            getTickFormat(date, index, tickLength)
          }
          tickLabelProps={() => ({
            fill: theme.color.text.text02,
            fontSize: 8,
            textAnchor: "middle",
            fontFamily: theme.fonts.body,
          })}
        />
        <AnimatedLineSeries
          dataKey="seriesOne"
          data={seriesOne}
          strokeDasharray="1,1"
          stroke={theme.color.support.indigo}
          xAccessor={({ date }: UtilizationData) => date}
          yAccessor={({ value }: UtilizationData) => Number(value)}
        />
        <When condition={isNotEmpty(seriesTwo)}>
          <AnimatedLineSeries
            dataKey="seriesTwo"
            data={seriesTwo}
            strokeDasharray="1,1"
            stroke={theme.color.support.purple}
            xAccessor={({ date }: UtilizationData) => date}
            yAccessor={({ value }: UtilizationData) => Number(value)}
          />
        </When>
        <When condition={isEmpty === false}>
          <Tooltip<UtilizationData>
            detectBounds
            showVerticalCrosshair
            snapTooltipToDatumX
            showSeriesGlyphs
            renderTooltip={({ tooltipData }) =>
              getTooltipContent({
                tooltipData,
                tooltip,
              })
            }
          />
        </When>
      </XYChart>
    </ChartDataDisplayWrapper>
  );
}

type TooltipContentProps = {
  tooltipData: TooltipData<UtilizationData> | undefined;
  tooltip: UtilizationTooltipItem;
};

function getTooltipContent({
  tooltipData,
  tooltip: tooltipConfig,
}: TooltipContentProps) {
  if (tooltipData === undefined) {
    return null;
  }

  const { seriesOne, seriesTwo } = tooltipData.datumByKey;

  const { date } = seriesOne.datum;

  const { formatter, colors, labels } = tooltipConfig;

  const seriesTwoVisual =
    seriesTwo == null ? null : (
      <TooltipItem
        value={seriesTwo.datum.value}
        label={labels[1]}
        color={colors[1]}
        formatter={formatter}
      />
    );

  return (
    <FlexContainer direction="column" alignItems="start">
      <Text
        color={theme.color.text.text04}
        size="sm"
        weight="medium"
        mb={theme.spacing.spacing01}
      >
        {dayjs(date).format(dateFormat.dayShortMonthYear)}{" "}
      </Text>
      <TooltipItem
        value={seriesOne.datum.value}
        label={labels[0]}
        color={colors[0]}
        formatter={formatter}
      />
      {seriesTwoVisual}
    </FlexContainer>
  );
}

type TooltipItemProps = {
  color: string;
  label: string;
  value: string | undefined;
  formatter: (v: string) => string;
};

function TooltipItem({ color, label, value, formatter }: TooltipItemProps) {
  return (
    <FlexContainer gap={theme.spacing.spacing01}>
      <ColorBox color={color} border={`solid 1px ${theme.color.text.text04}`} />
      <FlexContainer gap={theme.spacing.spacing01}>
        <Text color={theme.color.text.text04} size="sm" mb={0}>
          {label}:
        </Text>
        <Text color={theme.color.text.text04} size="sm" mb={0} weight="medium">
          {value == null ? "No value registered" : formatter(value)}
        </Text>
      </FlexContainer>
    </FlexContainer>
  );
}

function getPlaceholder(ticks: string[], seed = 1) {
  return generateNumericArray(ticks.length).map((number, index, array) => ({
    date: ticks[index],
    value: (
      (seed * (number * (array.length - index) + index)) %
      array.length
    ).toString(),
  }));
}

function getTickFormat(date: string, index: number, ticksLength: number) {
  // Change date formatting for yearly data set when there is the same month
  // Shown twice on the chart (eg. August 2021 and August 2022)
  const format =
    ticksLength > 330 ? dateFormat.dayShortMonthYear : dateFormat.dayMonthShort;
  const formattedDate = dayjs(date).format(format);

  return index % getTickFormatCoefficient(ticksLength) >= 1
    ? undefined
    : formattedDate;
}

function getTickFormatCoefficient(ticksLength: number) {
  // Yearly data set
  if (ticksLength > 120) {
    return 39; // Show every 39th tick, resulting in ~ 10 ticks
  }

  // Quarterly data set
  if (ticksLength > 31) {
    return 7; // Show every 7th tick, resulting in ~ 13 ticks
  }

  return 2; // Show every 2nd tick for monthly data set
}

function merge(timeTicks: string[], data: UtilizationData[]) {
  return timeTicks.map(
    (tick) =>
      data.find((item) => item.date === tick) ?? {
        date: tick,
        value: undefined,
      }
  );
}

type NoDataProps = {
  message?: string;
};

function NoData({ message }: NoDataProps) {
  return (
    <FlexContainer direction="column" rowGap={theme.spacing.spacing01}>
      <Text weight="medium" mb={0}>
        No data available.
      </Text>
      <When condition={isNotNil(message)}>
        <Text weight="medium" mb={0}>
          {message}
        </Text>
      </When>
    </FlexContainer>
  );
}
