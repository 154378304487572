/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useFormContext } from "react-hook-form";
import { Checkbox, FlexContainer, Label, Text, theme } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { WorkflowFormData } from "~/views/workflows/types";
import { PreviewButton } from "../../EmailSettingsForm/PreviewButton";
import { parseFieldErrors } from "./utils";

type Props = {
  isReadMode: boolean;
  onPreviewClick: () => void;
};

export function KpiOptionsDataTypeField({ isReadMode, onPreviewClick }: Props) {
  const {
    register,
    formState: { errors },
  } = useFormContext<WorkflowFormData>();
  const { kpiOptionsError } = parseFieldErrors(errors);

  return (
    <>
      <FlexContainer
        paddingBottom={theme.spacing.spacing02}
        alignItems="center"
        justifyContent="space-between"
      >
        <Label
          css={{ marginBottom: theme.spacing.spacing00, textTransform: "none" }}
          name="Select Data Type(s)"
          required={!isReadMode}
        />
        <PreviewButton type="button" size="sm" onClick={() => onPreviewClick()}>
          Sample Message
        </PreviewButton>
      </FlexContainer>
      <Text size="sm" color={theme.color.text.text03}>
        KPIs for the selected Business Context(s) will be sent. Please select
        one or more data types which will be located in a ServiceNow field.
      </Text>
      <FormGroup error={kpiOptionsError}>
        <Checkbox
          disabled={isReadMode}
          {...register("serviceNow.kpiOptions.costAllocation")}
          labelText="Cost Allocation Coverage KPI"
        />
        <Checkbox
          disabled={isReadMode}
          {...register("serviceNow.kpiOptions.overallWaste")}
          labelText="Overall Waste KPI"
        />
        <Checkbox
          disabled={isReadMode}
          {...register("serviceNow.kpiOptions.nonProdHours")}
          labelText="Non-Production Environments Shutdown Time KPI"
        />
      </FormGroup>
    </>
  );
}
