/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const NOT_AVAILABLE_TEXT = "-";

export const emptyPlanObj = {
  awsType: "",
  instanceFamily: "",
  commitment: "",
  paymentOption: "",
  upFront: "",
  term: 0,
  startDate: "",
  endDate: "",
  monthly: "",
};
