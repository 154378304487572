/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { generatePath, Link } from "react-router-dom";
import {
  FlexContainer,
  Hidden,
  PieChart,
  SVGIcon,
  Tag,
  Text,
  theme,
  Tooltip,
} from "@nordcloud/gnui";
import { ROUTES } from "~/routing/routes";
import { inflect } from "~/tools";
import { getApplicationStatus } from "./ApplicationStatus";
import { ApplicationDesc } from "./Types";

type Props = {
  application: ApplicationDesc;
  isApplicationPage?: boolean;
  isBusinessContextPage?: boolean;
  isEstateRecordPage?: boolean;
};

export function ApplicationItemDescription({
  application,
  isApplicationPage,
  isBusinessContextPage,
  isEstateRecordPage,
}: Props) {
  const status = getApplicationStatus(application.cost.forecastToPrev);
  const envsCount = application.envsCount ?? application.envs?.length ?? 0;

  const hasEnvironments = envsCount > 0;
  const hasEstateRecords = application.resources > 0;

  return (
    <>
      <When condition={isApplicationPage}>
        <Hidden xs>
          <Tooltip
            status={status.color}
            caption={status.tooltip}
            position="left"
          >
            <PieChart
              progress={application.cost.forecastToPrev}
              size={52}
              strokeWidth={8}
              color={status.color}
              ml={theme.spacing.spacing03}
            >
              <SVGIcon name={status.icon} color={status.color} />
            </PieChart>
          </Tooltip>
        </Hidden>
      </When>
      <FlexContainer direction="column" alignItems="start">
        <Text
          weight="medium"
          size={isApplicationPage ? "md" : "lg"}
          tag="div"
          mb={theme.spacing.spacing01}
          ml={theme.spacing.spacing04}
        >
          <Link
            to={generatePath(ROUTES.applications.details, {
              application: application.id,
            })}
          >
            {application.name}
          </Link>
        </Text>
        <When condition={application.hasAnomalies && isApplicationPage}>
          <Tag
            text="Anomaly"
            color="warning"
            css={{
              marginLeft: theme.spacing.spacing04,
            }}
          />
        </When>
        <FlexContainer>
          <When condition={isApplicationPage || isEstateRecordPage}>
            <FlexContainer
              ml={theme.spacing.spacing04}
              columnGap={theme.spacing.spacing02}
            >
              <When condition={hasEnvironments}>
                <Text size="sm" tag="span">
                  {envsCount} {inflect("Environment")(envsCount)}
                </Text>
              </When>
              <When condition={hasEnvironments && hasEstateRecords}>
                <Text
                  as="span"
                  mb={0}
                  color={theme.color.text.text02}
                  size="sm"
                >
                  |
                </Text>
              </When>
              <When condition={hasEstateRecords}>
                <Text size="sm" tag="span">
                  {application.resources}{" "}
                  {inflect("Estate Record")(application.resources)}
                </Text>
              </When>
            </FlexContainer>
          </When>
          <When condition={isBusinessContextPage}>
            <Text size="sm" tag="span" ml={theme.spacing.spacing04}>
              {envsCount} {inflect("Environment")(envsCount)} attached to this
              OU
            </Text>
          </When>
        </FlexContainer>
      </FlexContainer>
    </>
  );
}
