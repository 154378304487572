/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  Box,
  FlexContainer,
  MultipleSelect,
  SelectButton,
  Text,
  theme,
} from "@nordcloud/gnui";
import {
  ReservationsRecommendationPaymentOption,
  ReservationsRecommendationTerm,
} from "~/generated/graphql";
import { termsFilter } from "../constants";
import { PaymentOptionsFilter } from "../types";

type Props = {
  paymentOption: ReservationsRecommendationPaymentOption;
  term: ReservationsRecommendationTerm;
  setTerm: (term: ReservationsRecommendationTerm) => void;
  setPaymentOption: (
    paymentOption: ReservationsRecommendationPaymentOption
  ) => void;
  isSidebar: boolean;
  paymentOptionsFilter: PaymentOptionsFilter[];
};

export function RecommendationsSettings({
  paymentOption,
  term,
  setPaymentOption,
  setTerm,
  isSidebar,
  paymentOptionsFilter,
}: Props) {
  const flexDirection = isSidebar ? "column" : "row";
  const title = isSidebar
    ? "Recommendations Options"
    : "Recommendations Settings";
  const marginTop = !isSidebar ? theme.spacing.spacing01 : 0;

  return (
    <>
      <Text weight="medium" marginBottom={theme.spacing.spacing02}>
        {title}
      </Text>
      <Box boxStyle="lightGrey" p={theme.spacing.spacing03}>
        <FlexContainer columnGap={theme.spacing.spacing06}>
          <FlexContainer css={{ flexDirection, alignItems: "flex-start" }}>
            <Text
              mb={theme.spacing.spacing01}
              mr={theme.spacing.spacing05}
              style={{ marginTop }}
            >
              Term:
            </Text>
            <MultipleSelect size="small">
              {termsFilter.map((termfilter) => (
                <SelectButton
                  key={termfilter.name}
                  name={termfilter.name}
                  value={termfilter.value}
                  labelText={termfilter.labelText}
                  isActive={term === termfilter.value}
                  style={{ width: "8.5rem" }}
                  onClick={() => setTerm(termfilter.value)}
                />
              ))}
            </MultipleSelect>
          </FlexContainer>
          <FlexContainer css={{ flexDirection, alignItems: "flex-start" }}>
            <Text
              mb={theme.spacing.spacing01}
              mr={theme.spacing.spacing05}
              style={{ marginTop }}
            >
              Payment Option:
            </Text>
            <MultipleSelect size="small">
              {paymentOptionsFilter.map((paymentFilter) => (
                <SelectButton
                  key={paymentFilter.name}
                  name={paymentFilter.name}
                  value={paymentFilter.value}
                  labelText={paymentFilter.labelText}
                  isActive={paymentOption === paymentFilter.value}
                  style={{ width: "9rem" }}
                  onClick={() => setPaymentOption(paymentFilter.value)}
                />
              ))}
            </MultipleSelect>
          </FlexContainer>
        </FlexContainer>
      </Box>
    </>
  );
}
