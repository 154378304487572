/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import styled, { css } from "styled-components";
import {
  FlexContainer,
  SVGIcon,
  Text,
  theme,
  Tag,
  Checkbox,
} from "@nordcloud/gnui";
import { useDisclosure } from "~/hooks";
import { isEmpty, isNotEmpty, isNotNil } from "~/tools";
import { Node, TreeEvent } from "./types";

type Props = {
  data: Node;
  offset?: number;
  disableExpanding?: boolean;
  onCheck: (event: TreeEvent) => void;
  disabled: boolean;
};

export function TreeNode({
  data,
  offset = 0,
  onCheck,
  disabled,
  disableExpanding,
}: Props) {
  const { isOpen, toggle } = useDisclosure(
    isNotNil(data.isOpen) ? data.isOpen : true
  );
  const handleCheckboxChange = () => {
    onCheck({ data });
  };

  const icon = isEmpty(data.children) ? (
    <SVGIcon
      name="minus"
      css={{
        width: theme.spacing.spacing04,
        height: theme.spacing.spacing04,
      }}
    />
  ) : (
    <HeaderIcon animate={isOpen} onClick={() => toggle()}>
      <SVGIcon
        name="chevronDown"
        css={{
          width: theme.spacing.spacing04,
          height: theme.spacing.spacing04,
        }}
      />
    </HeaderIcon>
  );

  return (
    <>
      <TreeRow
        ml={`${offset}rem`}
        p={theme.spacing.spacing02}
        justifyContent="space-between"
        checked={data.checked}
      >
        <FlexContainer columnGap={theme.spacing.spacing04}>
          <When condition={!disableExpanding}>{icon}</When>
          <FlexContainer>
            <Checkbox
              withoutLabel
              checked={data.checked}
              disabled={disabled}
              onChange={handleCheckboxChange}
              onKeyDown={(event) => event.preventDefault()}
            />
          </FlexContainer>
          <FlexContainer direction="column" alignItems="flex-start">
            <Text mb="0">{data.label}</Text>
            <When condition={isNotNil(data.subtext)}>
              <Text
                size="xs"
                mb="0"
                color={theme.color.text.text02}
                lineHeight="1.2rem"
              >
                {data.subtext}
              </Text>
            </When>
          </FlexContainer>
          <When condition={isNotEmpty(data.tags ?? [])}>
            <FlexContainer ml={theme.spacing.spacing02}>
              {data.tags?.map((text) => (
                <Tag
                  key={text}
                  text={text}
                  css={{ fontSize: theme.fontSizes.xs, margin: 0 }}
                />
              ))}
            </FlexContainer>
          </When>
        </FlexContainer>
      </TreeRow>
      <When condition={isOpen && !disableExpanding}>
        {data.children.map((node) => (
          <TreeNode
            key={node.id}
            data={node}
            offset={offset + ROW_MARGIN_IN_REMS}
            disabled={disabled}
            onCheck={onCheck}
          />
        ))}
      </When>
    </>
  );
}

const ROW_MARGIN_IN_REMS = 1.5;

type TreeRowProps = {
  checked: boolean;
};

const TreeRow = styled(FlexContainer)<TreeRowProps>`
  border-bottom: solid 1px ${theme.color.border.border01};
  background-color: ${({ checked }) =>
    checked ? theme.color.background.ui03 : "inherit"};
`;

type HeaderIconProps = {
  animate?: boolean;
};

const HeaderIcon = styled.div<HeaderIconProps>`
  display: flex;
  cursor: pointer;
  svg {
    transition: ${theme.transition};
    transform-origin: center;
    transform: rotate(-90deg);
  }
  ${({ animate }) =>
    animate &&
    css`
      svg {
        transform: rotate(0);
        transform-origin: center;
        transition: ${theme.transition};
      }
    `}
`;
