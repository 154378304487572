/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Provider } from "~/tests/types";
import { Costs } from "../../types";
import { EstateRecord, LinkedResources } from "../types";

type Props = {
  estateRecord: EstateRecord;
  costs: Costs;
  linkedResources: LinkedResources | undefined;
};

export function useHeader({ estateRecord, costs, linkedResources }: Props) {
  return {
    name: estateRecord.name ?? "",
    providerId: estateRecord.providerId ?? "",
    providerType: estateRecord.providerType ?? Provider.Aws,
    account: estateRecord.cloudAccountId ?? "",
    costs,
    linkedResources,
  };
}
