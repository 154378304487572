/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  RemoveOrgUnitTypeMutationVariables,
  useRemoveOrgUnitTypeMutation,
} from "~/generated/graphql";
import { removeFromApolloCache } from "~/tools";

export function useRemoveOrgUnitType(
  options?: Parameters<typeof useRemoveOrgUnitTypeMutation>[0]
) {
  const [remove, { client, ...rest }] = useRemoveOrgUnitTypeMutation({
    ...options,
  });

  const removeOrgUnitType = async (
    variables: RemoveOrgUnitTypeMutationVariables
  ) => {
    await remove({ variables });

    removeFromApolloCache(client.cache, {
      item: `OrgUnitTypeV2:${variables.id}`,
    });
  };

  return [removeOrgUnitType, { client, ...rest }] as const;
}
