/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { Table, theme } from "@nordcloud/gnui";
import { EstatePeriod } from "~/components";

export const Td = styled(Table.td)<{ period: EstatePeriod }>`
  vertical-align: middle;
  text-align: right;

  &:first-of-type {
    width: "30%";
    text-align: left;
    position: sticky;
    z-index: ${theme.zindex.default};
    left: 0;
    background: ${theme.color.background.ui01};
  }
`;

export const Th = styled(Table.th)<{ wide?: boolean }>`
  width: ${({ wide }) => (wide ? "13.5rem" : "7.5rem")};
  font-size: ${theme.fontSizes.sm};
  letter-spacing: normal;
  color: ${theme.colors.darks[4]};
  background: ${theme.color.background.ui03};
  font-weight: ${theme.fontWeights.medium};
  cursor: pointer;
  border-bottom: ${theme.borders.grey};
  border-top: ${theme.borders.grey};
  text-align: right;

  &:first-of-type {
    width: 15rem;
    text-align: left;
    position: sticky;
    z-index: ${theme.zindex.default};
    left: 0;
  }
`;

export const StyledTable = styled(Table)`
  @media screen and (min-width: ${theme.breakpoints.lg}px) {
    table-layout: fixed;
  }
  tr:hover td {
    background-color: ${theme.color.background.ui03};
  }
`;
