/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  ReservationsProvider,
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
} from "~/generated/graphql";

import { useCheckActiveAccount } from "../../hooks";

export function useAzureReservationsFeatureFlag(activeTab: number) {
  const { tabsSelection, loading } = useCheckActiveAccount(
    ReservationsProvider.Azure
  );

  return {
    tabsSelection,
    loading,
    // Optional chaining is needed since `activeTab` can be bigger than `tabsSelection` length
    serviceUsageType:
      tabsSelection[activeTab]?.serviceType ??
      ReservationsAndCommitmentsCoverageServiceUsageType.Storage,
    reservationOfferingType:
      tabsSelection[activeTab]?.offeringType ??
      ReservationsAndCommitmentsCoverageOfferingType.All,
  };
}
