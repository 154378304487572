/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { capitalize } from "~/tools";
import { Metadata } from "../../components";

export function useImageDetails(metadata: Metadata[]) {
  const image = metadata.find(({ label }) => label === "image");

  const values = image?.value ? (image.value as MappedImage) : undefined;

  return {
    imageId: values?.imageId ?? "",
    name: values?.name ?? "",
    imageLocation: values?.imageLocation ?? "",
    owner: values?.imageOwnerAlias
      ? capitalize(values?.imageOwnerAlias)
      : values?.ownerId ?? "",
    architecture: values?.architecture ?? "",
    platformDetails: values?.platformDetails ?? "",
    state: values?.state ?? "",
    creationDate: values?.creationDate,
    deprecationTime: values?.deprecationTime,
  };
}

type MappedImage = {
  imageId: string;
  name: string;
  imageLocation: string;
  ownerId: string;
  imageOwnerAlias: string | null;
  architecture: string;
  platformDetails: string;
  state: string;
  creationDate: string;
  deprecationTime: string;
};
