/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Vpc } from "./types";

export const generateSelectedSubnets = (
  selectedSubnets: string[],
  vpcs: Vpc[]
) => {
  const flatSubnets = vpcs.flatMap((vpc) => vpc.subnets);

  return selectedSubnets.map((selectedId) => ({
    subnetId: selectedId,
    nid:
      flatSubnets.find((vpcSubnet) => vpcSubnet.subnetId === selectedId)?.nid ??
      "",
  }));
};
