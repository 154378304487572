/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { curveStep } from "@visx/curve";
import { AreaSeries, AreaStack, LineSeries } from "@visx/xychart";
import { costVsBudgetChartColors } from "./consts";

type BudgetAreaProps = {
  yData: {
    date: string;
    value: number;
    budget: number;
    forecast: number;
  }[];
  yMax: number;
};

export function BudgetArea({ yData, yMax }: BudgetAreaProps) {
  return (
    <>
      <AreaStack curve={curveStep}>
        <AreaSeries
          dataKey="budgetArea"
          data={yData}
          xAccessor={({ date }) => date}
          fill={costVsBudgetChartColors.budgetAreaPositive}
          yAccessor={({ budget }) => budget ?? 0}
        />
        <AreaSeries
          dataKey="budgetOppositeArea"
          data={yData}
          xAccessor={({ date }) => date}
          fill={costVsBudgetChartColors.budgetAreaNegative}
          yAccessor={({ budget }) => yMax - (budget ?? 0)}
        />
      </AreaStack>
      <LineSeries
        dataKey="targetValuesLine"
        data={yData}
        strokeDasharray="6,4"
        xAccessor={({ date }) => date}
        yAccessor={({ budget }) => budget}
        curve={curveStep}
        stroke={costVsBudgetChartColors.budgetStroke}
      />
    </>
  );
}
