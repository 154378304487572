/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloCache } from "@apollo/client";
import {
  DriftResolveStatus,
  DriftsDocument,
  DriftsQuery,
  DriftsQueryVariables,
  useResolveAllDriftsMutation,
} from "~/generated/graphql";
import { showSuccess } from "~/services/toast";
import { handleError, Maybe, toInputError } from "~/tools";
import { mapFilters, ResponseParameters } from "./utils";

export function useAllDriftsUpdate() {
  const [updateAllDrifts, { client, ...mutationState }] =
    useResolveAllDriftsMutation();

  const update = async (
    status: DriftResolveStatus,
    queryParameters: ResponseParameters
  ) => {
    try {
      const parameters = {
        page: queryParameters.page,
        limit: queryParameters.limit,
        filters: mapFilters(
          queryParameters.filteringParams?.modes,
          queryParameters.filteringParams?.filters
        ),
      };

      const { data } = await updateAllDrifts({
        variables: {
          input: {
            status,
            filters: parameters.filters,
          },
        },
      });

      const isSuccess = data?.resolveAllDrifts.success;

      if (isSuccess) {
        showSuccess(
          `All drifts will be ${
            status === DriftResolveStatus.Approve ? "approved" : "rejected"
          }. This process may take up to couple of minutes.`
        );
      }

      handleCacheUpdate(isSuccess, client.cache, {
        input: parameters,
      });
    } catch (error) {
      handleError(toInputError(error));
    }
  };

  return [update, mutationState] as const;
}

function handleCacheUpdate(
  isSuccess: Maybe<boolean>,
  cache: ApolloCache<unknown>,
  variables: DriftsQueryVariables
) {
  // We used `cache.evict` to remove all pages from cache (other than current page)
  // effectively clearing up the `estateAllocationDrifts` cache
  cache.evict({
    fieldName: "estateAllocationDrifts",
    broadcast: false,
  });
  // We are overwriting current page of Drifts resources with empty values
  // because we are no longer interested in what is in cache - resolveAll mutation has
  // been invoked, and we are expecting it to be correctly handled
  cache.writeQuery<DriftsQuery, DriftsQueryVariables>({
    query: DriftsDocument,
    variables,
    data: {
      estateAllocationDrifts: { count: 0, records: [], pages: 0 },
    },
  });
  // Goodbye lovely Drift items, we will miss you!
  cache.gc();
}
