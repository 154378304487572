/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Link } from "react-router-dom";
import { FlexContainer, Message, Text, theme } from "@nordcloud/gnui";
import { ROUTES } from "~/routing/routes";
import { CloudProviderType, Provider } from "~/views/cloudAccounts/types";

type Props = {
  providerType?: CloudProviderType;
  provider: Provider;
};
export function EstateRecordsNotification({ provider, providerType }: Props) {
  const additionalCostResources = getAdditionalCostResourcesText(
    provider,
    providerType
  );

  return (
    <Message status="notification" image="info" width="100%">
      <FlexContainer direction="column" alignItems="flex-start">
        <Text
          weight="medium"
          color={theme.color.text.info}
          mb={theme.spacing.spacing02}
        >
          The list includes only Cloud Estate Records.
        </Text>
        <Text mb={theme.spacing.spacing00} color={theme.color.text.text02}>
          The difference between the selected account’s monthly cost and the sum
          of estate records costs is that we don’t include “networking,”
          “discount,” “support,” and “other” costs. Instead, those costs are
          grouped into the {additionalCostResources}
        </Text>
      </FlexContainer>
    </Message>
  );
}

function getAdditionalCostResourcesText(
  provider: Provider,
  providerType?: CloudProviderType
) {
  const additionalCostResourcesText = "additional cost resources.";

  if (providerType === CloudProviderType.Cloud) {
    const estateLink = `${ROUTES.estateRecords.index}?page=0&provider[0]=${provider}&typeSubtype[0]=cost/networking&typeSubtype[1]=cost/other&typeSubtype[2]=cost/support&typeSubtype[3]=cost/discount&typeSubtype[4]=cost/correction`;

    return <Link to={estateLink}>{additionalCostResourcesText}</Link>;
  }

  return additionalCostResourcesText;
}
