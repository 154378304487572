/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export enum AwsFormField {
  EXTERNAL_ID = "externalId",
  ARN = "arn",
}

export enum AzureFormField {
  APP_ID = "appId",
  PASSWORD = "password",
  TENANT_ID = "tenantId",
}

export enum GcpFormField {
  KEY = "key",
}

export enum IbmFormField {
  API_KEY = "apikey",
}

export const ERROR_FILE_TYPE = "Wrong file provided.";
export const ERROR_READ_FILE = "Error reading file contents.";
export const ERROR_IS_REQUIRED = "You have to provide a file.";

export const URL_AWS_TEMPLATE =
  "https://nordcloud-pat-public-assets.s3-eu-west-1.amazonaws.com/templates/mca/MCAAWSScannerServiceRole.yml";

export const AWS_INFO_ONBOARDING = `
For on-boarding process, each account needs to have an IAM role
created with read only permissions and trust relationship set to
Nordcloud’s AWS account. For your convenience, please use provided
CloudFormation template. There is no requirement for deployment
region, but we recommend eu-west-1.`;

export const AWS_CLOUD_FORMATION_LINK =
  "https://console.aws.amazon.com/cloudformation/home";

export const AWS_MANAGEMENT_CONSOLE_LINK =
  "https://us-east-1.console.aws.amazon.com/console/home?region=us-east-1";

export const AWS_ABOUT_EXTERNAL_IDS =
  "https://aws.amazon.com/blogs/security/how-to-use-external-id-when-granting-access-to-your-aws-resources/";

export const AZURE_LINK = "https://portal.azure.com/";

export const URL_GCP_CONSOLE = "https://console.cloud.google.com/";
export const URL_GCP_STORAGE =
  "https://console.cloud.google.com/storage/browser";
export const URL_GCP_SERVICE_ACCOUNTS =
  "https://console.cloud.google.com/iam-admin/serviceaccounts";
export const URL_GCP_IAM_CREDENTIALS =
  "https://console.cloud.google.com/marketplace/product/google/iamcredentials.googleapis.com";
