/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  PeopleDocument,
  PeopleQuery,
  useAddPersonMutation,
  useRemovePersonMutation,
  useUpdatePersonMutation,
  AddPersonMutationVariables,
  PeopleQueryVariables,
  RemovePersonMutationVariables,
  UpdatePersonMutationVariables,
} from "~/generated/graphql";
import { showSuccess } from "~/services/toast";
import { generateActionSuccessText, handleError, noop } from "~/tools";

export function useRemovePerson() {
  const [
    removePersonMutation,
    {
      client: { cache },
      ...state
    },
  ] = useRemovePersonMutation();

  const removePerson = async (
    variables: RemovePersonMutationVariables & { name: string },
    onSuccess: () => void = noop
  ) => {
    try {
      await removePersonMutation({ variables });

      cache.updateQuery<PeopleQuery, PeopleQueryVariables>(
        { query: PeopleDocument },
        (peopleData) => ({
          people: (peopleData?.people ?? []).filter(
            (person) => person?.id !== variables.id
          ),
        })
      );

      showSuccess(
        generateActionSuccessText(`The ${variables.name}`)()("removed")()
      );
      onSuccess();
    } catch (error) {
      handleError(error);
    }
  };

  return [removePerson, state] as const;
}

export function useAddPerson() {
  const [
    addPersonMutation,
    {
      client: { cache },
      ...state
    },
  ] = useAddPersonMutation();

  const addPerson = async (
    variables: AddPersonMutationVariables,
    onSuccess: () => void = noop
  ) => {
    try {
      const { data } = await addPersonMutation({ variables });

      const addedPerson = data?.addPersonV2;

      if (addedPerson != null) {
        cache.updateQuery<PeopleQuery, PeopleQueryVariables>(
          { query: PeopleDocument },
          (peopleData) => ({
            people: [addedPerson, ...(peopleData?.people ?? [])],
          })
        );
      }

      showSuccess(generateActionSuccessText(variables.name)()("added")());
      onSuccess();
    } catch (error) {
      handleError(error);
    }
  };

  return [addPerson, state] as const;
}

export function useUpdatePerson() {
  const [updatePersonMutation, { client: ignored, ...state }] =
    useUpdatePersonMutation();

  const updatePerson = async (
    variables: UpdatePersonMutationVariables,
    onSuccess: () => void = noop
  ) => {
    try {
      await updatePersonMutation({ variables });

      showSuccess(
        generateActionSuccessText(variables.name ?? "person")()("updated")()
      );
      onSuccess();
    } catch (error) {
      handleError(error);
    }
  };

  return [updatePerson, state] as const;
}
