/**
 * Copyright 2021-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import styled from "styled-components";
import { FlexContainer, Text, theme } from "@nordcloud/gnui";
import { Provider } from "~/generated/graphql";
import { useDisclosure } from "~/hooks";
import { formatMoney, inflect, isNotEmpty } from "~/tools";
import { ReallocatedCostsSidebar } from "~/views/estate/EstateDetailsPage/components/ReallocatedCostsSidebar";
import { LinkedRecords } from "../types";

const ResourceLink = styled.a`
  &:hover {
    cursor: pointer;
    opacity: 0.75;
  }
`;

type Props = {
  originalCost: number;
  linkedRecords?: LinkedRecords;
  provider?: Provider;
  currency: string;
};

export function OriginalCost({
  originalCost,
  currency,
  provider,
  linkedRecords = [],
}: Props) {
  const { isOpen, close, open } = useDisclosure();

  const records = linkedRecords ?? [];

  return (
    <>
      <FlexContainer
        gap={theme.spacing.spacing02}
        className={originalCost === 0 ? "hidden" : ""}
      >
        <Text tag="div">{formatMoney(originalCost, currency)}</Text>
        <When condition={isNotEmpty(records) && originalCost > 0}>
          <Text tag="div" size="sm">
            The original cost was transferred to the{" "}
            <ResourceLink onClick={open}>
              {inflect("resource")(records.length)}
            </ResourceLink>
          </Text>
        </When>
      </FlexContainer>
      <ReallocatedCostsSidebar
        isOpen={isOpen}
        linkedResources={records}
        provider={provider}
        onClose={close}
      />
    </>
  );
}
