/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { UniversalWrap } from "~/components";
import { SUCCESS_TEXT, ERROR_TEXT } from "~/constants";
import { showError, showSimpleInfo } from "~/services/toast";
import { isNotEmpty } from "~/tools";
import { Provider } from "~/views/cloudAccounts/types";
import { BillingDataList } from "../components/BillingDataList";
import { BillingDataListItem } from "../components/BillingDataListItem";
import { AddBillingData } from "./AddBillingDataForm";
import { useAzureBillingData } from "./hooks/useAzureBillingData";
import { useDeleteAzureBillingData } from "./hooks/useDeleteAzureBillingData";

export function AzureBillingDataContent({ onClose }: { onClose: () => void }) {
  const [billingData, { loading, error }] = useAzureBillingData();
  const [removeAzureBillingData] = useDeleteAzureBillingData();

  const isBillingData = isNotEmpty(billingData);

  const handleDelete = async (interactionId: string) => {
    try {
      await removeAzureBillingData({ capacitorCustomerId: interactionId });
      showSimpleInfo(SUCCESS_TEXT.billingDataRemoved);
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  const billingDataListTitle =
    billingData.length > 1 ? "List of Billing Accounts" : "Billing Account";

  return (
    <UniversalWrap loaderProps={{ loading }} errorProps={{ error }}>
      <BillingDataList title={isBillingData ? billingDataListTitle : ""}>
        {billingData.map(
          ({ enrollmentNumber, capacitorCustomerId, isActive }) => (
            <BillingDataListItem<Provider.Azure, string>
              key={enrollmentNumber}
              isActive={Boolean(isActive)}
              interactionId={capacitorCustomerId}
              label="Enrollment Number"
              title={enrollmentNumber}
              updateFormValues={{
                enrollmentNumber,
                capacitorCustomerId,
                enrollmentAccessKey: "",
              }}
              onDelete={handleDelete}
            />
          )
        )}
      </BillingDataList>
      <When condition={!isBillingData}>
        <AddBillingData onClose={onClose} />
      </When>
    </UniversalWrap>
  );
}
