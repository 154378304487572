/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer, Text, theme } from "@nordcloud/gnui";
import { TARGET_TYPE } from "~/components/Charts/types";
import { KpiType, getKpiType } from "~/constants";
import { useCurrency } from "~/hooks";
import { isNotEmpty, isNotNil, formatMoney, Maybe } from "~/tools";

type Props = {
  value?: Maybe<string>;
  percentage?: Maybe<string>;
  targetPercentage?: Maybe<string>;
  type: KpiType;
};

export function KpiDetailsRow({
  value,
  percentage,
  targetPercentage,
  type,
}: Props) {
  const { currency } = useCurrency();

  const secondaryTitles = {
    costAllocation: "Allocated Cost",
    overallWaste: "Current Waste",
    nonProdHours: "Current Shutdown Time",
  };

  const { targetType } = getKpiType(type);
  const percentageColor = getKpiValueColor({
    targetType,
    kpiPercentage: parseFloat(percentage ?? ""),
    targetPercentageNumber: parseFloat(
      targetPercentage ? targetPercentage.replace("%", "") : "0"
    ),
    defaultColor: theme.color.text.text01,
  });
  const percentageResult = percentage ? `${percentage}%` : "-";

  const valueFormatted =
    type === KpiType.NON_PROD_HOURS
      ? `${value} h`
      : formatMoney(value, currency);
  const valueResult = valueFormatted || "-";

  const prefix = targetType === TARGET_TYPE.positive ? "<" : ">";
  const targetResult =
    isNotNil(targetPercentage) &&
    isNotEmpty(targetPercentage) &&
    targetPercentage.toUpperCase() !== "N/A"
      ? `${prefix}${targetPercentage}`
      : "-";

  return (
    <FlexContainer
      direction="row"
      justifyContent="space-between"
      mb={theme.spacing.spacing05}
      css={{ width: "100%" }}
    >
      <FlexContainer
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        css={{
          width: "50%",
          borderRight: `1px ${theme.color.border.border01} solid`,
        }}
      >
        <Text size="sm" weight="bold" mb={0} color={theme.color.text.text02}>
          {secondaryTitles[type]}
        </Text>
        <FlexContainer>
          <Text mb={0} mr={theme.spacing.spacing01} color={percentageColor}>
            {percentageResult}
          </Text>
          <Text size="sm" color={theme.color.text.text02} mb={0}>
            ({valueResult})
          </Text>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        css={{ width: "50%", paddingLeft: theme.spacing.spacing03 }}
      >
        <Text size="sm" weight="bold" mb={0} color={theme.color.text.text02}>
          Target
        </Text>
        <Text weight="bold">{targetResult}</Text>
      </FlexContainer>
    </FlexContainer>
  );
}

type GetKpiValueColorProps = {
  targetType: TARGET_TYPE;
  kpiPercentage?: number;
  targetPercentageNumber?: number;
  defaultColor?: string;
};

function getKpiValueColor({
  targetType,
  kpiPercentage,
  targetPercentageNumber,
  defaultColor = theme.color.text.text04,
}: GetKpiValueColorProps): string {
  if (isNaN(kpiPercentage ?? NaN) || isNaN(targetPercentageNumber ?? NaN)) {
    return defaultColor;
  }

  if (
    typeof kpiPercentage === "number" &&
    typeof targetPercentageNumber === "number"
  ) {
    if (targetType === TARGET_TYPE.positive) {
      return kpiPercentage > targetPercentageNumber
        ? theme.colors.danger
        : theme.colors.success;
    }

    return kpiPercentage < targetPercentageNumber
      ? theme.colors.danger
      : theme.colors.success;
  }

  return defaultColor;
}
