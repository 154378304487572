/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState, useRef, useEffect, useCallback } from "react";
import { useToggle } from "react-use";
import { useApplicationsPaginatedListLazyQuery } from "~/generated/graphql";
import { Mode } from "~/components";
import { getFirstItem } from "~/tools";
import { formatDataObject } from "~/utils";

type ApplicationOption = {
  label: string;
  value: string;
};

type Props = {
  selectedApplications: string[];
  setSelectedApplications: (application: string[]) => void;
  setSelectedApplicationsList: (application: string[]) => void;
};

export function useInfiniteScroll({
  selectedApplications,
  setSelectedApplications,
  setSelectedApplicationsList,
}: Props) {
  const [page, setPage] = useState(0);
  const elementRef = useRef(null);
  const [hasMore, setHasMore] = useToggle(true);
  const [applications, setApplications] = useState<ApplicationOption[]>([]);
  const [getApplications, { data, loading }] =
    useApplicationsPaginatedListLazyQuery({
      variables: {
        limit: 20,
        page,
      },
    });

  const fetchData = useCallback(async () => {
    await getApplications();

    setPage((prevPage) => prevPage + 1);
    if (data?.applicationsPaginated?.count) {
      setHasMore(
        Number(data?.applicationsPaginated?.count) > applications.length
      );
    }
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (getFirstItem(entries).isIntersecting && hasMore) {
          void fetchData();
        }
      },
      { threshold: 1 }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [fetchData, hasMore]);

  useEffect(() => {
    const applicationsPaginated = formatDataObject(data);

    setApplications((prevApplications) => {
      const newApplications =
        applicationsPaginated?.applicationsPaginated?.results?.map((app) => ({
          label: app.name,
          value: app.nid,
        })) ?? [];

      return [...prevApplications, ...newApplications].sort((a, b) =>
        a.label.localeCompare(b.label)
      );
    });
  }, [data]);

  const onModeChange = (mode: Mode) => {
    const list =
      mode === Mode.INCLUDE
        ? selectedApplications
        : applications
            .filter((app) => !selectedApplications.includes(app.value))
            .map((app) => app.value);
    setSelectedApplicationsList(list);
  };

  const handleInstanceMultiSelect = useCallback(
    (id: string[]) => {
      setSelectedApplicationsList(id);
      setSelectedApplications(id);
    },
    [setSelectedApplications, setSelectedApplicationsList]
  );

  return {
    loading,
    elementRef,
    applications,
    handleInstanceMultiSelect,
    onModeChange,
  };
}
