/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then } from "react-if";
import styled from "styled-components";
import { Box, Text, theme } from "@nordcloud/gnui";

type Props = {
  limit: number;
  count: number;
  hasAllSelected: boolean;
  onAllSelected: (state: boolean) => void;
};

export function DriftsBanner({
  count,
  limit,
  hasAllSelected,
  onAllSelected,
}: Props) {
  return (
    <Box boxStyle="lightGrey">
      <If condition={hasAllSelected}>
        <Then>
          <Text mb={0} tag="span">
            All {count} Estate Records are selected.
          </Text>
          &nbsp;
          <ClickableText
            mb={0}
            tag="span"
            onClick={() => {
              onAllSelected(false);
            }}
          >
            Clear selection.
          </ClickableText>
        </Then>
        <Else>
          <Text mb={0} tag="span">
            All {limit} Estate Records on this page are selected.
          </Text>
          &nbsp;
          <ClickableText
            mb={0}
            tag="span"
            onClick={() => {
              onAllSelected(true);
            }}
          >
            Select all {count} Estate Records across all pages.
          </ClickableText>
        </Else>
      </If>
    </Box>
  );
}

const ClickableText = styled(Text)`
  cursor: pointer;
  color: ${theme.color.interactive.link};

  &:hover {
    text-decoration: underline;
  }
`;
