/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export enum SortDirection {
  Ascending = "Ascending",
  Descending = "Descending",
}

export type Option = {
  value: string;
  label: string;
};
