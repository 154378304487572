/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useMemo } from "react";
import {
  useOrgUnitOtherCategoriesCostQuery,
  useOrgUnitTopCategoriesCostsQuery,
} from "~/generated/graphql";
import {
  extractCategoryIds,
  getTableGranularity,
  mapGranularity,
} from "~/components/Charts/CostAnalysis";
import {
  Granularity,
  Period,
  PlaceholderCategory,
  TableData,
  topSpendingGroup,
} from "~/components/Charts/CostAnalysis/types";
import {
  generateTopGroupData,
  generateTopTableData,
} from "~/components/Charts/CostAnalysis/utils";
import { isNotNil } from "~/tools";
import { getCostSplitGroupNames } from "~/views/applications/ApplicationCostTabs/hooks/utils";

type Props = {
  orgUnitId: string;
  period: Period;
  startDate: string;
  endDate: string;
  granularity: Granularity;
};

export function useOrgUnitCostPerCategoriesChart({
  orgUnitId,
  period,
  startDate,
  endDate,
  granularity,
}: Props) {
  const {
    data,
    loading: isCostLoading,
    error: costError,
  } = useOrgUnitTopCategoriesCostsQuery({
    variables: {
      top: topSpendingGroup.CATEGORIES,
      orgUnitId,
      startDate,
      endDate,
      granularity: mapGranularity(granularity),
      tableGranularity: getTableGranularity(granularity, period),
    },
  });

  const {
    data: orgUnitOtherCategoriesCostTableResponse,
    loading: isOtherCategoriesLoading,
    error: otherCategoriesError,
  } = useOrgUnitOtherCategoriesCostQuery({
    variables: {
      input: {
        top: topSpendingGroup.CATEGORIES,
        orgUnitId,
        startDate,
        endDate,
        granularity: mapGranularity(Granularity.MONTHS),
      },
    },
  });

  const orgUnitTopCategoriesCostTableTimePoints = useMemo(
    () =>
      (data?.orgUnitTopCategoriesTableCost?.timePoints ?? []).filter(isNotNil),
    [data]
  );

  const costPerCategoryTableData: TableData[] = useMemo(
    () => generateTopGroupData(orgUnitTopCategoriesCostTableTimePoints ?? []),
    [orgUnitTopCategoriesCostTableTimePoints]
  );

  const orgUnitTopCategoriesCostChartData = useMemo(
    () => (data?.orgUnitTopCategoriesCost?.timePoints ?? []).filter(isNotNil),
    [data]
  );

  const costPerCategoryTotal = Number(
    data?.orgUnitTopCategoriesTableCost?.total ?? "0"
  );

  const orgUnitOtherCategoriesCostTableData = (
    orgUnitOtherCategoriesCostTableResponse?.orgUnitOtherCategoriesCost
      ?.timePoints ?? []
  ).filter(isNotNil);

  const costPerOtherCategoryTableData: TableData[] = useMemo(
    () => generateTopTableData(orgUnitOtherCategoriesCostTableData ?? []),
    [orgUnitOtherCategoriesCostTableData]
  );

  const getSubRows = useCallback(
    (originalRow: TableData): TableData[] =>
      originalRow.field === PlaceholderCategory.OTHER.name
        ? costPerOtherCategoryTableData
        : [],
    [costPerOtherCategoryTableData]
  );

  const organizationUnits = getCostSplitGroupNames(costPerCategoryTableData);

  const otherCategoryIds = extractCategoryIds(
    orgUnitOtherCategoriesCostTableData
  );

  const isLoading = isCostLoading || isOtherCategoriesLoading;
  const error = costError || otherCategoriesError;

  return {
    orgUnitTopCategoriesCostChartData,
    costPerCategoryTableData,
    costPerCategoryTotal,
    isLoading,
    error,
    getSubRows,
    organizationUnits,
    otherCategoryIds,
  };
}
