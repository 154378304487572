/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useFormContext } from "react-hook-form";
import { Switch, Case, Default } from "react-if";
import { WorkflowDataType } from "../../constants";
import { WorkflowFormData } from "../../types";
import { AnomalyCostsMessage } from "./AnomalyCostsMessage";
import { BudgetsSelectDataType } from "./BudgetsSelectDataType";
import { CloudCredentialsMessage } from "./CloudCredentialsMessage";
import { CostsSelectDataType } from "./CostsSelectDataType";
import { KpiSelectDataType } from "./KpiSelectDataType";
import { RegularMessage } from "./RegularMessage";
import { SavingSuggestionsDataType } from "./SavingSuggestionsDataType";
import { SlackErrors } from "./types";
import { UnallocatedResourceMessage } from "./UnallocatedResourcesMessage";

type Props = {
  selectDataComponent?: WorkflowDataType;
  isReadMode: boolean;
  slackErrors: SlackErrors;
};

export function MessageSwitch({
  selectDataComponent,
  isReadMode,
  slackErrors,
}: Props) {
  const { register } = useFormContext<WorkflowFormData>();

  return (
    <Switch>
      <Case condition={selectDataComponent === WorkflowDataType.ANOMALY_COSTS}>
        <AnomalyCostsMessage />
      </Case>
      <Case condition={selectDataComponent === WorkflowDataType.BUDGETS}>
        <BudgetsSelectDataType isReadMode={isReadMode} />
      </Case>
      <Case
        condition={selectDataComponent === WorkflowDataType.SAVING_SUGGESTIONS}
      >
        <SavingSuggestionsDataType
          slackErrors={slackErrors}
          register={register}
          isReadMode={isReadMode}
        />
      </Case>
      <Case condition={selectDataComponent === "KPI"}>
        <KpiSelectDataType
          slackErrors={slackErrors}
          register={register}
          isReadMode={isReadMode}
        />
      </Case>
      <Case
        condition={
          selectDataComponent === WorkflowDataType.UNALLOCATED_RESOURCES
        }
      >
        <UnallocatedResourceMessage />
      </Case>
      <Case
        condition={selectDataComponent === WorkflowDataType.CLOUD_CREDENTIALS}
      >
        <CloudCredentialsMessage />
      </Case>
      <Case condition={selectDataComponent === WorkflowDataType.COSTS}>
        <CostsSelectDataType
          slackErrors={slackErrors}
          register={register}
          isReadMode={isReadMode}
        />
      </Case>
      <Default>
        <RegularMessage
          selectDataComponent={selectDataComponent}
          register={register}
          isReadMode={isReadMode}
          slackErrors={slackErrors}
        />
      </Default>
    </Switch>
  );
}
