/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer, theme } from "@nordcloud/gnui";
import {
  CloudAccountsDocument,
  CloudAccountsQuery,
  CloudAccountsQueryVariables,
  useUpdateAccountV3Mutation,
} from "~/generated/graphql";
import { ERROR_TEXT, SUCCESS_TEXT } from "~/constants/text";
import { showError, showSuccess } from "~/services/toast";
import { Maybe, generateActionSuccessText, isNotNil } from "~/tools";
import { AccountOwner } from "../../types";
import { ContactDetailsField } from "./ContactDetailsField";
import { TextDetailsField } from "./TextDetailsField";

type Props = {
  accountId: string;
  accountInternalId: string;
  accountOwner: Maybe<AccountOwner>;
  contactsNames: string[];
  description: string;
};

export function DetailsForm({
  accountId,
  accountInternalId,
  accountOwner,
  contactsNames,
  description,
}: Omit<Props, "contactsNames"> & {
  contactsNames: string;
}) {
  const [updateAccount, { client }] = useUpdateAccountV3Mutation();

  const handleOwnerEdit = async (id: string) => {
    try {
      await updateAccount({
        variables: {
          nid: accountInternalId,
          input: { ownerId: id },
        },
      });
      handleCache();
      showSuccess(SUCCESS_TEXT.ownerUpdated);
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  const handleContactsEdit = async (id?: string) => {
    try {
      await updateAccount({
        variables: {
          nid: accountInternalId,
          input: { contactIds: isNotNil(id) ? [id] : [] },
        },
      });
      handleCache();
      showSuccess(SUCCESS_TEXT.contactPersonUpdated);
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  const handleDescriptionEdit = async (newDescription: string) => {
    try {
      await updateAccount({
        variables: {
          nid: accountInternalId,
          input: { description: newDescription },
        },
      });
      handleCache();
      showSuccess(generateActionSuccessText("Description")()("updated")());
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  const handleCache = () => {
    client.cache.updateQuery<CloudAccountsQuery, CloudAccountsQueryVariables>(
      {
        query: CloudAccountsDocument,
        variables: {
          limit: 1,
          filter: {
            nid: accountId,
          },
        },
      },
      (accounts) => ({
        cloudAccounts: {
          count: accounts?.cloudAccounts?.count ?? 0,
          pages: accounts?.cloudAccounts?.pages ?? 0,
          accounts: [...(accounts?.cloudAccounts?.accounts ?? [])],
        },
      })
    );
  };

  return (
    <>
      <FlexContainer
        alignItems="flex-start"
        direction="column"
        pt={theme.spacing.spacing03}
        gap={theme.spacing.spacing03}
      >
        <TextDetailsField label="Account ID" value={accountId} />
        <ContactDetailsField
          label="Account Owner"
          value={accountOwner?.name ?? ""}
          onEdit={handleOwnerEdit}
        />
        <ContactDetailsField
          label="Contact Person"
          value={contactsNames}
          onEdit={handleContactsEdit}
          onDelete={handleContactsEdit}
        />
        <TextDetailsField
          label="Description"
          value={description}
          onEdit={handleDescriptionEdit}
        />
      </FlexContainer>
    </>
  );
}
