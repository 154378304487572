/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as yup from "yup";
import { isEmpty, isNotEmpty } from "~/tools";

export const getIntegrationPluginSchema = () =>
  yup.object().shape({
    integrationName: yup
      .string()
      .required("Integration Plugin Name is required.")
      .max(50, "Integration Plugin Name limit is 50 characters."),
    integrationDescription: yup
      .string()
      .max(255, "Integration Plugin Description limit is 255 characters."),
    integrationType: yup.string().oneOf(["Turbonomic"]),
    url: yup.string().url("URL must be valid.").required("URL is required."),
    accountName: yup
      .string()
      .required("Integration Plugin Account Name is required."),
    password: yup.string().required("Integration Plugin Password is required."),
    contactPersonIds: yup
      .array()
      .of(
        yup.object({
          value: yup.string(),
        })
      )
      .test({
        name: "required",
        message: "At least one Contact Person is required.",
        test: (array) =>
          isNotEmpty((array ?? []).filter(({ value }) => value !== "")),
      })
      .test({
        name: "required",
        message: "Empty Contact Person fields must be filled or removed.",
        test: (array) =>
          isEmpty((array ?? []).filter(({ value }) => value === "")),
      })
      .test({
        name: "unique",
        message: "The same person cannot be selected more than once.",
        test: (array) =>
          (array ?? []).length ===
          new Set(array?.map(({ value }) => value)).size,
      }),
  });
