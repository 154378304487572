/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Checkbox, FlexContainer, Spacer, Text, theme } from "@nordcloud/gnui";
import { Provider } from "~/generated/graphql";
import {
  FilterHeader,
  Mode,
  Separator,
  SidebarItemWrapper,
  getSelectedMode,
} from "~/components";
import { SwitchWrap } from "../AppEnvSelector/styles";
import { useProvidersFilter } from "../hooks";

type Props = {
  selectedProviders: Provider[];
  onProviderSelect: (provider: Provider[]) => void;
  isExcludeSelected?: boolean;
  onModeChange?: (mode: Mode) => void;
};

export function ProvidersFilter({
  selectedProviders,
  onProviderSelect,
  isExcludeSelected,
  onModeChange,
}: Props) {
  const { selectedItems, filteredProviders, setSearch, onApply } =
    useProvidersFilter({
      selectedProviders,
      onProviderSelect,
    });

  return (
    <>
      <FilterHeader
        showSearchBar
        allowModeChange
        defaultMode={getSelectedMode(isExcludeSelected)}
        onSetSearch={setSearch}
        onModeChange={onModeChange}
      />
      <Spacer height={theme.spacing.spacing04} />
      <FlexContainer
        direction="column"
        css={{ maxHeight: "20rem", overflowY: "scroll", overflowX: "hidden" }}
      >
        <Separator />
        {filteredProviders.map((provider) => (
          <SidebarItemWrapper
            key={provider}
            hideBottomBorder
            title={provider ?? ""}
            onClick={() => onApply(provider)}
          >
            <SwitchWrap>
              <Checkbox
                withoutLabel
                readOnly
                checked={selectedItems?.includes(provider ?? "")}
              />
            </SwitchWrap>
            <Text mb="0">{provider}</Text>
          </SidebarItemWrapper>
        ))}
      </FlexContainer>
    </>
  );
}
