/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { AxisBottom, AxisLeft } from "@visx/axis";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { LinePath } from "@visx/shape";
import { Tooltip, useTooltip } from "@visx/tooltip";
import { When } from "react-if";
import { theme } from "@nordcloud/gnui";
import { ReservationsRecommendationTerm } from "~/generated/graphql";
import { LoaderWrap, BrickLoader } from "~/components";
import { ChartPlaceholder } from "~/components/Charts/components";
import { useCurrency } from "~/hooks";
import { formatMoneyLabel, isEmpty } from "~/tools";
import { BreakevenPointData } from "../types";
import { BreakevenLegend } from "./BreakevenLegend";

type BreakevenChartProps = {
  width: number;
  height: number;
  loading: boolean;
  data: BreakevenPointData[];
  highestOnDemandCost: number;
  highestLabel: number;
  startSavings: number;
  breakEvenPointXAxis: number;
  breakEvenPointYAxis: number;
  termOption: ReservationsRecommendationTerm;
};

export function BreakevenChart({
  width,
  height,
  loading,
  data = [],
  highestOnDemandCost,
  highestLabel,
  startSavings,
  breakEvenPointXAxis,
  breakEvenPointYAxis,
  termOption,
}: BreakevenChartProps) {
  const { currency } = useCurrency();
  const radius = 5;
  const offset = 10;
  const VERTICAL_MARGIN = 70;
  const strokeWidth = 2;
  const strokeOpacity = 1;
  const tooltipOffset = 100;
  const xMax = width - 60;
  const yMax = height - VERTICAL_MARGIN;
  const titleTickData: TitleTickData =
    termOption === ReservationsRecommendationTerm.OneYear
      ? { bufferTick: 1.2, titleTick: data.length + 1, numTicks: data.length }
      : { bufferTick: 3, titleTick: data.length + 2, numTicks: data.length };
  const highestLabelWithTitle = highestLabel + titleTickData.bufferTick;
  const xScale = scaleLinear({
    domain: [0, highestLabelWithTitle],
    range: [0, xMax],
    round: true,
  });
  const adjustment = highestOnDemandCost / 4;
  const hightestDomain = highestOnDemandCost + adjustment;
  const yScale = scaleLinear({
    domain: [0, hightestDomain],
    range: [yMax, 0],
    round: true,
  });

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  } = useTooltip();

  const savingMonth = startSavings.toFixed(0);
  const hasStartSaving = startSavings !== 0;

  const showTooltipElement = (event: React.MouseEvent) => {
    showTooltip({
      tooltipLeft: event.clientX,
      tooltipTop: event.clientY,
      tooltipData: (
        <p>After {savingMonth} months you will start saving money</p>
      ),
    });
  };

  return (
    <LoaderWrap
      loading={loading}
      Component={BrickLoader}
      viewBox="0 0 10 4"
      backgroundColor={theme.color.background.ui01}
    >
      <div
        css={{
          position: "relative",
          marginBottom: theme.spacing.spacing04,
        }}
      >
        <ChartPlaceholder show={isEmpty(data)} width={width} height={height} />
        <svg width={width} height={height}>
          <Group top={offset / 2} left={50} data-testid="breakeven-chart">
            <GridRows
              scale={yScale}
              width={xMax}
              strokeDasharray="1,3"
              stroke={theme.color.border.focus}
              pointerEvents="none"
              numTicks={data.length}
            />
            <AxisLeft
              hideTicks
              top={0}
              scale={yScale}
              stroke={theme.color.border.focus}
              numTicks={offset}
              tickFormat={(n) => formatMoneyLabel(n, currency)}
              tickLabelProps={() => ({
                fill: theme.color.text.text02,
                fontSize: 10,
                textAnchor: "end",
              })}
            />
            <Group>
              <AxisBottom
                top={yMax}
                scale={xScale}
                stroke={theme.color.border.focus}
                tickStroke={theme.color.border.focus}
                numTicks={data.length}
                tickFormat={(n) => formatTitleTick(n, titleTickData)}
                tickLabelProps={() => ({
                  fill: theme.color.text.text02,
                  fontSize: 10,
                  textAnchor: "middle",
                })}
              />
            </Group>

            <LinePath
              strokeWidth={strokeWidth}
              strokeOpacity={strokeOpacity}
              stroke={theme.color.background.ui05}
              shapeRendering="geometricPrecision"
              x={({ x }) => xScale(Number(x))}
              y={({ y }) => yScale(Number(y))}
              data={data.map(({ label, onDemandCost }) => ({
                x: label,
                y: onDemandCost,
              }))}
            />

            <LinePath
              strokeWidth={strokeWidth}
              strokeOpacity={strokeOpacity}
              stroke={theme.color.support.cyan}
              shapeRendering="geometricPrecision"
              x={({ x }) => xScale(Number(x))}
              y={({ y }) => yScale(Number(y))}
              data={data.map(({ label, reservedInstanceCost }) => ({
                x: label,
                y: reservedInstanceCost,
              }))}
            />
            <When condition={hasStartSaving}>
              <circle
                r={radius}
                cx={xScale(breakEvenPointXAxis)}
                cy={yScale(breakEvenPointYAxis)}
                stroke={theme.color.support.cyan}
                fill={theme.color.support.cyan}
                role="figure"
                data-testid="breakevenPoint"
                css={{ cursor: "pointer" }}
                onMouseEnter={(event) => showTooltipElement(event)}
              />
            </When>
          </Group>
        </svg>
        {tooltipOpen && (
          <Tooltip
            key="breakeven-intersection"
            top={tooltipTop}
            left={tooltipLeft}
            offsetLeft={-tooltipOffset}
            style={{
              backgroundColor: theme.color.background.ui05,
              color: theme.color.text.text04,
              position: "fixed",
              padding: `${theme.spacing.spacing01} ${theme.spacing.spacing02}`,
              fontSize: theme.fontSizes.sm,
              borderRadius: theme.radius.sm,
              transform: "translateY(-100%)",
              width: "auto",
            }}
            onMouseLeave={hideTooltip}
          >
            <>{tooltipData}</>
          </Tooltip>
        )}

        <BreakevenLegend hasStartSaving={hasStartSaving} />
      </div>
    </LoaderWrap>
  );
}

type TitleTickData = {
  bufferTick: number;
  titleTick: number;
  numTicks: number;
};

const formatTitleTick = (
  tickNumber: number | { valueOf: () => number },
  titleTickData: TitleTickData
): string => {
  if (titleTickData.titleTick === tickNumber) {
    return "Months";
  }

  const numOfTicks =
    typeof tickNumber === "number" ? tickNumber : tickNumber.valueOf();

  if (numOfTicks > titleTickData.numTicks) {
    return "";
  }

  return numOfTicks.toString();
};
