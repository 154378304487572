/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { If, Then, Else, When } from "react-if";
import { Link } from "react-router-dom";
import {
  Box,
  theme,
  FlexContainer,
  Text,
  Button,
  Modal,
  SVGIcon,
} from "@nordcloud/gnui";
import { useDisclosure, useToggle } from "~/hooks";
import { isNotNil } from "~/tools";
import { Provider } from "../../types";
import { UpdateForm, UpdateFormValues } from "./UpdateForm";

type Props<P extends ProvidersWithBillingData, InteractionValue> = {
  label: string;
  title: string;
  isActive?: boolean;
  linkTitle?: LinkTitle;
  interactionId: InteractionValue;
  updateFormValues: UpdateFormValues[P];
  onDelete: (interactionId: InteractionValue) => void;
};

export function BillingDataListItem<
  P extends ProvidersWithBillingData,
  InteractionValue,
>({
  label,
  title,
  isActive,
  interactionId,
  linkTitle,
  updateFormValues,
  onDelete,
}: Props<P, InteractionValue>) {
  const {
    close: closeDeleteModal,
    open: openDeleteModal,
    isOpen: isOpenDeleteModal,
  } = useDisclosure();

  const [showUpdateForm, toggleShowUpdateForm] = useToggle(false);

  const ActivityStatus = isNotNil(isActive) ? (
    <SVGIcon {...getAccountStatusStyle(isActive)} />
  ) : null;

  return (
    <>
      <Box border="disabled" boxStyle="lightGrey" mb={theme.spacing.spacing03}>
        <FlexContainer
          justifyContent="space-between"
          alignItems="center"
          gap={theme.spacing.spacing03}
        >
          <FlexContainer gap={theme.spacing.spacing03}>
            {ActivityStatus}
            <Title linkTitle={linkTitle} label={label} title={title} />
          </FlexContainer>
          <FlexContainer gap={theme.spacing.spacing03}>
            <Button
              severity="low"
              icon="edit"
              size="md"
              onClick={toggleShowUpdateForm}
            />
            <Button
              severity="low"
              icon="trash"
              size="md"
              onClick={openDeleteModal}
            />
          </FlexContainer>
        </FlexContainer>
        <When condition={showUpdateForm}>
          <UpdateForm
            updateFormValues={updateFormValues}
            onClose={toggleShowUpdateForm}
          />
        </When>
      </Box>
      <Modal
        isOpen={isOpenDeleteModal}
        contentLabel="Delete Billing Data"
        actions={[
          {
            order: 0,
            onAction: closeDeleteModal,
            label: "Cancel",
            severity: "low",
          },
          {
            order: 1,
            onAction: () => {
              onDelete(interactionId);
              closeDeleteModal();
            },
            label: "Delete",
            severity: "high",
          },
        ]}
        onClose={closeDeleteModal}
      >
        <Text>Are you sure you want to delete:</Text>
        <If condition={isNotNil(linkTitle)}>
          <Then>
            <Text>
              {linkTitle?.title} (<Text weight="bold">{title}</Text>)
            </Text>
          </Then>
          <Else>
            <Text weight="bold">{title}</Text>
          </Else>
        </If>
      </Modal>
    </>
  );
}

function Title({
  linkTitle,
  label,
  title,
}: {
  linkTitle?: LinkTitle;
  label: string;
  title: string;
}) {
  return (
    <FlexContainer direction="column" alignItems="flex-start">
      <If condition={isNotNil(linkTitle)}>
        <Then>
          <Link to={linkTitle?.link ?? ""}>{linkTitle?.title}</Link>
          <Text size="sm" color={theme.color.text.text02}>
            {title}
          </Text>
        </Then>
        <Else>
          <Text tag="span" size="sm" color={theme.color.text.text03}>
            {label}
          </Text>
          <Text>{title}</Text>
        </Else>
      </If>
    </FlexContainer>
  );
}

function getAccountStatusStyle(isActive: boolean) {
  if (isActive) {
    return {
      color: "success",
      name: "cloudOnline",
    } as const;
  }

  return {
    color: "danger",
    name: "cloudOffline",
  } as const;
}

type LinkTitle = {
  link: string;
  title: string;
};

type ProvidersWithBillingData = Provider.Aws | Provider.Azure | Provider.Gcp;
