/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { When } from "react-if";
import {
  Box,
  Button,
  FlexContainer,
  Message,
  Spacer,
  Text,
  theme,
} from "@nordcloud/gnui";
import { useToggle } from "~/hooks";
import { inflect, isLastIndex } from "~/tools";
import { DismissModal } from "./DismissModal";
import { useDismissSuggestion } from "./hooks";
import { SavingSuggestionsItem } from "./SavingSuggestionsItem";
import { CostSaving, DismissModalPayload } from "./types";

type Props = {
  costSavings: CostSaving[];
  provider: string;
  onSuggestionCountChanged: (v: number) => void;
};

export function SavingSuggestions({
  costSavings,
  provider,
  onSuggestionCountChanged,
}: Props) {
  const [showMore, setShowMore] = useToggle(false);
  const [modalPayload, setModalPayload] = useState<DismissModalPayload>({
    isOpen: false,
    detectionId: undefined,
  });

  const { dismiss, isDismissed } = useDismissSuggestion({
    setModalPayload,
  });

  const items = (costSavings ?? []).filter(
    (value) => !isDismissed(value.detectionId ?? "")
  );
  const size = items.length;

  useEffect(() => onSuggestionCountChanged(size), [size]);

  if (size === 0) {
    return (
      <Message status="notification" image="success">
        There are no Savings Suggestions.
      </Message>
    );
  }

  const onDismiss = (comment?: string) =>
    dismiss(modalPayload.detectionId ?? "", comment);

  const visibleItems = items.filter((_, index) =>
    showMore ? true : index === 0
  );

  return (
    <>
      <Text color={theme.color.text.text02} size="sm" mb={0}>
        You have {size} {inflect("recommendation")(size)} to reduce spends. To
        address a specific recommendation, use the Cloud Console accessible via
        the corresponding button.
      </Text>
      <Spacer height={theme.spacing.spacing04} />
      <Box boxStyle="lightGrey">
        <FlexContainer
          direction="column"
          alignItems="start"
          rowGap={theme.spacing.spacing04}
        >
          {visibleItems.map((costSaving, index, list) => (
            <SavingSuggestionsItem
              key={costSaving.detectionId}
              costSaving={costSaving}
              provider={provider}
              isLast={isLastIndex(list, index)}
              onSetOpenModal={setModalPayload}
            />
          ))}
        </FlexContainer>
      </Box>
      <When condition={size > 1}>
        <Spacer height={theme.spacing.spacing02} />
        <Button
          severity="low"
          css={{ width: "100%", justifyContent: "center" }}
          icon={showMore ? "chevronUp" : "chevronDown"}
          size="md"
          display="flex"
          onClick={setShowMore}
        >
          <Text size="sm" mb={0}>
            Show {showMore ? "less" : "more"}
          </Text>
        </Button>
      </When>
      <DismissModal
        isOpen={modalPayload.isOpen}
        onClose={setModalPayload}
        onDismiss={onDismiss}
      />
    </>
  );
}
