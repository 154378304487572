/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { useFormContext } from "react-hook-form";
import { When } from "react-if";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  FlexContainer,
  Input,
  Tab,
  Tabs,
  theme,
} from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { getFirstItem, isNotNil } from "~/tools";
import { AwsFormFields } from "~/views/cloudAccounts/types";
import { AwsFormData } from "../types";

type Props = {
  accountIds: {
    id: string;
    value: string;
  }[];
  setAccountIds: Dispatch<
    SetStateAction<
      {
        id: string;
        value: string;
      }[]
    >
  >;
};

export function AccountInfoTabs({ accountIds, setAccountIds }: Props) {
  const [currentTab, setCurrentTab] = useState(0);

  const {
    formState: { errors },
  } = useFormContext<AwsFormData>();

  const addAccountIdInput = () => {
    setAccountIds((prev) => [...prev, { id: uuidv4(), value: "" }]);
  };

  const removeAccountIdInput = (id: string) => {
    const newAccountIds = accountIds.filter(
      ({ id: accountId }) => id !== accountId
    );
    setAccountIds(newAccountIds);
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>, id: string) => {
    const newAccountIds = accountIds.map((accountId) => {
      if (accountId.id === id) {
        return {
          id,
          value: event.target.value,
        };
      }
      return accountId;
    });
    setAccountIds(newAccountIds);
  };

  return (
    <div
      css={{
        border: theme.borders.grey,
        borderRadius: theme.radius.md,
        width: "100%",
      }}
    >
      <Tabs step={currentTab} handleTab={setCurrentTab}>
        <Tab label="Provide account ID">
          <FormGroup
            customCss={{ marginBottom: 0 }}
            error={getFirstItem(
              (errors[AwsFormFields.ACCOUNT_IDS] ?? []).filter(isNotNil)
            )}
          >
            <FlexContainer
              direction="column"
              alignItems="start"
              rowGap={theme.spacing.spacing03}
            >
              {accountIds.map(({ id, value }) => {
                return (
                  <AccountIdInputField
                    key={id}
                    removeAccountIdInput={removeAccountIdInput}
                    id={id}
                    value={value}
                    accountIds={accountIds}
                    onInputChange={onInputChange}
                  />
                );
              })}
              <Button
                severity="low"
                icon="plus"
                type="button"
                onClick={addAccountIdInput}
              >
                Add Account
              </Button>
            </FlexContainer>
          </FormGroup>
        </Tab>
      </Tabs>
    </div>
  );
}

type AccountIdInputFieldProps = {
  id: string;
  value: string;
  accountIds: { id: string; value: string }[];
  onInputChange: (event: ChangeEvent<HTMLInputElement>, id: string) => void;
  removeAccountIdInput: (id: string) => void;
};

function AccountIdInputField({
  id,
  value,
  accountIds,
  onInputChange,
  removeAccountIdInput,
}: AccountIdInputFieldProps) {
  return (
    <FlexContainer columnGap={theme.spacing.spacing03} css={{ width: "100%" }}>
      <div css={{ flexGrow: 1 }}>
        <Input
          value={value}
          placeholder="e.g. 123456789012"
          onChange={(event) => onInputChange(event, id)}
        />
      </div>
      <When condition={accountIds.length > 1}>
        <Button
          severity="low"
          icon="trash"
          type="button"
          onClick={() => removeAccountIdInput(id)}
        />
      </When>
    </FlexContainer>
  );
}
