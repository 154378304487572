/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer, Modal, theme } from "@nordcloud/gnui";
import { useDisclosure } from "~/hooks";
import { SLACK_MSG_PREVIEW_MODAL_TITLE } from "../constants";
import { PreviewButton } from "../EmailSettingsForm/PreviewButton";
import { CloudCredentialsPreviewMessage } from "../PreviewMessages";

export function CloudCredentialsMessage() {
  const { isOpen, close, open } = useDisclosure();

  return (
    <>
      <FlexContainer
        paddingBottom={theme.spacing.spacing02}
        alignItems="center"
        justifyContent="space-between"
      >
        <PreviewButton type="button" size="m" onClick={open}>
          Sample Message
        </PreviewButton>
      </FlexContainer>
      <Modal
        isOpen={isOpen}
        actions={[]}
        contentMaxHeight="40rem"
        contentLabel={SLACK_MSG_PREVIEW_MODAL_TITLE}
        onClose={close}
      >
        <CloudCredentialsPreviewMessage />
      </Modal>
    </>
  );
}
