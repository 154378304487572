/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer, Text, theme } from "@nordcloud/gnui";
import { DottedLine, SolidLine } from "~/components";

export function CostAllocationLegend() {
  return (
    <FlexContainer>
      <SolidLine color={theme.color.text.text01} />
      <Text color={theme.color.text.text01} size="xs" m={0}>
        Total Cost Allocation
      </Text>
      <DottedLine color={theme.color.text.text01} />
      <Text color={theme.color.text.text01} size="xs" m={0}>
        Estimated Total Cost Allocation
      </Text>
    </FlexContainer>
  );
}
