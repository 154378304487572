/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { FlexContainer, theme } from "@nordcloud/gnui";

export const FiltersFooter = styled(FlexContainer)`
  position: sticky;
  bottom: 0;
  padding: ${theme.spacing.spacing04};
  margin: -${theme.spacing.spacing04};
  background-color: ${theme.color.background.ui01};
  border-top: ${theme.borders.grey};
`;
