/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { ApolloError } from "@apollo/client";
import { When } from "react-if";
import { Spacer, Tab, Tabs, theme } from "@nordcloud/gnui";
import { Ec2InstanceFamily, Provider } from "~/generated/graphql";
import { BreadcrumbsBox, UniversalWrap } from "~/components";
import { useTabsWithUrl } from "~/hooks";
import {
  isNotEmpty,
  isNotNil,
  ResourceLifecycleDates,
  UsageOperationType,
} from "~/tools";
import {
  CountHeader,
  Ec2AdvancedData,
  Ec2ResourceTags,
  mapMetadata,
  Metadata,
  ReservedInstances,
  SavingSuggestions,
} from "./components";
import {
  Breadcrumbs,
  CloudAccountMissing,
  Ec2ImageDetails,
  Ec2InstanceApplications,
  Ec2InstanceComparison,
  Ec2InstanceDetails,
  Ec2InstanceHeader,
  Ec2InstanceUtilization,
  Ec2Networking,
  Ec2Storage,
  EstateRecord,
  LinkedResources,
  useCloudAccountStatus,
  useEc2InstanceDetails,
  useHeader,
  useImageDetails,
  useInstanceComparison,
} from "./Ec2Instance";
import { externalIntegrationTabs } from "./EstateDetailsTabs/components";
import { useEstateDetails } from "./hooks";
import { useExistingReservedInstances } from "./hooks/useExistingReservedInstances";
import { ApplicationProps, Costs } from "./types";

type Props = {
  estateRecord: EstateRecord;
  linkedResources: LinkedResources | undefined;
  metadata: Metadata[];
  metatags: Metadata[];
  applications: ApplicationProps;
  loading: boolean;
  error: ApolloError | undefined;
  breadcrumbs: Breadcrumbs;
  costs: Costs;
  nid: string;
  resourceLifecycleDates: ResourceLifecycleDates | undefined;
  enrichments: ReturnType<typeof useEstateDetails>["data"]["enrichments"];
};

export function Ec2InstancePage({
  estateRecord,
  linkedResources,
  metadata,
  metatags,
  applications,
  loading,
  error,
  breadcrumbs,
  costs,
  nid,
  resourceLifecycleDates,
  enrichments,
}: Props) {
  const [savingSuggestionsCount, setSavingSuggestionsCount] = useState(
    estateRecord.costSavings?.length ?? 0
  );
  const [comparisonType, setComparisonType] = useState<Ec2InstanceFamily>(
    Ec2InstanceFamily.GeneralPurpose
  );

  const {
    activeTab: additionalDataActiveTab,
    setActiveTab: setAdditionalDataActiveTab,
  } = useTabsWithUrl("additional-data");
  const {
    activeTab: comparisonActiveTab,
    setActiveTab: setComparisonActiveTab,
  } = useTabsWithUrl("comparison");

  const {
    message,
    isActivated,
    loading: isCloudStatusLoading,
  } = useCloudAccountStatus({ nid });

  const {
    reservations,
    reservedInstanceBenefit,
    loading: isExistingReservedInstancesLoading,
    error: existingReservedInstancesError,
  } = useExistingReservedInstances({
    nid,
  });

  const name = estateRecord.name;
  const headerData = useHeader({
    estateRecord,
    costs: { ...costs, reservedInstanceBenefit },
    linkedResources,
  });

  const mappedMetadata = useEc2InstanceDetails(
    metadata,
    metatags,
    resourceLifecycleDates
  );
  const imageDetails = useImageDetails(metadata);

  const volumeIds = mappedMetadata.storage.deviceMappings.map(
    ({ volumeId, volumeNid }) => ({
      volumeId,
      volumeNid,
    })
  );

  const savingSuggestionTypes =
    estateRecord.costSavings
      ?.flatMap((suggestion) => suggestion?.metadata?.recommendedInstanceType)
      .filter(isNotNil) ?? [];

  const {
    instanceType,
    usageOperation,
    placement: { tenancy } = {},
  } = mapMetadata<{
    instanceType: string;
    usageOperation: UsageOperationType;
    placement:
      | {
          tenancy?: string;
        }
      | undefined;
  }>(metadata);

  const region = estateRecord.region;

  const showEnrichments = isNotEmpty(enrichments ?? []);

  const {
    getInstanceComparison,
    comparison,
    loading: isComparisonLoading,
    error: comparisonError,
  } = useInstanceComparison({
    nid,
    usageOperation,
    region,
    tenancy,
    type: instanceType,
    isActivated,
    savingSuggestionTypes,
  });

  const onComparisonTypeChange = (type: Ec2InstanceFamily) => {
    void getInstanceComparison(type);
    setComparisonType(type);
  };

  useEffect(
    () => onComparisonTypeChange(Ec2InstanceFamily.GeneralPurpose),
    [isActivated]
  );

  return (
    <UniversalWrap loaderProps={{ loading }} errorProps={{ error }}>
      <BreadcrumbsBox title={name} labels={breadcrumbs} />
      <When condition={!isCloudStatusLoading && !isActivated}>
        <CloudAccountMissing message={message} />
        <Spacer height={theme.spacing.spacing04} />
      </When>
      <Ec2InstanceHeader {...headerData} />
      <Spacer height={theme.spacing.spacing04} />
      <Ec2InstanceDetails {...mappedMetadata.instance} />
      <Spacer height={theme.spacing.spacing04} />
      <Ec2InstanceApplications {...applications} />
      <Spacer height={theme.spacing.spacing04} />
      <Tabs step={comparisonActiveTab} handleTab={setComparisonActiveTab}>
        <Tab
          label={
            <CountHeader
              count={savingSuggestionsCount}
              title="Savings Suggestions"
            />
          }
        >
          <SavingSuggestions
            costSavings={estateRecord.costSavings ?? []}
            provider={estateRecord.provider ?? Provider.Aws}
            onSuggestionCountChanged={setSavingSuggestionsCount}
          />
        </Tab>
        <Tab heading="Instance Comparison">
          <Ec2InstanceComparison
            comparison={comparison}
            error={comparisonError}
            isActivated={isActivated}
            loading={isComparisonLoading}
            type={comparisonType}
            onComparisonTypeChange={onComparisonTypeChange}
          />
        </Tab>
        <Tab heading="Reserved Instances">
          <ReservedInstances
            usageOperation={usageOperation ?? "RunInstances"}
            instanceType={instanceType ?? ""}
            region={region ?? ""}
            reservations={reservations}
            isLoading={isExistingReservedInstancesLoading}
            error={existingReservedInstancesError}
          />
        </Tab>
      </Tabs>
      <Spacer height={theme.spacing.spacing04} />
      <Ec2InstanceUtilization
        nid={nid}
        isActivated={isActivated}
        volumeIds={volumeIds}
      />
      <Spacer height={theme.spacing.spacing04} />
      <Tabs
        step={additionalDataActiveTab}
        handleTab={setAdditionalDataActiveTab}
      >
        <Tab heading="Image Details">
          <Ec2ImageDetails {...imageDetails} />
        </Tab>
        <Tab heading="Networking">
          <Ec2Networking {...mappedMetadata.networking} />
        </Tab>
        <Tab heading="Storage">
          <Ec2Storage {...mappedMetadata.storage} />
        </Tab>
        <Tab
          label={<CountHeader count={metatags.length} title="Resource Tags" />}
        >
          <Ec2ResourceTags metatags={metatags} />
        </Tab>
        <Tab
          label={<CountHeader count={metadata.length} title="Advanced Data" />}
        >
          <Ec2AdvancedData metadata={metadata} nid={nid} />
        </Tab>
        {showEnrichments && externalIntegrationTabs(enrichments)}
      </Tabs>
    </UniversalWrap>
  );
}
