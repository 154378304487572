/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

export const DefaultEc2GridWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  row-gap: ${theme.spacing.spacing02};
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: ${theme.breakpoints.lg}px) {
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
`;
