/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { dateFormat } from "~/constants";
import { isString } from "./guards";
import { MixedValue } from "./types";

export function formatCustomDate(
  date: MixedValue,
  format: string = dateFormat.fullDate
) {
  return isString(date) && dayjs(date).isValid()
    ? dayjs.utc(date).format(format)
    : "";
}

export function formatShortDate(date?: string) {
  if (date) {
    return dayjs(date).format(dateFormat.shortDate);
  }
}
