/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useRef, useState } from "react";
import { When } from "react-if";
import styled from "styled-components";
import { FlexContainer, Loader } from "@nordcloud/gnui";
import { FilterHeader, Mode } from "~/components";
import { FiltersProp } from "~/models/estateRecordRow";
import { useEnvFilterSelector, useInfiniteApplications } from "../hooks";
import { SelectableApplicationRow } from "./SelectableApplicationRow";

type Props = {
  selectedEnvironments: string[];
  isCleared: boolean;
  onMultiSelect: (filter: FiltersProp) => void;
  headerProps?: {
    allowModeChange?: boolean;
    defaultMode?: Mode;
    onModeChange?: (mode: Mode) => void;
  };
};

const defaultHeaderProps = {};

export function AppEnvAccordion({
  selectedEnvironments,
  isCleared,
  onMultiSelect,
  headerProps = defaultHeaderProps,
}: Props) {
  const [applicationName, setApplicationName] = useState("");
  const elementRef = useRef(null);

  const { isAppsLoading, applications, selectedEnvs, setSelectedEnvs } =
    useInfiniteApplications({
      elementRef,
      selectedEnvironments,
      applicationName,
      isCleared,
    });

  const { selectApp, selectEnv } = useEnvFilterSelector({
    apps: applications,
    selectedEnvs,
    setSelectedEnvs,
  });

  useEffect(() => {
    onMultiSelect({
      environment: selectedEnvs.map(({ nid }) => nid),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEnvs]);

  return (
    <>
      <FilterHeader
        showSearchBar
        onSetSearch={setApplicationName}
        {...headerProps}
      />
      <SelectableApplicationRowWrapper>
        {applications.map((application) => (
          <SelectableApplicationRow
            key={application.nid}
            application={application}
            selectedEnvs={selectedEnvs}
            onAppCheck={selectApp}
            onEnvCheck={selectEnv}
          />
        ))}
        <When condition={isAppsLoading}>
          <Loader inContent />
        </When>
        <div ref={elementRef} />
      </SelectableApplicationRowWrapper>
    </>
  );
}

const SelectableApplicationRowWrapper = styled(FlexContainer)`
  align-items: flex-start;
  flex-direction: column;
  max-height: 20rem;
  overflow-y: scroll;
  overflow-x: hidden;
`;
