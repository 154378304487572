/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { createFilter } from "react-select";
import { FlexContainer, Select, Text, theme } from "@nordcloud/gnui";
import { usePeopleQuery } from "~/generated/graphql";
import { ContactPerson } from "~/components/KPI/types";
import { isNotNil } from "~/tools";

type Props = {
  onChange: (value?: string) => void;
  contactPerson?: ContactPerson | null;
};

export function ContactPersonSelect({ onChange, contactPerson }: Props) {
  const { data, loading } = usePeopleQuery();

  const options = data?.people?.filter(isNotNil).map((person) => ({
    value: person.id,
    label: person.name,
    email: person.email,
  }));

  const defaultValue = contactPerson
    ? {
        value: contactPerson.id ?? "",
        label: contactPerson.name ?? "",
        email: contactPerson.email ?? "",
      }
    : null;

  return (
    <Select
      isDisabled={loading}
      options={options}
      isLoading={loading}
      placeholder="KPI Target Contact Person"
      formatOptionLabel={ContactPersonOption}
      defaultValue={defaultValue}
      filterOption={createFilter({ ignoreAccents: false, ignoreCase: true })}
      styles={{
        input: (base) => ({
          ...base,
          color: theme.color.text.text01,
          height: "34px",
        }),
      }}
      onChange={(option) => onChange(option?.value)}
    />
  );
}

type ContactPersonOptionProps = {
  value: string;
  label: string;
  email: string;
};

function ContactPersonOption({ label, email }: ContactPersonOptionProps) {
  return (
    <FlexContainer alignItems="start" justifyContent="space-between">
      <Text tag="span">{label}</Text>
      <Text tag="span" size="sm" css={{ opacity: 0.7 }}>
        {email}
      </Text>
    </FlexContainer>
  );
}
