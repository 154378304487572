/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Cell } from "react-table";
import { Text } from "@nordcloud/gnui";
import { SkuCost } from "~/generated/graphql";
import { CostCell } from "~/components";

export const SKU_COSTS_COLUMNS = [
  {
    Header: "Name",
    accessor: "skuName",
    disableSortBy: true,
    width: "60%",
    Cell: ({ row }: Cell<SkuCost>) => (
      <Text tag="span">{row.original.name}</Text>
    ),
  },
  {
    Header: "Cost",
    accessor: "skuValue",
    disableSortBy: true,
    width: "40%",
    Cell: ({ row }: Cell<SkuCost>) => (
      <CostCell value={row.original.value ?? ""} />
    ),
  },
];
