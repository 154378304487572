/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Spacer } from "@nordcloud/gnui";
import { CollapsibleBox } from "~/components";
import { getUrl } from "../../helpers";
import { DefaultEc2GridWrapper, Item } from "../components";

type Props = {
  groupName: string;
  groupId: string;
  vpcId: string;
  vpcNid: string;
  description: string;
  firstSeen: string;
  lastSeen: string;
};

export function Ec2SecurityGroupDetails({
  vpcId,
  vpcNid,
  groupName,
  groupId,
  description,
  firstSeen,
  lastSeen,
}: Props) {
  return (
    <CollapsibleBox title="Details">
      <DefaultEc2GridWrapper>
        <Item label="Security Group name" value={groupName} truncate />
        <Item label="Security Group ID" value={groupId} />
        <Item
          label="VPC ID"
          value={vpcId}
          url={getUrl(vpcId, vpcNid, "ec2/vpc")}
        />
        <Item label="Description" value={description} truncate />
        <Spacer />
        <Spacer />
        <Item label="First Seen" value={firstSeen} />
        <Item label="Last Seen" value={lastSeen} />
      </DefaultEc2GridWrapper>
    </CollapsibleBox>
  );
}
