/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
} from "~/generated/graphql";

export function useGetServiceUsageType(activeTab: number) {
  switch (activeTab) {
    case 1:
      return {
        serviceUsageType:
          ReservationsAndCommitmentsCoverageServiceUsageType.Database,
        reservationOfferingType:
          ReservationsAndCommitmentsCoverageOfferingType.All,
      };
    case 2:
      return {
        serviceUsageType:
          ReservationsAndCommitmentsCoverageServiceUsageType.MachineLearning,
        reservationOfferingType:
          ReservationsAndCommitmentsCoverageOfferingType.SavingsPlans,
      };
    default:
      return {
        serviceUsageType:
          ReservationsAndCommitmentsCoverageServiceUsageType.Compute,
        reservationOfferingType:
          ReservationsAndCommitmentsCoverageOfferingType.All,
      };
  }
}
