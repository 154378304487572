/**
 * Copyright 2021-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { generatePath, Link } from "react-router-dom";
import {
  Col,
  FlexContainer,
  Row,
  SVGIcon,
  Text,
  theme,
  Tooltip,
} from "@nordcloud/gnui";
import { TARGET_TYPE } from "~/components/Charts/types";
import { BrickLoader } from "~/components/Loaders";
import { SelectableBox } from "~/components/SelectableBox";
import { LoaderWrap } from "~/components/Utils";
import { getKpiType, KpiType } from "~/constants";
import { ROUTES } from "~/routing/routes";
import { noop } from "~/tools";
import { KpiSummary } from "./KpiSummary";

type OrgUnitItem = {
  name: string;
  nid: string;
  value: string;
  additionalValue?: string;
};

type Props = {
  orgUnits?: OrgUnitItem[];
  selectedOrgUnit?: string;
  kpiType: KpiType;
  onClick: ({ id, name }: { id: string; name: string }) => void;
  loading: boolean;
  businessContextId: string;
  isNested?: boolean;
};

export function WorstOrgUnits({
  orgUnits = [],
  selectedOrgUnit,
  kpiType,
  onClick,
  loading,
  businessContextId,
  isNested = false,
}: Props) {
  return (
    <>
      <FlexContainer
        marginBottom={theme.spacing.spacing02}
        gap={theme.spacing.spacing02}
      >
        <Text weight="medium" mb={0}>
          {getTitle(kpiType, isNested)}
        </Text>
        <Tooltip caption="The following list of Organizational Units is sorted by the greatest impact on your organization">
          <SVGIcon name="info" size="sm" />
        </Tooltip>
      </FlexContainer>
      <LoaderWrap loading={loading} Component={BrickLoader} viewBox="0 0 10 1">
        <Row css={{ rowGap: theme.spacing.spacing06 }}>
          {orgUnits.map((orgUnit, index) => (
            <Col
              key={`${orgUnit.nid}${index}`}
              xl={2.4}
              lg={2.4}
              md={1.6}
              sm={4}
            >
              <OrgUnitBox
                type={kpiType}
                selected={selectedOrgUnit === orgUnit.nid}
                businessContextId={businessContextId}
                onClick={onClick}
                {...orgUnit}
              />
            </Col>
          ))}
        </Row>
      </LoaderWrap>
    </>
  );
}

type OrgUnitProps = {
  name: string;
  nid: string;
  businessContextId: string;
  value: string;
  additionalValue?: string;
  type: KpiType;
  selected?: boolean;
  onClick: ({ id, name }: { id: string; name: string }) => void;
};

function OrgUnitBox({
  name,
  nid,
  businessContextId,
  type,
  value,
  additionalValue,
  onClick = noop,
  selected = false,
}: OrgUnitProps) {
  return (
    <SelectableBox
      selected={selected}
      onClick={() => onClick({ id: nid, name })}
    >
      <FlexContainer gap={theme.spacing.spacing03} alignItems="flex-start">
        <div css={{ flex: 1 }}>
          <Link
            to={generatePath(ROUTES.businessContexts.details, {
              structureId: businessContextId,
              unitId: nid,
            })}
            onClick={(e) => e.stopPropagation()}
          >
            {name}
          </Link>
          <KpiSummary
            items={[
              {
                key: getKpiType(type).valueLabel,
                value: value ?? "",
                additionalValue: additionalValue ?? undefined,
              },
            ]}
          />
        </div>
      </FlexContainer>
    </SelectableBox>
  );
}

function getTitle(kpiType: KpiType, isNested: boolean) {
  const { label, targetType } = getKpiType(kpiType);
  const base = `Organizational Units with ${
    targetType === TARGET_TYPE.positive ? "highest" : "lowest"
  } ${label}`;
  return isNested ? `Child ${base}` : base;
}
