/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { SetStateAction } from "react";
import { Maybe } from "graphql/jsutils/Maybe";
import { isNotEmpty, isNotNil } from "~/tools";
import { generateSelectedEnvs } from "../AppEnvSelectorPaginated/utils";
import { ApplicationOption, EnvironmentOption } from "../types";
import { filterUniqueEnvironments, findById, findByNid } from "./utils";

type Props = {
  apps: Maybe<ApplicationOption[]>;
  selectedApps: ApplicationOption[];
  setSelectedApps: (value: SetStateAction<ApplicationOption[]>) => void;
  selectedEnvs: EnvironmentOption[];
  setSelectedEnvs: (value: SetStateAction<EnvironmentOption[]>) => void;
};

export function useAppEnvFilterSelector({
  apps = [],
  selectedApps,
  setSelectedApps,
  selectedEnvs,
  setSelectedEnvs,
}: Props) {
  function findParentApp(env: EnvironmentOption) {
    return apps?.find((app) =>
      app.environments.find(({ nid }) => nid === env.nid)
    );
  }
  function removeAppFromSelectedApps(appNid: string) {
    setSelectedApps((prevState) =>
      prevState.filter(({ nid }) => nid !== appNid)
    );
  }

  function addAppToSelectedApps(app: ApplicationOption) {
    setSelectedApps((prevState) => [...prevState, app]);
  }

  function addEnvToSelectedEnvs(env: EnvironmentOption) {
    setSelectedEnvs((prevState) => [...prevState, env]);
  }

  function removeEnvFromSelectedEnvs(envNid: string) {
    setSelectedEnvs((prevState) =>
      prevState.filter(({ nid }) => nid !== envNid)
    );
  }

  function removeChildrenEnvsFromSelectedEnvs(app: ApplicationOption) {
    setSelectedEnvs((prevState) => {
      return prevState.filter((prevEnv) =>
        app.environments.every(
          (parentEnv) => parentEnv && parentEnv.nid !== prevEnv.nid
        )
      );
    });
  }

  function addChildrenEnvsToSelectedEnvs(
    parentApp: ApplicationOption,
    env?: EnvironmentOption
  ) {
    // Toggle-on other envs belonging to parent app if env is provided
    // Toggle-on all envs belonging to parent if env is not provided
    const selectedEnvNids =
      parentApp.environments
        .map((parentEnv) => {
          if (parentEnv && parentEnv.nid !== env?.nid) {
            return parentEnv.nid;
          }

          return undefined;
        })
        .filter(isNotNil) ?? [];
    setSelectedEnvs((prevState) =>
      filterUniqueEnvironments([
        ...prevState,
        ...generateSelectedEnvs(selectedEnvNids),
      ])
    );
  }

  function areAllEnvsSelected(
    parentApp: ApplicationOption,
    env: EnvironmentOption
  ) {
    return parentApp?.environments.every((parentEnv) =>
      [...selectedEnvs, env].some(
        (selectedEnv) => selectedEnv.nid === parentEnv?.nid
      )
    );
  }

  const selectApp = (appNid: string) => {
    // When app is toggled on/off, remove all of its envs from selected envs
    const app = findById(apps, appNid);
    if (app) {
      if (findByNid(selectedApps, appNid)) {
        removeAppFromSelectedApps(appNid);

        if (isNotNil(app.environments) && isNotEmpty(app.environments)) {
          removeChildrenEnvsFromSelectedEnvs(app);
        }
      } else {
        addAppToSelectedApps(app);

        if (isNotNil(app.environments) && isNotEmpty(app.environments)) {
          addChildrenEnvsToSelectedEnvs(app);
        }
      }
    }
  };

  const selectEnv = (env: EnvironmentOption) => {
    const parentApp = findParentApp(env);
    const isParentAppSelected =
      parentApp?.nid && findByNid(selectedApps, parentApp.nid);
    const isEnvSelected = findByNid(selectedEnvs, env.nid);

    if (isEnvSelected || isParentAppSelected) {
      if (parentApp && isParentAppSelected) {
        removeAppFromSelectedApps(parentApp?.nid ?? "");
      }
      removeEnvFromSelectedEnvs(env.nid);
    } else if (parentApp && areAllEnvsSelected(parentApp, env)) {
      addAppToSelectedApps(parentApp);
      removeChildrenEnvsFromSelectedEnvs(parentApp);
    } else {
      addEnvToSelectedEnvs(env);
    }
  };

  return {
    selectApp,
    selectEnv,
  };
}
