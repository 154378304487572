import {
  ReservationAndMetadataFilters,
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsProvider,
  useReservationsAndMetadataQuery,
} from "~/generated/graphql";
import { isNotNil } from "~/tools";
import { DATABASE_PLATFORM_OPTIONS } from "../constants";
import { valueFormatter } from "../utils";

type ExistingLinkProps = {
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  offeringType: ReservationsAndCommitmentsCoverageOfferingType;
  filters: ReservationAndMetadataFilters | undefined;
  page: number;
  pageSize: number;
  provider: ReservationsProvider;
};

export function useReservationsExistingLinks({
  serviceUsageType,
  offeringType,
  page,
  pageSize,
  filters,
  provider,
}: ExistingLinkProps) {
  const { data, loading, error } = useReservationsAndMetadataQuery({
    variables: {
      limit: pageSize,
      page,
      offeringType,
      serviceUsageType,
      onlyCount: false,
      filters,
      provider,
    },
  });
  const reservationFilters = data?.reservationsAndMetadata?.filters;

  const platformOptions =
    reservationFilters?.platforms?.filter(isNotNil)?.map((platformName) => {
      const mappedDatabase = DATABASE_PLATFORM_OPTIONS.find(
        ({ mappedPlatform }) => mappedPlatform.includes(platformName)
      );
      const description =
        serviceUsageType ===
        ReservationsAndCommitmentsCoverageServiceUsageType.Compute
          ? platformName
          : valueFormatter(mappedDatabase?.platform, "");
      return {
        label: description,
        value: description,
      };
    }) ?? [];
  const instanceTypeOptions =
    reservationFilters?.resourceTypes
      ?.filter(isNotNil)
      ?.map((instanceTypeName) => ({
        label: instanceTypeName,
        value: instanceTypeName,
      })) ?? [];

  return {
    data,
    loading,
    error,
    platformOptions,
    instanceTypeOptions,
    filters,
    offeringType,
    serviceUsageType,
  };
}
