/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { isNotNil, setUnion } from "~/tools";
import { Node, TreeEvent } from "../types";

type TreeAction = {
  type: ActionType;
  ids: string[];
};

const enum ActionType {
  ADD = "add",
  REMOVE = "remove",
}

type Behavior = "independent" | "linkedToParent";

export function eventToActions(
  { data }: TreeEvent,
  behavior: Behavior = "independent"
): TreeAction[] {
  const actionType = data.checked ? ActionType.REMOVE : ActionType.ADD;

  const { checked, unchecked } = readBranchIds(data);
  const linkedIds = getLinkedIds(data, behavior);

  if (actionType === ActionType.ADD) {
    return [
      {
        type: ActionType.ADD,
        ids: [data.id, ...unchecked, ...linkedIds],
      },
    ];
  }

  return [
    {
      type: ActionType.REMOVE,
      ids: [data.id, ...checked, ...linkedIds],
    },
  ];
}

type ChildrenData = {
  checked: string[];
  unchecked: string[];
};

function readBranchIds(root: Node): ChildrenData {
  return root.children.reduce<ChildrenData>(
    (acc, curr) => {
      const currentChecked = curr.checked ? [curr.id] : [];
      const currentUnchecked = curr.checked ? [] : [curr.id];
      const childrenData = readBranchIds(curr);

      return {
        checked: [...acc.checked, ...currentChecked, ...childrenData.checked],
        unchecked: [
          ...acc.unchecked,
          ...currentUnchecked,
          ...childrenData.unchecked,
        ],
      };
    },
    {
      checked: [],
      unchecked: [],
    }
  );
}

function getLinkedIds(data: Node, behavior: Behavior) {
  if (behavior === "independent" || !data.isLast) {
    return [];
  }

  return isNotNil(data.parentId)
    ? setUnion([data.parentId], data.lastParentIds ?? [])
    : [];
}
