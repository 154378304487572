/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import styled, { css } from "styled-components";
import { space, SpaceProps } from "styled-system";
import { Flex, SVGIcon, theme } from "@nordcloud/gnui";

type Status = "danger" | "success";

type StyledInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  small?: boolean;
  value?: string;
};

type StyledInputGroupProps = {
  status?: Status;
};

type Props = SpaceProps & StyledInputGroupProps & StyledInputProps;

// Enhance the input componment to expand the height automatically if it exceeds the width of input.
export const EnhancedInput = React.forwardRef<HTMLInputElement, Props>(
  ({ disabled, status, value, onChange, ...props }, ref) => {
    const initValue = React.useRef(value);
    return (
      <InputGroup status={status}>
        <Input
          ref={ref}
          contentEditable={!disabled}
          dangerouslySetInnerHTML={{ __html: `${initValue.current ?? ""}` }}
          onInput={(event: React.FormEvent<HTMLDivElement>) => {
            const modifiedEvent = Object.assign({}, event, {
              target: {
                ...event.currentTarget,
                value: event.currentTarget.textContent,
              },
            }) as React.ChangeEvent<HTMLInputElement>;
            onChange?.(modifiedEvent);
          }}
          {...props}
        />
        {status && <SVGIcon name={status} size="smd" />}
      </InputGroup>
    );
  }
);

const setStatusColor = ({ status }: StyledInputGroupProps) => {
  if (status === "danger") {
    return css`
      border: 1px solid ${theme.color.border.error};
    `;
  }
  if (status === "success") {
    return css`
      border: 1px solid ${theme.color.border.input};
    `;
  }
};

const InputGroup = styled(Flex)<SpaceProps & StyledInputGroupProps>`
  position: relative;
  align-items: center;
  border: 1px solid ${theme.color.border.input};
  padding: ${theme.spacing.spacing02};
  border-radius: ${theme.radiusDefault};
  color: ${theme.color.text.text01};
  background: ${theme.color.field.default};
  transition: ${theme.transition};
  overflow: hidden;

  &:hover {
    border: 1px solid ${theme.color.border.focus};
  }

  &:focus {
    outline: 0;
    border: 1px solid ${theme.color.border.focus};
  }
  ${setStatusColor};
  ${space};
`;

const Input = styled.div<StyledInputProps>`
  border: 0;
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  font-family: ${theme.typography.fonts.body};
  font-size: ${(props: StyledInputProps) =>
    props.small ? theme.fontSizes.sm : theme.fontSizes.md};
  line-height: ${(props: StyledInputProps) =>
    props.small ? "1rem" : theme.lineHeight};
  padding: 0;
  margin: 0;
  transition: ${theme.transition};
  color: ${theme.color.text.text01};
  &:hover,
  &:focus {
    outline: 0;
    border: 0;
  }

  /* placeholder */
  &:empty:before {
    content: attr(placeholder);
    color: ${theme.color.text.text03};
    pointer-events: none;
    display: block; /* For Firefox */
  }

  /* disabled */
  &[contenteditable="false"] {
    color: ${theme.color.text.text03};
    cursor: not-allowed;
  }
`;
