/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Button, Spacer, theme } from "@nordcloud/gnui";
import { FiltersFooter, FilterWrapper, Mode } from "~/components";
import { isEmpty, isNotEmpty } from "~/tools";
// I have tried to move those imports from `~/views/estate` to `~/components` but so far spend 130 minutes
// and still haven't reached anything close for it to be considered as done.
// This is hydra.
// Inside those components there are imports from various specialized components/styles that are so tied to
// estate directory structure that it is mostly impossible to move it to `~/components`.
//
// If you tried and failed, put some comment here. So more people know that you wanted to be "cool guy" (as I did).
// If you tried and succeeded - my sincere congratulations, you are tremendous person, and you deserve a pay rise.
import { AppEnvAccordion } from "~/views/estate/components/Filters";
import { Filters, Modes } from "../types";

type Props = {
  onApply: (mode: Modes, filters: Filters) => void;
  defaultParams: { modes: Modes; filters: Filters } | undefined;
};

export function DriftFilters({ onApply, defaultParams }: Props) {
  const defaultFilters = {
    currentEnvironments: [],
    targetEnvironments: [],
  };
  const defaultModes = {
    currentMode: Mode.INCLUDE,
    targetMode: Mode.INCLUDE,
  };
  const initialFilters = defaultParams?.filters ?? defaultFilters;

  const [mode, setMode] = useState<Modes>(defaultParams?.modes ?? defaultModes);
  const [filters, setFilters] = useState<Filters>(initialFilters);

  const hasEmptyFilters = isEmpty([
    ...filters.currentEnvironments,
    ...filters.targetEnvironments,
  ]);
  const hasEmptyInitialFilters = isEmpty([
    ...initialFilters.currentEnvironments,
    ...initialFilters.targetEnvironments,
  ]);

  const commit = () => {
    onApply(mode, filters);
  };

  const reset = () => {
    onApply(defaultModes, defaultFilters);
  };

  return (
    <>
      <FilterWrapper
        name="Current Applications and Environments"
        selectedOptions={filters.currentEnvironments}
        isSelected={isNotEmpty(filters.currentEnvironments)}
        onClear={() =>
          setFilters((prevState) => ({
            ...prevState,
            currentEnvironments: [],
          }))
        }
      >
        <AppEnvAccordion
          selectedEnvironments={filters.currentEnvironments}
          isCleared={isEmpty(filters.currentEnvironments)}
          headerProps={{
            allowModeChange: true,
            defaultMode: mode.currentMode,
            onModeChange: (newMode: Mode) =>
              setMode((prev) => ({
                ...prev,
                currentMode: newMode,
              })),
          }}
          onMultiSelect={(newFilters) => {
            setFilters((prevState) => ({
              ...prevState,
              currentEnvironments: newFilters.environment ?? [],
            }));
          }}
        />
      </FilterWrapper>
      <Spacer height={theme.spacing.spacing04} />
      <FilterWrapper
        name="Target Applications and Environments"
        selectedOptions={filters.targetEnvironments}
        isSelected={isNotEmpty(filters.targetEnvironments)}
        onClear={() =>
          setFilters((prevState) => ({
            ...prevState,
            targetEnvironments: [],
          }))
        }
      >
        <AppEnvAccordion
          selectedEnvironments={filters.targetEnvironments}
          isCleared={isEmpty(filters.targetEnvironments)}
          headerProps={{
            allowModeChange: true,
            defaultMode: mode.targetMode,
            onModeChange: (newMode: Mode) =>
              setMode((prev) => ({
                ...prev,
                targetMode: newMode,
              })),
          }}
          onMultiSelect={(newFilters) => {
            setFilters((prevState) => ({
              ...prevState,
              targetEnvironments: newFilters.environment ?? [],
            }));
          }}
        />
      </FilterWrapper>
      <Spacer
        height={theme.spacing.spacing04}
        css={{ marginTop: theme.spacing.spacing04 }}
      />
      <FiltersFooter>
        <Button
          disabled={hasEmptyFilters && hasEmptyInitialFilters}
          mr={theme.spacing.spacing02}
          onClick={commit}
        >
          Apply filters
        </Button>
        <Button
          severity="low"
          disabled={hasEmptyFilters && hasEmptyInitialFilters}
          onClick={reset}
        >
          Clear filters &amp; reload
        </Button>
      </FiltersFooter>
    </>
  );
}
