/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Cell, Row } from "react-table";
import { Text } from "@nordcloud/gnui";
import { DetailData } from "~/components/Details/Detail";
import {
  convertBytes,
  convertCpuCores,
} from "~/components/Tables/Columns/KubernetesMetricsColumns";
import { isObject } from "~/tools";

export const NODE_DETAILS_COLUMNS = [
  {
    Header: "Node Details",
    accessor: "details",
    disableSortBy: true,
    width: "20%",
    Cell: ({ row }: Cell<DetailData>) => (
      <Text tag="div">{row.original.label}</Text>
    ),
  },
  {
    Header: "Volumes",
    accessor: "volumes",
    disableSortBy: true,
    width: "20%",
    Cell: ({ row }: Cell<DetailData>) => (
      <Text tag="span">{filterRows(row, "attachable-volumes")}</Text>
    ),
  },
  {
    Header: "CPU",
    accessor: "cpu",
    disableSortBy: true,
    width: "20%",
    Cell: ({ row }: Cell<DetailData>) => {
      const cpu = filterRows(row, "cpu");
      const converted = alignCores(cpu);
      return <Text tag="span">{convertCpuCores(converted)}</Text>;
    },
  },
  {
    Header: "Ephemeral Storage",
    accessor: "nodeStorage",
    disableSortBy: true,
    width: "20%",
    Cell: ({ row }: Cell<DetailData>) => {
      const storage = filterRows(row, "ephemeral-storage");
      const converted = convertToNumber(storage);
      return <Text tag="span">{convertBytes(converted)}</Text>;
    },
  },
  {
    Header: "Memory",
    accessor: "nodeMemory",
    disableSortBy: true,
    width: "20%",
    Cell: ({ row }: Cell<DetailData>) => {
      const memory = filterRows(row, "memory");
      const converted = convertToNumber(memory);
      return <Text tag="span">{convertBytes(converted)}</Text>;
    },
  },
  {
    Header: "Pods",
    accessor: "nodePods",
    disableSortBy: true,
    width: "20%",
    Cell: ({ row }: Cell<DetailData>) => (
      <Text tag="span">{filterRows(row, "pods")}</Text>
    ),
  },
];

function filterRows(row: Row<DetailData>, predicate: string) {
  const values = isObject(row.original.value)
    ? Object.values(row.original.value)
    : [];

  const result = values
    .filter(isObject)
    .filter(
      (value) =>
        "name" in value &&
        typeof value.name === "string" &&
        value.name.includes(predicate)
    )
    .map((value) =>
      "value" in value && typeof value.value === "string" ? value.value : ""
    )
    .shift();

  return result ?? "";
}

function convertToNumber(value: string): number {
  const regex = /[ik]/gi;
  const parsedValue = value.replaceAll(regex, "");

  return parseInt(parsedValue, 10);
}

function alignCores(value: string) {
  return /m/gi.test(value)
    ? parseInt(value.replace(/m/, ""), 10)
    : parseInt(value.replace(/m/, ""), 10) * 1000;
}
