/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import { ParentSize } from "@visx/responsive";
import { Else, If, Then, When } from "react-if";
import styled from "styled-components";
import { Sidebar, Spacer, Text, theme } from "@nordcloud/gnui";
import {
  ReservationsProvider,
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsAndCommitmentsLookbackPeriod,
  ReservationsRecommendationTerm,
  ReservationsRecommendationPaymentOption,
} from "~/generated/graphql";
import { LoaderWrap, ErrorWrap } from "~/components";
import {
  useGetRecommendationDetails,
  useGetSidebarDataItems,
  useGetCheckRecommendationTitle,
} from "../hooks";
import { UsageCoverageData, PaymentOptionsFilter } from "../types";
import { BreakevenChart } from "./BreakevenChart";
import { RecommendationBanner } from "./RecommendationBanner";
import { RecommendationData } from "./RecommendationData";
import { RecommendationsSettings } from "./RecommendationsSettings";

type Props = {
  provider: ReservationsProvider;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  sidebarData: UsageCoverageData;
  type: ReservationsAndCommitmentsCoverageOfferingType;
  timePeriod: ReservationsAndCommitmentsLookbackPeriod;
  paymentOptionsFilter: PaymentOptionsFilter[];
  paymentOption: ReservationsRecommendationPaymentOption;
  termOption: ReservationsRecommendationTerm;
};

export function RecommendationsSidebar({
  isSidebarOpen,
  toggleSidebar,
  sidebarData,
  timePeriod,
  paymentOptionsFilter,
  provider,
  paymentOption,
  termOption,
}: Props) {
  const serviceUsageType =
    sidebarData.serviceUsageType ??
    ReservationsAndCommitmentsCoverageServiceUsageType.Compute;
  const {
    details,
    recommendationDetails,
    sidebarLoading,
    sidebarError,
    hideEdit,
    breakevenTimeSeries,
    highestOnDemandCost,
    highestLabel,
    options: {
      currentPaymentOption,
      currentTermOption,
      updateTermOption,
      updatePaymentOption,
    },
    getSidebarDetails,
  } = useGetRecommendationDetails({
    resourceType: sidebarData.resource ?? "",
    region: sidebarData.region ?? "",
    platform: sidebarData.platform ?? "",
    provider,
    term: termOption,
    offeringType:
      ReservationsAndCommitmentsCoverageOfferingType.ReservedInstances,
    serviceUsageType,
    timePeriod,
    defaultPaymentOption: paymentOption,
  });

  const sidebarDataItems = useGetSidebarDataItems(
    recommendationDetails,
    provider
  );

  useEffect(() => {
    getSidebarDetails();
  }, [getSidebarDetails]);

  const resetSidebar = () => {
    toggleSidebar();
    hideEdit();
    updatePaymentOption(paymentOption);
    updateTermOption(termOption);
  };

  const resource = sidebarData?.resource;
  const hasCoverageResourceList = sidebarData?.hasCoverageResourceList;
  const { headerText, resourceLabel, isAws } = useGetCheckRecommendationTitle({
    hasCoverageResourceList,
    serviceUsageType,
    provider,
  });

  const hasNoSavings = Number(details?.monthlySavings) > 0;

  return (
    <Sidebar
      title={headerText}
      isOpen={isSidebarOpen}
      width="50rem"
      onClick={resetSidebar}
    >
      <ErrorWrap error={sidebarError}>
        <LoaderWrap inContent loading={sidebarLoading}>
          <Text marginBottom={theme.spacing.spacing04}>
            Recommendations for <Strong>{resource}</Strong> {resourceLabel}{" "}
            <When condition={isAws}>
              running <Strong>{sidebarData?.platform}</Strong>{" "}
            </When>{" "}
            in <Strong>{sidebarData?.region ?? ""}</Strong>
          </Text>
          <RecommendationsSettings
            isSidebar
            paymentOption={currentPaymentOption}
            term={currentTermOption}
            setTerm={updateTermOption}
            setPaymentOption={updatePaymentOption}
            paymentOptionsFilter={paymentOptionsFilter}
          />
          <If condition={hasNoSavings}>
            <Then>
              {sidebarDataItems.map(({ id, header, dataItems }, index) => (
                <RecommendationDataWrapper
                  key={id}
                  id={id}
                  header={header}
                  dataItems={dataItems}
                  index={index}
                />
              ))}
              <Spacer height={theme.spacing.spacing04} />
              <Text weight="medium" mb={theme.spacing.spacing01}>
                Break-even chart
              </Text>
              <Text
                size="sm"
                color={theme.color.text.text02}
                mb={theme.spacing.spacing02}
              >
                On-Demand Costs vs RI Costs
              </Text>
              <ParentSize>
                {(parent) => (
                  <BreakevenChart
                    termOption={termOption}
                    width={parent.width}
                    height={280}
                    loading={sidebarLoading}
                    data={breakevenTimeSeries}
                    highestOnDemandCost={highestOnDemandCost}
                    highestLabel={highestLabel}
                    startSavings={recommendationDetails.startSavings}
                    breakEvenPointXAxis={
                      recommendationDetails.breakEvenPointXAxis
                    }
                    breakEvenPointYAxis={
                      recommendationDetails.breakEvenPointYAxis
                    }
                  />
                )}
              </ParentSize>
            </Then>
            <Else>
              <RecommendationBanner
                heading="No Recommendation Details"
                subText="For this term and payment option the system couldn't find any recommendations"
                hasFilters={false}
              />
            </Else>
          </If>
        </LoaderWrap>
      </ErrorWrap>
    </Sidebar>
  );
}

const Strong = styled.span`
  font-weight: bold;
`;

type RecommendationDataWrapperProps = {
  id: string;
  header: string;
  dataItems: {
    label: string;
    value: string;
  }[];
  index: number;
};

function RecommendationDataWrapper({
  id,
  header,
  dataItems,
  index,
}: RecommendationDataWrapperProps) {
  const spacing =
    index === 0 ? theme.spacing.spacing01 : theme.spacing.spacing02;
  const recommendationDataProps = { id, dataItems };
  return (
    <>
      <Spacer height={theme.spacing.spacing04} />
      <Text weight="medium" mb={spacing} as="header">
        {header}
      </Text>
      <RecommendationData {...recommendationDataProps} />
    </>
  );
}
