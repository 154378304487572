/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { WorkflowEmailOptionsMessageType } from "~/generated/graphql";
import { isEmpty, isNotNil } from "~/tools";
import { WorkflowDataType } from "../constants";
import { WorkflowProps } from "../WorkflowPropsMapping";

export function getPredefinedSubjectAndMessage(
  messageType: WorkflowEmailOptionsMessageType
) {
  switch (messageType) {
    case WorkflowEmailOptionsMessageType.SavingSuggestions:
      return {
        subject: "Saving Suggestions per Category",
        message:
          "Saving suggestions bulked per category will be displayed here",
      };
    case WorkflowEmailOptionsMessageType.SavingSuggestionsIndividual:
      return {
        subject: "Individual Saving Suggestions",
        message: "-",
      };
    case WorkflowEmailOptionsMessageType.SavingSuggestionsCombined:
      return {
        subject: "Combined Saving Suggestions",
        message: "-",
      };
    case WorkflowEmailOptionsMessageType.NewUnallocatedResources:
      return {
        subject: "Unallocated resources",
        message: "-",
      };
    case WorkflowEmailOptionsMessageType.Kpi:
      return {
        subject: "KPIs",
        message: "-",
      };
    case WorkflowEmailOptionsMessageType.Budgets:
      return {
        subject: "Budget per applications, environments and organization units",
        message:
          "Budget per applications, environments and organization units will be displayed here",
      };
    case WorkflowEmailOptionsMessageType.AnomalyCosts:
      return {
        subject: "Anomaly Costs",
        message: "-",
      };
    case WorkflowEmailOptionsMessageType.CostsIndividual:
      return {
        subject: "Individual costs",
        message: "-",
      };
    case WorkflowEmailOptionsMessageType.CostsTopCosts:
      return {
        subject: "Top costs",
        message: "-",
      };
    case WorkflowEmailOptionsMessageType.CloudCredentials:
      return {
        subject: "Cloud Credentials Notification",
        message: "-",
      };
    default:
      return {
        subject: "-",
        message: "-",
      };
  }
}

export function isServiceNowEmpty(serviceNow?: { url: string }) {
  if (serviceNow == null) {
    return true;
  }

  return serviceNow.url === "";
}

export function isEmailEmpty(email?: { mailTo?: string }) {
  if (email == null) {
    return true;
  }

  return email.mailTo === "";
}

export function isSlackEmpty(slack?: { channel: string }) {
  if (slack == null) {
    return true;
  }

  return slack.channel === "";
}

export function isSchedulerEmpty(scheduler?: { startDate: string }) {
  if (scheduler == null) {
    return true;
  }

  return scheduler.startDate === "";
}

export function isAppsAndEnvsEmpty(appsAndEnvsOptions?: {
  applications?: string[];
  environments?: string[];
}) {
  const { applications, environments } = appsAndEnvsOptions ?? {};

  return isEmpty(applications ?? []) && isEmpty(environments ?? []);
}

export function getDefaultEmailMessageRadioValue(
  selectDataComponent?: WorkflowDataType,
  workflow?: WorkflowProps
) {
  if (isNotNil(workflow)) {
    const emailIntegration = workflow.externalIntegrations.find(
      (externalIntegration) => isNotNil(externalIntegration.emailOptions)
    );

    if (emailIntegration?.emailOptions?.messageType !== undefined) {
      return emailIntegration.emailOptions.messageType;
    }
  }

  switch (selectDataComponent) {
    case WorkflowDataType.SAVING_SUGGESTIONS:
      return WorkflowEmailOptionsMessageType.SavingSuggestionsIndividual;
    case WorkflowDataType.BUDGETS:
      return WorkflowEmailOptionsMessageType.Budgets;
    case WorkflowDataType.UNALLOCATED_RESOURCES:
      return WorkflowEmailOptionsMessageType.NewUnallocatedResources;
    case WorkflowDataType.CLOUD_CREDENTIALS:
      return WorkflowEmailOptionsMessageType.CloudCredentials;
    case WorkflowDataType.ANOMALY_COSTS:
      return WorkflowEmailOptionsMessageType.AnomalyCosts;
    case WorkflowDataType.KPI:
      return WorkflowEmailOptionsMessageType.Kpi;
    case WorkflowDataType.COSTS:
      return WorkflowEmailOptionsMessageType.CostsIndividual;
    default:
      return WorkflowEmailOptionsMessageType.Undefined;
  }
}
