import styled from "styled-components";
import { Table, theme } from "@nordcloud/gnui";
import { isEmpty } from "~/tools";

export const Td = styled(Table.td)<{ background?: string }>`
  vertical-align: middle;
  text-align: right;
  border: ${theme.borders.grey};
  background: ${({ background }) =>
    isEmpty(background ?? "") ? theme.color.background.ui01 : background};

  &:first-of-type {
    text-align: left;
    position: sticky;
    z-index: ${theme.zindex.default};
    border-left: none;
    left: 0;
  }

  &:last-of-type {
    position: sticky;
    z-index: ${theme.zindex.default};
    border-right: none;
    right: 0;
  }
`;

export const StyledTable = styled(Table)`
  border-collapse: separate;

  @media screen and (min-width: ${theme.breakpoints.lg}px) {
    table-layout: fixed;
  }
  tr:hover td {
    background-color: ${theme.color.background.ui03};
  }
`;

export const Th = styled(Table.th)<{
  wide?: boolean;
  firstColumnWidth?: string;
}>`
  width: ${({ wide }) => (wide ? "13.5rem" : "7.5rem")};
  font-size: ${theme.fontSizes.sm};
  letter-spacing: normal;
  color: ${theme.colors.darks[4]};
  background: ${theme.color.background.ui03};
  font-weight: ${theme.fontWeights.medium};
  cursor: pointer;
  border-bottom: ${theme.borders.grey};
  border-top: ${theme.borders.grey};
  border-right: ${theme.borders.grey};
  text-align: right;

  &:first-of-type {
    border-left: none;
    width: ${({ firstColumnWidth }) =>
      firstColumnWidth ? firstColumnWidth : "15rem"};
    text-align: left;
    position: sticky;
    z-index: ${theme.zindex.default};
    left: 0;
  }

  &:last-of-type {
    border-left: ${theme.borders.grey};
    border-right: none;
    width: 15rem;
    position: sticky;
    z-index: ${theme.zindex.default};
    right: 0;
  }
`;
