/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ChangeEvent, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import debounce from "lodash.debounce";
import {
  ApplicationsSearchWithBudgetsQuery,
  useAllAppAndEnvIdsQuery,
  useApplicationsSearchWithBudgetsQuery,
} from "~/generated/graphql";
import { Node, TreeEvent } from "~/components";
import { eventToActions } from "~/components/TreeSelector/tools";
import { dateFormat } from "~/constants";
import {
  setDifference,
  setIntersection,
  inflect,
  isNotNil,
  setAdjust,
  joinSentenceSubjects,
  isEmpty,
  isNotEmpty,
  setUnion,
} from "~/tools";

export type AppsEnvsFieldState = {
  apps: string[];
  envs: string[];
};

const PAGE_SIZE = 20;
const DEBOUNCE_TIMEOUT_MS = 300;

const emptyState = {
  apps: [],
  envs: [],
};

function getDescriptiveSentence(appsCount: number, envsCount: number) {
  const joinedSubjects = joinSentenceSubjects([
    { subject: "Application", count: appsCount },
    { subject: "Environment", count: envsCount },
  ]);

  return isEmpty(joinedSubjects) ? "" : `${joinedSubjects} selected`;
}

function getButtonText(
  appsCount: number,
  envsCount: number,
  disableExpanding?: boolean
) {
  if (appsCount === 0 && envsCount === 0) {
    return disableExpanding
      ? "Select Applications"
      : "Select Applications and Environments";
  }

  return getDescriptiveSentence(appsCount, envsCount);
}

type CompressPageProps = {
  queryResults: ApplicationsSearchWithBudgetsQuery["applicationsPaginated"];
  apps: string[];
  envs: string[];
  skip?: boolean;
};

function compressPage({ queryResults, apps, envs, skip }: CompressPageProps) {
  if (skip) {
    return envs;
  }

  const envsToRemove = findEnvironments(queryResults, apps);

  return setDifference(envs, envsToRemove);
}

function uncompressPage({ queryResults, apps, envs, skip }: CompressPageProps) {
  if (skip) {
    return envs;
  }

  const envsToAdd = findEnvironments(queryResults, apps);

  return setUnion(envs, envsToAdd);
}

function findEnvironments(
  queryResults: ApplicationsSearchWithBudgetsQuery["applicationsPaginated"],
  apps: string[]
) {
  const appsInQuery = queryResults?.results ?? [];

  return appsInQuery.reduce<string[]>((acc, curr) => {
    if (!apps.includes(curr.id)) {
      return acc;
    }
    const allEnvsInApp =
      curr.environments?.filter(isNotNil).map(({ id }) => id) ?? [];
    return [...acc, ...allEnvsInApp];
  }, []);
}

type Props = {
  outerState?: AppsEnvsFieldState;
  fetchAllIds?: boolean;
  nodeBehavior?: "independent" | "linkedToParent";
  // if true and all envs of an app are selected, only inlude app id in output state
  compressState?: boolean;
  onClose?: () => void;
  displayTags?: boolean;
  disableExpanding?: boolean;
};

export function useAppsEnvsField({
  outerState = emptyState,
  fetchAllIds = true,
  nodeBehavior = "independent",
  compressState = false,
  onClose,
  displayTags = true,
  disableExpanding,
}: Props) {
  const [page, setPage] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [apps, setApps] = useState(outerState.apps);
  const [envs, setEnvs] = useState(outerState.envs);

  const {
    data: pageData,
    loading: loadingPage,
    error: pageError,
  } = useApplicationsSearchWithBudgetsQuery({
    variables: {
      limit: PAGE_SIZE,
      page,
      applicationName: searchString,
      year: dayjs().format(dateFormat.year),
    },
    onCompleted: ({ applicationsPaginated }) => {
      if (compressState) {
        setEnvs(
          uncompressPage({
            queryResults: applicationsPaginated,
            apps,
            envs,
          })
        );
      }
    },
  });
  const queryResults = pageData?.applicationsPaginated?.results ?? [];
  const count = pageData?.applicationsPaginated?.count ?? 0;

  useEffect(() => {
    setApps(outerState.apps);
    setEnvs(
      uncompressPage({
        queryResults: pageData?.applicationsPaginated,
        apps: outerState.apps,
        envs: outerState.envs,
        skip: !compressState,
      })
    );
  }, [setApps, setEnvs, outerState.apps, outerState.envs]);

  const compressedEnvs = compressPage({
    queryResults: pageData?.applicationsPaginated,
    skip: !compressState,
    apps,
    envs,
  });

  const handleSetPage = (pageIndex: number) => {
    if (compressState) {
      setEnvs(compressedEnvs);
    }

    return setPage(pageIndex);
  };

  const { nodes, appIds } = useMemo(() => {
    const emptyObject = { nodes: [], appIds: [] };

    const appsSet = new Set(apps);
    const envsSet = new Set(envs);

    return (
      queryResults.reduce<{
        nodes: Node[];
        appIds: string[];
      }>((acc, curr) => {
        const filteredEnvs = curr.environments?.filter(isNotNil) ?? [];
        const uncheckedEnvsCount = filteredEnvs.reduce(
          (totalEnvs, { id }) => (!envsSet.has(id) ? totalEnvs + 1 : totalEnvs),
          0
        );

        const appEnvs = filteredEnvs.map((env) => {
          const isChecked = envsSet.has(env.id);
          const hasNoBudget = parseFloat(env.budgetYearly.yearlySum) === 0;
          const isLast =
            (isChecked && uncheckedEnvsCount === 0) ||
            (!isChecked && uncheckedEnvsCount === 1);

          return {
            id: env.id,
            groupId: "",
            parentId: curr.id,
            checked: isChecked,
            children: [],
            label: env.name,
            path: curr.id,
            tags: hasNoBudget && displayTags ? ["No Budget Set"] : [],
            isLast,
          };
        });

        const isSingleChecked = appsSet.has(curr.id);
        const hasNoBudget = curr.budgetYearly.yearlySum === "0.00";

        const app = {
          id: curr.id,
          parentId: null,
          checked: isSingleChecked,
          children: appEnvs,
          label: curr.name,
          subtext: `${appEnvs.length} ${inflect("Environment")(
            appEnvs.length
          )}`,
          path: "",
          isOpen: appEnvs.some((env) => env.checked),
          tags: hasNoBudget && displayTags ? ["No Budget Set"] : [],
        };

        return { nodes: [...acc.nodes, app], appIds: [...acc.appIds, curr.id] };
      }, emptyObject) ?? emptyObject
    );
  }, [queryResults, apps, envs, outerState]);

  const handleCheck = (event: TreeEvent) => {
    eventToActions(event, nodeBehavior).forEach((action) => {
      const { type, ids } = action;
      const setOperation = type === "add" ? "union" : "difference";

      const newApps = setIntersection(ids, appIds);
      const newEnvs = setDifference(ids, appIds);

      setApps((prev) => setAdjust(prev, newApps, setOperation));
      setEnvs((prev) => setAdjust(prev, newEnvs, setOperation));
    });
  };

  const buttonText = getButtonText(
    apps.length,
    compressedEnvs.length,
    disableExpanding
  );
  const inputText = getDescriptiveSentence(
    outerState.apps.length,
    outerState.envs.length
  );

  const debouncedSearch = debounce((value: string) => {
    if (compressState) {
      setEnvs(compressedEnvs);
    }

    setPage(0);
    setSearchString(value);
  }, DEBOUNCE_TIMEOUT_MS);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    debouncedSearch(value);
  };

  const {
    data: allIds,
    error: allIdsError,
    loading: loadingAllIds,
  } = useAllAppAndEnvIdsQuery({
    variables: {
      applicationName: searchString,
    },
    skip: !fetchAllIds,
  });

  const adjustAppsAndEnvs = (operation: "difference" | "union") => {
    if (operation === "difference" && isEmpty(searchString)) {
      setApps([]);
      setEnvs([]);

      return;
    }

    // We use type assertion here to avoid filtering 1000s of items by `isNotNil`
    const allAppIds = (allIds?.selectAllAppAndEnvIds?.applicationNids ??
      []) as string[];
    const allEnvIds = (allIds?.selectAllAppAndEnvIds?.environmentNids ??
      []) as string[];

    const newEnvsIds = compressState
      ? uncompressPage({
          queryResults: pageData?.applicationsPaginated,
          apps: appIds,
          envs: setUnion(
            envs,
            findEnvironments(pageData?.applicationsPaginated, apps)
          ),
        })
      : allEnvIds;

    if (isNotEmpty(searchString)) {
      setApps((prev) => setAdjust(prev, allAppIds, operation));
      setEnvs((prev) => setAdjust(prev, newEnvsIds, operation));
    } else {
      setApps(allAppIds);
      setEnvs(newEnvsIds);
    }
  };

  const handleDeselectAll = () => adjustAppsAndEnvs("difference");
  const handleSelectAll = () => adjustAppsAndEnvs("union");

  const resetState = () => {
    debouncedSearch.cancel();
    setPage(0);
    setSearchString("");
    setApps(outerState.apps);
    setEnvs(outerState.envs);
  };

  const handleClose = () => {
    resetState();

    if (onClose) {
      onClose();
    }
  };

  return {
    nodes,
    loading: loadingPage || loadingAllIds,
    error: pageError ?? allIdsError,
    count,
    page,
    pageSize: PAGE_SIZE,
    setPage: handleSetPage,
    handleSearchChange,
    handleCheck,
    innerState: { apps, envs: compressedEnvs },
    buttonText,
    inputText,
    handleSelectAll,
    handleDeselectAll,
    handleClose,
  };
}
