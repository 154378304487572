/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer, Message, SVGIcon, Text } from "@nordcloud/gnui";
import { useCustomers } from "~/services/customers";

export function NoCustomerAccess() {
  const { hideNoCustomerMessage } = useCustomers();

  return (
    <Message status="notification">
      <FlexContainer justifyContent="space-between" css={{ width: "100%" }}>
        <Text mb={0}>
          The requested customer either doesn&apos;t exist or access is denied.
        </Text>
        <SVGIcon
          name="close"
          size="sm"
          css={{ cursor: "pointer" }}
          onClick={hideNoCustomerMessage}
        />
      </FlexContainer>
    </Message>
  );
}
