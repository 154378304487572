/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch } from "react";
import { useRemoveThresholdMutation } from "~/generated/graphql";
import { showError, showSuccess } from "~/services/toast";
import {
  generateActionSuccessText,
  isNotNil,
  removeFromApolloCache,
  toInputError,
} from "~/tools";
import {
  ACTION,
  Action,
  ThresholdActionsState,
} from "./thresholdActionsReducer";

export function useThresholdRemove(
  dispatch: Dispatch<Action>,
  thresholdActionsState: ThresholdActionsState,
  closeModal: () => void
) {
  const [removeThresholdMutation, { client }] = useRemoveThresholdMutation();

  const onRemoveHandler = (id: string, name: string) => {
    dispatch({ type: ACTION.REMOVE, payload: { id, name } });
  };

  const onDelete = async () => {
    try {
      if (isNotNil(thresholdActionsState.tempThresholdData.id)) {
        const response = await removeThresholdMutation({
          variables: {
            id: thresholdActionsState.tempThresholdData.id,
          },
        });

        if (response.data?.removeThreshold.id) {
          removeFromApolloCache(client.cache, {
            item: `Threshold:${response.data?.removeThreshold.id}`,
          });
          showSuccess(generateActionSuccessText("Threshold")()("deleted")());
        }
      }
    } catch (thresholdRemovalError) {
      showError(toInputError(thresholdRemovalError).message, {
        autoClose: false,
      });
    }
    closeModal();
  };

  return { onRemoveHandler, onDelete };
}
