/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { Maybe } from "~/generated/graphql";
import {
  CostAnalysisFields,
  Granularity,
  TableData,
  mapTimePointValue,
} from "~/components";
import { isNotNil } from "~/tools";
import { ChartData, ChartJobData, GroupTimePoint } from "../types";
import { generateDataWithSavingPlanCommitments } from "./helpers";

export function useEstateRecordsAccumulatedCost(
  granularity: Granularity,
  data?: ChartJobData
) {
  const chartData = useMemo(
    () =>
      granularity === Granularity.DAYS
        ? data?.chartJob?.data
        : data?.chartMonthlyJob?.data,
    [data, granularity]
  );

  const accumulatedCostChartData = useMemo(
    () =>
      generateDataWithSavingPlanCommitments(mapAccumulatedCostData(chartData)),
    [chartData]
  );

  const accumulatedCostTimePoints = useMemo(
    () => (chartData?.timePoints ?? []).filter(isNotNil).map(mapTimePointValue),
    [chartData?.timePoints]
  );

  const accumulatedCostTotal = Number(chartData?.total ?? 0);

  const accumulatedCostTableData: TableData[] = useMemo(
    () => [
      {
        field: CostAnalysisFields.MONTHLY_COST,
        total: accumulatedCostTotal,
        ...accumulatedCostTimePoints?.reduce(
          (acc, { date, value }) => ({ ...acc, [date]: Number(value ?? 0) }),
          {}
        ),
      },
    ],
    [accumulatedCostTotal, accumulatedCostTimePoints]
  );

  return {
    accumulatedCostChartData,
    accumulatedCostTableData,
    accumulatedCostTotal: accumulatedCostTotal.toString(),
  };
}

function mapAccumulatedCostData(data?: ChartData | null) {
  if (isNotNil(data)) {
    return (data?.timePoints ?? [])?.filter(isNotNil).map((timePoint) => {
      return {
        ...timePoint,
        ...mapGroups(timePoint.groups),
      };
    });
  }

  return [];
}

function mapGroups(groupTimePoints: Maybe<GroupTimePoint[]> | undefined) {
  return {
    groups: groupTimePoints?.map((group) => {
      return {
        id: group?.id ?? "",
        name:
          group?.name === "correction"
            ? CostAnalysisFields.CORRECTION_COST
            : CostAnalysisFields.DAILY_COST,
        value: group?.value ?? "",
      };
    }),
  };
}
