/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Cell, Row } from "react-table";
import { ExtendedTooltip, SVGIcon } from "@nordcloud/gnui";

import { EstateRecordRow } from "~/models/estateRecordRow";
import { getLastItem, isNotEmpty, MixedValue } from "~/tools";
import { PodStatus } from "~/views/estate/components/types";

export const STATUS_ICON_COLUMN = {
  accessor: "status",
  disableSortBy: true,
  width: "3%",
  Cell: ({ row }: Cell<EstateRecordRow>) => {
    const statusDetails = checkStatus(row);
    return (
      <ExtendedTooltip
        caption={statusDetails.status}
        status={statusDetails.tooltipStatus}
      >
        <SVGIcon name={statusDetails.icon} css={{ verticalAlign: "sub" }} />
      </ExtendedTooltip>
    );
  },
};

function checkStatus(row: Row<EstateRecordRow>): PodStatus {
  const statusArray =
    row.original.metadataAndTags?.metadata?.filter((val) =>
      val.key.includes("status")
    ) ?? [];

  const status = isNotEmpty(statusArray)
    ? JSON.parse(getLastItem(statusArray).value)
    : "Created";

  return getKubernetesPodStatus(status);
}

export function getKubernetesPodStatus(status: MixedValue): PodStatus {
  switch (status) {
    case "Running":
      return KUBERNETES_POD_STATUS.running;
    case "Created":
      return KUBERNETES_POD_STATUS.created;
    case "Deleted":
      return KUBERNETES_POD_STATUS.deleted;
    case "Pending":
      return KUBERNETES_POD_STATUS.pending;
    case "Succeeded":
      return KUBERNETES_POD_STATUS.succeeded;
    case "Failed":
      return KUBERNETES_POD_STATUS.failed;
    default:
      return KUBERNETES_POD_STATUS.unknown;
  }
}

const KUBERNETES_POD_STATUS = {
  running: {
    status: "Running",
    icon: "statusRunning",
    tooltipStatus: "notification",
  },
  created: {
    status: "Created",
    icon: "statusSuccess",
    tooltipStatus: "success",
  },
  deleted: { status: "Deleted", icon: "statusError", tooltipStatus: "danger" },
  pending: {
    status: "Pending",
    icon: "statusOnHold",
    tooltipStatus: "warning",
  },
  succeeded: {
    status: "Succeeded",
    icon: "statusSuccess",
    tooltipStatus: "success",
  },
  failed: { status: "Failed", icon: "statusWarning", tooltipStatus: "warning" },
  unknown: {
    status: "Unknown",
    icon: "statusWarning",
    tooltipStatus: "warning",
  },
} as const;
