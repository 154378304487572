/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as yup from "yup";

export const getWorkflowDetailsEditSchema = () =>
  yup.object().shape({
    name: yup
      .string()
      .min(1, "Workflow Name can not be empty.")
      .max(50, "Workflow Name limit is 50 characters."),
    description: yup
      .string()
      .max(255, "Workflow Description limit is 255 characters."),
  });
