/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { useGetBusinessContextsQuery } from "~/generated/graphql";
import { Recursive } from "~/components";
import { dateFormat } from "~/constants";
import {
  inflect,
  isNotEmpty,
  getFirstItem,
  noop,
  setUnion,
  isEmpty,
} from "~/tools";

type Props = {
  initialOrgUnits?: string[];
  behaviour: "biDirectional" | "parentToChild";
  disabled: boolean;
};

export function useOrgUnitsFields({
  initialOrgUnits = [],
  behaviour,
  disabled,
}: Props) {
  const [selectedList, setSelectedList] = useState<string[]>([]);
  const [expandedList, setExpandedList] = useState<string[]>([]);
  const [groupSelected, setGroupSelected] = useState("");

  const { data, loading, error } = useGetBusinessContextsQuery({
    variables: {
      year: dayjs().format(dateFormat.year),
    },
  });

  const businessContexts = useMemo(
    () => data?.businessContexts?.businessContexts ?? [],
    [data?.businessContexts?.businessContexts]
  );

  const orgUnitsByGroups = useMemo(
    () =>
      businessContexts
        .filter((businessContext) =>
          isNotEmpty(businessContext?.orgUnits?.orgUnits ?? [])
        )
        .map((businessContext) => ({
          orgUnits: businessContext.orgUnits?.orgUnits,
          id: businessContext.id,
          name: businessContext.name,
          color: businessContext.color,
          isPrimary: businessContext.isPrimary,
        })),
    [businessContexts]
  );

  const rawSelectedIds = useMemo(() => {
    return selectedList
      .map((id) => id.split("->"))
      .map((splittedPath) => splittedPath.at(-1) as string);
  }, [selectedList]);

  const currentGroup = useMemo(
    () => orgUnitsByGroups.find(({ id }) => id === groupSelected),
    [groupSelected, orgUnitsByGroups]
  );

  const currentTree = useMemo(
    () =>
      arrayToTree({
        flatTree:
          currentGroup?.orgUnits?.map((orgUnit) => ({
            id: orgUnit.nid,
            parentId: orgUnit.parentNid ?? "",
            uid: orgUnit.nid ?? "",
            label: orgUnit.name,
            behaviour,
            budget: {
              id: orgUnit.budgetYearly.id,
              yearlySum: orgUnit.budgetYearly.yearlySum,
            },
          })) ?? [],
        parentId: "",
        parentUid: "",
      }),
    [currentGroup, behaviour]
  );

  const allTreesUids = useMemo(
    () =>
      orgUnitsByGroups.map((group) =>
        getTreeUids(
          arrayToTree({
            flatTree:
              group.orgUnits?.map((orgUnit) => ({
                id: orgUnit.nid,
                parentId: orgUnit.parentNid ?? "",
                uid: orgUnit.nid ?? "",
                label: orgUnit.name,
                behaviour,
                budget: {
                  id: orgUnit.budgetYearly.id,
                  yearlySum: orgUnit.budgetYearly.yearlySum,
                },
              })) ?? [],
            parentId: "",
            parentUid: "",
          })
        )
      ),
    [behaviour, orgUnitsByGroups]
  );

  const selectedGroupsMembers = useMemo(
    () =>
      orgUnitsByGroups
        .map(({ orgUnits, id }) => ({
          selectedMembersCount: orgUnits?.filter((item) =>
            rawSelectedIds.includes(item.id)
          ).length,
          id,
        }))
        .filter((item) => item.selectedMembersCount),
    [orgUnitsByGroups, rawSelectedIds]
  );

  const handleSelect = () => {
    return rawSelectedIds;
  };

  const handleSelectAll = () => {
    setSelectedList((currentIds) =>
      setUnion(getTreeUids(currentTree), currentIds)
    );
  };

  const handleDeselectAll = () => {
    setSelectedList([]);
  };

  const buttonText = useMemo(
    () => getButtonLabelText(selectedList.length),
    [selectedList]
  );

  const inputText = useMemo(
    () => getInputValue(initialOrgUnits.length),
    [initialOrgUnits]
  );

  const reset = () => {
    setSelectedList(initialOrgUnits);
  };

  useEffect(() => {
    setGroupSelected(getFirstItem(orgUnitsByGroups)?.id ?? "");
  }, [orgUnitsByGroups]);

  useEffect(() => {
    setExpandedList(getTreeUids(currentTree));
  }, [currentTree]);

  useEffect(() => {
    const selectedRelationIds = getTreeUids(currentTree).filter((id) =>
      initialOrgUnits.includes(id.split("->").at(-1) ?? "")
    );

    setSelectedList((currentIds) => setUnion(selectedRelationIds, currentIds));
  }, [initialOrgUnits, currentTree]);

  useEffect(() => {
    if (isEmpty(initialOrgUnits)) {
      setSelectedList([]);
    } else {
      const allSelectedRelations = allTreesUids.flatMap((groupIds) =>
        groupIds.filter((id) =>
          initialOrgUnits.includes(id.split("->").at(-1) ?? "")
        )
      );

      setSelectedList(allSelectedRelations);
    }
  }, [initialOrgUnits]);

  return {
    apiState: {
      loading,
      error,
    },
    text: {
      buttonText,
      inputText,
    },
    tree: {
      behaviour,
      handlers: {
        handleSelect,
        reset,
        handleSelectAll,
        handleDeselectAll,
        setSelectedList: disabled ? noop : setSelectedList,
        setExpandedList,
      },
      state: {
        currentTree,
        selectedList,
        expandedList,
        rawSelectedIds,
      },
    },
    groups: {
      setGroupSelected,
      groupSelected,
      selectedGroupsMembers,
    },
    orgUnitsByGroups,
  };
}

function getInputValue(orgUnitsCount: number) {
  if (orgUnitsCount === 0) {
    return "";
  }

  return `${orgUnitsCount} ${inflect("Organizational Unit")(
    orgUnitsCount
  )} Selected`;
}

function getButtonLabelText(orgUnitsCount: number) {
  if (orgUnitsCount === 0) {
    return "Select Organizational Units";
  }

  return `${orgUnitsCount} ${inflect("Organizational Unit")(
    orgUnitsCount
  )} selected`;
}

// Funtion that creates a tree of flat array items by using parentId in the item
// and setting relations as uids
// from: [{ parentId: '', name: '1', id: '1' }, { parentId: '1', name: '2', id: '2' }]
// to: [{ name: '1', id: '1', children: [{ name: '2', id: '2', uid: '1->2' }] }]
function arrayToTree({
  flatTree,
  parentId,
  parentUid,
}: {
  flatTree: Omit<Recursive, "children">[];
  parentId: string;
  parentUid: string;
}): Recursive[] {
  const children = flatTree.filter((item) => item.parentId === parentId);

  return children.map(({ label, id, budget, behaviour }) => ({
    id,
    uid: parentUid ? `${parentUid}->${id}` : id,
    parentId: id,
    label,
    budget,
    behaviour,
    children: arrayToTree({
      flatTree,
      parentId: id,
      parentUid: parentUid ? `${parentUid}->${id}` : id,
    }),
  }));
}

function getTreeUids(currentTree: Recursive[]): string[] {
  const uids: string[] = [];

  const recursive = (tree: Recursive) => {
    uids.push(tree.uid);

    tree.children.forEach((item) => {
      uids.push(item.uid);
      item.children.forEach(recursive);
    });
  };

  currentTree.forEach((tree) => recursive(tree));

  return uids;
}
