/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useDrop } from "react-dnd";
import { When } from "react-if";
import styled, { css } from "styled-components";
import {
  Box,
  ExtendedTooltip,
  SVGIcon,
  Text,
  theme,
  FlexContainer,
} from "@nordcloud/gnui";
import { PathName } from "~/tools";
import {
  ComponentConfig,
  ComponentGroup,
  ComponentType,
} from "../../constants";
import { WorkflowFormMode } from "../../types";
import { ComponentIconWrapper } from "../styles";
import { ComponentActions } from "./ComponentActions";

type Props = {
  formMode: WorkflowFormMode;
  title: string;
  color?: string;
  componentIcon?: PathName;
  tooltipCaption?: React.ReactNode;
  isLastItem?: boolean;
  timelineIcon: React.ComponentProps<typeof SVGIcon>["name"];
  timelineLabel?: string;
  children?: React.ReactNode;
  componentGroup: ComponentGroup;
  displayPlaceholder: boolean;
  onSettingsClick?: () => void;
  onDrop?: (type: ComponentType) => void;
  onRemoveClick?: () => void;
  hasError?: boolean;
  settingsDisabled?: boolean;
};

export function ComponentHolder({
  title,
  color,
  componentIcon,
  tooltipCaption,
  isLastItem = false,
  timelineIcon,
  timelineLabel,
  children,
  onSettingsClick,
  settingsDisabled = false,
  componentGroup,
  displayPlaceholder,
  onDrop,
  onRemoveClick,
  hasError,
  formMode,
}: Props) {
  const [{ canDrop }, drop] = useDrop(() => ({
    accept: componentGroup,
    drop: ({ type }: ComponentConfig) => {
      if (formMode === "read" || !onDrop) {
        return;
      }

      onDrop(type);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const marginOverride = isLastItem ? 0 : undefined;
  const boxBackground = canDrop ? undefined : theme.color.background.ui03;
  const boxBorder = canDrop ? "notification" : "transparent";
  const placeholderTextColor = hasError ? theme.colors.danger : "grey";
  const handleRemoveClick = formMode === "read" ? undefined : onRemoveClick;

  return (
    <Box
      mb={theme.spacing.spacing04}
      backgroundColor={boxBackground}
      border={boxBorder}
      margin={marginOverride}
      p={theme.spacing.spacing02}
    >
      <FlexContainer>
        <div
          css={{
            flex: "0 0 20%",
            minWidth: "7rem",
            maxWidth: "16.5rem",
            paddingRight: theme.spacing.spacing03,
          }}
        >
          <FlexContainer>
            <BubbleIconWrapper color={color}>
              <SVGIcon
                css={{ display: "block" }}
                name={timelineIcon}
                size="md"
                color="white"
              />
            </BubbleIconWrapper>
            <Text tag="span" pl={theme.spacing.spacing02}>
              {timelineLabel}
            </Text>
          </FlexContainer>
        </div>
        <div css={{ flex: "1" }}>
          <When condition={!displayPlaceholder}>
            <ComponentWrapper
              ref={drop}
              isLastItem={isLastItem}
              color={color}
              css={{ width: "auto" }}
            >
              <FlexContainer
                justifyContent="center"
                ml={theme.spacing.spacing02}
                mr={theme.spacing.spacing01}
              >
                <ComponentIconWrapper color={color ?? "inherit"}>
                  <SVGIcon
                    css={{ display: "block" }}
                    name={componentIcon ?? "default"}
                    color="white"
                    size="smd"
                  />
                </ComponentIconWrapper>
              </FlexContainer>
              <ContentWrapper>
                <Text display="inline" mr={theme.spacing.spacing02}>
                  {title}
                </Text>
                <When condition={tooltipCaption != null}>
                  <ExtendedTooltip caption={tooltipCaption}>
                    <SVGIcon name="info" size="sm" />
                  </ExtendedTooltip>
                </When>
                {children}
              </ContentWrapper>
              <ComponentActions
                title={title}
                isReadonly={formMode === "read"}
                settingsDisabled={settingsDisabled}
                onRemoveClick={handleRemoveClick}
                onSettingsClick={onSettingsClick}
              />
            </ComponentWrapper>
          </When>
          <When condition={displayPlaceholder}>
            <ComponentWrapper
              ref={drop}
              isPlaceholder
              isLastItem={isLastItem}
              color={color}
              css={{ minHeight: "3rem", width: "auto" }}
              hasError={hasError}
            >
              <FlexContainer justifyContent="center" css={{ width: "100%" }}>
                <Text size="sm" color={placeholderTextColor}>
                  Please drag and drop {timelineLabel} components here
                </Text>
              </FlexContainer>
            </ComponentWrapper>
          </When>
        </div>
      </FlexContainer>
    </Box>
  );
}

type BubbleIconProps = {
  color?: string;
};

const BubbleIconWrapper = styled.div<BubbleIconProps>`
  border-radius: 100%;
  background: ${({ color }) => color ?? "transparent"};
  padding: ${theme.spacing.spacing02};
  width: ${theme.iconSize.md};
  height: ${theme.iconSize.md};
  margin-left: ${theme.spacing.spacing02};
`;

type ComponentWrapperProps = {
  color?: string;
  isLastItem: boolean;
  isPlaceholder?: boolean;
  hasError?: boolean;
};

const getComponentTagBorders = (color?: string) => css`
  border: ${theme.borders.disabled};
  border-left: ${theme.spacing.spacing02} solid
    ${color ?? theme.color.support.lightGreen};
`;

const getPlaceholderBorders = (hasError?: boolean) => css`
  border: 1px dashed ${hasError ? theme.colors.danger : theme.colors.lights[3]};
`;

const ComponentWrapper = styled.div<ComponentWrapperProps>`
  ${({ isPlaceholder, color, hasError }) =>
    isPlaceholder
      ? getPlaceholderBorders(hasError)
      : getComponentTagBorders(color)}
  background: ${({ isPlaceholder }) =>
    !isPlaceholder && theme.color.background.ui01};
  border-radius: ${theme.radius.md};
  display: flex;
  padding: ${theme.spacing.spacing02} ${theme.spacing.spacing02}
    ${theme.spacing.spacing02} ${theme.spacing.spacing01};
  position: relative;
  width: 100%;
  min-height: ${theme.spacing.spacing06};
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  padding: 0 ${theme.spacing.spacing08} 0 ${theme.spacing.spacing02};
`;
