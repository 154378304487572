/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloCache } from "@apollo/client";
import {
  EnvironmentBudgetDocument,
  EnvironmentBudgetQuery,
  EnvironmentBudgetQueryVariables,
  SetEnvironmentYearlyBudgetMutation,
  useSetEnvironmentYearlyBudgetMutation,
} from "~/generated/graphql";
import { newMonthValues } from "~/components/Budgets";
import { showSuccess } from "~/services/toast";
import {
  generateActionSuccessText,
  handleError,
  Maybe,
  toInputError,
} from "~/tools";

export function useCreateBudget() {
  const [
    createEnvironmentBudgetMutation,
    {
      client: { cache },
      ...state
    },
  ] = useSetEnvironmentYearlyBudgetMutation();

  const create = async ({ nid, year, budgetValue, currency }: CreateProps) => {
    try {
      const { data } = await createEnvironmentBudgetMutation({
        variables: {
          nid,
          budget: {
            budgetByMonth: newMonthValues(budgetValue),
            year,
            currency,
          },
        },
      });

      handleCacheUpdate(data, cache, {
        id: nid,
        year,
        previousYear: (parseInt(year, 10) - 1).toString(),
      });

      showSuccess(generateActionSuccessText("Budget")()("created")());
    } catch (error) {
      handleError(toInputError(error));
    }
  };

  return [create, state] as const;
}

function handleCacheUpdate(
  data: Maybe<SetEnvironmentYearlyBudgetMutation>,
  cache: ApolloCache<unknown>,
  variables: EnvironmentBudgetQueryVariables
) {
  cache.updateQuery<EnvironmentBudgetQuery, EnvironmentBudgetQueryVariables>(
    { query: EnvironmentBudgetDocument, variables },
    (envData): EnvironmentBudgetQuery | null => {
      if (
        envData?.environment == null ||
        data?.setEnvironmentYearlyBudget == null
      ) {
        return null;
      }

      return {
        ...envData,
        environment: {
          ...envData.environment,
          budgetYearly: {
            ...data.setEnvironmentYearlyBudget,
          },
        },
      };
    }
  );
}

type CreateProps = {
  nid: string;
  year: string;
  budgetValue: number;
  currency: string;
};
