/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Link } from "react-router-dom";
import styled from "styled-components";
import { Message, Spacer, Table, theme } from "@nordcloud/gnui";
import { CollapsibleBox } from "~/components";
import { isEmpty, isNotNil } from "~/tools";
import { getUrl } from "../../../helpers";
import { Item } from "../../components";
import { Status } from "../components";

type Props = {
  publicIPv4: string;
  publicIPv4DNS: string;
  privateIPv4: string;
  privateIPv4DNS: string;
  ipv6Addresses: string[];
  networkInterfaces: NetworkInterface[];
};

export function Ec2Networking({
  publicIPv4,
  publicIPv4DNS,
  privateIPv4,
  privateIPv4DNS,
  ipv6Addresses,
  networkInterfaces,
}: Props) {
  return (
    <>
      <GridWrapper>
        <Item label="Public IPv4 Address" value={publicIPv4} />
        <Item label="Public IPv4 DNS" value={publicIPv4DNS} />
        <Item label="IPv6 Addresses" value={ipv6Addresses.filter(isNotNil)} />
        <Item label="Private IPv4 Address" value={privateIPv4} />
        <Item label="Private IP DNS Name (IPv4 only)" value={privateIPv4DNS} />
        <Item label="Secondary Private IPv4 Addresses" value="-" />
      </GridWrapper>
      <Spacer height={theme.spacing.spacing06} />
      <CollapsibleBox
        title="Network Interfaces"
        boxProps={{ innerSpacing: "spacing01" }}
      >
        <NetworkInterfaces networkInterfaces={networkInterfaces} />
      </CollapsibleBox>
    </>
  );
}

const GridWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  row-gap: ${theme.spacing.spacing02};
  column-gap: ${theme.spacing.spacing04};
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: ${theme.breakpoints.lg}px) {
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: 1fr;
  }
`;

type NetworkInterfacesProps = {
  networkInterfaces: NetworkInterface[];
};

function NetworkInterfaces({ networkInterfaces }: NetworkInterfacesProps) {
  if (isEmpty(networkInterfaces)) {
    return (
      <Message status="notification" image="info">
        No Network Interfaces found.
      </Message>
    );
  }

  return (
    <Table>
      <Table.thead>
        <Table.tr>
          <Table.th>Interface ID</Table.th>
          <Table.th>Public IPv4 Address</Table.th>
          <Table.th>Private IPv4 Address</Table.th>
          <Table.th>Attachment Status</Table.th>
          <Table.th>VPC ID</Table.th>
          <Table.th>Subnet ID</Table.th>
          <Table.th>Security Group ID</Table.th>
        </Table.tr>
      </Table.thead>
      <Table.tbody>
        {networkInterfaces.map((networkInterface) => (
          <Table.tr key={networkInterface.networkInterfaceId}>
            <Table.td>
              <Link
                to={getUrl(
                  networkInterface.networkInterfaceId,
                  networkInterface.networkInterfaceNid,
                  "ec2/network-interface"
                )}
              >
                {networkInterface.networkInterfaceId}
              </Link>
            </Table.td>
            <Table.td>{networkInterface.publicIp}</Table.td>
            <Table.td>{networkInterface.privateIpAddress}</Table.td>
            <Table.td>
              <Status value={networkInterface.status} successOn="attached" />
            </Table.td>
            <Table.td>
              <Link
                to={getUrl(
                  networkInterface.vpcId,
                  networkInterface.vpcNid,
                  "ec2/vpc"
                )}
              >
                {networkInterface.vpcId}
              </Link>
            </Table.td>
            <Table.td>
              <Link
                to={getUrl(
                  networkInterface.subnetId,
                  networkInterface.subnetNid,
                  "ec2/subnet"
                )}
              >
                {networkInterface.subnetId}
              </Link>
            </Table.td>
            <Table.td>
              <Link
                to={getUrl(
                  networkInterface.groupId,
                  networkInterface.groupNid,
                  "ec2/security-group"
                )}
              >
                {networkInterface.groupId}
              </Link>
            </Table.td>
          </Table.tr>
        ))}
      </Table.tbody>
    </Table>
  );
}

type NetworkInterface = {
  networkInterfaceId: string;
  networkInterfaceNid: string;
  publicIp: string;
  privateIpAddress: string;
  status: string;
  vpcId: string;
  vpcNid: string;
  groupId: string;
  groupNid: string;
  subnetId: string;
  subnetNid: string;
};
