/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

export const LinkButton = styled.button`
  color: ${theme.color.interactive.link};
  border: none;
  background: inherit;
  font-size: inherit;
  display: inline;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
