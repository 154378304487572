/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Modal } from "@nordcloud/gnui";
import { useDisclosure } from "~/hooks";
import { BudgetsConditions } from "../../BudgetsConditions";
import { SERVICENOW_MSG_PREVIEW_MODAL_TITLE } from "../../constants";
import { BudgetsPreviewMessage } from "../../PreviewMessages";
import { ShortDescriptionField } from "./ShortDescriptionField";

type Props = {
  isReadMode: boolean;
};

export function BudgetsOptionsSection({ isReadMode }: Props) {
  const { isOpen, close, open } = useDisclosure();

  return (
    <>
      <ShortDescriptionField isReadMode={isReadMode} />
      <BudgetsConditions
        shouldBeSelected
        isReadMode={isReadMode}
        onPreviewClick={open}
      />
      <Modal
        isOpen={isOpen}
        actions={[]}
        contentMaxHeight="40rem"
        contentLabel={SERVICENOW_MSG_PREVIEW_MODAL_TITLE}
        onClose={close}
      >
        <BudgetsPreviewMessage />
      </Modal>
    </>
  );
}
