/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { SUCCESS_TEXT, ERROR_TEXT } from "~/constants";
import { showSimpleInfo, showError } from "~/services/toast";
import { Form } from "./Form";
import { useUpdateAwsBillingData } from "./hooks/useUpdateAwsBillingData";
import { AwsFormFields, FormData } from "./validators";

type Props = {
  accountId: string;
  bucketId: string;
  region: string;
  reportName: string;
  reportPathPrefix: string;
  onClose: () => void;
};

export function AwsBillingDataUpdateForm({
  accountId,
  bucketId,
  region,
  reportName,
  reportPathPrefix,
  onClose,
}: Props) {
  const [updateAwsBillingData, { loading, error }] = useUpdateAwsBillingData();

  const handleUpdate = async (data: FormData) => {
    try {
      await updateAwsBillingData(data);
      showSimpleInfo(SUCCESS_TEXT.billingDataUpdated);
      onClose();
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  return (
    <Form
      showDivider
      loading={loading}
      error={error}
      disabledFields={[AwsFormFields.ACCOUNT_ID]}
      defaultValues={{
        accountId,
        bucketId,
        region,
        reportName,
        reportPathPrefix,
      }}
      onSubmit={handleUpdate}
      onClose={onClose}
    />
  );
}
