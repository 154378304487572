/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row, Tab, Tabs, Text, theme } from "@nordcloud/gnui";
import { KpiTargetSelection } from "~/generated/graphql";
import { KpiTarget } from "~/components/KPI/types";
import { KpiType } from "~/constants";
import { useTabsWithUrl } from "~/hooks";
import { KpiTargetsList } from "./KpiTargetsList";

const TABS_CONFIG = [
  { label: "Upcoming Targets", targetSelection: KpiTargetSelection.Upcoming },
  { label: "Completed Targets", targetSelection: KpiTargetSelection.Past },
  { label: "Archived Targets", targetSelection: KpiTargetSelection.Archived },
] as const;

type Props = {
  selectedBusinessContextId: string;
  kpiType: KpiType;
  onArchiveTarget: (id: string, selection: KpiTargetSelection) => void;
  onEditTarget: (kpi: KpiTarget) => void;
};

export function KpiTargets({
  selectedBusinessContextId,
  kpiType,
  onArchiveTarget,
  onEditTarget,
}: Props) {
  const { activeTab, setActiveTab } = useTabsWithUrl();

  return (
    <Row>
      <Col>
        <Tabs step={activeTab} handleTab={setActiveTab}>
          {TABS_CONFIG.map(({ label, targetSelection }) => (
            <Tab
              key={targetSelection}
              label={
                <Text weight="medium" size="md" mb={theme.spacing.spacing01}>
                  {label}
                </Text>
              }
            >
              <KpiTargetsList
                businessContextId={selectedBusinessContextId}
                targetSelection={targetSelection}
                kpiType={kpiType}
                onArchiveTarget={onArchiveTarget}
                onEditTarget={onEditTarget}
              />
            </Tab>
          ))}
        </Tabs>
      </Col>
    </Row>
  );
}
