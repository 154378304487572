/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo, useCallback } from "react";
import {
  useGetApplicationTopCategoriesCostsQuery,
  useGetApplicationOtherCategoriesCostQuery,
} from "~/generated/graphql";
import {
  extractCategoryIds,
  getTableGranularity,
  mapGranularity,
  Granularity,
  Period,
  PlaceholderCategory,
  TableData,
  topSpendingGroup,
  generateTopGroupData,
} from "~/components";
import { isNotNil } from "~/tools";
import { getCostSplitGroupNames } from "./utils";

type Props = {
  appId: string;
  period: Period;
  startDate: string;
  endDate: string;
  granularity: Granularity;
};

export function useApplicationCategoriesCostChart({
  appId,
  period,
  startDate,
  endDate,
  granularity,
}: Props) {
  const {
    data,
    loading: isCostLoading,
    error: showCostError,
  } = useGetApplicationTopCategoriesCostsQuery({
    variables: {
      top: topSpendingGroup.CATEGORIES,
      applicationId: appId,
      startDate,
      endDate,
      granularity: mapGranularity(granularity),
      tableGranularity: getTableGranularity(granularity, period),
    },
  });

  const {
    data: applicationOtherCategoriesCostTableResponse,
    loading: isOtherCategoriesLoading,
    error: otherCategoriesError,
  } = useGetApplicationOtherCategoriesCostQuery({
    variables: {
      input: {
        top: topSpendingGroup.CATEGORIES,
        applicationId: appId,
        startDate,
        endDate,
        granularity: mapGranularity(Granularity.MONTHS),
      },
    },
  });

  const applicationTopCategoriesCostTableTimePoints = useMemo(
    () =>
      (data?.applicationTopCategoriesTableCost?.timePoints ?? []).filter(
        isNotNil
      ),
    [data]
  );

  const costPerCategoryTableData: TableData[] = useMemo(
    () =>
      generateTopGroupData(applicationTopCategoriesCostTableTimePoints ?? []),
    [applicationTopCategoriesCostTableTimePoints]
  );

  const applicationTopCategoriesCostChartData = useMemo(
    () =>
      (data?.applicationTopCategoriesCost?.timePoints ?? []).filter(isNotNil),
    [data]
  );

  const costPerCategoryTotal = Number(
    data?.applicationTopCategoriesTableCost?.total ?? "0"
  );

  const applicationOtherCategoriesCostTableData = (
    applicationOtherCategoriesCostTableResponse?.applicationOtherCategoriesCost
      ?.timePoints ?? []
  ).filter(isNotNil);

  const costPerOtherCategoryTableData: TableData[] = useMemo(
    () => generateTopGroupData(applicationOtherCategoriesCostTableData ?? []),
    [applicationOtherCategoriesCostTableData]
  );

  const getSubRows = useCallback(
    (originalRow: TableData): TableData[] =>
      originalRow.field === PlaceholderCategory.OTHER.name
        ? costPerOtherCategoryTableData
        : [],
    [costPerOtherCategoryTableData]
  );

  const categories = getCostSplitGroupNames(costPerCategoryTableData);
  const otherCategoryIds = extractCategoryIds(
    applicationOtherCategoriesCostTableData
  );

  const isLoading = isCostLoading || isOtherCategoriesLoading;
  const showError = showCostError || otherCategoriesError;

  return {
    applicationTopCategoriesCostChartData,
    costPerCategoryTableData,
    costPerCategoryTotal,
    isLoading,
    showError,
    getSubRows,
    categories,
    otherCategoryIds,
  };
}
