/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ChartType } from "~/components/Charts/CostAnalysis/types";

export const chartTypeOptions = [
  { value: ChartType.ACCUMULATED_COST, label: ChartType.ACCUMULATED_COST },
  { value: ChartType.COST_VS_BUDGET, label: ChartType.COST_VS_BUDGET },
  {
    value: ChartType.COST_PER_APPLICATION,
    label: ChartType.COST_PER_APPLICATION,
  },
  { value: ChartType.COST_PER_CATEGORY, label: ChartType.COST_PER_CATEGORY },
];
