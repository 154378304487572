/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Label } from "@nordcloud/gnui";
import {
  Fieldset,
  FilterWrapper,
  SelectableListWithSearch,
} from "~/components";
import { isArrayFilterSelected } from "~/utils";
import { useInfiniteScroll } from "./hook";

type Props = {
  selectedApplications: string[];
  setSelectedApplications: (application: string[]) => void;
  setSelectedApplicationsList: (application: string[]) => void;
  handleClear: () => void;
};

export function ApplicationList({
  selectedApplications,
  setSelectedApplicationsList,
  setSelectedApplications,
  handleClear,
}: Props) {
  const {
    loading,
    elementRef,
    applications,
    handleInstanceMultiSelect,
    onModeChange,
  } = useInfiniteScroll({
    selectedApplications,
    setSelectedApplications,
    setSelectedApplicationsList,
  });

  return (
    <Fieldset>
      <Label name="Permissions" />
      <FilterWrapper
        isExpandedInitially
        name="Applications"
        isSelected={isArrayFilterSelected(selectedApplications)}
        selectedOptions={selectedApplications}
        onClear={handleClear}
      >
        <SelectableListWithSearch
          allowModeChange
          isInfiniteScroll
          elementRef={elementRef}
          isLoading={loading}
          selectableItems={applications}
          selectedOptions={selectedApplications}
          onModeChange={onModeChange}
          onMultiSelect={handleInstanceMultiSelect}
        />
      </FilterWrapper>
    </Fieldset>
  );
}
