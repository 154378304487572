/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ROUTES } from "~/routing/routes";
import { PathName } from "~/tools";

export type NotificationsConfigProps = {
  unread: number;
  limit: number;
};

export type AlertDotProps = {
  top?: string;
  left?: string;
  show?: boolean;
};

export function getNotificationConfig({
  unread,
  limit,
}: NotificationsConfigProps): {
  caption: string;
  icon: PathName;
} {
  return {
    caption: formatNotificationCaption({ unread, limit }),
    icon: unread ? "bellActiveRed" : "bell",
  };
}

function formatNotificationCaption({
  unread,
  limit,
}: NotificationsConfigProps) {
  if (unread === 0) {
    return "Notifications";
  }

  const prefix = unread <= limit ? unread : `${limit}+`;

  return `${prefix} new notifications`;
}

export function isSelected(
  pathname: string,
  path: string,
  exact = path === ROUTES.index
) {
  if (exact) {
    return pathname === path;
  }

  return pathname.includes(path);
}
