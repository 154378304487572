/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Row } from "@tanstack/react-table";
import { Else, If, Then, When } from "react-if";
import { Link } from "react-router-dom";
import {
  FlexContainer,
  Spacer,
  theme,
  Text,
  ExtendedTooltip,
} from "@nordcloud/gnui";
import { isNotEmpty, isNotNil } from "~/tools";
import { ExpandRowIcon } from "../../components/CostTableStyles";
import { generateLinks } from "../helpers";
import { ChartType, LinkParameters, TableData } from "../types";
import { CellColorIcon } from "./styles";

type Props = {
  row: Row<TableData>;
  showColorIcon: boolean;
  chartType: ChartType;
  color: string;
  linkParameters?: LinkParameters;
};

export function TableCell({
  row,
  showColorIcon,
  chartType,
  color,
  linkParameters,
}: Props) {
  const link = generateLinks(chartType, linkParameters, row.original) ?? "";

  return (
    <FlexContainer>
      <If condition={row.getCanExpand()}>
        <Then>
          <ExpandRowIcon
            isExpanded={row.getIsExpanded()}
            name="chevronDown"
            onClick={row.getToggleExpandedHandler()}
          />
        </Then>
        <Else>
          <Spacer
            width={theme.spacing.spacing06}
            css={{ minWidth: theme.spacing.spacing06 }}
          />
        </Else>
      </If>

      <When condition={row.depth > 0}>
        <Spacer width={theme.spacing.spacing06} />
      </When>
      <When condition={showColorIcon && row.depth === 0}>
        <CellColorIcon color={color} />
      </When>
      <ExtendedTooltip
        placement="bottom"
        position="start"
        caption={row.original?.field}
        display="inline-grid"
      >
        <Text
          mb="0"
          css={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          <If condition={isNotNil(link) && isNotEmpty(link)}>
            <Then>
              <Link to={link}>{row.original?.field}</Link>
            </Then>
            <Else>{row.original?.field}</Else>
          </If>
        </Text>
      </ExtendedTooltip>

      <When condition={isNotEmpty(row.getLeafRows())}>
        <Text marginLeft={theme.spacing.spacing01}>
          ({row.getLeafRows().length})
        </Text>
      </When>
    </FlexContainer>
  );
}
