/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import styled from "styled-components";
import {
  Label,
  Input,
  Description,
  theme,
  Tooltip,
  FlexContainer,
  SVGIcon,
} from "@nordcloud/gnui";
import { useDateValues } from "~/hooks";
import { formatShortDate } from "~/tools";
import { MIN_FILTER_DATE as minSelectableDate } from "../constants";
import { useDateRange } from "../hooks";

type Props = {
  from: string;
  to: string;
  onValueFromChange: (input: string) => void;
  onValueToChange: (input: string) => void;
  showLabels?: boolean;
  label?: string;
  additionalInfo?: string;
};

export function DateRangeInput({
  from,
  to,
  onValueFromChange,
  onValueToChange,
  showLabels = true,
  label,
  additionalInfo,
}: Props) {
  const {
    isDateToBeforeDateFrom,
    isDateFromBeforeMinSelectable,
    isDateToBeforeMinSelectable,
  } = useDateRange(from, to);

  const discoveredToStatus =
    isDateToBeforeDateFrom || isDateToBeforeMinSelectable
      ? "danger"
      : undefined;

  const { endDate, setEndDate, startDate, setStartDate } = useDateValues({
    from,
    to,
  });

  return (
    <>
      <When condition={showLabels}>
        <FlexContainer>
          <DateInputContainer>
            <Label name={label ? `${label} From` : "From"} />
          </DateInputContainer>
          <DateInputContainer>
            <Label name={label ? `${label} To` : "To"} />
          </DateInputContainer>
        </FlexContainer>
      </When>
      <FlexContainer>
        <DateInputContainer>
          <Input
            type="date"
            min={formatShortDate(minSelectableDate)}
            value={startDate ?? ""}
            status={isDateFromBeforeMinSelectable ? "danger" : undefined}
            placeholder="MM/DD/YYYY"
            onChange={(event) => setStartDate(event.target.value)}
            // See `useDateValues` comment
            onBlur={() => onValueFromChange(startDate ?? "")}
          />
        </DateInputContainer>
        <DateInputContainer>
          <Input
            type="date"
            min={formatShortDate(minSelectableDate)}
            value={endDate ?? ""}
            status={discoveredToStatus}
            placeholder="MM/DD/YYYY"
            onChange={(event) => setEndDate(event.target.value)}
            // See `useDateValues` comment
            onBlur={() => onValueToChange(endDate ?? "")}
          />
        </DateInputContainer>
        <When condition={additionalInfo}>
          <TooltipWrapper>
            <Tooltip caption={additionalInfo} position="right" minWidth="11rem">
              <FlexContainer data-testid="date-range-input-tooltip">
                <SVGIcon
                  name="info"
                  size="smd"
                  style={{ marginRight: ".4rem" }}
                />
              </FlexContainer>
            </Tooltip>
          </TooltipWrapper>
        </When>
      </FlexContainer>
      <When condition={isDateFromBeforeMinSelectable}>
        <Description>
          &quot;Became unallocated from&quot; should not be earlier than{" "}
          {minSelectableDate}.
        </Description>
      </When>
      <When condition={isDateToBeforeDateFrom}>
        <Description>
          &quot;Became unallocated to&quot; must not be before the &quot;Became
          unallocated from&quot;.
        </Description>
      </When>
      <When condition={isDateToBeforeMinSelectable}>
        <Description>
          &quot;Became unallocated to&quot; should not be earlier than{" "}
          {minSelectableDate}.
        </Description>
      </When>
    </>
  );
}

const DateInputContainer = styled.div`
  margin-right: ${theme.spacing.spacing02};
  width: 50%;
`;

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-flex;
  text-align: center;
`;
