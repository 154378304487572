/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { SVGIcon } from "@nordcloud/gnui";
import { ROUTES } from "~/routing/routes";
import { MenuLink } from "../components";
import { SubmenuItem } from "./styles";
import { SubmenuTitle } from "./SubmenuTitle";
import { useGetProviderAccountsCount } from "./useGetProviderAccountsCount";

export function ReservationsSubmenu() {
  const { hasAWS, hasAzure } = useGetProviderAccountsCount();

  return (
    <nav>
      <SubmenuTitle title="Reservations and Commitments" />
      <ul>
        <When condition={hasAWS}>
          <li>
            <MenuLink to={ROUTES.reservedInstances.index}>
              <SubmenuItem>
                <SVGIcon name="aws" />
                AWS
              </SubmenuItem>
            </MenuLink>
          </li>
        </When>
        <When condition={hasAzure}>
          <li>
            <MenuLink to={ROUTES.reservedInstances.azureReservation}>
              <SubmenuItem>
                <SVGIcon name="azure" />
                Azure
              </SubmenuItem>
            </MenuLink>
          </li>
        </When>
      </ul>
    </nav>
  );
}
