/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { useAsync } from "react-use";
import { useHasReservedInstancesRecommendationsLazyQuery } from "~/generated/graphql";
import { isNotEmpty, isNotNil } from "~/tools";

type Props = {
  platform: string;
  region: string;
  resourceType: string;
};

export function useReservedInstancesRecommendations({
  platform,
  region,
  resourceType,
}: Props) {
  const [page, setPage] = useState(0);
  const [getList, { error }] =
    useHasReservedInstancesRecommendationsLazyQuery();

  const LIMIT = 20;

  const defaultVariables = {
    limit: LIMIT,
    filters: {
      region: [region],
      instanceFamily: [resourceType],
      platform: [platform],
    },
  };

  // We need to find the page number that contains first instance of resource with `hasRecommendations` flag set to true
  // Thus we are looping over requests as we don't know how many resources are there.
  const { value, loading } = useAsync(async () => {
    const queryResult = await getList({
      variables: {
        ...defaultVariables,
        page,
      },
    });

    const listData = queryResult.data?.listReservationsAndCommitmentsCoverage;

    const resources = listData?.groupedResources ?? [];
    const pages = listData?.pages ?? 0;

    const hasRecommendation = resources
      .flatMap((item) => item?.resources)
      .filter(isNotNil)
      .some((item) => item.hasRecommendations && isNotEmpty(resourceType));

    if (!hasRecommendation && page < pages) {
      setPage((prev) => prev + 1);
    }

    return hasRecommendation;
  }, [page]);

  return {
    hasRecommendations: value ?? false,
    resultPageNumber: page,
    limit: LIMIT,
    loading,
    error,
  };
}
