/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import dayjs from "dayjs";
import { useOrgUnitBudgetQuery } from "~/generated/graphql";
import {
  Period,
  TimePoint,
  TableData,
} from "~/components/Charts/CostAnalysis/types";
import {
  calculateTotalBudget,
  mapCostChartData,
  mapPeriodForCostChartData,
} from "~/components/Charts/CostAnalysis/utils";
import { dateFormat } from "~/constants";
import { isNotEmpty } from "~/tools";

type Props = {
  orgUnitId: string;
  currentYear: string;
  period: Period;
  startDate: string;
  endDate: string;
  orgUnitAccumulatedCostBudgetData: TimePoint[];
  orgUnitAccumulatedCostTimePoints: TimePoint[];
  orgUnitAccumulatedCostTotal: number;
};

export function useOrgUnitCostVersusBudgetChart({
  orgUnitId,
  currentYear,
  period,
  startDate,
  endDate,
  orgUnitAccumulatedCostBudgetData,
  orgUnitAccumulatedCostTimePoints,
  orgUnitAccumulatedCostTotal,
}: Props) {
  const { data, loading, error } = useOrgUnitBudgetQuery({
    variables: {
      nid: orgUnitId,
      year: currentYear,
      previousYear: (parseInt(currentYear, 10) - 1).toString(),
    },
  });

  const orgUnitBudgetData = data?.orgUnitV2?.budgetYearly.budgetByMonth.map(
    (value, index) => {
      const date = dayjs()
        .startOf("year")
        .add(index, "month")
        .format(dateFormat.shortDate);
      return {
        date,
        value: Number(value),
      };
    }
  );

  const orgUnitCostVersusBudgetChartData = useMemo(
    () =>
      mapCostChartData(
        mapPeriodForCostChartData(period, startDate, endDate),
        orgUnitAccumulatedCostBudgetData
      ),
    [period, orgUnitAccumulatedCostBudgetData, startDate, endDate]
  );

  const orgUnitBudgetTotal = useMemo(
    () =>
      Number(
        calculateTotalBudget(startDate, endDate, data?.orgUnitV2?.budgetYearly)
      ),
    [startDate, endDate, data]
  );

  const orgUnitBudgetTable: TableData[] = useMemo(
    () => [
      {
        field: "Cost",
        total: Number(orgUnitAccumulatedCostTotal),
        ...orgUnitAccumulatedCostTimePoints?.reduce(
          (acc, { date, value }) => ({ ...acc, [date]: Number(value ?? 0) }),
          {}
        ),
      },
      {
        field: "Budget",
        total: orgUnitBudgetTotal,
        ...data?.orgUnitV2?.budgetYearly.budgetByMonth?.reduce(
          (acc, budget, index) => {
            const date = dayjs()
              .startOf("year")
              .add(index, "month")
              .format(dateFormat.shortDate);
            return { ...acc, [date]: budget };
          },
          {}
        ),
      },
    ],
    [
      orgUnitAccumulatedCostTotal,
      orgUnitBudgetTotal,
      data,
      orgUnitAccumulatedCostTimePoints,
    ]
  );

  const hasBudget =
    isNotEmpty(orgUnitBudgetData ?? []) &&
    orgUnitBudgetData?.some(({ value }) => value !== 0);

  return {
    orgUnitBudgetData,
    orgUnitCostVersusBudgetChartData,
    orgUnitBudgetTable,
    orgUnitBudgetTotal,
    hasBudget,
    loading,
    error,
  };
}
