/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { Table, theme } from "@nordcloud/gnui";

export const StyledTd = styled(Table.td)<{ withPadding: boolean }>`
  vertical-align: middle;
  padding: ${({ withPadding }) =>
    withPadding ? theme.spacing.spacing02 : "inherit"};
`;

export const StyledTextItem = styled.span<{
  align?: "left" | "right";
  marginLeft?: string;
}>`
  color: ${theme.color.text.text04};
  width: 49%;
  text-align: ${({ align }) => align || "left"};
  font-size: ${theme.fontSizes.sm};
  white-space: nowrap;
  margin-left: ${({ marginLeft }) => marginLeft ?? theme.spacing.spacing00};
`;
