/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { theme } from "@nordcloud/gnui";
import { getUserLocalesInternal } from "~/tools";

export function formatPercentage(value: string) {
  return `${Math.abs(Number(value)).toFixed(2)}%`;
}

export function getSavingsIconColor(value: string) {
  return Number(value) > 0 ? "success" : "danger";
}

export function getSavingsIcon(value: string) {
  return Number(value) > 0 ? "caretDown" : "caretUp";
}

export function getIconType(
  isInstanceFromSavingsSuggestions: boolean,
  isCurrentType: boolean
) {
  if (isCurrentType) {
    return "success";
  }

  if (isInstanceFromSavingsSuggestions) {
    return "compare";
  }

  return "cloudWaste";
}

export function getTypeRowBackgroundColor(
  isInstanceFromSavingsSuggestions: boolean
) {
  return isInstanceFromSavingsSuggestions
    ? theme.color.support.greenInverse
    : undefined;
}

export function getIconTooltipText(
  isInstanceFromSavingsSuggestions: boolean,
  isCurrentType: boolean
) {
  if (isCurrentType) {
    return "Current instance";
  }

  if (isInstanceFromSavingsSuggestions) {
    return "Savings Suggestions' instance";
  }

  return "Instance Comparison's instance";
}

export function getSavingsTooltipText(saving: string) {
  const quantifier = Number(saving) > 0 ? "less" : "more";
  return `${formatPercentage(saving)} ${quantifier} than current type`;
}

export function calculateSaving(rawPrice: string, rawCurrentTypePrice: string) {
  const price = parseFloat(rawPrice);
  const currentPrice = parseFloat(rawCurrentTypePrice);

  return ((100 * (currentPrice - price)) / currentPrice).toFixed(2);
}

export function formatPrice(value: string) {
  // We're sure that this value is in dollars and we cannot use existing solution, because price is too fine-grained
  return new Intl.NumberFormat(getUserLocalesInternal(), {
    style: "currency",
    currency: "USD",
    currencyDisplay: "narrowSymbol",
    maximumSignificantDigits: 6,
  }).format(Number(value));
}
