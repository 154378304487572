/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { theme } from "@nordcloud/gnui";
import { useCurrency } from "~/hooks";
import { formatMoneyLabel } from "~/tools";
import { ReservationTooltipRowData } from "../../types";
import { ReservationTooltipRow } from "./ReservationTooltipRow";
import { ReservationTooltipTable } from "./ReservationTooltipTable";

export function ReservationTooltipActualCost({
  values,
}: {
  values: ReservationTooltipRowData[];
}) {
  const { currency } = useCurrency();

  return (
    <ReservationTooltipTable>
      {values.map(
        ({ key, value, percentageValue = "0.00%", color, hasBottomBorder }) => (
          <ReservationTooltipRow
            key={`${key}${value}`}
            infoValue={formatMoneyLabel(Number(value), currency)}
            color={color}
            infoLabel={
              key.includes("Daily Cost") ? key : `${key} (${percentageValue})`
            }
            css={{
              borderBottom: hasBottomBorder
                ? `1px solid ${theme.color.border.border01}`
                : "inherit",
              margin: `${theme.spacing.spacing04} 0`,
              padding: theme.spacing.spacing03,
            }}
          />
        )
      )}
    </ReservationTooltipTable>
  );
}
