/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { MultipleSelect, SelectButton } from "@nordcloud/gnui";

type Props = {
  currentYear: string;
  nextYear: string;
  onChange: (value: string) => void;
  selectedYear: string;
};

export function YearSelector({
  currentYear,
  nextYear,
  onChange,
  selectedYear,
}: Props) {
  return (
    <MultipleSelect>
      <SelectButton
        name="currentYear"
        value={currentYear}
        labelText={currentYear}
        onClick={() => onChange(currentYear)}
        className={selectedYear === currentYear ? "active" : ""}
        css={{
          width: "8rem",
        }}
      />
      <SelectButton
        name="nextYear"
        value={nextYear}
        labelText={nextYear}
        onClick={() => onChange(nextYear)}
        className={selectedYear === nextYear ? "active" : ""}
        css={{
          width: "8rem",
        }}
      />
    </MultipleSelect>
  );
}
