/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Provider } from "~/generated/graphql";
import { useAccountsOptions } from "~/hooks/useAccountsOptions";
import { isNotNil } from "~/tools";
import { isStringProvider } from "../utils";

export function useSelectAccount(provider: string) {
  const [selectedAccount, setSelectedAccount] = useState("");

  const { accountsOptions, loading, error } = useAccountsOptions(
    isStringProvider(provider) ? [Provider[provider]] : undefined
  );

  const accountsLabels = accountsOptions.map((account) => account.label);

  const onSelect = (label: string) => {
    if (isNotNil(accountsOptions.find((account) => account.label === label))) {
      setSelectedAccount(label);
    }
  };

  return {
    accountsLabels,
    selectedAccount,
    loading,
    error,
    onSelect,
  };
}
