/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { v4 as uuidv4 } from "uuid";
import {
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsAndCommitmentsFilters,
  ReservationsAndCommitmentsLookbackPeriod,
  ReservationsProvider,
  ReservationsRecommendationPaymentOption,
  ReservationsRecommendationTerm,
  useListReservationsAndCommitmentsCoverageQuery,
} from "~/generated/graphql";
import { isNotNil } from "~/tools";

type PropsCoverage = {
  page: number;
  limit: number;
  offeringType: ReservationsAndCommitmentsCoverageOfferingType;
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  paymentOption?: ReservationsRecommendationPaymentOption;
  termOption?: ReservationsRecommendationTerm;
  timePeriod?: ReservationsAndCommitmentsLookbackPeriod;
  filters?: ReservationsAndCommitmentsFilters;
  provider: ReservationsProvider;
};

export function useGetCoverageData({
  page,
  limit,
  offeringType,
  serviceUsageType,
  paymentOption,
  termOption,
  timePeriod,
  filters,
  provider,
}: PropsCoverage) {
  const { data, loading, error } =
    useListReservationsAndCommitmentsCoverageQuery({
      variables: {
        limit,
        page,
        offeringType,
        serviceUsageType,
        paymentOption,
        term: termOption,
        lookbackPeriod: timePeriod,
        filters,
        provider,
      },
      fetchPolicy: "cache-and-network",
    });

  const reservationsCoverage = data?.listReservationsAndCommitmentsCoverage;

  const RICoverageGroupedData =
    reservationsCoverage?.groupedResources?.filter(isNotNil)?.map((items) => {
      const dataResources = items?.resources?.map((item) => ({
        totalOnDemandCost: item?.totalOnDemandCost ?? "0.00",
        coverageSavedCost: item?.coverageSavedCost ?? "0.00",
        potentialSavings: item?.potentialSavings ?? "0.00",
        hasCoverageResourceList: item?.hasCoverageResourceList ?? false,
        hasRecommendations: item?.hasRecommendations ?? false,
        riCostPercent: item?.riCostPercent ?? "0",
        spCostPercent: item?.spCostPercent ?? "0",
        onDemandCostPercent: item?.onDemandCostPercent ?? "100",
        resource: item?.resource ?? "",
        platform: item?.platform ?? "",
        region: item?.region ?? "",
        provider: item?.provider ?? "",
      }));

      return { ...items, resources: dataResources, id: uuidv4() };
    }) ?? [];

  return {
    RICoverageGroupedData,
    RICoverageDataCount: reservationsCoverage?.count ?? 0,
    RICoverageLoading: loading,
    RICoverageError: error,
  };
}
