/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  FlexContainer,
  theme,
  Text,
  Button,
  Box,
  Spacer,
  Upload,
} from "@nordcloud/gnui";
import { ExternalLink, FormGroup, FormHint } from "~/components";
import { SUCCESS_TEXT, ERROR_TEXT } from "~/constants";
import { showError, showSuccess } from "~/services/toast";
import { isEmpty } from "~/tools";
import { readFile } from "~/views/cloudAccounts/utils";
import { GcpJsonData, Provider } from "../../types";
import { CopyBox } from "../components";
import {
  ERROR_READ_FILE,
  GcpFormField,
  URL_GCP_CONSOLE,
  URL_GCP_IAM_CREDENTIALS,
  URL_GCP_SERVICE_ACCOUNTS,
  URL_GCP_STORAGE,
} from "../constants";
import { useBatchUpdateGcpAccountCredentials } from "../hooks/useBatchUpdateGcpAccountCredentials";
import { UpdateHandlerVariables } from "../types";
import { gcpCredentialsSchema, hasFiles } from "../validators";

type Props = {
  nids: string[];
  onClose: () => void;
};

export function GcpUpdateCredentialsForm({ onClose, nids }: Props) {
  const [updateCredentials] = useBatchUpdateGcpAccountCredentials();
  const [filename, setFilename] = useState("");
  const formMethods = useForm<UpdateHandlerVariables<Provider.Gcp>>({
    resolver: yupResolver(gcpCredentialsSchema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = formMethods;

  const onSubmit = async ({ key }: UpdateHandlerVariables<Provider.Gcp>) => {
    try {
      await updateCredentials(
        {
          key,
        },
        nids
      );
      showSuccess(SUCCESS_TEXT.accountCredentialsUpdated);
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  const handleUpload = async ({ key }: { key: FileList }) => {
    try {
      const json = await readFile<GcpJsonData>(key[0]);
      const input: UpdateHandlerVariables<Provider.Gcp> = {
        key: JSON.stringify(json),
      };

      await onSubmit(input);
    } catch {
      showError(ERROR_READ_FILE);
      setFilename("");
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    callback: (event: unknown) => void
  ) => {
    const fileList = event.target.files;
    if (fileList instanceof FileList && hasFiles(fileList)) {
      setFilename(fileList[0].name);
    }

    callback(fileList);
  };

  const placeholder = isEmpty(filename)
    ? "Upload service account key json file"
    : filename;

  return (
    <FormProvider {...formMethods}>
      <form
        css={{ width: "100%" }}
        id="GCP-cred-form"
        onSubmit={handleSubmit(handleUpload)}
      >
        <Text color={theme.color.text.text02} size="sm">
          To on-board GCP Organisation, please follow the next steps:
        </Text>
        <FlexContainer
          css={{ width: "100%" }}
          alignItems="flex-start"
          direction="column"
          gap={theme.spacing.spacing03}
        >
          <FormHint stepNumber={1}>
            <Text tag="div">
              Sign in to the{" "}
              <ExternalLink href={URL_GCP_CONSOLE}>
                Google Cloud Platform
              </ExternalLink>
            </Text>
          </FormHint>
          <FormHint stepNumber={2}>
            <Text tag="div">
              Create a project for IBM Multicloud Accelerator
            </Text>
          </FormHint>
          <FormHint stepNumber={3}>
            <FlexContainer
              css={{ width: "100%" }}
              direction="column"
              alignItems="flex-start"
            >
              <Text tag="div">
                Go to the{" "}
                <ExternalLink href={URL_GCP_STORAGE}>
                  Cloud Storage
                </ExternalLink>{" "}
                and Create Bucket with label:
              </Text>
              <Box mt={theme.spacing.spacing02} boxStyle="grey">
                <ul>
                  <li>
                    <Text tag="div" weight="medium">
                      Key: nordcloud-purpose
                    </Text>
                  </li>
                  <li>
                    <Text tag="div" weight="medium">
                      Value: scanner
                    </Text>
                  </li>
                </ul>
              </Box>
            </FlexContainer>
          </FormHint>
          <FormHint stepNumber={4}>
            <Text>
              Go to the{" "}
              <ExternalLink href={URL_GCP_SERVICE_ACCOUNTS}>
                Service Accounts
              </ExternalLink>{" "}
              and create new Service Account
            </Text>
          </FormHint>
          <FormHint stepNumber={5}>
            <FlexContainer
              css={{ width: "100%" }}
              direction="column"
              alignItems="flex-start"
            >
              <Text tag="div">
                Go to the Service Account details and create new Key
              </Text>
              <FormGroup error={errors[GcpFormField.KEY]}>
                <div css={{ marginTop: theme.spacing.spacing02 }}>
                  <Controller
                    name={GcpFormField.KEY}
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Upload
                        id={GcpFormField.KEY}
                        placeholder={placeholder}
                        onChange={(event) => handleInputChange(event, onChange)}
                      />
                    )}
                  />
                </div>
              </FormGroup>
            </FlexContainer>
          </FormHint>
          <FormHint stepNumber={6}>
            <FlexContainer
              css={{ width: "100%" }}
              direction="column"
              alignItems="flex-start"
            >
              <Text tag="div">
                Go to the{" "}
                <ExternalLink href={`${URL_GCP_IAM_CREDENTIALS}?pli=1`}>
                  IAM
                </ExternalLink>{" "}
                and on project level add following roles to the Service Account
              </Text>
              <FlexContainer
                css={{ width: "100%" }}
                mt={theme.spacing.spacing02}
                direction="column"
                gap={theme.spacing.spacing01}
              >
                <CopyBox>Service Account Token Creator</CopyBox>
                <CopyBox>Service Usage Admin</CopyBox>
                <CopyBox>Storage Object Creator</CopyBox>
              </FlexContainer>
            </FlexContainer>
          </FormHint>
          <FormHint stepNumber={7}>
            <FlexContainer
              css={{ width: "100%" }}
              direction="column"
              alignItems="flex-start"
            >
              <Text tag="div">
                Switch to the organisation level and add following roles to the
                Service Account
              </Text>
              <FlexContainer
                css={{ width: "100%" }}
                mt={theme.spacing.spacing02}
                direction="column"
                gap={theme.spacing.spacing01}
              >
                <CopyBox>Cloud Asset Viewer</CopyBox>
                <CopyBox>Viewer</CopyBox>
                <CopyBox>Organization Viewer</CopyBox>
              </FlexContainer>
            </FlexContainer>
          </FormHint>
          <FormHint stepNumber={8}>
            <FlexContainer direction="column" alignItems="flex-start">
              <Text tag="div">
                Make sure that the following APIs are enabled on the project
                level:
              </Text>
              <ul
                css={{
                  listStyleType: "initial",
                  marginLeft: theme.spacing.spacing04,
                  paddingTop: theme.spacing.spacing04,
                }}
              >
                <li>
                  <ExternalLink href={URL_GCP_IAM_CREDENTIALS}>
                    IAM Service Account Credentials API
                  </ExternalLink>
                </li>
                <li>Service Usage API</li>
                <li>Storage API</li>
                <li>Cloud Asset API</li>
                <li>Cloud Resource Manager API</li>
                <li>Recommender API</li>
              </ul>
            </FlexContainer>
          </FormHint>
        </FlexContainer>
        <Spacer height={theme.spacing.spacing06} />
        <FlexContainer gap={theme.spacing.spacing04}>
          <Button type="submit" icon="checkmark">
            Apply
          </Button>
          <Button severity="low" onClick={onClose}>
            Cancel
          </Button>
        </FlexContainer>
      </form>
    </FormProvider>
  );
}
