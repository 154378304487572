/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { theme } from "@nordcloud/gnui";
import { ReservationsAndCommitmentsCoverageServiceUsageType } from "~/generated/graphql";
import { LegendProps } from "../../types";

export const useGetAzureLegendData = (
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType
): LegendProps[] => {
  const databaseLegend =
    "On-Demand Cost (Cosmos DB, MySQL, MariaDB, PostgreSQL, SQL Database and SQL Managed Instance services)";

  const reserveredInstanceAndCoverage = {
    color: theme.color.support.cyan,
    label: "Covered By Reserved Instances",
  };

  const databaseOnDemand = {
    color: theme.color.support.grey,
    label: databaseLegend,
  };

  const virtualMachinesOnDemand = {
    color: theme.color.support.grey,
    label: "On-Demand Cost (Virtual Machines)",
  };

  const analyticsOnDemand = {
    color: theme.color.support.grey,
    label: "On-Demand Cost (Synapse Analytics)",
  };

  const storageOnDemand = {
    color: theme.color.support.grey,
    label:
      "On-Demand Cost (Azure Data Lake Storage, Blob Storage, General Block Blob Storage and Tiered Block Blob Storage services)",
  };

  const defaultOnDemand = {
    color: theme.color.support.grey,
    label: "On-Demand Cost",
  };

  switch (serviceUsageType) {
    case ReservationsAndCommitmentsCoverageServiceUsageType.Database:
      return [databaseOnDemand, reserveredInstanceAndCoverage];
    case ReservationsAndCommitmentsCoverageServiceUsageType.Analytics:
      return [analyticsOnDemand, reserveredInstanceAndCoverage];
    case ReservationsAndCommitmentsCoverageServiceUsageType.Storage:
      return [storageOnDemand, reserveredInstanceAndCoverage];
    case ReservationsAndCommitmentsCoverageServiceUsageType.Compute:
      return [virtualMachinesOnDemand, reserveredInstanceAndCoverage];
    default:
      return [defaultOnDemand];
  }
};
