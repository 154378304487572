/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { firstOf } from "./array";

const DEFAULT_LOCALE = "en-US";

export function getUserLocalesInternal() {
  const language =
    firstOf(window.navigator.languages) ?? window.navigator.language;

  return language ?? DEFAULT_LOCALE;
}
