/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";

type Props = {
  trigger: () => boolean;
  ref: React.RefObject<(HTMLElement | null)[]>;
};

export function useScrollToFirstResult({ trigger, ref }: Props) {
  React.useLayoutEffect(() => {
    if (trigger() && ref.current?.[0] != null) {
      ref.current[0].scrollIntoView();
    }
  }, [ref, trigger]);
}
