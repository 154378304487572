/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { UnpackNestedValue } from "react-hook-form";
import {
  GetWorkflowListQueryVariables,
  useAddWorkflowMutation,
  WorkflowExternalIntegrationType,
  WorkflowTriggerType,
  GetWorkflowListDocument,
  GetWorkflowListQuery,
} from "~/generated/graphql";
import { ERROR_TEXT } from "~/constants";
import { showError } from "~/services/toast";
import { isNotNil } from "~/tools";
import { getAddWorkflowUserError } from "../../errors";
import { useWorkflowsListSort } from "../../hooks";
import {
  getEmailInput,
  getServiceNowInput,
  getSlackInput,
  mapSchedulerSettings,
  mapSelectData,
} from "../../mapping";
import { WorkflowFormData } from "../../types";
import { Step } from "../constants";

function mapTrigger(
  type: WorkflowTriggerType,
  schedulerFormData: UnpackNestedValue<WorkflowFormData>["scheduler"]
) {
  const isScheduler = type === WorkflowTriggerType.Scheduler;

  return {
    type,
    ...(isScheduler && {
      schedulerOptions: mapSchedulerSettings(schedulerFormData),
    }),
  };
}

function mapExternalIntegrations(
  type: WorkflowExternalIntegrationType,
  formData: UnpackNestedValue<WorkflowFormData>
) {
  switch (type) {
    case WorkflowExternalIntegrationType.ServiceNow:
      return {
        type,
        serviceNowOptions: getServiceNowInput(formData.serviceNow),
      };
    case WorkflowExternalIntegrationType.Email:
      return {
        type,
        emailOptions: getEmailInput(formData.email),
      };
    case WorkflowExternalIntegrationType.Slack:
      return {
        type,
        slackOptions: getSlackInput(formData.slack),
      };
    default:
      return {
        type,
      };
  }
}

function getQueryInput(formData: UnpackNestedValue<WorkflowFormData>) {
  return {
    name: formData.name,
    description: formData.description,
    contactPersonIds: formData.contactPersonIds.map(({ value }) => value),
    active: formData.active,
    triggers: formData.triggerComponents.map((type) =>
      mapTrigger(type, formData.scheduler)
    ),
    selectData: mapSelectData(formData),
    externalIntegrations: formData.sendDataComponents.map((type) =>
      mapExternalIntegrations(type, formData)
    ),
  };
}

export function useAddWorkflow() {
  const [
    addWorkflow,
    {
      data: addWorkflowResult,
      loading,
      error,
      client: { cache },
    },
  ] = useAddWorkflowMutation();
  const { sortField, sortOrder } = useWorkflowsListSort();
  const [currentStep, setCurrentStep] = React.useState(Step.DETAILS);
  const [userError, setUserError] = React.useState<JSX.Element | undefined>();

  const handleAddWorkflow = React.useCallback(
    async (formData: UnpackNestedValue<WorkflowFormData>) => {
      try {
        const addResponse = await addWorkflow({
          variables: {
            input: getQueryInput(formData),
          },
        });
        const addedWorkflow = addResponse?.data?.addWorkflowV2?.workflow;
        const newWorkflows = addedWorkflow
          ? [
              {
                id: addedWorkflow.id,
                name: addedWorkflow.name,
                description: addedWorkflow.description,
                active: addedWorkflow.active,
                contactPersons: addedWorkflow.contactPersons,
                createdAt: addedWorkflow.createdAt,
                lastRunDate: addedWorkflow.lastRunDate,
                lastRunStatus: addedWorkflow.lastRunStatus,
                triggers: addedWorkflow.triggers,
              },
            ]
          : [];

        cache.updateQuery<GetWorkflowListQuery, GetWorkflowListQueryVariables>(
          {
            query: GetWorkflowListDocument,
            variables: {
              field: sortField,
              order: sortOrder,
            },
          },
          (getWorkflowList) => ({
            workflows: [...newWorkflows, ...(getWorkflowList?.workflows ?? [])],
          })
        );

        const userErrors = addResponse?.data?.addWorkflowV2?.userErrors;
        if (isNotNil(userErrors)) {
          setUserError(getAddWorkflowUserError(userErrors));
        } else {
          setCurrentStep(Step.SUMMARY);
        }
      } catch {
        showError(ERROR_TEXT.default);
      }
    },
    [addWorkflow, cache, sortField, sortOrder]
  );

  return {
    addWorkflowResult,
    loading,
    error,
    handleAddWorkflow,
    currentStep,
    setCurrentStep,
    userError,
    setUserError,
  };
}
