/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ROUTES } from "~/routing/routes";
import { ACTIONS, Can } from "~/services/auth";
import { MenuLink } from "../components";
import { SubmenuTitle } from "./SubmenuTitle";

export function SettingsSubmenu() {
  return (
    <>
      <SubmenuTitle title="Settings" />
      <ul>
        <li>
          <MenuLink to={ROUTES.settings.cloudWaste}>
            Saving Suggestions
          </MenuLink>
        </li>
        <Can action={ACTIONS.visitCostSplittingRules}>
          <li>
            <MenuLink to={ROUTES.settings.costSplitting}>
              Cost Splitting Rules
            </MenuLink>
          </li>
        </Can>
        <li>
          <MenuLink to={ROUTES.settings.mapping}>Mapping Rules</MenuLink>
        </li>
        <Can action={ACTIONS.visitExternalIntegrations}>
          <li>
            <MenuLink to={ROUTES.externalIntegrations.index}>
              External Integrations
            </MenuLink>
          </li>
        </Can>
        <li>
          <MenuLink to={ROUTES.integrationPlugins.index}>
            Integration Plugins
          </MenuLink>
        </li>
        <Can action={ACTIONS.visitWorkflows}>
          <li>
            <MenuLink to={ROUTES.workflows.index}>Workflows</MenuLink>
          </li>
        </Can>
      </ul>
    </>
  );
}
