/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ReservationsAndCommitmentsCoverageServiceUsageType } from "~/generated/graphql";
import { ResourceLabel } from "../constants";
import { useGetCurrentTab } from "./useGetCurrentTab";

export function useGetFilterLabel(
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType
) {
  const { isDatabaseTab, isAnalyticsTab, isStorageTab } =
    useGetCurrentTab(serviceUsageType);

  if (isAnalyticsTab || isDatabaseTab) {
    return ResourceLabel.Service;
  }

  if (isStorageTab) {
    return ResourceLabel.TierAndRedundancy;
  }
  return ResourceLabel.Instance;
}
