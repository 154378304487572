/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer, Text, theme } from "@nordcloud/gnui";
import { ColorBox } from "~/components";

type Props = {
  items: { color: string; label: string }[];
};

export function UtilizationLegend({ items }: Props) {
  return (
    <FlexContainer columnGap={theme.spacing.spacing02}>
      {items.map(({ color, label }) => (
        <FlexContainer
          columnGap={theme.spacing.spacing01}
          key={`${color}-${label}`}
        >
          <ColorBox color={color} />
          <Text size="sm" tag="span" color={theme.color.text.text02}>
            {label}
          </Text>
        </FlexContainer>
      ))}
    </FlexContainer>
  );
}
