/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useFormContext } from "react-hook-form";
import {
  Row,
  Col,
  Dropdown,
  Label,
  Input,
  InputPassword,
  Spacer,
  theme,
} from "@nordcloud/gnui";
import { FormGroup, FormWizardFooter } from "~/components";
import { noop } from "~/tools";
import { IntegrationPluginContactSelect } from "./IntegrationPluginContactSelect";
import { TestTurbonomicConnectionButton } from "./TestTurbonomicConnectionButton";
import { IntegrationPluginFormData } from "./types";

export function IntegrationPluginDetailsForm() {
  const {
    register,
    formState: { errors },
  } = useFormContext<IntegrationPluginFormData>();

  return (
    <Row justify="center">
      <Col sm={6} md={6} lg={8}>
        <Spacer height={theme.spacing.spacing07} />
        <FormGroup error={errors.integrationName}>
          <Label name="Integration Name" htmlFor="integrationName" required />
          <Input
            id="integrationName"
            placeholder="Integration Name"
            {...register("integrationName")}
          />
        </FormGroup>
        <FormGroup error={errors.integrationDescription}>
          <Label
            name="Integration Description"
            htmlFor="IntegrationPluginFields.DESCRIPTION"
          />
          <Input
            id="integrationDescription"
            placeholder="Integration Description"
            {...register("integrationDescription")}
          />
        </FormGroup>
        <FormGroup error={errors.integrationType}>
          <Label name="Integration Type" htmlFor="integrationType" />
          <Dropdown
            name="Turbonomic"
            options={[]}
            value="TURBONOMIC"
            onChange={noop}
            disabled
          />
        </FormGroup>
        <FormGroup error={errors.url}>
          <Label name="URL" htmlFor="url" required />
          <Input
            id="url"
            {...register("url")}
            placeholder="https://try.turbonomic.io"
          />
        </FormGroup>
        <FormGroup error={errors.accountName}>
          <Label name="Account Name" htmlFor="accountName" required />
          <Input
            id="accountName"
            {...register("accountName")}
            placeholder="Account Name"
          />
        </FormGroup>
        <FormGroup error={errors.password}>
          <Label name="Password" htmlFor="password" required />
          <InputPassword
            id="password"
            {...register("password")}
            placeholder="Password"
            showPasswordOnInit={false}
          />
        </FormGroup>
        <TestTurbonomicConnectionButton />
        <Spacer height={theme.spacing.spacing05} />
        <IntegrationPluginContactSelect />
        <Spacer height={theme.spacing.spacing07} />
        <FormWizardFooter
          nextButtonProps={{
            type: "submit",
            children: "Add Integration",
          }}
        />
      </Col>
    </Row>
  );
}
