/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then, When } from "react-if";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ExtendedTooltip, FlexContainer, Text, theme } from "@nordcloud/gnui";
import { getFirstItem, isNotEmpty } from "~/tools";

type Props = {
  label: string;
  value?: string[] | string;
  url?: string;
  component?: React.ReactNode;
  truncate?: boolean;
  textWidth?: string;
};

export function Item({
  label,
  value = "",
  url = "",
  component,
  truncate = false,
  textWidth = "20rem",
}: Props) {
  const hasUrl = isNotEmpty(url);
  const hasValue = isNotEmpty(value);
  const hasMore = Array.isArray(value) && value.length > 1;

  return (
    <FlexContainer direction="column" alignItems="flex-start">
      <Text size="sm" mb={0} color={theme.color.text.text02}>
        {label}
      </Text>
      <When condition={component != null}>{component}</When>
      <When condition={component == null}>
        <If condition={hasUrl && hasValue}>
          <Then>
            <Link to={url}>{value}</Link>
          </Then>
          <Else>
            <FlexContainer gap={theme.spacing.spacing02}>
              <If condition={truncate}>
                <Then>
                  <ScrollableText width={textWidth}>
                    {getValue(value)}
                  </ScrollableText>
                </Then>
                <Else>{hasValue ? getValue(value) : "-"}</Else>
              </If>
              <When condition={hasMore}>
                <ExtendedTooltip
                  caption={
                    <ul>
                      {Array.isArray(value)
                        ? value.map((item) => <li key={item}>- {item}</li>)
                        : null}
                    </ul>
                  }
                >
                  <Text size="sm" mb={0} css={{ cursor: "pointer" }}>
                    | Show More
                  </Text>
                </ExtendedTooltip>
              </When>
            </FlexContainer>
          </Else>
        </If>
      </When>
    </FlexContainer>
  );
}

function getValue(value: string[] | string) {
  return Array.isArray(value) ? getFirstItem(value) : value;
}

const ScrollableText = styled(Text)`
  margin-bottom: 0;
  overflow: scroll;
  white-space: nowrap;
`;
