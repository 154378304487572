/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export type ThresholdActionsState = {
  isModalConfirmOpen: boolean;
  initialValues: { id: string; name: string };
  tempThresholdData: { id?: string; name?: string };
};

export type Action =
  | {
      type: ACTION.CLOSE_MODAL;
    }
  | {
      type: ACTION.REMOVE;
      payload: {
        id: string;
        name: string;
      };
    };

export const initialState = {
  isModalConfirmOpen: false,
  initialValues: {
    id: "",
    name: "",
  },
  tempThresholdData: {},
};

export enum ACTION {
  REMOVE = "REMOVE",
  CLOSE_MODAL = "CLOSE_MODAL",
}

export const reducer = (
  state: ThresholdActionsState,
  action: Action
): ThresholdActionsState => {
  switch (action.type) {
    case ACTION.REMOVE:
      return {
        ...state,
        isModalConfirmOpen: true,
        tempThresholdData: {
          id: action.payload.id,
          name: action.payload.name,
        },
      };

    case ACTION.CLOSE_MODAL:
      return {
        ...state,
        isModalConfirmOpen: false,
      };

    default:
      throw new Error("Unknown action");
  }
};
