/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Spacer } from "@nordcloud/gnui";
import { CollapsibleBox } from "~/components";
import { capitalize } from "~/tools";
import { Item, DefaultEc2GridWrapper } from "../components";

type Props = {
  instanceTenancy: string;
  isDefault: boolean;
  state: string;
  dhcpOptionsId: string;
  cidrBlock: string;
  region: string;
};

export function Ec2VpcDetails({
  instanceTenancy,
  isDefault,
  state,
  dhcpOptionsId,
  cidrBlock,
  region,
}: Props) {
  return (
    <CollapsibleBox title="Details">
      <DefaultEc2GridWrapper>
        <Item label="Tenancy" value={instanceTenancy} />
        <Item label="Default VPC" value={isDefault ? "Yes" : "No"} />
        <Item label="State" value={capitalize(state)} />
        <Item label="DHCP option set" value={dhcpOptionsId} />
        <Item label="IPv4 CIDR" value={cidrBlock} />
        <Spacer />
        <Item label="Region" value={region} />
      </DefaultEc2GridWrapper>
    </CollapsibleBox>
  );
}
