/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { Button, Checkbox, Label, Spacer, theme } from "@nordcloud/gnui";
import {
  ReservationsAndCommitmentsCoverageServiceUsageType as ServiceUsageType,
  ReservationsAndCommitmentsCoverageOfferingType as OfferingType,
  ReservationsAndCommitmentsLookbackPeriod as TimePeriod,
  ReservationsAndCommitmentsFilters,
  ReservationsProvider,
} from "~/generated/graphql";
import { Fieldset, FiltersFooter } from "~/components";
import { ResourceLabel } from "../../constants";
import { useReservationsSidebarFilters, useSetFilters } from "../../hooks";
import { CommonFilters } from "./CommonFilters";

type Props = {
  filters: ReservationsAndCommitmentsFilters;
  serviceUsageType: ServiceUsageType;
  timePeriod: TimePeriod;
  offeringType: OfferingType;
  setFilters: (filter: ReservationsAndCommitmentsFilters) => void;
  onApply: () => void;
  allowSavingFilters: boolean;
};

export function AwsReservationsSidebarFilters({
  filters,
  serviceUsageType,
  offeringType,
  timePeriod,
  onApply,
  setFilters,
}: Props) {
  const provider = ReservationsProvider.Aws;
  const { operatingSystems, regionOptions, instanceFamilies } =
    useReservationsSidebarFilters({
      offeringType,
      serviceUsageType,
      timePeriod,
      provider,
    });
  const { commit, reset, hasEmptyFilters } = useSetFilters({
    filters,
    onApply,
  });

  const [platformData, setPlatformData] = React.useState([false, false]);
  const changePlatform = (platform: string) => {
    const index = platform === "Linux" ? 0 : 1;
    const data = platformData;
    data[index] = !platformData[index];
    setPlatformData(data);
    const filterData = filters?.platform ?? [];
    const selectedIndex = filterData.find((x) => x === platform);
    if (data[index]) {
      if (selectedIndex) {
        const platformOptions = filterData.filter((x) => x !== platform);
        return setFilters({
          ...filters,
          platform: platformOptions,
        });
      }

      const operatingSystem = [...filterData, platform];
      setFilters({
        ...filters,
        platform: operatingSystem,
      });
    }
  };

  const serviceTypeTitle =
    serviceUsageType === ServiceUsageType.Compute
      ? "Operating System Family"
      : "Database Type";

  return (
    <>
      <Fieldset>
        <Label name={serviceTypeTitle} />
        {operatingSystems.map((operatingSystemsData) => (
          <Checkbox
            key={operatingSystemsData.id}
            id={operatingSystemsData.id}
            labelText={operatingSystemsData.label}
            checked={(filters?.platform ?? []).includes(
              operatingSystemsData.value
            )}
            onChange={() => {
              changePlatform(operatingSystemsData.value);
            }}
          />
        ))}
      </Fieldset>
      <CommonFilters
        showInstanceFamilyFilter
        familyLabel={ResourceLabel.InstanceFamily}
        instanceFamilies={instanceFamilies}
        regionOptions={regionOptions}
        filters={filters}
        setFilters={setFilters}
      />
      <Spacer height={theme.spacing.spacing04} />
      <FiltersFooter>
        <Button
          disabled={hasEmptyFilters}
          mr={theme.spacing.spacing02}
          onClick={commit}
        >
          Apply filters
        </Button>
        <Button severity="low" disabled={hasEmptyFilters} onClick={reset}>
          Clear filters &amp; reload
        </Button>
      </FiltersFooter>
    </>
  );
}
