/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { FlexContainer, theme } from "@nordcloud/gnui";
import {
  ChartType,
  LinkParameters,
  TableData,
} from "~/components/Charts/CostAnalysis/types";
import { DataContainer, DataItem } from "~/components/Data";
import { useCurrency } from "~/hooks";
import { formatMoney } from "~/tools";
import { TotalCostItems } from "./TotalCostItems";

type Props = {
  chartTypeValue: ChartType;
  tableData: TableData[];
  total: number;
  linkParameters?: LinkParameters;
};

export function TotalCosts({
  chartTypeValue,
  tableData,
  total,
  linkParameters,
}: Props) {
  const { currency } = useCurrency();

  const showTotalCostPerGroup = [
    ChartType.COST_PER_ENVIRONMENT,
    ChartType.COST_PER_CATEGORY,
    ChartType.COST_PER_APPLICATION,
  ].includes(chartTypeValue);

  return (
    <>
      <FlexContainer
        css={{
          borderTop: `1px solid ${theme.color.border.border01}`,
          paddingTop: theme.spacing.spacing04,
        }}
      >
        <DataContainer marginBottom={theme.spacing.spacing02}>
          <DataItem
            grow={false}
            weight="medium"
            size="lg"
            label="Total Cost"
            value={formatMoney(total ?? 0, currency)}
            padding="0"
          />
          <When condition={showTotalCostPerGroup}>
            <TotalCostItems
              data={tableData}
              chartTypeValue={chartTypeValue}
              linkParameters={linkParameters}
            />
          </When>
        </DataContainer>
      </FlexContainer>
    </>
  );
}
