import {
  ReservationsAndCommitmentsLookbackPeriod,
  ReservationsProvider,
  ReservationsRecommendationPaymentOption,
  ReservationsRecommendationTerm,
  useReservationsActiveTabsQuery,
} from "~/generated/graphql";
import { ReservationsTabSection } from "../types";
import { getReservationsTabs } from "../utils";

export function useCheckActiveAccount(provider: ReservationsProvider) {
  const { data, loading } = useReservationsActiveTabsQuery({
    variables: {
      paymentOption: ReservationsRecommendationPaymentOption.PartialUpfront,
      term: ReservationsRecommendationTerm.OneYear,
      lookbackPeriod: ReservationsAndCommitmentsLookbackPeriod.ThirtyDays,
      provider,
    },
    fetchPolicy: "cache-and-network",
  });

  const tabs = getReservationsTabs(provider);

  const tabsSelection = tabs.map((tab): ReservationsTabSection => {
    const currentTab = data?.reservationsActiveTabs?.tabs?.find(
      ({ serviceUsageType }) => serviceUsageType === tab.serviceType
    );

    const isActiveTab = currentTab?.isActive ?? false;

    return { ...tab, isActiveTab };
  });

  return {
    loading,
    tabsSelection,
  };
}
