/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import dayjs from "dayjs";
import { dateFormat } from "~/constants";
import { useBillingPeriod } from "~/services/customers";

export function useIsDateInCurrentBillingPeriod(date?: string) {
  const { isCurrentBillingPeriod } = useBillingPeriod();

  if (date == null) {
    return false;
  }

  const billingPeriodMonth = dayjs()
    .subtract(isCurrentBillingPeriod ? 0 : 1, "month")
    .month();
  const inputMonth = dayjs(date, dateFormat.dayMonthYear).month();

  return billingPeriodMonth === inputMonth;
}
