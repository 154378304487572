/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import dayjs from "dayjs";
import { When } from "react-if";
import styled from "styled-components";
import {
  FlexContainer,
  MultipleSelect,
  SelectButton,
  Spacer,
  Spinner,
  Text,
  theme,
} from "@nordcloud/gnui";
import { dateFormat } from "~/constants";
import {
  generateNumericArray,
  getFirstItem,
  getLastItem,
  isNotEmpty,
  isNotNil,
  sort,
} from "~/tools";

type MonthSelectorProps = {
  onChange: (months: string[]) => void;
  blocked: string[][];
  thisYear: boolean;
  value: string[];
  loading: boolean;
  disabled: boolean;
  year: string;
};

export function MonthSelector({
  onChange,
  blocked,
  thisYear,
  value,
  loading,
  disabled,
  year,
}: MonthSelectorProps) {
  const startOfYear = dayjs().startOf("year");
  const thisMonth = dayjs().startOf("month");

  const months = React.useMemo(
    () =>
      generateNumericArray(12).map((_, index) =>
        startOfYear.add(index, "month").format(dateFormat.monthShort)
      ),
    []
  );

  const unavailableThisYear = React.useMemo(
    () =>
      thisYear
        ? generateNumericArray(12)
            .map((_, index) => {
              const curr = startOfYear.add(index, "month");

              if (curr.isBefore(thisMonth)) {
                return curr.format(dateFormat.monthShort);
              }

              return undefined;
            })
            .filter(isNotNil)
        : [],
    [thisYear]
  );

  const toggle = (month: string) => {
    const hasMonth = value.includes(month);

    if (hasMonth) {
      onChange(
        value
          .filter((mon) => month !== mon)
          .filter((mon) =>
            dayjs(mon, dateFormat.monthShort).isBefore(
              dayjs(month, dateFormat.monthShort)
            )
          )
      );
    } else {
      const { missingMonths, first, last } = getMissingMonths([
        ...value,
        month,
      ]);

      onChange(first === last ? [first] : [first, ...missingMonths, last]);
    }
  };

  const getClassName = (month: string) => {
    const isActive = value.includes(month);
    const alreadyChosen = blocked.flatMap((period) => {
      const { missingMonths, first, last } = getMissingMonths(period);
      return [...new Set([...missingMonths, first, last])];
    });
    const unavailable = [...alreadyChosen, ...unavailableThisYear];
    const isDisabled = unavailable.includes(month);

    const [start, end] = [getStartDate(true), getEndDate(true)];

    const isArtificiallyDisabled = [
      ...findPreviousPeriodDisabled(unavailable, start),
      ...findNextPeriodDisabled(unavailable, end),
    ].includes(month);

    if (isDisabled || loading || isArtificiallyDisabled) {
      return "disabled";
    }

    if (isActive) {
      return "active";
    }

    return "";
  };

  const getStartDate = (raw?: boolean) => {
    const start = getFirstItem(value);

    return raw
      ? start
      : dayjs(`${start} ${year}`, dateFormat.monthYearShortNoComma)
          .startOf("month")
          .format(dateFormat.shortDate);
  };

  const getEndDate = (raw?: boolean) => {
    const end = value.length === 1 ? getFirstItem(value) : getLastItem(value);

    return raw
      ? end
      : dayjs(`${end} ${year}`, dateFormat.monthYearShortNoComma)
          .endOf("month")
          .format(dateFormat.shortDate);
  };

  React.useEffect(() => {
    if (!disabled) {
      onChange([]);
    }
    // we don't want to wrap onChange into useCallback and we don't want
    // this effect to be triggered when onChange changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thisYear]);

  return (
    <FlexContainer direction="column" alignItems="start">
      <When condition={!disabled}>
        <FlexContainer columnGap={theme.spacing.spacing01}>
          <MultipleSelect size="small">
            {months.map((month, index) => (
              <SmallSelectButton
                key={`${month}-${index}`}
                name={month}
                value={index.toString()}
                labelText={month}
                className={getClassName(month)}
                onClick={() => toggle(month)}
              />
            ))}
          </MultipleSelect>
          <When condition={loading}>
            <Spinner size="sm" />
          </When>
        </FlexContainer>
      </When>
      <When condition={!disabled && isNotEmpty(value)}>
        <Spacer height={theme.spacing.spacing05} />
      </When>
      <When condition={isNotEmpty(value)}>
        <FlexContainer alignItems="start" columnGap={theme.spacing.spacing06}>
          <FlexContainer columnGap={theme.spacing.spacing03}>
            <Text size="sm" mb={0}>
              Start Date
            </Text>
            <Text mb={0}>{getStartDate()}</Text>
          </FlexContainer>
          <FlexContainer columnGap={theme.spacing.spacing03}>
            <Text size="sm" mb={0}>
              End Date
            </Text>
            <Text mb={0}>{getEndDate()}</Text>
          </FlexContainer>
        </FlexContainer>
      </When>
    </FlexContainer>
  );
}

const SmallSelectButton = styled(SelectButton)`
  font-size: ${theme.fontSizes.xs};

  &.disabled {
    opacity: 0.3;
    background-color: ${theme.color.interactive.disabled};
    color: ${theme.color.text.text03};
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export function getMissingMonths(months: string[]) {
  const startOfYear = dayjs().startOf("year");
  const allMonths = Array.from({ length: 12 }, (_, index) =>
    startOfYear.add(index, "month").format(dateFormat.monthShort)
  );

  const sorted = sort(months, (a, b) =>
    dayjs(a, dateFormat.monthShort).isBefore(dayjs(b, dateFormat.monthShort))
      ? -1
      : 1
  );
  const [first, last] = [getFirstItem(sorted), getLastItem(sorted)];
  const missingMonths = allMonths.filter((mon) => {
    const dayjsMonth = dayjs(mon, dateFormat.monthShort);
    return dayjsMonth.isBetween(
      dayjs(first, dateFormat.monthShort),
      dayjs(last, dateFormat.monthShort)
    );
  });

  return { missingMonths, first, last };
}

function findPreviousPeriodDisabled(unavailable: string[], start: string) {
  const JANUARY = dayjs().startOf("year").format(dateFormat.monthShort);
  const monthsBefore = unavailable.filter((mon) =>
    dayjs(mon, dateFormat.monthShort).isBefore(
      dayjs(start, dateFormat.monthShort)
    )
  );
  const lastOccurrence = getLastItem(monthsBefore);
  const { missingMonths, first, last } = getMissingMonths([
    JANUARY,
    lastOccurrence,
  ]);
  return lastOccurrence ? [first, last, ...missingMonths] : [];
}

function findNextPeriodDisabled(unavailable: string[], end: string) {
  const DECEMBER = dayjs().endOf("year").format(dateFormat.monthShort);
  const monthsAfter = unavailable.filter((mon) =>
    dayjs(mon, dateFormat.monthShort).isAfter(dayjs(end, dateFormat.monthShort))
  );
  const firstOccurrence = getFirstItem(monthsAfter);
  const { missingMonths, first, last } = getMissingMonths([
    firstOccurrence,
    DECEMBER,
  ]);
  return firstOccurrence ? [first, last, ...missingMonths] : [];
}
