/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  GetDiscoveryRulesDocument,
  GetDiscoveryRulesQuery,
  useAddDiscoveryRuleMutation,
  useRemoveDiscoveryRuleMutation,
  useUpdateDiscoveryRuleMutation,
  AddDiscoveryRuleMutationVariables,
  GetDiscoveryRulesQueryVariables,
  RemoveDiscoveryRuleMutationVariables,
  UpdateDiscoveryRuleMutationVariables,
} from "~/generated/graphql";
import { handleError, noop } from "~/tools";

export function useRemoveDiscoveryRule(
  options: Parameters<typeof useRemoveDiscoveryRuleMutation>[0]
) {
  const [
    removeDiscoveryRuleMutation,
    {
      client: { cache },
      ...state
    },
  ] = useRemoveDiscoveryRuleMutation(options);

  const removeDiscoveryRule = async (
    variables: RemoveDiscoveryRuleMutationVariables & { environmentId: string },
    onSuccess: () => void = noop
  ) => {
    try {
      const { data, errors } = await removeDiscoveryRuleMutation({ variables });

      if (data?.removeDiscoveryRule) {
        cache.updateQuery<
          GetDiscoveryRulesQuery,
          GetDiscoveryRulesQueryVariables
        >(
          {
            query: GetDiscoveryRulesDocument,
            variables: { envId: variables.environmentId },
          },
          (discoveryRulesData) => ({
            discoveryRules: (discoveryRulesData?.discoveryRules ?? []).filter(
              (discoveryRule) => discoveryRule?.id !== variables.id
            ),
          })
        );
      }

      if (!errors) {
        onSuccess();
      }
    } catch (error) {
      handleError(error);
    }
  };

  return [removeDiscoveryRule, state] as const;
}

export function useAddDiscoveryRule(
  options: Parameters<typeof useAddDiscoveryRuleMutation>[0]
) {
  const [
    addDiscoveryRuleMutation,
    {
      client: { cache },
      ...state
    },
  ] = useAddDiscoveryRuleMutation(options);

  const addDiscoveryRule = async (
    variables: AddDiscoveryRuleMutationVariables,
    onSuccess: () => void = noop
  ) => {
    try {
      const { data, errors } = await addDiscoveryRuleMutation({ variables });

      const addedDiscoveryRule = data?.addDiscoveryRuleV2;

      if (addedDiscoveryRule != null) {
        cache.updateQuery<
          GetDiscoveryRulesQuery,
          GetDiscoveryRulesQueryVariables
        >(
          {
            query: GetDiscoveryRulesDocument,
            variables: { envId: variables.input.environmentId },
          },
          (discoveryRulesData) => ({
            discoveryRules: [
              addedDiscoveryRule,
              ...(discoveryRulesData?.discoveryRules ?? []),
            ],
          })
        );
      }

      if (!errors) {
        onSuccess();
      }
    } catch (error) {
      handleError(error);
    }
  };

  return [addDiscoveryRule, state] as const;
}

export function useUpdateDiscoveryRule(
  options: Parameters<typeof useUpdateDiscoveryRuleMutation>[0]
) {
  const [updateDiscoveryRuleMutation, { client: ignored, ...state }] =
    useUpdateDiscoveryRuleMutation(options);

  const updateDiscoveryRule = async (
    variables: UpdateDiscoveryRuleMutationVariables,
    onSuccess: () => void = noop
  ) => {
    try {
      const result = await updateDiscoveryRuleMutation({ variables });

      if (!result.errors) {
        onSuccess();
      }
    } catch (error) {
      handleError(error);
    }
  };

  return [updateDiscoveryRule, state] as const;
}
