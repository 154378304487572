/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { If, Then, Else } from "react-if";
import styled from "styled-components";
import { Text, Tooltip } from "@nordcloud/gnui";

type Props = {
  isDatabaseTab: boolean;
  isMultiRegionParent: boolean;
  region: string;
};

export function RegionText({
  isDatabaseTab,
  isMultiRegionParent,
  region,
}: Props) {
  const regionList = region.split(", ");
  const showMultiRegionDatabase =
    isDatabaseTab && isMultiRegionParent && regionList.length > 1;
  const text = regionList.length > 1 ? regionList.join(" ") : region;

  return (
    <If condition={showMultiRegionDatabase}>
      <Then>
        <TooltipWrapper>
          <Tooltip caption={text}>
            <Text>{regionList.length} Regions</Text>
          </Tooltip>
        </TooltipWrapper>
      </Then>
      <Else>
        <Text tag="span" weight="medium">
          {text}
        </Text>
      </Else>
    </If>
  );
}

const TooltipWrapper = styled.span`
  position: relative;
`;
