/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ACTION, Can } from "~/services/auth";

export function ProtectedBy({
  action,
  children,
}: {
  action?: ACTION;
  children: JSX.Element;
}) {
  if (action != null) {
    return <Can action={action}>{children}</Can>;
  }

  return children;
}
