/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useFeature } from "~/hooks";

export function FeatureFlag({
  featureFlag,
  children,
}: {
  featureFlag?: string;
  children: JSX.Element;
}) {
  const allowed = useFeature(featureFlag ?? "");

  if (featureFlag != null && allowed === false) {
    return null;
  }

  return children;
}
