/**
 * Copyright 2021-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Provider } from "~/generated/graphql";
import { Budget, GridItemWrapper, OriginalCost } from "./components";
import { CustomComponents, LinkedRecords } from "./types";

type CustomComponentInstanceProps = {
  currency: string;
  budget: number | null;
  originalCost: number;
  linkedRecords: LinkedRecords;
  provider?: Provider;
  scrollToBudget?: () => void;
};

export function getCustomComponentInstance(
  {
    currency,
    budget,
    originalCost,
    linkedRecords,
    provider,
    scrollToBudget,
  }: CustomComponentInstanceProps,
  customComponent?: CustomComponents
) {
  switch (customComponent) {
    case CustomComponents.BUDGET:
      return (
        <GridItemWrapper title="Monthly Budget">
          <Budget
            value={budget}
            currency={currency}
            scrollToBudget={scrollToBudget}
          />
        </GridItemWrapper>
      );
    case CustomComponents.ORIGINAL_COST:
      return (
        <GridItemWrapper title={originalCost === 0 ? "" : "Original Cost"}>
          <OriginalCost
            originalCost={originalCost}
            currency={currency}
            linkedRecords={linkedRecords}
            provider={provider}
          />
        </GridItemWrapper>
      );
    default:
      return "";
  }
}
