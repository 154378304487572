/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  FlexContainer,
  MultipleSelect,
  SelectButton,
  Text,
  theme,
} from "@nordcloud/gnui";
import { Granularity } from "~/components/Charts/CostAnalysis/types";

type Props = {
  granularity: Granularity;
  onChange: (granularity: Granularity) => void;
  disableDailyView: boolean;
  disableSelector?: boolean;
};

export function GranularitySelector({
  granularity,
  onChange,
  disableDailyView,
  disableSelector = false,
}: Props) {
  const handleClick = (selectedGranularity: Granularity) => {
    onChange(selectedGranularity);
  };

  return (
    <FlexContainer marginRight={theme.spacing.spacing07}>
      <Text
        size="sm"
        mb="0"
        mr={theme.spacing.spacing04}
        weight="medium"
        color={theme.color.text.text02}
      >
        Granularity:
      </Text>
      <MultipleSelect size="small">
        <SelectButton
          name="daily-granularity"
          value="1"
          labelText="Daily"
          isActive={granularity === Granularity.DAYS}
          disabled={disableDailyView || disableSelector}
          style={{
            padding: `${theme.spacing.spacing02} ${theme.spacing.spacing06}`,
            minWidth: "6rem",
          }}
          onClick={() => handleClick(Granularity.DAYS)}
        />
        <SelectButton
          name="monthly-granularity"
          value="2"
          labelText="Monthly"
          isActive={granularity === Granularity.MONTHS}
          disabled={disableSelector}
          style={{
            minWidth: "6rem",
          }}
          onClick={() => handleClick(Granularity.MONTHS)}
        />
      </MultipleSelect>
    </FlexContainer>
  );
}
