/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { Maybe } from "~/tools";

export type CostAllocationTimePoint = {
  date: string;
  value?: number | string;
  isEstimated?: boolean;
  isEstimationPadding?: boolean;
};

export type ApplicationV2 = {
  name: string;
  id: string;
  description?: Maybe<string>;
  owner?: Maybe<{
    id: string;
    name: string;
    email: string;
  }>;
  contacts?: Maybe<
    ({
      id: string;
      name: string;
      email: string;
    } | null)[]
  >;
  resourceCount: number;
  budgetYearly: {
    id: string;
    budgetByMonth: string[];
  };
  cloudWasteAggregate?: Maybe<{
    cost?: Maybe<string>;
  }>;
  cost?: Maybe<{
    currentMonth: string;
    forecast?: Maybe<string>;
  }>;
  environmentsV2?: Maybe<{
    count?: Maybe<number>;
  }>;
  finOpsOptimisation: {
    budgetAdded: boolean;
    contactPersonAdded: boolean;
    environmentsAttachedToOUs: boolean;
    environmentsTypesDefined: boolean;
    optimisationLevel: string;
    ownerAdded: boolean;
    savingSuggestions: {
      savingSuggestionsUtilised: boolean;
      savingSuggestionsValue: string;
    };
  };
};

export type MappedApplicationV2 = {
  id: string;
  name: string;
  resourceCount: number;
  budget: string;
  cost: string;
  forecast: string;
  savingsSuggestion: string;
  environmentsCount: number;
  hasSavingsSuggestion: boolean;
  description: string;
  finOpsOptimisation: OptimisationValues;
  contacts: {
    id: string;
    name: string;
    email: string;
  }[];
  owner:
    | {
        id: string;
        name: string;
        email: string;
      }
    | undefined;
};

export type MappedEnvironmentV2 = {
  id: string;
  name: string;
  savingsSuggestion: string;
  cost: string;
  forecast: string;
  budget: string;
  orgUnits: MappedOrgUnitV2[];
};

export type MappedOrgUnitV2 = {
  name: string;
  id: string;
  parentId: string;
  envName: string;
  color: Maybe<string>;
};

export type OptimisationValues = {
  optimisationLevel: OptimisationLevel;
  contactPersonAdded: boolean;
  ownerAdded: boolean;
  budgetAdded: boolean;
  environmentsAttachedToOUs: boolean;
  environmentsTypesDefined: boolean;
  savingSuggestions: {
    savingSuggestionsUtilised: boolean;
    savingSuggestionsValue: string;
  };
};

export const enum OptimisationLevel {
  NEEDS_OPTIMISATION = "NEEDS_OPTIMISATION",
  PARTIALLY_OPTIMISED = "PARTIALLY_OPTIMISED",
  MODERATE_OPTIMISATION = "MODERATE_OPTIMISATION",
  FULLY_OPTIMISED = "FULLY_OPTIMISED",
}
