/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { When } from "react-if";
import {
  Button,
  Description,
  FlexContainer,
  Input,
  InputPassword,
  Spinner,
  Text,
  theme,
} from "@nordcloud/gnui";
import { UpdateTurbonomicIntegrationPluginInput } from "~/generated/graphql";
import { mapInputStatus, Maybe, noop } from "~/tools";
import { getIntegrationPluginSchema } from "../validators";

type EditModeContentsProps = {
  propertyName: string;
  value?: Maybe<string>;
  isPassword?: boolean;
  isLoading: boolean;
  toggleEditMode?: (propertyName: string) => void;
  setIsEditMode: (editMode: boolean) => void;
  submitForm: (data: UpdateTurbonomicIntegrationPluginInput) => void;
};

export function EditModeContents({
  propertyName,
  value,
  isPassword,
  isLoading,
  toggleEditMode = noop,
  setIsEditMode,
  submitForm,
}: EditModeContentsProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(getIntegrationPluginSchema()),
  });

  const status = errors[propertyName] ? "error" : undefined;
  const input = isPassword ? (
    <InputPassword
      showPasswordOnInit={false}
      {...register(propertyName)}
      status={mapInputStatus(status)}
    />
  ) : (
    <Input
      defaultValue={value ?? ""}
      {...register(propertyName)}
      status={mapInputStatus(status)}
      data-testid="edit-input"
      disabled={isLoading}
    />
  );

  return (
    <FlexContainer alignItems="center" direction="column">
      <form css={{ display: "flex" }} onSubmit={handleSubmit(submitForm)}>
        {input}
        <FlexContainer>
          <When condition={!isLoading}>
            <Button
              ml={theme.spacing.spacing02}
              type="submit"
              icon="save"
              size="md"
              severity="low"
              disabled={isLoading}
            >
              Save
            </Button>
            <Button
              ml={theme.spacing.spacing02}
              icon="close"
              type="button"
              size="md"
              severity="low"
              disabled={isLoading}
              onClick={() => {
                setIsEditMode(false);
                toggleEditMode(propertyName);
                reset();
              }}
            >
              Cancel
            </Button>
          </When>

          <When condition={isLoading}>
            <FlexContainer
              margin={`0 ${theme.spacing.spacing02}`}
              gap={theme.spacing.spacing02}
            >
              <Spinner size="sm" />
              <Text m={0}>Loading...</Text>
            </FlexContainer>
          </When>
        </FlexContainer>
      </form>
      <When condition={errors[propertyName]}>
        <Description>{errors[propertyName]?.message}</Description>
      </When>
    </FlexContainer>
  );
}
