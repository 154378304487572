/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { showSuccess } from "~/services/toast";
import { isNotNil } from "~/tools";
import { handleError, toInputError } from "~/tools/errors";
import { generateActionSuccessText } from "~/tools/text";

type UserErrorParser<T> = (result: T) => string | undefined;

type UpdateInput<T> =
  | {
      mutation: () => Promise<T>;
      identifier: string;
      successAction: string;
      successText?: never;
      userErrorParser?: UserErrorParser<T>;
    }
  | {
      mutation: () => Promise<T>;
      identifier?: never;
      successAction?: never;
      successText: string;
      userErrorParser?: UserErrorParser<T>;
    };

const runMutation = async <T>({
  identifier,
  mutation,
  successAction,
  successText = generateActionSuccessText(identifier ?? "")()(
    successAction ?? ""
  )(),
  userErrorParser,
}: UpdateInput<T>): Promise<ReturnType<typeof mutation> | undefined> => {
  try {
    const result = await mutation();

    if (isNotNil(result) && isNotNil(userErrorParser)) {
      const userError = userErrorParser(result);

      if (isNotNil(userError)) {
        throw new Error(userError);
      }
    }

    showSuccess(successText);
    return result;
  } catch (error) {
    handleError(toInputError(error));
  }
};

export function useMutationHandler() {
  return { runMutation };
}
