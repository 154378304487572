/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { PropsWithChildren } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { generatePath, Link } from "react-router-dom";
import {
  Button,
  Checkbox,
  FlexContainer,
  SVGIcon,
  Table,
  Text,
  theme,
} from "@nordcloud/gnui";
import { DriftResolveStatus } from "~/generated/graphql";
import { SUCCESS_TEXT } from "~/constants";
import { ROUTES } from "~/routing/routes";
import { showSimpleInfo } from "~/services/toast";
import { getProviderIconName, trimTextBeginning } from "~/tools";
import { DriftItem } from "../types";

type DriftRowProps = {
  drift: DriftItem;
  isSelected: boolean;
  disabled: boolean;
  onDriftIdToggle: (id: string) => void;
  onDriftStatusChanged: (status: DriftResolveStatus, id: string[]) => void;
};

export function DriftRow({
  drift,
  isSelected,
  disabled,
  onDriftIdToggle,
  onDriftStatusChanged,
}: DriftRowProps) {
  return (
    <Table.tr>
      <Table.td css={{ verticalAlign: "middle" }}>
        <Checkbox
          withoutLabel
          checked={isSelected}
          onChange={() => onDriftIdToggle(drift.id)}
        />
      </Table.td>
      <Table.td css={{ verticalAlign: "middle" }}>
        <SVGIcon name={getProviderIconName(drift.provider)} size="lg" />
      </Table.td>
      <Table.td>
        <section>
          <FlexContainer columnGap={theme.spacing.spacing02}>
            <Link to={generatePath(ROUTES.resource, { nordcloudId: drift.id })}>
              <Text
                tag="span"
                color={theme.color.interactive.link}
                weight="medium"
              >
                {trimTextBeginning(drift.name, 30)}
              </Text>
            </Link>
            <CopyIcon contentToCopy={drift.name} />
          </FlexContainer>
          <section>
            <Text tag="span" size="sm" weight="medium">
              {drift.categoryName}&nbsp;
            </Text>
            <Text tag="span" size="sm">
              | {drift.providerType} | {drift.region}
            </Text>
          </section>
        </section>
      </Table.td>
      <Table.td>
        <FlexContainer direction="column" alignItems="end">
          <ApplicationLinkWrap
            applicationId={drift.currentEnvironment?.application.nid}
          >
            <Text tag="span" color={theme.color.interactive.link}>
              {drift.currentEnvironment?.application.name}
            </Text>
          </ApplicationLinkWrap>
          <EnvironmentLinkWrap
            applicationId={drift.currentEnvironment?.application.nid}
            environmentId={drift.currentEnvironment?.nid}
          >
            <Text tag="span" size="sm" color={theme.color.interactive.link}>
              {drift.currentEnvironment?.name}
            </Text>
          </EnvironmentLinkWrap>
        </FlexContainer>
      </Table.td>
      <Table.td css={{ verticalAlign: "middle" }}>
        <SVGIcon name="arrowRight" size="md" />
      </Table.td>
      <Table.td>
        <FlexContainer direction="column" alignItems="start">
          <ApplicationLinkWrap
            applicationId={drift.targetEnvironment?.application.nid}
          >
            <Text tag="span" color={theme.color.interactive.link}>
              {drift.targetEnvironment?.application.name}
            </Text>
          </ApplicationLinkWrap>
          <EnvironmentLinkWrap
            applicationId={drift.targetEnvironment?.application.nid}
            environmentId={drift.targetEnvironment?.nid}
          >
            <Text tag="span" size="sm" color={theme.color.interactive.link}>
              {drift.targetEnvironment?.name}
            </Text>
          </EnvironmentLinkWrap>
        </FlexContainer>
      </Table.td>
      <Table.td css={{ verticalAlign: "middle" }}>
        <FlexContainer columnGap={theme.spacing.spacing04}>
          <Button
            icon="close"
            severity="low"
            size="md"
            type="button"
            disabled={disabled}
            onClick={() =>
              onDriftStatusChanged(DriftResolveStatus.Reject, [drift.id])
            }
          >
            Reject
          </Button>
          <Button
            icon="checkmark"
            severity="low"
            size="md"
            type="button"
            disabled={disabled}
            onClick={() =>
              onDriftStatusChanged(DriftResolveStatus.Approve, [drift.id])
            }
          >
            Approve
          </Button>
        </FlexContainer>
      </Table.td>
    </Table.tr>
  );
}

function isEmptyId(id: string | null | undefined) {
  return id === "" || id == null;
}

function ApplicationLinkWrap({
  applicationId,
  children,
}: PropsWithChildren<{ applicationId: string | undefined }>) {
  if (isEmptyId(applicationId)) {
    return <>{children}</>;
  }

  return (
    <Link
      to={generatePath(ROUTES.applications.details, {
        application: applicationId,
      })}
    >
      {children}
    </Link>
  );
}

function EnvironmentLinkWrap({
  applicationId,
  environmentId,
  children,
}: PropsWithChildren<{
  applicationId: string | undefined;
  environmentId: string | undefined;
}>) {
  if (isEmptyId(applicationId) || isEmptyId(environmentId)) {
    return <>{children}</>;
  }

  return (
    <Link
      to={generatePath(ROUTES.applications.environment, {
        application: applicationId,
        environment: environmentId,
      })}
    >
      {children}
    </Link>
  );
}

type CopyIconProps = {
  contentToCopy: string;
};

function CopyIcon({ contentToCopy }: CopyIconProps) {
  return (
    <CopyToClipboard
      text={contentToCopy}
      onCopy={() => {
        showSimpleInfo(SUCCESS_TEXT.copied);
      }}
    >
      <SVGIcon name="copy" size="sm" css={{ cursor: "pointer" }} />
    </CopyToClipboard>
  );
}
