/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloError } from "@apollo/client";
import { ParentSize } from "@visx/responsive";
import { TooltipData } from "@visx/xychart/lib/types/tooltip";
import styled from "styled-components";
import { Spacer, theme } from "@nordcloud/gnui";
import {
  ReservationsAndCommitmentsFilters,
  ReservationsAndCommitmentsCoverageServiceUsageType,
} from "~/generated/graphql";
import { ErrorWrap } from "~/components";
import { isNotEmpty } from "~/tools";
import { UsageAnalysisChart } from "../components";
import { TimePoint, TooltipCallbackData } from "../types";
import { AwsUsageAnalysisDetails } from "./AwsUsageAnalysisDetails";
import { useGetAwsLegendData } from "./hooks/useGetAwsLegendData";

type Props = {
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  filters: ReservationsAndCommitmentsFilters;
  graphLoading: boolean;
  timeSeries: TimePoint[];
  highestCost: number;
  utilisationChartError: ApolloError | undefined;
  averageCoverage: string;
  reservedInstaceCoverage: string;
  savingsPlanCoverage: string;
  savedCost: string;
  totalOnDemandCost: string;
  tooltipDetailCallback: (
    tooltipData: TooltipData<TimePoint>
  ) => TooltipCallbackData;
};

export function AwsUsageAnalysisGraph({
  serviceUsageType,
  filters,
  graphLoading,
  timeSeries,
  highestCost,
  utilisationChartError,
  averageCoverage,
  reservedInstaceCoverage,
  savingsPlanCoverage,
  savedCost,
  totalOnDemandCost,
  tooltipDetailCallback,
}: Props) {
  const showEc2Only = isNotEmpty([
    ...(filters?.platform ?? []),
    ...(filters?.instanceFamily ?? []),
  ]);

  const legendData = useGetAwsLegendData(serviceUsageType, showEc2Only);

  return (
    <ErrorWrap error={utilisationChartError}>
      <ParentSize>
        {(parent) => (
          <UsageAnalysisChart
            width={parent.width}
            height={280}
            loading={graphLoading}
            data={timeSeries}
            highestCost={highestCost}
            legendData={legendData}
            tooltipDetailCallback={tooltipDetailCallback}
            serviceUsageType={serviceUsageType}
          />
        )}
      </ParentSize>
      <StyledSpacer />
      <AwsUsageAnalysisDetails
        serviceUsageType={serviceUsageType}
        averageCoverage={averageCoverage}
        reservedInstaceCoverage={reservedInstaceCoverage}
        savingsPlanCoverage={savingsPlanCoverage}
        savedCost={savedCost}
        totalOnDemandCost={totalOnDemandCost}
      />
    </ErrorWrap>
  );
}

const StyledSpacer = styled(Spacer)`
  margin: 0 0 ${theme.spacing.spacing04} 0;
  border-bottom: 1px solid ${theme.color.border.border01};
`;
