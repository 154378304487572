/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useUpdateKpiTargetMutation } from "~/generated/graphql";
import { handleError, noop, toInputError } from "~/tools";
import { UpdateKPITargetInput } from "../types";

export function useUpdateKpiTarget() {
  const [updateKpi, { loading }] = useUpdateKpiTargetMutation();

  const updateTarget = async (
    targetId: string,
    input: UpdateKPITargetInput,
    onSuccess: () => void = noop
  ) => {
    try {
      await updateKpi({
        variables: {
          id: targetId,
          input,
        },
      });

      onSuccess();
    } catch (error) {
      handleError(toInputError(error));
    }
  };

  return { updateTarget, loading };
}
