/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { theme, FlexContainer, Button } from "@nordcloud/gnui";
import { ReservationsAndCommitmentsFilters } from "~/generated/graphql";

import { QueryStateFilter } from "~/hooks";
import { isNotEmpty, noop } from "~/tools";
import { FilterExpandableTag } from "./FilterExpandableTag";

type Props = {
  filters: QueryStateFilter<ReservationsAndCommitmentsFilters>[];
  onRemoveKey: (key: (keyof ReservationsAndCommitmentsFilters)[]) => void;
  onRemoveValue?: (key: string, tagIndexToRemove: number) => void;
  onClear?: () => void;
  allowSavingFilters: boolean;
};

export function ReservationsActiveFilters({
  filters,
  onRemoveKey: handleTagRemoveKey,
  onRemoveValue: handleTagRemoveValue,
  onClear = noop,
}: Props) {
  const showActions = isNotEmpty(filters);

  return (
    <FlexContainer justifyContent="space-between">
      <FlexContainer wrap="wrap">
        <span css={{ marginRight: theme.spacing.spacing02 }}>
          Applied filters:
        </span>
        {filters.map(([key, value]) => (
          <FilterExpandableTag
            key={key}
            keyToRemove={key}
            value={value}
            onRemoveKey={handleTagRemoveKey}
            onRemoveValue={handleTagRemoveValue}
          />
        ))}
      </FlexContainer>

      <When condition={showActions}>
        <FlexContainer columnGap={theme.spacing.spacing04}>
          <Button icon="filterClear" severity="low" onClick={onClear}>
            Clear Filters
          </Button>
        </FlexContainer>
      </When>
    </FlexContainer>
  );
}
