/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";

type Props = {
  sidebarOpened: boolean;
  setSidebarOpened: (state: boolean) => void;
};

export const MenuBarContext = React.createContext<Props | undefined>(undefined);
MenuBarContext.displayName = "MenuBarContext";
