/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ConnectionStatus } from "~/generated/graphql";

export function getStatusMessage(status?: string) {
  const state =
    status === ConnectionStatus.Connected ? "successful" : "unsuccessful";

  const connectionState = `Connection was ${state}`;

  return status
    ? connectionState
    : `${connectionState}, please double-check the URL`;
}
