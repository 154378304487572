/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ROUTES } from "~/routing/routes";
import { ACTIONS, Can } from "~/services/auth";
import { MenuLink } from "../components";
import { SubmenuTitle } from "./SubmenuTitle";

export function EmployeesSubmenu() {
  return (
    <>
      <SubmenuTitle title="Employees" />
      <ul>
        <Can action={ACTIONS.visitEmployees}>
          <li>
            <MenuLink to={ROUTES.settings.employees}>Contact persons</MenuLink>
          </li>
        </Can>
        <Can action={ACTIONS.visitUsersPermissions}>
          <li>
            <MenuLink to={ROUTES.settings.users}>
              Users and Permissions
            </MenuLink>
          </li>
        </Can>
        <Can action={ACTIONS.visitGlobalApiKeys}>
          <li>
            <MenuLink to={ROUTES.settings.globalApiKeys}>
              Global API Keys
            </MenuLink>
          </li>
        </Can>
      </ul>
    </>
  );
}
