/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Checkbox, FlexContainer, Text } from "@nordcloud/gnui";
import { SidebarItemWrapper } from "~/components";
import { useToggle } from "~/hooks";
import { SwitchWrap } from "../AppEnvSelector/styles";
import { StyledSVGIcon } from "../styles";
import { ApplicationOption, EnvironmentOption } from "../types";
import { EnvironmentsList } from "./EnvironmentsList";

type Props = {
  application: ApplicationOption;
  selectedEnvs: EnvironmentOption[];
  onAppCheck: (appNid: string) => void;
  onEnvCheck: (env: EnvironmentOption) => void;
  disabled?: boolean;
};

export function SelectableApplicationRow({
  application,
  onAppCheck,
  onEnvCheck,
  selectedEnvs,
  disabled = false,
}: Props) {
  const [isRowOpen, toggleRowOpen] = useToggle(false);
  const { environments, name, nid } = application;
  const envCount = environments.length;

  const hasEnvironments = envCount > 0;

  const isAppChecked =
    hasEnvironments &&
    environments.every((env) =>
      selectedEnvs.find((selectedEnv) => selectedEnv.nid === env.nid)
    );

  return (
    <>
      <FlexContainer>
        <StyledSVGIcon
          isOpen={isRowOpen}
          disabled={envCount === 0}
          name="chevronDown"
          data-testid="app-expander"
          onClick={toggleRowOpen}
        />
        <FlexContainer direction="column" alignItems="flex-start">
          <SidebarItemWrapper
            hideBottomBorder
            title={name}
            disabled={!hasEnvironments || disabled}
            onClick={() => onAppCheck(nid)}
          >
            <SwitchWrap>
              <Checkbox
                withoutLabel
                readOnly
                data-testid="app-filter-checkbox"
                checked={isAppChecked}
              />
            </SwitchWrap>
            <Text marginBottom="0">{name}</Text>
          </SidebarItemWrapper>
        </FlexContainer>
      </FlexContainer>
      <When condition={isRowOpen}>
        <EnvironmentsList
          application={application}
          selectedEnvs={selectedEnvs}
          isAppChecked={isAppChecked}
          disabled={disabled}
          onEnvCheck={onEnvCheck}
        />
      </When>
    </>
  );
}
