/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Row } from "@tanstack/react-table";
import { Else, If, Then, When } from "react-if";
import { Link } from "react-router-dom";
import { FlexContainer, Text, ExtendedTooltip } from "@nordcloud/gnui";
import { ChartType, LinkParameters, TableData } from "~/components";
import { CellColorIcon } from "~/components/Charts/CostAnalysis/components/styles";
import { generateLinks } from "~/components/Charts/CostAnalysis/helpers";
import { isNotEmpty, isNotNil } from "~/tools";

type Props = {
  row: Row<TableData>;
  showColorIcon: boolean;
  chartType: ChartType;
  color: string;
  linkParameters?: LinkParameters;
};

export function EstateTotalsTableCell({
  row,
  showColorIcon,
  chartType,
  color,
  linkParameters,
}: Props) {
  const link = generateLinks(chartType, linkParameters, row.original) ?? "";

  const isVisibleColorIcon =
    chartType !== ChartType.ACCUMULATED_COST &&
    showColorIcon &&
    row.depth === 0;

  const isLinkVisible = isNotNil(link) && isNotEmpty(link);

  return (
    <FlexContainer>
      <When condition={isVisibleColorIcon}>
        <CellColorIcon color={color} />
      </When>
      <ExtendedTooltip
        caption={row.original?.field}
        display="inline-grid"
        placement="bottom"
        position="start"
      >
        <Text
          mb="0"
          css={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <If condition={isLinkVisible}>
            <Then>
              <Link to={link}>{row.original?.field}</Link>
            </Then>
            <Else>{row.original?.field}</Else>
          </If>
        </Text>
      </ExtendedTooltip>
    </FlexContainer>
  );
}
