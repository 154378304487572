/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { Box, theme } from "@nordcloud/gnui";
import { Logo } from "../../components";

export const HeaderGrid = styled(Box)`
  display: grid;
  height: 4.5rem;
  width: 100%;
  align-items: center;
  column-gap: ${theme.spacing.spacing02};
  padding: ${theme.spacing.spacing01} ${theme.spacing.spacing04};
  grid-template-columns: auto 1fr repeat(5, auto);
  grid-template-areas: "logo search billingPeriod recalculation notifications customerSwitcher user";

  ${Logo} {
    grid-area: logo;
  }

  @media screen and (max-width: ${theme.breakpoints.xl}px) {
    grid-template-columns: auto 1fr auto minmax(auto, 50px) repeat(3, auto);
  }
`;

export const SearchWrapper = styled.div<{ isOpen: boolean }>`
  grid-area: search;
  display: flex;
  width: 100%;

  @media screen and (max-width: ${theme.breakpoints.xl}px) {
    justify-content: ${({ isOpen }) => (isOpen ? "initial" : "flex-end")};
    > * {
      flex-grow: ${({ isOpen }) => (isOpen ? 1 : 0)};
      align-self: stretch;
    }
  }
`;

export const BillingPeriodWrapper = styled.div`
  grid-area: billingPeriod;
`;

export const RecalculationWrapper = styled.div`
  grid-area: recalculation;
`;

export const NotificationsWrapper = styled.div`
  grid-area: notifications;
`;

export const UserWrapper = styled.div`
  grid-area: user;
`;

export const CustomerSwitch = styled.div`
  grid-area: customerSwitcher;

  p {
    max-width: 140px;
  }
`;
