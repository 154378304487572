/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ReactNode } from "react";
import { Unless } from "react-if";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  Button,
  ExtendedTooltip,
  FlexContainer,
  Message,
  Sidebar,
  Spacer,
  SVGIcon,
  Text,
  theme,
  useThemeSwitcher,
} from "@nordcloud/gnui";
import { useCurrency, useDisclosure } from "~/hooks";
import { formatMoney } from "~/tools";
import {
  getIcon,
  getIconColor,
  getLinkToResourcesWithWaste,
  getMessageStyle,
  getStatus,
  getTitle,
} from "./finOpsHelpers";
import { OptimisationValues } from "./types";

type Props = {
  applicationId: string;
  values: OptimisationValues;
};

export function FinOpsOptimisation({ applicationId, values }: Props) {
  const { currentTheme } = useThemeSwitcher();
  const { currency } = useCurrency();
  const { isOpen, close, toggle } = useDisclosure();

  const yearlySavings =
    12 * parseFloat(values.savingSuggestions.savingSuggestionsValue);

  const icon = getIcon(values.optimisationLevel, currentTheme);

  return (
    <>
      <ExtendedTooltip
        caption={getTitle(values.optimisationLevel)}
        status={getStatus(values.optimisationLevel)}
      >
        <Button
          type="button"
          icon={icon}
          severity="low"
          onClick={() => toggle()}
        />
      </ExtendedTooltip>
      <Sidebar
        title="Optimization Level"
        isOpen={isOpen}
        width="38rem"
        onClick={close}
      >
        <Message {...getMessageStyle(values.optimisationLevel)} image={icon}>
          {getTitle(values.optimisationLevel)}
        </Message>
        <Spacer height={theme.spacing.spacing06} />
        <OptimisationItemWrapper>
          <Unless
            condition={values.savingSuggestions.savingSuggestionsUtilised}
          >
            <OptimisationItem
              icon="danger"
              title={
                <Link to={getLinkToResourcesWithWaste(applicationId)}>
                  Act on Savings Suggestions
                </Link>
              }
              description={`to save ${formatMoney(
                values.savingSuggestions.savingSuggestionsValue,
                currency
              )} this month and ${formatMoney(
                yearlySavings,
                currency
              )} in the next 12 months!`}
            />
          </Unless>
          <Unless condition={values.budgetAdded}>
            <OptimisationItem
              icon="warning"
              title="Add Budget"
              description="to maintain cost control."
            />
          </Unless>
          <Unless condition={values.environmentsAttachedToOUs}>
            <OptimisationItem
              icon="warning"
              title="Attach Environments to Organizational Units"
              description="to gain visibility into cloud cost distribution."
            />
          </Unless>
          <Unless condition={values.ownerAdded}>
            <OptimisationItem
              icon="warning"
              title="Add Application Owner"
              description="to establish accountability for application."
            />
          </Unless>
          <Unless condition={values.environmentsTypesDefined}>
            <OptimisationItem
              icon="warning"
              title="Define environment types"
              description="to efficiently allocate and track costs."
            />
          </Unless>
          <Unless condition={values.contactPersonAdded}>
            <OptimisationItem
              icon="info"
              title="Add contact person"
              description="to establish accountability for application."
            />
          </Unless>
        </OptimisationItemWrapper>
      </Sidebar>
    </>
  );
}

type OptimisationItemProps = {
  icon: "danger" | "info" | "warning";
  title: ReactNode;
  description: string;
};

function OptimisationItem({ icon, title, description }: OptimisationItemProps) {
  return (
    <FlexContainer
      direction="column"
      alignItems="start"
      justifyContent="start"
      rowGap={theme.spacing.spacing02}
    >
      <FlexContainer columnGap={theme.spacing.spacing04} alignItems="center">
        <SVGIcon name={icon} color={getIconColor(icon)} />
        <Text mb="0" weight="medium">
          {title}
        </Text>
      </FlexContainer>
      <Text ml={theme.spacing.spacing08} color={theme.color.text.text02} mb="0">
        {description}
      </Text>
    </FlexContainer>
  );
}

const OptimisationItemWrapper = styled(FlexContainer)`
  flex-direction: column;
  align-items: start;
  row-gap: ${theme.spacing.spacing03};
  width: 100%;

  > div:not(:last-child) {
    padding-bottom: ${theme.spacing.spacing03};
    width: 100%;
    border-bottom: ${theme.borders.darkenGrey};
  }
`;
