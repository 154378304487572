/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { Button, Text, theme } from "@nordcloud/gnui";

export const SubmenuItem = styled.div`
  align-items: center;
  box-sizing: border-box;
  column-gap: ${theme.spacing.spacing02};
  display: flex;
  justify-content: start;
`;

type BusinessContextBoxProps = {
  color: string;
};

export const BusinessContextBox = styled.div<BusinessContextBoxProps>`
  background-color: ${({ color }) => color};
  height: 1rem;
  width: 0.5rem;
`;

export const ShowAll = styled(Text)`
  cursor: pointer;
  font-size: ${theme.fontSizes.sm};
  margin-bottom: 0;

  &:hover {
    color: ${theme.color.text.info};
  }
`;

export const BusinessContextButton = styled(Button)`
  width: 100%;
  margin-top: ${theme.spacing.spacing04};
  background-color: ${theme.color.interactive.secondary};
  color: ${theme.color.text.text01};

  &:hover {
    background-color: ${theme.color.interactive.secondaryHover};
    color: ${theme.color.text.text01};
  }

  &:active {
    background-color: ${theme.color.interactive.secondaryActive};
    color: ${theme.color.text.text01};
  }
`;
