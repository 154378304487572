/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Provider } from "~/tests/types";
import { Maybe } from "~/tools";
import { Costs } from "../../../types";

type EstateRecord = {
  name?: string;
  providerId?: string;
  providerType?: Maybe<string>;
  cloudAccountId?: Maybe<string>;
  co2e?: Maybe<Co2e>;
  savingsAmount?: Maybe<SavingsAmount>;
};

type Co2e = {
  currentMonth: string;
};

type SavingsAmount = {
  co2e: string;
  amount: string;
};

export function useDefaultEc2HeaderData(
  estateRecord: EstateRecord,
  costs: Costs
) {
  return {
    name: estateRecord.name ?? "",
    providerId: estateRecord.providerId ?? "",
    providerType: estateRecord.providerType ?? Provider.Aws,
    account: estateRecord.cloudAccountId ?? "",
    costs,
  };
}
