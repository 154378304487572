/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { Box, theme } from "@nordcloud/gnui";
import { Ec2Header } from "../components/Ec2Header";
import { Costs } from "../types";
import { LinkedResources } from "./types";

type Props = {
  name: string;
  providerId: string;
  providerType: string;
  account: string;
  costs: Costs;
  linkedResources?: LinkedResources;
};

export function Ec2InstanceHeader({
  name,
  providerId,
  providerType,
  account,
  costs,
  linkedResources,
}: Props) {
  return (
    <Box>
      <GridWrapper>
        <Ec2Header
          name={name}
          providerId={providerId}
          providerType={providerType}
          account={account}
          costs={costs}
          linkedResources={linkedResources}
        />
      </GridWrapper>
    </Box>
  );
}

const GridWrapper = styled.div`
  display: grid;
  width: 100%;
  row-gap: ${theme.spacing.spacing04};
  grid-template-rows: 0.75fr 1px 2fr;
  grid-template-columns: 60% 40%;
  grid-template-areas:
    "name costs"
    "line costs"
    "details costs";

  @media screen and (max-width: ${theme.breakpoints.lg}px) {
    height: 100%;
    grid-template-rows: 1fr 1px 2fr 3fr;
    grid-template-columns: 1fr;
    grid-template-areas:
      "name"
      "line"
      "details"
      "costs";
  }
`;
