/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

export const TreeWrap = styled.div<{ disabled?: boolean }>`
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;

export const CheckboxWrap = styled.div`
  margin: 0;
  width: 1.5rem;
`;

export const TreeItem = styled.div`
  padding: ${theme.spacing.spacing02};
`;

export const Indentation = styled.div<{ isRootElement?: boolean }>`
  margin-left: ${(props) =>
    props.isRootElement ? theme.spacing.spacing00 : theme.spacing.spacing07};
`;

export const MinusIcon = styled.div<{ isRootElement?: boolean }>`
  transform: rotate(90deg);
  margin-left: ${theme.spacing.spacing01};
  margin-right: ${theme.spacing.spacing01};
`;
