/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloError } from "@apollo/client";
import { theme } from "@nordcloud/gnui";
import { Maybe } from "~/tools";

export function getActivateWorkflowUserError(userError: UserError) {
  if (userError.code === UserErrorCode.ACTIVE_WORKFLOW_LIMIT_REACHED) {
    return (
      <>
        {userError.message}; please deactivate some of your workflows or contact
        &nbsp;
        <a
          css={{ color: theme.color.text.text04 }}
          href={userError.supportLink ?? ""}
        >
          IBM Multicloud Accelerator support
        </a>
        &nbsp; to increase the limit.
      </>
    );
  }

  return <>{userError.message}</>;
}

export function getAddWorkflowUserError(userError: UserError) {
  if (userError.code === UserErrorCode.ACTIVE_WORKFLOW_LIMIT_REACHED) {
    return (
      <>
        {userError.message}; Please deactivate some of your workflows (
        <a
          css={{ color: theme.color.text.text04 }}
          href={`${location.origin}/workflows`}
        >
          In workflow list
        </a>
        &nbsp; or Step 1 of Creating a Workflow) or contact&nbsp;
        <a
          css={{ color: theme.color.text.text04 }}
          href={userError.supportLink ?? ""}
        >
          IBM Multicloud Accelerator support
        </a>
        &nbsp; to increase the limit.
      </>
    );
  }

  return <>{userError.message}</>;
}

export function getCloneWorkflowUserErrors(error: ApolloError) {
  for (const err of error.graphQLErrors) {
    if (
      err.extensions?.errorCode ===
        UserErrorCode.ACTIVE_WORKFLOW_LIMIT_REACHED &&
      err.extensions?.supportLink
    ) {
      return getActivateWorkflowUserError({
        message: err.message,
        supportLink:
          typeof err.extensions?.supportLink === "string"
            ? err.extensions?.supportLink
            : "",
        code: err.extensions?.errorCode,
      });
    }
  }
}

type UserError = {
  code: string;
  message: string;
  supportLink?: Maybe<string>;
};

const enum UserErrorCode {
  ACTIVE_WORKFLOW_LIMIT_REACHED = "ACTIVE_WORKFLOW_LIMIT_REACHED",
}
