/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then } from "react-if";
import { Text } from "@nordcloud/gnui";
import { NoData } from "~/components";
import { includes } from "~/tools";
import { GCPServicesList, SERVICE_TYPE } from "./GCPServicesList";

type Detail = {
  value: string;
  label: string;
};

type Props = {
  details: Detail[];
  metaTags: Detail[];
  cloudAccountId: string;
};

export function GCPServicesTabContents({
  details,
  metaTags,
  cloudAccountId,
}: Props) {
  const showGCPServicesList = isGCPServicesListVisible(details);

  return (
    <If condition={showGCPServicesList}>
      <Then>
        <GCPServicesList
          projectResourceName={getProjectId(metaTags)}
          serviceType={getServiceType(details)}
          cloudAccountId={cloudAccountId}
        />
      </Then>
      <Else>
        <NoData message={<Text>There are no related resources.</Text>} />
      </Else>
    </If>
  );
}

function getProjectId(
  metaTags: {
    label: string;
    value: string;
  }[]
): string {
  return metaTags.find((item) => item.label.includes("projectID"))?.value ?? "";
}

function getServiceType(details: Detail[]): string {
  const serviceType = details.find(({ label }) => label.includes("GCP type"))
    ?.value;

  if (serviceType == null) {
    return "";
  }

  const [first, second] = serviceType.split("/");
  return second || first;
}

function isGCPServicesListVisible(details: Detail[]): boolean {
  return includes(Object.values(SERVICE_TYPE), getServiceType(details));
}
