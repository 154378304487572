/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { If, Then, Else } from "react-if";
import { Text, Box } from "@nordcloud/gnui";
import {
  WorkflowDataType,
  WorkflowDetailedResult,
  WorkflowEventErrorCode,
  WorkflowEventStatus,
  WorkflowTrigger,
} from "~/generated/graphql";
import { useAccess, ACTIONS } from "~/services/auth";
import { Maybe } from "~/tools";
import { KpiResults } from "./KpiResults";

type WorkflowEvent = {
  dataType: WorkflowDataType;
  detailedResult?: Maybe<WorkflowDetailedResult>;
  endTime: string;
  errorCode?: Maybe<WorkflowEventErrorCode>;
  result: string;
  startTime: string;
  status: WorkflowEventStatus;
  trigger: WorkflowTrigger;
  workflowDescription?: Maybe<string>;
  workflowName: string;
};

type Props = {
  historyEvent?: Maybe<WorkflowEvent>;
  isRunFailed: boolean;
};

export function WorkflowHistorySidebarResultRow({
  historyEvent,
  isRunFailed,
}: Props) {
  const { hasAccess } = useAccess(ACTIONS.viewWorkflowKpis);

  const kpiResult = historyEvent?.detailedResult?.kpiResult;

  return (
    <Box boxStyle="lightGrey">
      <If
        condition={
          historyEvent?.dataType === WorkflowDataType.Kpi && !isRunFailed
        }
      >
        <Then>
          <KpiResults hasAccess={hasAccess} kpiResult={kpiResult} />
        </Then>
        <Else>
          <Text>
            {getResultText(
              historyEvent?.result ?? "",
              historyEvent?.errorCode ?? ""
            )}
          </Text>
        </Else>
      </If>
    </Box>
  );
}

function getResultText(result: string, errorCode: string) {
  if (result && errorCode) {
    return `${errorCode}: ${result}`;
  }

  return result || errorCode || "-";
}
