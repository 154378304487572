/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Text, theme } from "@nordcloud/gnui";

type Props = {
  summary: string;
  caption: string;
};

export function RecalculationItemTooltip({ summary, caption }: Props) {
  return (
    <section>
      <Text
        color={theme.color.text.text04}
        weight="bold"
        mb={theme.spacing.spacing01}
      >
        {summary}
      </Text>
      <Text size="sm" color={theme.color.text.text04} mb={0}>
        {caption}
      </Text>
    </section>
  );
}
