/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import {
  KpiTargetSelection,
  useBusinessContextTargetsQuery,
} from "~/generated/graphql";
import { getKpiFetchConfig } from "~/components/KPI/utils";
import { KpiType } from "~/constants";
import { useQueryState } from "~/hooks";
import { mapKpiTargets } from "../mapping";

export const PAGE_LIMIT = 20;

export function useKpiTargets(
  businessContextId: string,
  targetSelection: KpiTargetSelection,
  kpiType: KpiType
) {
  const {
    state: { page },
    set,
  } = useQueryState();

  const { data, loading, error } = useBusinessContextTargetsQuery({
    variables: {
      bcId: businessContextId,
      selection: targetSelection,
      ...getKpiFetchConfig(kpiType),
    },
  });

  const rows = useMemo(() => mapKpiTargets(kpiType, data), [kpiType, data]);

  const targets =
    rows.length > PAGE_LIMIT
      ? rows.slice(page * PAGE_LIMIT, (page + 1) * PAGE_LIMIT)
      : rows;

  return {
    targets,
    targetsCount: rows.length,
    loading,
    error,
    page,
    set,
  };
}
