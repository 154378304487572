/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

export const ColorBox = styled.div<{
  width?: string;
  height?: string;
  color: string;
  opacity?: number;
  border?: string;
}>`
  width: ${({ width }) => width ?? "0.75rem"};
  height: ${({ height }) => height ?? "0.75rem"};
  background-color: ${({ color }) => color};
  border-radius: ${theme.radiusDefault};
  opacity: ${({ opacity }) => opacity ?? 1};
  border: ${({ border }) => border ?? ""};
`;
