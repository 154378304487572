/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  ExpandedState,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { When } from "react-if";
import { Text } from "@nordcloud/gnui";
import { isEmpty } from "~/tools";
import { Th, FooterTh, StyledTable } from "../components/CostTableStyles";
import { CostAnalysisCostTableBody } from "./components/CostAnalysisCostTableBody";
import { Period, TableData } from "./types";

type Props = {
  data: TableData[];
  columns: ColumnDef<TableData>[];
  period: Period;
  hasFooter?: boolean;
  hideHeader?: boolean;
  getSubRows?: (
    originalRow: TableData,
    index: number
  ) => TableData[] | undefined;
};

export function CostAnalysisCostTable({
  data,
  columns,
  period,
  hasFooter,
  hideHeader,
  getSubRows,
}: Props) {
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    state: { expanded },
    onExpandedChange: setExpanded,
    getSubRows,
  });

  if (isEmpty(table.getRowModel().rows)) {
    return <Text>No data available.</Text>;
  }

  const columnSize = table.getAllColumns().length;

  return (
    <StyledTable mb="0">
      <When condition={!hideHeader}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={`header-row-${headerGroup.id}`}>
              {headerGroup.headers.map((header) => (
                <Th
                  key={header.id}
                  colSpan={header.colSpan}
                  wide={columnSize < 10}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Th>
              ))}
            </tr>
          ))}
        </thead>
      </When>
      <CostAnalysisCostTableBody
        data={data}
        rows={table.getRowModel().rows}
        period={period}
      />
      <When condition={hasFooter}>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <FooterTh key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </FooterTh>
              ))}
            </tr>
          ))}
        </tfoot>
      </When>
    </StyledTable>
  );
}
