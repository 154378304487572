/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { WorkflowFrequency } from "~/generated/graphql";
import { dateFormat } from "~/constants";
import { generateActionConfirmText, inflect } from "~/tools";
import { FrequencyTextMap, WeekdayLabelMap } from "./AddWorkflow/constants";
import { WorkflowInfo } from "./types";

type WorkflowInput = {
  frequency: WorkflowFrequency;
  startDate: string;
  byWeekday?: number[];
  endDate?: string;
  interval?: number;
};

export const getScheduleSummaryTextFromWorkflowData = ({
  frequency,
  startDate,
  byWeekday,
  endDate,
  interval,
}: WorkflowInput) => {
  const startDateDayjs = dayjs(startDate);
  const endDateDayjs = endDate ? dayjs(endDate) : dayjs(startDate);
  const getHoursBetweenDates = () =>
    dayjs.duration(endDateDayjs.diff(startDateDayjs)).asHours();

  const executeOnce =
    frequency === WorkflowFrequency.Daily &&
    endDate &&
    getHoursBetweenDates() < 24;

  const weekdays = byWeekday
    ? Array.from({ length: 7 })
        .fill({ selected: false })
        .map((_, index) => ({ selected: byWeekday.includes(index) }))
    : [];

  return getScheduleSummaryTextFromFormData({
    frequency,
    repeat: !executeOnce,
    executionTime: getExecutionTimeString(startDateDayjs),
    weekdays,
    interval: interval ?? 0,
    startDate: startDateDayjs.format(dateFormat.shortDate),
  });
};

const getExecutionTimeString = (date: dayjs.Dayjs) => {
  const hourString = date.hour().toString().padStart(2, "0");
  const minuteString = date.minute().toString().padStart(2, "0");

  return `${hourString}:${minuteString}`;
};

type WorkflowFormInput = {
  repeat: boolean;
  startDate: string;
  executionTime: string;
  frequency: WorkflowFrequency;
  interval: number;
  weekdays: { selected: boolean }[];
};

export const getScheduleSummaryTextFromFormData = ({
  repeat,
  startDate,
  executionTime,
  frequency,
  interval,
  weekdays,
}: WorkflowFormInput) => {
  if (!repeat) {
    return `On ${startDate} at ${executionTime}`;
  }

  const frequencySubstring = inflect(FrequencyTextMap[frequency])(interval);
  const intervalSubstring =
    interval === 1 ? frequencySubstring : `${interval} ${frequencySubstring}`;

  if (frequency === WorkflowFrequency.Weekly) {
    const weekdaysString = weekdays
      .reduce<string[]>(
        (accumulator, { selected }, index) =>
          selected ? [...accumulator, WeekdayLabelMap[index]] : accumulator,
        []
      )
      .join(", ");

    const weekdaysSubstring =
      weekdaysString === "" ? "" : `on ${weekdaysString}`;
    return `Every ${intervalSubstring} ${weekdaysSubstring} at ${executionTime}`;
  }

  return `Every ${intervalSubstring} at ${executionTime}`;
};

export const getForceRunConfirmContentText = (workflow?: WorkflowInfo) =>
  workflow?.name
    ? generateActionConfirmText(`"${workflow.name}" now`)("force run")()
    : "";

export const getActivateConfirmContentText = (workflow?: WorkflowInfo) => {
  const action = workflow?.active ? "deactivate" : "activate";
  return workflow?.name
    ? generateActionConfirmText(`"${workflow.name}"`)(action)()
    : "";
};

export const getDeleteConfirmContentText = (workflow?: WorkflowInfo) =>
  workflow?.name
    ? generateActionConfirmText(`"${workflow.name}"`)("delete")()
    : "";

export function calculateRunDuration(
  startTime: string,
  endTime: string,
  format = dateFormat.fullDateDash
) {
  if (startTime && endTime) {
    const start = dayjs(startTime, format);
    const end = dayjs(endTime, format);
    if (start.isValid() && end.isValid()) {
      return dayjs.duration(end.diff(start)).format(dateFormat.hour);
    }
  }
  return "";
}
