/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { MultipleSelect, SelectButton } from "@nordcloud/gnui";

type Props = {
  period: number;
  onPeriodChanged: (v: number) => void;
};

export function PeriodSwitch({ period, onPeriodChanged }: Props) {
  return (
    <MultipleSelect size="small">
      <SelectButton
        name="month"
        value={Period.MONTH.toString()}
        labelText="The last 30 days"
        onClick={() => onPeriodChanged(Period.MONTH)}
        isActive={period === Period.MONTH}
      />
      <SelectButton
        name="quarter"
        value={Period.QUARTER.toString()}
        labelText="Up to 3 Months"
        onClick={() => onPeriodChanged(Period.QUARTER)}
        isActive={period === Period.QUARTER}
      />
      <SelectButton
        name="year"
        value={Period.YEAR.toString()}
        labelText="Up to 12 Months"
        onClick={() => onPeriodChanged(Period.YEAR)}
        isActive={period === Period.YEAR}
      />
    </MultipleSelect>
  );
}

export const enum Period {
  MONTH = 1,
  QUARTER = 3,
  YEAR = 12,
}
