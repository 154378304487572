/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer, theme, Text, Message } from "@nordcloud/gnui";
import { CopyBox } from "./CopyBox";

export function KubernetesOpenshiftDocumentationForm() {
  return (
    <FlexContainer
      css={{ width: "100%" }}
      alignItems="flex-start"
      direction="column"
      gap={theme.spacing.spacing03}
    >
      <Message status="notification" image="info" width="100%">
        <div css={{ width: "100%" }}>
          <Text
            weight="medium"
            color={theme.color.text.info}
            mb={theme.spacing.spacing00}
          >
            Setting the scanner inside the Kubernetes cluster.
          </Text>
          <Text
            mt={theme.spacing.spacing02}
            mb={theme.spacing.spacing00}
            color={theme.color.text.text02}
          >
            Deployment YAML needs to be placed in your cluster to send the data
            to MCA. After placing the Deployment YAML into your Kubernetes
            cluster it might take up to 30 minutes for MCA to highlight your
            cluster with the green icon and get first data.
          </Text>
        </div>
      </Message>
      <Text weight="bold" tag="div">
        Update Scanner
      </Text>
      <CopyBox value="kubectl rollout restart deployment nordcloud-scanner">
        kubectl rollout restart deployment nordcloud-scanner
      </CopyBox>
      <Text weight="bold">
        Configure Kubernetes Scanner to Use Existing Prometheus Instance
      </Text>
      <Text tag="div" weight="medium">
        Configuration
      </Text>
      <Text tag="div">
        Modify the prometheus-endpoint property in the
        nordcloud-metrics-exporter-config-map config map (default namespace:
        nordcloud-scanner) and set your prometheus instance endpoint.
      </Text>
      <Text weight="medium" tag="div">
        Clean Up
      </Text>
      <Text tag="div">
        Remove prometheus resources (cluster role, cluster role binding, config
        map, deployment and service) that are no longer needed:
      </Text>
      <CopyBox
        value={`kubectl delete deployment/nordcloud-prometheus-deployment -n nordcloud-scanner 
      kubectl delete service/nordcloud-prometheus-service -n nordcloud-scanner 
      kubectl delete configmap/nordcloud-prometheus-config-map -n nordcloud-scanner 
      kubectl delete clusterRoleBinding nordcloud-prometheus 
      kubectl delete clusterRole nordcloud-prometheus`}
      >
        <div>
          kubectl delete deployment/nordcloud-prometheus-deployment -n
          nordcloud-scanner
        </div>
        <div>
          kubectl delete service/nordcloud-prometheus-service -n
          nordcloud-scanner
        </div>
        <div>
          kubectl delete configmap/nordcloud-prometheus-config-map -n
          nordcloud-scanner
        </div>
        <div>kubectl delete clusterRoleBinding nordcloud-prometheus</div>
        <div>kubectl delete clusterRole nordcloud-prometheus</div>
      </CopyBox>
    </FlexContainer>
  );
}
