/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useQueryState, useToggle } from "~/hooks";
import { ChartsQueryState, ChartType } from "../types";

type Props = {
  chartType: ChartType;
};

export function useViewOthers({ chartType }: Props) {
  const {
    state: { otherCosts },
  } = useQueryState<ChartsQueryState>();

  const [isOtherCostsOpen, toggleOtherCostsOpen] = useToggle(
    otherCosts ?? true
  );

  const [isOtherCostsSwitchEnabled, toggleOtherCostsSwitch] = useToggle(true);

  function handleOtherCostsSwitch(enable: boolean) {
    const hasAvailabilityChanged = enable !== isOtherCostsSwitchEnabled;

    if (hasAvailabilityChanged) {
      toggleOtherCostsSwitch();
    }

    if (hasAvailabilityChanged && hideOtherCosts(enable, isOtherCostsOpen)) {
      toggleOtherCostsOpen();
    }
  }

  const isOtherCostsSwitchVisible = [
    ChartType.COST_PER_APPLICATION,
    ChartType.COST_PER_CATEGORY,
    ChartType.COST_PER_SERVICES,
  ].includes(chartType);

  return {
    isOtherCostsSwitchVisible,
    isOtherCostsOpen,
    toggleOtherCosts: toggleOtherCostsOpen,
    isOtherCostsSwitchEnabled,
    handleOtherCostsSwitch,
  };
}

function hideOtherCosts(isEnabled: boolean, isOpen: boolean) {
  if (!isEnabled && isOpen) {
    return true;
  }

  return isEnabled && !isOpen;
}
