/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloError } from "@apollo/client";
import { Spacer, theme } from "@nordcloud/gnui";
import { BreadcrumbsBox, UniversalWrap } from "~/components";
import {
  Ec2InstanceApplications,
  Ec2InstanceHeader,
  EstateRecord,
} from "../Ec2Instance";
import { useEstateDetails } from "../hooks";
import { ApplicationProps, Costs } from "../types";
import { Ec2VpcDetails } from "./Ec2VpcDetails";
import { Ec2VpcTabs } from "./Ec2VpcTabs";
import { useHeaderData, useInstanceDetails } from "./hooks";

type Props = {
  header: EstateRecord;
  data: ReturnType<typeof useEstateDetails>["data"];
  applications: ApplicationProps;
  loading: boolean;
  error: ApolloError | undefined;
  costs: Costs;
};

export function Ec2VpcPage({
  header,
  data,
  applications,
  loading,
  error,
  costs,
}: Props) {
  const { metadata, breadcrumbLabels } = data;

  const { name } = header;
  const headerData = useHeaderData(header, costs);
  const instanceDetails = useInstanceDetails(metadata);

  return (
    <UniversalWrap loaderProps={{ loading }} errorProps={{ error }}>
      <BreadcrumbsBox title={name} labels={breadcrumbLabels} />
      <Ec2InstanceHeader {...headerData} />
      <Spacer height={theme.spacing.spacing04} />
      <Ec2VpcDetails {...instanceDetails} />
      <Spacer height={theme.spacing.spacing04} />
      <Ec2InstanceApplications {...applications} />
      <Spacer height={theme.spacing.spacing04} />
      <Ec2VpcTabs data={data} />
    </UniversalWrap>
  );
}
