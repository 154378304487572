/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

export const Wrapper = styled.div`
  display: inline-block;
  position: relative;

  li {
    border: none;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Content = styled.div<{ isVisible: boolean }>`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: ${theme.zindex.dropdown};
  transition:
    visibility 0.1s ease-out,
    opacity 0.1s ease-out;
  margin-top: ${theme.spacing.spacing01};

  ${({ isVisible }) =>
    isVisible &&
    `
    visibility: visible;
    opacity: 1;
  `}
`;
