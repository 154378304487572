/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import dayjs from "dayjs";
import { When } from "react-if";
import { Box, Col, FlexContainer, Row, Text } from "@nordcloud/gnui";
import {
  TurbonomicIntegrationPluginDocument,
  UpdateTurbonomicIntegrationPluginInput,
  useUpdateTurbonomicIntegrationPluginMutation,
} from "~/generated/graphql";
import { dateFormat, ERROR_TEXT } from "~/constants";
import { showError } from "~/services/toast";
import { TurbonomicIntegrationPluginProp } from "../models/IntegrationPlugin";
import { DetailsRow } from "./DetailsRow/DetailsRow";
import { InlineContactPicker } from "./InlineContactPicker/InlineContactPicker";
import { LastIntegrationSyncInfo } from "./LastIntegrationSyncInfo";
import { TestExistingTurbonomicCredentialsButton } from "./TestExistingTurbonomicCredentialsButton";

type Props = {
  plugin: TurbonomicIntegrationPluginProp;
};

export function TurbonomicIntegrationPluginsDetailsView({ plugin }: Props) {
  const [isUsernameInEditMode, setIsUsernameInEditMode] = React.useState(false);
  const [isPasswordInEditMode, setIsPasswordInEditMode] = React.useState(false);
  const [isUrlInEditMode, setIsUrlInEditMode] = React.useState(false);

  const [updatePluginMutation, { loading }] =
    useUpdateTurbonomicIntegrationPluginMutation();

  const createdAt = dayjs(plugin.createdAt).format(
    dateFormat.dayShortMonthYear
  );

  const disableTestConnectionButton =
    isUsernameInEditMode || isPasswordInEditMode || isUrlInEditMode || loading;

  const onEditSubmit = async (data: UpdateTurbonomicIntegrationPluginInput) => {
    try {
      await updatePluginMutation({
        variables: {
          id: plugin.id,
          input: { ...data },
        },
        awaitRefetchQueries: true,
        refetchQueries: [TurbonomicIntegrationPluginDocument],
      });
    } catch (err) {
      showError(err.message ?? ERROR_TEXT.tryAgainDefault);
    }
  };

  const toggleEditMode = (propertyName: string) => {
    if (propertyName === "username") {
      return setIsUsernameInEditMode(!isUsernameInEditMode);
    }
    if (propertyName === "password") {
      return setIsPasswordInEditMode(!isPasswordInEditMode);
    }
    if (propertyName === "url") {
      return setIsUrlInEditMode(!isUrlInEditMode);
    }
  };

  return (
    <Box>
      <Row>
        <Col sm={5} md={6} lg={8}>
          <FlexContainer direction="column" alignItems="start">
            <Text isTitle weight="medium">
              Integration Details
            </Text>
            <DetailsRow
              title="Integration Name"
              propertyName="name"
              value={plugin.name}
              onSubmit={onEditSubmit}
            />
            <DetailsRow
              disableEdit
              title="Type"
              propertyName="type"
              value={plugin.integrationType}
            />
            <DetailsRow
              title="Description"
              propertyName="description"
              value={plugin.description}
              onSubmit={onEditSubmit}
            />
            <InlineContactPicker
              title="Contact Person"
              initValues={plugin?.contactPersons ?? []}
              property="contactPersonIds"
              onSubmit={onEditSubmit}
            />
            <DetailsRow
              title="URL"
              propertyName="url"
              value={plugin.url}
              toggleEditMode={toggleEditMode}
              onSubmit={onEditSubmit}
            />
            <DetailsRow
              title="Account Name"
              propertyName="username"
              value={plugin?.username}
              toggleEditMode={toggleEditMode}
              onSubmit={onEditSubmit}
            />
            <DetailsRow
              isPassword
              title="Password"
              propertyName="password"
              value="••••••••••••••"
              toggleEditMode={toggleEditMode}
              onSubmit={onEditSubmit}
            />
            <DetailsRow
              disableEdit
              title="Integration Created"
              propertyName="createdAt"
              value={createdAt}
            />
            <TestExistingTurbonomicCredentialsButton
              id={plugin.id}
              disabled={disableTestConnectionButton}
            />
          </FlexContainer>
        </Col>
        <Col sm={3} md={2} lg={4}>
          <When condition={plugin.lastSyncStatus}>
            <LastIntegrationSyncInfo plugin={plugin} />
          </When>
        </Col>
      </Row>
    </Box>
  );
}
