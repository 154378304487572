/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then } from "react-if";
import { FlexContainer, Text, theme } from "@nordcloud/gnui";
import { formatMoney } from "~/tools";
import { Editing } from "../helpers";
import { BudgetNumberInput } from "./BudgetNumberInput";

type Props = {
  editing: boolean;
  yearlyBudget: number;
  currency: string;
  onYearlyBudgetChanged: React.ComponentProps<
    typeof BudgetNumberInput
  >["onChange"];
  onSetType: (value: Editing) => void;
};

export function YearlyBudget({
  editing,
  yearlyBudget,
  currency,
  onYearlyBudgetChanged,
  onSetType,
}: Props) {
  return (
    <FlexContainer
      direction="column"
      alignItems="start"
      gap={theme.spacing.spacing01}
      data-testid="budget-year"
    >
      <Text size="sm" tag="span" color={theme.color.text.text02}>
        Yearly Budget
      </Text>
      <If condition={editing}>
        <Then>
          <BudgetNumberInput
            value={yearlyBudget}
            type={Editing.YEARLY}
            onChange={onYearlyBudgetChanged}
            onSetType={onSetType}
          />
        </Then>
        <Else>
          <Text size="lg" weight="medium" data-testid="budget-cell-year">
            {formatMoney(yearlyBudget, currency, false)}
          </Text>
        </Else>
      </If>
    </FlexContainer>
  );
}
