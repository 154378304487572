/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { NestedValue, UnpackNestedValue } from "react-hook-form";
import { deepClone } from "~/tools";

export type IntegrationPluginFormData = {
  integrationName: string;
  integrationDescription: string;
  integrationType: string;
  url: string;
  accountName: string;
  password: string;
  contactPersonIds: NestedValue<{ value: string }[]>;
};

const DEFAULT_VALUES: UnpackNestedValue<IntegrationPluginFormData> = {
  integrationName: "",
  integrationDescription: "",
  integrationType: "Turbonomic",
  url: "",
  accountName: "",
  password: "",
  contactPersonIds: [{ value: "" }],
};

export const getDefaultValues = () => deepClone(DEFAULT_VALUES);
