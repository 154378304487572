import { ReservationsAndCommitmentsFilters } from "~/generated/graphql";
import { useFilteredQueryState } from "~/hooks";
import { filtersTable } from "../constants";
import { CoverageChartFilters } from "../types";
import { convertTags } from "../utils";

type ComputeFilters = ReservationsAndCommitmentsFilters & {
  tags: string[];
  page: number;
  limit: number;
};

export function useGetFilters(): CoverageChartFilters {
  const { state: filterState } = useFilteredQueryState(filtersTable);
  // @ts-expect-error ignored temporary after migration to TypeScript 4.9
  const { tags, ...rawFilters }: ComputeFilters = filterState;

  const filters: CoverageChartFilters = {
    ...rawFilters,
    tags: convertTags(tags),
  };
  return filters;
}
