/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Button, FlexContainer, theme } from "@nordcloud/gnui";
import { WorkflowHistoryLimitButtons } from "./WorkflowHistoryLimitButtons";

type Props = {
  limitValues: number[];
  selectedLimit: number;
  setLimit: (limit: number) => void;
  onClickPrevious: () => void;
  onClickNext: () => void;
  disablePrev: boolean;
  disableNext: boolean;
};

export function WorkflowHistoryPagination({
  limitValues,
  selectedLimit,
  setLimit,
  onClickPrevious,
  onClickNext,
  disablePrev,
  disableNext,
}: Props) {
  return (
    <FlexContainer justifyContent="space-between">
      <FlexContainer
        marginLeft="50%"
        css={{ transform: "translate(-50%)" }}
        justifyContent="center"
      >
        <Button
          disabled={disablePrev}
          mr={theme.spacing.spacing04}
          data-testid="prev-btn"
          type="button"
          onClick={onClickPrevious}
        >
          Previous
        </Button>
        <Button
          disabled={disableNext}
          data-testid="next-btn"
          type="button"
          onClick={onClickNext}
        >
          Next
        </Button>
      </FlexContainer>
      <WorkflowHistoryLimitButtons
        limitValues={limitValues}
        selectedLimit={selectedLimit}
        setLimit={setLimit}
      />
    </FlexContainer>
  );
}
