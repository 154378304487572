/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { v4 as uuidv4 } from "uuid";
import { ReservationsProvider } from "~/generated/graphql";
import { RecommendationDetails, SideBarRecommendation } from "../types";

export function useGetSidebarDataItems(
  recommendationDetails: RecommendationDetails,
  provider: ReservationsProvider
): SideBarRecommendation[] {
  const isAws = provider === ReservationsProvider.Aws;

  return [
    {
      id: uuidv4(),
      header: "Reserved Instance Purchase Recommendation",
      dataItems: [
        {
          label: isAws ? "Instance Type" : "SKU Name",
          value: recommendationDetails?.instanceType,
        },
        {
          label: "Units",
          value: recommendationDetails?.numberOfInstances,
        },
        {
          label: "Expected RI Utilization",
          value: recommendationDetails?.expectedUtilization,
        },
      ],
    },
    {
      id: uuidv4(),
      header: "Savings",
      dataItems: [
        {
          label: "Potential Monthly Savings",
          value: recommendationDetails?.monthlySavings,
        },
        {
          label: "Potential Yearly Savings",
          value: recommendationDetails?.yearlySavings,
        },
        {
          label: "Savings Rate",
          value: recommendationDetails?.monthlySavingsPercentage,
        },
      ],
    },
    {
      id: uuidv4(),
      header: "Costs",
      dataItems: [
        {
          label: "Total Upfront Cost",
          value: recommendationDetails?.upfrontCost,
        },
        {
          label: "Recurring Monthly Cost",
          value: recommendationDetails?.recurringMonthlyCost,
        },
      ],
    },
  ];
}
