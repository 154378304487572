/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

export const RecalculationItemWrapper = styled.button<{
  isClickable: boolean;
}>`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: ${theme.radiusDefault};
  justify-content: center;
  column-gap: ${theme.spacing.spacing02};
  padding: calc(${theme.spacing.spacing03} + 1px);
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "cursor")};
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${({ isClickable }) =>
      isClickable ? theme.color.interactive.secondaryHover : undefined};
  }
`;
