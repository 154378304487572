/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ColumnDef, Row } from "@tanstack/react-table";
import { Else, If, Then } from "react-if";
import { theme, Text } from "@nordcloud/gnui";
import { TableCell } from "~/components/Charts/CostAnalysis/components/TableCell";
import { formatChartDates } from "~/components/Charts/CostAnalysis/components/utils";
import {
  ChartType,
  CostAnalysisFields,
  LinkParameters,
  Period,
  TableData,
} from "~/components/Charts/CostAnalysis/types";
import { formatMoney } from "~/tools";
import { otherCostsColor } from "./consts";

type ColumnsProps = {
  startDate: string;
  endDate: string;
  currency: string;
  data: TableData[];
  chartType: ChartType;
  period: Period;
  colors: { [k: string]: string };
  linkParameters?: LinkParameters;
  headerName?: string;
};

type GeneratedColumnProps = {
  header: { date: string; displayDate: string };
  currency: string;
  data: TableData[];
};

function generateDateColumn({
  header,
  currency,
}: GeneratedColumnProps): ColumnDef<TableData>[] {
  return [
    {
      header: header.displayDate,
      accessorKey: header.date,
      cell: ({ row }) => getStyledText(row?.original, currency, header.date),
    },
  ];
}

export function getCostAnalysisTableColumns({
  startDate,
  endDate,
  currency,
  data,
  chartType,
  colors,
  period,
  linkParameters,
  headerName = "",
}: ColumnsProps): ColumnDef<TableData>[] {
  const headers = formatChartDates(startDate, endDate, period);

  const totalColumn: ColumnDef<TableData> = {
    header: "Total",
    accessorKey: "total",
    cell: ({ row }) => getStyledText(row?.original, currency),
  };

  return [
    {
      header: headerName,
      accessorKey: "field",
      cell: ({ row }) => {
        if (row.original?.field === CostAnalysisFields.TOTAL_COST) {
          return (
            <Text
              css={{ marginLeft: theme.spacing.spacing06 }}
              weight="medium"
              mb="0"
            >
              {row?.original?.field}
            </Text>
          );
        }

        const color = mapBoxColor(row, colors);

        return (
          <TableCell
            row={row}
            showColorIcon={showColorBox(row, colors)}
            chartType={chartType}
            color={color}
            linkParameters={linkParameters}
          />
        );
      },
    },
    ...headers.flatMap((header) =>
      generateDateColumn({
        header,
        currency,
        data,
      })
    ),
    ...(period !== Period.MONTH ? [totalColumn] : []),
  ];
}

function getStyledText(
  tableData: TableData | undefined,
  currency: string,
  date?: string
) {
  const isTotal =
    tableData?.field === "Total cost" && Number(tableData?.total) > 0;
  const value = date
    ? Number(tableData?.[date] ?? 0)
    : Number(tableData?.total ?? 0);
  const textFormat = value ? formatMoney(value, currency) : "-";

  return (
    <If condition={isTotal}>
      <Then>
        <Text weight="medium" mb={0}>
          {textFormat}
        </Text>
      </Then>
      <Else>{textFormat}</Else>
    </If>
  );
}

function showColorBox(row: Row<TableData>, colors: { [k: string]: string }) {
  return (
    Object.keys(colors).length > 0 &&
    row?.original?.id !== CostAnalysisFields.TOTAL_COST
  );
}

function mapBoxColor(row: Row<TableData>, colors: { [k: string]: string }) {
  if (row.original?.total === 0) {
    return "primary";
  }

  if (row.original?.total !== undefined && Number(row.original?.total) > 0) {
    return colors[row.original?.field] ?? otherCostsColor;
  }
  return otherCostsColor;
}
