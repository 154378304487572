/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Group } from "@visx/group";
import { Pie } from "@visx/shape";
import { Text } from "@visx/text";
import { theme } from "@nordcloud/gnui";

type DataItem = {
  name: string;
  value: number;
  color: string;
};

type Props = {
  data: DataItem[];
  innerText: string;
};

const SIZE = 160;

// ESLint reports false-positive
// eslint-disable-next-line unicorn/prefer-dom-node-text-content
export function TopSpendingAppsChart({ data, innerText }: Props) {
  return (
    <svg width={SIZE} height={SIZE}>
      <Group top={SIZE / 2} left={SIZE / 2}>
        <Pie<DataItem>
          data={data}
          pieValue={(item) => item.value}
          outerRadius={80}
          innerRadius={60}
          padAngle={0.01}
        >
          {(pie) =>
            pie.arcs.map((arc) => (
              <g key={arc.data.name}>
                <path d={pie.path(arc) ?? undefined} fill={arc.data.color} />
              </g>
            ))
          }
        </Pie>

        <Text
          textAnchor="middle"
          fill={theme.color.text.text01}
          fontSize={20}
          fontWeight="bold"
          dy={8}
        >
          {innerText}
        </Text>
      </Group>
    </svg>
  );
}
