/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ReservationsAndCommitmentsCoverageServiceUsageType } from "~/generated/graphql";
import { ResourceLabel } from "../constants";
import { useGetCurrentTab } from "./useGetCurrentTab";

export function useGetAzureServiceLabel(
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType
) {
  const { isDatabaseTab, isAnalyticsTab, isStorageTab } =
    useGetCurrentTab(serviceUsageType);

  if (isAnalyticsTab || isDatabaseTab) {
    return ResourceLabel.ServiceDetails;
  }

  if (isStorageTab) {
    return ResourceLabel.TierDetails;
  }
  return ResourceLabel.InstanceDetails;
}
