/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import {
  theme,
  Text,
  Switch,
  FlexContainer,
  Spacer,
  Modal,
} from "@nordcloud/gnui";
import { useToggle } from "~/hooks";
import { EMAIL_MSG_PREVIEW_MODAL_TITLE } from "../constants";
import { PreviewButton } from "./PreviewButton";
import { PreviewImage } from "./PreviewImage";

type Props = {
  isReadMode: boolean;
  checked: boolean;
  onChange: () => void;
  title: string;
  details: string;
  previewImage?: string;
};

export function SendToOption({
  isReadMode,
  checked,
  onChange,
  details,
  title,
  previewImage,
}: Props) {
  const [showModal, setShowModal] = useToggle(false);

  const isPreviewEnabled = Boolean(previewImage);

  return (
    <>
      <FlexContainer justifyContent="space-between">
        <FlexContainer columnGap={theme.spacing.spacing02}>
          <Switch disabled={isReadMode} checked={checked} onChange={onChange} />
          {title}
        </FlexContainer>
        <When condition={isPreviewEnabled}>
          <PreviewButton type="button" size="sm" onClick={setShowModal}>
            Sample Message
          </PreviewButton>
        </When>
      </FlexContainer>
      <Spacer height={theme.spacing.spacing01} />
      <Text ml="3.5rem" tag="div" color={theme.color.text.text02} size="sm">
        {details}
      </Text>
      <Spacer height={theme.spacing.spacing03} />
      <When condition={showModal}>
        <Modal
          contentLabel={EMAIL_MSG_PREVIEW_MODAL_TITLE}
          isOpen={showModal}
          actions={[]}
          contentMaxHeight="40rem"
          onClose={setShowModal}
        >
          <PreviewImage src={previewImage} />
        </Modal>
      </When>
    </>
  );
}
