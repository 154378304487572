/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback } from "react";
import { Else, If, Then, When } from "react-if";
import { generatePath, Link } from "react-router-dom";
import {
  ExtendedTooltip,
  FlexContainer,
  SVGIcon,
  Table,
  Text,
} from "@nordcloud/gnui";
import { StyledRect } from "~/components";
import { NO_FORECAST_TEXT } from "~/constants";
import { useCurrency } from "~/hooks";
import { ROUTES } from "~/routing/routes";
import { formatMoney, isNotEmpty, truncateString } from "~/tools";
import { useTopSpendingAppsWidgetRow } from "./useTopSpendingAppsWidgetRow";

type Props = {
  nid: string;
  name: string;
  currentMonth: string;
  forecast: string;
  previousMonth: string;
  index: number;
  isCurrentBillingPeriod: boolean;
};

export function TopSpendingAppsWidgetRow({
  name,
  nid,
  currentMonth,
  forecast,
  previousMonth,
  index,
  isCurrentBillingPeriod,
}: Props) {
  const TRUNCATE_LENGTH = 16;
  const isUnallocated = name === "Unallocated";
  const { currency } = useCurrency();

  const getLink = useCallback(
    (applicationId: string) => {
      if (isUnallocated) {
        return `${ROUTES.estateRecords.index}?unassigned=true`;
      }

      if (isNotEmpty(applicationId)) {
        return generatePath(ROUTES.applications.details, {
          application: applicationId,
        });
      }

      return ROUTES.applications.index;
    },
    [isUnallocated]
  );

  const {
    rectColor,
    linkColor,
    currentMonthColor,
    forecastColor,
    borderTop,
    hasValidForecast,
  } = useTopSpendingAppsWidgetRow({
    nid,
    isUnallocated,
    forecast,
    currentMonth,
    previousMonth,
    index,
  });

  return (
    <Table.tr
      css={{
        borderTop,
      }}
    >
      <Table.td>
        <FlexContainer>
          <StyledRect
            css={{
              background: rectColor,
            }}
          />
          <Link to={getLink(nid)}>
            <Text tag="span" color={linkColor}>
              <If condition={name.length > TRUNCATE_LENGTH}>
                <Then>
                  <ExtendedTooltip caption={name}>
                    {truncateString(name, TRUNCATE_LENGTH)}
                  </ExtendedTooltip>
                </Then>
                <Else>{name}</Else>
              </If>
            </Text>
          </Link>
        </FlexContainer>
      </Table.td>
      <Table.td>
        <Text tag="span" color={currentMonthColor}>
          {formatMoney(currentMonth, currency)}
        </Text>
      </Table.td>
      <When condition={isCurrentBillingPeriod}>
        <Table.td>
          <If condition={hasValidForecast}>
            <Then>
              <ExtendedTooltip caption={NO_FORECAST_TEXT}>
                <SVGIcon name="minus" size="sm" />
              </ExtendedTooltip>
            </Then>
            <Else>
              <Text tag="span" color={forecastColor}>
                {formatMoney(forecast, currency)}
              </Text>
            </Else>
          </If>
        </Table.td>
      </When>
    </Table.tr>
  );
}
