/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useFormContext } from "react-hook-form";
import { WorkflowFormData, WorkflowFormMode } from "../types";
import { AppsEnvsSidebar } from "./AppsEnvsSidebar";
import { useAppsEnvsField } from "./useAppsEnvsField";

type Props = {
  formMode: WorkflowFormMode;
  isOpen: boolean;
  onClose: () => void;
  applications: string[];
  environments: string[];
  type: "anomalyCostsOptions" | "costsOptions" | "savingsSuggestions";
};

export function AppsEnvsSettingsForm({
  formMode,
  isOpen,
  onClose,
  applications,
  environments,
  type,
}: Props) {
  const isReadMode = formMode === "read";
  const isAnomalyCostsType = type === "anomalyCostsOptions";
  const { setValue } = useFormContext<WorkflowFormData>();
  const {
    nodes,
    loading,
    error,
    count,
    page,
    pageSize,
    setPage,
    handleSearchChange,
    handleCheck,
    buttonText,
    handleSelectAll,
    handleDeselectAll,
    innerState,
    handleClose,
  } = useAppsEnvsField({
    outerState: { apps: applications, envs: environments },
    fetchAllIds: !isReadMode,
    nodeBehavior: "linkedToParent",
    compressState: true,
    disableExpanding: isAnomalyCostsType,
    onClose,
  });

  const handleSave = () => {
    setValue(`${type}.applications` as const, innerState.apps, {
      shouldDirty: true,
    });
    setValue(`${type}.environments` as const, innerState.envs, {
      shouldDirty: true,
    });

    handleClose();
  };

  const title = isAnomalyCostsType
    ? "Select Applications"
    : "Select Applications and Environments";

  return (
    <AppsEnvsSidebar
      isOpen={isOpen}
      title={title}
      disabled={isReadMode}
      nodes={nodes}
      loading={loading}
      error={error}
      count={count}
      page={page}
      pageSize={pageSize}
      setPage={setPage}
      saveButtonText={buttonText}
      disableExpanding={isAnomalyCostsType}
      onClose={handleClose}
      onSave={handleSave}
      onSearchChange={handleSearchChange}
      onCheck={handleCheck}
      onSelectAll={handleSelectAll}
      onClear={handleDeselectAll}
    />
  );
}
