/**
 * Copyright 2021-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled, { css } from "styled-components";
import { theme } from "@nordcloud/gnui";

type Props = {
  hide?: boolean;
};

export const CostWrapper = styled.div<Props>`
  grid-area: cost;
  ${handleStylesFromProps};
`;

export const CarbonFootprintWrapper = styled.div<Props>`
  grid-area: carbon;
  ${handleStylesFromProps};
`;

export const ForecastWrapper = styled.div<Props>`
  align-self: end;
  grid-area: forecast;
  ${handleStylesFromProps};
`;

export const SavingsWrapper = styled.div<Props>`
  align-self: end;
  grid-area: savings;
  ${handleStylesFromProps};
`;

export const CustomWrapper = styled.div<Props>`
  align-self: center;
  grid-area: custom;
  ${handleStylesFromProps};
`;

export const Separator = styled.div<Props>`
  border-top: solid 1px ${theme.color.border.border01};
  grid-area: separator;
  ${handleStylesFromProps};
`;

export const CostGrid = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 45% 55%;
  grid-template-rows: repeat(2, 1fr) 1px auto;
  row-gap: ${theme.spacing.spacing03};
  grid-template-areas:
    "cost      carbon"
    "custom    custom"
    "separator separator"
    "forecast  savings";

  > * {
    padding-left: ${theme.spacing.spacing03};
    padding-right: ${theme.spacing.spacing03};
  }

  .hidden > * {
    visibility: hidden;
  }
`;

function handleStylesFromProps(props: Props) {
  return css({
    visibility: props.hide ? "hidden" : "visible",
  });
}
