/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsAndCommitmentsFilters,
  ReservationsAndCommitmentsLookbackPeriod,
  ReservationsProvider,
} from "~/generated/graphql";
import { LoaderWrap } from "~/components/Utils/LoaderWrap";
import { useGetCoverageDetailsData } from "../../hooks";
import { UsageCoverageData } from "../../types";
import { TableItems } from "./TableItems";

type Props = {
  resources: UsageCoverageData;
  offeringType?: ReservationsAndCommitmentsCoverageOfferingType;
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  timePeriod?: ReservationsAndCommitmentsLookbackPeriod;
  openSidebar: (rowDetails: UsageCoverageData) => void;
  filters?: ReservationsAndCommitmentsFilters;
  provider: ReservationsProvider;
};

export function ReservationCoverageChild({
  resources,
  offeringType,
  serviceUsageType,
  timePeriod,
  provider,
  openSidebar,
}: Props) {
  const { loading, RICoverageData } = useGetCoverageDetailsData({
    resources,
    offeringType,
    serviceUsageType,
    timePeriod,
    provider,
  });

  return (
    <LoaderWrap inContent loading={loading}>
      {RICoverageData?.map((item: UsageCoverageData) => {
        return (
          <TableItems
            key={item.resource}
            openSidebar={openSidebar}
            offeringType={offeringType}
            serviceUsageType={serviceUsageType}
            resources={item}
            isParent={false}
            provider={provider}
          />
        );
      })}
    </LoaderWrap>
  );
}
