import {
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsRecommendationPaymentOption,
  ReservationsRecommendationTerm,
} from "~/generated/graphql";
import { termsFilter, ReservationsAndCommitmentsOptions } from "../constants";
import { PaymentOptionsFilter } from "../types";

type ReservationTypeProps = {
  type: ReservationsAndCommitmentsCoverageOfferingType;
  paymentOption: ReservationsRecommendationPaymentOption;
  termOption: ReservationsRecommendationTerm;
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  paymentOptionsFilter: PaymentOptionsFilter[];
};

export function useGetReservationsType({
  type,
  paymentOption,
  termOption,
  serviceUsageType,
  paymentOptionsFilter,
}: ReservationTypeProps) {
  const optionType =
    paymentOptionsFilter.find(({ value }) => value === paymentOption)
      ?.labelText ?? "";
  const termType =
    termsFilter.find((term) => term.value === termOption)?.subtitleText ?? "";
  const reservationOption = ReservationsAndCommitmentsOptions.find(
    ({ value }) => value === type
  );

  const label =
    serviceUsageType ===
    ReservationsAndCommitmentsCoverageServiceUsageType.MachineLearning
      ? "Machine Learning Savings Plan Recommendations"
      : reservationOption?.label ?? "";

  return {
    value: reservationOption?.value ?? "",
    valueLabel: reservationOption?.valueLabel ?? "",
    label,
    description: `${termType} / ${optionType}`,
  };
}
