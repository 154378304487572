/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Link } from "react-router-dom";
import { FlexContainer } from "@nordcloud/gnui";
import {
  DetailsDescription,
  DetailsItem,
  DetailsTitle,
} from "~/components/Details/styles";
import { isNotNil } from "~/tools";
import { LinkedResource } from "~/views/estate/EstateDetailsPage/components/ReallocatedCostsSidebar";
import {
  getCloudResource,
  isPVCInstance,
} from "~/views/estate/EstateDetailsPage/utils";

type PodProp = {
  linkedResources: LinkedResource[];
};

export function PodDetails({ linkedResources }: PodProp) {
  const pvcs = linkedResources
    ?.map(({ sourceRecord }) => getCloudResource(sourceRecord))
    .filter(isNotNil)
    .filter(({ providerType }) => isPVCInstance(providerType ?? ""));

  return (
    <DetailsItem>
      <DetailsTitle css={{ wordBreak: "normal" }}>
        Persistent Volume Claims
      </DetailsTitle>
      <DetailsDescription>
        <FlexContainer direction="column" alignItems="start">
          {pvcs.map((pvc) => (
            <Link
              key={pvc?.id}
              data-testid={`link-${pvc?.id}`}
              to={`/estate/${pvc?.id}`}
            >
              {pvc?.name}
            </Link>
          ))}
        </FlexContainer>
      </DetailsDescription>
    </DetailsItem>
  );
}
