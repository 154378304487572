/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Box, BrickLoader, Text } from "@nordcloud/gnui";
import { UniversalWrap } from "~/components";
import { SUCCESS_TEXT, ERROR_TEXT } from "~/constants";
import { showSuccess, showError } from "~/services/toast";
import { AzureForm } from "./AzureForm";
import { useAddAzureBillingData } from "./hooks/useAddAzureBillingData";
import { FormData } from "./validators";

export function AddBillingData({ onClose }: { onClose: () => void }) {
  const [addAzureCapacity, { error, loading }] = useAddAzureBillingData();

  const onSubmit = async (data: FormData) => {
    try {
      await addAzureCapacity(data);
      showSuccess(SUCCESS_TEXT.billingDataAdded);
      onClose();
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  return (
    <Box boxStyle="lightGrey">
      <Text weight="medium">Billing Data Settings</Text>
      <UniversalWrap
        loaderProps={{ loading, Component: BrickLoader }}
        errorProps={{ error }}
      >
        <AzureForm onCloseForm={onClose} onSubmit={onSubmit} />
      </UniversalWrap>
    </Box>
  );
}
