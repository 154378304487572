/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ReactNode } from "react";
import { FlexContainer, Text, theme } from "@nordcloud/gnui";

type ItemProps = {
  title: ReactNode;
  content: ReactNode;
  align?: "start" | "end";
};

export function Item({ title, content, align = "start" }: ItemProps) {
  return (
    <FlexContainer direction="column" alignItems={align}>
      <Text mb={0} size="sm" color={theme.color.text.text02} nowrap>
        {title}
      </Text>
      <Text mb={0}>{content}</Text>
    </FlexContainer>
  );
}
