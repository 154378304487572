/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ReactNode } from "react";
import { CopyToClipboard as Copy } from "react-copy-to-clipboard";
import { Unless, When } from "react-if";
import { Box, FlexContainer, SVGIcon, Text } from "@nordcloud/gnui";
import { SUCCESS_TEXT } from "~/constants";
import { showSimpleInfo } from "~/services/toast";

type Props = {
  value: ReactNode;
  valueToCopy?: string;
};

export function CopyToClipboard({ value, valueToCopy }: Props) {
  const isValueAString = typeof value === "string";
  const textToCopy = isValueAString && !valueToCopy ? value : valueToCopy || "";

  return (
    <Box css={{ width: "100%" }} boxStyle="grey">
      <FlexContainer css={{ width: "100%" }} justifyContent="space-between">
        <When condition={isValueAString}>
          <Text css={{ maxWidth: "95%" }} tag="div">
            {value}
          </Text>
        </When>
        <Copy
          text={textToCopy}
          onCopy={() => {
            showSimpleInfo(SUCCESS_TEXT.copied);
          }}
        >
          <FlexContainer
            css={{ width: !isValueAString ? "100%" : "auto" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Unless condition={isValueAString}>
              <FlexContainer
                css={{ width: "95%" }}
                alignItems="flex-start"
                direction="column"
              >
                {value}
              </FlexContainer>
            </Unless>
            <SVGIcon
              name="copy"
              size="md"
              css={{
                cursor: "pointer",
                minWidth: "1rem",
              }}
            />
          </FlexContainer>
        </Copy>
      </FlexContainer>
    </Box>
  );
}
