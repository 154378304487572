/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ChangeEvent, useMemo, useState } from "react";
import { MappedCustomer } from "~/services/customers/types";
import { Customer } from "~/services/customers/useFetchCustomers";
import { isNotEmpty, sort } from "~/tools";

type Props = {
  onSubmit: (id: string, name: string, orgName: string) => void;
  customers: Customer[];
};

export function useCustomerSelectorForm({ onSubmit, customers }: Props) {
  const [search, setSearch] = useState("");

  const onCustomerSelected = (id: string, name: string, orgName: string) => {
    onSubmit(id, name, orgName);
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const resetSearch = () => setSearch("");

  const sortedCustomers = useMemo(() => {
    const aggregatedCustomers = Object.values(
      customers.reduce<{
        [key: string]: MappedCustomer;
      }>((store, customer) => {
        if (store[customer.organizationName] == null) {
          store[customer.organizationName] = {
            organizationName: customer.organizationName,
            tenants: [customer],
          };
        } else {
          store[customer.organizationName].tenants.push(customer);
        }
        return store;
      }, {})
    );

    const sortedTenants = aggregatedCustomers.map((customer) => ({
      ...customer,
      tenants: sort(customer.tenants, ({ name: a }, { name: b }) =>
        a.localeCompare(b)
      ),
    }));

    return sort(
      sortedTenants,
      ({ organizationName: a }, { organizationName: b }) => {
        if (b.length === 0) {
          return -1;
        }
        if (a.length === 0) {
          return 1;
        }
        return a.localeCompare(b);
      }
    );
  }, [customers]);

  const filteredCustomers = useMemo(() => {
    return sortedCustomers
      .filter(
        (customer) =>
          customer.organizationName
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase()) ||
          isNotEmpty(
            (customer.tenants ?? []).filter((tenant) =>
              tenant.name
                .toLocaleLowerCase()
                .includes(search.toLocaleLowerCase())
            )
          )
      )
      .map((customer) => ({
        ...customer,
        tenants: customer.organizationName
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase())
          ? customer.tenants
          : customer.tenants.filter((tenant) =>
              tenant.name
                .toLocaleLowerCase()
                .includes(search.toLocaleLowerCase())
            ),
      }));
  }, [search, sortedCustomers]);

  return {
    onCustomerSelected,
    onSearchChange,
    resetSearch,
    filteredCustomers,
  };
}
