/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  WorkflowSortingFields,
  WorkflowSortingOrder,
} from "~/generated/graphql";
import { SortSelector, SortDirection } from "~/components";

type Props = {
  sortField: WorkflowSortingFields;
  sortOrder: WorkflowSortingOrder;
  onSortFieldChange: (value: WorkflowSortingFields) => void;
  onSortOrderChange: (value: WorkflowSortingOrder) => void;
};

export function WorkflowListSort({
  sortField,
  sortOrder,
  onSortFieldChange,
  onSortOrderChange,
}: Props) {
  const order =
    sortOrder === WorkflowSortingOrder.Asc
      ? SortDirection.Ascending
      : SortDirection.Descending;

  const handleSortChange = (value: SortDirection) => {
    const newOrder =
      value === SortDirection.Ascending
        ? WorkflowSortingOrder.Asc
        : WorkflowSortingOrder.Desc;

    onSortOrderChange(newOrder);
  };

  return (
    <SortSelector
      options={SortOptions}
      value={sortField}
      direction={order}
      onSelect={(field) => onSortFieldChange(field as WorkflowSortingFields)}
      onSortChange={handleSortChange}
    />
  );
}

const SortOptions = [
  {
    value: WorkflowSortingFields.Active,
    label: "Status",
  },
  {
    value: WorkflowSortingFields.LastRunDate,
    label: "Last Run Date",
  },
  {
    value: WorkflowSortingFields.LastRunStatus,
    label: "Last Run Status",
  },
  {
    value: WorkflowSortingFields.Name,
    label: "Workflow Name",
  },
];
