/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import dayjs from "dayjs";
import { MultipleSelect, SelectButton } from "@nordcloud/gnui";
import { dateFormat } from "~/constants";

type Props = {
  period: string;
  setPeriod: (period: string) => void;
};

export function YearSelector({ period, setPeriod }: Props) {
  const years = React.useMemo(() => {
    const current = dayjs();

    const formatted = current.format(dateFormat.year);

    return [
      current.subtract(1, "year").format(dateFormat.year),
      formatted,
      current.add(1, "year").format(dateFormat.year),
    ];
  }, []);

  return (
    <MultipleSelect size="small">
      {years.map((year) => (
        <SelectButton
          key={year}
          name={year}
          value={year}
          labelText={year}
          onClick={() => setPeriod(year)}
          isActive={period === year}
          style={{ width: "5rem" }}
        />
      ))}
    </MultipleSelect>
  );
}
