/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

export const ItemWrapper = styled.div`
  flex: 1;

  :not(:last-child) {
    padding-right: ${theme.spacing.spacing06};
    border-right: 1px solid ${theme.color.border.border01};
  }
`;
