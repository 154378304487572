/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";
import { ExternalLink } from "~/components";

export const Wrapper = styled.div<{ isLast: boolean }>`
  display: grid;
  width: 100%;
  column-gap: ${theme.spacing.spacing04};
  row-gap: ${({ isLast }) =>
    isLast ? theme.spacing.spacing01 : theme.spacing.spacing05};
  grid-template-columns: 2rem 5fr repeat(3, 1fr);
  grid-template-rows: 1fr ${({ isLast }) => (isLast ? "0" : "2px")};
  grid-template-areas:
    "icon action carbon savings actions"
    "line line line line line";

  @media screen and (max-width: ${theme.breakpoints.lg}px) {
    height: 100%;
    grid-template-columns: 2rem 1fr 1fr 0.5fr;
    grid-template-rows: 1.2fr 0.8fr 2px;
    grid-template-areas:
      "icon action action actions"
      "icon savings carbon actions"
      "line line line line";
  }
`;

export const Icon = styled.div`
  grid-area: icon;
  align-self: center;
  height: 2rem;
`;

export const Action = styled.div`
  grid-area: action;
`;

export const Savings = styled.div`
  grid-area: savings;
  align-items: end;
`;

export const Carbon = styled.div`
  grid-area: carbon;
`;

export const Actions = styled.div`
  grid-area: actions;
  align-self: center;
  justify-self: end;
`;

export const Line = styled.div`
  grid-area: line;
`;

export const ExternalLinkWrapper = styled(ExternalLink)`
  height: 100%;
  width: 100%;
  margin: 0;
  color: ${theme.color.text.text04};

  :hover {
    text-decoration: none;
    color: ${theme.color.text.text04};
  }
`;
