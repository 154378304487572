/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { yupResolver } from "@hookform/resolvers/yup";
import { SidebarProps } from "@nordcloud/gnui/dist/components/sidebar/types";
import { FormProvider, useForm } from "react-hook-form";
import { Button, FlexContainer, Sidebar, theme } from "@nordcloud/gnui";
import { noop } from "~/tools";
import {
  ThresholdName,
  ThresholdData,
  AmountSelector,
  EmailNotificationSettings,
} from "./components";
import {
  ADD_THRESHOLDS_DEFAULT_VALUES,
  ThresholdFormData,
  thresholdSchema,
} from "./validators";

type Props = {
  sidebarState: {
    title: SidebarProps["title"];
    isOpen: SidebarProps["isOpen"];
    onClick: SidebarProps["onClick"];
  };
};

export function ThresholdSidebar({ sidebarState }: Props) {
  const formMethods = useForm<ThresholdFormData>({
    resolver: yupResolver(thresholdSchema),
    defaultValues: ADD_THRESHOLDS_DEFAULT_VALUES,
  });

  const { handleSubmit } = formMethods;

  // TODO: connect to gql
  const onAddThreshold = () => {
    noop();
  };

  return (
    <FormProvider {...formMethods}>
      <form id="add-threshold" onSubmit={handleSubmit(onAddThreshold)}>
        <Sidebar {...sidebarState} width="42rem" footer={<SidebarFooter />}>
          <FlexContainer
            direction="column"
            alignItems="flex-start"
            gap={theme.spacing.spacing04}
          >
            <ThresholdName />
            <ThresholdData />
            <AmountSelector />
            <EmailNotificationSettings />
          </FlexContainer>
        </Sidebar>
      </form>
    </FormProvider>
  );
}

function SidebarFooter() {
  return (
    <FlexContainer
      padding={theme.spacing.spacing04}
      gap={theme.spacing.spacing04}
      alignItems="center"
    >
      <Button form="add-threshold" type="submit" icon="plus">
        Apply
      </Button>
      <Button severity="low">Cancel</Button>
    </FlexContainer>
  );
}
