/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { PropsWithChildren } from "react";
import { FlexContainer, theme, Box, Text } from "@nordcloud/gnui";

type Props = {
  stepNumber: number;
};

const NUMBER_BOX_SIZE = "1.5rem";

export function FormHint({ stepNumber, children }: PropsWithChildren<Props>) {
  return (
    <FlexContainer
      alignItems="flex-start"
      pb={theme.spacing.spacing03}
      gap={theme.spacing.spacing03}
      css={{ width: "100%" }}
    >
      <Box
        p={0}
        boxStyle="grey"
        css={{
          width: NUMBER_BOX_SIZE,
          height: NUMBER_BOX_SIZE,
          minWidth: NUMBER_BOX_SIZE,
          minHeight: NUMBER_BOX_SIZE,
        }}
      >
        <FlexContainer
          css={{ height: "100%", width: "100%" }}
          alignItems="center"
          justifyContent="center"
        >
          <Text tag="div" weight="medium" size="sm">
            {stepNumber}
          </Text>
        </FlexContainer>
      </Box>
      {children}
    </FlexContainer>
  );
}
