/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then } from "react-if";
import { Box } from "@nordcloud/gnui";
import { SelectableBox } from "~/components/SelectableBox";

type Props = {
  hasSelectable: boolean;
  selected: boolean;
  isSaveSetting: boolean;
  children: React.ReactNode;
  onClickBox?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export function SelectableContainer({
  hasSelectable,
  selected,
  isSaveSetting,
  children,
  onClickBox,
}: Props) {
  return (
    <If condition={hasSelectable}>
      <Then>
        <SelectableBox
          selected={selected}
          isSaveSetting={isSaveSetting}
          onClick={onClickBox}
        >
          {children}
        </SelectableBox>
      </Then>
      <Else>
        <Box boxStyle="lightGrey">{children}</Box>
      </Else>
    </If>
  );
}
