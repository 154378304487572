/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import dayjs from "dayjs";
import { dateFormat } from "~/constants";
import {
  formatMoneyWithoutSymbol,
  generateNumericArray,
  getFirstItem,
  getLastItem,
  isEmpty,
} from "~/tools";
import { getEndDateDayjs, isValueLessThanCent } from "../helpers";
import {
  CostVsBudgetForecastChartTimePoint,
  Granularity,
  TimePoint,
} from "../types";
import {
  getForecastValue,
  getForecastValueIncreaseByDay,
  getMonthStart,
  isCurrentMonth,
  mockDataForDisplay,
} from "../utils";

type Props = {
  data: TimePoint[];
  budgetData?: TimePoint[];
  forecastValue: number;
  granularity: Granularity;
  startDate: string;
  endDate: string;
};

const today = dayjs().startOf("day");

export function useCostForecast({
  data,
  budgetData,
  forecastValue,
  granularity,
  startDate,
  endDate,
}: Props) {
  const currentMonthStart = dayjs().startOf("month");
  const currentMonthEnd = dayjs().endOf("month");

  const lastCostValue = getLastItem(data) ?? {
    date: today.toString(),
    value: 0,
  };

  const daysToForecast = currentMonthEnd.diff(
    dayjs().subtract(1, "day"),
    "day"
  );

  const monthBudget =
    budgetData?.find(
      ({ date }) => date === currentMonthStart.format(dateFormat.shortDate)
    )?.value ?? 0;

  const currentMonthForecast = forecastValue > 0 ? forecastValue : monthBudget;

  const budgetLeft = currentMonthForecast - lastCostValue.value;

  const forecastValueByDay =
    currentMonthForecast !== 0
      ? getForecastValueIncreaseByDay(budgetLeft, daysToForecast, lastCostValue)
      : 0;

  const endDateDayjs = getEndDateDayjs(endDate, granularity);

  const xTicks = Math.ceil(endDateDayjs.diff(startDate, granularity, true));

  const xDates = useMemo(
    () =>
      generateNumericArray(xTicks).map((index) =>
        dayjs(startDate)
          .add(index, granularity)
          .startOf(granularity)
          .format(dateFormat.shortDate)
      ),
    [startDate, granularity, xTicks]
  );

  // Prepare array with all values in range
  const yData = useMemo(
    () =>
      xDates.map((date) => {
        const cost =
          data.find((timePoint) => timePoint.date === date)?.value.toFixed(2) ??
          0;
        const monthStart = getMonthStart(date);
        const budget =
          budgetData?.find((item) => item.date === monthStart)?.value ?? 0;

        return {
          date,
          value: Number(formatMoneyWithoutSymbol(cost)),
          budget,
          forecast: 0,
        };
      }),
    [data, xDates, budgetData]
  );

  const getDataWithForecast = () => {
    return yData?.map((item, index) => {
      if (granularity === Granularity.MONTHS) {
        return {
          ...item,
          forecast: isCurrentMonth(item.date) ? forecastValue : item.forecast,
        };
      }

      return mapDataWithForecast(yData, index, {
        dailyForecastIncrease: forecastValueByDay,
        totalForecast: forecastValue,
      });
    });
  };

  // Forecasting values only to those applications that have cost data until current day (or yesterday)
  const dataWithForecast = getDataWithForecast();

  const budgetDisplayData = getBudgetData(dataWithForecast, granularity) ?? [];

  const isCostsBelowCent = data.every(({ value }) =>
    isValueLessThanCent(value)
  );

  const hasNoData =
    isEmpty(data) ||
    data.every((timePoint) => timePoint.value === 0) ||
    isCostsBelowCent;

  const forecastValues = dataWithForecast.flatMap(({ forecast }) => forecast);

  return {
    hasNoData,
    yData,
    xDates,
    isCostsBelowCent,
    dataWithForecast,
    forecastValues,
    budgetDisplayData,
  };
}

function mapDataWithForecast(
  yData: CostVsBudgetForecastChartTimePoint[],
  index: number,
  forecasts: {
    dailyForecastIncrease: number;
    totalForecast: number;
  }
) {
  return yData.slice(0, index + 1).reduce((previous, current) => {
    if (dayjs(current.date).isBefore(today)) {
      return {
        ...current,
        value: current.value !== 0 ? current.value : previous.value,
      };
    }

    const { dailyForecastIncrease, totalForecast } = forecasts;

    return {
      ...current,
      forecast: getForecastValue(dayjs(current.date).isAfter(today), previous, {
        dailyForecastIncrease,
        totalForecast,
      }),
    };
  });
}

function getBudgetData(
  dataWithForecast: CostVsBudgetForecastChartTimePoint[],
  granularity: Granularity
) {
  const showUpdatedData = dataWithForecast.length === 1;

  if (showUpdatedData) {
    const data = getFirstItem(dataWithForecast);
    const isMonthly = granularity === Granularity.MONTHS;

    return [
      { ...mockDataForDisplay(data, -1, isMonthly) },
      {
        ...data,
      },
      { ...mockDataForDisplay(data, 1, isMonthly) },
    ];
  }

  return dataWithForecast.map((item) => {
    const forecastToDisplay = item.forecast - item.value;
    return {
      ...item,
      forecast: forecastToDisplay > 0 ? forecastToDisplay : 0,
    };
  });
}
