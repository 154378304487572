/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { DateRange, EstatePeriod, Mode } from "~/components";
import { dateFormat } from "~/constants";
import { isNotEmpty } from "~/tools";

export function getNumberFilterValue(value: string): number | undefined {
  return isNotEmpty(value) ? Number(value) : undefined;
}

export function isDoubleFilterSelected(from?: string, to?: string) {
  return isFilterSelected(from) || isFilterSelected(to);
}

function isFilterSelected(value?: string) {
  return isNotEmpty(value ?? "");
}

export function isExcluded(mode: Mode) {
  return mode === Mode.EXCLUDE ? true : undefined;
}

export function getStartDate(
  period: EstatePeriod,
  range: DateRange | undefined
) {
  const currentMonthStart = dayjs()
    .startOf("month")
    .format(dateFormat.shortDate);

  if (period === EstatePeriod.RANGE) {
    return range?.from ?? currentMonthStart;
  }

  return period === EstatePeriod.PREVIOUS_MONTH
    ? dayjs().subtract(1, "month").startOf("month").format(dateFormat.shortDate)
    : currentMonthStart;
}

export function getEndDate(period: EstatePeriod, range: DateRange | undefined) {
  const currentMonthEnd = dayjs().endOf("month").format(dateFormat.shortDate);

  if (period === EstatePeriod.RANGE) {
    return range?.to ?? currentMonthEnd;
  }

  return period === EstatePeriod.PREVIOUS_MONTH
    ? dayjs().subtract(1, "month").endOf("month").format(dateFormat.shortDate)
    : currentMonthEnd;
}
