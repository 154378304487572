/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { theme, Text } from "@nordcloud/gnui";
import {
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsAndCommitmentsFilters,
  ReservationsAndCommitmentsLookbackPeriod,
  ReservationsProvider,
} from "~/generated/graphql";
import { StyledRow } from "~/components/Tables/styles";
import { UsageCoverageData } from "../../types";
import { CoverageTabAccordionItem } from "./CoverageTabAccordionItem";
import { StyledTd } from "./styles";

type Props = {
  data: UsageCoverageData[];
  offeringType?: ReservationsAndCommitmentsCoverageOfferingType;
  serviceType: ReservationsAndCommitmentsCoverageServiceUsageType;
  timePeriod?: ReservationsAndCommitmentsLookbackPeriod;
  filters?: ReservationsAndCommitmentsFilters;
  provider: ReservationsProvider;
  platform: string;
  openSidebar: (rowDetails: UsageCoverageData) => void;
  hasGroupByParameter: boolean;
};

export function ReservationCoverageParent({
  data,
  offeringType,
  serviceType,
  timePeriod,
  filters,
  platform,
  provider,
  openSidebar,
  hasGroupByParameter,
}: Props) {
  return (
    <>
      <When condition={hasGroupByParameter}>
        <StyledRow css={{ background: theme.color.background.ui03 }}>
          <StyledTd withPadding width="100%" colSpan={5}>
            <Text weight="medium">{platform}</Text>
          </StyledTd>
        </StyledRow>
      </When>
      {data?.map((parentItem) => (
        <CoverageTabAccordionItem
          key={`${parentItem.resource}${parentItem.platform}${parentItem.region}`}
          resources={parentItem}
          serviceUsageType={serviceType}
          timePeriod={timePeriod}
          offeringType={offeringType}
          openSidebar={openSidebar}
          filters={filters}
          provider={provider}
        />
      ))}
    </>
  );
}
