/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { RadioGroup, Radio, theme, InputSearch } from "@nordcloud/gnui";
import { isNotEmpty } from "~/tools";
import { useHandleListSearch } from "../hooks";

type Props = {
  name: string;
  optionsList: string[];
  optionSelected: string;
  searchable?: boolean;
  onSelect: (valueClicked: string) => void;
};

export function RadioOptionList({
  name,
  optionsList,
  optionSelected,
  searchable,
  onSelect,
}: Props) {
  const [searchedList, searchValue, onSearchUpdate] =
    useHandleListSearch(optionsList);

  return (
    <div
      css={{
        width: "100%",
        maxHeight: "22rem",
        overflow: "scroll",
        position: "relative",
      }}
    >
      <When condition={searchable}>
        <div css={{ paddingBottom: theme.spacing.spacing04 }}>
          <InputSearch
            icon="search"
            value={searchValue}
            onChange={(event) => onSearchUpdate(event.currentTarget.value)}
          />
        </div>
      </When>
      <When condition={isNotEmpty(searchedList)}>
        <RadioGroup name={name}>
          {searchedList.map((option) => (
            <Radio
              key={option}
              value={option}
              id={option}
              labelText={option}
              checked={optionSelected === option}
              onClick={(event) => onSelect(event.currentTarget.value)}
            />
          ))}
        </RadioGroup>
      </When>
    </div>
  );
}
