/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme, Text } from "@nordcloud/gnui";

type WrapperProps = {
  width?: string;
  hideBorder?: boolean;
};

export const ColumnWrapper = styled.div<WrapperProps>`
  padding-left: ${theme.spacing.spacing04};
  padding-right: ${({ hideBorder }) =>
    hideBorder ? "none" : `${theme.spacing.spacing02}`};
  border-right: 1px solid ${theme.color.border.border01};
  border-right: ${({ hideBorder }) =>
    hideBorder ? "none" : `1px solid ${theme.color.border.border01}`};
  width: ${({ width }) => width ?? "15rem"};
`;

export const StyledText = styled(Text)`
  line-height: ${theme.lineHeight};
  text-align: right;
`;
