/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsAndCommitmentsLookbackPeriod,
  ReservationsAndCommitmentsFilters,
  ReservationsProvider,
  useReservationsAndCommitmentUsageCoverageQuery,
} from "~/generated/graphql";
import { getMinMax } from "~/components/Charts/utils";
import { useCurrency } from "~/hooks";
import {
  formatMoney,
  formatMoneyWithoutSymbol,
  getLastItem,
  isNotNil,
} from "~/tools";
import { NOT_APPLICABLE } from "../constants";
import { TimePoint } from "../types";

type UsageAnalysisProps = {
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  timePeriod: ReservationsAndCommitmentsLookbackPeriod;
  filters: ReservationsAndCommitmentsFilters;
  provider: ReservationsProvider;
};

export function useGetUsageAnalysisData({
  serviceUsageType,
  timePeriod,
  filters,
  provider,
}: UsageAnalysisProps) {
  const { currency, baseCurrency } = useCurrency();
  const hasCurrencyConversion = currency !== baseCurrency;
  const {
    data,
    loading,
    error: utilisationChartError,
  } = useReservationsAndCommitmentUsageCoverageQuery({
    variables: {
      lookbackPeriod: timePeriod,
      serviceUsageType,
      filters,
      provider,
    },
    fetchPolicy: "cache-and-network",
  });

  const usageCoverageData = data?.reservationsAndCommitmentUsageCoverage;

  const timeSeries: TimePoint[] =
    usageCoverageData?.timeSeries?.filter(isNotNil).map((item) => ({
      onDemandCost: formatMoneyWithoutSymbol(
        item?.onDemandCost ?? "0",
        currency,
        hasCurrencyConversion
      ).toString(),
      reservedInstanceCost: formatMoneyWithoutSymbol(
        item?.riCost ?? "0",
        currency,
        hasCurrencyConversion
      ).toString(),
      savingsPlanCost: formatMoneyWithoutSymbol(
        item?.spCost ?? "0",
        currency,
        hasCurrencyConversion
      ).toString(),
      totalCost: formatMoneyWithoutSymbol(
        item?.totalCost ?? "0",
        currency,
        hasCurrencyConversion
      ).toString(),
      date: item?.date ?? "",
    })) ?? [];

  const highestCost = getLastItem(
    getMinMax(
      timeSeries?.map((item) =>
        Number(
          formatMoneyWithoutSymbol(
            item?.totalCost ?? "0",
            currency,
            hasCurrencyConversion
          )
        )
      )
    )
  );

  return {
    averageCoverage: usageCoverageData?.averageCoverage
      ? `${usageCoverageData?.averageCoverage}%`
      : NOT_APPLICABLE,
    reservedInstaceCoverage: usageCoverageData?.riAverageCoverage
      ? `${usageCoverageData?.riAverageCoverage}%`
      : NOT_APPLICABLE,
    savingsPlanCoverage: usageCoverageData?.spAverageCoverage
      ? `${usageCoverageData?.spAverageCoverage}%`
      : NOT_APPLICABLE,
    savedCost: formatMoney(usageCoverageData?.savedCost ?? 0, currency),
    totalOnDemandCost: formatMoney(
      usageCoverageData?.totalOnDemandCost ?? 0,
      currency
    ),
    timeSeries,
    loading,
    utilisationChartError,
    highestCost,
  };
}
