/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Composition } from "./types";

export const getChildrenUids = (composition: Composition): string[] => {
  const ids: string[] = [];

  const recursivelyIterateChildren = (children: Composition[]) => {
    children.forEach((child) => {
      ids.push(child.uid);
      if (child.children) {
        recursivelyIterateChildren(child.children);
      }
    });
  };

  recursivelyIterateChildren(composition.children ?? []);

  return ids;
};

export const getParentsUids = (
  compositionUid: string,
  separator: string
): string[] => {
  const currentIdSpilt = compositionUid.split(separator);

  return currentIdSpilt.map((_, index) =>
    [...currentIdSpilt].splice(0, index + 1).join(separator)
  );
};
