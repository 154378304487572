/**
 * Copyright 2021-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then, When } from "react-if";
import {
  ExtendedTooltip,
  FlexContainer,
  SVGIcon,
  Text,
  theme,
} from "@nordcloud/gnui";
import { formatMoneyWithFallback } from "~/tools";

type Props = {
  value: number | null;
  currency: string;
  scrollToBudget?: () => void;
};

export function Budget({ value, currency, scrollToBudget }: Props) {
  const hasBudget = value != null;

  return (
    <FlexContainer alignItems="start" columnGap={theme.spacing.spacing02}>
      <Text
        tag="span"
        mt="2px"
        color={hasBudget ? undefined : "danger"}
        weight="medium"
      >
        <If condition={hasBudget}>
          <Then>{formatMoneyWithFallback(value, currency)}</Then>
          <Else>No Budget Set</Else>
        </If>
      </Text>
      <When condition={!hasBudget && scrollToBudget != null}>
        <ExtendedTooltip caption="Create New Budget">
          <SVGIcon
            name="plus"
            size="sm"
            css={{
              cursor: "pointer",
            }}
            onClick={scrollToBudget}
          />
        </ExtendedTooltip>
      </When>
    </FlexContainer>
  );
}
