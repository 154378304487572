
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "BusinessContextKpi": [
      "KpiCostAllocationCoverage",
      "KpiNonProdHoursBusinessContext",
      "KpiOverallWasteBusinessContext"
    ],
    "CloudWasteSettingParameter": [
      "CloudWasteSettingParameterInt"
    ],
    "EnvironmentKpi": [
      "KpiNonProdHoursForEnvironment",
      "KpiOverallWasteForEnvironment"
    ],
    "OrgUnitKpi": [
      "KpiNonProdHoursForOrgUnit",
      "KpiOverallWasteForOrgUnit"
    ],
    "Resource": [
      "CloudResource",
      "CostResource"
    ],
    "SearchResult": [
      "Account",
      "Application",
      "CloudResource",
      "CostResource",
      "Environment",
      "OrgUnitV2"
    ]
  }
};
      export default result;
    