/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ReactNode, useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Case, Default, Switch } from "react-if";
import { useNavigate } from "react-router-dom";
import { Role } from "~/generated/graphql";
import { ErrorMessage } from "~/components/Error";
import { LoaderWrap } from "~/components/Utils";
// importing from `/hooks/useQueryState` and `/hooks/useToggle` to avoid circular dependency
import { useQueryState } from "~/hooks/useQueryState";
import { useToggle } from "~/hooks/useToggle";
import { ROUTES } from "~/routing/routes";
import { getFirstItem, isNil, isNotEmpty, isNotNil, noop } from "~/tools";
import { CustomersContext } from "./customersContext";
import { NotAssignedView } from "./NotAssignedView";
import { SelectCustomerSidebar } from "./SelectCustomerSidebar";
import { SelectCustomerView } from "./SelectCustomerView";
import { useCustomerSelector } from "./useCustomerSelector";
import { useFetchCustomers } from "./useFetchCustomers";

export function CustomersProvider({ children }: { children: ReactNode }) {
  const { customers, loading, error } = useFetchCustomers();
  const { selectedCustomer, selectCustomer } = useCustomerSelector();
  const { state, removeFields } = useQueryState();

  const [showCustomerSelector, setShowCustomerSelector] = useState(
    () => selectedCustomer == null
  );
  // useState because we want to ensure that consecutive clicks will not change state in unexpected way
  const [isSidebarCloseDisabled, setIsSidebarCloseDisabled] = useState(false);
  const [showNoCustomerMessage, toggleShowNoCustomerMessage] = useToggle();

  const client = useApolloClient();
  const navigate = useNavigate();

  const userRole = customers.find(({ id }) => id === selectedCustomer?.id)
    ?.role;

  useEffect(() => {
    if (customers.length === 1) {
      const customer = getFirstItem(customers);
      selectCustomer(customer.id, customer.name, customer.organizationName);
    }

    // Update selected customer name and organization on customer list change. It could change in MCP
    const customer = customers.find((item) => item.id === selectedCustomer?.id);
    if (isNotNil(customer)) {
      selectCustomer(customer.id, customer.name, customer.organizationName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- setting selectedCustomer could lead to recursive re-renders
  }, [customers]);

  // use effect below allows us to handle changes of customer via URL param called `customerId`
  useEffect(() => {
    const customerId = state.customerId;
    const customer = customers.find(({ id }) => id === customerId);

    if (isNil(customerId) || selectedCustomer?.id === customerId) {
      removeFields(["customerId"]);
      return;
    }

    if (!loading) {
      if (isNotNil(customer)) {
        selectCustomer(customer.id, customer.name, customer.organizationName);
        // this is important to call when we open page for the first time
        // then no customer was assigned and the component to select customer has
        // major precedence and stays on the screen even though this algorithm
        // assigns customer and stores it in `localStorage`
        setShowCustomerSelector(false);
      } else {
        toggleShowNoCustomerMessage();
      }
      removeFields(["customerId"]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, loading, customers, selectedCustomer?.id, state.customerId]);

  const hasError = error != null;
  const hasCustomers = isNotEmpty(customers);

  const showSelectCustomerForm = hasCustomers && showCustomerSelector;

  const submitCustomer = async (id: string, name: string, orgName: string) => {
    // There is no point to reset data when the customer stays the same
    setIsSidebarCloseDisabled(true);
    if (selectedCustomer?.id !== id) {
      selectCustomer(id, name, orgName);
      try {
        await client.resetStore();
      } catch {
        setShowCustomerSelector(false);
      }
    }

    setShowCustomerSelector(false);

    navigate(ROUTES.index);
    setIsSidebarCloseDisabled(false);
  };

  return (
    <CustomersContext.Provider
      value={{
        customers,
        selectedCustomer,
        userRole: userRole ?? Role.ReadOnly,
        isAdminLite: userRole === Role.AdminLite,
        shouldShowNoCustomerMessage: showNoCustomerMessage,
        hideNoCustomerMessage: toggleShowNoCustomerMessage,
        showCustomerSelector: () => setShowCustomerSelector(true),
      }}
    >
      <LoaderWrap isBackground loading={loading}>
        <Switch>
          <Case condition={isNil(selectedCustomer)}>
            <SelectCustomerView
              customers={customers}
              onSubmit={submitCustomer}
            />
          </Case>
          <Case condition={hasCustomers === false}>
            <NotAssignedView />
          </Case>
          <Default>{children}</Default>
        </Switch>

        <SelectCustomerSidebar
          isOpen={isNotNil(selectedCustomer) && showSelectCustomerForm}
          customers={customers}
          onSubmit={submitCustomer}
          onClose={
            isSidebarCloseDisabled ? noop : () => setShowCustomerSelector(false)
          }
        />
        {hasError && (
          <ErrorMessage
            error={{
              message: error ?? "",
              name: "CustomersProvider",
            }}
          />
        )}
      </LoaderWrap>
    </CustomersContext.Provider>
  );
}
