/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FilterProps } from "../types";
import { RecordsDateRange } from "./RecordsDateRange";

export function UnassignedRecordsDateRange({
  filters,
  setFilters,
}: FilterProps) {
  return (
    <RecordsDateRange
      startDate={filters.assignmentUpdatedFrom}
      endDate={filters.assignmentUpdatedTo}
      label="Became unallocated"
      additionalInfo="Define the dates of estate records` environment change from allocated to unallocated."
      onEndDateChange={(date) =>
        setFilters({
          ...filters,
          assignmentUpdatedTo: date,
        })
      }
      onStartDateChange={(date) =>
        setFilters({
          ...filters,
          assignmentUpdatedFrom: date,
        })
      }
    />
  );
}
