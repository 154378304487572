import { ApolloCache } from "@apollo/client";
import {
  useRemoveAccountsMutation,
  CloudAccountsQuery,
  CloudAccountsQueryVariables,
  CloudAccountsDocument,
  CloudAccountsInput,
  CloudAccountsResponse,
} from "~/generated/graphql";
import { SUCCESS_TEXT } from "~/constants";
import { showSuccess } from "~/services/toast";
import { Maybe, handleError, toInputError } from "~/tools";

export function useCloudAccountBatchDelete({
  responseParameters,
}: {
  responseParameters: CloudAccountsInput;
}) {
  const [removeBatchAccounts, { client, ...mutationState }] =
    useRemoveAccountsMutation();

  const handleBatchDelete = async (selectedAccountsNids: string[]) => {
    try {
      const { data } = await removeBatchAccounts({
        variables: {
          input: {
            nids: selectedAccountsNids,
            responseParameters,
          },
        },
      });

      handleCacheUpdate(client.cache, data?.removeAccounts);

      if (selectedAccountsNids.length === 1) {
        showSuccess(SUCCESS_TEXT.accountRemoved);
      } else {
        showSuccess(SUCCESS_TEXT.accountsRemoved);
      }
    } catch (error) {
      handleError(toInputError(error));
    }
  };

  return [handleBatchDelete, mutationState] as const;
}

function handleCacheUpdate(
  cache: ApolloCache<unknown>,
  newAccounts?: Maybe<CloudAccountsResponse>
) {
  cache.evict({
    id: "ROOT_QUERY",
    fieldName: "cloudAccounts",
  });
  cache.updateQuery<CloudAccountsQuery, CloudAccountsQueryVariables>(
    {
      query: CloudAccountsDocument,
    },
    () => ({
      cloudAccounts: {
        count: newAccounts?.count ?? 0,
        pages: newAccounts?.pages ?? 0,
        accounts: newAccounts?.accounts ?? [],
      },
    })
  );
  cache.gc();
}
