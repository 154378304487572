/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { SVGIcon, Table, theme } from "@nordcloud/gnui";
import { Period } from "../CostAnalysis/types";

export const Th = styled(Table.th)<{ wide?: boolean }>`
  width: ${({ wide }) => (wide ? "13.5rem" : "7.5rem")};
  font-size: ${theme.fontSizes.sm};
  letter-spacing: normal;
  color: ${theme.colors.darks[4]};
  background: ${theme.color.background.ui03};
  font-weight: ${theme.fontWeights.medium};
  cursor: pointer;
  border-bottom: ${theme.borders.grey};
  border-top: ${theme.borders.grey};
  border-right: ${theme.borders.grey};
  text-align: right;

  &:first-of-type {
    width: 15rem;
    border-left: ${theme.borders.grey};
    text-align: left;
    position: sticky;
    z-index: ${theme.zindex.default};
    left: 0;
  }

  &:last-of-type {
    width: 13.5rem;
    position: sticky;
    z-index: ${theme.zindex.default};
    right: 0;
    border-left: ${theme.borders.grey};
  }
`;

export const FooterTh = styled(Table.td)`
  font-size: ${theme.fontSizes.md};
  letter-spacing: normal;
  color: ${theme.color.text};
  background: ${theme.color.background.ui03};
  font-weight: ${theme.fontWeights.medium};
  border-bottom: ${theme.borders.grey};
  border-top: ${theme.borders.grey};
  border-right: ${theme.borders.grey};
  text-align: right;
  white-space: nowrap;

  &:first-of-type {
    border-left: ${theme.borders.grey};
    text-align: left;
    position: sticky;
    z-index: ${theme.zindex.default};
    left: 0;
  }

  &:last-of-type {
    position: sticky;
    z-index: ${theme.zindex.default};
    right: 0;
    border-left: ${theme.borders.grey};
  }
`;

export const StyledTable = styled(Table)`
  border-collapse: separate;

  @media screen and (min-width: ${theme.breakpoints.lg}px) {
    table-layout: fixed;
  }
  tr:hover td {
    background-color: ${theme.color.background.ui03};
  }
`;

export const Td = styled(Table.td)<{ period: Period; isTotal: boolean }>`
  vertical-align: middle;
  text-align: right;
  background: ${({ isTotal }) =>
    isTotal ? theme.color.background.ui03 : theme.color.background.ui01};
  border: ${theme.borders.grey};

  &:first-of-type {
    background: ${theme.color.background.ui03};
    width: ${({ period }) => (period === Period.MONTH ? "30%" : undefined)};
    text-align: left;
    position: sticky;
    z-index: ${theme.zindex.default};
    left: 0;
  }

  &:last-of-type {
    background: ${({ period, isTotal }) =>
      period !== Period.MONTH || isTotal
        ? theme.color.background.ui03
        : theme.color.background.ui01};
    position: sticky;
    z-index: ${theme.zindex.default};
    right: 0;
  }
`;

export const ExpandRowIcon = styled(SVGIcon)<{ isExpanded: boolean }>`
  transition: "transform .1s linear";
  transform: ${(props) =>
    props.isExpanded ? "rotate(0deg)" : "rotate(-90deg)"};
`;
