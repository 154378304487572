/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import dayjs from "dayjs";
import { Box, FlexContainer, SVGIcon, Text, theme } from "@nordcloud/gnui";
import { IntegrationPluginSyncStatus } from "~/generated/graphql";
import { dateFormat } from "~/constants";
import { TurbonomicIntegrationPluginProp } from "../models/IntegrationPlugin";

type Props = {
  plugin: TurbonomicIntegrationPluginProp;
};

export function LastIntegrationSyncInfo({ plugin }: Props) {
  const isCompleted = React.useMemo(
    () => plugin.lastSyncStatus === IntegrationPluginSyncStatus.Completed,
    [plugin.lastSyncStatus]
  );

  const getMessageText = () => {
    const lastSyncTime = dayjs(plugin.lastSyncTime).format(dateFormat.fullDate);
    const state = isCompleted ? "successfully synced" : "failed to sync";

    return `Integration ${state} on ${lastSyncTime}`;
  };

  return (
    <Box boxStyle="lightGrey">
      <Text
        size="sm"
        mb={theme.spacing.spacing02}
        color={theme.color.text.text02}
      >
        Last integration sync check
      </Text>
      <FlexContainer
        gap={theme.spacing.spacing02}
        marginBottom={theme.spacing.spacing02}
      >
        <SVGIcon name={isCompleted ? "statusSuccess" : "statusError"} />
        <Text m={0} size="lg">
          {isCompleted ? "Success" : "Failure"}
        </Text>
      </FlexContainer>
      <Text size="sm" m={0} color={theme.color.text.text02}>
        {getMessageText()}
      </Text>
    </Box>
  );
}
