/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useFormContext } from "react-hook-form";
import { Else, If, Then, When } from "react-if";
import {
  Button,
  FlexContainer,
  Spinner,
  SVGIcon,
  Text,
  theme,
} from "@nordcloud/gnui";
import {
  ConnectionStatus,
  useTurbonomicConnectionStatusLazyQuery,
} from "~/generated/graphql";
import { handleError } from "~/tools";
import { getStatusMessage } from "../utils";
import { IntegrationPluginFormData } from "./types";

export function TestTurbonomicConnectionButton() {
  const [testConnectionQuery, { data, loading, called }] =
    useTurbonomicConnectionStatusLazyQuery({
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    });

  const { getValues, trigger } = useFormContext<IntegrationPluginFormData>();

  const connectionStatus = data?.turbonomicConnectionStatus;

  const testConnection = async () => {
    const { url, accountName, password } = getValues();
    await trigger(["accountName", "password", "url"]);

    if (url && accountName && password) {
      try {
        testConnectionQuery({
          variables: {
            connection: {
              url,
              username: accountName,
              password,
            },
          },
        });
      } catch (err) {
        handleError(err);
      }
    }
  };

  return (
    <FlexContainer gap={theme.spacing.spacing04}>
      <Button type="button" disabled={loading} onClick={testConnection}>
        Test Connection
      </Button>
      <If condition={loading}>
        <Then>
          <Spinner />
        </Then>
        <Else>
          <When condition={called}>
            <FlexContainer gap={theme.spacing.spacing02}>
              <SVGIcon
                name={
                  connectionStatus === ConnectionStatus.Connected
                    ? "statusSuccess"
                    : "statusError"
                }
              />
              <Text>{getStatusMessage(connectionStatus)}</Text>
            </FlexContainer>
          </When>
        </Else>
      </If>
    </FlexContainer>
  );
}
