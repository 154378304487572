/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Fragment } from "react";
import { Else, If, Then } from "react-if";
import { Table, Text, theme } from "@nordcloud/gnui";
import {
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsProvider,
} from "~/generated/graphql";
import { StyledTd } from "~/components/Tables/styles";
import { isNotEmpty, isNotNil } from "~/tools";
import { CoverageTableChildHeader } from "./CoverageTableChildHeader";
import { getCoverageHeaderColumns } from "./CoverageTableColumns";

type Props = {
  isReservedInstances: boolean;
  provider: ReservationsProvider;
  serviceUsageType?: ReservationsAndCommitmentsCoverageServiceUsageType;
};

export function ReservationsCoverageTableHeader({
  isReservedInstances,
  provider,
  serviceUsageType,
}: Props) {
  const columns = getCoverageHeaderColumns({
    isReservedInstances,
    provider,
    serviceUsageType,
  });
  return (
    <Table.tr>
      {columns?.map((column) => {
        const columnChildren = column?.childHeaders?.filter(isNotNil);
        const hasChildren = isNotEmpty(column?.childHeaders ?? []);
        return (
          <Fragment key={column.accessor}>
            <StyledTd
              hasRightBorder={column?.hasRightBorder ?? false}
              width={column?.width ?? "auto"}
              colSpan={column.hasColSpan ? 2 : 1}
              style={{
                background: column.styleTd.background,
                padding: column.styleTd.padding,
              }}
            >
              <If condition={hasChildren}>
                <Then>
                  <CoverageTableChildHeader
                    isReservedInstances
                    childHeaders={columnChildren}
                  />
                </Then>
                <Else>
                  <Text
                    align={column.alignment === "center" ? "center" : "left"}
                    color={theme.color.text.text02}
                    size="sm"
                    mb={0}
                  >
                    {column.headerName}
                  </Text>
                </Else>
              </If>
            </StyledTd>
          </Fragment>
        );
      })}
    </Table.tr>
  );
}
