import {
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsProvider,
  useReservationsAndMetadataQuery,
} from "~/generated/graphql";
import { inflect } from "~/tools";

export const useGetServiceTypeCount = (
  offeringType: ReservationsAndCommitmentsCoverageOfferingType,
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType,
  provider: ReservationsProvider
) => {
  const { data, loading } = useReservationsAndMetadataQuery({
    variables: {
      page: 0,
      limit: 50,
      offeringType,
      serviceUsageType,
      onlyCount: true,
      provider,
    },
  });

  const totalCount = Number(data?.reservationsAndMetadata?.count ?? 0);
  const type =
    offeringType ===
    ReservationsAndCommitmentsCoverageOfferingType.ReservedInstances
      ? "RI"
      : "SP";
  const inflectedVerb = totalCount > 1 ? "Exist" : "Exists";
  const linkText =
    totalCount > 0
      ? `${totalCount} ${inflect(type)(totalCount)} ${inflectedVerb}`
      : "";

  return {
    countLoading: loading,
    linkText,
  };
};
