/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ChangeEvent, useState } from "react";
import { When } from "react-if";
import styled from "styled-components";
import { Input, Message, Spacer, Text, theme } from "@nordcloud/gnui";
import { List } from "~/components/Styled";
import { LoaderWrap } from "~/components/Utils";
import { useCustomers } from "~/services/customers/customersContext";
import { firstOf, isEmpty, isNil } from "~/tools";
import { MappedCustomer } from "./types";

type Props = {
  onCustomerSelected: (id: string, name: string, orgName: string) => void;
  onSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
  customers: MappedCustomer[];
};

export function SelectCustomerForm({
  customers,
  onCustomerSelected,
  onSearchChange,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const tenant = firstOf(customers)?.tenants;
  const placeholderOrganization = isNil(tenant)
    ? "Sample Organization"
    : firstOf(tenant)?.name ?? "Sample Organization";

  return (
    <section id="uf-customer-switcher">
      <Input
        placeholder={`e.g. ${placeholderOrganization}`}
        disabled={isLoading}
        onChange={onSearchChange}
      />
      <Spacer height={theme.spacing.spacing04} />
      <LoaderWrap inContent loading={isLoading}>
        {customers.map((customer) => (
          <CustomerItem
            key={customer.organizationName}
            customer={customer}
            onCustomerSelected={(id, name, orgName) => {
              setIsLoading(true);
              onCustomerSelected(id, name, orgName);
            }}
          />
        ))}
        <When condition={isEmpty(customers)}>
          <Message status="discovery" image="info">
            It seems that the requested tenant/organization does not exist or
            you do not have the necessary access.
          </Message>
        </When>
      </LoaderWrap>
    </section>
  );
}

type CustomerItemProps = {
  customer: MappedCustomer;
  onCustomerSelected: (id: string, name: string, orgName: string) => void;
};

function CustomerItem({
  customer: { organizationName, tenants },
  onCustomerSelected,
}: CustomerItemProps) {
  const { selectedCustomer } = useCustomers();

  return (
    <CustomerItemWrapper>
      <Text mb={theme.spacing.spacing02} weight="medium">
        {organizationName}
      </Text>
      <List selectable>
        {tenants.map((tenant) => (
          <ListItem
            key={tenant.id}
            selected={tenant.id === selectedCustomer?.id}
            onClick={() =>
              onCustomerSelected(
                tenant.id,
                tenant.name,
                tenant.organizationName
              )
            }
          >
            <Text mb={0} size="sm">
              {tenant.name}
            </Text>
          </ListItem>
        ))}
      </List>
      <Spacer height={theme.spacing.spacing04} />
    </CustomerItemWrapper>
  );
}

const ListItem = styled.li<{ selected: boolean }>`
  padding: ${theme.spacing.spacing01};
  border-radius: ${theme.radiusDefault};
  background-color: ${({ selected }) =>
    selected ? theme.color.background.ui04 : undefined};
`;

const CustomerItemWrapper = styled.section`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.color.border.border01};
    margin-bottom: ${theme.spacing.spacing04};
  }
`;
