import { TableLoader } from "~/components";

export function ReservationPageLoader() {
  return (
    <TableLoader
      gapY={6}
      gapX={0}
      rows={[
        {
          height: 100,
          count: 1,
          items: [{ widthPercent: 100 }],
        },
        {
          height: 400,
          count: 1,
          items: [{ widthPercent: 100 }],
        },
        {
          height: 100,
          count: 1,
          items: [{ widthPercent: 100 }],
        },
        {
          height: 200,
          count: 1,
          items: [{ widthPercent: 100 }],
        },
      ]}
    />
  );
}
