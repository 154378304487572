/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloError } from "@apollo/client";
import dayjs from "dayjs";
import styled from "styled-components";
import {
  FlexContainer,
  ExtendedTooltip,
  SVGIcon,
  Text,
  theme,
  Sidebar,
} from "@nordcloud/gnui";
import { GetWorkflowHistoryEventQuery } from "~/generated/graphql";
import { LoaderWrap, ErrorWrap } from "~/components";
import { DetailsTitle, DetailsDescription } from "~/components/Details/styles";
import { dateFormat } from "~/constants";
import { isNotNil } from "~/tools";
import { EventStatus } from "../constants";
import { calculateRunDuration } from "../utils";
import { TriggerList } from "../WorkflowsList/TriggerList";
import { WorkflowHistorySidebarResultRow } from "./WorkflowHistorySidebarResultRow";

type SidebarProps = {
  isOpen: boolean;
  onClose: () => void;
  data: GetWorkflowHistoryEventQuery | undefined;
  loading: boolean;
  error?: ApolloError;
};

export function WorkflowHistorySidebar({
  isOpen,
  onClose,
  data,
  loading,
  error,
}: SidebarProps) {
  const historyEvent = data?.workflowHistoryEvent;
  const triggers = [historyEvent?.trigger].filter(isNotNil);
  const isRunFailed = historyEvent?.status
    ? EventStatus[historyEvent.status] === EventStatus.FAILED
    : true;
  const resultsTitle = isRunFailed ? "Failure reason" : "Results";

  const sidebarContent = historyEvent ? (
    <>
      <SidebarRow>
        <DetailsTitle>Workflow Name:</DetailsTitle>
        <Text css={{ wordBreak: "break-all" }}>
          {historyEvent.workflowName}
        </Text>
      </SidebarRow>

      <SidebarRow>
        <DetailsTitle>Status:</DetailsTitle>
        <FlexContainer css={{ width: "auto" }}>
          <StatusIcon status={historyEvent.status} />
          <StatusText status={historyEvent.status} />
        </FlexContainer>
      </SidebarRow>

      <SidebarRow>
        <DetailsTitle>Trigger:</DetailsTitle>
        <DetailsDescription>
          <TriggerList active triggers={triggers} />
        </DetailsDescription>
      </SidebarRow>

      <SidebarRow>
        <DetailsTitle>Start Date/Time:</DetailsTitle>
        <Text nowrap align="center" mb="0">
          {dayjs(historyEvent.startTime).format(dateFormat.fullDateShort)}
        </Text>
      </SidebarRow>

      <SidebarRow>
        <DetailsTitle>End Date/Time:</DetailsTitle>
        <Text nowrap align="center" mb="0">
          {dayjs(historyEvent.endTime).format(dateFormat.fullDateShort)}
        </Text>
      </SidebarRow>

      <SidebarRow>
        <DetailsTitle>Duration:</DetailsTitle>
        <Text nowrap align="center" mb="0">
          {calculateRunDuration(historyEvent.startTime, historyEvent.endTime)}
        </Text>
      </SidebarRow>

      <SidebarRow direction="column" alignItems="flex-start">
        <DetailsTitle>{resultsTitle}</DetailsTitle>
        <WorkflowHistorySidebarResultRow
          historyEvent={historyEvent}
          isRunFailed={isRunFailed}
        />
      </SidebarRow>
    </>
  ) : null;

  return (
    <Sidebar isOpen={isOpen} title="Workflow Run Details" onClick={onClose}>
      <LoaderWrap inContent loading={loading}>
        <ErrorWrap error={error}>{sidebarContent}</ErrorWrap>
      </LoaderWrap>
    </Sidebar>
  );
}

function getStatusText(status: string) {
  return status === EventStatus.COMPLETED ? "Success" : "Failure";
}

const SidebarRow = styled(FlexContainer)`
  margin-bottom: ${theme.spacing.spacing02};
`;

type TooltipCaptionProps = {
  text: string;
};

function TooltipCaption({ text }: TooltipCaptionProps) {
  return (
    <Text size="sm" color={theme.color.text.text04} mb="0">
      {text}
    </Text>
  );
}

type StatusIconProps = {
  status: string;
};

export function StatusIcon({ status }: StatusIconProps) {
  return (
    <ExtendedTooltip
      caption={<TooltipCaption text={getStatusText(status)} />}
      status={status === EventStatus.COMPLETED ? "success" : "danger"}
      css={{ position: "relative" }}
    >
      <SVGIcon
        name={
          status === EventStatus.COMPLETED ? "statusSuccess" : "statusError"
        }
        css={{
          paddingRight: theme.spacing.spacing03,
          marginTop: theme.spacing.spacing02,
        }}
      />
    </ExtendedTooltip>
  );
}

type StatusTextProps = {
  status: string;
};

function StatusText({ status }: StatusTextProps) {
  return (
    <FlexContainer direction="column" alignItems="flex-start">
      <Text nowrap align="center" mb="0">
        {getStatusText(status)}
      </Text>
    </FlexContainer>
  );
}
