/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ERROR_TEXT, SUCCESS_TEXT } from "~/constants";
import { showError, showSuccess } from "~/services/toast";
import { useOnboardAccount } from "../../hooks";
import { HybridForm } from "./HybridForm";
import { HybridFormData } from "./types";

type Props = {
  accountNid: string;
  clusterName: string;
  ownerId: string;
  onClose: () => void;
};

export function HybridOnboardSidebarContent({
  accountNid,
  onClose,
  clusterName,
  ownerId,
}: Props) {
  const [onboardAccount, { loading }] = useOnboardAccount();

  const onSubmit = async (formData: HybridFormData) => {
    try {
      await onboardAccount(accountNid, {
        name: formData.clusterName,
        ownerId: formData.ownerId,
      });

      showSuccess(SUCCESS_TEXT.accountOnboarded);
      onClose();
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  return (
    <HybridForm
      defaultValues={{ ownerId, clusterName }}
      loading={loading}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
}
