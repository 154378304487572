/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsProvider,
  ReservationsRecommendationPaymentOption,
} from "~/generated/graphql";
import {
  AwsPaymentOptions,
  awsPaymentOptionsFilter,
  AzurePaymentOptions,
  azurePaymentOptionsFilter,
  MULTI_REGIONS_RESOURCE,
  NESTING_LEVEL,
} from "./constants";
import { PaymentOptionsFilter, ReservationsTab } from "./types";

export function valueFormatter(
  data: string | null | undefined,
  fallback = "n/a"
): string {
  return data ?? fallback;
}

export type ExistingLink = {
  reservationId: string;
  unitsCount: number;
  productDescription: string;
  instanceType: string;
  paymentOption: string;
  offeringClass: string;
  expirationTimeInDays: string;
  to: string;
  region: string;
  expirationTimeInYears: string;
  commitment: string;
  savingsPlanType: string;
  ec2InstanceFamily: string;
  skuName: string;
  originalQuantity: string;
  billingPlan: string;
  yearlyUtilization: {
    utilization: string;
    benefit: number;
  };
};

export function getInstanceDetails(
  row: ExistingLink,
  isAzure: boolean,
  isReservedInstance: boolean
) {
  const {
    instanceType,
    unitsCount,
    productDescription,
    region,
    commitment,
    ec2InstanceFamily,
    savingsPlanType,
    skuName,
    originalQuantity,
  } = row;
  const skuDetails = skuName ? `${skuName} (${originalQuantity})` : "-";
  const commitmentData = savingsPlanType.includes("EC2Instance")
    ? ec2InstanceFamily
    : `commitment/hr:${Number(commitment).toFixed(2)}`;
  const savingsCommitment = region
    ? `${region} / ${commitmentData}`
    : commitmentData;
  const resource =
    unitsCount > 0 ? `${instanceType} (${unitsCount})` : `${instanceType}`;
  const details = isAzure ? skuDetails : resource;
  const riProductDescription = productDescription
    ? `${region} / ${productDescription}`
    : `${region}`;

  const description = isReservedInstance
    ? riProductDescription
    : savingsCommitment;

  const azureDescription = isAzure ? `${region}` : `${description}`;

  return {
    details,
    description,
    azureDescription,
  };
}

export function getPaymentOptions(provider: ReservationsProvider) {
  return provider === ReservationsProvider.Aws
    ? AwsPaymentOptions
    : AzurePaymentOptions;
}

export function getReservationsTabs(
  provider: ReservationsProvider
): ReservationsTab[] {
  const isAws = provider === ReservationsProvider.Aws;
  const computeTabs: ReservationsTab = {
    label: "Compute",
    icon: "compute",
    serviceType: ReservationsAndCommitmentsCoverageServiceUsageType.Compute,
    offeringType: isAws
      ? ReservationsAndCommitmentsCoverageOfferingType.All
      : ReservationsAndCommitmentsCoverageOfferingType.ReservedInstances,
    idText: isAws ? "compute" : "computeAzure",
    bannerText: isAws
      ? "Based on the historical usage of EC2, Lambda, and Fargate services during a specific period, the system collects usage data and finds no recommendations that can be made. \n Savings Plan Recommendations are generated for customers with an average On-Demand spend of $0.10/hour during the lookback period (30 or 60 days)."
      : "Based on the historical usage of Virtual Machines during a specific period, the system collects usage data and finds no coverage that can be made.",
  };

  const databaseTab: ReservationsTab = {
    label: "Database",
    icon: "databaseCategory",
    serviceType: ReservationsAndCommitmentsCoverageServiceUsageType.Database,
    idText: isAws ? "database" : "databaseAzure",
    offeringType:
      ReservationsAndCommitmentsCoverageOfferingType.ReservedInstances,
    bannerText:
      provider === ReservationsProvider.Aws
        ? "Based on the historical usage of RDS, Redshift, OpenSearch, or ElasticCache services during a specific period, the system collects usage data and finds no recommendations that can be made."
        : "Based on the historical usage of Cosmos DB, MySQL, MariaDB, PostgreSQL, SQL Database or SQL Managed Instance services during a specific period, the system collects usage data and finds no coverage that can be made.",
  };

  const machineLearningTab: ReservationsTab = {
    label: "Machine Learning",
    icon: "machineLearning",
    serviceType:
      ReservationsAndCommitmentsCoverageServiceUsageType.MachineLearning,
    offeringType: ReservationsAndCommitmentsCoverageOfferingType.SavingsPlans,
    idText: "machineLearning",
    bannerText:
      "Based on the historical usage of Sagemaker machines or no machines are used during a specific period, the system collects usage data and finds no recommendations that can be made.",
  };

  const analyticsTab: ReservationsTab = {
    label: "Analytics",
    icon: "analytics",
    serviceType: ReservationsAndCommitmentsCoverageServiceUsageType.Analytics,
    offeringType:
      ReservationsAndCommitmentsCoverageOfferingType.ReservedInstances,
    idText: "analytics",
    bannerText:
      "Based on the historical usage of Azure Synapse Analytics during a specific period, the system collects usage data and finds no coverage that can be made.",
  };

  const storageTab: ReservationsTab = {
    label: "Storage",
    icon: "storage",
    serviceType: ReservationsAndCommitmentsCoverageServiceUsageType.Storage,
    idText: "storage",
    offeringType:
      ReservationsAndCommitmentsCoverageOfferingType.ReservedInstances,
    bannerText:
      "Based on the historical usage of Azure Data Lake Storage, Blob Storage, General Block Blob Storage and Tiered Block Blob Storage services during a specific period, the system collects usage data and finds no coverage that can be made.",
  };

  return provider === ReservationsProvider.Aws
    ? [computeTabs, databaseTab, machineLearningTab]
    : [computeTabs, databaseTab, storageTab, analyticsTab];
}

export function convertTags(tags: string[]) {
  return tags?.map((tag) => {
    const [key = "", ...values] = tag.split(",");
    return {
      key,
      values: values ?? [""],
    };
  });
}

export function getPaymentOptionsFilterprovider(
  provider: ReservationsProvider
): PaymentOptionsFilter[] {
  return provider === ReservationsProvider.Aws
    ? awsPaymentOptionsFilter
    : azurePaymentOptionsFilter;
}

export function getDefaultPaymentOption(provider: ReservationsProvider) {
  const isAws = provider === ReservationsProvider.Aws;
  return isAws
    ? ReservationsRecommendationPaymentOption.PartialUpfront
    : ReservationsRecommendationPaymentOption.Monthly;
}

export const formatCost = (cost: number) => cost.toFixed(2);
export const checkIsMultiRegion = (
  platform: string,
  resource: string,
  nestingLevel: NESTING_LEVEL
) => {
  return nestingLevel === NESTING_LEVEL.PARENT
    ? Boolean(
        MULTI_REGIONS_RESOURCE[platform ?? ""] &&
          MULTI_REGIONS_RESOURCE[resource ?? ""]
      )
    : Boolean(
        MULTI_REGIONS_RESOURCE[platform ?? ""] &&
          !MULTI_REGIONS_RESOURCE[resource ?? ""]
      );
};
