/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { SetStateAction } from "react";
import { Maybe } from "graphql/jsutils/Maybe";
import { isNotEmpty, isNotNil } from "~/tools";
import { generateSelectedEnvs } from "../AppEnvSelectorPaginated/utils";
import { ApplicationOption, EnvironmentOption } from "../types";
import { filterUniqueEnvironments, findById, findByNid } from "./utils";

type Props = {
  apps: Maybe<ApplicationOption[]>;
  selectedEnvs: EnvironmentOption[];
  setSelectedEnvs: (value: SetStateAction<EnvironmentOption[]>) => void;
};

export function useEnvFilterSelector({
  apps = [],
  selectedEnvs,
  setSelectedEnvs,
}: Props) {
  const selectedApps =
    apps?.map((app) => (isAppSelected(app) ? app : null)).filter(isNotNil) ??
    [];

  function addEnvToSelectedEnvs(env: EnvironmentOption) {
    setSelectedEnvs((prevState) => [...prevState, env]);
  }

  function removeEnvFromSelectedEnvs(envNid: string) {
    setSelectedEnvs((prevState) =>
      prevState.filter(({ nid }) => nid !== envNid)
    );
  }

  function removeChildrenEnvsFromSelectedEnvs(app: ApplicationOption) {
    setSelectedEnvs((prevState) => {
      return prevState.filter((prevEnv) =>
        app.environments.every(
          (parentEnv) => parentEnv && parentEnv.nid !== prevEnv.nid
        )
      );
    });
  }

  function addChildrenEnvsToSelectedEnvs(
    parentApp: ApplicationOption,
    env?: EnvironmentOption
  ) {
    // Toggle-on other envs belonging to parent app if env is provided
    // Toggle-on all envs belonging to parent if env is not provided
    const selectedEnvNids =
      parentApp.environments
        .map((parentEnv) => {
          if (parentEnv && parentEnv.nid !== env?.nid) {
            return parentEnv.nid;
          }

          return undefined;
        })
        .filter(isNotNil) ?? [];
    setSelectedEnvs((prevState) =>
      filterUniqueEnvironments([
        ...prevState,
        ...generateSelectedEnvs(selectedEnvNids),
      ])
    );
  }

  function isAppSelected(parentApp: ApplicationOption) {
    return parentApp?.environments.every((parentEnv) =>
      selectedEnvs.some(({ nid }) => nid === parentEnv?.nid)
    );
  }

  const selectApp = (appNid: string) => {
    // When app is toggled on/off, remove all of its envs from selected envs
    const app = findById(apps, appNid);
    if (app) {
      const hasEnvironments =
        isNotNil(app.environments) && isNotEmpty(app.environments);

      if (hasEnvironments) {
        if (isAppSelected(app)) {
          removeChildrenEnvsFromSelectedEnvs(app);
        } else {
          addChildrenEnvsToSelectedEnvs(app);
        }
      }
    }
  };

  const selectEnv = (env: EnvironmentOption) => {
    const isEnvSelected = findByNid(selectedEnvs, env.nid);

    if (isEnvSelected) {
      removeEnvFromSelectedEnvs(env.nid);
    } else {
      addEnvToSelectedEnvs(env);
    }
  };

  return {
    selectedApps,
    selectEnv,
    selectApp,
  };
}
