/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  AzureBillingDataDocument,
  AzureBillingDataQuery,
  AzureBillingDataQueryVariables,
  useUpdateAzureBillingConfigV2Mutation,
} from "~/generated/graphql";
import { isNotNil } from "~/tools";
import { UpdateFormData } from "../validators";

export function useUpdateAzureBillingData() {
  const [updateBillingDataItem, { client, ...mutationState }] =
    useUpdateAzureBillingConfigV2Mutation();

  const updateAzureBillingData = async (data: UpdateFormData) => {
    const response = await updateBillingDataItem({
      variables: {
        input: {
          capacitorCustomerId: data.capacitorCustomerId,
          enrollmentNumber: data.enrollmentNumber,
          enrollmentAccessKey: data.enrollmentAccessKey,
        },
      },
    });
    const updatedItem = response.data?.updateAzureBillingConfigV2;

    if (isNotNil(updatedItem)) {
      client.cache.updateQuery<
        AzureBillingDataQuery,
        AzureBillingDataQueryVariables
      >(
        {
          query: AzureBillingDataDocument,
        },
        (billingData) => ({
          azureBillingData: (billingData?.azureBillingData ?? []).map((item) =>
            item?.capacitorCustomerId !== updatedItem.capacitorCustomerId
              ? updatedItem
              : item
          ),
        })
      );
    }
  };

  return [updateAzureBillingData, mutationState] as const;
}
