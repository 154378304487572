/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useLockBodyScroll } from "react-use";
import { Box, Button, FlexContainer, Sidebar, theme } from "@nordcloud/gnui";
import { DriftResolveStatus } from "~/generated/graphql";
import { useDisclosure } from "~/hooks";
import { noop } from "~/tools";
import { Filters, Modes } from "../types";
import { DriftFilters } from "./DriftFilters";

type Props = {
  isLoading: boolean;
  hasNoneSelected: boolean;
  onHandleSelection: (status: DriftResolveStatus) => void;
  filteringParams: { modes: Modes; filters: Filters } | undefined;
  onSetFilteringParams: ({
    modes,
    filters,
  }: {
    modes: Modes;
    filters: Filters;
  }) => void;
};

export function DriftHeader({
  isLoading,
  hasNoneSelected,
  onHandleSelection,
  filteringParams,
  onSetFilteringParams,
}: Props) {
  const { isOpen, open, close } = useDisclosure();

  useLockBodyScroll(isOpen);

  return (
    <Box boxStyle="lightGrey">
      <FlexContainer
        columnGap={theme.spacing.spacing04}
        direction="row-reverse"
      >
        <Button
          icon="filter"
          size="md"
          type="button"
          disabled={isLoading}
          onClick={isLoading ? noop : open}
        >
          Filters
        </Button>
        <Button
          icon="checkmark"
          size="md"
          type="button"
          disabled={hasNoneSelected || isLoading}
          onClick={() => onHandleSelection(DriftResolveStatus.Approve)}
        >
          Approve Selected
        </Button>
        <Button
          icon="close"
          size="md"
          type="button"
          disabled={hasNoneSelected || isLoading}
          onClick={() => onHandleSelection(DriftResolveStatus.Reject)}
        >
          Reject Selected
        </Button>
      </FlexContainer>
      <Sidebar
        width="35rem"
        isOpen={isOpen}
        title="Allocation Drifts Filters"
        onClick={close}
      >
        <DriftFilters
          defaultParams={filteringParams}
          onApply={(modes, filters) => {
            onSetFilteringParams({ modes, filters });
            close();
          }}
        />
      </Sidebar>
    </Box>
  );
}
