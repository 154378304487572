/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

type GridProps = {
  columnNo?: number;
  divider?: string;
};

export const GridDisplay = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${({ columnNo, divider }) => {
    switch (columnNo) {
      case 3:
        return divider ?? `3fr 3fr 3fr`;
      case 5:
        return divider ?? `repeat(5, 1fr)`;
      default:
        return divider ?? `1fr 1fr`;
    }
  }};
  padding: ${theme.spacing.spacing02};
  margin-right: ${theme.spacing.spacing02};
`;
