/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useUpdateAccountV3Mutation } from "~/generated/graphql";

export function useOnboardAccount() {
  const [updateAccount, { ...mutationState }] = useUpdateAccountV3Mutation();

  const onboard = async (nid: string, input: OnboardAccountInput) => {
    const status = "inactive";
    const { name, ownerId } = input;

    await updateAccount({
      variables: {
        nid,
        input: {
          status,
          name,
          ownerId,
        },
      },
    });
  };

  return [onboard, mutationState] as const;
}

type OnboardAccountInput = {
  name?: string;
  ownerId?: string;
};
