/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch } from "react";
import {
  Checkbox,
  CheckboxGroup,
  FlexContainer,
  SVGIcon,
  Spacer,
  Text,
  theme,
} from "@nordcloud/gnui";
import { ProviderFilterValues, ProvidersForFiltering } from "../types";
import { providerFilterIconProps } from "./constants";

type CheckboxGroupProps = {
  providerValues: ProviderFilterValues;
  updateProviderValues: Dispatch<ProvidersForFiltering>;
};

export function ProviderCheckboxGroup({
  providerValues,
  updateProviderValues,
}: CheckboxGroupProps) {
  return (
    <>
      <Spacer height={theme.spacing.spacing03} />
      <CheckboxGroup name="provider-checkbox-group">
        {providerFilterIconProps.map((checkbox) => (
          <CheckItem
            key={checkbox.provider}
            checkbox={checkbox}
            providerValues={providerValues}
            updateProviderValues={updateProviderValues}
          />
        ))}
      </CheckboxGroup>
    </>
  );
}

type CheckItemProps = {
  providerValues: ProviderFilterValues;
  updateProviderValues: Dispatch<ProvidersForFiltering>;
  checkbox: {
    label: string;
    provider: ProvidersForFiltering;
    icon: "aws" | "azure" | "gcp";
  };
};

function CheckItem({
  checkbox,
  providerValues,
  updateProviderValues,
}: CheckItemProps) {
  const { icon, label, provider } = checkbox;

  return (
    <FlexContainer>
      <div>
        <Checkbox
          id={provider}
          checked={providerValues[provider]}
          onChange={() => updateProviderValues(provider)}
        />
      </div>
      <FlexContainer
        columnGap={theme.spacing.spacing02}
        mb={theme.spacing.spacing04}
      >
        <SVGIcon name={icon} />
        <Text mb={0}>{label}</Text>
      </FlexContainer>
    </FlexContainer>
  );
}
