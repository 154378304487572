/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useUpdateIbmAccountCredentialsBatchMutation } from "~/generated/graphql";
import { Provider } from "../../types";
import { UpdateHandlerVariables } from "../types";

export function useBatchUpdateIbmAccountCredentials() {
  const [updateGcpCredentials, ...mutationState] =
    useUpdateIbmAccountCredentialsBatchMutation();

  const handleGcpCredentialsUpdate = async (
    variables: UpdateHandlerVariables<Provider.Ibmcloud>,
    selectedNids: string[]
  ) => {
    const { apikey } = variables;

    await updateGcpCredentials({
      variables: {
        nids: selectedNids,
        input: { apikey },
      },
    });
  };

  return [handleGcpCredentialsUpdate, mutationState] as const;
}
