/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

type Props = {
  email: string;
};

export function Mailto({ email }: Props) {
  return <a href={`mailto:${email}`}>{email}</a>;
}
