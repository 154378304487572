/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Sidebar, theme, Spacer } from "@nordcloud/gnui";
import { ProviderTab } from "../types";
import { FormSwitch } from "./forms/FormSwitch";

type Props = {
  nids: string[];
  isOpen: boolean;
  provider: ProviderTab;
  onClose: () => void;
};

export function AccountCredentialsUpdateSidebar({
  nids,
  isOpen,
  provider,
  onClose,
}: Props) {
  return (
    <Sidebar
      width="47rem"
      isOpen={isOpen}
      title="Update Credentials"
      onClick={onClose}
    >
      <FormSwitch provider={provider} nids={nids} onClose={onClose} />
      <Spacer height={theme.spacing.spacing04} />
    </Sidebar>
  );
}
