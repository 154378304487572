/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { FlexContainer, Label, theme } from "@nordcloud/gnui";

export const TableFieldWrapper = styled.div<{ width: string }>`
  width: ${({ width }) => width};

  > * {
    margin-bottom: 0;
  }
`;

export const TableFieldLabel = styled(Label)`
  text-transform: none;
  font-size: ${theme.fontSizes.sm};
  margin-bottom: 0;
`;

export const TableRow = styled(FlexContainer)`
  align-items: center;
  padding-bottom: ${theme.spacing.spacing03};
  margin-bottom: ${theme.spacing.spacing03};
  border-bottom: solid 1px ${theme.color.border.border01};
`;
