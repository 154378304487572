/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import { Else, If, Then, Unless, When } from "react-if";
import {
  FlexContainer,
  SVGIcon,
  Checkbox,
  HeaderIcon as ToggleIcon,
  Tag,
  theme,
  Text,
} from "@nordcloud/gnui";
import { isNotEmpty } from "~/tools";
import * as Styled from "./styled";
import { Composition, RenderCompositionProps } from "./types";
import { getChildrenUids, getParentsUids } from "./utils";

export function RenderComposition({
  uid,
  label,
  budget,
  children = [],
  selectedList,
  setSelectedList,
  expandedList,
  setExpandedList,
  separator,
  parent,
  behaviour,
}: RenderCompositionProps) {
  const handleSelect = (treeItem: Composition) => {
    if (!selectedList.includes(treeItem.uid)) {
      const uids = [treeItem.uid, ...getChildrenUids(treeItem)];
      setSelectedList([...new Set([...selectedList, ...uids])]);
    } else {
      if (behaviour === "biDirectional") {
        const uids = [
          treeItem.uid,
          ...getChildrenUids(treeItem),
          ...getParentsUids(treeItem.uid, separator),
        ];

        return setSelectedList(selectedList.filter((id) => !uids.includes(id)));
      }

      setSelectedList(selectedList.filter((id) => id !== treeItem.uid));
    }
  };

  const handleExpand = (clickedPathId: string) => {
    if (expandedList.includes(clickedPathId)) {
      setExpandedList(
        expandedList.filter((pathId) => pathId !== clickedPathId)
      );
    } else {
      setExpandedList([...expandedList, clickedPathId]);
    }
  };

  const isChildrenPresent = isNotEmpty(children);
  const isItemSelected = selectedList.includes(uid);
  const shouldShowChildren = expandedList.includes(uid) && isChildrenPresent;

  useEffect(() => {
    if (
      isChildrenPresent &&
      children.every((child) => selectedList.includes(child.uid)) &&
      !isItemSelected &&
      behaviour === "biDirectional"
    ) {
      setSelectedList([...selectedList, uid]);
    }
  }, [
    selectedList,
    setSelectedList,
    parent,
    uid,
    behaviour,
    children,
    isChildrenPresent,
    isItemSelected,
  ]);

  useEffect(() => {
    if (
      parent &&
      selectedList.includes(parent.uid) &&
      !isItemSelected &&
      behaviour === "biDirectional"
    ) {
      const childrenUids = getChildrenUids(parent);
      setSelectedList([...selectedList, ...childrenUids]);
    }
  }, [selectedList, behaviour, isItemSelected, parent, setSelectedList]);

  return (
    <Styled.Indentation isRootElement={uid.split(separator).length === 1}>
      <Styled.TreeItem>
        <FlexContainer columnGap={theme.spacing.spacing03}>
          <If condition={isChildrenPresent}>
            <Then>
              <ToggleIcon
                animate={expandedList.includes(uid)}
                cursor="pointer"
                onClick={() => handleExpand(uid)}
              >
                <SVGIcon name="down" />
              </ToggleIcon>
            </Then>
            <Else>
              <Styled.MinusIcon>
                <ToggleIcon>
                  <SVGIcon size="sm" name="minus" />
                </ToggleIcon>
              </Styled.MinusIcon>
            </Else>
          </If>
          <Styled.CheckboxWrap>
            <Checkbox
              css={{}}
              checked={isItemSelected}
              id={uid}
              onChange={() =>
                handleSelect({ uid, label, children, budget, behaviour })
              }
            />
          </Styled.CheckboxWrap>
          <Text m={0} ml="-.3rem">
            {label}
          </Text>
          <Unless condition={Boolean(budget.yearlySum)}>
            <Tag css={{ margin: 0 }} text="Budget Undefined" />
          </Unless>
        </FlexContainer>
      </Styled.TreeItem>
      <When condition={shouldShowChildren}>
        {children.map((child) => (
          <RenderComposition
            key={child.uid}
            parent={{ uid, label, children, budget, behaviour }}
            setSelectedList={setSelectedList}
            setExpandedList={setExpandedList}
            selectedList={selectedList}
            expandedList={expandedList}
            separator={separator}
            {...child}
          />
        ))}
      </When>
    </Styled.Indentation>
  );
}
