/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useFormState } from "react-hook-form";
import { Text, theme } from "@nordcloud/gnui";
import { WorkflowExternalIntegrationType } from "~/generated/graphql";
import { isNotNil } from "~/tools";
import { ComponentGroup } from "../../constants";
import { WorkflowFormData, WorkflowFormMode } from "../../types";
import { ComponentHolder } from "./ComponentHolder";
import {
  SendDataComponentInitialValues,
  useHoldSendDataComponent,
} from "./hooks/useHoldSendDataComponent";

type Props = {
  formMode: WorkflowFormMode;
  onSettingsClick?: () => void;
  initialValues?: SendDataComponentInitialValues;
  type?: WorkflowExternalIntegrationType;
  isEmpty?: boolean;
};

export function SendDataHolder({
  formMode,
  onSettingsClick,
  initialValues,
  type,
  isEmpty = false,
}: Props) {
  const { errors } = useFormState<WorkflowFormData>();
  const {
    handleDrop,
    handleRemove,
    description,
    tagText,
    tagTextColor,
    title,
    iconName,
    settingsDisabled,
  } = useHoldSendDataComponent({
    initialValues,
    type,
  });

  return (
    <ComponentHolder
      formMode={formMode}
      title={title ?? ""}
      tooltipCaption={description}
      color={theme.color.support.orange}
      componentIcon={iconName}
      onSettingsClick={onSettingsClick}
      timelineIcon="send"
      timelineLabel="Send Data"
      isLastItem
      componentGroup={ComponentGroup.OUTPUT}
      displayPlaceholder={isEmpty}
      onDrop={handleDrop}
      onRemoveClick={handleRemove}
      hasError={isNotNil(errors.sendDataComponents)}
      settingsDisabled={settingsDisabled}
    >
      <Text size="sm" color={tagTextColor}>
        {tagText}
      </Text>
    </ComponentHolder>
  );
}
