/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as yup from "yup";
import {
  AwsFormFields,
  AzureFormFields,
  GcpFormFields,
  VMWareFormFields,
  IbmCloudFormFields,
  AdditionalSettingsFields,
} from "../../types";
import { hasFiles, isJson } from "../utils";

const REQUIRED_VALIDATION_MESSAGE = "This field is required.";
const ERROR_FILE_IS_REQUIRED = "You have to provide a file.";
const ERROR_FILE_TYPE = "Wrong file provided.";

export const addAwsSchema = yup.object().shape({
  [AwsFormFields.ACCOUNT_IDS]: yup
    .array()
    .of(yup.string().min(1, "ID must not be an empty string"))
    .min(1, "At least one ID must be provided")
    .required(REQUIRED_VALIDATION_MESSAGE),
  [AwsFormFields.ACCOUNT_OWNER]: yup
    .string()
    .required(REQUIRED_VALIDATION_MESSAGE),
  [AdditionalSettingsFields.DISPLAY_NAME]: yup.string(),
  [AdditionalSettingsFields.DESCRIPTION]: yup.string(),
  [AdditionalSettingsFields.CONTACT_PERSON]: yup.string(),
  [AwsFormFields.ROLE_EXTERNAL_ID]: yup
    .string()
    .required(REQUIRED_VALIDATION_MESSAGE),
  [AwsFormFields.ROLE_NAME]: yup.string().required(REQUIRED_VALIDATION_MESSAGE),
});

export const addAzureSchema = yup.object().shape({
  [AzureFormFields.SUBSCRIPTION_IDS]: yup
    .array()
    .of(yup.string().min(1, "Subscription ID is required"))
    .min(1, "At least one ID must be provided")
    .required(REQUIRED_VALIDATION_MESSAGE),
  [AzureFormFields.ACCOUNT_OWNER]: yup
    .string()
    .required("Account Owner is required"),
  [AdditionalSettingsFields.DISPLAY_NAME]: yup.string(),
  [AdditionalSettingsFields.DESCRIPTION]: yup.string(),
  [AdditionalSettingsFields.CONTACT_PERSON]: yup.string(),
  [AzureFormFields.APP_ID]: yup.string().required("App ID is required"),
  [AzureFormFields.PASSWORD]: yup.string().required("Password is required"),
  [AzureFormFields.TENANT]: yup.string().required("Tenant is required"),
});

export const addGcpSchema = yup.object().shape({
  [GcpFormFields.ORGANISATION_ID]: yup
    .string()
    .min(1, "ID must not be an empty string")
    .required(REQUIRED_VALIDATION_MESSAGE),
  [GcpFormFields.ACCOUNT_OWNER]: yup
    .string()
    .required(REQUIRED_VALIDATION_MESSAGE),
  [AdditionalSettingsFields.DISPLAY_NAME]: yup.string(),
  [AdditionalSettingsFields.DESCRIPTION]: yup.string(),
  [AdditionalSettingsFields.CONTACT_PERSON]: yup.string(),
  [GcpFormFields.GCP_FILE]: yup
    .mixed()
    .test("file", ERROR_FILE_IS_REQUIRED, (value: FileList) => {
      return hasFiles(value ?? []);
    })
    .test("fileType", ERROR_FILE_TYPE, (value: FileList) => {
      return hasFiles(value ?? []) && isJson(value[0]);
    }),
});

export const addIbmCloudSchema = yup.object().shape({
  [IbmCloudFormFields.ACCOUNT_ID]: yup
    .string()
    .required("Main Account ID is required"),
  [IbmCloudFormFields.ACCOUNT_OWNER]: yup
    .string()
    .required("Account owner field is required"),
  [AdditionalSettingsFields.DISPLAY_NAME]: yup.string(),
  [AdditionalSettingsFields.DESCRIPTION]: yup.string(),
  [AdditionalSettingsFields.CONTACT_PERSON]: yup.string(),
  [IbmCloudFormFields.API_KEY]: yup
    .string()
    .required(REQUIRED_VALIDATION_MESSAGE),
});

export const addVMWareSchema = yup.object().shape({
  [VMWareFormFields.NAME]: yup.string().required("VMware name is required"),
  [VMWareFormFields.OWNER]: yup.string().required("VMware owner is required"),
});
