/**
 * Copyright 2021-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { FlexContainer, SVGIcon, Text, theme } from "@nordcloud/gnui";
import { formatNumberToLocaleString, inflect } from "~/tools";
import { getCarbonFootprint } from "./helpers";

const THRESHOLD_MAX = 100_000;
const THRESHOLD_MIN = 1;

const compact = (value: number) =>
  `${formatNumberToLocaleString(Number((value / 1000).toFixed(0)))}K`;

function format(value: number) {
  const absoluteValue = Math.abs(value);
  if (absoluteValue > THRESHOLD_MAX) {
    return compact(value);
  }
  return formatNumberToLocaleString(Math.round(value));
}

function formatPhone(value: number) {
  if (Math.abs(value) < THRESHOLD_MIN) {
    return value < 0 ? ">-1" : "<1";
  }

  return format(value);
}

export function TooltipCaption(co2e: number) {
  const carbonFootprintConfig = {
    cars: 250, // 1 km generates 250 grams of CO2e
    phones: 8.2, // 1 phone charging generates 8.2 grams of CO2e
  };

  const carbonFootprint = getCarbonFootprint(co2e, carbonFootprintConfig);

  const showCar = Math.abs(carbonFootprint.cars) >= 0.5;

  return (
    <FlexContainer
      direction="column"
      alignItems="start"
      rowGap={theme.spacing.spacing03}
      padding={theme.spacing.spacing01}
    >
      <When condition={showCar}>
        <FlexContainer css={{ columnGap: theme.spacing.spacing02 }}>
          <SVGIcon name="cars" css={{ fill: theme.color.text.text04 }} />
          <FlexContainer direction="column" alignItems="start">
            <Text
              tag="div"
              weight="medium"
              color={theme.color.text.text04}
              css={{ lineHeight: 1.1 }}
            >
              {format(carbonFootprint.cars)}
            </Text>
            <Text tag="div" size="sm" color={theme.color.text.text04}>
              km driven by the car
            </Text>
          </FlexContainer>
        </FlexContainer>
      </When>
      <FlexContainer css={{ columnGap: theme.spacing.spacing02 }}>
        <SVGIcon name="smartphone" css={{ fill: theme.color.text.text04 }} />
        <FlexContainer direction="column" alignItems="start">
          <Text
            tag="div"
            weight="medium"
            color={theme.color.text.text04}
            css={{ lineHeight: 1.1 }}
          >
            {formatPhone(carbonFootprint.phones)}
          </Text>
          <Text tag="div" size="sm" color={theme.color.text.text04}>
            {inflect("smartphone")(carbonFootprint.phones)} charged
          </Text>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
}
