/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { isEmpty } from "lodash";
import {
  Anomaly,
  CostAnalysisFields,
  Granularity,
  TableData,
} from "~/components/Charts/CostAnalysis/types";
import { dateFormat } from "~/constants";
import { generateNumericArray } from "~/tools";

type DatePoints = {
  startDate: string;
  endDate: string;
};

export function mapAnomaliesChartData(
  anomaliesData: Anomaly[],
  datePoints: DatePoints,
  granularity: Granularity
) {
  const { startDate, endDate } = datePoints;

  if (granularity === Granularity.MONTHS) {
    const months = generateNumericArray(
      getMonthDifference(startDate, endDate)
    ).map((_, index) =>
      dayjs(startDate).add(index, "month").format(dateFormat.monthYear)
    );

    return months.map((month) => {
      const cost = getRangeCost(anomaliesData, {
        startDate: getStartDate(month, startDate),
        endDate: getEndDate(month, endDate),
      });

      if (isEmpty(cost.date)) {
        return {
          ...cost,
          date: month,
        };
      }

      return {
        ...cost,
        anomaly: isAnomaly(cost.anomalyCost),
      };
    });
  }

  return (
    anomaliesData
      .filter(
        ({ date }) =>
          dayjs(date).isAfter(startDate) || dayjs(date).isSame(startDate)
      )
      .filter(({ date }) =>
        dayjs(date).isBefore(dayjs(endDate).endOf("day"))
      ) ?? []
  );
}

function isAnomaly(anomalyCost?: number | null): boolean {
  if (anomalyCost) {
    return anomalyCost > 0;
  }
  return false;
}

function getStartDate(month: string, startDate: string) {
  if (dayjs(startDate).format(dateFormat.monthYear) === month) {
    return startDate;
  }
  return dayjs(month).startOf("month").format(dateFormat.shortDate);
}

function getEndDate(month: string, endDate: string) {
  if (dayjs(endDate).format(dateFormat.monthYear) === month) {
    return endDate;
  }
  return dayjs(month).endOf("month").format(dateFormat.shortDate);
}

function getRangeCost(anomaliesData: Anomaly[], datePoints: DatePoints) {
  const { startDate, endDate } = datePoints;

  return anomaliesData
    .filter((anomaly) =>
      dayjs(anomaly.date).isBetween(startDate, endDate, "day", "[]")
    )
    .reduce(
      (previous, current) => ({
        anomaly: current.anomaly,
        anomalyCost: (previous.anomalyCost ?? 0) + (current.anomalyCost ?? 0),
        cost: previous.cost + current.cost,
        date: dayjs(current.date).startOf("month").format(dateFormat.shortDate),
      }),
      {
        anomaly: false,
        date: "",
        cost: 0,
        anomalyCost: 0,
      }
    );
}

export function getMonthDifference(start: string, end: string) {
  const startDate = new Date(start);
  const endDate = new Date(end);
  return (
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear()) +
    1
  );
}

export function getCategoriesList(
  tableData: TableData[],
  isSavingsPlans?: boolean
) {
  const categories = getCostSplitGroupNames(tableData).filter(
    (category) => category !== CostAnalysisFields.TOTAL_COST
  );

  if (isSavingsPlans) {
    return [...categories, CostAnalysisFields.SAVINGS_PLANS];
  }

  return categories;
}

export function getCostSplitGroupNames(tableData: TableData[]) {
  return Object.values(tableData).flatMap(({ field }) => String(field));
}
