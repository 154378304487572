/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState, useEffect } from "react";

export function useHandleListSearch(optionsList: string[]) {
  const [searchValue, setSearchValue] = useState("");
  const [searchedList, setSearchedList] = useState<string[]>(optionsList);

  const onSearchUpdate = (string: string) => {
    setSearchValue(string);
  };

  useEffect(() => {
    setSearchedList(
      optionsList.filter((option) =>
        option.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [searchValue, optionsList]);

  useEffect(() => {
    setSearchValue("");
  }, [optionsList]);

  return [searchedList, searchValue, onSearchUpdate] as const;
}
