/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

type ComponentIconWrapperProps = {
  color: string;
};

export const ComponentIconWrapper = styled.div<ComponentIconWrapperProps>`
  border-radius: ${theme.radiusDefault};
  background: ${({ color }) => color};
  padding: ${theme.spacing.spacing01};
  width: ${theme.iconSize.smd};
  height: ${theme.iconSize.smd};
`;
