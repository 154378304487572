/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloCache } from "@apollo/client";
import {
  DriftResolveStatus,
  DriftsDocument,
  DriftsQuery,
  DriftsQueryVariables,
  ResolveDriftsMutation,
  useResolveDriftsMutation,
} from "~/generated/graphql";
import { showSuccess } from "~/services/toast";
import { handleError, inflect, Maybe, toInputError } from "~/tools";
import { mapFilters, ResponseParameters } from "./utils";

export function useDriftUpdate() {
  const [updateDrift, { client, ...mutationState }] = useResolveDriftsMutation({
    notifyOnNetworkStatusChange: true,
  });

  const update = async (
    status: DriftResolveStatus,
    ids: string[],
    queryParameters: ResponseParameters
  ) => {
    try {
      const responseParameters = {
        page: queryParameters.page,
        limit: queryParameters.limit,
        filters: mapFilters(
          queryParameters.filteringParams?.modes,
          queryParameters.filteringParams?.filters
        ),
      };

      const { data } = await updateDrift({
        variables: {
          input: {
            status,
            nids: ids,
            responseParameters,
          },
        },
      });

      showSuccess(
        `Selected ${inflect("drift")(ids.length)} ${inflect(
          "has",
          "have"
        )(ids.length)} been ${
          status === DriftResolveStatus.Approve ? "approved" : "rejected"
        }.`
      );

      handleCacheUpdate(data, client.cache, { input: responseParameters });
    } catch (error) {
      handleError(toInputError(error));
    }
  };

  return [update, mutationState] as const;
}

function handleCacheUpdate(
  data: Maybe<ResolveDriftsMutation>,
  cache: ApolloCache<unknown>,
  variables: DriftsQueryVariables
) {
  cache.evict({ id: "ROOT_QUERY", fieldName: "estateAllocationDrifts" });
  cache.updateQuery<DriftsQuery, DriftsQueryVariables>(
    {
      query: DriftsDocument,
      variables,
    },
    (driftData) => {
      if (driftData?.estateAllocationDrifts == null) {
        return;
      }

      return {
        estateAllocationDrifts: {
          count: data?.resolveDrifts.count ?? 0,
          pages: data?.resolveDrifts.pages ?? 0,
          records: data?.resolveDrifts.records ?? [],
        },
      };
    }
  );
  cache.gc();
}
