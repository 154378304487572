/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { TableLoader } from "~/components";
import { useQueryState } from "~/hooks";

export function DriftsLoader() {
  const {
    state: { limit },
  } = useQueryState();

  return (
    <TableLoader
      gapY={10}
      gapX={2}
      rows={[
        {
          count: limit,
          height: 45,
          items: [
            {
              widthPercent: 5, // checkbox
            },
            {
              widthPercent: 5, // logo
            },
            {
              widthPercent: 30, // name
            },
            {
              widthPercent: 20, // current
            },
            {
              widthPercent: 5, // arrow
            },
            {
              widthPercent: 20, // target
            },
            {
              widthPercent: 15, // actions
            },
          ],
        },
      ]}
    />
  );
}
