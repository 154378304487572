import { theme } from "@nordcloud/gnui";
import { isEmpty } from "~/tools";
import { COLORS } from "./constants";

type Props = {
  nid: string;
  isUnallocated: boolean;
  forecast: string;
  currentMonth: string;
  previousMonth: string;
  index: number;
};

export function useTopSpendingAppsWidgetRow({
  nid,
  isUnallocated,
  forecast,
  currentMonth,
  previousMonth,
  index,
}: Props) {
  const hasValidForecast =
    Number(forecast) < 0 && Number(forecast) < Number(currentMonth);

  const rectColor = isUnallocated ? theme.color.support.red : COLORS[index];

  const linkColor = isUnallocated
    ? theme.color.text.error
    : theme.color.interactive.link;

  const currentMonthColor = isUnallocated ? theme.color.text.error : "inherit";

  const forecastColor =
    parseFloat(forecast) <= parseFloat(previousMonth)
      ? theme.color.text.success
      : theme.color.text.error;

  const borderTop = isEmpty(nid)
    ? `2px solid ${theme.color.border.border01}`
    : undefined;

  return {
    rectColor,
    linkColor,
    currentMonthColor,
    forecastColor,
    borderTop,
    hasValidForecast,
  };
}
