/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { ModalConfirm, ListLoader } from "@nordcloud/gnui";
import { useIntegrationPluginsQuery } from "~/generated/graphql";
import {
  ReactTableResizeable,
  UniversalWrap,
  GlobalSpinner,
  UserErrorMessage,
  DataDisplayWrapper,
} from "~/components";
import { isNotEmpty } from "~/tools";
import { useRemoveIntegrationPluginAction } from "../hooks/useRemoveIntegrationPluginAction";
import { IntegrationPluginsListColumns } from "./IntegrationPluginsListColumns";

export function IntegrationPluginsList() {
  const [userError, setUserError] = React.useState<Error | undefined>();

  const removeAction = useRemoveIntegrationPluginAction();
  const columns = React.useMemo(
    () =>
      IntegrationPluginsListColumns({
        removeAction,
      }),
    [removeAction]
  );

  const { data, loading, error: queryError } = useIntegrationPluginsQuery();
  const tableData = data?.integrationPlugins ?? [];

  return (
    <UniversalWrap
      loaderProps={{
        loading,
        Component: ListLoader,
      }}
      errorProps={{
        error: queryError,
      }}
    >
      <GlobalSpinner loading={loading} />
      <UserErrorMessage
        error={userError}
        onClick={() => setUserError(undefined)}
      />
      <DataDisplayWrapper hasData={isNotEmpty(tableData)}>
        <ReactTableResizeable columns={columns} data={tableData} />
      </DataDisplayWrapper>
      <ModalConfirm {...removeAction.modalProps}>
        {removeAction.contentText}
      </ModalConfirm>
    </UniversalWrap>
  );
}
