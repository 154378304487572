/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When, If, Then, Else } from "react-if";
import { Button, Text } from "@nordcloud/gnui";
import {
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
} from "~/generated/graphql";
import { StyledRow } from "~/components/Tables/styles";
import { useGetCoverageInformation } from "../../hooks";
import { UsageCoverageData } from "../../types";
import { CoverageAndCostColumn } from "./CoverageAndCostColumn";
import { CoverageTableExpandable } from "./CoverageTableExpandable";
import { StyledTd } from "./styles";

type Props = {
  resources: UsageCoverageData;
  isParent: boolean;
  isExpanded?: boolean;
  offeringType?: ReservationsAndCommitmentsCoverageOfferingType;
  serviceUsageType?: ReservationsAndCommitmentsCoverageServiceUsageType;
  openSidebar: (rowDetails: UsageCoverageData) => void;
  iconClick?: () => void;
};

export function AwsCoverageTable({
  resources,
  isParent,
  isExpanded,
  offeringType,
  serviceUsageType,
  openSidebar,
  iconClick,
}: Props) {
  const { isReservedInstances, rowData } = useGetCoverageInformation({
    resources,
    offeringType,
    serviceUsageType,
  });

  return (
    <StyledRow>
      <CoverageTableExpandable
        hasCoverageResourceList={resources.hasCoverageResourceList}
        isExpanded={isExpanded}
        iconClick={iconClick}
      />

      <StyledTd hasRightBorder withPadding>
        <If condition={isParent}>
          <Then>
            <Text>
              <Text tag="span" weight="medium">
                {resources.resource}
              </Text>{" "}
              running{" "}
              <Text tag="span" weight="medium">
                {resources.platform}
              </Text>{" "}
              in{" "}
              <Text tag="span" weight="medium">
                {resources.region}
              </Text>
            </Text>
          </Then>
          <Else>
            <Text>{resources.instanceType ?? ""}</Text>
          </Else>
        </If>
      </StyledTd>
      <CoverageAndCostColumn
        riCostPercent={resources.riCostPercent}
        spCostPercent={resources.spCostPercent}
        hasBorder={isReservedInstances}
        isReservedInstances={isReservedInstances}
        totalOnDemandCost={resources.totalOnDemandCost}
        coverageSavedCost={resources.coverageSavedCost}
        potentialSavings={resources.potentialSavings}
        potentialSavingsPlaceholder={
          !resources.hasRecommendations && isParent ? "-" : ""
        }
        onDemandCostPercent={resources.onDemandCostPercent}
      />

      <When condition={isReservedInstances}>
        <StyledTd withPadding>
          <When condition={resources.hasRecommendations}>
            <Button
              severity="low"
              size="md"
              css={{ width: "100%", justifyContent: "center" }}
              onClick={() => openSidebar(rowData)}
            >
              Check Recommendation
            </Button>
          </When>
        </StyledTd>
      </When>
    </StyledRow>
  );
}
