/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { NumericFormat, NumberFormatValues } from "react-number-format";
import { theme } from "@nordcloud/gnui";
import { isNotNil } from "~/tools";

type Props = {
  value: number;
  setValue: (val: number) => void;
};

export function GrowthPlanNumberInput({ value, setValue }: Props) {
  return (
    <NumericFormat
      css={{
        border: `1px solid ${theme.color.border.input}`,
        width: "50%",
        height: "16px",
        background: "transparent",
        backgroundColor: `${theme.color.field.default}`,
        fontFamily: `${theme.typography.fonts.body}`,
        fontSize: `${theme.fontSizes.md}`,
        borderRadius: `${theme.radiusDefault}`,
        padding: `${theme.spacing.spacing02}`,
        color: `${theme.color.text.text01}`,
        outline: "none",
        transition: "all 0.26s ease-in-out;",
        ":focus, :hover": {
          borderColor: `${theme.color.border.focus}`,
        },
        "::placeholder": {
          color: `${theme.color.text.text03}`,
        },
        ":disabled": {
          opacity: 0.5,
          cursor: "not-allowed",
        },
      }}
      allowNegative
      placeholder="e.g. 10%"
      displayType="input"
      value={value}
      suffix={isNotNil(value) ? "%" : ""}
      onValueChange={(newValue) => {
        setValue(newValue.floatValue ?? 0);
      }}
      isAllowed={({ floatValue }: NumberFormatValues) =>
        floatValue === undefined ||
        (typeof floatValue === "number" && floatValue >= -100)
      }
    />
  );
}
