/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Text, theme } from "@nordcloud/gnui";
import { FilterWrapper, InputLoader, getSelectedMode } from "~/components";
import { isEmpty } from "~/tools";
import { isArrayFilterSelected } from "~/utils";
import { isExcluded } from "../helpers";
import { useDataOptions, useFilters } from "../hooks";
import { AppEnvAccordion } from "./AppEnvAccordion";
import { SidebarFilterProps } from "./types";
import { UnallocatedEstateFilter } from "./UnallocatedEstateFilter";

export function CostAllocationFilters({
  filters,
  updateFilters,
}: SidebarFilterProps) {
  const {
    clearApplicationFilter,
    isUnallocatedFilterSelected,
    clearUnallocatedFilters,
  } = useFilters({
    filters,
    updateFilters,
  });

  const { loadingCloudWastePoliciesCategorised } = useDataOptions(filters);

  return (
    <>
      <Text weight="medium" marginTop={theme.spacing.spacing04}>
        Cost Allocation
      </Text>
      <FilterWrapper
        name="Applications and Environments"
        isSelected={isArrayFilterSelected(filters.environment)}
        selectedOptions={filters.environment}
        onClear={clearApplicationFilter}
      >
        <AppEnvAccordion
          selectedEnvironments={filters.environment ?? []}
          isCleared={isEmpty(filters.environment ?? [])}
          headerProps={{
            allowModeChange: true,
            defaultMode: getSelectedMode(filters.environmentExclude),
            onModeChange: (mode) =>
              updateFilters({ environmentExclude: isExcluded(mode) }),
          }}
          onMultiSelect={updateFilters}
        />
      </FilterWrapper>
      <FilterWrapper
        name="Unallocated Estate Records"
        isSelected={isUnallocatedFilterSelected}
        onClear={clearUnallocatedFilters}
      >
        <InputLoader loading={loadingCloudWastePoliciesCategorised}>
          <UnallocatedEstateFilter
            filters={filters}
            updateFilters={updateFilters}
            isExcludeSelected={filters.unassignedExclude}
            onModeChage={(mode) =>
              updateFilters({ unassignedExclude: isExcluded(mode) })
            }
          />
        </InputLoader>
      </FilterWrapper>
    </>
  );
}
