/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

type SubmenuProps = {
  children: JSX.Element;
};

export function Submenu({ children }: SubmenuProps) {
  return <SubmenuWrapper>{children}</SubmenuWrapper>;
}

const SubmenuWrapper = styled.div`
  background-color: ${theme.color.background.ui01};
  border: solid 1px ${theme.color.background.ui04};
  border-radius: ${theme.radiusDefault};
  box-shadow: rgb(31 30 47 / 10%) 12px 0 22px;
  padding: ${theme.spacing.spacing02} ${theme.spacing.spacing04};

  li {
    margin-bottom: ${theme.spacing.spacing01};

    > * {
      padding: ${theme.spacing.spacing01} ${theme.spacing.spacing03};
      width: 100%;
    }

    a {
      box-sizing: border-box;
      display: inline-block;
      width: 100%;
    }
  }
`;
