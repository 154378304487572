/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { SVGIconProps } from "@nordcloud/gnui";
import { CloudAccountStatus, getTypedKeys } from "~/tools";
import {
  CloudProviderType,
  GcpJsonData,
  Provider,
  ProviderFilterValues,
  ProviderStatus,
  ProviderTab,
} from "../types";
import { toTitleCase } from "../utils";

export function getChargeTypeLabel(
  chargeType: string,
  provider?: ProviderTab
): string {
  const chargeTypeMap = new Map<string, string>();
  chargeTypeMap.set("RIFee", "RI Fee");
  chargeTypeMap.set("AZURE", "AKS Cost");
  chargeTypeMap.set("AWS", "EKS Cost");
  chargeTypeMap.set("GCP", "GKE Cost");
  if (provider === ProviderTab.OPENSHIFT) {
    chargeTypeMap.set("AZURE", "ARO Cost");
    chargeTypeMap.set("AWS", "OpenShift Cost");
    chargeTypeMap.set("GCP", "OpenShift Cost");
  }
  chargeTypeMap.set("KUBERNETES", "Kubernetes Cost");
  chargeTypeMap.set("OPENSHIFT", "Openshift Cost");

  const found = chargeTypeMap.get(chargeType);
  if (found !== undefined) {
    return found;
  }

  return toTitleCase(chargeType);
}

type ProviderLowercase = Lowercase<(typeof Provider)[keyof typeof Provider]>;
type IconName = SVGIconProps["name"];
type ProviderIconName =
  | "hybridCloud"
  | "ibmCloud"
  | "info"
  | (IconName & ProviderLowercase);

export function getProviderIconName(
  provider: Provider,
  cloudProviderType?: CloudProviderType
): ProviderIconName {
  const SPECIAL_PROVIDER_ICONS: { [key: string]: ProviderIconName } = {
    [`${Provider.Ibmcloud}`]: "ibmCloud",
    [`${Provider.Hybrid}`]: "hybridCloud",
    [`${Provider.Hybrid}:${CloudProviderType.Kubernetes}`]: "kubernetes",
    [`${Provider.Hybrid}:${CloudProviderType.Openshift}`]: "openshift",
  };

  if ([Provider.Services, Provider.Nordcloud].includes(provider)) {
    return "info";
  }
  const icon =
    SPECIAL_PROVIDER_ICONS[`${provider}:${cloudProviderType ?? ""}`] ??
    SPECIAL_PROVIDER_ICONS[`${provider}`];

  return icon ?? (provider.toLocaleLowerCase() as ProviderIconName);
}

export const getStatusFilterValues = (status: string[]) => {
  return {
    [CloudAccountStatus.FullAccess]: status?.includes(
      CloudAccountStatus.FullAccess
    ),
    [CloudAccountStatus.BillingOnly]: status?.includes(
      CloudAccountStatus.BillingOnly
    ),
    [CloudAccountStatus.ApiOnly]: status?.includes(CloudAccountStatus.ApiOnly),
    [CloudAccountStatus.NoAccess]: status?.includes(
      CloudAccountStatus.NoAccess
    ),
    [CloudAccountStatus.NoData]: status?.includes(CloudAccountStatus.NoData),
  };
};

export const getProviderFilterValues = (providerFilter: string[]) => {
  return {
    [Provider.Aws]: providerFilter?.includes(Provider.Aws),
    [Provider.Azure]: providerFilter?.includes(Provider.Azure),
    [Provider.Gcp]: providerFilter?.includes(Provider.Gcp),
  };
};

export const getStatusReducer = (status: string[]) => {
  return (
    state: ProviderStatus,
    action:
      | keyof ProviderStatus
      | "clearFilterCheckboxes"
      | "setValuesFromQuery"
  ) => {
    if (action === "setValuesFromQuery") {
      return getStatusFilterValues(status);
    }
    if (action === "clearFilterCheckboxes") {
      return {
        [CloudAccountStatus.FullAccess]: false,
        [CloudAccountStatus.BillingOnly]: false,
        [CloudAccountStatus.ApiOnly]: false,
        [CloudAccountStatus.NoAccess]: false,
        [CloudAccountStatus.NoData]: false,
      };
    }
    return {
      ...state,
      [action]: !state[action],
    };
  };
};

export const getProviderReducer =
  (providerFilter: string[]) =>
  (
    state: ProviderFilterValues,
    action:
      | keyof ProviderFilterValues
      | "clearFilterCheckboxes"
      | "setValuesFromQuery"
  ) => {
    if (action === "setValuesFromQuery") {
      return getProviderFilterValues(providerFilter);
    }
    if (action === "clearFilterCheckboxes") {
      return {
        [Provider.Aws]: false,
        [Provider.Azure]: false,
        [Provider.Gcp]: false,
      };
    }
    return {
      ...state,
      [action]: !state[action],
    };
  };

export function isJson(file: File) {
  return file.type === "application/json";
}

export function hasFiles(list: FileList): boolean {
  return list.length > 0;
}

export function prepareData(data: GcpJsonData) {
  return getTypedKeys(data).reduce((acc, curr) => {
    return {
      ...acc,
      [snakeToCamel(curr)]: data[curr],
    };
    // Auto-fix changes return type of a function to `string` which is wrong
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
  }, {} as FormData);
}

function snakeToCamel(str: string) {
  return str.replaceAll(/([_-][a-z])/g, (group) =>
    group.toUpperCase().replace("-", "").replace("_", "")
  );
}
