/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then } from "react-if";
import { Box, Button, FlexContainer, Text, theme } from "@nordcloud/gnui";
import { NoData, UnstyledLink } from "~/components";
import { ROUTES } from "~/routing/routes";

type ReservationBannerProps = {
  heading: string;
  subText: string;
  buttonText: string;
  isLink: boolean;
  onClick?: () => void;
  routePath?: string;
};
export function ReservationBanner({
  heading,
  subText,
  buttonText,
  isLink = false,
  routePath,
  onClick,
}: ReservationBannerProps) {
  return (
    <Box spacing="spacing04">
      <NoData
        message={
          <FlexContainer direction="column">
            <Text
              weight="medium"
              mt={theme.spacing.spacing05}
              mb={theme.spacing.spacing01}
            >
              {heading}
            </Text>
            <Text size="sm" css={{ whiteSpace: "pre-line" }}>
              {subText}
            </Text>
            <If condition={isLink}>
              <Then>
                <UnstyledLink to={routePath ?? ROUTES.index}>
                  <Button css={{ textTransform: "none" }}>{buttonText}</Button>
                </UnstyledLink>
              </Then>
              <Else>
                <Button css={{ textTransform: "none" }} onClick={onClick}>
                  {buttonText}
                </Button>
              </Else>
            </If>
          </FlexContainer>
        }
      />
    </Box>
  );
}
