/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { theme } from "@nordcloud/gnui";
import { AlertDotWrap } from "~/components";
import { isNotEmpty } from "~/tools";
import { AlertDotProps, isSelected } from "../helpers";
import { MenuLink } from "./MenuLink";

export function MenuItemWrapper({
  route,
  children,
  triggeredPaths,
  hasMenuItem,
  alertDotOptions,
}: {
  route?: string;
  children: JSX.Element;
  triggeredPaths?: string[];
  hasMenuItem?: boolean;
  alertDotOptions?: AlertDotProps;
}) {
  const { pathname } = useLocation();

  if (hasMenuItem === false) {
    return null;
  }

  if (route != null) {
    return (
      <MenuLink to={route}>
        <AlertDotWrap
          show={alertDotOptions?.show}
          top={alertDotOptions?.top}
          left={alertDotOptions?.left}
        >
          {children}
        </AlertDotWrap>
      </MenuLink>
    );
  }

  if (triggeredPaths && isNotEmpty(triggeredPaths)) {
    const isSectionActive = triggeredPaths.some((path) =>
      isSelected(pathname, path)
    );

    return (
      <TriggeredByWrapper className={isSectionActive ? "selected" : ""}>
        {children}
      </TriggeredByWrapper>
    );
  }

  return children;
}

const TriggeredByWrapper = styled.div`
  &.selected,
  &.selected > * {
    background-color: ${theme.color.interactive.secondaryHover};
    border-radius: ${theme.radius.md};
    color: ${theme.color.text.text01};
    text-decoration: none;
  }
`;
