/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import {
  FlexContainer,
  Message,
  Modal,
  Spacer,
  Text,
  Textarea,
  theme,
} from "@nordcloud/gnui";
import { DismissModalPayload } from "./types";

type Props = {
  isOpen: boolean;
  onClose: (v: DismissModalPayload) => void;
  onDismiss: (v: string | undefined) => void;
};

export function DismissModal({ isOpen, onClose, onDismiss }: Props) {
  const [comment, setComment] = useState<string | undefined>(undefined);

  const close = () => {
    setComment(undefined);
    onClose({
      isOpen: false,
      detectionId: undefined,
    });
  };

  const dismiss = () => {
    onDismiss(comment);
    setComment(undefined);
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Dismiss Saving Suggestion"
      actions={[
        {
          order: 1,
          onAction: dismiss,
          label: "Dismiss Suggestion",
          severity: "high",
        },
      ]}
      onClose={close}
    >
      <Text
        mb={theme.spacing.spacing02}
        color={theme.color.text.text02}
        size="sm"
      >
        Please comment on this dismissal
      </Text>
      <Textarea
        maxLength={200}
        placeholder="Enter the comment here..."
        onChange={(event) => setComment(event.target.value?.trim() ?? "")}
      />
      <FlexContainer justifyContent="end">
        <Text size="sm" color={theme.color.text.text03}>
          {200 - (comment?.length ?? 0)} / 200
        </Text>
      </FlexContainer>
      <Spacer height={theme.spacing.spacing05} />
      <Message status="notification" image="info">
        Any dismissed savings suggestion cannot be recovered later.
      </Message>
    </Modal>
  );
}
