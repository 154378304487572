/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const enum GcpFormFields {
  ORGANIZATION_ID = "organizationId",
  ONBOARDED_ORG_ID = "onboardedOrgId",
  SERVICE_ACCOUNT_KEY = "serviceAccountKey",
  PROJECT_ID = "projectId",
  BIG_QUERY_DATASET_NAME = "bigQueryDatasetName",
  BIG_QUERY_TABLE_NAME = "bigQueryTableName",
  BIG_QUERY_DATASET = "bigQueryDataset",
  BIG_QUERY_TABLE = "bigQueryTable",
  USE_DETAILED_BILLING_DATA = "useDetailedBillingData",
}

export type FormData = {
  [GcpFormFields.ORGANIZATION_ID]: string;
  [GcpFormFields.SERVICE_ACCOUNT_KEY]: string;
  [GcpFormFields.PROJECT_ID]: string;
  [GcpFormFields.BIG_QUERY_DATASET_NAME]: string;
  [GcpFormFields.BIG_QUERY_TABLE_NAME]: string;
  [GcpFormFields.USE_DETAILED_BILLING_DATA]: boolean;
};

export type UpdateFormData = {
  [GcpFormFields.ORGANIZATION_ID]: string;
  [GcpFormFields.ONBOARDED_ORG_ID]: string;
  [GcpFormFields.SERVICE_ACCOUNT_KEY]: string;
  [GcpFormFields.PROJECT_ID]: string;
  [GcpFormFields.BIG_QUERY_DATASET]: string;
  [GcpFormFields.BIG_QUERY_TABLE]: string;
  [GcpFormFields.USE_DETAILED_BILLING_DATA]: boolean;
};
