/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import dayjs from "dayjs";
import styled from "styled-components";
import {
  Box,
  FlexContainer,
  Input,
  Spacer,
  Switch,
  Table,
  Text,
  theme,
} from "@nordcloud/gnui";
import { dateFormat } from "~/constants";
import { isEmpty, isNotEmpty } from "~/tools";

const initOptions = [
  { name: "Monday", from: "06:00", to: "20:00", disabled: false },
  { name: "Tuesday", from: "06:00", to: "20:00", disabled: false },
  { name: "Wednesday", from: "06:00", to: "20:00", disabled: false },
  { name: "Thursday", from: "06:00", to: "20:00", disabled: false },
  { name: "Friday", from: "06:00", to: "20:00", disabled: false },
  { name: "Saturday", from: "06:00", to: "20:00", disabled: true },
  { name: "Sunday", from: "06:00", to: "20:00", disabled: true },
];

export function NonProdHoursHelper() {
  const [options, setOptions] = React.useState([...initOptions]);

  const onChange = (newConfigObject: DayPreset) => {
    setOptions((prev) => {
      return prev.map((item) =>
        item.name === newConfigObject.name ? newConfigObject : item
      );
    });
  };

  const workingHours = React.useMemo(
    () =>
      Number(
        options
          .filter(({ disabled }) => !disabled)
          .filter(({ from, to }) => isNotEmpty(from) && isNotEmpty(to))
          .filter(({ from, to }) => {
            return Number(from.replace(":", "")) <= Number(to.replace(":", ""));
          })
          .reduce((partialSum, { from, to }) => {
            const fromDate = getDateFromTime(from);
            const toDate = getDateFromTime(to);
            return partialSum + Math.abs(toDate.diff(fromDate, "hour", true));
          }, 0)
          .toFixed(1)
      ),
    [options]
  );
  const totalWeeklyHours = 24 * 7;
  const nonWorkingHours = totalWeeklyHours - workingHours;

  const recommendedTarget = Number(
    ((100 * nonWorkingHours) / totalWeeklyHours).toFixed(1)
  );

  return (
    <>
      <Text
        size="sm"
        color={theme.color.text.text02}
        mb={theme.spacing.spacing01}
      >
        Specify the times during the week when your VMs should run on
        non-production environments
      </Text>
      <Table tiny css={{ tableLayout: "fixed" }}>
        <Table.thead>
          <Table.tr>
            <Table.th css={{ width: "6rem" }}>
              <Text size="sm" tag="span">
                Day
              </Text>
            </Table.th>
            <Table.th css={{ width: "8rem" }}>
              <Text size="sm" tag="span">
                VM ON/OFF
              </Text>
            </Table.th>
            <Table.th>
              <Text size="sm" tag="span">
                Working Hours
              </Text>
            </Table.th>
          </Table.tr>
        </Table.thead>
        <Table.tbody>
          {options.map((option) => (
            <DayRow key={option.name} option={option} onChange={onChange} />
          ))}
        </Table.tbody>
      </Table>
      <Summary
        workingHours={workingHours}
        nonWorkingHours={nonWorkingHours}
        recommendedTarget={recommendedTarget}
      />
    </>
  );
}

type DayRowProps = {
  option: DayPreset;
  onChange: (option: DayPreset) => void;
};

function DayRow({ option, onChange }: DayRowProps) {
  const { name, from, to, disabled } = option;
  const invalidValue =
    Number(from.replace(":", "")) > Number(to.replace(":", ""));

  return (
    <Table.tr>
      <Table.td>
        <Text size="sm" tag="span">
          {name}
        </Text>
      </Table.td>
      <Table.td>
        <FlexContainer
          css={{ width: "100%" }}
          columnGap={theme.spacing.spacing01}
        >
          <Text tag="span" size="sm">
            ON
          </Text>
          <Switch
            severity="danger"
            checked={disabled}
            onChange={() => onChange({ ...option, disabled: !disabled })}
          />
          <Text tag="span" size="sm">
            OFF
          </Text>
        </FlexContainer>
      </Table.td>
      <Table.td>
        <FlexContainer
          css={{ width: "100%" }}
          margin="0 auto"
          columnGap={theme.spacing.spacing02}
        >
          <Input
            small
            pattern={dateFormat.hourMinute}
            type="time"
            value={from}
            max={to}
            disabled={disabled}
            status={getInputStatus(isEmpty(from) || invalidValue)}
            onChange={({ target }: TargetType) =>
              onChange({ ...option, from: target.value })
            }
          />
          <Text tag="span">-</Text>
          <Input
            small
            pattern={dateFormat.hourMinute}
            type="time"
            value={to}
            min={from}
            disabled={disabled}
            status={getInputStatus(isEmpty(to) || invalidValue)}
            onChange={({ target }: TargetType) =>
              onChange({ ...option, to: target.value })
            }
          />
        </FlexContainer>
      </Table.td>
    </Table.tr>
  );
}

type SummaryProps = {
  workingHours: number;
  nonWorkingHours: number;
  recommendedTarget: number;
};

function Summary({
  workingHours,
  nonWorkingHours,
  recommendedTarget,
}: SummaryProps) {
  return (
    <Box border="grey" mt={theme.spacing.spacing02}>
      <Text weight="medium" mb={theme.spacing.spacing02}>
        Configuration Result
      </Text>
      <Text color={theme.color.text.text02} size="sm" tag="div">
        This calculation is based on the above configuration and represents the
        number of hours for 1 VM
      </Text>
      <Spacer height={theme.spacing.spacing02} />
      <FlexContainer
        css={{ width: "100%" }}
        direction="column"
        rowGap={theme.spacing.spacing02}
      >
        <FlexContainer css={{ width: "100%" }} justifyContent="space-between">
          <Text mb={0}>Working hours:</Text>
          <Text mb={0}>
            {workingHours}h <MutedText tag="span">per 1 VM</MutedText>
          </Text>
        </FlexContainer>
        <FlexContainer css={{ width: "100%" }} justifyContent="space-between">
          <Text mb={0}>Shutdown Hours:</Text>
          <Text mb={0}>
            {nonWorkingHours}h <MutedText tag="span">per 1 VM</MutedText>
          </Text>
        </FlexContainer>
        <FlexContainer css={{ width: "100%" }} justifyContent="space-between">
          <Text mb={0}>Recommended Target for shutdown time:</Text>
          <Text weight="bold" mb={0}>
            {recommendedTarget}%
          </Text>
        </FlexContainer>
      </FlexContainer>
    </Box>
  );
}

function getInputStatus(invalid: boolean) {
  return invalid ? "danger" : undefined;
}

function getDateFromTime(time: string) {
  const [hourFrom, minuteFrom] = time.split(":").map(Number);
  return dayjs().set("hour", hourFrom).set("minute", minuteFrom);
}

type TargetType = {
  target: {
    value: string;
  };
};

type DayPreset = {
  name: string;
  from: string;
  to: string;
  disabled: boolean;
};

const MutedText = styled(Text)`
  margin-bottom: 0;
  opacity: 0.7;
  font-size: ${theme.fontSizes.sm};
`;
