import { theme } from "@nordcloud/gnui";

export function ReservationTooltipTable({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <table css={{ marginTop: theme.spacing.spacing01 }}>
      <tbody>{children}</tbody>
    </table>
  );
}
