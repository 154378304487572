import { useCallback, useEffect } from "react";
import dayjs from "dayjs";
import { dateFormat } from "~/constants";
import { useQueryState } from "~/hooks";
import { isNotNil } from "~/tools";
import {
  ChartOption,
  ChartsQueryState,
  DateRange,
  Granularity,
  Period,
} from "../types";

type Props = {
  showOtherCosts: boolean | undefined;
  onHandleClick: (period: Period) => void;
  onRangeChange: (dateRange?: DateRange | undefined) => void;
  onChartChange: (item: ChartOption | null) => void;
  onGranularityChange: (granularity: Granularity) => void;
};

export function useHeaderParameters({
  showOtherCosts,
  onHandleClick,
  onRangeChange,
  onChartChange,
  onGranularityChange,
}: Props) {
  const {
    state: {
      chartType: stateChartType,
      granularity: stateGranularity,
      rangeStartDate: queryRangeStartDate,
      rangeEndDate: queryRangeEndDate,
      costTab,
    },
    updateQueryState,
    setQueryState,
  } = useQueryState<ChartsQueryState>();

  useEffect(() => {
    updateQueryState({ otherCosts: showOtherCosts });
  }, [showOtherCosts]);

  const handlePeriodClick = useCallback(onHandleClick, [onHandleClick]);

  const handleTermChange = (
    selectedPeriod?: Period,
    selectedRange?: DateRange
  ) => {
    if (isNotNil(selectedPeriod)) {
      handlePeriodClick(selectedPeriod);
    } else {
      handlePeriodClick(Period.MONTH);
      onRangeChange(undefined);
      setQueryState({ period: Period.MONTH, costTab });
    }

    if (selectedPeriod !== Period.RANGE) {
      updateQueryState({
        period: selectedPeriod,
        granularity: stateGranularity,
        chartType: stateChartType,
      });
    }

    if (isNotNil(selectedRange)) {
      const selectedDateStart = dayjs(selectedRange?.from).format(
        dateFormat.shortDate
      );
      const selectedDateEnd = dayjs(selectedRange?.to).format(
        dateFormat.shortDate
      );
      onRangeChange(selectedRange);
      updateQueryState({
        period: Period.RANGE,
        rangeStartDate: selectedDateStart,
        rangeEndDate: selectedDateEnd,
      });
    }
  };

  const handleGranularityChange = (selectedGranularity: Granularity) => {
    onGranularityChange(selectedGranularity);
    updateQueryState({
      granularity: selectedGranularity,
    });
  };

  const handleChartChange = (item: ChartOption | null) => {
    onChartChange(item);
    updateQueryState({ chartType: item?.value ?? "", page: 0 });
  };

  return {
    queryRangeStartDate,
    queryRangeEndDate,
    handleTermChange,
    handleGranularityChange,
    handleChartChange,
  };
}
