/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { theme } from "@nordcloud/gnui";
import { abbreviateNumber } from "~/tools";

export const UtilizationLegendConfig = {
  cpu: [
    {
      color: theme.color.support.indigo,
      label: "CPU utilization (%)",
    },
  ],
  memory: [
    {
      color: theme.color.support.indigo,
      label: "Memory utilization (%)",
    },
  ],
  network: [
    {
      color: theme.color.support.indigo,
      label: "Network in (bytes)",
    },
    {
      color: theme.color.support.purple,
      label: "Network out (bytes)",
    },
  ],
  disk: [
    {
      color: theme.color.support.indigo,
      label: "Disk reads (KiB/s)",
    },
    {
      color: theme.color.support.purple,
      label: "Disk writes (KiB/s)",
    },
  ],
};

export const UtilizationTooltipConfig = {
  cpu: {
    formatter: (value: string) => `${value}%`,
    colors: [theme.color.support.indigo],
    labels: ["CPU utilization"],
  },
  memory: {
    formatter: (value: string) => `${value}%`,
    colors: [theme.color.support.indigo],
    labels: ["Memory utilization"],
  },
  network: {
    formatter: (value: string) => `${abbreviateNumber(Number(value))}b`,
    colors: [theme.color.support.indigo, theme.color.support.purple],
    labels: ["Network in utilization", "Network out utilization"],
  },
  disk: {
    formatter: (value: string) => `${value}KiB/s`,
    colors: [theme.color.support.indigo, theme.color.support.purple],
    labels: ["Disk reads utilization", "Disk writes utilization"],
  },
};
