/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer, theme, Text } from "@nordcloud/gnui";
import { SUCCESS_TEXT, ERROR_TEXT } from "~/constants";
import { showSimpleInfo, showError } from "~/services/toast";
import { AzureForm } from "./AzureForm";
import { useUpdateAzureBillingData } from "./hooks/useUpdateAzureBillingData";
import { UpdateFormData } from "./validators";

type Props = {
  enrollmentNumber: string;
  capacitorCustomerId: string;
  onClose: () => void;
};

export function AzureBillingDataUpdateForm({
  enrollmentNumber,
  capacitorCustomerId,
  onClose,
}: Props) {
  const [updateAzureBillingData] = useUpdateAzureBillingData();

  const handleUpdate = async (data: UpdateFormData) => {
    try {
      await updateAzureBillingData(data);
      showSimpleInfo(SUCCESS_TEXT.billingDataUpdated);
      onClose();
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  return (
    <FlexContainer
      marginTop={theme.spacing.spacing04}
      direction="column"
      alignItems="flex-start"
      gap={theme.spacing.spacing01}
    >
      <Text weight="bold">Billing Data Settings</Text>
      <AzureForm
        defaultValues={{ enrollmentNumber, capacitorCustomerId }}
        onCloseForm={onClose}
        onSubmit={handleUpdate}
      />
    </FlexContainer>
  );
}
