/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { theme, Text, FlexContainer, Modal, Radio } from "@nordcloud/gnui";
import { useToggle } from "~/hooks";
import { EMAIL_MSG_PREVIEW_MODAL_TITLE } from "../constants";
import { PreviewButton } from "./PreviewButton";
import { PreviewImage } from "./PreviewImage";

type Props = {
  labelText: string;
  isReadMode: boolean;
  checked: boolean;
  onChange: () => void;
  details: string;
  previewImage?: string;
};

export function RadioOption({
  labelText,
  isReadMode,
  checked,
  onChange,
  details,
  previewImage,
}: Props) {
  const [showModal, setShowModal] = useToggle(false);

  const previewEnabled = Boolean(previewImage);

  return (
    <>
      <FlexContainer
        css={{ width: "100%" }}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Radio
          labelText={labelText}
          checked={checked}
          disabled={isReadMode}
          onChange={onChange}
        />
        <When condition={previewEnabled}>
          <PreviewButton type="button" size="sm" onClick={setShowModal}>
            Sample Message
          </PreviewButton>
        </When>
      </FlexContainer>
      <Text
        m={`-${theme.spacing.spacing02} 0 ${theme.spacing.spacing06} 1.75rem`}
        tag="div"
        color={theme.color.text.text02}
        size="sm"
      >
        {details}
      </Text>
      <When condition={showModal}>
        <Modal
          contentLabel={EMAIL_MSG_PREVIEW_MODAL_TITLE}
          isOpen={showModal}
          actions={[]}
          contentMaxHeight="40rem"
          onClose={setShowModal}
        >
          <PreviewImage src={previewImage} />
        </Modal>
      </When>
    </>
  );
}
