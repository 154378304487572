/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Table, theme, Text } from "@nordcloud/gnui";
import {
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsAndCommitmentsFilters,
  ReservationsAndCommitmentsLookbackPeriod,
  ReservationsProvider,
} from "~/generated/graphql";
import { StyledTable, StyledRow } from "~/components/Tables/styles";
import { isEmpty, isNotNil, Maybe } from "~/tools";
import { UsageCoverageData } from "../../types";
import { ReservationsCoverageTableHeader } from "./ReservationCoverageHeader";
import { ReservationCoverageParent } from "./ReservationCoverageParent";
import { StyledTd } from "./styles";

type Props = {
  resourcesData?: Maybe<ResourceProps[]>;
  offeringType?: ReservationsAndCommitmentsCoverageOfferingType;
  serviceType: ReservationsAndCommitmentsCoverageServiceUsageType;
  timePeriod?: ReservationsAndCommitmentsLookbackPeriod;
  filters?: ReservationsAndCommitmentsFilters;
  provider: ReservationsProvider;
  hasGroupByParameter: boolean;
  openSidebar: (rowDetails: UsageCoverageData) => void;
};

export function ReservationsCoverageTable({
  resourcesData,
  offeringType,
  serviceType,
  timePeriod,
  filters,
  provider,
  openSidebar,
  hasGroupByParameter,
}: Props) {
  const isReservedInstances =
    offeringType !==
    ReservationsAndCommitmentsCoverageOfferingType.SavingsPlans;

  return (
    <StyledTable>
      <Table.thead>
        <ReservationsCoverageTableHeader
          isReservedInstances={isReservedInstances}
          provider={provider}
          serviceUsageType={serviceType}
        />
      </Table.thead>
      <Table.tbody>
        {resourcesData?.filter(isNotNil)?.map((item) => {
          return (
            <ReservationCoverageParent
              key={item.id}
              data={item?.resources ?? []}
              serviceType={serviceType}
              timePeriod={timePeriod}
              offeringType={offeringType}
              openSidebar={openSidebar}
              filters={filters}
              provider={provider}
              platform={item?.platform ?? ""}
              hasGroupByParameter={hasGroupByParameter}
            />
          );
        })}
        <When condition={isEmpty(resourcesData ?? [])}>
          <StyledRow css={{ background: theme.color.background.ui03 }}>
            <StyledTd width="100%" colSpan={5} withPadding={false}>
              <Text weight="medium" align="center">
                No data available
              </Text>
            </StyledTd>
          </StyledRow>
        </When>
      </Table.tbody>
    </StyledTable>
  );
}

type ResourceProps = {
  id: string;
  resources?: Maybe<UsageCoverageData[]>;
  platform?: Maybe<string>;
};
