/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Modal } from "@nordcloud/gnui";
import { useDisclosure } from "~/hooks";
import { SERVICENOW_MSG_PREVIEW_MODAL_TITLE } from "../../constants";
import { AnomalyPreviewMessage } from "../../PreviewMessages";
import { ShortDescriptionField } from "./ShortDescriptionField";
import { StaticDescriptionField } from "./StaticDescriptionField";

const DESCRIPTION =
  'The anomaly costs of selected applications will be located in the "Description" ServiceNow field.';

type Props = {
  isReadMode: boolean;
};

export function AnomalyCostsDescriptionSection({ isReadMode }: Props) {
  const { isOpen, close, open } = useDisclosure();

  return (
    <>
      <ShortDescriptionField isReadMode={isReadMode} />
      <StaticDescriptionField description={DESCRIPTION} onPreviewClick={open} />
      <Modal
        isOpen={isOpen}
        actions={[]}
        contentMaxHeight="40rem"
        contentLabel={SERVICENOW_MSG_PREVIEW_MODAL_TITLE}
        onClose={close}
      >
        <AnomalyPreviewMessage />
      </Modal>
    </>
  );
}
