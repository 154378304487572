/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

export const ButtonTooltip = styled.button`
  background: none;
  border: none;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  outline: none;
  border-radius: ${theme.radiusDefault};
  padding: calc(${theme.spacing.spacing03} + 1px);

  &:hover {
    background-color: ${theme.color.interactive.secondaryHover};
  }
`;
