/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { theme, Text, SVGIcon, FlexContainer } from "@nordcloud/gnui";
import { PathName } from "~/tools";

type Props = {
  icon: PathName;
  label: string;
};

export function TabSectionLabel({ icon, label }: Props) {
  return (
    <FlexContainer as="span" css={{ columnGap: theme.spacing.spacing02 }}>
      <SVGIcon
        name={icon}
        size="md"
        css={{
          position: "relative",
          top: `-${theme.spacing.spacing00}`,
        }}
      />
      <Text weight="medium" mb="0">
        {label}
      </Text>
    </FlexContainer>
  );
}
