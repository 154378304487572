/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { FlexContainer, theme } from "@nordcloud/gnui";

export const DisplayMessageWrapper = styled(FlexContainer)<{
  hasFilters: boolean;
}>`
  align-items: start;
  padding: ${({ hasFilters }) =>
    hasFilters
      ? `${theme.spacing.spacing03} 0 ${theme.spacing.spacing01} 0 `
      : `${theme.spacing.spacing06} 0`};
  column-gap: ${theme.spacing.spacing01};
`;
