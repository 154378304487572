/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloError } from "@apollo/client";
import dayjs from "dayjs";
import {
  Ec2MetricGranulation,
  useEc2DiskUtilizationLazyQuery,
  useEc2UtilizationLazyQuery,
} from "~/generated/graphql";
import { dateFormat } from "~/constants";
import {
  generateNumericArray,
  isCloudResource,
  isNotEmpty,
  noop,
} from "~/tools";
import { Period } from "../components";

type Ec2UtilizationProps = {
  nid: string;
  period: Period;
  isActivated: boolean;
  volumeId: string;
};

export function useEc2Utilization({
  nid,
  period,
  isActivated,
  volumeId,
}: Ec2UtilizationProps) {
  const startDate = getStartDate(period).format(dateFormat.shortDate);
  const endDate = dayjs().format(dateFormat.shortDate);

  const variables = {
    nid,
    startDate,
    endDate,
    granulation: Ec2MetricGranulation.Daily,
    volumeId,
  };

  const [getData, { data, loading, error, called }] =
    useEc2UtilizationLazyQuery();

  if (!called && isActivated && isNotEmpty(volumeId)) {
    void getData({ variables });
  }

  const [
    getDiskData,
    {
      data: diskUtilizationData,
      loading: isDiskUtilizationLoading,
      error: diskUtilizationError,
    },
  ] = useEc2DiskUtilizationLazyQuery();

  const estateRecord = data?.estateRecord ?? {};

  const getDiskUtilizationByVolumeId = async (volId: string) => {
    await getDiskData({
      variables: {
        ...variables,
        volumeId: volId,
      },
    });
  };

  const customerError = getCustomerError(error || diskUtilizationError);

  if (!isCloudResource(estateRecord)) {
    return {
      cpu: [],
      memory: [],
      networkIn: [],
      networkOut: [],
      diskRead: [],
      diskWrite: [],
      timeTicks: [],
      loading,
      diskLoading: isDiskUtilizationLoading,
      error: error || diskUtilizationError,
      customerError,
      getDiskUtilizationByVolumeId: noop,
    };
  }

  const diskDataMetrics = isCloudResource(diskUtilizationData?.estateRecord)
    ? diskUtilizationData?.estateRecord?.ec2UsageMetrics?.disk
    : undefined;

  const metrics = estateRecord.ec2UsageMetrics;

  const cpu = mapValues(metrics?.processor?.utilization ?? []);
  const memory = mapValues(metrics?.memory?.utilization ?? []);
  const networkIn = mapValues(metrics?.network?.inBytes ?? []);
  const networkOut = mapValues(metrics?.network?.outBytes ?? []);
  const diskRead = mapValues(
    diskDataMetrics?.readBytes ?? metrics?.disk?.readBytes ?? []
  );
  const diskWrite = mapValues(
    diskDataMetrics?.writeBytes ?? metrics?.disk?.writeBytes ?? []
  );

  const timeTicks = generateTimeTicks(startDate, endDate);

  return {
    cpu,
    memory,
    networkIn,
    networkOut,
    diskRead,
    diskWrite,
    loading,
    diskLoading: isDiskUtilizationLoading,
    timeTicks,
    error: error || diskUtilizationError,
    customerError,
    getDiskUtilizationByVolumeId,
  };
}

function getStartDate(period: Period) {
  const today = dayjs();
  switch (period) {
    case Period.MONTH:
      return today.subtract(1, "month");
    case Period.QUARTER:
      return today.subtract(3, "month");
    case Period.YEAR:
      return today.subtract(12, "month");
    default:
      return today;
  }
}

function mapValues(
  utilization: TimePoint[],
  valueFormatter = (value: string) => value
) {
  return utilization
    .map(({ date, value }) => ({
      date: formatDate(date),
      value: valueFormatter(value),
    }))
    .reverse();
}

function formatDate(date: string) {
  return dayjs(date).format(dateFormat.shortDate);
}

type TimePoint = {
  date: string;
  value: string;
};

function generateTimeTicks(dateFrom: string, dateTo: string) {
  const startDate = dayjs(dateFrom);
  const endDate = dayjs(dateTo);

  const diffDays = endDate.diff(startDate, "day");
  const dates = generateNumericArray(diffDays);

  return dates.map((dayNumber) =>
    startDate.add(dayNumber, "day").format(dateFormat.shortDate)
  );
}

// Needs to be in sync with https://github.com/nordcloud/cnop-api-apollo/blob/master/src/modules/MetricFetcher/resolvers/EC2/ec2UsageMetrics.ts#L57
function getCustomerError(error?: ApolloError) {
  switch (true) {
    case hasErrorCode(error, "UnableToGetCredentials"):
      return "Unable to get temporary credentials. They can be expired or invalid.";
    case hasErrorCode(error, "CredentialsNotFound"):
      return "Credentials are not found. Please verify if they were added correctly.";
    case hasErrorCode(error, "CredentialsCannotAssumeAtThisTime"):
      return "Unable to assume credentials in desired time. Please try again later.";
    default:
      return "";
  }
}

function hasErrorCode(error: ApolloError | undefined, code: string) {
  return error?.graphQLErrors.some(
    (graphqlError) => graphqlError.extensions.code === code
  );
}
