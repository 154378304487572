/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Label, theme, FlexContainer, Text, Spacer } from "@nordcloud/gnui";
import { ExternalRecipients } from "./ExternalRecipients";
import { InternalRecipients } from "./InternalRecipients";

export function EmailNotificationSettings() {
  return (
    <section css={{ width: "100%" }}>
      <Label required name="Email Notification Settings" css={{ margin: 0 }} />
      <Text size="sm" color={theme.color.text.text03}>
        Select recipients from a list or enter external emails for alerts when
        costs exceed the defined threshold.
      </Text>
      <FlexContainer
        css={{ width: "100%" }}
        alignItems="flex-start"
        direction="column"
      >
        <InternalRecipients />
      </FlexContainer>
      <Spacer height={theme.spacing.spacing04} />
      <ExternalRecipients />
    </section>
  );
}
