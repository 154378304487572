/**
 * Copyright 2021-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { When } from "react-if";
import { FlexContainer, Text, theme } from "@nordcloud/gnui";
import { ItemWrapper } from "~/components/Styled/ItemWrapper";
import { KeyValue } from "~/models";

type Item = KeyValue & {
  color?: string;
  additionalValue?: string;
  size?: React.ComponentProps<typeof Text>["size"];
};

type Props = {
  items: Item[];
};

export function KpiSummary({ items }: Props) {
  return (
    <FlexContainer
      columnGap={theme.spacing.spacing06}
      wrap="wrap"
      marginTop={theme.spacing.spacing03}
    >
      {items.map((item, index) => (
        <ItemWrapper
          key={`${item.key}${index}`}
          data-test-id="kpi-value-text-wrapper"
        >
          <Text
            size="sm"
            color={theme.color.text.text02}
            mb={0}
            css={{ textTransform: "capitalize" }}
            weight="medium"
          >
            {item.key}
          </Text>
          <Text
            size={item.size ?? "md"}
            mb={0}
            weight="medium"
            color={item.color ?? theme.color.text.text01}
          >
            {item.value}{" "}
            <When condition={item.additionalValue}>
              <Text as="span" size="sm">
                ({item.additionalValue})
              </Text>
            </When>
          </Text>
        </ItemWrapper>
      ))}
    </FlexContainer>
  );
}
