/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { KpiTimePeriod } from "~/generated/graphql";
import {
  BrickLoader,
  KpiWidgets,
  UniversalWrap,
  useKpiWidgetData,
} from "~/components";
import { KpiType } from "~/constants";
import { useQueryState } from "~/hooks";
import { KpiQueryState } from "./types";

type Props = {
  businessContextId?: string;
  selectedType: KpiType;
};

export function KpiWidget({ businessContextId, selectedType }: Props) {
  const {
    state: { period },
    setQueryState,
  } = useQueryState<KpiQueryState>();
  const { costAllocationCoverage, overallWaste, nonProdHours, loading, error } =
    useKpiWidgetData(businessContextId);

  return (
    <UniversalWrap
      loaderProps={{
        loading,
        Component: BrickLoader,
        viewBox: "0 0 10 1",
      }}
      errorProps={{
        error,
      }}
    >
      <KpiWidgets
        costAllocationCoverage={costAllocationCoverage}
        nonProdHours={nonProdHours}
        overallWaste={overallWaste}
        selectedType={selectedType}
        onClick={(type) =>
          setQueryState({
            kpiType: type,
            businessContext: businessContextId,
            period: period ?? KpiTimePeriod.ThreeMonths,
          })
        }
      />
    </UniversalWrap>
  );
}
