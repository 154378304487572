/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { DateRange } from "~/components/Charts";
import { EstatePeriod as Period } from "~/components/DateRangeSelector";
import { useBillingPeriod } from "~/services/customers";

type Props = {
  onChange: (selectedPeriod: Period, selectedRange?: DateRange) => void;
  clearRange: () => void;
};

export function usePeriodSelector({ onChange, clearRange }: Props) {
  const defaultRangeLabel = "Date Range";
  const { isPreviousBillingPeriod } = useBillingPeriod();

  const [rangeLabel, setRangeLabel] = useState(defaultRangeLabel);

  const setRange = (selectedRange: DateRange | undefined) => {
    onChange(Period.RANGE, selectedRange);
  };

  const handleClear = () => {
    clearRange();
    setRangeLabel(defaultRangeLabel);
    onChange(mapEstatePeriod(isPreviousBillingPeriod));
  };

  const onPeriodChange = (selectedPeriod: Period) => {
    onChange(selectedPeriod);
  };

  return {
    rangeLabel,
    setRangeLabel,
    setRange,
    handleClear,
    onPeriodChange,
  };
}

function mapEstatePeriod(isPreviousBillingPeriod: boolean) {
  return isPreviousBillingPeriod ? Period.PREVIOUS_MONTH : Period.DEFAULT_MONTH;
}
