/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { DetailData } from "~/components/Details/Detail";
import {
  DetailsDescription,
  DetailsItem,
  DetailsTitle,
} from "~/components/Details/styles";
import { BorderSeparator } from "~/components/Styled";
import { ResourceLifecycleDates } from "~/tools";
import { useResourceLifeSpanDates } from "../../../hooks/useResouceLifeSpanDates";

type Props = {
  data: DetailData[];
  resourceLifecycleDates: ResourceLifecycleDates | undefined;
};

export function ResourceLifeSpan({ data, resourceLifecycleDates }: Props) {
  const lastUpdateDate =
    data
      .find((detail) => detail.label === "lastUpdateDate")
      ?.value?.toString() ?? "";

  const { firstSeenDate, lastSeenDate } = useResourceLifeSpanDates({
    lastUpdateDate,
    resourceLifecycleDates,
  });

  return (
    <>
      <DetailsItem>
        <DetailsTitle>First Seen:</DetailsTitle>
        <DetailsDescription data-testid="first-seen-date">
          {firstSeenDate}
        </DetailsDescription>
      </DetailsItem>
      <When condition={lastSeenDate}>
        <DetailsItem>
          <DetailsTitle>Last Seen:</DetailsTitle>
          <DetailsDescription data-testid="last-seen-date">
            {lastSeenDate}
          </DetailsDescription>
        </DetailsItem>
      </When>
      <BorderSeparator />
    </>
  );
}
