/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import styled from "styled-components";
import { FlexContainer, Input, Label, Text, theme } from "@nordcloud/gnui";
import { dateFormat } from "~/constants";
import { useBillingPeriod } from "~/services/customers";

type Props = {
  startDate: string;
  setStartDate: (date: string) => void;
  endDate: string;
  setEndDate: (date?: string) => void;
};

export function DateRangeInputs({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: Props) {
  const { isCurrentBillingPeriod } = useBillingPeriod();

  const defaultDate = isCurrentBillingPeriod
    ? dayjs()
    : dayjs().subtract(1, "month").endOf("month");

  const handleStartDateSelection = (value: string) => {
    setStartDate(value);
    if (dayjs(endDate).isBefore(value)) {
      setEndDate(undefined);
    }
  };

  const lastSelectableDay = isCurrentBillingPeriod
    ? dayjs().format(dateFormat.shortDate)
    : dayjs().subtract(1, "month").endOf("month").format(dateFormat.shortDate);

  return (
    <>
      <Text weight="bold">Date range</Text>
      <FlexContainer>
        <DateInputContainer>
          <DateLabel name="Start date:" />
        </DateInputContainer>
        <DateInputContainer>
          <DateLabel name="End date:" />
        </DateInputContainer>
      </FlexContainer>
      <FlexContainer>
        <DateInputContainer>
          <Input
            type="date"
            value={startDate ?? ""}
            placeholder="MM/DD/YYYY"
            max={lastSelectableDay}
            min={getDateToMinValue(defaultDate)}
            onChange={(event) => handleStartDateSelection(event.target.value)}
            onKeyDown={(event) => event.preventDefault()}
          />
        </DateInputContainer>
        <DateInputContainer>
          <Input
            type="date"
            value={endDate ?? ""}
            placeholder="MM/DD/YYYY"
            max={lastSelectableDay}
            disabled={startDate === undefined}
            min={getDateToMinValue(defaultDate, startDate)}
            onChange={(event) => setEndDate(event.target.value)}
            onKeyDown={(event) => event.preventDefault()}
          />
        </DateInputContainer>
      </FlexContainer>
    </>
  );
}

const DateInputContainer = styled.div`
  margin-right: ${theme.spacing.spacing02};
  width: 50%;
`;

const DateLabel = styled(Label)`
  font-weight: ${theme.fontWeights.medium};
  font-size: ${theme.fontSizes.sm};
`;

function getDateToMinValue(defaultDate: dayjs.Dayjs, startDate?: string) {
  return startDate
    ? dayjs(startDate).format(dateFormat.shortDate)
    : defaultDate.add(-5, "year").format(dateFormat.shortDate);
}
