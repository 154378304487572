/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { When } from "react-if";
import { useToggle } from "react-use";
import { theme } from "@nordcloud/gnui";
import {
  ReservationsAndCommitmentsCoverageOfferingType as OfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType as ServiceUsageType,
  ReservationsProvider,
} from "~/generated/graphql";
import { DataContainer, DataItem } from "~/components";
import { useFeature } from "~/hooks";
import {
  ReservationExistingLinkCounter,
  ReservationExistingLink,
} from "../components";
import { ReservationTooltip } from "../constants";
import { useGetCurrentTab } from "../hooks";

type Props = {
  serviceUsageType: ServiceUsageType;
  reservedInstaceCoverage: string;
  savedCost: string;
  totalOnDemandCost: string;
};

export function AzureUsageAnalysisDetails({
  serviceUsageType,
  reservedInstaceCoverage,
  totalOnDemandCost,
  savedCost,
}: Props) {
  const hasUtilization = useFeature("azure-ea-utilization");
  const [offeringType, setOfferingType] = useState(
    OfferingType.ReservedInstances
  );
  const [isSidebarOpen, toggleIsSidebarOpen] = useToggle(false);

  const { isComputeTab, isRICoverage } = useGetCurrentTab(serviceUsageType);

  const openExistingLink = (type: OfferingType) => {
    setOfferingType(type);
    toggleIsSidebarOpen(true);
  };

  const padding = isComputeTab
    ? `0 ${theme.spacing.spacing03}`
    : `0 0 0 ${theme.spacing.spacing03}`;

  return (
    <>
      <DataContainer marginBottom={theme.spacing.spacing06}>
        <When condition={isRICoverage}>
          <ReservationExistingLinkCounter
            serviceUsageType={serviceUsageType}
            coverageLabel="RI Avg. Coverage"
            coverageValue={reservedInstaceCoverage}
            padding={padding}
            provider={ReservationsProvider.Azure}
            offeringType={offeringType}
            onClick={() => openExistingLink(offeringType)}
          />
        </When>
        <DataItem
          size="md"
          label="On-Demand Cost"
          value={totalOnDemandCost}
          tooltipText={ReservationTooltip.ON_DEMAND_COST}
          mb={theme.spacing.spacing01}
          itemWidth="20%"
          grow={false}
        />
        <DataItem
          size="md"
          label="Cost Savings"
          value={savedCost}
          tooltipText={ReservationTooltip.COST_SAVINGS_RI}
          mb={theme.spacing.spacing01}
          itemWidth="20%"
          grow={false}
        />
      </DataContainer>
      <When condition={isSidebarOpen}>
        <ReservationExistingLink
          showLinks
          hasUtilization={hasUtilization}
          toggleSidebar={toggleIsSidebarOpen}
          isSidebarOpen={isSidebarOpen}
          offeringType={offeringType}
          serviceUsageType={serviceUsageType}
          provider={ReservationsProvider.Azure}
        />
      </When>
    </>
  );
}
