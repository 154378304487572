/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { When } from "react-if";
import {
  Button,
  FlexContainer,
  Spinner,
  SVGIcon,
  Text,
  theme,
} from "@nordcloud/gnui";
import {
  ConnectionStatus,
  useTurbonomicPluginConnectionStatusLazyQuery,
} from "~/generated/graphql";
import { handleError } from "~/tools";
import { getStatusMessage } from "../utils";

type Props = {
  id: string;
  disabled: boolean;
};

export function TestExistingTurbonomicCredentialsButton({
  id,
  disabled,
}: Props) {
  const [connectionTestLoading, setConnectionTestLoading] =
    React.useState(false);

  const [testConnection, { data: connectionTestData, called }] =
    useTurbonomicPluginConnectionStatusLazyQuery({
      fetchPolicy: "no-cache",
    });

  const connectionStatus = connectionTestData?.turbonomicPluginConnectionStatus;

  const onTestConnection = async () => {
    setConnectionTestLoading(true);
    try {
      await testConnection({
        variables: { id },
      });
    } catch (err) {
      handleError(err);
    } finally {
      setConnectionTestLoading(false);
    }
  };

  return (
    <FlexContainer css={{ columnGap: theme.spacing.spacing04 }}>
      <Button
        severity="medium"
        type="button"
        disabled={disabled}
        onClick={onTestConnection}
      >
        Test Connection
      </Button>
      <When condition={connectionTestLoading}>
        <Spinner size="md" />
      </When>
      <When condition={!connectionTestLoading && connectionStatus}>
        <FlexContainer gap={theme.spacing.spacing02}>
          <SVGIcon
            name={
              connectionStatus === ConnectionStatus.Connected
                ? "statusSuccess"
                : "statusError"
            }
          />
          <Text m={0}>{getStatusMessage(connectionStatus)}</Text>
        </FlexContainer>
      </When>
      <When condition={!connectionTestLoading && !connectionStatus && called}>
        <FlexContainer gap={theme.spacing.spacing02}>
          <SVGIcon name="statusError" />
          <Text m={0}>{getStatusMessage(connectionStatus)}</Text>
        </FlexContainer>
      </When>
    </FlexContainer>
  );
}
