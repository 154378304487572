/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ReactNode } from "react";
import { When } from "react-if";
import { theme } from "@nordcloud/gnui";

type Props = {
  children: ReactNode;
  top?: string;
  left?: string;
  show?: boolean;
  color?: string;
  size?: string;
  outlineColor?: string;
};

export function AlertDotWrap({
  children,
  top = "2px",
  left = "17px",
  color = theme.colors.danger,
  size = "4",
  outlineColor = theme.color.background.ui01,
  show = false,
}: Props) {
  const sizePixels = parseInt(size, 10);
  const center = (sizePixels + 1).toString();
  const svgBoxSize = ((sizePixels + 1) * 2).toString();

  return (
    <div css={{ position: "relative" }}>
      <When condition={show}>
        <svg
          width={svgBoxSize}
          height={svgBoxSize}
          css={{
            position: "absolute",
            top,
            left,
          }}
        >
          <circle
            cx={center}
            cy={center}
            r={size}
            fill={color}
            stroke={outlineColor}
            strokeWidth="1"
          />
        </svg>
      </When>
      {children}
    </div>
  );
}
