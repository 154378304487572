/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Link } from "react-router-dom";
import styled from "styled-components";

export const UnstyledLink = styled(Link)`
  &:hover {
    text-decoration: none;

    * {
      text-decoration: none;
    }
  }
`;
