/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ComponentProps } from "react";
import { ApolloError } from "@apollo/client";
import { Tab, Tabs } from "@nordcloud/gnui";
import { isNotEmpty } from "~/tools";
import { DisabledFilters } from "~/views/estate/components/EstateFilters";
import { EstateRecords } from "~/views/estate/EstateRecords";
import { externalIntegrationTabs } from "../../EstateDetailsTabs/components/ExternalIntegrationTabs";
import { CountHeader } from "../CountHeader";
import { Ec2AdvancedData } from "./Ec2AdvancedData";
import { Ec2ResourceTags } from "./Ec2ResourceTags";
import { EstateTabsData, NewResource } from "./types";

type Props = {
  data: EstateTabsData;
  activeTab: number;
  setActiveTab: (key: number) => void;
  dataEstate: {
    data?: NewResource;
    loading: boolean;
    error?: ApolloError;
  };
  estateFilters: ComponentProps<typeof EstateRecords>["queryFilters"];
};
export function Ec2RelatedResourcesTab({
  data,
  dataEstate,
  estateFilters,
  activeTab,
  setActiveTab,
}: Props) {
  const showEnrichments = isNotEmpty(data.enrichments ?? []);

  return (
    <Tabs step={activeTab} handleTab={setActiveTab}>
      <Tab heading="Related Resources">
        <EstateRecords
          excludeColumns={["selection", "actions"]}
          disabledFilters={[DisabledFilters.APPS]}
          dataEstate={dataEstate}
          queryFilters={estateFilters}
        />
      </Tab>
      <Tab
        label={
          <CountHeader count={data.metaTags.length} title="Resource Tags" />
        }
      >
        <Ec2ResourceTags metatags={data.metaTags} />
      </Tab>

      <Tab
        label={
          <CountHeader count={data.metadata.length} title="Advanced Data" />
        }
      >
        <Ec2AdvancedData
          metadata={data.metadata}
          nid={data.estateRecord?.id ?? ""}
        />
      </Tab>
      {showEnrichments && externalIntegrationTabs(data.enrichments)}
    </Tabs>
  );
}
