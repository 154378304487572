/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { generateActionConfirmText } from "~/tools";
import { useDisclosure } from "./useDisclosure";

type ActionItemType = {
  name: string;
};

type TextOrFunction<T> =
  | string
  | ((actionName: string, itemData?: T) => string);

type ConfirmActionProps<T extends ActionItemType> = {
  name: string;
  action: (itemData: T) => Promise<void>;
  contentText?: TextOrFunction<T>;
  actionLabel?: TextOrFunction<T>;
  contentLabel?: TextOrFunction<T>;
};

function getDefaultContentText<T extends ActionItemType>(
  actionName: string,
  actionItem?: T
) {
  return actionItem?.name
    ? generateActionConfirmText(`"${actionItem.name}"`)(actionName)()
    : "";
}

export function useConfirmAction<T extends ActionItemType>({
  name,
  action,
  contentText = getDefaultContentText,
  actionLabel,
  contentLabel,
}: ConfirmActionProps<T>) {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<T | undefined>();
  const { isOpen, close, open } = useDisclosure();

  const onClose = () => {
    close();
    setData(undefined);
  };

  const confirm = async () => {
    if (data && !loading) {
      close();
      setLoading(true);
      await action(data);
      setLoading(false);
      setData(undefined);
    }
  };

  const onClick = (selectedData: T) => {
    open();
    setData(selectedData);
  };

  const getText = (textOrFunction?: TextOrFunction<T>) => {
    if (typeof textOrFunction === "function") {
      return textOrFunction(name, data);
    }
    return textOrFunction ?? "";
  };

  const modalActionLabel = getText(actionLabel);

  const modalContentLabel = getText(contentLabel);

  const modalProps = {
    isOpen,
    onClose,
    confirm,
    actionLabel: modalActionLabel,
    contentLabel: modalContentLabel,
  };

  const modalContentText = getText(contentText);

  return {
    loading,
    modalProps,
    contentText: modalContentText,
    onClick,
  };
}
