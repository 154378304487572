/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { ParentSize } from "@visx/responsive";
import { Else, If, Then, When } from "react-if";
import {
  Box,
  ExtendedTooltip,
  FlexContainer,
  Spacer,
  Switch,
  theme,
} from "@nordcloud/gnui";
import { BrickLoader, UniversalWrap } from "~/components";
import { isNotEmpty } from "~/tools";
import {
  BudgetBreakdown,
  BudgetChart,
  EmptyBudget,
  YearSelector,
} from "./components";

type Props = {
  budget: string[];
  forecast: number[];
  childrenBudget?: number[];
  warningCaption?: string;
  spends: number[];
  loading: boolean;
  error?: React.ComponentProps<typeof UniversalWrap>["errorProps"]["error"];
  year: string;
  isBudgetGenerated: boolean;
  onSetYear: (year: string) => void;
  onCreateBudget: (value: number) => Promise<void>;
  onEditBudget: (values: number[]) => Promise<void>;
  onGetGeneratedBudget: (initial?: boolean) => void;
  onBudgetReset: (cached: number[][]) => void;
};

export function BudgetTab({
  budget,
  forecast,
  childrenBudget,
  warningCaption,
  spends,
  year,
  isBudgetGenerated,
  loading,
  error,
  onSetYear,
  onCreateBudget,
  onEditBudget,
  onGetGeneratedBudget,
  onBudgetReset,
}: Props) {
  const [compare, setCompare] = React.useState(false);
  const [changedBudget, setChangedBudget] = React.useState<
    number[] | undefined
  >(undefined);

  const hasBudget = React.useMemo(
    () => budget.some((value) => parseFloat(value) !== 0),
    [budget]
  );
  const shouldAllowGeneration = React.useMemo(
    () => spends.some((value) => value > 0),
    [spends]
  );
  const showCompare = hasBudget && !loading;

  const caption = shouldAllowGeneration
    ? null
    : "No spends registered for Previous Year";

  return (
    <>
      <Box boxStyle="lightGrey" css={{ marginBottom: theme.spacing.spacing03 }}>
        <FlexContainer
          gap={theme.spacing.spacing04}
          data-testid="budget-actions"
        >
          <YearSelector period={year} setPeriod={onSetYear} />
          <When condition={showCompare}>
            <ExtendedTooltip
              caption={caption}
              placement="bottom"
              position="start"
            >
              <div
                css={{
                  opacity: shouldAllowGeneration ? 1 : 0.5,
                }}
              >
                <Switch
                  labelText="Compare with Previous Year"
                  checked={compare}
                  disabled={!shouldAllowGeneration}
                  onChange={() => setCompare((prevState) => !prevState)}
                />
              </div>
            </ExtendedTooltip>
          </When>
        </FlexContainer>
      </Box>
      <UniversalWrap
        loaderProps={{ loading, Component: BrickLoader }}
        errorProps={{ error }}
      >
        <If condition={!hasBudget}>
          <Then>
            <Spacer height={theme.spacing.spacing03} />
            <EmptyBudget
              year={year}
              shouldAllowGeneration={shouldAllowGeneration}
              getGeneratedBudget={() => onGetGeneratedBudget()}
              previousYear={spends}
              onManualBudgetCreation={onCreateBudget}
            />
          </Then>
          <Else>
            <ParentSize>
              {(parent) => (
                <BudgetChart
                  width={parent.width}
                  height={250}
                  year={year}
                  showPreviousYear={compare}
                  previousYearData={spends}
                  forecast={forecast}
                  currentYearData={
                    changedBudget != null && isNotEmpty(changedBudget)
                      ? changedBudget.map(String)
                      : budget
                  }
                />
              )}
            </ParentSize>
            <Spacer height={theme.spacing.spacing05} />
            <BudgetBreakdown
              monthly={budget.map(Number)}
              childrenMonthly={childrenBudget}
              warningCaption={warningCaption}
              shouldAllowRestoration={shouldAllowGeneration}
              isBudgetGenerated={isBudgetGenerated}
              year={year}
              onChangedBudget={setChangedBudget}
              onEdit={onEditBudget}
              onRestoreBudget={onGetGeneratedBudget}
              onReset={onBudgetReset}
            />
          </Else>
        </If>
      </UniversalWrap>
    </>
  );
}
