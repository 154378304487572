/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useFormContext } from "react-hook-form";
import { When } from "react-if";
import { Sidebar } from "@nordcloud/gnui";
import { getTypedEntries, isNotNil } from "~/tools";
import { getDefaultValues, WorkflowFormData, WorkflowFormMode } from "../types";
import { getDefaultValues as getDefaultWorkflowValues } from "../WorkflowDetails/helpers";
import { WorkflowProps } from "../WorkflowPropsMapping";
import { AppsEnvsField } from "./AppsEnvsField";
import { ActionButtons } from "./components/ActionButtons";
import { OrgUnitsField } from "./OrgUnitsField";
import { AppsEnvsFieldState } from "./useAppsEnvsField";

type Props = {
  formMode: WorkflowFormMode;
  isOpen: boolean;
  onClose: () => void;
  workflow?: WorkflowProps;
};

export function BudgetsSettingsForm({
  isOpen,
  onClose,
  formMode,
  workflow,
}: Props) {
  const { resetField, watch, setValue } = useFormContext<WorkflowFormData>();
  const isReadMode = formMode === "read";

  const handleClear = () => {
    const defaultValues = getDefaultValues().budgetsOptions;
    // Resetting each nested field value individually, because resetting parent didn't work
    getTypedEntries(defaultValues).forEach(([key, value]) => {
      if (!["forecastGtBudget", "costGtBudget"].includes(key)) {
        resetField(`budgetsOptions.${key}` as const, {
          defaultValue: value,
        });
      }
    });
  };

  const [budgetsOptions, snapshot] = watch([
    "budgetsOptions",
    "budgetsSubmissionSnapshot",
  ]);

  const handleClose = () => {
    if (isReadMode) {
      onClose();

      return;
    }

    const getDefaultValue = () => {
      if (isNotNil(snapshot)) {
        return JSON.parse(snapshot);
      }

      if (formMode === "edit" && workflow) {
        return getDefaultWorkflowValues(workflow).budgetsOptions;
      }

      return getDefaultValues().budgetsOptions;
    };

    resetField("budgetsOptions", {
      defaultValue: getDefaultValue(),
    });
    onClose();
  };

  const handleSave = () => {
    setValue("budgetsSubmissionSnapshot", JSON.stringify(budgetsOptions), {
      shouldDirty: true,
    });

    onClose();
  };

  const handleOrgUnitChange = (orgUnits: string[]) => {
    setValue("budgetsOptions.orgUnitIds", orgUnits, {
      shouldDirty: true,
    });
  };

  const handleAppsEnvsFieldChange = (state: AppsEnvsFieldState) => {
    setValue("budgetsOptions.applications", state.apps, {
      shouldDirty: true,
    });
    setValue("budgetsOptions.environments", state.envs, {
      shouldDirty: true,
    });
  };

  return (
    <Sidebar
      title="Budget Settings"
      isOpen={isOpen}
      width="35rem"
      onClick={handleClose}
    >
      <AppsEnvsField
        displayTags
        disabled={isReadMode}
        state={{
          apps: budgetsOptions.applications,
          envs: budgetsOptions.environments,
        }}
        onChange={handleAppsEnvsFieldChange}
      />
      <OrgUnitsField
        disabled={isReadMode}
        initialOrgUnits={budgetsOptions.orgUnitIds}
        treeBehaviour="parentToChild"
        onChange={handleOrgUnitChange}
      />
      <When condition={!isReadMode}>
        <ActionButtons onClear={handleClear} onSave={handleSave} />
      </When>
    </Sidebar>
  );
}
