/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Mode } from "~/components";
import { FilteringParams, Filters, Modes } from "../types";

export function mapFilters(
  modes?: Modes | undefined,
  filters?: Filters | undefined
) {
  const sourceKey =
    modes?.currentMode === Mode.INCLUDE
      ? "includeSourceEnvironments"
      : "excludeSourceEnvironments";
  const targetKey =
    modes?.targetMode === Mode.INCLUDE
      ? "includeTargetEnvironments"
      : "excludeTargetEnvironments";
  const current = filters?.currentEnvironments ?? [];
  const target = filters?.targetEnvironments ?? [];

  return {
    [sourceKey]: current,
    [targetKey]: target,
  };
}

export type ResponseParameters = {
  limit: number;
  page: number;
  filteringParams: FilteringParams | undefined;
};
