/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useFormContext } from "react-hook-form";
import { When } from "react-if";
import {
  Button,
  Sidebar,
  Label,
  Input,
  Text,
  theme,
  InputPassword,
} from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { noop } from "~/tools";
import {
  WorkflowFormData,
  getDefaultValues,
  WorkflowFormMode,
} from "../../types";
import { getDefaultValues as getDefaultWorkflowValues } from "../../WorkflowDetails/helpers";
import { WorkflowProps } from "../../WorkflowPropsMapping";
import { AdditionalFieldsSection } from "./components/AdditionalFieldsSection";
import { DescriptionFieldsSection } from "./components/DescriptionFieldsSection";
import { useValidateFields } from "./hooks";

type Props = {
  formMode: WorkflowFormMode;
  isOpen: boolean;
  workflow?: WorkflowProps;
  onClose: () => void;
  onSave?: () => void;
  requirePassword: boolean;
};

export function ServiceNowSettingsForm({
  formMode,
  isOpen,
  workflow,
  onClose,
  onSave = noop,
  requirePassword,
}: Props) {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
    resetField,
  } = useFormContext<WorkflowFormData>();
  const [serviceNow, serviceNowSubmissionSnapshot, selectDataComponent] = watch(
    ["serviceNow", "serviceNowSubmissionSnapshot", "selectDataComponent"]
  );
  const isReadMode = formMode === "read";
  const validateFields = useValidateFields();

  const handleSaveButtonClick = async () => {
    const isValid = await validateFields();

    if (isValid) {
      setValue("serviceNowSubmissionSnapshot", JSON.stringify(serviceNow), {
        shouldDirty: true,
      });
      setValue("serviceNowSaved", true);
      resetField("serviceNow", { defaultValue: serviceNow });
      onSave();
    }
  };

  const handleClose = () => {
    if (formMode === "add") {
      resetField("serviceNow", {
        defaultValue: serviceNowSubmissionSnapshot
          ? JSON.parse(serviceNowSubmissionSnapshot)
          : getDefaultValues().serviceNow,
      });
    }
    if (formMode === "edit") {
      resetField("serviceNow", {
        defaultValue: serviceNowSubmissionSnapshot
          ? JSON.parse(serviceNowSubmissionSnapshot)
          : workflow &&
            (getDefaultWorkflowValues(workflow).serviceNow as unknown as Pick<
              WorkflowFormData,
              "serviceNow"
            >),
      });
    }

    resetField("budgetsOptions.costGtBudget");
    resetField("budgetsOptions.forecastGtBudget");

    onClose();
  };

  const serviceNowErrors = errors.serviceNow;

  return (
    <Sidebar
      title="ServiceNow Settings"
      isOpen={isOpen}
      width="42rem"
      onClick={handleClose}
    >
      <When condition={!isReadMode}>
        <Text size="sm" color={theme.color.text.text02}>
          Please enter the following information of your ServiceNow account.
        </Text>
      </When>
      <FormGroup error={serviceNowErrors?.url}>
        <Label name="URL" htmlFor="serviceNowUrl" required={!isReadMode} />
        <Input
          disabled={isReadMode}
          id="serviceNowUrl"
          placeholder="https://example.com/"
          {...register("serviceNow.url")}
        />
      </FormGroup>
      <FormGroup error={serviceNowErrors?.username}>
        <Label
          name="Username"
          htmlFor="serviceNowUsername"
          required={!isReadMode}
        />
        <Input
          disabled={isReadMode}
          id="serviceNowUsername"
          placeholder="e.g. JohnDoe"
          {...register("serviceNow.username")}
        />
      </FormGroup>
      <When condition={!isReadMode}>
        <FormGroup error={serviceNowErrors?.password}>
          <Label
            name="Password"
            htmlFor="serviceNowPassword"
            required={requirePassword}
          />
          <InputPassword
            id="serviceNowPassword"
            placeholder="••••••••"
            showPasswordOnInit={false}
            {...register("serviceNow.password")}
          />
        </FormGroup>
      </When>
      <DescriptionFieldsSection
        isReadMode={isReadMode}
        selectDataComponent={selectDataComponent}
      />
      <AdditionalFieldsSection isReadMode={isReadMode} />
      <When condition={!isReadMode}>
        <Button
          type="button"
          mt={theme.spacing.spacing04}
          onClick={handleSaveButtonClick}
        >
          Apply
        </Button>
      </When>
    </Sidebar>
  );
}
