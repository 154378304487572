/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloError } from "@apollo/client";
import { Else, If, Then } from "react-if";
import { generatePath, Link } from "react-router-dom";
import { BrickLoader, Message, Table, Text, theme } from "@nordcloud/gnui";
import { BarChart, UniversalWrap } from "~/components";
import { ROUTES } from "~/routing/routes";
import { isNotEmpty } from "~/tools";
import { Reservation } from "./types";

type Props = {
  reservations: Reservation[];
  loading: boolean;
  error: ApolloError | undefined;
};

export function ExistingReservedInstances({
  reservations,
  loading,
  error,
}: Props) {
  return (
    <UniversalWrap
      loaderProps={{ loading, Component: BrickLoader }}
      errorProps={{ error }}
    >
      <Text weight="medium" mb={theme.spacing.spacing02}>
        Existing Reserved Instances
      </Text>
      <If condition={isNotEmpty(reservations)}>
        <Then>
          <Table>
            <Table.thead>
              <Table.tr>
                <Table.th>Instance Details</Table.th>
                <Table.th css={{ textAlign: "right" }}>Units</Table.th>
                <Table.th>Region</Table.th>
                <Table.th>OS</Table.th>
                <Table.th>Class</Table.th>
                <Table.th>Purchase Option</Table.th>
                <Table.th>Total Utilization</Table.th>
                <Table.th>Expiration Date</Table.th>
                <Table.th css={{ textAlign: "right" }}>Benefit</Table.th>
              </Table.tr>
            </Table.thead>
            <Table.tbody>
              {reservations.map((item) => (
                <Table.tr key={`${item.instanceType}-${item.units}`}>
                  <Table.td>
                    <Link
                      to={generatePath(ROUTES.resource, {
                        nordcloudId: item.id,
                      })}
                    >
                      {item.instanceType}
                    </Link>
                  </Table.td>
                  <Table.td css={{ textAlign: "right" }}>{item.units}</Table.td>
                  <Table.td>{item.region}</Table.td>
                  <Table.td>{item.operatingSystem}</Table.td>
                  <Table.td>{item.offeringClass}</Table.td>
                  <Table.td>{item.paymentOption}</Table.td>
                  <Table.td>
                    <BarChart
                      css={{ marginBottom: 0 }}
                      values={item.utilization}
                    />
                  </Table.td>
                  <Table.td>{item.endDate}</Table.td>
                  <Table.td css={{ textAlign: "right" }}>
                    {item.benefit}
                  </Table.td>
                </Table.tr>
              ))}
            </Table.tbody>
          </Table>
        </Then>
        <Else>
          <Message status="notification" image="info">
            There are no Reserved Instances associated with this instance.
          </Message>
        </Else>
      </If>
    </UniversalWrap>
  );
}
