/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Button, ListLoader, Spacer, theme } from "@nordcloud/gnui";
import {
  ReservationsAndCommitmentsCoverageOfferingType as OfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType as ServiceUsageType,
  ReservationsAndCommitmentsLookbackPeriod as LookbackPeriod,
  ReservationsAndCommitmentsFilters,
  ReservationsProvider,
} from "~/generated/graphql";
import { FiltersFooter, LoaderWrap } from "~/components";
import {
  useGetFilterLabel,
  useGetCurrentTab,
  useReservationsSidebarFilters,
  useSetFilters,
} from "../../hooks";
import { CommonFilters } from "./CommonFilters";

type Props = {
  filters: ReservationsAndCommitmentsFilters;
  serviceUsageType: ServiceUsageType;
  offeringType: OfferingType;
  timePeriod: LookbackPeriod;
  allowSavingFilters: boolean;
  setFilters: (filter: ReservationsAndCommitmentsFilters) => void;
  onApply: () => void;
};

export function AzureReservationsSidebarFilters({
  filters,
  serviceUsageType,
  offeringType,
  timePeriod,
  onApply,
  setFilters,
}: Props) {
  const provider = ReservationsProvider.Azure;
  const { instanceFamilies, regionOptions, loading } =
    useReservationsSidebarFilters({
      offeringType,
      serviceUsageType,
      timePeriod,
      provider,
    });

  const { isAnalyticsTab } = useGetCurrentTab(serviceUsageType);

  const { commit, reset, hasEmptyFilters } = useSetFilters({
    filters,
    onApply,
  });

  const familyLabel = useGetFilterLabel(serviceUsageType);

  return (
    <LoaderWrap loading={loading} Component={ListLoader}>
      <CommonFilters
        familyLabel={familyLabel}
        instanceFamilies={instanceFamilies ?? []}
        regionOptions={regionOptions ?? []}
        filters={filters}
        setFilters={setFilters}
        showInstanceFamilyFilter={!isAnalyticsTab}
      />
      <Spacer height={theme.spacing.spacing04} />
      <FiltersFooter>
        <Button
          disabled={hasEmptyFilters}
          mr={theme.spacing.spacing02}
          onClick={commit}
        >
          Apply filters
        </Button>
        <Button severity="low" disabled={hasEmptyFilters} onClick={reset}>
          Clear filters &amp; reload
        </Button>
      </FiltersFooter>
    </LoaderWrap>
  );
}
