/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  useAddAccountV2Mutation,
  useRemoveAccountV2Mutation,
  useUpdateAccountV2Mutation,
  UpdateAccountV2MutationVariables,
  RemoveAccountV2MutationVariables,
  Query,
  Account,
} from "~/generated/graphql";
import { removeFromApolloCache } from "~/tools";

export function useAddAccount(
  options?: Parameters<typeof useAddAccountV2Mutation>[0]
) {
  const [addAccount, { client, ...rest }] = useAddAccountV2Mutation({
    onCompleted: () => {
      client.refetchQueries({
        updateCache(cache) {
          cache.modify<Query>({
            fields: {
              // Update queries that involve Query.someRootField, without actually
              // changing its value in the cache.
              accountsV2(_value, { INVALIDATE }) {
                return INVALIDATE;
              },
            },
          });
        },
      });
    },
    ...options,
  });

  return [addAccount, { client, ...rest }] as const;
}

export function useUpdateAccount(
  options?: Parameters<typeof useUpdateAccountV2Mutation>[0]
) {
  const [update, { client, ...rest }] = useUpdateAccountV2Mutation({
    ...options,
  });

  const updateAccount = async (variables: UpdateAccountV2MutationVariables) => {
    const { data } = await update({ variables });

    if (data) {
      client.cache.modify<Account>({
        id: client.cache.identify({
          __typename: "AccountV2",
          id: variables.id,
        }),
        fields: {
          name: (name) => variables.name ?? name,
          displayName: (displayName) => variables.displayName ?? displayName,
          // ownerId: (ownerId) => variables.ownerId ?? ownerId,
          description: (description) => variables.description ?? description,
        },
      });
    }
  };

  return [updateAccount, { client, ...rest }] as const;
}

export function useRemoveAccount(
  options?: Parameters<typeof useRemoveAccountV2Mutation>[0]
) {
  const [remove, { client, ...rest }] = useRemoveAccountV2Mutation({
    ...options,
  });

  const removeAccount = async (variables: RemoveAccountV2MutationVariables) => {
    await remove({ variables });

    removeFromApolloCache(client.cache, { item: `Account:${variables.nid}` });
  };

  return [removeAccount, { client, ...rest }] as const;
}
