/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { flexRender, Cell } from "@tanstack/react-table";
import { TableData } from "~/components";
import { Td } from "./EstateGroupingsStyles";

type Props = {
  cell: Cell<TableData>;
  background?: string;
};

export function EstateGroupingsCell({ cell, background }: Props) {
  return (
    <Td key={`cell-${cell.id}`} background={background}>
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </Td>
  );
}
