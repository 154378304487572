/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { CostAnalysisFields } from "~/components";
import {
  CustomerCorrectionTimePoint,
  CustomerServiceGroupTimePoint,
} from "./types";

export function generateDataWithCorrections(
  timepoints: CustomerServiceGroupTimePoint[],
  correctionTimepoints: CustomerCorrectionTimePoint[]
) {
  return timepoints.map((timepoint) => {
    if (dayjs(timepoint.date).isAfter(dayjs(), "day")) {
      return {
        ...timepoint,
        groups: [
          {
            id: "savings",
            value: timepoint.totalCost,
            name: CostAnalysisFields.SAVINGS_PLANS,
          },
        ],
      };
    }

    const correctionCost =
      correctionTimepoints?.find(
        (correction) => correction.date === timepoint.date
      )?.value ?? 0;

    const isCorrections = correctionCost !== 0;
    const costCorrectionGroups = isCorrections
      ? [
          {
            id: "correctionsId",
            value: correctionCost,
            name: CostAnalysisFields.CORRECTION_COST,
          },
        ]
      : [];

    return {
      ...timepoint,
      groups: [...costCorrectionGroups, ...(timepoint.groups ?? [])],
    };
  });
}
