/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as yup from "yup";

export const getIntegrationPluginSchema = () =>
  yup.object().shape({
    name: yup
      .string()
      .min(1, "Integration Plugin Name can not be empty.")
      .max(50, "Integration Plugin Name limit is 50 characters."),
    description: yup
      .string()
      .max(255, "Integration Plugin Description limit is 255 characters."),
    url: yup
      .string()
      .min(1, "Integration Plugin URL can not be empty.")
      .url("URL must be valid."),
    accountName: yup
      .string()
      .min(1, "Integration Plugin Account Name can not be empty."),
    password: yup
      .string()
      .min(1, "Integration Plugin password can not be empty."),
  });
