/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useAuth0 } from "@auth0/auth0-react";
import { isNotLocalServer } from "~/config";
import { YbugProvider } from "~/services/ybug";

const YBUG_ID = "js45v5z94x7hkvfpvmvp";

type Props = {
  children: React.ReactNode;
};

export function YbugWrapper({ children }: Props) {
  const { user } = useAuth0();

  return (
    <YbugProvider
      ybugId={YBUG_ID}
      enabled={isNotLocalServer}
      settings={{
        id: YBUG_ID,
        hide_launcher: true,
        // Listeners need to defined here (to be appended before Ybug initialization)
        onbeforesend: (report: { comment: string }) => {
          if (user?.email == null) {
            return;
          }

          const rumLogs =
            "https://app.datadoghq.com/rum/error-tracking?query=" +
            encodeURIComponent(`@usr.email:${user.email}`);

          const apiLogs =
            "https://app.datadoghq.com/apm/traces?spanType=all&query=" +
            encodeURIComponent(
              `env:production service:apollo operation_name:graphql.execute status:error @usr.id:${user.email}`
            );
          /**
           * report comment format:
           *
           * <comment>
           * ------------------------------------------------
           *
           * Datadog logs:
           * https://app.datadoghq.com/rum/error-tracking?query=*
           * https://app.datadoghq.com/apm/traces?spanType=all&query=*
           */
          report.comment = `${
            report.comment
          }\n------------------------------------------------------------\nDatadog logs: \n${[
            rumLogs,
            apiLogs,
          ].join("\r\n")}`;
        },
      }}
    >
      {children}
    </YbugProvider>
  );
}
