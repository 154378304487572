/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  ApolloError,
  ServerError,
  ServerParseError,
  isApolloError,
} from "@apollo/client";
import { addError } from "~/services/datadog/addError";
import { showError } from "~/services/toast";
import { isEmpty } from "./size";
import { getTypedKeys } from "./types";

export type SimpleError = {
  name: string;
  message: string;
};

export type InputError = ApolloError | Error | SimpleError;

function isNetworkError(error: ApolloError): error is ApolloError & {
  networkError: ServerError | ServerParseError;
} {
  return (
    error.networkError != null &&
    Object.hasOwn(error.networkError, "statusCode")
  );
}

function isSimpleError(error: InputError) {
  return (
    error.message != null && Object.getOwnPropertyNames(error).length === 2
  );
}

const DEFAULT_ERROR_MESSAGE = {
  general: "Something went wrong",
  unknown: "Unknown error",
  network: "Unable to reach server",
};

export const ERROR_CONFIG = {
  UNAVAILABLE: "Requested resource is unavailable",
  BAD_REQUEST: "Bad request",
  TIMEOUT: "Request timeout",
  BAD_USER_INPUT: "Invalid user input",
  NOT_FOUND: "Resource not found",
  INTERNAL_SERVER_ERROR: DEFAULT_ERROR_MESSAGE.general,
  UNKNOWN: DEFAULT_ERROR_MESSAGE.general,
  FORBIDDEN: "Access denied, insufficient role",
  UNAUTHENTICATED: "Access denied, sign in again",
  GRAPHQL_VALIDATION_FAILED: DEFAULT_ERROR_MESSAGE.general,
  GRAPHQL_PARSE_FAILED: DEFAULT_ERROR_MESSAGE.general,
  PERSISTED_QUERY_NOT_FOUND: DEFAULT_ERROR_MESSAGE.general,
  PERSISTED_QUERY_NOT_SUPPORTED: DEFAULT_ERROR_MESSAGE.general,
};

type ErrorConfig = typeof ERROR_CONFIG;

type ErrorCode = keyof ErrorConfig;

function isSupportedErrorCode(
  code: string,
  config: ErrorConfig
): code is ErrorCode {
  return getTypedKeys(config).includes(code as ErrorCode);
}

function getMessage(errorCode: string, errorConfig: ErrorConfig) {
  if (isSupportedErrorCode(errorCode, errorConfig)) {
    return errorConfig[errorCode];
  }

  return DEFAULT_ERROR_MESSAGE.unknown;
}

export function getErrorMessage(
  error: InputError,
  errorConfig: ErrorConfig = ERROR_CONFIG
) {
  if (isSimpleError(error)) {
    return error.message;
  }

  if (isApolloError(error)) {
    for (const err of error.graphQLErrors) {
      // Only the first error is considered (no need to spam UI with multiple messages)
      return getMessage(
        typeof err.extensions?.code === "string" ? err.extensions?.code : "",
        errorConfig
      );
    }

    if (isNetworkError(error)) {
      return DEFAULT_ERROR_MESSAGE.network;
    }
  }

  // Handle runtime JS errors
  return DEFAULT_ERROR_MESSAGE.general;
}

type Actions = { shouldReportError?: boolean; showNotification?: boolean };

type CustomErrorConfig = Partial<ErrorConfig>;

const defaultActions = {
  shouldReportError: false,
  showNotification: true,
};

/**
 *
 * Utility function for inline error handling,
 * may be used for mutations or regular functions
 * @param error - error object (`Error`, `ApolloError` or error-like object `{ name: string, message: string }`)
 * @param actions - custom actions to execute
 * @param actions.shouldReportError - datadog reporting
 * @param actions.showNotification - toast display
 * @param config - optional config for mapping error codes to custom messages
 */
export function handleError(
  error: InputError,
  {
    shouldReportError = false,
    showNotification = true,
  }: Actions = defaultActions,
  config: CustomErrorConfig = {}
) {
  if (shouldReportError) {
    addError(error);
  }

  const mergedErrorConfig = {
    ...ERROR_CONFIG,
    ...config,
  };

  const errorMessage = getErrorMessage(error, mergedErrorConfig);

  if (showNotification) {
    showError(errorMessage, ERROR_TOAST_CONFIG);
  }
}

export const ERROR_TOAST_CONFIG = {
  draggable: false,
  closeOnClick: false,
  autoClose: 5000,
};

export function isEmptyError(error: InputError | undefined) {
  return error == null || isEmpty(Object.getOwnPropertyNames(error));
}

export function isInputError(error: unknown): error is InputError {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  );
}

export function toInputError(maybeError: unknown): InputError {
  if (isInputError(maybeError)) {
    return maybeError;
  }

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}
