/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { When } from "react-if";
import { Box, Label, Text, theme, FlexContainer } from "@nordcloud/gnui";
import { isNotEmpty } from "~/tools";
import { WorkflowDataType } from "../constants";
import { LinkButton } from "./components/LinkButton";
import { markerMap } from "./markers";

type Props = {
  type?: WorkflowDataType;
  required?: boolean;
  name: string;
};

export function MessageMarkersLabel({ type, required = false, name }: Props) {
  const markers = getMarkers(type);
  const hasMarkers = isNotEmpty(markers);
  const [showMarkers, setShowMarkers] = React.useState(true);

  const textMarkersString = "text markers";
  const linkButtonText = showMarkers
    ? `Hide ${textMarkersString}`
    : `Show ${textMarkersString}`;

  return (
    <>
      <FlexContainer justifyContent="space-between">
        <Label name={name} required={required} />
        <When condition={hasMarkers}>
          <LinkButton
            type="button"
            onClick={() => setShowMarkers((prevState) => !prevState)}
          >
            {linkButtonText}
          </LinkButton>
        </When>
      </FlexContainer>
      <When condition={showMarkers && hasMarkers}>
        <Box boxStyle="lightGrey" mb={theme.spacing.spacing04}>
          {markers.map(({ description, title }) => (
            <div key={title}>
              <Text
                color={theme.color.text.text01}
                mb={0}
                css={{ whiteSpace: "pre-wrap" }}
              >
                {title}
              </Text>
              <Text size="sm" color={theme.color.text.text02}>
                {description}
              </Text>
            </div>
          ))}
        </Box>
      </When>
    </>
  );
}

function getMarkers(type?: WorkflowDataType) {
  switch (type) {
    case "SAVING_SUGGESTIONS":
      return markerMap.SAVING_SUGGESTIONS;
    case "BUDGETS":
      return markerMap.BUDGETS;
    case "UNALLOCATED_RESOURCES":
      return markerMap.UNALLOCATED_RESOURCES;
    case "KPI":
      return markerMap.KPI;
    case "COSTS":
      return markerMap.COSTS;
    default:
      return [];
  }
}
