/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FormEvent, useCallback, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { When } from "react-if";
import {
  Accordion,
  AccordionHeader,
  Checkbox,
  InputSearch,
} from "@nordcloud/gnui";
import { useGetUsersPermissionsLazyQuery } from "~/generated/graphql";
import { FormGroup } from "~/components";
import { ERROR_TEXT } from "~/constants";
import { showError } from "~/services/toast";
import { isNotEmpty, isNotNil } from "~/tools";
import { SortByOrder, SortByValue } from "~/views/settings/types";
import { ThresholdFormData, ThresholdFormFields } from "../validators";
import { StyledBox, ListContainer, StyledClearButton } from "./styled";

export function InternalRecipients() {
  const [fetchUsers] = useGetUsersPermissionsLazyQuery();

  const [users, setUsers] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState("");

  const {
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<ThresholdFormData>();

  const onSearchUpdate = useCallback(
    async (search: string) => {
      try {
        const data = await fetchUsers({
          variables: {
            limit: 10,
            page: 0,
            sortByValue: SortByValue.UserEmailId,
            sortByOrder: SortByOrder.Descending,
            searchByValue: search,
          },
        });

        setUsers(
          (data.data?.usersPermissions?.usersPermissions ?? [])
            .filter(isNotNil)
            .map(({ email }) => email)
        );
      } catch {
        showError(ERROR_TEXT.default);
      }
    },
    [fetchUsers]
  );

  const handleSelect = (event: FormEvent<HTMLInputElement>) => {
    const currentRecipients = getValues(ThresholdFormFields.USER_RECIPIENTS);
    const emailClicked = event.currentTarget.value;

    if (currentRecipients.includes(emailClicked)) {
      return setValue(
        ThresholdFormFields.USER_RECIPIENTS,
        currentRecipients.filter((value) => value !== emailClicked)
      );
    }

    setValue(ThresholdFormFields.USER_RECIPIENTS, [
      ...currentRecipients,
      emailClicked,
    ]);
  };

  const handleClear = () => {
    setValue(ThresholdFormFields.USER_RECIPIENTS, []);
  };

  useEffect(() => {
    onSearchUpdate(searchValue);
  }, [searchValue, onSearchUpdate]);

  return (
    <div css={{ width: "100%", position: "relative" }}>
      <Accordion>
        <AccordionHeader title="MCA Users">
          <StyledBox boxStyle="lightGrey">
            <InputSearch
              icon="search"
              value={searchValue}
              onChange={(event) => setSearchValue(event.currentTarget.value)}
            />
            <FormGroup error={errors[ThresholdFormFields.USER_RECIPIENTS]}>
              <Controller
                name={ThresholdFormFields.USER_RECIPIENTS}
                render={() => (
                  <ListContainer>
                    {users.map((email) => (
                      <Checkbox
                        key={email}
                        value={email}
                        id={email}
                        labelText={email}
                        checked={getValues(
                          ThresholdFormFields.USER_RECIPIENTS
                        ).includes(email)}
                        onChange={handleSelect}
                      />
                    ))}
                  </ListContainer>
                )}
              />
            </FormGroup>
          </StyledBox>
        </AccordionHeader>
        <When
          condition={isNotEmpty(getValues(ThresholdFormFields.USER_RECIPIENTS))}
        >
          <StyledClearButton onClick={handleClear}>
            Clear: {getValues(ThresholdFormFields.USER_RECIPIENTS).length}
          </StyledClearButton>
        </When>
      </Accordion>
    </div>
  );
}
