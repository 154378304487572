/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then, When } from "react-if";
import styled from "styled-components";
import { FlexContainer, SVGIcon, Text, theme } from "@nordcloud/gnui";
import { useCustomers } from "~/services/customers";
import { isNotNil, noop } from "~/tools";

export function CustomerSwitcher() {
  const { selectedCustomer, customers, showCustomerSelector } = useCustomers();

  const hasMultipleCustomers = customers.length > 1;

  return (
    <CustomerSwitcherWrapper
      selectable={hasMultipleCustomers}
      id={
        hasMultipleCustomers
          ? "uf-customer-switcher-button"
          : "uf-customer-switcher-button-disabled"
      }
      onClick={hasMultipleCustomers ? showCustomerSelector : noop}
    >
      <If condition={isNotNil(selectedCustomer)}>
        <Then>
          <FlexContainer direction="column" alignItems="start">
            <Text nowrap mb={0} color={theme.color.text.text02} size="sm">
              {selectedCustomer?.organizationName}
            </Text>
            <Text nowrap mb={0} color={theme.color.text.text01} size="sm">
              {selectedCustomer?.name}
            </Text>
          </FlexContainer>
        </Then>
        <Else>
          <Text mb={0} color={theme.color.text.error} size="sm">
            Please select customer
          </Text>
        </Else>
      </If>
      <When condition={hasMultipleCustomers}>
        <SVGIcon name="burgerMenu" />
      </When>
    </CustomerSwitcherWrapper>
  );
}

const CustomerSwitcherWrapper = styled.button<{ selectable: boolean }>`
  display: flex;
  height: 50px;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: ${theme.radiusDefault};
  justify-content: center;
  column-gap: ${theme.spacing.spacing02};
  padding: calc(${theme.spacing.spacing02} - 1px) ${theme.spacing.spacing03};
  cursor: ${({ selectable }) => (selectable ? "pointer" : "default")};
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${({ selectable }) =>
      selectable ? theme.color.interactive.secondaryHover : undefined};
  }
`;
