/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { If, Then, Else } from "react-if";
import styled from "styled-components";
import { FlexContainer, SVGIcon, Text, theme } from "@nordcloud/gnui";
import { WorkflowKpiResult } from "~/generated/graphql";
import { KpiType } from "~/constants";
import { Maybe } from "~/tools";
import { KpiDetailsRow } from "./KpiDetailsRow";

type Props = {
  kpiResult?: Maybe<WorkflowKpiResult>;
  hasAccess: boolean;
};

export function KpiResults({ hasAccess, kpiResult }: Props) {
  return (
    <If condition={hasAccess}>
      <Then>
        <Text size="sm" weight="bold" mb={0} color={theme.color.text.text02}>
          Selected Business Context
        </Text>
        <FlexContainer py={theme.spacing.spacing02}>
          <Indicator
            color={
              kpiResult?.businessContextColor ?? theme.color.support.purple
            }
          />
          <Text>{kpiResult?.businessContextName ?? "-"}</Text>
        </FlexContainer>
        <Text mb={theme.spacing.spacing02}>Cost Allocation Coverage</Text>
        <KpiDetailsRow
          value={kpiResult?.costAllocation?.value}
          percentage={kpiResult?.costAllocation?.percentage}
          targetPercentage={kpiResult?.costAllocation?.target}
          type={KpiType.COST_ALLOCATION_COVERAGE}
        />
        <Text mb={theme.spacing.spacing02}>Overall Waste</Text>
        <KpiDetailsRow
          value={kpiResult?.overallWaste?.value}
          percentage={kpiResult?.overallWaste?.percentage}
          targetPercentage={kpiResult?.overallWaste?.target}
          type={KpiType.OVERALL_WASTE}
        />
        <Text mb={theme.spacing.spacing02}>
          Non-Production Environments Shutdown Time
        </Text>
        <KpiDetailsRow
          value={kpiResult?.nonProdHours?.value}
          percentage={kpiResult?.nonProdHours?.percentage}
          targetPercentage={kpiResult?.nonProdHours?.target}
          type={KpiType.NON_PROD_HOURS}
        />
      </Then>
      <Else>
        <FlexContainer
          direction="column"
          css={{ padding: theme.spacing.spacing07 }}
        >
          <SVGIcon name="emptyData" size="xxl" />
          <Text weight="bold">You have no permission</Text>
        </FlexContainer>
      </Else>
    </If>
  );
}

const Indicator = styled.div<{ color: string }>`
  width: 0.75rem;
  height: 2.5rem;
  background-color: ${(props) => props.color};
  border-radius: 2px;
  margin-right: ${theme.spacing.spacing02};
`;
