/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FieldError, FieldErrors } from "react-hook-form";
import { WorkflowFormData } from "~/views/workflows/types";

export function parseFieldErrors(fieldErrors: FieldErrors<WorkflowFormData>) {
  return {
    shortDescriptionError: fieldErrors.serviceNow?.shortDescription,
    descriptionError: fieldErrors.serviceNow?.description,
    costsOptionsError: fieldErrors.serviceNow?.costsOptions as
      | FieldError
      | undefined,
    kpiOptionsError: fieldErrors.serviceNow?.kpiOptions as
      | FieldError
      | undefined,
    savingSuggestionsError: fieldErrors.serviceNow?.savingSuggestionsOptions as
      | FieldError
      | undefined,
  };
}
