/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as yup from "yup";
import { validateAzureJwt } from "~/views/accounts/validators";
import { REQUIRED_FIELD_WARNING } from "../../constants";

export const enum AzureFormFields {
  ENROLLMENT_NUMBER = "enrollmentNumber",
  ENROLLMENT_ACCESS_KEY = "enrollmentAccessKey",
  CAPACITOR_CUSTOMER_ID = "capacitorCustomerId",
}

export type FormData = {
  [AzureFormFields.ENROLLMENT_NUMBER]: string;
  [AzureFormFields.ENROLLMENT_ACCESS_KEY]: string;
};

export type UpdateFormData = FormData & {
  [AzureFormFields.CAPACITOR_CUSTOMER_ID]: string;
};

export const AzureFormSchema = yup.object().shape({
  [AzureFormFields.ENROLLMENT_NUMBER]: yup
    .string()
    .test({
      name: "is string of digits",
      message: "Enrollment number must be 7-8 digits.",
      test: (value) => new RegExp(/^\d{7,8}$/).test(value ?? ""),
    })
    .min(7, "Enrollment number must be 7-8 digits.")
    .max(8, "Enrollment number must be 7-8 digits.")
    .required(REQUIRED_FIELD_WARNING),
  [AzureFormFields.ENROLLMENT_ACCESS_KEY]: yup
    .string()
    .required(REQUIRED_FIELD_WARNING)
    .test("azureJwt", "This token invalid.", function (value = "") {
      const result = validateAzureJwt(value);
      return result === true ? true : this.createError({ message: result });
    }),
});
