/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Fragment } from "react";
import { flexRender, Row } from "@tanstack/react-table";
import { When } from "react-if";
import styled from "styled-components";
import { Table, theme } from "@nordcloud/gnui";
import { isLastIndex } from "~/tools";
import { Td } from "../../components/CostTableStyles";
import { CostAnalysisFields, Period, TableData } from "../types";

type Props = {
  data: TableData[];
  period: Period;
  rows: Row<TableData>[];
};

export function CostAnalysisCostTableBody({ data, period, rows }: Props) {
  const showTotalSpacer = data.some(
    ({ field }) => field === CostAnalysisFields.TOTAL_COST
  );

  const showOtherSpacer = data.some(({ field }) =>
    [
      CostAnalysisFields.OTHER_APPLICATIONS_COST,
      CostAnalysisFields.OTHER_CATEGORIES_COST,
      CostAnalysisFields.OTHER_SERVICES_COST,
    ].find((value: string) => value === field)
  );

  return (
    <tbody>
      {rows.map((row) => {
        const isRowTotal = showTotalSpacer && row.index === 0;
        const isRowOtherCosts = showOtherSpacer && isLastIndex(rows, row.index);

        return (
          <Fragment key={`data-row-${row.id}`}>
            <When condition={isRowOtherCosts}>
              <RowSpacer />
            </When>
            <tr>
              {row.getVisibleCells().map((cell) => (
                <Td
                  key={`cell-${cell.id}`}
                  period={period}
                  isTotal={isRowTotal || isRowOtherCosts}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </tr>
            <When condition={isRowTotal}>
              <RowSpacer />
            </When>
          </Fragment>
        );
      })}
    </tbody>
  );
}

const RowSpacer = styled(Table.tr)`
  height: ${theme.spacing.spacing04};
`;
