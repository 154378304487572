/**
 * Copyright 2021-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { getUserLocalesInternal } from "~/tools";

type CarbonFootprintConfig = {
  cars: number;
  phones: number;
};

export function getCarbonFootprint(
  co2e: number,
  configuration: CarbonFootprintConfig
) {
  // converts tons to gram units to show co2e
  const grams = co2e * 1000 * 1000;
  return Object.fromEntries(
    Object.entries(configuration).map(([key, value]) => [
      key,
      Math.abs(grams) / value,
    ])
  );
}

export function formatCarbonFootprint(co2e: number) {
  const absoluteCo2e = Math.abs(co2e);
  const locale = getUserLocalesInternal();
  const toLocaleString = (value: number, digits = 2) =>
    value.toLocaleString(locale, { maximumFractionDigits: digits });
  // show ton
  if (absoluteCo2e >= 1) {
    return `${toLocaleString(absoluteCo2e)} t`;
  }
  // show kilogram
  if (absoluteCo2e >= 0.001) {
    return `${toLocaleString(absoluteCo2e * 1000)} kg`;
  }
  // show gram
  if (absoluteCo2e >= 0.000_001) {
    return `${toLocaleString(absoluteCo2e * 1000 * 1000, 0)} g`;
  }
  // show less than 1 gram
  return "<1 g";
}
