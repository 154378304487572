/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Ec2InstanceFamily } from "~/generated/graphql";

export const FAMILY_TYPES = [
  {
    value: Ec2InstanceFamily.GeneralPurpose,
    label: "General Purpose",
    description:
      "Provides a balance of compute, memory, and networking resources, and can be used for a wide range of workloads",
  },
  {
    value: Ec2InstanceFamily.ComputeOptimized,
    label: "Compute Optimized",
    description:
      "Ideal for compute-bound applications that benefit from high-performance processors",
  },
  {
    value: Ec2InstanceFamily.MemoryOptimized,
    label: "Memory Optimized",
    description:
      "Designed to deliver fast performance for workloads that process large data sets in memory",
  },
  {
    value: Ec2InstanceFamily.StorageOptimized,
    label: "Storage Optimized",
    description:
      "Designed for workloads that require high, sequential read and write access to very large data sets on local storage",
  },
  {
    value: Ec2InstanceFamily.NetworkThroughput,
    label: "Improved Network Throughput",
    description:
      "Ideal for applications that can take advantage of improved network throughput and packet rate performance",
  },
  {
    value: Ec2InstanceFamily.Burstable,
    label: "Burstable Performance",
    description:
      "Provides a baseline CPU performance with the ability to burst above the baseline at any time for as long as required",
  },
];

export const INSTANCE_COMPARISON_CAPTION =
  "Instance types comprise varying combinations of CPU, memory, storage, and networking capacity and give you the flexibility to choose the appropriate mix of resources for your applications.";

export const MISSING_CLOUD_ACCOUNT_MESSAGE =
  "We cannot show the Instance Comparison due to the lack of resource scanning. Please provide cloud credentials.";
