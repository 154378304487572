/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import {
  FlexContainer,
  MultipleSelect,
  SelectButton,
  Text,
  theme,
} from "@nordcloud/gnui";
import { DateRangeSelector } from "~/components/DateRangeSelector";
import { DateRange, Period } from "../types";
import { getRangeLabel } from "./utils";

type Props = {
  period: Period;
  onChange: (selectedPeriod?: Period, selectedRange?: DateRange) => void;
  range?: DateRange;
  rangeStartDate?: string;
  rangeEndDate?: string;
};

const defaultRangeLabel = "Date Range";
const defaultSelectButtonWidth = "3rem";

export function TermSelector({
  period,
  onChange,
  range,
  rangeStartDate,
  rangeEndDate,
}: Props) {
  const [rangeLabel, setRangeLabel] = useState(defaultRangeLabel);

  useEffect(() => {
    if (period === Period.RANGE || rangeStartDate) {
      setRangeLabel(getRangeLabel(range));
    }
  }, [period, range, onChange, rangeStartDate, rangeEndDate]);

  const setRange = (selectedRange: DateRange | undefined) => {
    onChange(Period.RANGE, selectedRange);
  };

  const handleClear = () => {
    setRangeLabel(defaultRangeLabel);
    onChange();
  };

  const onPeriodChange = (selectedPeriod: Period) => {
    onChange(selectedPeriod);
  };

  return (
    <FlexContainer
      css={{
        paddingBottom: theme.spacing.spacing01,
        marginRight: theme.spacing.spacing07,
      }}
    >
      <Text size="sm" mb="0" mr={theme.spacing.spacing04} weight="medium">
        Term:
      </Text>
      <MultipleSelect size="small">
        <SelectButton
          name="month"
          value="1"
          labelText="1M"
          isActive={period === Period.MONTH}
          style={{
            minWidth: defaultSelectButtonWidth,
          }}
          onClick={() => onPeriodChange(Period.MONTH)}
        />
        <SelectButton
          name="quarter"
          value="3"
          labelText="3M"
          isActive={period === Period.QUARTER}
          style={{ minWidth: defaultSelectButtonWidth }}
          onClick={() => onPeriodChange(Period.QUARTER)}
        />
        <SelectButton
          name="year"
          value="12"
          labelText="12M"
          isActive={period === Period.YEAR}
          style={{ minWidth: defaultSelectButtonWidth }}
          onClick={() => onPeriodChange(Period.YEAR)}
        />
        <DateRangeSelector
          isActive={period === Period.RANGE}
          label={rangeLabel}
          period={period}
          range={range}
          onApply={setRange}
          onClear={handleClear}
        />
      </MultipleSelect>
    </FlexContainer>
  );
}
