/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  useAddApplicationMutation,
  useRemoveApplicationMutation,
  RemoveApplicationMutationVariables,
  Query,
} from "~/generated/graphql";
import { removeFromApolloCache } from "~/tools";

export function useAddApplication(
  options?: Parameters<typeof useAddApplicationMutation>[0]
) {
  const [updateFn, { client, ...rest }] = useAddApplicationMutation({
    onCompleted: () => {
      client.refetchQueries({
        updateCache(cache) {
          cache.modify<Query>({
            fields: {
              // Update queries that involve Query.someRootField, without actually
              // changing its value in the cache.
              applications(_value, { INVALIDATE }) {
                return INVALIDATE;
              },
              applicationsPaginated(_value, { INVALIDATE }) {
                return INVALIDATE;
              },
            },
          });
        },
      });
    },
    ...options,
  });

  return [updateFn, { client, ...rest }] as const;
}

export function useRemoveApplication(
  options?: Parameters<typeof useRemoveApplicationMutation>[0]
) {
  const [remove, { client, ...rest }] = useRemoveApplicationMutation({
    ...options,
  });

  const removeApplication = async (
    variables: RemoveApplicationMutationVariables
  ) => {
    await remove({ variables });

    removeFromApolloCache(client.cache, {
      item: `Application:${variables.nid}`,
    });
  };

  return [removeApplication, { client, ...rest }] as const;
}
