/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useRemoveSearchPresetMutation } from "~/generated/graphql";
import { ERROR_TEXT } from "~/constants";
import { showError, showSuccess } from "~/services/toast";
import {
  generateActionSuccessText,
  noop,
  removeFromApolloCache,
} from "~/tools";

export type DeleteItem = {
  name: string;
  id: string;
};

type Props = {
  onSuccess: () => void;
  deleteItem: DeleteItem | null;
};

export function useRemoveSearchPreset({ onSuccess = noop, deleteItem }: Props) {
  const [removeSearchPreset] = useRemoveSearchPresetMutation();

  return async () => {
    if (deleteItem == null) {
      return;
    }

    try {
      const response = await removeSearchPreset({
        variables: { id: deleteItem.id },
        update: (cache) =>
          removeFromApolloCache(cache, {
            item: `SearchPreset:${deleteItem.id}`,
          }),
      });
      if (response) {
        showSuccess(generateActionSuccessText(deleteItem.name)()("removed")());
      }

      onSuccess();
    } catch (error) {
      showError(error.message ?? ERROR_TEXT.tryAgainDefault);
      throw error;
    }
  };
}
