/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { dateFormat } from "~/constants";
import { useQueryState } from "~/hooks";
import { useBillingPeriod } from "~/services/customers";
import { Period, Granularity, ChartsQueryState, DateRange } from "../types";
import { isMoreThanYearDiff } from "../utils";

export function usePeriod(range?: DateRange) {
  const {
    state: {
      period: statePeriod,
      granularity: stateGranularity,
      rangeEndDate,
      rangeStartDate,
    },
  } = useQueryState<ChartsQueryState>();
  const { isCurrentBillingPeriod } = useBillingPeriod();
  const [period, setPeriod] = useState(statePeriod ?? Period.MONTH);
  const isDailyChartViewDisabled = isDailyViewDisabled(period, range);

  const [granularity, setGranularity] = useState(
    stateGranularity ?? getGranularity(period, isDailyChartViewDisabled)
  );
  const isRangePeriod = period === Period.RANGE;
  const startDate =
    isRangePeriod && rangeStartDate
      ? dayjs(rangeStartDate).format(dateFormat.shortDate)
      : getStartDate(period, isCurrentBillingPeriod, range);
  const endDate =
    isRangePeriod && rangeEndDate
      ? dayjs(rangeEndDate).format(dateFormat.shortDate)
      : getEndDate(period, isCurrentBillingPeriod, range);

  const currentYear = dayjs().format(dateFormat.year);

  useEffect(() => {
    setGranularity(
      stateGranularity ?? getGranularity(period, isDailyChartViewDisabled)
    );
  }, [period, isDailyChartViewDisabled, stateGranularity]);

  return {
    period,
    setPeriod,
    granularity,
    setGranularity,
    startDate,
    endDate,
    currentYear,
    isDailyChartViewDisabled,
  };
}

function getGranularity(period: Period, isDailyChartViewDisabled: boolean) {
  if (period === Period.YEAR || isDailyChartViewDisabled) {
    return Granularity.MONTHS;
  }
  return Granularity.DAYS;
}

function getStartDate(
  period: Period,
  isCurrentBillingPeriod: boolean,
  range?: DateRange
) {
  const startDate = isCurrentBillingPeriod
    ? dayjs()
    : dayjs().subtract(1, "month");

  if (period === Period.QUARTER) {
    return startDate
      .subtract(Period.QUARTER, "months")
      .startOf("month")
      .format(dateFormat.shortDate);
  }

  if (period === Period.YEAR) {
    return startDate
      .subtract(Period.YEAR, "months")
      .startOf("month")
      .format(dateFormat.shortDate);
  }

  if (period === Period.RANGE) {
    return dayjs(range?.from).format(dateFormat.shortDate);
  }

  return startDate.startOf("month").format(dateFormat.shortDate);
}

function getEndDate(
  period: Period,
  isCurrentBillingPeriod: boolean,
  range?: DateRange
) {
  const endDate = isCurrentBillingPeriod
    ? dayjs().endOf("month")
    : dayjs().subtract(1, "month").endOf("month");

  if (period === Period.QUARTER) {
    return endDate.format(dateFormat.shortDate);
  }

  if (period === Period.RANGE) {
    return dayjs(range?.to).format(dateFormat.shortDate);
  }

  return endDate.endOf("month").format(dateFormat.shortDate);
}

function isDailyViewDisabled(period: Period, range?: DateRange) {
  if (period !== Period.RANGE) {
    return false;
  }

  return isMoreThanYearDiff(range?.from, range?.to);
}
