/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Provider } from "~/generated/graphql";
import { useQueryState } from "~/hooks";
import { FiltersProp } from "~/models/estateRecordRow";
import { getTypedKeys, isEmptyObj, isNotEmpty } from "~/tools";
import { useCostHook } from "./useCostHook";
import { useDateRange } from "./useDateRange";

export enum DisabledFilters {
  EXTERNAL = "external",
  ENRICHMENT = "enrichment",
  APPS = "aplications",
}

type Props = {
  filters: FiltersProp;
  setFilters: (filter: FiltersProp) => void;
  onApply: () => void;
  disabledFilters?: DisabledFilters[];
};

export function useHandleEstateFilters({
  filters,
  setFilters,
  onApply,
  disabledFilters = [],
}: Props) {
  const { isCostInvalid } = useCostHook(
    filters.costFrom ?? 0,
    filters.costTo ?? 0
  );

  const { isDateInvalid } = useDateRange(
    filters.assignmentUpdatedFrom,
    filters.assignmentUpdatedTo
  );

  const { updateQueryState, removeFields } = useQueryState();

  const commit = () => {
    updateQueryState({ ...filters, page: 0 });
    onApply();
  };

  const reset = () => {
    removeFields([...getTypedKeys(filters), "page"]);
    onApply();
  };

  const onSelectAppsAndEnvs = (appsValue: string[], envsValue: string[]) => {
    setFilters({ ...filters, application: appsValue, environment: envsValue });
  };

  const onSelectEnvs = (envsValue: string[]) => {
    setFilters({ ...filters, environment: envsValue });
  };

  const toggleProvider = (provider: Provider) => {
    const toggledProvider = filters.provider?.find(
      (filterProvider) => filterProvider === provider
    );

    if (toggledProvider) {
      setFilters({
        ...filters,
        externalIntegration: undefined,
        provider: filters.provider?.filter(
          (filterProvider) => filterProvider !== provider
        ),
        region: undefined,
        account: undefined,
      });
    } else {
      setFilters({
        ...filters,
        provider: [...(filters.provider ?? []), provider],
      });
    }
  };

  const showEstateUnassigned = !filters.application && !filters.environment;

  const isRegionEnabled = isNotEmpty(
    filters.provider?.filter((provider) =>
      [Provider.Aws, Provider.Azure, Provider.Gcp, Provider.Ibmcloud].includes(
        provider
      )
    ) ?? []
  );

  const showExternalIntegration = !disabledFilters?.includes(
    DisabledFilters.EXTERNAL
  );

  const showEnrichment = !disabledFilters?.includes(DisabledFilters.ENRICHMENT);

  const showApps = !disabledFilters?.includes(DisabledFilters.APPS);

  const hasEmptyFilters = isEmptyObj(filters);

  const isApplyButtonDisabled =
    hasEmptyFilters || isCostInvalid || isDateInvalid;

  return {
    commit,
    reset,
    onSelectAppsAndEnvs,
    onSelectEnvs,
    toggleProvider,
    isCostInvalid,
    isDateInvalid,
    isRegionEnabled,
    showEstateUnassigned,
    showExternalIntegration,
    showEnrichment,
    showApps,
    hasEmptyFilters,
    isApplyButtonDisabled,
  };
}
