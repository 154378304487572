/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { useGetAnomalyDetailsLazyQuery } from "~/generated/graphql";
import { useCurrency } from "~/hooks";
import { formatResources } from "../charts/components/utils";
import { useIsDateInCurrentBillingPeriod } from "./useIsDateInCurrentBillingPeriod";

type Props = {
  date: string;
};

export function useAnomaliesDetails({ date }: Props) {
  const { currency } = useCurrency();
  const isInCorrectBillingPeriod = useIsDateInCurrentBillingPeriod(date);

  const [getAnomalyDetails, { data, loading: isAnomaliesLoading }] =
    useGetAnomalyDetailsLazyQuery();

  const anomalies = formatResources(
    data?.anomalyV2.resources ?? [],
    currency,
    isInCorrectBillingPeriod
  );

  const anomaliesCount = data?.anomalyV2.count ?? 0;

  return { getAnomalyDetails, isAnomaliesLoading, anomalies, anomaliesCount };
}
