/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";

export const TooltipWrapper = styled.div`
  > * {
    display: block;
  }
`;
