/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  ReservationsRecommendationPaymentOption,
  ReservationsRecommendationTerm,
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsFilters,
} from "~/generated/graphql";

type ComponentLabel = {
  value: string;
  label: string;
};

export const breadcrumbLabelsAWSRI: ComponentLabel[] = [
  {
    value: "reservations-and-commitments",
    label: "Reservations and Commitments",
  },
];

export const filtersValues: ReservationsAndCommitmentsFilters = {
  instanceFamily: [],
  platform: [],
  region: [],
};

export const breadcrumbLabelsAzureRI: ComponentLabel[] = [
  {
    value: "reservations-azure",
    label: "Azure Reservations",
  },
];

export const azurePaymentOptionsFilter = [
  {
    name: "AllUpfront",
    labelText: "All Upfront",
    value: ReservationsRecommendationPaymentOption.AllUpfront,
  },
  {
    name: "Monthly",
    labelText: "Monthly",
    value: ReservationsRecommendationPaymentOption.Monthly,
  },
];

export const awsPaymentOptionsFilter = [
  {
    name: "AllUpfront",
    labelText: "All Upfront",
    value: ReservationsRecommendationPaymentOption.AllUpfront,
  },
  {
    name: "PartialUpfront",
    labelText: "Partial Upfront",
    value: ReservationsRecommendationPaymentOption.PartialUpfront,
  },
  {
    name: "NoUpfront",
    labelText: "No Upfront",
    value: ReservationsRecommendationPaymentOption.NoUpfront,
  },
];

export const AzurePaymentOptions = [
  {
    value: ReservationsRecommendationPaymentOption.AllUpfront,
    label: "Upfront",
  },
  {
    value: ReservationsRecommendationPaymentOption.Monthly,
    label: "Monthly",
  },
];

export const termsFilter = [
  {
    name: "OneYear",
    labelText: "One Year",
    subtitleText: "1 Year",
    value: ReservationsRecommendationTerm.OneYear,
  },
  {
    name: "ThreeYears",
    labelText: "Three Years",
    subtitleText: "3 Years",
    value: ReservationsRecommendationTerm.ThreeYears,
  },
];

type ReservationsTypeOption = {
  value: ReservationsAndCommitmentsCoverageOfferingType;
  valueLabel: string;
  label: string;
  description?: string;
};

export const ReservationsAndCommitmentsOptions: ReservationsTypeOption[] = [
  {
    value: ReservationsAndCommitmentsCoverageOfferingType.All,
    valueLabel: "Potential Monthly Savings",
    label: "Reserved Instances Recommendations",
    description: "1 year/upfront",
  },
  {
    value: ReservationsAndCommitmentsCoverageOfferingType.ReservedInstances,
    valueLabel: "Potential Monthly Savings",
    label: "Reserved Instances Recommendations",
    description: "1 year/upfront",
  },
  {
    value: ReservationsAndCommitmentsCoverageOfferingType.SavingsPlans,
    valueLabel: "Potential Monthly Savings",
    label: "Compute Savings Plan Recommendations",
    description: "1 year/upfront",
  },
];

export const FONT_SIZE = 10;
export const VALUE_Y_TICKS = 10;
export const VALUE_X_TICKS = 16;
export const TICK_LENGTH = 6;
export const CHART_MARGIN = { top: 20, right: 0, bottom: 20, left: 60 };
export const NOT_APPLICABLE = "N/A";

export const AwsPaymentOptions = [
  {
    value: ReservationsRecommendationPaymentOption.AllUpfront,
    label: "All Upfront",
  },
  {
    value: ReservationsRecommendationPaymentOption.PartialUpfront,
    label: "Partial Upfront",
  },
  {
    value: ReservationsRecommendationPaymentOption.NoUpfront,
    label: "No Upfront",
  },
];

export const TermOptions = [
  {
    value: ReservationsRecommendationTerm.OneYear,
    label: "1 year",
  },
  {
    value: ReservationsRecommendationTerm.ThreeYears,
    label: "3 years",
  },
];

export const DATABASE_PLATFORM_OPTIONS = [
  {
    platform: "Oracle",
    mappedPlatform: [
      "oracle-se(byol)",
      "oracle-se1(byol)",
      "oracle-se2(byol)",
      "oracle-ee(byol)",
      "oracle-se2(li)",
    ],
  },
  {
    platform: "SQL Server",
    mappedPlatform: [
      "sqlserver-se(li)",
      "sqlserver-ee(li)",
      "sqlserver-web(li)",
      "sqlserver-ex(li)",
    ],
  },
  { platform: "PostgreSQL", mappedPlatform: ["postgresql"] },
  { platform: "MariaDB", mappedPlatform: ["mariadb"] },
  { platform: "Aurora MySQL", mappedPlatform: ["aurora-mysql"] },
  { platform: "Aurora PostgreSQL", mappedPlatform: ["aurora-postgresql"] },
  { platform: "MySQL", mappedPlatform: ["mysql"] },
  { platform: "Elasticache", mappedPlatform: ["redis", "memcached"] },
];

export const filtersTable: (
  | keyof ReservationsAndCommitmentsFilters
  | "limit"
  | "page"
)[] = ["instanceFamily", "region", "platform"];

export const RESERVATION_BANNER_DEFAULT_PROPS = {
  heading: "There are no recommendations",
  subText:
    "IBM Multicloud Accelerator can only generate recommendations for the current month.",
  buttonText: "Switch to the Current Month",
  isLink: false,
};

export const enum RecommendationsBanner {
  MACHINE_LEARNINGS = "Based on the historical usage of sagemaker machines during a specific period, the system collects usage data and finds no recommendations that can be made.",
  AWS_DATABASE = "Based on the historical usage of RDS, Redshift, OpenSearch, or ElasticCache services during a specific period, the system collects usage data and finds no recommendations that can be made.",
  AZURE_DATABASE = "Based on the historical usage of database resources during a specific period, the system collects usage data and finds no recommendations that can be made.",
  AZURE_COMPUTE = "Based on the historical usage of compute resources during a specific period, the system collects usage data and finds no recommendations that can be made.",
  STORAGE = "Based on the historical usage of storage resources during a specific period, the system collects usage data and finds no recommendations that can be made.",
  ANALYTICS = "Based on the historical usage of analytics resources during a specific period, the system collects usage data and finds no recommendations that can be made.",
  COMPUTE_RESERVATIONS = "Based on the historical usage of EC2 services during a specific period, the system collects usage data and finds no recommendations that can be made.",
  COMPUTE_SAVINGS_PLAN_WITH_FILTERS = "Compute Savings Plans automatically apply to any EC2 instance usage regardless of region, instance family, operating systems or tenency.",
  COMPUTE_SAVINGS_PLAN_WITHOUT_FILTERS = "Based on the historical usage of Compute services during a specific period, the system collects usage data and finds no recommendations that can be made.",
}

export const AZURE_DATABASE_PLATFORM_MAP: { [key: string]: string } = {
  "SQL Database": "SQL",
  "Azure Cosmos DB": "",
  "Azure Database for MySQL": "MySQL",
  "Azure Database for MariaDB": "MariaDB",
  "SQL Managed Instance": "SQL",
  "Azure Database for PostgreSQL": "PostgreSQL",
};

export const MULTI_REGIONS_RESOURCE: { [key: string]: string } = {
  "Azure Cosmos DB": "Azure Cosmos DB",
};

export const enum NESTING_LEVEL {
  PARENT = "PARENT",
  CHILD = "CHILD",
}

export const enum ResourceLabel {
  AzureDatabaseAndAnalytics = "service",
  AzureStorage = "tiers and redundancy options",
  AWSResources = "family instances",
  Others = "instance",
  InstanceDetails = "Instance Details",
  ServiceDetails = "Service Details",
  TierDetails = "Tier And Redundancy Details",
  InstanceFamily = "Instance Family",
  Service = "Service",
  TierAndRedundancy = "Tier and Redundancy Option",
  Instance = "Instance",
}

export const enum ReservationTooltip {
  ON_DEMAND_COST = "Cost for cloud resources made available to the user as needed",
  POTENTIAL_SAVINGS = "Potential extra cost reductions associated with RIs",
  COST_SAVINGS_RI = " Cloud cost savings achieved by RIs",
  COST_SAVINGS_SP = " Cloud cost savings achieved by SPs",
}
