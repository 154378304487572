/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsProvider,
} from "~/generated/graphql";
import { useGetCurrentTab } from "./useGetCurrentTab";

type Props = {
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  provider: ReservationsProvider;
};

export function useGetRecommendationBox({ serviceUsageType, provider }: Props) {
  const isAws = provider === ReservationsProvider.Aws;

  const { isComputeTab, isMachineLearningTab } =
    useGetCurrentTab(serviceUsageType);
  const hasClickableTab = isAws && isComputeTab;
  switch (true) {
    case isMachineLearningTab:
      return {
        offeringType:
          ReservationsAndCommitmentsCoverageOfferingType.SavingsPlans,
        hasMultiTabs: false,
        savingsPlanType:
          ReservationsAndCommitmentsCoverageOfferingType.SavingsPlans,
      };
    case isComputeTab:
      return {
        offeringType: ReservationsAndCommitmentsCoverageOfferingType.All,
        hasMultiTabs: hasClickableTab,
        savingsPlanType:
          ReservationsAndCommitmentsCoverageOfferingType.SavingsPlans,
      };
    default:
      return {
        offeringType:
          ReservationsAndCommitmentsCoverageOfferingType.ReservedInstances,
        savingsPlanType:
          ReservationsAndCommitmentsCoverageOfferingType.ReservedInstances,
        hasMultiTabs: false,
      };
  }
}
