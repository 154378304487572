/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { MenuBarContext } from "./context";

export function useSidebarVisibility() {
  const context = React.useContext(MenuBarContext);

  if (context === undefined) {
    throw new Error(
      "useSidebarVisibility must be used within a MenuBarContext"
    );
  }

  return context;
}
