/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { CloudWasteSettingKey } from "~/generated/graphql";

export function getIconName(policyId: CloudWasteSettingKey) {
  const isTurbonomic = policyId === "TURBONOMIC_ACTION";
  return {
    iconName: isTurbonomic ? "turbonomic" : "cloudWaste",
  } as const;
}

export function getCo2eSavingsTitle(co2eSavings: string) {
  return parseFloat(co2eSavings) < 0
    ? "Additional Carbon Emissions"
    : "Carbon Emissions Savings";
}

export function getUrlButtonText(
  policyId: CloudWasteSettingKey,
  cloudProvider: string
) {
  if (policyId === "TURBONOMIC_ACTION") {
    return "Go to Turbonomic";
  }

  switch (cloudProvider) {
    case "AZURE":
      return "Go to Azure Portal";
    case "AWS":
      return "Go to AWS Console";
    case "GCP":
      return "Go to Google Cloud Console";
    default:
      return "";
  }
}
