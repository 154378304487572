/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  WorkflowEventInListSortingFields,
  WorkflowSortingOrder,
} from "~/generated/graphql";
import { SortSelector, SortDirection } from "~/components";

type Props = {
  sortField: WorkflowEventInListSortingFields;
  sortOrder: WorkflowSortingOrder;
  onSortFieldChange: (value: WorkflowEventInListSortingFields) => void;
  onSortOrderChange: (value: WorkflowSortingOrder) => void;
};

export function WorkflowHistoryListSort({
  sortField,
  sortOrder,
  onSortFieldChange,
  onSortOrderChange,
}: Props) {
  const order =
    sortOrder === WorkflowSortingOrder.Asc
      ? SortDirection.Ascending
      : SortDirection.Descending;

  const handleSortChange = (value: SortDirection) => {
    const newOrder =
      value === SortDirection.Ascending
        ? WorkflowSortingOrder.Asc
        : WorkflowSortingOrder.Desc;

    onSortOrderChange(newOrder);
  };

  return (
    <SortSelector
      options={SortOptions}
      value={sortField}
      direction={order}
      onSortChange={handleSortChange}
      onSelect={(field) =>
        onSortFieldChange(field as WorkflowEventInListSortingFields)
      }
    />
  );
}

const SortOptions = [
  {
    value: WorkflowEventInListSortingFields.StartTime,
    label: "Start Date",
  },
  {
    value: WorkflowEventInListSortingFields.Status,
    label: "Status",
  },
  {
    value: WorkflowEventInListSortingFields.Name,
    label: "Workflow Name",
  },
];
