/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import {
  RecommendationState,
  useUpdateCostSavingMutation,
} from "~/generated/graphql";
import { showSuccess } from "~/services/toast";
import { DismissModalPayload } from "../types";

type Props = {
  setModalPayload: (v: DismissModalPayload) => void;
};

export function useDismissSuggestion({ setModalPayload }: Props) {
  const [dismissedIds, setDismissedIds] = useState<string[]>([]);
  const [updateCostSavings, state] = useUpdateCostSavingMutation();

  async function dismiss(detectionId: string, comment?: string) {
    await updateCostSavings({
      variables: {
        detectionId: detectionId ?? "",
        state: RecommendationState.Dismissed,
        comment: comment ?? "",
      },
    });
    showSuccess("Successfully Dismissed Suggestion");
    setDismissedIds([...dismissedIds, detectionId]);
    setModalPayload({
      isOpen: false,
      detectionId: undefined,
    });
  }

  function isDismissed(id: string) {
    return dismissedIds.includes(id);
  }

  return { dismiss, isDismissed, state };
}
