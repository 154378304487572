/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer, Label, Text, theme } from "@nordcloud/gnui";
import { PreviewButton } from "../../EmailSettingsForm/PreviewButton";

type Props = {
  onPreviewClick: () => void;
  description: string;
};

export function StaticDescriptionField({ onPreviewClick, description }: Props) {
  return (
    <FlexContainer
      direction="column"
      alignItems="start"
      rowGap={theme.spacing.spacing02}
      marginBottom={theme.spacing.spacing02}
    >
      <FlexContainer justifyContent="space-between" css={{ width: "100%" }}>
        <Label name="Description" css={{ margin: theme.spacing.spacing00 }} />
        <PreviewButton type="button" size="sm" onClick={() => onPreviewClick()}>
          Sample Message
        </PreviewButton>
      </FlexContainer>
      <Text size="sm" color={theme.color.text.text02}>
        {description}
      </Text>
    </FlexContainer>
  );
}
