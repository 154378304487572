/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { getUserLocalesInternal, isNil, isNotNil } from "~/tools";

export function formatTotalCosts(value: number, currency: string) {
  // example: 123345 -> 123.3k
  // "en-US" is used to retrieve universal abbreviation symbols e.g.
  // k - thousand
  // m - milion
  // b - bilion
  const abbreviated = new Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 1,
  })
    .format(value)
    .toLowerCase();

  // return value:
  // [
  //   { type: "integer", value: "3" },
  //   { type: "group", value: "." },
  //   { type: "integer", value: "500" },
  //   { type: "decimal", value: "," },
  //   { type: "fraction", value: "00" },
  //   { type: "literal", value: " " },
  //   { type: "currency", value: "€" },
  // ];
  const withCurrencyFormatParts = new Intl.NumberFormat(
    getUserLocalesInternal(),
    {
      style: "currency",
      currency,
      currencyDisplay: "narrowSymbol",
    }
  ).formatToParts(value);

  const currencyIndex = withCurrencyFormatParts.findIndex(
    (part) => part.type === "currency"
  );

  // Currency position varies based on user locale, it should be preserved
  return currencyIndex === 0
    ? `${withCurrencyFormatParts[currencyIndex].value}${abbreviated}`
    : `${abbreviated} ${withCurrencyFormatParts[currencyIndex].value}`;
}

function sumCosts(currentCost: string, previousCost: string) {
  return (parseFloat(currentCost) + parseFloat(previousCost)).toString();
}

type ReducedApp = {
  nid: string;
  name: string;
  currentMonth: string;
  forecast: string;
  previousMonth: string;
};

export function transformSpendingData<
  T extends {
    defaultApplication?: ReducedApp | null;
    topSpending?: ReducedApp[] | null;
    total?: { currentMonth: string };
    other?: ReducedApp;
  },
>(limit: number, data?: T) {
  if (isNil(data)) {
    return { allApps: [], appSpendsByNameValue: [], totalCosts: 0 };
  }

  const {
    defaultApplication,
    total,
    other = {
      currentMonth: "0.00",
      forecast: "0.00",
      name: "Other",
      nid: "",
      previousMonth: "0.00",
    },
  } = data;
  const topSpending = data.topSpending ?? [];

  const availableSlots = limit - (isNotNil(defaultApplication) ? 1 : 0);
  const showOther = topSpending.length > availableSlots;
  const topSpendingLimit = availableSlots - (showOther ? 1 : 0);

  const appsNotIncludedInOtherSpending = showOther
    ? topSpending.slice(topSpendingLimit)
    : [];

  const adjustedOtherSpending = appsNotIncludedInOtherSpending.reduce(
    (prev, curr) => ({
      ...prev,
      currentMonth: sumCosts(curr.currentMonth, prev.currentMonth),
      forecast: sumCosts(curr.forecast, prev.forecast),
      previousMonth: sumCosts(curr.previousMonth, prev.previousMonth),
    }),
    other
  );

  const allApps = [
    ...topSpending.slice(0, topSpendingLimit),
    ...(showOther ? [adjustedOtherSpending] : []),
    ...(isNotNil(defaultApplication) ? [defaultApplication] : []),
  ];

  const appSpendsByNameValue = allApps.map((app) => ({
    name: app.name,
    value: parseFloat(app.currentMonth || "0"),
  }));

  return {
    allApps,
    appSpendsByNameValue,
    totalCosts: parseFloat(total?.currentMonth || "0"),
  };
}
