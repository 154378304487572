/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Button, theme, FlexContainer } from "@nordcloud/gnui";
import { isNotNil } from "~/tools";

type Props = {
  title: string;
  onSettingsClick?: () => void;
  onRemoveClick?: () => void;
  isReadonly: boolean;
  settingsDisabled?: boolean;
};

export function ComponentActions({
  title,
  onRemoveClick,
  onSettingsClick,
  isReadonly,
  settingsDisabled = false,
}: Props) {
  if (!onRemoveClick && !onSettingsClick) {
    return null;
  }

  return (
    <FlexContainer columnGap={theme.spacing.spacing02}>
      <When condition={isNotNil(onSettingsClick)}>
        <ActionButton
          type="settings"
          onClick={onSettingsClick}
          title={title}
          isReadonly={isReadonly}
          disabled={settingsDisabled}
        />
      </When>
      <When condition={isNotNil(onRemoveClick)}>
        <ActionButton
          type="remove"
          onClick={onRemoveClick}
          title={title}
          isReadonly={isReadonly}
        />
      </When>
    </FlexContainer>
  );
}

type ActionButtonProps = {
  type: "remove" | "settings";
  title: string;
  onClick?: () => void;
  isReadonly: boolean;
  disabled?: boolean;
};

function ActionButton({
  type,
  onClick,
  title,
  isReadonly,
  disabled = false,
}: ActionButtonProps) {
  const icon = getIconName(type, isReadonly);

  return (
    <Button
      key={title}
      severity="low"
      icon={icon}
      size="md"
      onClick={onClick}
      type="button"
      title={`${title} Settings`}
      disabled={disabled}
    />
  );
}

const getIconName = (type: "remove" | "settings", isReadonly: boolean) => {
  if (type === "settings") {
    return isReadonly ? "eye" : "settings";
  }

  return "trash";
};
