/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Box, Text, theme } from "@nordcloud/gnui";
import { SelectCustomerForm } from "./SelectCustomerForm";
import { useCustomerSelectorForm } from "./useCustomerSelectorForm";
import { Customer } from "./useFetchCustomers";

type Props = {
  customers: Customer[];
  onSubmit: (id: string, name: string, orgName: string) => void;
};

export function SelectCustomerView({ customers, onSubmit }: Props) {
  const { onCustomerSelected, onSearchChange, filteredCustomers } =
    useCustomerSelectorForm({
      customers,
      onSubmit,
    });

  return (
    <Box
      px={theme.spacing.spacing08}
      css={{
        width: "50vw",
        minWidth: "600px",
        margin: `${theme.spacing.spacing04} auto`,
      }}
    >
      <Text as="h1">Select Tenant</Text>
      <SelectCustomerForm
        customers={filteredCustomers}
        onCustomerSelected={onCustomerSelected}
        onSearchChange={onSearchChange}
      />
    </Box>
  );
}
