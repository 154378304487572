/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FilterProps } from "../types";
import { RecordsDateRange } from "./RecordsDateRange";

export function ScannedRecordsDateRange({ filters, setFilters }: FilterProps) {
  return (
    <RecordsDateRange
      startDate={filters.firstSeenAtFrom}
      endDate={filters.firstSeenAtTo}
      showLabels={false}
      onStartDateChange={(date) =>
        setFilters({ ...filters, firstSeenAtFrom: date })
      }
      onEndDateChange={(date) =>
        setFilters({ ...filters, firstSeenAtTo: date })
      }
    />
  );
}
