/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { generatePath, Link } from "react-router-dom";
import {
  Button,
  ExtendedTooltip,
  FlexContainer,
  Spacer,
  SVGIcon,
  Text,
  theme,
} from "@nordcloud/gnui";
import { ROUTES } from "~/routing/routes";
import { MappedOrgUnitV2 } from "../types";

type Props = {
  orgUnits: MappedOrgUnitV2[];
  onDetach: (item: MappedOrgUnitV2) => void;
};

export function OrgUnitPopover({ orgUnits, onDetach }: Props) {
  return (
    <div
      css={{
        padding: `${theme.spacing.spacing02} ${theme.spacing.spacing04}`,
        border: theme.borderDefault,
        backgroundColor: theme.color.background.ui01,
        borderRadius: theme.radius.md,
        maxWidth: "20rem",
        boxShadow: theme.shadow.shadow04,
      }}
    >
      <ul>
        {orgUnits.map((orgUnit, index, list) => (
          <li
            key={orgUnit.id}
            css={{
              listStyle: "none",
              marginBottom:
                index === list.length - 1 ? undefined : theme.spacing.spacing01,
            }}
          >
            <FlexContainer justifyContent="space-between">
              <FlexContainer columnGap={theme.spacing.spacing01}>
                <div
                  css={{
                    width: "0.75rem",
                    height: "0.75rem",
                    backgroundColor: orgUnit.color ?? "#c0c0c0",
                    borderRadius: theme.radius.sm,
                  }}
                />
                <Link
                  to={generatePath(ROUTES.businessContexts.details, {
                    structureId: orgUnit.parentId,
                    unitId: orgUnit.id,
                  })}
                >
                  <Text size="md" mb={0}>
                    {orgUnit.name}
                  </Text>
                </Link>
              </FlexContainer>
              <ExtendedTooltip caption="Detach Environment">
                <Button
                  type="button"
                  icon="brokenLink"
                  size="sm"
                  severity="low"
                  onClick={() => onDetach(orgUnit)}
                />
              </ExtendedTooltip>
            </FlexContainer>
          </li>
        ))}
      </ul>
      <Spacer height={theme.spacing.spacing04} />
      <Button type="button" severity="low">
        <FlexContainer>
          <SVGIcon name="link" size="sm" />
          <Text size="sm" mb="0">
            Attach Environment to the OU
          </Text>
        </FlexContainer>
      </Button>
    </div>
  );
}
