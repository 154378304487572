/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  GcpBillingDataDocument,
  GcpBillingDataQuery,
  GcpBillingDataQueryVariables,
  useRemoveGcpBillingConfigV2Mutation,
} from "~/generated/graphql";
import { isNotNil } from "~/tools";

export function useDeleteGcpBillingData() {
  const [deleteBillingDataItem, { client, ...mutationState }] =
    useRemoveGcpBillingConfigV2Mutation();

  const removeGcpBillingData = async (data: { onboardedOrgId: string }) => {
    const { data: removeResponse } = await deleteBillingDataItem({
      variables: { onboardedOrgId: data.onboardedOrgId },
    });
    const deletedId = removeResponse?.removeGcpBillingConfigV2.id;

    if (isNotNil(deletedId)) {
      client.cache.updateQuery<
        GcpBillingDataQuery,
        GcpBillingDataQueryVariables
      >(
        {
          query: GcpBillingDataDocument,
        },
        (billingData) => ({
          gcpBillingData: (billingData?.gcpBillingData ?? []).filter(
            (item) => item?.onboardedOrgId !== deletedId
          ),
        })
      );
    }
  };

  return [removeGcpBillingData, mutationState] as const;
}
