/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { isNotNil } from "~/tools";
import { Provider } from "../../types";
import { AwsBillingDataUpdateForm } from "../aws/AwsBillingDataUpdateForm";
import { FormData as AwsFormData } from "../aws/validators";
import { AzureBillingDataUpdateForm } from "../azure/AzureBillingDataUpdateForm";
import { UpdateFormData as AzureUpdateFormData } from "../azure/validators";
import { GcpBillingDataUpdateForm } from "../gcp/GcpBillingDataUpdateForm";
import { UpdateFormData as GcpUpdateFormData } from "../gcp/types";

export function UpdateForm({
  updateFormValues,
  onClose,
}: {
  onClose: () => void;
  updateFormValues: unknown;
}) {
  if (isValidAwsFormValues(updateFormValues)) {
    return <AwsBillingDataUpdateForm onClose={onClose} {...updateFormValues} />;
  }

  if (isValidAzureFormValues(updateFormValues)) {
    return (
      <AzureBillingDataUpdateForm onClose={onClose} {...updateFormValues} />
    );
  }

  if (isValidGcpFormValues(updateFormValues)) {
    return <GcpBillingDataUpdateForm onClose={onClose} {...updateFormValues} />;
  }

  return null;
}

function isValidAwsFormValues(
  values: unknown
): values is UpdateFormValues[Provider.Aws] {
  if (typeof values === "object" && isNotNil(values)) {
    return [
      "accountId",
      "bucketId",
      "reportName",
      "reportPathPrefix",
      "region",
    ].every((name) => name in values);
  }

  return false;
}

function isValidAzureFormValues(
  values: unknown
): values is UpdateFormValues[Provider.Azure] {
  if (typeof values === "object" && isNotNil(values)) {
    return ["enrollmentNumber", "enrollmentAccessKey"].every(
      (name) => name in values
    );
  }

  return false;
}

function isValidGcpFormValues(
  values: unknown
): values is UpdateFormValues[Provider.Gcp] {
  if (typeof values === "object" && isNotNil(values)) {
    return [
      "bigQueryTable",
      "bigQueryDataset",
      "projectId",
      "onboardedOrgId",
      "organizationId",
      "useDetailedBillingData",
    ].every((name) => name in values);
  }

  return false;
}

export type UpdateFormValues = {
  [Provider.Aws]: AwsFormData;
  [Provider.Azure]: AzureUpdateFormData;
  [Provider.Gcp]: GcpUpdateFormData;
};
