/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import { If, Then, Else } from "react-if";
import { useToggle } from "react-use";
import { Button } from "@nordcloud/gnui";
import { UniversalWrap } from "~/components";
import { SUCCESS_TEXT, ERROR_TEXT } from "~/constants";
import { showError, showSimpleInfo } from "~/services/toast";
import { isNotEmpty } from "~/tools";
import { Provider } from "~/views/cloudAccounts/types";
import { BillingDataList } from "../components/BillingDataList";
import { BillingDataListItem } from "../components/BillingDataListItem";
import { AddBillingData } from "./AddBillingDataForm";
import { useDeleteGcpBillingData } from "./hooks/useDeleteAzureBillingData";
import { useGcpBillingData } from "./hooks/useGcpBillingData";

export function GcpBillingDataContent() {
  const [billingData, { loading, error }] = useGcpBillingData();
  const [removeGcpBillingData] = useDeleteGcpBillingData();

  const [isAddBillingDataFormOpen, toggleIsAddBillingDataForm] =
    useToggle(false);

  const isBillingData = isNotEmpty(billingData);

  const handleDelete = async (interactionId: string) => {
    try {
      await removeGcpBillingData({ onboardedOrgId: interactionId });
      showSimpleInfo(SUCCESS_TEXT.billingDataRemoved);
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  useEffect(() => {
    if (!isBillingData) {
      toggleIsAddBillingDataForm();
    }
  }, [isBillingData, toggleIsAddBillingDataForm]);

  return (
    <UniversalWrap loaderProps={{ loading }} errorProps={{ error }}>
      <BillingDataList title="List of Organizations and Projects">
        {billingData.map(
          ({
            isActive,
            organizationId,
            onboardedOrgId,
            projectId,
            bigQueryTableName,
            bigQueryDatasetName,
            useDetailedBillingData,
          }) => (
            <BillingDataListItem<Provider.Gcp, string>
              key={onboardedOrgId}
              isActive={Boolean(isActive)}
              interactionId={onboardedOrgId}
              label={organizationId}
              title={projectId}
              updateFormValues={{
                organizationId,
                serviceAccountKey: "",
                projectId,
                onboardedOrgId,
                useDetailedBillingData,
                bigQueryDataset: bigQueryDatasetName,
                bigQueryTable: bigQueryTableName,
              }}
              onDelete={handleDelete}
            />
          )
        )}
      </BillingDataList>
      <If condition={isAddBillingDataFormOpen}>
        <Then>
          <AddBillingData onClose={toggleIsAddBillingDataForm} />
        </Then>
        <Else>
          <Button icon="plus" onClick={toggleIsAddBillingDataForm}>
            Add Billing Data
          </Button>
        </Else>
      </If>
    </UniversalWrap>
  );
}
