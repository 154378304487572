/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  Button,
  ExtendedPaginationBox,
  FlexContainer,
  Spacer,
  theme,
} from "@nordcloud/gnui";
import { noop } from "~/tools";
import { SubnetsSubmitContainer } from "./styles";

type Props = {
  count: number;
  pageSize: number;
  page: number;
  isDisabled: boolean;
  setPage: (page: number) => void;
  onSubmitHandle: () => void;
};

export function SidebarFooter({
  count,
  pageSize,
  page,
  isDisabled,
  setPage,
  onSubmitHandle,
}: Props) {
  return (
    <FlexContainer direction="column" css={{ width: "100%" }}>
      <div css={{ width: "100%" }}>
        <ExtendedPaginationBox
          small
          count={count ?? 0}
          from={page * pageSize}
          setPage={(newPage) => {
            setPage(newPage / pageSize);
          }}
          setSize={noop}
          size={pageSize}
        />
      </div>
      <Spacer height={theme.spacing.spacing02} />
      <SubnetsSubmitContainer>
        <Button onClick={onSubmitHandle} disabled={isDisabled}>
          Apply
        </Button>
      </SubnetsSubmitContainer>
    </FlexContainer>
  );
}
