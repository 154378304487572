/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";

export function useHandleThresholdDataFilterTabs() {
  const [selectedTab, setSelectedTab] =
    useState<ThresholdDataFilterTabs>("providers");

  const onTabSelect = (tabName: ThresholdDataFilterTabs) => {
    setSelectedTab(tabName);
  };

  const tabs: {
    name: ThresholdDataFilterTabs;
    label: string;
    isActive: boolean;
  }[] = [
    {
      name: "providers",
      label: "Cloud Providers",
      isActive: selectedTab === "providers",
    },
    {
      name: "accounts",
      label: "Cloud Accounts",
      isActive: selectedTab === "accounts",
    },
    {
      name: "services",
      label: "Cloud Services",
      isActive: selectedTab === "services",
    },
  ];

  return { tabs, selectedTab, onTabSelect };
}

export type ThresholdDataFilterTabs = "accounts" | "providers" | "services";
