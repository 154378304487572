/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Tab } from "@nordcloud/gnui";
import { useEstateDetails } from "../../../hooks";
import { ExternalIntegrationTabContents } from "./ExternalIntegrationTabContents";

export function externalIntegrationTabs(
  enrichments: ReturnType<typeof useEstateDetails>["data"]["enrichments"]
) {
  return (enrichments ?? []).map(
    ({
      externalIntegrationName,
      externalIntegrationId,
      data: enrichmentData,
    }) => (
      <Tab
        key={externalIntegrationId}
        heading={externalIntegrationName}
        caption="Data from external integration"
      >
        <ExternalIntegrationTabContents
          enrichments={enrichmentData}
          externalIntegrationId={externalIntegrationId}
          externalIntegrationName={externalIntegrationName}
        />
      </Tab>
    )
  );
}
