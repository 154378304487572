/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useMemo } from "react";
import type { RumPublicApi } from "@datadog/browser-rum-core";
import { isNotLocalServer } from "~/config";
import { logger } from "./logger";
import { getGlobalObject } from "./utils/getGlobalObject";

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    DD_RUM?: RumPublicApi;
  }
}

export function useDatadogRUM(): RumPublicApi | undefined {
  useEffect(() => {
    if (isNotLocalServer && getGlobalObject<Window>().DD_RUM == null) {
      logger?.error("Datadog RUM not available");
    }
  }, []);

  return useMemo(() => getGlobalObject<Window>().DD_RUM, []);
}
