/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { SUCCESS_TEXT } from "~/constants";
import { useRemoveApplication as useRemoveApplicationMutation } from "~/hooks/graphql";
import { showError, showSuccess } from "~/services/toast";
import { handleError, isNotNil, toInputError } from "~/tools";
import { getApplicationStatus } from "../../components/ApplicationItem/ApplicationStatus";

type Props = {
  id: string;
  hasEnvs: boolean;
  setIsModalConfirmOpen: (v: boolean) => void;
  name: string;
  onSuccess?: () => void;
};

export function useRemoveApplication({
  id,
  hasEnvs,
  name,
  setIsModalConfirmOpen,
  onSuccess,
}: Props) {
  const [remove] = useRemoveApplicationMutation();

  const removeApplication = async () => {
    if (hasEnvs) {
      showError(
        "This application has environments attached and cannot be deleted, they have to be removed before removing the Application."
      );
      setIsModalConfirmOpen(false);
      return null;
    }
    try {
      await remove({ nid: id });
      showSuccess(SUCCESS_TEXT.applicationRemoved);

      onSuccess?.();
    } catch (err) {
      handleError(toInputError(err));
    }
  };

  return {
    removeApplication,
    isDefaultApplication: name === "default",
  };
}

type BudgetStateProps = {
  budget: number | null;
  forecast: number;
  cost: number;
};

export function useGetBudgetState({
  budget,
  forecast,
  cost,
}: BudgetStateProps) {
  const baseline = forecast <= 0 ? cost : forecast;

  const progress =
    isNotNil(budget) && budget > 0 ? (100 * baseline) / budget : 0;

  return {
    progress,
    status: getApplicationStatus(progress),
    isForecast: forecast >= 0,
  };
}
