/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { yupResolver } from "@hookform/resolvers/yup";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FormProvider, UnpackNestedValue, useForm } from "react-hook-form";
import { Row, Col, Tabs, Tab } from "@nordcloud/gnui";
import {
  BreadcrumbsBox,
  ErrorWrap,
  GlobalSpinner,
  UserErrorMessageHTML,
} from "~/components";
import { getDefaultValues, WorkflowFormData } from "../types";
import { Step } from "./constants";
import { useAddWorkflow } from "./hooks/useAddWorkflow";
import { getWorkflowFormSchema } from "./validators";
import { WorkflowDetailsForm } from "./WorkflowDetailsForm";
import { WorkflowSettingsForm } from "./WorkflowSettingsForm";
import { WorkflowSummary } from "./WorkflowSummary";

const BREADCRUMBS_LABELS = [
  {
    value: "workflows",
    label: "Workflows",
  },
  {
    value: "add-workflow",
    label: "Add New Workflow",
  },
];

export function AddWorkflowPage() {
  const formMethods = useForm<UnpackNestedValue<WorkflowFormData>>({
    mode: "onChange",
    defaultValues: getDefaultValues(),
    resolver: yupResolver(
      getWorkflowFormSchema({
        requireServiceNowPassword: true,
        requireSlackToken: true,
        validateSchedulerEndDate: true,
      })
    ),
  });
  const { handleSubmit, reset } = formMethods;
  const {
    addWorkflowResult,
    currentStep,
    setCurrentStep,
    error,
    handleAddWorkflow,
    loading,
    userError,
    setUserError,
  } = useAddWorkflow();

  const handleAddAnotherClick = () => {
    reset(getDefaultValues());
    setCurrentStep(Step.DETAILS);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <ErrorWrap error={error}>
        <GlobalSpinner loading={loading} />
        <UserErrorMessageHTML
          html={userError}
          onClick={() => setUserError(undefined)}
        />
        <Row>
          <Col>
            <BreadcrumbsBox
              title="Add New Workflow"
              labels={BREADCRUMBS_LABELS}
            />
            <FormProvider {...formMethods}>
              <form
                id="addWorkflowForm"
                onSubmit={handleSubmit(handleAddWorkflow)}
              >
                <Tabs wizard step={currentStep} handleTab={setCurrentStep}>
                  <Tab
                    disabled
                    heading="Workflow Details"
                    caption="Give some general information about your workflow"
                  >
                    <WorkflowDetailsForm
                      onNextButtonClick={() => setCurrentStep(Step.SETTINGS)}
                    />
                  </Tab>
                  <Tab
                    disabled
                    heading="Workflow Settings"
                    caption="Start building your workflow"
                  >
                    <WorkflowSettingsForm
                      onPrevButtonClick={() => setCurrentStep(Step.DETAILS)}
                    />
                  </Tab>
                  <Tab
                    disabled
                    heading="Summary"
                    caption="Great, your workflow is ready"
                  >
                    <WorkflowSummary
                      id={addWorkflowResult?.addWorkflowV2?.workflow?.id ?? ""}
                      onAddAnotherClick={handleAddAnotherClick}
                    />
                  </Tab>
                </Tabs>
              </form>
            </FormProvider>
          </Col>
        </Row>
      </ErrorWrap>
    </DndProvider>
  );
}
