/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { theme } from "@nordcloud/gnui";
import {
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsProvider,
} from "~/generated/graphql";
import { percentageFormat } from "~/components/Charts/utils";
import { percentage } from "~/tools";
import { GraphTooltipData } from "../types";
import { formatCost } from "../utils";

type TooltipProps = {
  totalCost: number;
  reservedInstanceCost: number;
  savingsPlanCost: number;
  onDemandCost: number;
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  provider: ReservationsProvider;
};

export function useGetTooltipData({
  serviceUsageType,
  totalCost,
  reservedInstanceCost,
  savingsPlanCost,
  onDemandCost,
  provider,
}: TooltipProps): GraphTooltipData[] {
  const calcuateRelativeCost = (cost: number) =>
    percentage(cost, totalCost).toFixed(2);

  const riCoverage = calcuateRelativeCost(reservedInstanceCost);
  const spCoverage = calcuateRelativeCost(savingsPlanCost);
  const onDemandCostData = calcuateRelativeCost(onDemandCost);
  const coverage = calcuateRelativeCost(reservedInstanceCost + savingsPlanCost);

  const dailyCoverage = {
    key: "Daily Cost",
    percentageValue: 0,
    value: formatCost(totalCost),
    hasColor: false,
    hasBottomBorder: false,
  };

  const totalCoverage = {
    key: "RI & SP Avg. Coverage",
    value: formatCost(reservedInstanceCost + savingsPlanCost),
    percentageValue: percentageFormat(coverage),
    hasColor: false,
    hasBottomBorder: true,
  };

  const riCoverageTooltip = {
    key: "Covered By RI",
    value: formatCost(reservedInstanceCost),
    percentageValue: percentageFormat(riCoverage),
    hasColor: true,
    color: theme.color.support.cyan,
    hasBottomBorder: false,
  };

  const spCoverageTooltip = {
    key: "Covered By SP",
    value: formatCost(savingsPlanCost),
    percentageValue: percentageFormat(spCoverage),
    hasColor: true,
    color: theme.color.support.yellow,
    hasBottomBorder: false,
  };

  const onDemandTooltip = {
    key: "On-Demand Cost",
    value: formatCost(onDemandCost),
    percentageValue: percentageFormat(onDemandCostData),
    hasColor: true,
    color: theme.color.support.grey,
    hasBottomBorder: false,
  };
  const isAws = provider === ReservationsProvider.Aws;

  if (isAws) {
    switch (serviceUsageType) {
      case ReservationsAndCommitmentsCoverageServiceUsageType.Database:
        return [dailyCoverage, riCoverageTooltip, onDemandTooltip];
      case ReservationsAndCommitmentsCoverageServiceUsageType.MachineLearning:
        return [dailyCoverage, spCoverageTooltip, onDemandTooltip];
      default:
        return [
          dailyCoverage,
          totalCoverage,
          riCoverageTooltip,
          spCoverageTooltip,
          onDemandTooltip,
        ];
    }
  } else {
    return [dailyCoverage, riCoverageTooltip, onDemandTooltip];
  }
}
