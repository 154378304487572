/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { When } from "react-if";
import { useToggle } from "react-use";
import { theme } from "@nordcloud/gnui";
import {
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsProvider,
} from "~/generated/graphql";
import { DataContainer, DataItem } from "~/components";
import {
  ReservationExistingLink,
  ReservationExistingLinkCounter,
} from "../components";
import { ReservationTooltip } from "../constants";
import { useGetCurrentTab } from "../hooks";

type Props = {
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  averageCoverage: string;
  reservedInstaceCoverage: string;
  savingsPlanCoverage: string;
  savedCost: string;
  totalOnDemandCost: string;
};

export function AwsUsageAnalysisDetails({
  serviceUsageType,
  averageCoverage,
  reservedInstaceCoverage,
  savingsPlanCoverage,
  totalOnDemandCost,
  savedCost,
}: Props) {
  const [offeringType, setOfferingType] = useState(
    ReservationsAndCommitmentsCoverageOfferingType.ReservedInstances
  );
  const [isSidebarOpen, toggleIsSidebarOpen] = useToggle(false);
  const { isComputeTab, isMachineLearningTab, isRICoverage } =
    useGetCurrentTab(serviceUsageType);

  const openExistingLink = (
    type: ReservationsAndCommitmentsCoverageOfferingType
  ) => {
    setOfferingType(type);
    toggleIsSidebarOpen(true);
  };

  const padding = isComputeTab
    ? `0 ${theme.spacing.spacing03}`
    : `0 0 0 ${theme.spacing.spacing03}`;

  const hasSavingsPlan = isComputeTab || isMachineLearningTab;
  const costSavingsText = hasSavingsPlan ? "RIs and SPs" : "RIs";

  return (
    <>
      <DataContainer marginBottom={theme.spacing.spacing06}>
        <When condition={isComputeTab}>
          <DataItem
            size="md"
            label="RI & SP Avg. Coverage"
            value={averageCoverage}
            mb={theme.spacing.spacing01}
            padding={`0 0 0 ${theme.spacing.spacing03}`}
            itemWidth="20%"
            grow={false}
          />
        </When>
        <When condition={isComputeTab || isMachineLearningTab}>
          <ReservationExistingLinkCounter
            serviceUsageType={serviceUsageType}
            coverageLabel="SP Avg. Coverage"
            coverageValue={savingsPlanCoverage}
            padding={padding}
            provider={ReservationsProvider.Aws}
            offeringType={
              ReservationsAndCommitmentsCoverageOfferingType.SavingsPlans
            }
            onClick={() =>
              openExistingLink(
                ReservationsAndCommitmentsCoverageOfferingType.SavingsPlans
              )
            }
          />
        </When>
        <When condition={isRICoverage}>
          <ReservationExistingLinkCounter
            serviceUsageType={serviceUsageType}
            coverageLabel="RI Avg. Coverage"
            coverageValue={reservedInstaceCoverage}
            padding={padding}
            provider={ReservationsProvider.Aws}
            offeringType={
              ReservationsAndCommitmentsCoverageOfferingType.ReservedInstances
            }
            onClick={() =>
              openExistingLink(
                ReservationsAndCommitmentsCoverageOfferingType.ReservedInstances
              )
            }
          />
        </When>
        <DataItem
          size="md"
          label="On-Demand Cost"
          value={totalOnDemandCost}
          tooltipText={ReservationTooltip.COST_SAVINGS_RI}
          mb={theme.spacing.spacing01}
          itemWidth="20%"
          grow={false}
        />
        <DataItem
          size="md"
          label="Cost Savings"
          value={savedCost}
          tooltipText={`Cloud cost savings achieved by ${costSavingsText}.`}
          mb={theme.spacing.spacing01}
          itemWidth="20%"
          grow={false}
        />
      </DataContainer>
      <When condition={isSidebarOpen}>
        <ReservationExistingLink
          showLinks
          hasUtilization={false}
          toggleSidebar={toggleIsSidebarOpen}
          isSidebarOpen={isSidebarOpen}
          offeringType={offeringType}
          serviceUsageType={serviceUsageType}
          provider={ReservationsProvider.Aws}
        />
      </When>
    </>
  );
}
