/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { useQueryState } from "~/hooks";
import { isEmpty, isNotEmpty } from "~/tools";

type Props = {
  count: number;
};

export function useDriftsSelect({ count }: Props) {
  const {
    state: { page, limit },
  } = useQueryState();

  const rest = count % limit;
  const lastPage = Math.ceil(count / limit) - 1;
  const isLastPage = lastPage === page;

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  useEffect(() => {
    setSelectedIds([]);
  }, [page]);

  const isDriftIdSelected = (id: string) => selectedIds.includes(id);

  const onAllDriftIdsToggle = (ids: string[]) => {
    const itemsPerPage = isLastPage ? rest : limit;
    setSelectedIds(selectedIds.length === itemsPerPage ? [] : ids);
  };

  const onDriftIdToggle = (id: string) => {
    if (selectedIds.includes(id)) {
      setSelectedIds((prev) => prev.filter((item) => item !== id));
    } else {
      setSelectedIds((prev) => [...prev, id]);
    }
  };

  const hasAnySelected = isNotEmpty(selectedIds);
  const hasAllOnPageSelected = isLastPage
    ? selectedIds.length === rest
    : selectedIds.length === limit;
  const hasNoneSelected = isEmpty(selectedIds);
  const showBanner = hasAllOnPageSelected && count > limit;

  return {
    onDriftIdToggle,
    onAllDriftIdsToggle,
    isDriftIdSelected,
    hasAnySelected,
    hasAllOnPageSelected,
    hasNoneSelected,
    showBanner,
    selectedIds,
    page,
    limit: isLastPage ? rest : limit,
  };
}
