/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DateRange, EstatePeriod, Granularity } from "~/components";
import { dateFormat } from "~/constants";
import { useQueryState } from "~/hooks";
import { useBillingPeriod } from "~/services/customers";
import { isNotNil } from "~/tools";
import { EstateChartsQueryState } from "../types";

export function useEstateChartParameters() {
  const { state, updateQueryState } = useQueryState<EstateChartsQueryState>();

  const { isPreviousBillingPeriod } = useBillingPeriod();

  const [period, setPeriod] = useState<EstatePeriod>(
    state.estatePeriod ?? EstatePeriod.DEFAULT_MONTH
  );
  const [search, setSearch] = useState("");

  const [isDataLoading, setIsDataLoading] = useState(false);

  const [range, setRange] = useState<DateRange | undefined>(
    getRange(state, isPreviousBillingPeriod)
  );

  const granularity = state.granularity ?? Granularity.MONTHS;

  const handleGranularityChange = (granularitySelection: Granularity) => {
    updateQueryState({
      granularity: granularitySelection,
    });
  };

  const clearRange = () => {
    setRange(undefined);
  };

  const handlePeriodChange = (
    selectedPeriod: EstatePeriod,
    selectedRange?: DateRange
  ) => {
    setPeriod(selectedPeriod);
    if (selectedRange) {
      setRange(selectedRange);
    }

    updateQueryState({
      periodStart: getStartDate(
        selectedPeriod,
        isPreviousBillingPeriod,
        selectedRange
      ),
      periodEnd: getEndDate(
        selectedPeriod,
        isPreviousBillingPeriod,
        selectedRange
      ),
      estatePeriod: selectedPeriod,
    });
  };

  const handleRangeSelection = () => {
    if (areDatesInPeriod(isPreviousBillingPeriod, state)) {
      handlePeriodChange(
        EstatePeriod.RANGE,
        adjustRangeByPeriod(isPreviousBillingPeriod, state)
      );
    } else {
      handlePeriodChange(EstatePeriod.DEFAULT_MONTH);
      setRange(undefined);
    }
  };

  useEffect(() => {
    if (period === EstatePeriod.RANGE) {
      handleRangeSelection();
    } else {
      handlePeriodChange(period);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreviousBillingPeriod]);

  return {
    period,
    granularity,
    range,
    handleGranularityChange,
    handlePeriodChange,
    search,
    setSearch,
    clearRange,
    isDataLoading,
    setIsDataLoading,
  };
}

function getStartDate(
  period: EstatePeriod,
  isPreviousBillingPeriod: boolean,
  range?: DateRange
) {
  const monthStart = isPreviousBillingPeriod
    ? generatePreviousPeriodDate(true)
    : generateCurrentPeriodDate(true);

  if (period === EstatePeriod.RANGE) {
    return range?.from ?? monthStart;
  }

  return period === EstatePeriod.PREVIOUS_MONTH
    ? dayjs(monthStart)
        .subtract(1, "month")
        .startOf("month")
        .format(dateFormat.shortDate)
    : monthStart;
}

function getEndDate(
  period: EstatePeriod,
  isPreviousBillingPeriod: boolean,
  range?: DateRange
) {
  const monthEnd = isPreviousBillingPeriod
    ? generatePreviousPeriodDate(false)
    : generateCurrentPeriodDate(false);

  if (period === EstatePeriod.RANGE) {
    return range?.to ?? monthEnd;
  }

  return period === EstatePeriod.PREVIOUS_MONTH
    ? dayjs(monthEnd)
        .subtract(1, "month")
        .endOf("month")
        .format(dateFormat.shortDate)
    : monthEnd;
}

function generatePreviousPeriodDate(isStartDate: boolean) {
  if (isStartDate) {
    return dayjs()
      .subtract(1, "month")
      .startOf("month")
      .format(dateFormat.shortDate);
  }

  return dayjs()
    .subtract(1, "month")
    .endOf("month")
    .format(dateFormat.shortDate);
}

function generateCurrentPeriodDate(isStartDate: boolean) {
  if (isStartDate) {
    return dayjs().startOf("month").format(dateFormat.shortDate);
  }
  return dayjs().endOf("month").format(dateFormat.shortDate);
}

function isDateValid(endDate: string, date: string | undefined) {
  return (
    isNotNil(date) && dayjs(date).isBefore(dayjs(endDate).add(1, "day"), "date")
  );
}

function areDatesInPeriod(
  isPreviousBillingPeriod: boolean,
  state: EstateChartsQueryState
) {
  const { periodStart, periodEnd } = state;
  const maxEndDate = getEndDate(EstatePeriod.RANGE, isPreviousBillingPeriod);

  return (
    isDateValid(maxEndDate, periodStart) && isDateValid(maxEndDate, periodEnd)
  );
}

function getRange(
  state: EstateChartsQueryState,
  isPreviousBillingPeriod: boolean
) {
  const { estatePeriod, periodStart, periodEnd } = state;

  const isRangeDefined =
    isNotNil(estatePeriod) &&
    estatePeriod === EstatePeriod.RANGE &&
    isNotNil(periodStart) &&
    isNotNil(periodEnd);

  const isRangeValid =
    isRangeDefined && areDatesInPeriod(isPreviousBillingPeriod, state);

  if (isRangeValid) {
    return {
      from: periodStart,
      to: periodEnd,
    };
  }

  return undefined;
}

function adjustRangeByPeriod(
  isPreviousBillingPeriod: boolean,
  state: EstateChartsQueryState
) {
  const { periodStart, periodEnd } = state;
  const maxEndDate = getEndDate(EstatePeriod.RANGE, isPreviousBillingPeriod);

  const isDefinedRangeValid =
    isNotNil(periodStart) &&
    isNotNil(periodEnd) &&
    areDatesInPeriod(isPreviousBillingPeriod, state);

  if (isDefinedRangeValid) {
    return {
      from: periodStart,
      to: periodEnd,
    };
  }

  const defaultStartDate = getStartDate(
    EstatePeriod.RANGE,
    isPreviousBillingPeriod
  );

  return {
    from: defaultStartDate,
    to: maxEndDate,
  };
}
