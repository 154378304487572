/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { theme, THEME_OPTIONS } from "@nordcloud/gnui";
import { ROUTES } from "~/routing/routes";
import { OptimisationLevel } from "./types";

export function getIconColor(icon: "danger" | "info" | "warning") {
  switch (icon) {
    case "warning":
      return theme.color.text.warning;
    case "danger":
      return theme.color.support.red;
    case "info":
      return theme.color.support.green;
    default:
      return theme.color.support.red;
  }
}

export function getStatus(level: OptimisationLevel) {
  switch (level) {
    case OptimisationLevel.FULLY_OPTIMISED:
      return "success";
    case OptimisationLevel.MODERATE_OPTIMISATION:
      return "success";
    case OptimisationLevel.PARTIALLY_OPTIMISED:
      return "warning";
    case OptimisationLevel.NEEDS_OPTIMISATION:
      return "danger";
    default:
      return "danger";
  }
}

export function getLinkToResourcesWithWaste(applicationId: string) {
  return `${ROUTES.estateRecords.index}?application=${applicationId}&waste=true`;
}

export function getIcon(level: OptimisationLevel, currentTheme: THEME_OPTIONS) {
  switch (level) {
    case OptimisationLevel.FULLY_OPTIMISED:
      return currentTheme === THEME_OPTIONS.LIGHT
        ? "lmFullyOptimized"
        : "dmFullyOptimized";
    case OptimisationLevel.MODERATE_OPTIMISATION:
      return currentTheme === THEME_OPTIONS.LIGHT
        ? "lmModerateOptimization"
        : "dmModerateOptimization";
    case OptimisationLevel.PARTIALLY_OPTIMISED:
      return currentTheme === THEME_OPTIONS.LIGHT
        ? "lmPartiallyOptimized"
        : "dmPartiallyOptimized";
    default:
      return currentTheme === THEME_OPTIONS.LIGHT
        ? "lmNeedsOptimization"
        : "dmNeedsOptimization";
  }
}

export function getMessageStyle(level: OptimisationLevel) {
  switch (level) {
    case OptimisationLevel.NEEDS_OPTIMISATION:
      return {
        color: theme.color.support.red,
        borderColor: theme.color.support.red,
      };
    case OptimisationLevel.PARTIALLY_OPTIMISED:
      return {
        color: theme.color.text.warning,
        borderColor: theme.color.text.warning,
      };
    default:
      return {
        color: theme.color.support.green,
        borderColor: theme.color.support.green,
      };
  }
}

export function getTitle(level: OptimisationLevel) {
  switch (level) {
    case OptimisationLevel.FULLY_OPTIMISED:
      return "Fully Optimised";
    case OptimisationLevel.MODERATE_OPTIMISATION:
      return "Moderately Optimised";
    case OptimisationLevel.PARTIALLY_OPTIMISED:
      return "Partially Optimised";
    case OptimisationLevel.NEEDS_OPTIMISATION:
      return "Needs to be Optimised";
    default:
      return "Needs to be Optimised";
  }
}
