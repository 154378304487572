/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Provider, ProviderTab } from "../types";

export const providerFilterIconProps = [
  {
    label: "AWS",
    provider: Provider.Aws as const,
    icon: "aws" as const,
  },
  {
    label: "Azure",
    provider: Provider.Azure as const,
    icon: "azure" as const,
  },
  {
    label: "Google Cloud",
    provider: Provider.Gcp as const,
    icon: "gcp" as const,
  },
];

export const staticChargeTypeOptions = [
  {
    value: "Total Cost",
    label: "Total Cost",
  },
];

export const tabDefinitions = [
  {
    name: ProviderTab.AWS,
    count: null,
    cost: null,
    hasWarning: false,
  },
  {
    name: ProviderTab.AZURE,
    count: null,
    cost: null,
    hasWarning: false,
  },
  {
    name: ProviderTab.GCP,
    count: null,
    cost: null,
    hasWarning: false,
  },
  {
    name: ProviderTab.IBMCLOUD,
    count: null,
    cost: null,
    hasWarning: false,
  },
  {
    name: ProviderTab.KUBERNETES,
    count: null,
    cost: null,
    hasWarning: false,
  },
  {
    name: ProviderTab.OPENSHIFT,
    count: null,
    cost: null,
    hasWarning: false,
  },
  {
    name: ProviderTab.VMWARE,
    count: null,
    // VMWare does not have costs
    cost: "N/A",
    hasWarning: false,
  },
];
