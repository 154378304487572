/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import {
  SVGIcon,
  Text,
  Tooltip,
  Table,
  theme,
  FlexContainer,
} from "@nordcloud/gnui";
import { childColumnProps } from "../../types";

type Props = {
  isReservedInstances: boolean;
  childHeaders?: childColumnProps[];
};

export function CoverageTableChildHeader({
  isReservedInstances,
  childHeaders,
}: Props) {
  return (
    <Table>
      <Table.tr>
        {childHeaders?.map((childElement: childColumnProps, index: number) => {
          const isLastChild = index === childHeaders.length - 1 ? "34%" : "33%";
          return (
            <Table.td
              key={childElement.accessor}
              width={isReservedInstances ? isLastChild : "50%"}
              style={{
                borderTop: "none",
                minWidth: "7rem",
                position: "relative",
              }}
            >
              <FlexContainer alignContent="end">
                <Text
                  align={childElement.alignment}
                  size="sm"
                  color={theme.color.text.text02}
                  mb={0}
                  tag="div"
                  style={{ flex: "1" }}
                >
                  {childElement.headerName}
                </Text>
                <When condition={childElement.tooltipText}>
                  <Tooltip caption={childElement.tooltipText} minWidth="10rem">
                    <SVGIcon
                      name="info"
                      size="sm"
                      color="black"
                      css={{
                        marginLeft: theme.spacing.spacing01,
                      }}
                    />
                  </Tooltip>
                </When>
              </FlexContainer>
            </Table.td>
          );
        })}
      </Table.tr>
    </Table>
  );
}
