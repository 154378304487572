/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import jwt_decode from "jwt-decode";

type AzureJwtToken = {
  accountId?: string;
  departmentId?: string;
  enrollmentNumber?: string;
  id?: string;
  partnerId?: string;
  reportView?: string;
  aud: string;
  exp: number;
  iss: string;
  nbf?: number;
};

export function validateAzureJwt(token: string) {
  const isJwtTokenQuery =
    "^([a-zA-Z0-9_=]+)\\.([a-zA-Z0-9_=]+)\\.([a-zA-Z0-9_\\-+\\/=]*)";

  if (!new RegExp(isJwtTokenQuery).test(token)) {
    return "This token invalid.";
  }

  const decoded: AzureJwtToken = jwt_decode(token);
  const { iss, aud, exp } = decoded;

  if (iss !== "ea.microsoftazure.com") {
    return "This token invalid, wrong issuer field.";
  }

  if (aud !== "client.ea.microsoftazure.com") {
    return "This token invalid, wrong audience field.";
  }

  if (dayjs.unix(exp).diff(dayjs()) < 0) {
    return "This token is expired.";
  }

  if (dayjs.unix(exp).subtract(48, "hours").diff(dayjs()) < 0) {
    return "This token will expire in less than 48 hours.";
  }

  return true;
}
