/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { CSSProp } from "styled-components";
import { BrickLoader } from "./BrickLoader";

type WrapperProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

type InputLoaderProps = {
  loading: boolean;
  children: React.ReactNode;
  customCss?: CSSProp;
};

export function InputLoader({
  loading,
  children,
  customCss = {},
  ...props
}: InputLoaderProps & WrapperProps) {
  if (loading) {
    return (
      <div css={[{ height: "40px", width: "100%" }, customCss]} {...props}>
        <BrickLoader />
      </div>
    );
  }

  return <>{children}</>;
}
