/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { getExchangeRate } from "~/tools";
import { useCurrency } from "./useCurrency";

export function useConvertFromBaseCurrency() {
  const [rate, setRate] = useState(1);
  const { currency, baseCurrency } = useCurrency();

  useEffect(() => {
    getExchangeRate(currency, baseCurrency, baseCurrency).then((value) =>
      setRate(value)
    );
  }, [currency, baseCurrency]);

  const convert = (amount: string) => {
    return (parseFloat(amount) * rate).toString();
  };

  return { convert, rate };
}
