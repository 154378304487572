import { useMemo } from "react";
import {
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsLookbackPeriod,
  ReservationsAndCommitmentsFilters,
  ReservationsRecommendationPaymentOption,
  ReservationsRecommendationTerm,
  useReservationsRecommendationsSummaryLazyQuery,
  ReservationsRecommendationServiceType,
  ReservationsProvider,
} from "~/generated/graphql";
import { isNotNil } from "~/tools";

type RecommendationsSummaryProps = {
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  type: ReservationsAndCommitmentsCoverageOfferingType;
  timePeriod: ReservationsAndCommitmentsLookbackPeriod;
  filters?: ReservationsAndCommitmentsFilters;
  paymentOption: ReservationsRecommendationPaymentOption;
  term: ReservationsRecommendationTerm;
  provider: ReservationsProvider;
};

export function useGetReservationsDetails({
  serviceUsageType,
  term,
  paymentOption,
  type,
  timePeriod,
  filters,
  provider,
}: RecommendationsSummaryProps) {
  const [getSummary, { data: summaryData, loading: isSummaryLoading }] =
    useReservationsRecommendationsSummaryLazyQuery({
      variables: {
        paymentOption,
        term,
        serviceType: ReservationsRecommendationServiceType.ShowAll,
        offeringType: type,
        serviceUsageType,
        lookbackPeriod: timePeriod,
        filters,
        provider,
      },
      fetchPolicy: "cache-and-network",
    });

  const summary = summaryData?.reservationsRecommendationsSummary;
  const potentialMonthlySaving = summary?.monthlySavings ?? "0";
  const potentialYearlySaving = summary?.yearlySavings ?? "0";
  const upfrontCost = summary?.upfrontCost ?? "0";
  const recurringMonthlyCost = summary?.recurringMonthlyCost ?? "0";
  const savingsRate = summary?.savingsRate ?? "0";

  const savingsPlansCommitments = useMemo(() => {
    const comments = summary?.savingsPlansCommitments?.filter(isNotNil) ?? [];
    return comments.map((commitment) => ({
      savingsPlansHourlyCommitmentToPurchase:
        commitment.savingsPlansHourlyCommitmentToPurchase ?? "0",
      onDemandCostPerHour: commitment.onDemandCostPerHour ?? "0",
      savingsPerHour: commitment.savingsPerHour ?? "0",
      account: commitment.account ?? "",
    }));
  }, [summary?.savingsPlansCommitments]);

  return {
    getSummary,
    isSummaryLoading,
    potentialMonthlySaving,
    potentialYearlySaving,
    upfrontCost,
    recurringMonthlyCost,
    savingsRate,
    savingsPlansCommitments,
  };
}
