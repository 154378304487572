/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Table } from "@nordcloud/gnui";
import { NoData } from "~/components";
import { isEmpty } from "~/tools";
import { Metadata } from "../DefaultEc2Components";

type Props = {
  metatags: Metadata[];
};

export function Ec2ResourceTags({ metatags }: Props) {
  if (isEmpty(metatags)) {
    return <NoData message="No resource tags found" />;
  }

  return (
    <Table css={{ tableLayout: "fixed" }}>
      <Table.thead>
        <Table.tr>
          <Table.th css={{ width: "35%" }}>Key</Table.th>
          <Table.th>Value</Table.th>
        </Table.tr>
      </Table.thead>
      <Table.tbody>
        {metatags.map((data) => (
          <Table.tr key={`${data.label}-${data.value}`}>
            <Table.td>{data.label}</Table.td>
            <Table.td>
              <>{data.value}</>
            </Table.td>
          </Table.tr>
        ))}
      </Table.tbody>
    </Table>
  );
}
