/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled, { css } from "styled-components";
import { theme } from "@nordcloud/gnui";

type Props = {
  hide?: boolean;
  marginBottom?: string;
};

export const Separator = styled.div<Props>`
  border-top: solid 1px ${theme.color.border.border01};
  grid-area: separator;
  ${handleStylesFromProps};
`;

function handleStylesFromProps(props: Props) {
  const { hide, marginBottom } = props;

  return css({
    visibility: hide ? "hidden" : "visible",
    marginBottom: marginBottom ? marginBottom : undefined,
  });
}
