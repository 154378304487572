/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import {
  OrgUnitAccumulatedCostQuery,
  TimePoint,
  useOrgUnitAccumulatedCostQuery,
} from "~/generated/graphql";
import {
  getTableGranularity,
  mapGranularity,
} from "~/components/Charts/CostAnalysis";
import {
  CostAnalysisFields,
  Granularity,
  Period,
  TableData,
} from "~/components/Charts/CostAnalysis/types";
import { isNotNil } from "~/tools";

type Props = {
  orgUnitId: string;
  startDate: string;
  endDate: string;
  granularity: Granularity;
  period: Period;
};

export function useOrgUnitAccumulatedCostChart({
  orgUnitId,
  startDate,
  endDate,
  granularity,
  period,
}: Props) {
  const { data, loading, error } = useOrgUnitAccumulatedCostQuery({
    variables: {
      orgUnitId,
      startDate,
      endDate,
      granularity: mapGranularity(granularity),
      tableGranularity: getTableGranularity(granularity, period),
    },
  });

  const orgUnitAccumulatedCostChartData = useMemo(
    () => generateAccumulatedCostGroups(data),
    [data]
  );

  const orgUnitAccumulatedCostBudgetData = useMemo(
    () =>
      (data?.orgUnitAccumulatedCost?.timePoints ?? [])
        .filter(isNotNil)
        .map((item) => mapTimePointValue(item)),
    [data]
  );

  const orgUnitAccumulatedCostTimePoints = useMemo(
    () =>
      (data?.orgUnitAccumulatedCostTable?.timePoints ?? [])
        .filter(isNotNil)
        .map((item) => mapTimePointValue(item)),
    [data]
  );

  const orgUnitAccumulatedCostTotal = Number(
    data?.orgUnitAccumulatedCostTable?.total ?? 0
  );

  const orgUnitAccumulatedCostTableData: TableData[] = useMemo(
    () => [
      {
        field: "Cost",
        total: orgUnitAccumulatedCostTotal,
        ...orgUnitAccumulatedCostTimePoints?.reduce((acc, { date, value }) => {
          return { ...acc, [date]: Number(value ?? 0) };
        }, {}),
      },
    ],
    [orgUnitAccumulatedCostTotal, orgUnitAccumulatedCostTimePoints]
  );

  return {
    orgUnitAccumulatedCostChartData,
    orgUnitAccumulatedCostTableData,
    orgUnitAccumulatedCostTotal,
    orgUnitAccumulatedCostTimePoints,
    orgUnitAccumulatedCostBudgetData,
    loading,
    error,
  };
}

function mapTimePointValue(timePoint: TimePoint) {
  return {
    ...timePoint,
    value: Number(timePoint.value),
  };
}

function generateAccumulatedCostGroups(
  data: OrgUnitAccumulatedCostQuery | undefined
) {
  return (data?.orgUnitAccumulatedCost.timePoints ?? [])
    .filter(isNotNil)
    .map((timepoint) => {
      return {
        ...timepoint,
        groups: [
          {
            id: "accumulatedDailyCost",
            value: timepoint.value,
            name: CostAnalysisFields.DAILY_COST,
          },
        ],
      };
    });
}
