/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { Link } from "react-router-dom";
import {
  FlexContainer,
  Message,
  Spacer,
  SVGIcon,
  Table,
  theme,
} from "@nordcloud/gnui";
import { CollapsibleBox } from "~/components";
import { dateFormat } from "~/constants";
import { isEmpty } from "~/tools";
import { getUrl } from "../../../helpers";
import { Item } from "../../components";
import { Status } from "../components";

type Props = {
  rootDeviceType: string;
  rootDeviceName: string;
  deviceMappings: DeviceMapping[];
};

export function Ec2Storage({
  rootDeviceName,
  rootDeviceType,
  deviceMappings,
}: Props) {
  return (
    <>
      <FlexContainer columnGap={theme.spacing.spacing08}>
        <Item label="Root Device Name" value={rootDeviceName} />
        <Item label="Root Device Type" value={rootDeviceType} />
      </FlexContainer>
      <Spacer height={theme.spacing.spacing06} />
      <CollapsibleBox
        title="Block Devices"
        boxProps={{ innerSpacing: "spacing01" }}
      >
        <BlockDeviceMappings deviceMappings={deviceMappings} />
      </CollapsibleBox>
    </>
  );
}

type BlockDeviceMappingsProps = {
  deviceMappings: DeviceMapping[];
};

function BlockDeviceMappings({ deviceMappings }: BlockDeviceMappingsProps) {
  if (isEmpty(deviceMappings)) {
    return (
      <Message status="notification" image="info">
        No Block Devices found.
      </Message>
    );
  }

  return (
    <Table>
      <Table.thead>
        <Table.tr>
          <Table.th>Volume ID</Table.th>
          <Table.th>Device Name</Table.th>
          <Table.th>Volume Size (GiB)</Table.th>
          <Table.th>Attachment Status</Table.th>
          <Table.th>Attachment Time</Table.th>
          <Table.th>Delete On Termination</Table.th>
        </Table.tr>
      </Table.thead>
      <Table.tbody>
        {deviceMappings.map((deviceMapping) => (
          <Table.tr key={deviceMapping.volumeId}>
            <Table.td>
              <Link
                to={getUrl(
                  deviceMapping.volumeId,
                  deviceMapping.volumeNid,
                  "ec2/volume"
                )}
              >
                {deviceMapping.volumeId}
              </Link>
            </Table.td>
            <Table.td>{deviceMapping.deviceName}</Table.td>
            <Table.td>{deviceMapping.volumeSize ?? "-"}</Table.td>
            <Table.td>
              <Status value={deviceMapping.status} successOn="attached" />
            </Table.td>
            <Table.td>
              {dayjs(deviceMapping.attachTime).format(dateFormat.awsDateTime)}
            </Table.td>
            <Table.td>
              <SVGIcon
                name={deviceMapping.deleteOnTermination ? "success" : "cancel"}
                color={deviceMapping.deleteOnTermination ? "success" : "danger"}
              />
            </Table.td>
          </Table.tr>
        ))}
      </Table.tbody>
    </Table>
  );
}

type DeviceMapping = {
  volumeSize: number | null;
  status: string;
  volumeId: string;
  volumeNid: string;
  attachTime: string;
  deleteOnTermination: boolean;
  deviceName: string;
};
