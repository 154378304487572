/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import dayjs from "dayjs";
import {
  formatCustomDate,
  isNotNil,
  isString,
  MixedValue,
  ResourceLifecycleDates,
} from "~/tools";

type Props = {
  lastUpdateDate: string | undefined;
  resourceLifecycleDates: ResourceLifecycleDates | undefined;
  format?: string;
};

export function useResourceLifeSpanDates({
  lastUpdateDate,
  resourceLifecycleDates,
  format,
}: Props) {
  const { firstSeenDate, lastSeenDate } = useMemo(
    () =>
      resolveLifeSpanDates(
        lastUpdateDate ?? "",
        resourceLifecycleDates,
        format
      ),
    [lastUpdateDate, resourceLifecycleDates, format]
  );

  return { firstSeenDate, lastSeenDate };
}

function resolveLifeSpanDates(
  lastUpdateDate: string,
  resourceLifecycleDates: ResourceLifecycleDates | undefined,
  format?: string
) {
  const firstSeenDate = formatCustomDate(
    resourceLifecycleDates?.creationDate,
    format
  );

  const lastSeenDateRaw = getLastSeenDate(
    lastUpdateDate,
    resourceLifecycleDates?.currentMonthLastSpendDate
  );

  const lastSeenDate = formatCustomDate(lastSeenDateRaw, format);

  if (isNotNil(firstSeenDate) && isNotNil(lastSeenDate)) {
    /* First seen date is decided by the estate record creation date and last seen date is being decided by
        cloud scanner's metadata update date and last cost date. There are cases when capacity scanner can
        create an estate record and the cost for it can be in the past, therefore the first seen date can
        be ahead of last seen date. */
    return {
      firstSeenDate,
      lastSeenDate: dayjs(firstSeenDate).isAfter(lastSeenDate)
        ? firstSeenDate
        : lastSeenDate,
    };
  }

  return { firstSeenDate, lastSeenDate };
}

function getLastSeenDate(
  lastUpdateDate: MixedValue,
  currentMonthLastSpendDate: string | undefined
) {
  if (isString(lastUpdateDate) && currentMonthLastSpendDate) {
    return dayjs(lastUpdateDate).isAfter(currentMonthLastSpendDate)
      ? lastUpdateDate
      : currentMonthLastSpendDate;
  }
  return lastUpdateDate ?? currentMonthLastSpendDate;
}
