/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  Message,
  FlexContainer,
  theme,
  Spacer,
  Label,
  Text,
  Input,
  Button,
} from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { ContactsSelector } from "~/components/Inputs/ContactsSelector/ContactsSelector";
import { useQueryState } from "~/hooks";
import { CloudAccountsQueryState, ProviderTab } from "../../types";
import { HybridFormData, HybridFormFields } from "./types";

type Props = {
  loading: boolean;
  defaultValues?: {
    clusterName: string;
    ownerId: string;
  };
  onSubmit: (formData: HybridFormData) => Promise<void>;
  onClose: () => void;
};

export function HybridForm({
  loading,
  defaultValues,
  onSubmit,
  onClose,
}: Props) {
  const {
    state: { provider },
  } = useQueryState<CloudAccountsQueryState>();

  const formMethods = useForm<HybridFormData>({
    resolver: yupResolver(hybridCloudSchema),
    defaultValues,
  });

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const getProviderName = () => {
    switch (provider) {
      case ProviderTab.KUBERNETES:
        return "Kubernetes";
      case ProviderTab.OPENSHIFT:
        return "OpenShift";
      default:
        return "";
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form id="onboard-form" onSubmit={handleSubmit(onSubmit)}>
        <Message status="notification" image="info">
          <FlexContainer direction="column" alignItems="start">
            <Text
              weight="medium"
              color={theme.color.text.info}
              mb={theme.spacing.spacing00}
            >
              On-boarding process
            </Text>
            <Text
              mt={theme.spacing.spacing02}
              mb={theme.spacing.spacing00}
              color={theme.color.text.text02}
            >
              You can onboard to MCA your {getProviderName()} clusters and map
              your resources to the proper applications and environments. Here
              we prepared easy two-step onboarding process where you can create
              your cluster to be visible in MCA as a first step, and after that
              you deploy simple .yaml file inside your cluster to feed MCA with
              the data.
            </Text>
          </FlexContainer>
        </Message>
        <Spacer height={theme.spacing.spacing06} />
        <FormGroup error={errors[HybridFormFields.CLUSTER_NAME]}>
          <Label
            required
            htmlFor={HybridFormFields.CLUSTER_NAME}
            name="Cluster Name"
          />
          <Input
            placeholder="e.g., cluster name"
            {...register(HybridFormFields.CLUSTER_NAME)}
          />
        </FormGroup>
        <FormGroup error={errors[HybridFormFields.OWNER_ID] ?? []}>
          <Label
            required
            htmlFor={HybridFormFields.OWNER_ID}
            name="Cluster Owner"
          />
          <ContactsSelector
            onChange={(selectedContact) =>
              setValue(HybridFormFields.OWNER_ID, selectedContact)
            }
          />
        </FormGroup>
        <Spacer height={theme.spacing.spacing03} />

        <FlexContainer columnGap={theme.spacing.spacing03}>
          <Button
            icon="checkmark"
            initialState={loading ? "loading" : ""}
            css={{ border: 0 }}
            type="submit"
            form="onboard-form"
          >
            Apply
          </Button>
          <Button severity="low" type="button" onClick={onClose}>
            Cancel
          </Button>
        </FlexContainer>
      </form>
    </FormProvider>
  );
}

const hybridCloudSchema = yup.object().shape({
  [HybridFormFields.CLUSTER_NAME]: yup
    .string()
    .max(99, "Cluster name must not exceed 99 characters")
    .required("Cluster Name is required"),
  [HybridFormFields.OWNER_ID]: yup
    .string()
    .required("Cluster Owner is required"),
});
