/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Metadata } from "./types";

export function mapMetadata<T>(metadata: Metadata[]) {
  // Types of the fields in metadata are unknown, thus
  // Manual mapping here with custom type casting
  return Object.fromEntries(
    metadata.map(({ label, value }) => [label, value])
  ) as unknown as Partial<T>;
}
