/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Textarea } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { WorkflowDataType } from "~/views/workflows/constants";
import { MessageMarkersLabel } from "../../MessageMarkersLabel";
import { TableFieldLabel, TableFieldWrapper, TableRow } from "../styles";
import { useDescriptionFieldsSection } from "./hooks";
import { SelectDataComponentSwitch } from "./SelectDataComponentSwitch";
import { TableRowHeader } from "./TableRowHeader";

type Props = {
  selectDataComponent: WorkflowDataType | undefined;
  isReadMode: boolean;
};

export function DescriptionFieldsSection({
  selectDataComponent,
  isReadMode,
}: Props) {
  const {
    register,
    descriptionError,
    shortDescriptionError,
    shouldEnterSelectDataComponentSwitch,
  } = useDescriptionFieldsSection({ selectDataComponent });

  if (shouldEnterSelectDataComponentSwitch) {
    return (
      <SelectDataComponentSwitch
        selectDataComponent={selectDataComponent}
        isReadMode={isReadMode}
      />
    );
  }

  return (
    <>
      <MessageMarkersLabel
        type={selectDataComponent}
        name="Message Required Fields"
        required={!isReadMode}
      />
      <TableRowHeader />
      <TableRow>
        <TableFieldWrapper width="40%">
          <TableFieldLabel
            name="short_description"
            htmlFor="serviceNowShortDescription"
            required={!isReadMode}
          />
        </TableFieldWrapper>
        <TableFieldWrapper width="60%">
          <FormGroup error={shortDescriptionError}>
            <Textarea
              disabled={isReadMode}
              id="serviceNowShortDescription"
              placeholder="short_description"
              {...register("serviceNow.shortDescription")}
              small
            />
          </FormGroup>
        </TableFieldWrapper>
      </TableRow>
      <TableRow>
        <TableFieldWrapper width="40%">
          <TableFieldLabel
            name="description"
            htmlFor="serviceNowDescription"
            required={!isReadMode}
          />
        </TableFieldWrapper>
        <TableFieldWrapper width="60%">
          <FormGroup error={descriptionError}>
            <Textarea
              disabled={isReadMode}
              id="serviceNowDescription"
              placeholder="description"
              {...register("serviceNow.description")}
              small
            />
          </FormGroup>
        </TableFieldWrapper>
      </TableRow>
    </>
  );
}
