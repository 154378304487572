/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Provider } from "~/generated/graphql";
import {
  DetailsDescription,
  DetailsItem,
  DetailsTitle,
} from "~/components/Details/styles";
import { InlineCloudResourcesMultiLink } from "../../components";
import { LinkedResource } from "../../components/ReallocatedCostsSidebar";

type Props = {
  provider: Provider | "";
  isCloudResource: boolean;
  nordcloudId: string;
  linkedResources: LinkedResource[] | null | undefined;
  cloudResourceData: {
    name: string;
    providerId: string;
    provider: Provider;
    providerType: string | null | undefined;
  } | null;
  refetch: () => void;
};

export function LinkedResources({
  provider,
  isCloudResource,
  nordcloudId,
  linkedResources,
  cloudResourceData,
  refetch,
}: Props) {
  return (
    <When
      condition={showLinkedResources({
        isCloudEstateRecord: isCloudResource,
        provider,
      })}
    >
      <DetailsItem>
        <DetailsTitle>Linked Resources</DetailsTitle>
        <DetailsDescription>
          <InlineCloudResourcesMultiLink
            nordcloudId={nordcloudId}
            linkedResources={linkedResources ?? []}
            onChange={() => {
              refetch();
            }}
            {...cloudResourceData}
          />
        </DetailsDescription>
      </DetailsItem>
    </When>
  );
}

type ShowLinkedResources = {
  isCloudEstateRecord: boolean;
  provider: Provider | "";
};

function showLinkedResources({
  isCloudEstateRecord,
  provider,
}: ShowLinkedResources) {
  const excludedProviders: (Provider | "")[] = [
    Provider.Kubernetes,
    Provider.Openshift,
  ];
  return isCloudEstateRecord && !excludedProviders.includes(provider);
}
