/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ThemeColors } from "@nordcloud/gnui/dist/theme/config";
import { Role } from "~/generated/graphql";

export const showColor = (role: Role): ThemeColors => {
  switch (role) {
    case Role.Admin:
      return "indigo";
    case Role.TeamLead:
      return "purple";
    case Role.ReadOnly:
      return "cyan";
    case Role.AdminLite:
      return "teal";
    default:
      return "grey";
  }
};
