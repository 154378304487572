/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { Button, FlexContainer, Text, theme } from "@nordcloud/gnui";

type Props = {
  title: string;
  onSelectAll?: () => void;
  onDeselectAll?: () => void;
  disabled?: boolean;
};

export function TreeActionBox({ title, onSelectAll, disabled = false }: Props) {
  return (
    <ActionBoxWrapper justifyContent="space-between">
      <Text m="0" color={theme.color.text.text02}>
        {title}
      </Text>
      <FlexContainer columnGap={theme.spacing.spacing02}>
        <Button
          type="button"
          size="md"
          severity="low"
          disabled={disabled}
          onClick={onSelectAll}
        >
          Select All
        </Button>
      </FlexContainer>
    </ActionBoxWrapper>
  );
}

const ActionBoxWrapper = styled(FlexContainer)`
  border-bottom: solid 1px ${theme.color.border.border01};
  padding: 0 ${theme.spacing.spacing04} ${theme.spacing.spacing02};
  margin-bottom: ${theme.spacing.spacing02};
`;
