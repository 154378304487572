/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Box, Col, FlexContainer, Row, Spacer, theme } from "@nordcloud/gnui";
import {
  EstateV2Filter,
  SearchSortField,
  SearchSortOrder,
} from "~/generated/graphql";
import { SearchBoxContainer, SortDirection } from "~/components";
import { useEstateFilters } from "~/hooks";
import { isNotEmpty } from "~/tools";
import {
  EstateActiveFilters,
  EstateFiltersToggle,
  EstateSort,
} from "~/views/estate/components";

type Props = {
  sortField: SearchSortField;
  sortDirection: SearchSortOrder;
  onSortSelect: (filter: SearchSortField) => void;
  onSortOrderChange: (direction: SortDirection) => void;
  searchInput: string;
  setSearchInput: (query: string) => void;
  query: string;
  filters: EstateV2Filter;
};

export function EstateRecordsFilter({
  filters,
  sortField,
  sortDirection,
  onSortSelect,
  onSortOrderChange,
  searchInput,
  setSearchInput,
  query,
}: Props) {
  const { activeFilters, removeFields, clearFilters, removeFilterValue } =
    useEstateFilters(filters);
  const showFilters = isNotEmpty(activeFilters);

  return (
    <Box boxStyle="lightGrey" spacing="spacing04">
      <FlexContainer justifyContent="space-between" alignItems="center">
        <EstateSort
          sortField={sortField}
          sortDirection={sortDirection}
          onSortSelect={onSortSelect}
          onSortOrderChange={onSortOrderChange}
        />
        <div
          css={{
            margin: `0 ${theme.spacing.spacing05}`,
            flexGrow: 1,
          }}
        >
          <SearchBoxContainer
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
        </div>
        <EstateFiltersToggle
          isAccount
          allowSavingFilters={false}
          onApply={() => {
            setSearchInput(query ?? "");
          }}
        />
      </FlexContainer>
      <When condition={showFilters}>
        <Row>
          <Col md="12">
            <div css={{ marginLeft: theme.spacing.spacing02 }}>
              <Spacer height={theme.spacing.spacing04} />
              <EstateActiveFilters
                filters={activeFilters}
                allowSavingFilters={false}
                onRemoveKey={removeFields}
                onClear={clearFilters}
                onRemoveValue={(itemName, index) =>
                  removeFilterValue(itemName as keyof EstateV2Filter, index)
                }
              />
            </div>
          </Col>
        </Row>
      </When>
    </Box>
  );
}
