/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { theme } from "@nordcloud/gnui";
import { ReservationsAndCommitmentsCoverageServiceUsageType } from "~/generated/graphql";
import { LegendProps } from "../../types";

export function useGetAwsLegendData(
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType,
  showEC2Only: boolean
): LegendProps[] {
  const EC2Legend = showEC2Only
    ? "On-Demand Cost (EC2)"
    : "On-Demand Cost (EC2, Lambda, Fargate)";

  const databaseLegend =
    "On-Demand Cost (RDS, Redshift, OpenSearch and ElasticCache services)";

  const reservedInstanceAndCoverage = [
    {
      color: theme.color.support.cyan,
      label: "Covered By Reserved Instances",
    },
  ];

  const savingsPlanAndCoverage = [
    {
      color: theme.color.support.yellow,
      label: "Covered By Savings Plans",
    },
  ];

  const machineLearningOnDemand = [
    {
      color: theme.color.support.grey,
      label: "On-Demand Cost (Sagemaker)",
    },
  ];

  const computeOnDemand = [
    {
      color: theme.color.support.grey,
      label: EC2Legend,
    },
  ];

  const databaseOnDemand = [
    {
      color: theme.color.support.grey,
      label: databaseLegend,
    },
  ];

  switch (serviceUsageType) {
    case ReservationsAndCommitmentsCoverageServiceUsageType.Database:
      return [...databaseOnDemand, ...reservedInstanceAndCoverage];
    case ReservationsAndCommitmentsCoverageServiceUsageType.MachineLearning:
      return [...machineLearningOnDemand, ...savingsPlanAndCoverage];
    default:
      return [
        ...computeOnDemand,
        ...reservedInstanceAndCoverage,
        ...savingsPlanAndCoverage,
      ];
  }
}
