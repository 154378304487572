/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import {
  SearchJobQuery,
  SearchJobStatus,
  useSearchJobQuery,
  useSubmitSearchJobV2Mutation,
} from "~/generated/graphql";
import { dateFormat } from "~/constants";
import { useCustomers } from "~/services/customers";
import { isNotEmpty } from "~/tools";

const POLL_INTERVAL_MS = 1000; // 1 second
const POLL_INTERVAL_ON_FAIL_MS = 30_000; // 30 seconds

const ERROR_MESSAGE =
  "Sorry, something went wrong. Please clear existing filters and try again or contact us using products@nordcloud.com.";

export function useEstateRecordsV2(
  queryParams: Parameters<typeof useSubmitSearchJobV2Mutation>[0] & {
    skip?: boolean;
  },
  onError: (message: string) => void,
  period: string | undefined
) {
  const { isAdminLite } = useCustomers();
  const [jobId, setJobId] = useState("");
  const [errorCount, setErrorCount] = useState(0);
  const [results, setResults] = useState<SearchJobQuery | undefined>(undefined);

  const [submitSearch, { loading: isSubmitLoading }] =
    useSubmitSearchJobV2Mutation({
      ...queryParams,
      onCompleted: (result) => setJobId(result?.submitSearchJobV2?.jobId ?? ""),
      context: {
        headers: {
          "x-billing-period": dayjs(period).format(dateFormat.yearMonth),
        },
      },
    });

  const {
    data,
    loading: isSearchJobLoading,
    error,
    startPolling,
    stopPolling,
  } = useSearchJobQuery({
    variables: {
      jobId,
      isLite: isAdminLite,
    },
    pollInterval: POLL_INTERVAL_MS,
    notifyOnNetworkStatusChange: true,
    skip: isEmpty(jobId),
    onCompleted: (result) => {
      if (result.searchJob?.status === SearchJobStatus.Completed) {
        handleDataResponse(data);
      }

      if (result.searchJob?.status === SearchJobStatus.Failed) {
        handleDataResponse(data);
        onError(ERROR_MESSAGE);
      }
    },
    onError: () => {
      stopPolling();
      if (errorCount < 4) {
        setErrorCount((prevValue) => prevValue + 1);
        startPolling(POLL_INTERVAL_ON_FAIL_MS);
      } else {
        setErrorCount(0);
      }
    },
  });

  const handleDataResponse = (response: SearchJobQuery | undefined) => {
    setResults(response);
    setJobId("");
    stopPolling();
  };

  type SubmitSearchParams = Parameters<typeof submitSearch>[0];

  const refetch = useCallback(
    (
      params?: Omit<Parameters<typeof submitSearch>[0], "variables"> & {
        variables?: Partial<NonNullable<SubmitSearchParams>["variables"]>;
      }
    ) => {
      void submitSearch({
        ...queryParams,
        ...params,
        variables: {
          limit:
            queryParams?.variables?.limit ?? params?.variables?.limit ?? 20,
          ...queryParams?.variables,
          ...params?.variables,
          filter: {
            ...queryParams?.variables?.filter,
            ...params?.variables?.filter,
          },
        },
      });
    },
    // `queryParams` might get recreated on every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(queryParams)]
  );

  const isLoading =
    !error && (isSearchJobLoading || isSubmitLoading || isNotEmpty(jobId));

  useEffect(
    () => {
      if (queryParams?.skip) {
        return;
      }
      setErrorCount(0);
      refetch(queryParams);
    },
    // `queryParams` might get recreated on every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(queryParams), submitSearch, period]
  );

  return useMemo(
    () => ({
      data: isLoading ? undefined : results?.searchJob?.data ?? undefined,
      refetch,
      loading: isLoading,
      error,
    }),
    [results, error, isLoading, refetch]
  );
}
