/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ReservationsAndCommitmentsCoverageServiceUsageType } from "~/generated/graphql";

export function useGetCurrentTab(
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType
) {
  const isComputeTab =
    serviceUsageType ===
    ReservationsAndCommitmentsCoverageServiceUsageType.Compute;
  const isDatabaseTab =
    serviceUsageType ===
    ReservationsAndCommitmentsCoverageServiceUsageType.Database;
  const isMachineLearningTab =
    serviceUsageType ===
    ReservationsAndCommitmentsCoverageServiceUsageType.MachineLearning;
  const isAnalyticsTab =
    serviceUsageType ===
    ReservationsAndCommitmentsCoverageServiceUsageType.Analytics;
  const isStorageTab =
    serviceUsageType ===
    ReservationsAndCommitmentsCoverageServiceUsageType.Storage;
  const isRICoverage =
    isDatabaseTab || isComputeTab || isAnalyticsTab || isStorageTab;
  return {
    isComputeTab,
    isRICoverage,
    isAnalyticsTab,
    isDatabaseTab,
    isStorageTab,
    isMachineLearningTab,
  };
}
