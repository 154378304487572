/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Text } from "@nordcloud/gnui";
import { ReactTable, NoData } from "~/components";
import { isNotEmpty } from "~/tools";
import { KEY_VALUE_COLUMNS } from "./Columns/KeyValueColumns";

type Props = {
  metaTags: {
    label: string;
    value: string;
  }[];
};

export function MetaTagsTabContents({ metaTags }: Props) {
  const hasMetaTags = isNotEmpty(metaTags);

  if (hasMetaTags) {
    return <ReactTable columns={KEY_VALUE_COLUMNS} data={metaTags} striped />;
  }

  return <NoData message={<Text>There are no tags.</Text>} />;
}
