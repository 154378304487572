/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  ArchiveKpiTargetMutationVariables,
  BusinessContextTargetsDocument,
  BusinessContextTargetsQuery,
  BusinessContextTargetsQueryVariables,
  KpiTargetSelection,
  useArchiveKpiTargetMutation,
} from "~/generated/graphql";
import { SUCCESS_TEXT } from "~/constants";
import { showSuccess } from "~/services/toast";
import { handleError, toInputError } from "~/tools";
import {
  addTargetToCache,
  getModifiedKpiName,
  invalidateKpisInCache,
  isCurrentTarget,
  removeTargetFromCache,
} from "./helpers";

export function useArchiveTarget() {
  const [
    archiveTargetMutation,
    {
      client: { cache },
      loading,
    },
  ] = useArchiveKpiTargetMutation();

  const archiveTarget = async (
    variables: ArchiveKpiTargetMutationVariables,
    queryVariables: BusinessContextTargetsQueryVariables
  ) => {
    try {
      const { data } = await archiveTargetMutation({ variables });

      const archivedTarget = data?.archiveKPITarget;

      if (archivedTarget == null) {
        return;
      }
      const modifiedKpi = getModifiedKpiName(queryVariables);

      if (isCurrentTarget(archivedTarget)) {
        invalidateKpisInCache(cache, queryVariables);
      } else {
        cache.updateQuery<
          BusinessContextTargetsQuery,
          BusinessContextTargetsQueryVariables
        >(
          {
            query: BusinessContextTargetsDocument,
            variables: queryVariables,
          },
          (bcTargets) =>
            removeTargetFromCache(bcTargets, archivedTarget.id, modifiedKpi)
        );

        cache.updateQuery<
          BusinessContextTargetsQuery,
          BusinessContextTargetsQueryVariables
        >(
          {
            query: BusinessContextTargetsDocument,
            variables: {
              ...queryVariables,
              selection: KpiTargetSelection.Archived,
            },
          },
          (bcTargets) =>
            addTargetToCache(bcTargets, archivedTarget, modifiedKpi)
        );
      }

      showSuccess(SUCCESS_TEXT.kpiTargetArchived);
    } catch (error) {
      handleError(toInputError(error));
    }
  };

  return { archiveTarget, loading };
}
