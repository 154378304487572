/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  useAccountSpendsQuery,
  useCloudAccountSavingsCostsQuery,
  useCloudAccountsQuery,
} from "~/generated/graphql";
import { isNotNil } from "~/tools";
import { mapResponseData } from "../hooks/useCloudAccountsFetch";
import { CloudProviderType, Provider } from "../types";
import { isPossibleToUpdateCredentialsV2 } from "../utils";

export function useCloudAccountDetails(nid: string) {
  const {
    data,
    loading: isAccountsLoading,
    error: accountQueryError,
  } = useCloudAccountsQuery({
    variables: {
      limit: 1,
      filter: {
        nid,
      },
    },
  });

  const accounts = mapResponseData(data).accounts ?? [];

  const {
    providerId = "",
    provider,
    providerType,
    chargeTypeCosts,
  } = accounts[0] ?? {};

  const { data: accountSpendsData } = useAccountSpendsQuery({
    variables: { accountProviderId: providerId },
    fetchPolicy: "no-cache",
  });
  const {
    currentMonthForecastValue,
    currentMonthValue,
    previousMonthValue,
    currentMonthCo2e,
  } = accountSpendsData?.accountSpends ?? {};

  const { data: accountSavingsCostsData } = useCloudAccountSavingsCostsQuery({
    variables: { cloudProviderId: providerId },
    fetchPolicy: "no-cache",
  });
  const { cost: savings, co2e } =
    accountSavingsCostsData?.cloudAccountSavingCosts ?? {};

  const showUpdateCredentials = isPossibleToUpdateCredentialsV2(
    providerType,
    provider
  );

  const tags = [
    {
      color: "success",
      text: "Account",
    },
  ];

  const hideChargeTypes =
    [Provider.Hybrid].includes(provider) &&
    isNotNil(providerType) &&
    [CloudProviderType.Kubernetes, CloudProviderType.Openshift].includes(
      providerType
    );

  return {
    account: accounts[0],
    accountSpends: {
      currentMonthForecastValue: currentMonthForecastValue ?? 0,
      currentMonthValue: currentMonthValue ?? 0,
      previousMonthValue: previousMonthValue ?? 0,
      currentMonthCo2e: currentMonthCo2e ?? 0,
    },
    accountSavingsCosts: {
      cost: Number(savings ?? 0),
      co2e: Number(co2e ?? 0),
    },
    tags,
    isAccountsLoading,
    accountQueryError,
    showUpdateCredentials,
    chargeTypeCosts: hideChargeTypes
      ? []
      : (chargeTypeCosts ?? []).map(({ chargeType, cost }) => ({
          chargeType,
          cost,
        })),
  };
}
