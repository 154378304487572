/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  CloudAccountCostsByProviderResponse,
  CloudAccountsDocument,
  useAddAccountV3Mutation,
  CloudAccountsQuery,
  CloudAccountsQueryVariables,
  Query,
} from "~/generated/graphql";
import { useQueryState } from "~/hooks";
import { useCloudAccountSort } from "../../hooks";
import { CloudAccountsQueryState, ProviderTab } from "../../types";
import { getAccountFilters, getAccountStatusFilter } from "../../utils";

export function useAddAccount(provider: ProviderTab) {
  const {
    state: { status, query, page, limit, order, chargeType, providerFilter },
  } = useQueryState<CloudAccountsQueryState>();

  const { sortDirection, sortField } = useCloudAccountSort({
    chargeType,
    order,
  });

  const [addAccount, { loading }] = useAddAccountV3Mutation({
    update: (cache, mutationResult) => {
      if (mutationResult.data?.addAccountV3?.nid) {
        cache.modify<Query>({
          fields: {
            // @ts-expect-error TODO: fix
            cloudAccountCostsByProvider(
              value: CloudAccountCostsByProviderResponse
            ) {
              const costsByProvider = value?.costsByProvider?.map((cost) => {
                if (cost.provider === provider) {
                  return {
                    ...cost,
                    count: cost.count + 1,
                  };
                }

                return cost;
              });

              return {
                ...value,
                costsByProvider,
              };
            },
          },
        });
        cache.updateQuery<CloudAccountsQuery, CloudAccountsQueryVariables>(
          {
            query: CloudAccountsDocument,
            variables: {
              limit,
              page,
              order: {
                order: sortDirection,
                field: sortField,
                chargeType,
              },
              filter: {
                ...getAccountFilters(provider, providerFilter),
                ...getAccountStatusFilter(status ?? []),
              },
              query: typeof query === "number" ? String(query) : query,
            },
          },
          (cloudAccounts) => {
            const newAccount = mutationResult.data?.addAccountV3;
            return {
              cloudAccounts: {
                count: cloudAccounts?.cloudAccounts?.count ?? 0,
                pages: cloudAccounts?.cloudAccounts?.pages ?? 0,
                accounts: [
                  ...(newAccount ? [newAccount] : []),
                  ...(cloudAccounts?.cloudAccounts?.accounts ?? []),
                ],
              },
            };
          }
        );
      }
    },
  });

  return { addAccount, loading };
}
