/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Case, Default, Switch } from "react-if";
import styled from "styled-components";
import {
  ExtendedPopover,
  ExtendedTooltip,
  FlexContainer,
  ModalConfirm,
  SVGIcon,
  Text,
  theme,
} from "@nordcloud/gnui";
import { useRemoveEnvFromOrgUnitV2Mutation } from "~/generated/graphql";
import { SUCCESS_TEXT } from "~/constants";
import { useMutationHandler } from "~/hooks";
import { isNotNil } from "~/tools";
import { MappedOrgUnitV2 } from "../types";
import { OrgUnitPopover } from "./OrgUnitPopover";
import { OrgUnitTag } from "./OrgUnitTag";

type Props = {
  orgUnits: MappedOrgUnitV2[];
  environmentId: string;
};

export function OrganizationalUnitCell({ orgUnits, environmentId }: Props) {
  const { runMutation } = useMutationHandler();

  const [removeEnvFromOrgUnit] = useRemoveEnvFromOrgUnitV2Mutation();

  const [detachItem, setDetachItem] = useState<MappedOrgUnitV2 | null>(null);

  const handleRemove = async (envId: string, orgUnitId: string) => {
    await runMutation({
      mutation: () =>
        removeEnvFromOrgUnit({
          variables: {
            envId,
            orgUnitId,
          },
        }),
      successText: SUCCESS_TEXT.environmentRemoved,
    });
    setDetachItem(null);
  };

  return (
    <>
      <Switch>
        <Case condition={orgUnits.length < 2}>
          <FlexContainer columnGap={theme.spacing.spacing02}>
            {orgUnits.map((orgUnit) => (
              <OrgUnitTag
                key={orgUnit.id}
                orgUnit={orgUnit}
                onDetach={(item) => setDetachItem(item)}
              />
            ))}
            <ExtendedTooltip caption="Attach Environment">
              <LinkButton>
                <SVGIcon name="link" size="sm" />
              </LinkButton>
            </ExtendedTooltip>
          </FlexContainer>
        </Case>
        <Default>
          <ExtendedPopover
            position="center"
            placement="top"
            triggerOn="click"
            closeOn="hover"
            content={
              <OrgUnitPopover
                orgUnits={orgUnits}
                onDetach={(item) => setDetachItem(item)}
              />
            }
            trigger={
              <Text mb="0" css={{ cursor: "pointer" }} size="sm">
                {orgUnits.length} Organizational Units
              </Text>
            }
          />
        </Default>
      </Switch>
      <ModalConfirm
        isOpen={isNotNil(detachItem)}
        contentLabel="Detachment"
        actionLabel="Confirm"
        confirm={() => handleRemove(environmentId, detachItem?.id ?? "")}
        onClose={() => setDetachItem(null)}
      >
        Do you want to detach {detachItem?.envName} from {detachItem?.name}?
      </ModalConfirm>
    </>
  );
}

const LinkButton = styled.div`
  background-color: ${theme.color.background.ui04};
  border-radius: ${theme.radius.sm};
  cursor: pointer;
  padding: ${theme.spacing.spacing01} ${theme.spacing.spacing02};

  svg {
    margin-top: -4px;
  }

  &:hover {
    background-color: ${theme.color.background.ui01};
  }
`;
