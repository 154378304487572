/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { FlexContainer, Text, theme } from "@nordcloud/gnui";

type ApplicationItemCellProps = {
  title: string;
  children: React.ReactNode;
  borderLeft?: boolean;
};

export function ApplicationItemCell({
  title,
  children,
  borderLeft,
}: ApplicationItemCellProps) {
  return (
    <div
      css={{
        width: "13rem",
        paddingLeft: theme.spacing.spacing08,
        borderLeft: borderLeft
          ? `1px solid ${theme.color.border.border01}`
          : "none",
      }}
    >
      <FlexContainer justifyContent="flex-end" alignItems="end">
        <Text
          size="sm"
          tag="div"
          width="7rem"
          pr={theme.spacing.spacing02}
          mb={theme.spacing.spacing01}
          align="right"
        >
          {title}
        </Text>
      </FlexContainer>
      <FlexContainer justifyContent="flex-end" alignItems="end">
        {children}
      </FlexContainer>
    </div>
  );
}
