/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { TooltipData } from "@visx/xychart/lib/types/tooltip";
import { ReservationsProvider } from "~/generated/graphql";
import { TimePoint, TooltipCallbackData } from "./types";

export function getTooltipDetailsCallback(
  provider: ReservationsProvider
): (tooltipData: TooltipData<TimePoint>) => TooltipCallbackData {
  return (tooltipData: TooltipData<TimePoint>) => {
    if (!tooltipData.nearestDatum?.datum) {
      return {
        date: "",
        totalCost: "",
        reservedInstanceCost: "",
        savingsPlanCost: "",
        onDemandCost: "",
        provider,
      };
    }
    const {
      date,
      totalCost,
      reservedInstanceCost,
      savingsPlanCost,
      onDemandCost,
    } = tooltipData.nearestDatum.datum;

    return {
      date,
      totalCost: totalCost ?? "",
      reservedInstanceCost,
      savingsPlanCost,
      onDemandCost,
      provider,
    };
  };
}
