/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const FinopsKpiPolicies = {
  BusinessContextKpis: {
    merge: true,
  },
  KpiDetails: {
    merge: true,
  },
  KpiTargets: {
    merge: true,
  },
  KpiTarget: {
    merge: true,
  },
};
