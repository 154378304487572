/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch } from "react";
import {
  Checkbox,
  CheckboxGroup,
  FlexContainer,
  SVGIcon,
  Spacer,
  Text,
  theme,
} from "@nordcloud/gnui";
import { accountStatusIconProps } from "~/components/Icons/AccountStatusIcon";
import { CloudAccountStatus } from "~/tools";
import {
  ProviderTab,
  ProviderStatus,
  StatusFilterCheckboxProps,
} from "../types";

type CheckboxGroupProps = {
  provider: ProviderTab;
  statusValues: ProviderStatus;
  updateStatusValues: Dispatch<
    CloudAccountStatus | "clearFilterCheckboxes" | "setValuesFromQuery"
  >;
};

export function AccountStatusCheckboxGroup({
  provider,
  statusValues,
  updateStatusValues,
}: CheckboxGroupProps) {
  const checkboxes = getCheckboxes(provider);

  return (
    <>
      <Spacer height={theme.spacing.spacing03} />
      <CheckboxGroup name="status-checkbox-group">
        {checkboxes.map((checkbox) => (
          <CheckItem
            key={checkbox.status}
            checkbox={checkbox}
            statusValues={statusValues}
            updateStatusValues={updateStatusValues}
          />
        ))}
      </CheckboxGroup>
    </>
  );
}

type CheckItemProps = {
  statusValues: ProviderStatus;
  updateStatusValues: Dispatch<
    CloudAccountStatus | "clearFilterCheckboxes" | "setValuesFromQuery"
  >;
  checkbox: StatusFilterCheckboxProps;
};

function CheckItem({
  checkbox,
  statusValues,
  updateStatusValues,
}: CheckItemProps) {
  const { status, icon, color, label, description } = checkbox;

  return (
    <FlexContainer>
      <div>
        <Checkbox
          id={status}
          checked={statusValues[status]}
          onChange={() => updateStatusValues(status)}
        />
      </div>
      <FlexContainer
        columnGap={theme.spacing.spacing02}
        mb={theme.spacing.spacing04}
      >
        <SVGIcon name={icon} color={color} />
        <div>
          <Text mb={0}>{label}</Text>
          <Text size="sm" color={theme.color.text.text02} mb={0}>
            {description}
          </Text>
        </div>
      </FlexContainer>
    </FlexContainer>
  );
}

function getCheckboxes(provider: ProviderTab) {
  switch (provider) {
    case ProviderTab.IBMCLOUD:
      return [CloudAccountStatus.NoAccess, CloudAccountStatus.FullAccess].map(
        (status) => accountStatusIconProps[status]
      );
    case ProviderTab.KUBERNETES:
    case ProviderTab.OPENSHIFT:
      return [
        CloudAccountStatus.NoAccess,
        CloudAccountStatus.NoData,
        CloudAccountStatus.FullAccess,
      ].map((status) => accountStatusIconProps[status]);
    case ProviderTab.VMWARE:
      return [CloudAccountStatus.NoData, CloudAccountStatus.FullAccess].map(
        (status) => accountStatusIconProps[status]
      );
    default:
      return [
        CloudAccountStatus.NoAccess,
        CloudAccountStatus.BillingOnly,
        CloudAccountStatus.ApiOnly,
        CloudAccountStatus.FullAccess,
      ].map((status) => accountStatusIconProps[status]);
  }
}
