/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useWindowSize } from "react-use";
import { TableGeneratorLoader } from "./TableGeneratorLoader";

type Props = {
  width?: number;
};

export function TableLoader({
  gapY,
  rows,
  ...rest
}: Omit<
  React.ComponentPropsWithoutRef<typeof TableGeneratorLoader>,
  "height" | "width"
> &
  Props) {
  const { width } = useWindowSize();
  const height = rows.reduce(
    (acc, row) => acc + (row.height + gapY) * row.count,
    0
  );

  return (
    <TableGeneratorLoader
      width={width}
      height={height}
      gapY={gapY}
      rows={rows}
      style={{
        display: "block",
        width: "100%",
        height: "auto",
      }}
      {...rest}
    />
  );
}
