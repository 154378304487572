/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Checkbox, Text } from "@nordcloud/gnui";
import { SidebarItemWrapper } from "~/components";
import { SwitchWrap } from "../AppEnvSelector/styles";
import { StyledFlexContainer } from "../styles";
import { ApplicationOption, EnvironmentOption } from "../types";

type Props = {
  application: ApplicationOption;
  selectedEnvs: EnvironmentOption[];
  isAppChecked: boolean;
  disabled: boolean;
  onEnvCheck: (env: EnvironmentOption) => void;
};

export function EnvironmentsList({
  application,
  selectedEnvs,
  isAppChecked,
  disabled,
  onEnvCheck,
}: Props) {
  return (
    <>
      {application.environments.map((env) => (
        <EnvironmentRow
          key={env.nid}
          environment={env}
          selectedEnvs={selectedEnvs}
          isAppChecked={isAppChecked}
          disabled={disabled}
          onEnvCheck={onEnvCheck}
        />
      ))}
    </>
  );
}

type EnvRowProps = {
  environment: EnvironmentOption;
  selectedEnvs: EnvironmentOption[];
  isAppChecked: boolean;
  disabled: boolean;
  onEnvCheck: (env: EnvironmentOption) => void;
};

function EnvironmentRow({
  environment,
  selectedEnvs,
  isAppChecked,
  disabled,
  onEnvCheck,
}: EnvRowProps) {
  const { nid, name } = environment;
  const isEnvChecked =
    isAppChecked ||
    selectedEnvs.some(({ nid: selectedNid }) => selectedNid === nid);

  return (
    <StyledFlexContainer key={nid}>
      <SidebarItemWrapper
        hideBottomBorder
        title={name}
        data-testid="sidebar-item"
        onClick={() => onEnvCheck(environment)}
      >
        <SwitchWrap>
          <Checkbox
            readOnly
            withoutLabel
            data-testid="env-filter-checkbox"
            disabled={disabled}
            checked={isEnvChecked}
          />
        </SwitchWrap>
        <Text mb="0">{name}</Text>
      </SidebarItemWrapper>
    </StyledFlexContainer>
  );
}
