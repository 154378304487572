/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { DateRangeInput } from "./DateRangeInput";

type ISODate = string | undefined;

type Props = Omit<
  React.ComponentProps<typeof DateRangeInput>,
  "from" | "onValueFromChange" | "onValueToChange" | "to"
> & {
  startDate: ISODate;
  endDate: ISODate;
  onStartDateChange: (date: ISODate) => void;
  onEndDateChange: (date: ISODate) => void;
};

export function RecordsDateRange({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  ...props
}: Props) {
  const handleStartDateUpdate = (input: string) => {
    const newDate = getDate(input);

    onStartDateChange(
      newDate != null ? newDate.startOf("day").toISOString() : undefined
    );
  };

  const handleEndDateUpdate = (input: string) => {
    const newDate = getDate(input);

    onEndDateChange(
      newDate != null ? newDate.endOf("day").toISOString() : undefined
    );
  };

  return (
    <DateRangeInput
      from={startDate ?? ""}
      to={endDate ?? ""}
      onValueFromChange={handleStartDateUpdate}
      onValueToChange={handleEndDateUpdate}
      {...props}
    />
  );
}

function getDate(input: string) {
  return dayjs(input).isValid() ? dayjs(input) : undefined;
}
