/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ComponentProps, ReactNode, useState } from "react";
import { Case, Default, Switch, When } from "react-if";
import styled from "styled-components";
import {
  Box,
  FlexContainer,
  Spacer,
  SVGIcon,
  Text,
  theme,
} from "@nordcloud/gnui";
import { isString } from "~/tools";

type Props = {
  children: ReactNode;
  title: ReactNode;
  opened?: boolean;
  maxHeight?: string;
  boxProps?: ComponentProps<typeof Box>;
  arrowPlacement?: ArrowPlacement;
  onStateChange?: (opened: boolean) => void;
  disabled?: boolean;
};

export function CollapsibleBox({
  children,
  title,
  opened = true,
  maxHeight = "2000px",
  boxProps,
  arrowPlacement = "right",
  onStateChange,
  disabled = false,
}: Props) {
  const [isExpanded, setIsExpanded] = useState(opened);
  const icon = disabled ? "minus" : undefined;

  return (
    <Box {...boxProps}>
      <FlexContainer
        columnGap={theme.spacing.spacing02}
        alignItems="center"
        justifyContent={
          arrowPlacement === "left" ? "flex-start" : "space-between"
        }
      >
        <When condition={arrowPlacement === "left"}>
          <ClickableSVGIcon
            name={icon ?? (isExpanded ? "chevronUp" : "chevronDown")}
            isDisabled={disabled}
            onClick={() => setIsExpanded((prev) => !prev)}
          />
        </When>
        <Switch>
          <Case condition={isString(title)}>
            <Text weight="medium" mb={0}>
              {title}
            </Text>
          </Case>
          <Default>{title}</Default>
        </Switch>
        <When condition={arrowPlacement === "right"}>
          <ClickableSVGIcon
            name={icon ?? (isExpanded ? "chevronUp" : "chevronDown")}
            isDisabled={disabled}
            onClick={() => setIsExpanded((prev) => !prev)}
          />
        </When>
      </FlexContainer>
      <Wrapper
        expanded={isExpanded}
        maxHeight={maxHeight}
        onTransitionEnd={(event) => {
          if (
            (isExpanded && event.propertyName === "opacity") ||
            (!isExpanded && event.propertyName === "max-height")
          ) {
            onStateChange?.(isExpanded);
          }
        }}
      >
        <Spacer height={theme.spacing.spacing04} />
        {children}
      </Wrapper>
    </Box>
  );
}

type ArrowPlacement = "left" | "right";

const ClickableSVGIcon = styled(SVGIcon)<{ isDisabled: boolean }>`
  cursor: pointer;
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : undefined)};
`;

const Wrapper = styled.div<{ expanded: boolean; maxHeight: string }>`
  overflow: hidden;
  max-height: ${({ expanded, maxHeight }) => (expanded ? maxHeight : "0")};
  opacity: ${({ expanded }) => (expanded ? "1" : "0")};
  transition: ${({ expanded }) =>
    expanded
      ? "max-height 0.4s ease-in-out, opacity 0.2s linear 0.4s"
      : "max-height 0.4s ease-in-out 0.1s, opacity 0.2s linear"};
`;
