/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then } from "react-if";
import { Text } from "@nordcloud/gnui";
import { NoData } from "~/components";
import { DetailData } from "~/components/Details/Detail";
import { parseMetadata } from "~/views/estate/EstateDetailsPage/utils";
import { AdvancedDetails } from "./AdvancedDetails";

type Props = {
  metadata: ReturnType<typeof parseMetadata>[];
  jsonData: DetailData | undefined;
};

export function AdvancedDataTabContents({ metadata, jsonData }: Props) {
  const showAdvancedData =
    metadata.findIndex((item) => item.label === "details") > -1;

  return (
    <If condition={showAdvancedData}>
      <Then>
        <AdvancedDetails data={jsonData} />
      </Then>
      <Else>
        <NoData message={<Text>There is no advanced data.</Text>} />
      </Else>
    </If>
  );
}
