/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { TooltipData } from "@visx/xychart";
import dayjs from "dayjs";
import { When } from "react-if";
import { Text, theme } from "@nordcloud/gnui";
import { formatMoney } from "~/tools";
import { CostVsBudgetForecastChartTimePoint } from "../types";

type TooltipProps = {
  currency: string;
  hasBudget: boolean;
  tooltipDateFormat: string;
  tooltipData?: TooltipData<CostVsBudgetForecastChartTimePoint>;
};

export function renderCostVsBudgetTooltip({
  currency,
  hasBudget = false,
  tooltipDateFormat,
  tooltipData,
}: TooltipProps) {
  if (
    !tooltipData?.nearestDatum?.datum ||
    tooltipData.nearestDatum.distance > 30
  ) {
    return;
  }

  const { date, value, forecast, budget } = tooltipData.nearestDatum.datum;

  return (
    <>
      <Text m={0} size="xs" color={theme.color.text.text03}>
        {dayjs(date).format(tooltipDateFormat)}
      </Text>
      <When condition={value !== 0}>
        <Text size="sm" mb="0" color={theme.color.text.text04}>
          Cost: {formatMoney(value, currency)}
        </Text>
      </When>
      <When condition={forecast !== 0}>
        <Text size="sm" mb="0" color={theme.color.text.text04}>
          Forecast: {formatMoney(forecast, currency)}
        </Text>
      </When>
      <When condition={hasBudget}>
        <Text size="sm" mb="0" color={theme.color.text.text04}>
          Budget: {formatMoney(budget, currency)}
        </Text>
      </When>
    </>
  );
}
