/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useQueryState } from "~/hooks";
import { isNotNil } from "~/tools";
import { ThresholdSortOrder, ThresholdsQueryState } from "./types";

export function useThresholdsSort() {
  const { state, updateQueryState } = useQueryState<ThresholdsQueryState>();
  const { column, order } = state;

  const onSort = (sort: { id: string; desc: boolean }) => {
    updateQueryState({
      ...state,
      column: sort.id,
      order: sort.desc ? ThresholdSortOrder.Desc : ThresholdSortOrder.Asc,
      page: 0,
    });
  };

  const defaultSort = {
    id: column ?? "createdDate",
    desc: isNotNil(order) ? order === ThresholdSortOrder.Desc : true,
  };

  return {
    defaultSort,
    onSort,
  };
}
