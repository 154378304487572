/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export type BusinessContextItem = {
  id: string;
  name: string;
  color: string;
  isPrimary: boolean;
};

export function useGetBusinessContext(
  businessContextList: BusinessContextItem[]
) {
  const primary =
    businessContextList.find((context) => context.isPrimary) ?? null;
  const rest = businessContextList.filter((context) => !context.isPrimary);
  const showMoreButton = rest.length > 5;

  return {
    primary,
    showMoreButton,
    nonPrimary: rest.slice(0, primary == null ? 5 : 4),
  };
}
