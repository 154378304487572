/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch, useEffect, useState } from "react";
import { When } from "react-if";
import { FlexContainer, theme } from "@nordcloud/gnui";
import { useCustomerConfigQuery } from "~/generated/graphql";
import {
  DataContainer,
  DataItem,
  TotalCostItems,
  ChartType,
  LinkParameters,
  TableData,
} from "~/components";
import { useCurrency } from "~/hooks";
import { formatMoney } from "~/tools";
import { useUpdateAnomalyThreshold } from "../hooks/useUpdateAnomalyThreshold";
import { AnomaliesThreshold } from "./AnomaliesThreshold";

type Props = {
  chartTypeValue: ChartType;
  tableData: TableData[];
  total: number;
  showTotalCost: boolean;
  linkParameters?: LinkParameters;
  setChangedThreshold?: Dispatch<number>;
  isEmptyData?: boolean;
  tooltipText?: string;
};

export function TotalCosts({
  chartTypeValue,
  tableData,
  total,
  showTotalCost,
  linkParameters,
  setChangedThreshold,
  isEmptyData = false,
  tooltipText,
}: Props) {
  const { currency } = useCurrency();
  const [threshold, setThreshold] = useState(0);
  const [errorTimestamp, setErrorTimestamp] = useState(0);
  const { data: customerConfigData, refetch } = useCustomerConfigQuery();

  const showTotalCostPerGroup = [
    ChartType.COST_PER_ENVIRONMENT,
    ChartType.COST_PER_CATEGORY,
    ChartType.ANOMALY_COST,
  ].includes(chartTypeValue);

  useEffect(() => {
    setThreshold(customerConfigData?.customerConfig?.anomalyThreshold ?? 0);
  }, [customerConfigData]);

  const {
    update: updateAnomalyThreshold,
    loading: updateAnomalyThresholdLoading,
  } = useUpdateAnomalyThreshold();

  const handleThresholdChanged = async (newThreshold: number) => {
    try {
      await updateAnomalyThreshold(newThreshold);
      setThreshold(newThreshold ?? 0);

      if (setChangedThreshold) {
        setChangedThreshold(newThreshold);
      }

      refetch();
    } catch {
      setErrorTimestamp(Date.now());
    }
  };

  const totalValue = getTotal(isEmptyData, formatMoney(total ?? 0, currency));
  const tooltipValue = tooltipText ?? undefined;

  return (
    <When condition={showTotalCost}>
      <FlexContainer
        css={{
          borderTop: `1px solid ${theme.color.border.border01}`,
          paddingTop: theme.spacing.spacing04,
        }}
      >
        <DataContainer marginBottom={theme.spacing.spacing02}>
          <DataItem
            grow={false}
            weight="medium"
            size="lg"
            label="Total Cost"
            value={totalValue}
            padding="0"
            tooltipText={tooltipValue}
            tooltipPosition="left"
          />
          <When condition={showTotalCostPerGroup}>
            <TotalCostItems
              data={tableData}
              chartTypeValue={chartTypeValue}
              isEmptyData={isEmptyData}
              linkParameters={linkParameters}
            />
          </When>
          <When condition={chartTypeValue === ChartType.ANOMALY_COST}>
            <div
              css={{
                paddingRight: theme.spacing.spacing02,
                paddingLeft: theme.spacing.spacing02,
              }}
            >
              <AnomaliesThreshold
                threshold={threshold}
                errorTimestamp={errorTimestamp}
                loading={updateAnomalyThresholdLoading}
                onThresholdChanged={handleThresholdChanged}
              />
            </div>
          </When>
        </DataContainer>
      </FlexContainer>
    </When>
  );
}

function getTotal(isEmptyData: boolean, value: string) {
  return isEmptyData ? "N/A" : value;
}
