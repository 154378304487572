/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useAttachResourceCostSplitRulesMutation } from "~/generated/graphql";
import { ERROR_TEXT } from "~/constants";
import { showError, showSuccess } from "~/services/toast";
import { generateActionSuccessText } from "~/tools";
import { AttachInput } from "../../types";

type ReturnValues = {
  attachRules: (attachInput: AttachInput) => Promise<void>;
  loading: boolean;
};

export function useResourceCostSplitRule(): ReturnValues {
  const [attachResourceCostSplitRules, { loading }] =
    useAttachResourceCostSplitRulesMutation();

  const attachRules = async (attachInput: AttachInput) => {
    try {
      const { resources, algorithm, settings } = attachInput;
      const response = await attachResourceCostSplitRules({
        variables: {
          input: {
            resources,
            algorithm,
            settings: {
              environments: settings,
            },
          },
        },
        fetchPolicy: "network-only",
      });

      if (response) {
        showSuccess(
          generateActionSuccessText("Cost splitting rule")()("added")()
        );
      }
    } catch (error) {
      showError(error.message ?? ERROR_TEXT.tryAgainDefault);
      throw error;
    }
  };

  return {
    attachRules,
    loading,
  };
}
