/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback } from "react";
import { When } from "react-if";
import {
  theme,
  Box,
  MultipleSelect,
  SelectButton,
  Col,
  Row,
  FlexContainer,
} from "@nordcloud/gnui";
import {
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsAndCommitmentsFilters,
  ReservationsAndCommitmentsLookbackPeriod as LookbackPeriod,
  ReservationsProvider,
} from "~/generated/graphql";
import { QueryStateFilter } from "~/hooks";
import { isNotEmpty, noop } from "~/tools";
import {
  ReservationsCommitmentsFilters,
  ReservationsActiveFilters,
} from "./components";
import { filtersValues } from "./constants";
import { CoverageChartFilters } from "./types";

type Props = {
  idText: string;
  anyTab: boolean;
  serviceType: ReservationsAndCommitmentsCoverageServiceUsageType;
  filters: CoverageChartFilters;
  children: React.ReactNode;
  timePeriod: LookbackPeriod;
  showFiltersOption: boolean;
  activeFilters?: QueryStateFilter<ReservationsAndCommitmentsFilters>[];
  provider: ReservationsProvider;
  offeringType?: ReservationsAndCommitmentsCoverageOfferingType;
  onSetTimePeriodClick: (lookbackPeriod: LookbackPeriod) => void;
  onRemoveFields?: (fields: (number | string)[]) => void;
  onClearFilters?: () => void;
  onRemoveFilter?: (
    key: keyof ReservationsAndCommitmentsFilters,
    tagIndexToRemove: number
  ) => void;
};

export function CoverageChartWrapper({
  anyTab,
  idText,
  serviceType,
  activeFilters = [],
  children,
  timePeriod,
  showFiltersOption,
  provider,
  offeringType,
  onSetTimePeriodClick,
  onRemoveFields = noop,
  onClearFilters = noop,
  onRemoveFilter = noop,
}: Props) {
  const showFilters = isNotEmpty(activeFilters);

  const handleRemove = useCallback(
    (itemName: string, tagIndexToRemove: number) => {
      if (itemName in filtersValues) {
        onRemoveFilter(
          itemName as keyof ReservationsAndCommitmentsFilters,
          tagIndexToRemove
        );
      }
    },
    [onRemoveFilter]
  );

  return (
    <div id={`uf-${idText}-coverage-chart`}>
      <Box
        boxStyle="lightGrey"
        px={theme.spacing.spacing03}
        py={theme.spacing.spacing02}
        spacing="spacing04"
      >
        <FlexContainer justifyContent="space-between">
          <MultipleSelect size="small">
            <SelectButton
              name="thirtydays"
              labelText="30 days"
              style={{ width: "8rem" }}
              value={LookbackPeriod.ThirtyDays}
              isActive={timePeriod === LookbackPeriod.ThirtyDays}
              onClick={() => onSetTimePeriodClick(LookbackPeriod.ThirtyDays)}
            />
            <SelectButton
              name="sixtydays"
              labelText="60 Days"
              style={{ width: "8rem" }}
              value={LookbackPeriod.SixtyDays}
              isActive={timePeriod === LookbackPeriod.SixtyDays}
              onClick={() => onSetTimePeriodClick(LookbackPeriod.SixtyDays)}
            />
          </MultipleSelect>

          <When condition={anyTab && showFiltersOption}>
            <ReservationsCommitmentsFilters
              allowSavingFilters={false}
              serviceUsageType={serviceType}
              provider={provider}
              timePeriod={timePeriod}
              offeringType={offeringType}
              onApply={noop}
            />
          </When>
        </FlexContainer>

        <When condition={showFilters && anyTab}>
          <Row>
            <Col md="12">
              <div
                css={{
                  paddingTop: theme.spacing.spacing03,
                  marginTop: theme.spacing.spacing03,
                  borderTop: `1px solid ${theme.color.border.border01}`,
                }}
              >
                <ReservationsActiveFilters
                  filters={activeFilters}
                  allowSavingFilters={false}
                  onRemoveKey={onRemoveFields}
                  onClear={onClearFilters}
                  onRemoveValue={handleRemove}
                />
              </div>
            </Col>
          </Row>
        </When>
      </Box>
      {children}
    </div>
  );
}
