/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { FlexContainer, SVGIcon, Text, theme, Tooltip } from "@nordcloud/gnui";
import { formatCarbonFootprint } from "./helpers";
import { TooltipCaption } from "./TooltipCaption";

type Co2eSavingsProps = {
  co2eSavings: number;
  iconOnly?: boolean;
  iconSize?: keyof typeof theme.iconSize;
  hideSuffix?: boolean;
  fontSize?: keyof typeof theme.fontSizes;
};

export function Co2eSavings({
  co2eSavings,
  iconOnly = false,
  iconSize = "sm",
  hideSuffix = false,
  fontSize = "sm",
}: Co2eSavingsProps) {
  const showCo2eSavings = co2eSavings !== 0;
  const isCo2eUp = co2eSavings < 0;
  const statusColor = isCo2eUp ? "danger" : "success";
  const co2eWasteIconName = isCo2eUp ? "co2Up" : "co2Down";
  const suffix = isCo2eUp ? "CO2 additional emissions" : "CO2 savings";

  return (
    <When condition={showCo2eSavings}>
      <div css={{ position: "relative", display: "flex", cursor: "pointer" }}>
        <Tooltip
          caption={TooltipCaption(co2eSavings)}
          status={statusColor}
          minWidth="11rem"
        >
          <FlexContainer gap={theme.spacing.spacing01} css={{ cursor: "help" }}>
            <SVGIcon
              data-testid="co2eWasteIcon"
              size={iconSize}
              name={co2eWasteIconName}
              color={statusColor}
            />
            <When condition={!iconOnly}>
              <Text size={fontSize} tag="div" color={statusColor}>
                {formatCarbonFootprint(co2eSavings)}
              </Text>
              <When condition={!hideSuffix}>
                <Text size={fontSize} tag="div">
                  {suffix}
                </Text>
              </When>
            </When>
          </FlexContainer>
        </Tooltip>
      </div>
    </When>
  );
}
