/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Box, theme } from "@nordcloud/gnui";
import { DataItem, DataContainer } from "~/components";

export type Props = {
  id: string;
  dataItems: {
    label: string;
    value: string;
  }[];
};

export function RecommendationData({ id, dataItems }: Props) {
  return (
    <>
      <Box boxStyle="lightGrey">
        <DataContainer marginBottom="0">
          {dataItems.map(({ label, value }, childIndex) => (
            <DataItemWrapper
              key={`${label}-${id}`}
              label={label}
              value={value}
              index={childIndex}
            />
          ))}
        </DataContainer>
      </Box>
    </>
  );
}

type DataItemWrapperProps = {
  label: string;
  value: string;
  index: number;
};

function DataItemWrapper({ label, value, index }: DataItemWrapperProps) {
  const padding =
    index === 0
      ? `0 ${theme.spacing.spacing03} 0 0`
      : `0 ${theme.spacing.spacing03}`;
  return (
    <DataItem
      label={label}
      value={value}
      size="md"
      grow={false}
      mb={theme.spacing.spacing01}
      padding={padding}
      itemWidth="33.3333%"
    />
  );
}
