/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

// taken from
// https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/billing-info-fields.html
/* eslint-disable @typescript-eslint/naming-convention */
enum UsageOperation {
  "RunInstances" = "Linux/UNIX",
  "RunInstances:00g0" = "Red Hat BYOL Linux",
  "RunInstances:0010" = "Red Hat Enterprise Linux",
  "RunInstances:1010" = "Red Hat Enterprise Linux with HA",
  "RunInstances:1014" = "Red Hat Enterprise Linux with SQL Server Standard and HA",
  "RunInstances:1110" = "Red Hat Enterprise Linux with SQL Server Enterprise and HA",
  "RunInstances:0014" = "Red Hat Enterprise Linux with SQL Server Standard",
  "RunInstances:0210" = "Red Hat Enterprise Linux with SQL Server Web",
  "RunInstances:0110" = "Red Hat Enterprise Linux with SQL Server Enterprise",
  "RunInstances:0100" = "SQL Server Enterprise",
  "RunInstances:0004" = "SQL Server Standard",
  "RunInstances:0200" = "SQL Server Web",
  "RunInstances:000g" = "SUSE Linux",
  "RunInstances:0002" = "Windows",
  "RunInstances:0800" = "Windows BYOL",
  "RunInstances:0102" = "Windows with SQL Server Enterprise *",
  "RunInstances:0006" = "Windows with SQL Server Standard *",
  "RunInstances:0202" = "Windows with SQL Server Web *",
}
/* eslint-enable @typescript-eslint/naming-convention */

export type UsageOperationType = keyof typeof UsageOperation;

const redHat = [
  "00g0",
  "0010",
  "1010",
  "1014",
  "1110",
  "0014",
  "0210",
  "0110",
] as const;
const sql = ["0100", "0004", "0200"] as const;
const suseLinux = ["000g"] as const;
const windows = ["0002", "0800", "0102", "0006", "0202"] as const;

export function getOSName(type: UsageOperationType) {
  // we need to define custom split to handle tuples with type ids
  // as built-in `split` doesn't infer types from tuples
  const id = type.split(":")[1];

  switch (true) {
    case isInTuple(id, redHat) && redHat.includes(id):
      return "Red Hat";
    case isInTuple(id, sql) && sql.includes(id):
      return "SQL";
    case isInTuple(id, suseLinux) && suseLinux.includes(id):
      return "SUSE Linux";
    case isInTuple(id, windows) && windows.includes(id):
      return "Windows";
    case id === undefined:
      return "Linux";
    default:
      throw new Error(`Cannot find specific system name for ${id}`);
  }
}

function isInTuple<T extends readonly string[]>(
  str: string,
  tuple: T
): str is T[number] {
  return tuple.includes(str);
}
