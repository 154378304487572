/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { useOrgUnitTopApplicationsCostsQuery } from "~/generated/graphql";
import {
  getTableGranularity,
  mapGranularity,
} from "~/components/Charts/CostAnalysis";
import {
  Granularity,
  Period,
  TableData,
  topSpendingGroup,
} from "~/components/Charts/CostAnalysis/types";
import { generateTopGroupData } from "~/components/Charts/CostAnalysis/utils";
import { isNotNil } from "~/tools";
import { getCostSplitGroupNames } from "~/views/applications/ApplicationCostTabs/hooks/utils";

type Props = {
  orgUnitId: string;
  period: Period;
  startDate: string;
  endDate: string;
  granularity: Granularity;
};

export function useOrgUnitCostPerApplicationChart({
  orgUnitId,
  period,
  startDate,
  endDate,
  granularity,
}: Props) {
  const { data, loading, error } = useOrgUnitTopApplicationsCostsQuery({
    variables: {
      top: topSpendingGroup.APPLICATIONS,
      orgUnitId,
      startDate,
      endDate,
      granularity: mapGranularity(granularity),
      tableGranularity: getTableGranularity(granularity, period),
    },
  });

  const orgUnitTopApplicationsCostTableTimePoints = useMemo(
    () =>
      (data?.orgUnitTopApplicationsTableCost?.timePoints ?? []).filter(
        isNotNil
      ),
    [data]
  );

  const costPerApplicationTableData: TableData[] = useMemo(
    () => generateTopGroupData(orgUnitTopApplicationsCostTableTimePoints ?? []),
    [orgUnitTopApplicationsCostTableTimePoints]
  );

  const costPerApplicationChartData = useMemo(
    () => (data?.orgUnitTopApplicationsCost?.timePoints ?? []).filter(isNotNil),
    [data]
  );

  const costPerApplicationTotal = Number(
    data?.orgUnitTopApplicationsTableCost?.total ?? "0"
  );

  const applications = getCostSplitGroupNames(costPerApplicationTableData);

  return {
    costPerApplicationChartData,
    costPerApplicationTableData,
    costPerApplicationTotal,
    loading,
    error,
    applications,
  };
}
