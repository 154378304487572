/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const enum RecalculationItemCaption {
  OUTDATED_DATA = "Changes were made. Click here to update the data. This process may take up to a couple of hours. Otherwise, IBM Multicloud Accelerator will automatically update the data within 24 hours.",
  UPDATE_IN_PROGRESS = "Updating data... This process may take a while.",
  UP_TO_DATE = "Data is up to date. IBM Multicloud Accelerator automatically updates the data every 24 hours.",
}

export const enum RecalculationItemSummary {
  OUTDATED_DATA = "Outdated Data",
  UPDATE_IN_PROGRESS = "Updating Data",
  UP_TO_DATE = "Up-to-date Data",
}
