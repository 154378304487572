/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloError } from "@apollo/client";
import { ParentSize } from "@visx/responsive";
import { TooltipData } from "@visx/xychart/lib/types/tooltip";
import styled from "styled-components";
import { Spacer, theme } from "@nordcloud/gnui";
import { ReservationsAndCommitmentsCoverageServiceUsageType } from "~/generated/graphql";
import { ErrorWrap } from "~/components";
import { UsageAnalysisChart } from "../components";
import { TimePoint, TooltipCallbackData } from "../types";
import { AzureUsageAnalysisDetails } from "./AzureUsageAnalysisDetails";
import { useGetAzureLegendData } from "./hooks";

type Props = {
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  graphLoading: boolean;
  timeSeries: TimePoint[];
  highestCost: number;
  utilisationChartError: ApolloError | undefined;
  reservedInstaceCoverage: string;
  savedCost: string;
  totalOnDemandCost: string;
  tooltipDetailCallback: (
    tooltipData: TooltipData<TimePoint>
  ) => TooltipCallbackData;
};

export function AzureUsageAnalysisGraph({
  serviceUsageType,
  graphLoading,
  timeSeries,
  highestCost,
  utilisationChartError,
  reservedInstaceCoverage,
  savedCost,
  totalOnDemandCost,
  tooltipDetailCallback,
}: Props) {
  const legendData = useGetAzureLegendData(serviceUsageType);

  return (
    <ErrorWrap error={utilisationChartError}>
      <ParentSize>
        {(parent) => (
          <UsageAnalysisChart
            width={parent.width}
            height={280}
            loading={graphLoading}
            data={timeSeries}
            highestCost={highestCost}
            legendData={legendData}
            tooltipDetailCallback={tooltipDetailCallback}
            serviceUsageType={serviceUsageType}
          />
        )}
      </ParentSize>
      <StyledSpacer />
      <AzureUsageAnalysisDetails
        serviceUsageType={serviceUsageType}
        reservedInstaceCoverage={reservedInstaceCoverage}
        savedCost={savedCost}
        totalOnDemandCost={totalOnDemandCost}
      />
    </ErrorWrap>
  );
}

const StyledSpacer = styled(Spacer)`
  margin: 0 0 ${theme.spacing.spacing04} 0;
  border-bottom: 1px solid ${theme.color.border.border01};
`;
