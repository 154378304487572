/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";
import { CollapsibleBox } from "~/components";
import { capitalize, inflect } from "~/tools";
import { getUrl } from "../../helpers";
import { Item } from "../components";

type Props = {
  platform: string;
  platformDetails: string;
  instanceType: string;
  availabilityZone: string;
  vpcId: string;
  vpcNid: string;
  subnetId: string;
  subnetNid: string;
  publicIPv4: string;
  publicElasticIPAddress: string[];
  privateElasticIPAddress: string[];
  autoScalingGroupName: string;
  firstSeen: string | undefined;
  lastSeen: string | undefined;
};

export function Ec2InstanceDetails({
  platform,
  platformDetails,
  instanceType,
  availabilityZone,
  vpcId,
  vpcNid,
  subnetId,
  subnetNid,
  publicIPv4,
  publicElasticIPAddress,
  privateElasticIPAddress,
  autoScalingGroupName,
  firstSeen,
  lastSeen,
}: Props) {
  return (
    <CollapsibleBox title="Instance Details">
      <GridWrapper>
        <Item label="Platform" value={platform} />
        <Item label="Platform Details" value={platformDetails} />
        <Item label="Instance Type" value={instanceType} />
        <Item label="Availability Zone" value={availabilityZone} />
        <Item
          label="VPC ID"
          value={vpcId}
          url={getUrl(vpcId, vpcNid, "ec2/vpc")}
        />
        <Item
          label="Subnet ID"
          value={subnetId}
          url={getUrl(subnetId, subnetNid, "ec2/subnet")}
        />
        <Item label="Public IPv4 Address" value={publicIPv4} />
        <Item
          value={publicElasticIPAddress}
          label={`Public Elastic IP ${capitalize(
            inflect("address", "addresses")(publicElasticIPAddress.length)
          )}`}
        />
        <Item
          value={privateElasticIPAddress}
          label={`Private Elastic IP ${capitalize(
            inflect("address", "addresses")(privateElasticIPAddress.length)
          )}`}
        />
        <Item
          label="Auto Scaling Group Name"
          value={autoScalingGroupName}
          url={`/estate?query="${autoScalingGroupName}"`}
        />
        <Item label="First Seen" value={firstSeen} />
        <Item label="Last Seen" value={lastSeen} />
      </GridWrapper>
    </CollapsibleBox>
  );
}

const GridWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  row-gap: ${theme.spacing.spacing02};
  column-gap: ${theme.spacing.spacing04};
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: ${theme.breakpoints.lg}px) {
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
`;
