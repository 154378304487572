/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { When } from "react-if";
import { Link } from "react-router-dom";
import { Cell } from "react-table";
import {
  SVGIcon,
  theme,
  ExtendedTooltip,
  Text,
  Button,
  FlexContainer,
} from "@nordcloud/gnui";
import { IntegrationPluginSyncStatus } from "~/generated/graphql";
import { dateFormat } from "~/constants";
import { ROUTES } from "~/routing/routes";
import { isNotEmpty, Maybe } from "~/tools";
import { useRemoveIntegrationPluginAction } from "../hooks/useRemoveIntegrationPluginAction";
import { IntegrationPluginProp } from "../models/IntegrationPlugin";

type SyncProps = {
  isStatusAvailable: boolean;
  tooltipStatus: "danger" | "success";
  captionText: string;
  icon: "statusError" | "statusSuccess";
  lastSyncStatus?: Maybe<string>;
  lastSyncTime: string;
};

const getSyncProps = (integrationPlugin: IntegrationPluginProp): SyncProps => {
  const isStatusAvailable = !!integrationPlugin.lastSyncStatus;
  const isSyncFailed =
    integrationPlugin.lastSyncStatus === IntegrationPluginSyncStatus.Failure;
  return {
    isStatusAvailable,
    tooltipStatus: isSyncFailed ? "danger" : "success",
    captionText: isSyncFailed ? "Failure" : "Success",
    icon: isSyncFailed ? "statusError" : "statusSuccess",
    lastSyncStatus: integrationPlugin.lastSyncStatus,
    lastSyncTime: integrationPlugin.lastSyncTime,
  };
};

type Props = {
  removeAction: ReturnType<typeof useRemoveIntegrationPluginAction>;
};

export function IntegrationPluginsListColumns({ removeAction }: Props) {
  return [
    {
      Header: "Integration Name / Description",
      accessor: "name",
      width: 120,
      Cell: ({ row }: Cell<IntegrationPluginProp>) => {
        const { name, description } = row.original;
        return (
          <FlexContainer
            direction="column"
            alignItems="flex-start"
            css={{ wordBreak: "break-all" }}
          >
            <Link to={`${ROUTES.integrationPlugins.index}/${row.original.id}`}>
              <Text color={theme.color.text.info} mb="0">
                {name}
              </Text>
            </Link>
            <Text size="sm" color={theme.color.text.text02} mb="0">
              {description}
            </Text>
          </FlexContainer>
        );
      },
    },
    {
      Header: "Contact Person",
      accessor: "contactPerson",
      width: 120,
      Cell: ({ row }: Cell<IntegrationPluginProp>) => {
        const { contactPersons } = row.original;
        const contactPeopleText = isNotEmpty(contactPersons)
          ? contactPersons.map((cp) => cp.name).join(", ")
          : "Contact person missing";
        return (
          <Text
            css={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            mb="0"
          >
            {contactPeopleText}
          </Text>
        );
      },
    },
    {
      Header: "Type",
      accessor: "type",
      width: 120,
      Cell: ({ row }: Cell<IntegrationPluginProp>) => {
        return <Text>{row.original.integrationType}</Text>;
      },
    },
    {
      Header: "Last Sync Status / Time",
      accessor: "lastSyncStatusAndTime",
      width: 180,
      Cell: ({ row }: Cell<IntegrationPluginProp>) => {
        const sync = getSyncProps(row.original);
        const caption = (
          <Text size="sm" tag="div" color={theme.colors.white}>
            {sync.captionText}
          </Text>
        );

        return (
          <When condition={sync.isStatusAvailable}>
            <ExtendedTooltip status={sync.tooltipStatus} caption={caption}>
              <FlexContainer alignItems="center">
                <SVGIcon name={sync.icon} />
              </FlexContainer>
            </ExtendedTooltip>
            <Text nowrap mb="0" pl={theme.spacing.spacing01}>
              {dayjs(sync.lastSyncTime).format(dateFormat.fullDateShort)}
            </Text>
          </When>
        );
      },
    },
    {
      accessor: "actions",
      disableSortBy: true,
      width: 20,
      align: "right",
      Cell: ({ row }: Cell<IntegrationPluginProp>) => (
        <Button
          data-testid="delete-btn"
          icon="trash"
          severity="low"
          onClick={() => removeAction.onClick(row.original)}
          size="md"
        />
      ),
    },
  ];
}
