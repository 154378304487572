/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useMemo, useReducer, useState } from "react";
import { When } from "react-if";
import { Box, Button, ModalConfirm } from "@nordcloud/gnui";
import { useThresholdsQuery } from "~/generated/graphql";
import {
  BreadcrumbsBox,
  BrickLoader,
  DataDisplayWrapper,
  NoData,
  Pagination,
  ReactTableV2,
  UniversalWrap,
} from "~/components";
import { useDisclosure, useQueryState, useCurrency } from "~/hooks";
import { isNil, generateActionConfirmText } from "~/tools";
import { ThresholdSidebar } from "./AddThreshold/ThresholdSidebar";
import { ACTION, initialState, reducer } from "./thresholdActionsReducer";
import { ThresholdsListColumns } from "./ThresholdsListColumns";
import {
  Threshold,
  ThresholdSortField,
  ThresholdSortOrder,
  ThresholdsQueryState,
} from "./types";
import { useThresholdRemove } from "./useThresholdRemove";
import { useThresholdsSort } from "./useThresholdsSort";

export function ThresholdsPage() {
  const { open, close, isOpen } = useDisclosure();
  const { currency } = useCurrency();

  const { state, updateQueryState } = useQueryState<ThresholdsQueryState>();
  const { column, order, limit, page } = state;

  const { defaultSort, onSort } = useThresholdsSort();

  const [thresholds, setThresholds] = useState<Threshold[]>([]);

  const {
    data,
    loading: isLoading,
    error,
  } = useThresholdsQuery({
    variables: {
      sorting: {
        field: getColumnEnumValue(column ?? "createdDate"),
        order: order ?? ThresholdSortOrder.Desc,
      },
    },
  });

  useEffect(() => {
    if (!isLoading && isNil(column) && isNil(order)) {
      updateQueryState({
        column: "createdDate",
        order: ThresholdSortOrder.Desc,
      });
    }
  }, [isLoading, column, order, updateQueryState]);

  useEffect(() => {
    setThresholds(
      data?.thresholds.slice(page * limit, (page + 1) * limit) ?? []
    );
  }, [limit, page, order, data]);

  const [thresholdActionsState, dispatch] = useReducer(reducer, initialState);

  const closeModal = () => {
    dispatch({ type: ACTION.CLOSE_MODAL });
  };

  const { onDelete, onRemoveHandler } = useThresholdRemove(
    dispatch,
    thresholdActionsState,
    closeModal
  );

  const columns = useMemo(
    () => ThresholdsListColumns({ currency, onRemoveHandler }),
    [currency, onRemoveHandler]
  );

  const thresholdCount = data?.thresholds.length ?? 0;

  return (
    <>
      <BreadcrumbsBox title="Thresholds" labels={BREADCRUMB_LABELS}>
        <Button icon="plus" onClick={open}>
          Add New Threshold
        </Button>
      </BreadcrumbsBox>
      <UniversalWrap
        errorProps={{ error }}
        loaderProps={{
          loading: isLoading,
          Component: BrickLoader,
        }}
      >
        <DataDisplayWrapper
          hasData={thresholdCount > 0}
          renderNoData={(message) => (
            <Box>
              <NoData message={message} />
            </Box>
          )}
        >
          <Box spacing="spacing04">
            <ReactTableV2
              striped
              sort
              columns={columns}
              data={thresholds || []}
              defaultSort={defaultSort}
              onSort={onSort}
            />
            <When condition={thresholdCount > 0}>
              <Pagination count={thresholdCount} />
            </When>
          </Box>
        </DataDisplayWrapper>
        <ModalConfirm
          isOpen={thresholdActionsState.isModalConfirmOpen}
          confirm={onDelete}
          actionLabel="Delete"
          contentLabel="Delete Threshold"
          onClose={closeModal}
        >
          {generateActionConfirmText(
            thresholdActionsState.tempThresholdData.name ?? ""
          )("delete")()}
        </ModalConfirm>
      </UniversalWrap>
      <ThresholdSidebar
        sidebarState={{
          title: "Add New Threshold",
          isOpen,
          onClick: close,
        }}
      />
    </>
  );
}

const BREADCRUMB_LABELS = [{ value: "thresholds", label: "Thresholds" }];

function getColumnEnumValue(
  column: "amount" | "createdDate" | "name" | (string & {})
) {
  switch (column) {
    case "name":
      return ThresholdSortField.Name;
    case "createdDate":
      return ThresholdSortField.CreatedDate;
    case "amount":
      return ThresholdSortField.Amount;
    default:
      return ThresholdSortField.CreatedDate;
  }
}
