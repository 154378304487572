/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { Provider } from "~/generated/graphql";

const excludedProviders = [
  Provider.Hybrid,
  Provider.External,
  Provider.Nordcloud,
  Provider.Services,
];

type Props = {
  selectedProviders: Provider[];
  onProviderSelect: (provider: Provider[]) => void;
};

export function useProvidersFilter({
  selectedProviders,
  onProviderSelect,
}: Props) {
  const [selectedItems, setSelectedItems] = useState(selectedProviders);
  const [search, setSearch] = useState("");
  const [filteredProviders, setFilteredProviders] = useState<Provider[]>([]);

  const providersList = Object.values(Provider).filter(
    (provider) => !excludedProviders.includes(provider)
  );

  useEffect(() => {
    setSelectedItems(selectedProviders);
  }, [selectedProviders]);

  useEffect(() => {
    setFilteredProviders(
      providersList.filter((provider) =>
        provider.toLowerCase().includes(search)
      )
    );
  }, [search]);

  const onApply = (item: Provider) => {
    if (selectedItems.includes(item)) {
      setSelectedItems((prev) =>
        prev.filter((storedItem) => storedItem !== item)
      );
    } else {
      setSelectedItems((prev) => [...prev, item]);
    }
  };

  useEffect(() => {
    onProviderSelect(selectedItems ?? []);
  }, [selectedItems]);

  return {
    selectedItems,
    filteredProviders,
    setSearch,
    onApply,
  };
}
