/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Link } from "react-router-dom";
import {
  FlexContainer,
  Message,
  Spacer,
  theme,
  Text,
  Button,
} from "@nordcloud/gnui";

type Props = {
  link: string;
};

export function BudgetSetupNotification({ link }: Props) {
  return (
    <>
      <Message status="notification" image="info">
        <FlexContainer direction="column" alignItems="start">
          <Text
            weight="medium"
            color={theme.color.text.info}
            mb={theme.spacing.spacing04}
          >
            No budget set for one or more months.
          </Text>
          <Link
            to={link}
            css={{
              "&:hover": {
                textDecoration: "none",
              },
            }}
          >
            <Button as="span" size="md" color="notification">
              Create New Budget
            </Button>
          </Link>
        </FlexContainer>
      </Message>
      <Spacer height={theme.spacing.spacing02} />
    </>
  );
}
