/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback } from "react";
import { ReservationsAndCommitmentsFilters } from "~/generated/graphql";
import { ExpandableTag } from "~/components";
import { QueryStateFilterValue } from "~/hooks";
import { noop } from "~/tools";

type Props = {
  keyToRemove: keyof ReservationsAndCommitmentsFilters;
  value: QueryStateFilterValue;
  onRemoveKey: (key: (keyof ReservationsAndCommitmentsFilters)[]) => void;
  onRemoveValue?: (key: string, tagIndexToRemove: number) => void;
};

export function FilterExpandableTag({
  keyToRemove,
  value,
  onRemoveKey,
  onRemoveValue,
}: Props) {
  const handleRemoveKey = useCallback(
    () => onRemoveKey([keyToRemove]),
    [keyToRemove, onRemoveKey]
  );

  return (
    <ExpandableTag
      name={keyToRemove}
      value={value}
      onRemoveKey={handleRemoveKey}
      onRemoveValue={onRemoveValue ?? noop}
    />
  );
}
