/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  AwsBillingDataDocument,
  AwsBillingDataQuery,
  AwsBillingDataQueryVariables,
  useAddAwsBillingConfigMutation,
} from "~/generated/graphql";
import { ERROR_TEXT } from "~/constants";
import { isNotNil } from "~/tools";
import { FormData } from "~/views/cloudAccounts/BillingData/aws/validators";

export function useAddAwsBillingData() {
  const [addAwsBillingDataConfig, { loading, error, client }] =
    useAddAwsBillingConfigMutation();

  const addAwsBillingData = async (data: FormData) => {
    const response = await addAwsBillingDataConfig({
      variables: { input: data },
    });
    const addedItem = response.data?.addAWSBillingConfigV2;

    if (isNotNil(addedItem)) {
      client.cache.updateQuery<
        AwsBillingDataQuery,
        AwsBillingDataQueryVariables
      >(
        {
          query: AwsBillingDataDocument,
        },
        (billingData) => ({
          awsBillingData: [...(billingData?.awsBillingData ?? []), addedItem],
        })
      );

      return;
    }

    throw new Error(ERROR_TEXT.default);
  };

  return { addAwsBillingData, loading, error };
}
