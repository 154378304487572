/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Text, Spacer, theme } from "@nordcloud/gnui";
import { LoaderWrap } from "~/components";
import { isLastIndex } from "~/tools";
import { ComputeSavingsProps } from "../types";
import { CommitmentContainer } from "./CommitmentContainer";

type Props = {
  savingsPlanData: ComputeSavingsProps;
  commitmentLabel: string;
};

export function ReservationCommitments({
  savingsPlanData,
  commitmentLabel,
}: Props) {
  const { loading, savingsPlansCommitments } = savingsPlanData;

  return (
    <>
      <Spacer height={theme.spacing.spacing06} />
      <Text weight="medium" mb={theme.spacing.spacing03}>
        {commitmentLabel}
      </Text>
      <LoaderWrap loading={loading} inContent>
        {savingsPlansCommitments.map((commitmentsData, index) => (
          <CommitmentContainer
            key={`${index}-commitment`}
            savingsPlanData={commitmentsData}
            withSpacing={!isLastIndex(savingsPlansCommitments, index)}
          />
        ))}
      </LoaderWrap>
    </>
  );
}
