/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState, ChangeEvent, useMemo } from "react";
import { Else, If, Then } from "react-if";
import { JSONTree } from "react-json-tree";
import { useDebounce } from "react-use";
import {
  Box,
  Button,
  FlexContainer,
  Input,
  Spacer,
  Switch,
  Text,
  theme,
} from "@nordcloud/gnui";
import { NoData } from "~/components";
import { useToggle } from "~/hooks";
import { isEmpty, isNotEmpty } from "~/tools";
import { generateDataUrl } from "../../utils";
import { Metadata } from "../DefaultEc2Components";

type Props = {
  metadata: Metadata[];
  nid: string;
};

export function Ec2AdvancedData({ metadata, nid }: Props) {
  const [search, setSearch] = useState("");
  const [items, setItems] = useState<Record<string, Value>>({});

  const [expandAll, toggleExpandAll] = useToggle(true);
  const [searchByKeys, toggleSearchByKeys] = useToggle(true);
  const [searchByValues, toggleSearchByValues] = useToggle(true);
  const [caseSensitive, toggleCaseSensitive] = useToggle(true);

  const metadataObject = useMemo(() => parseMetadataToObject(metadata), []);

  useDebounce(
    () => {
      if (search.length !== 1) {
        setItems(
          searchThrough(metadataObject, search, {
            searchByKeys,
            searchByValues,
            caseSensitive,
          })
        );
      }
    },
    450,
    [search, searchByKeys, searchByValues, caseSensitive]
  );

  const hasEntries = useMemo(() => isNotEmpty(Object.keys(items)), [items]);

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <>
      <Box boxStyle="lightGrey">
        <FlexContainer columnGap={theme.spacing.spacing03}>
          <Input placeholder="Search..." onChange={onSearchChange} />
          <Button
            severity="low"
            icon={expandAll ? "collapse" : "expand"}
            onClick={toggleExpandAll}
          >
            {`${expandAll ? "Collapse" : "Expand"} All`}
          </Button>
          <Button
            // @ts-expect-error Button should be enhanced with download attribute;
            download={`ec2-metadata-${nid}.json`}
            icon="download"
            severity="low"
            linkTo={generateDataUrl(metadataObject)}
          >
            Download JSON
          </Button>
        </FlexContainer>
        <Spacer height={theme.spacing.spacing04} />
        <FlexContainer columnGap={theme.spacing.spacing04} justifyContent="end">
          <Switch
            labelText="Search by Keys"
            checked={searchByKeys}
            onChange={toggleSearchByKeys}
          />
          <Switch
            labelText="Search by Values"
            checked={searchByValues}
            onChange={toggleSearchByValues}
          />
          <Switch
            labelText="Case Sensitive"
            checked={caseSensitive}
            onChange={toggleCaseSensitive}
          />
        </FlexContainer>
      </Box>
      <Spacer height={theme.spacing.spacing04} />
      <If condition={hasEntries}>
        <Then>
          <JSONTree
            data={items}
            hideRoot
            shouldExpandNode={() => expandAll}
            theme={{
              tree: ({ style }) => ({
                style: { ...style, backgroundColor: undefined },
              }),
            }}
          />
        </Then>
        <Else>
          <NoData
            message={
              <FlexContainer columnGap={theme.spacing.spacing01}>
                <Text mb={0}>No entries found in the JSON for </Text>
                <Text weight="bold">{search}</Text>
              </FlexContainer>
            }
          />
        </Else>
      </If>
    </>
  );
}

function parseMetadataToObject(data: Metadata[]): Record<string, Value> {
  return Object.fromEntries(
    data.map((item) => [item.label, item.value as Value])
  );
}

type Value =
  | string
  | { [key: string]: Value }
  | { [key: string]: Value }[]
  | undefined
  | null
  | boolean
  | number;

function searchThrough(
  data: Record<string, Value>,
  predicate: string,
  config: {
    searchByKeys: boolean;
    searchByValues: boolean;
    caseSensitive: boolean;
  }
): Record<string, Value> {
  if (isEmpty(predicate)) {
    return data;
  }

  return Object.fromEntries(
    Object.entries(data)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return [
            key,
            value
              .map((val) => {
                if (typeof val === "object" && val) {
                  const result = searchThrough(val, predicate, config);
                  return isNotEmpty(Object.keys(result)) ? result : undefined;
                }

                return hasPredicate(val, predicate, config.caseSensitive)
                  ? val
                  : undefined;
              })
              .filter((v) => typeof v !== "undefined"),
          ];
        }

        if (typeof value === "object" && value) {
          return [key, searchThrough(value, predicate, config)];
        }

        const hasKeyMatch =
          config.searchByKeys &&
          hasPredicate(key, predicate, config.caseSensitive);

        const hasValueMatch =
          config.searchByValues &&
          hasPredicate(value, predicate, config.caseSensitive);

        const isMatch = hasKeyMatch || hasValueMatch;

        return isMatch ? [key, value] : [key, undefined];
      })
      .filter(([, value]) => typeof value !== "undefined")
      .filter(([, value]) => {
        if (typeof value === "object") {
          if (value === null) {
            return hasPredicate(value, predicate, config.caseSensitive);
          }
          return isNotEmpty(Object.keys(value ?? {}));
        }
        return true;
      })
  );
}

function hasPredicate(val: Value, predicate: string, caseSensitive: boolean) {
  if (caseSensitive) {
    return String(val).includes(predicate);
  }

  return String(val)
    .toLocaleLowerCase()
    .includes(predicate.toLocaleLowerCase());
}
