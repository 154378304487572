/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import {
  Button,
  FlexContainer,
  Input,
  Label,
  ListLoader,
  Sidebar,
  theme,
  Text,
} from "@nordcloud/gnui";
import {
  FormGroup,
  UniversalWrap,
  TreeSelectorGrouped,
  OrgUnitsByGroups,
} from "~/components";
import { useDisclosure } from "~/hooks";
import { useOrgUnitsFields } from "./useOrgUnitsFields";

const title = "Select Organizational Units";

type Props = {
  disabled: boolean;
  onChange: (state: string[]) => void;
  initialOrgUnits?: string[];
  treeBehaviour?: "biDirectional" | "parentToChild";
};

export function OrgUnitsField({
  disabled,
  onChange,
  initialOrgUnits,
  treeBehaviour = "biDirectional",
}: Props) {
  const { isOpen, open, close } = useDisclosure();

  const {
    apiState: { loading, error },
    tree: { handlers, state },
    groups,
    text: { buttonText, inputText },
    orgUnitsByGroups,
  } = useOrgUnitsFields({
    initialOrgUnits,
    behaviour: treeBehaviour,
    disabled,
  });

  function handleSave() {
    onChange(state.rawSelectedIds);
    close();
  }

  function handleClose() {
    handlers.reset();
    close();
  }

  return (
    <FormGroup>
      <Label name={title} htmlFor="orgUnits" />
      <Input
        readOnly
        title={title}
        value={inputText}
        placeholder="Select Organization Units"
        icon="sidebar"
        css={{ cursor: "pointer" }}
        id="orgUnits"
        onClick={open}
      />
      <Sidebar
        isOpen={isOpen}
        title={title}
        width="50rem"
        contentStyles={{ padding: "0" }}
        footer={
          <When condition={!disabled}>
            <FlexContainer
              direction="column"
              alignItems="flex-start"
              css={{ width: "100%" }}
              p={theme.spacing.spacing04}
            >
              <Text mb={theme.spacing.spacing02} size="sm">
                {buttonText}
              </Text>
              <FlexContainer columnGap={theme.spacing.spacing04}>
                <Button
                  px={theme.spacing.spacing06}
                  type="button"
                  onClick={handleSave}
                >
                  Apply
                </Button>
                <Button
                  severity="medium"
                  type="button"
                  onClick={handlers.handleDeselectAll}
                >
                  Clear
                </Button>
              </FlexContainer>
            </FlexContainer>
          </When>
        }
        onClick={handleClose}
      >
        <UniversalWrap
          loaderProps={{
            loading,
            Component: ListLoader,
          }}
          errorProps={{
            error,
          }}
        >
          <TreeSelectorGrouped
            title="List of Organizational Units"
            disabled={disabled}
            treeHandlers={handlers}
            treeState={state}
            treeGroups={groups}
            orgUnitsByGroups={orgUnitsByGroups as OrgUnitsByGroups}
          />
        </UniversalWrap>
      </Sidebar>
    </FormGroup>
  );
}
