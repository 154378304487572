/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Detail, DetailData } from "~/components/Details/Detail";
import { getFirstItem, isNotNil } from "~/tools";
import { LinkedResource } from "~/views/estate/EstateDetailsPage/components/ReallocatedCostsSidebar";
import { getCloudResource } from "~/views/estate/EstateDetailsPage/utils";

type SyntheticDetailsProp = {
  linkedResources: LinkedResource[];
};

export function SyntheticDetails({ linkedResources }: SyntheticDetailsProp) {
  const syntheticRecords = linkedResources
    ?.map(({ sourceRecord }) => getCloudResource(sourceRecord))
    .filter(isNotNil);

  const syntheticRecord = getFirstItem(syntheticRecords);

  const pvDetail: DetailData = {
    label: "Linked Resource",
    value: syntheticRecord?.name,
    target: syntheticRecord?.id,
  };

  return (
    <>
      <Detail key={pvDetail.label} detail={pvDetail} />
    </>
  );
}
