/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { useGetApplicationTopEnvironmentsCostsQuery } from "~/generated/graphql";
import {
  mapGranularity,
  getTableGranularity,
  TableData,
  Granularity,
  Period,
  topSpendingGroup,
  generateTopGroupData,
} from "~/components";
import { isNotNil } from "~/tools";
import { getCostSplitGroupNames } from "./utils";

type Props = {
  appId: string;
  period: Period;
  startDate: string;
  endDate: string;
  granularity: Granularity;
};

export function useApplicationEnvironmentCostChart({
  appId,
  period,
  startDate,
  endDate,
  granularity,
}: Props) {
  const { data, loading, error } = useGetApplicationTopEnvironmentsCostsQuery({
    variables: {
      top: topSpendingGroup.ENVIRONMENTS,
      applicationId: appId,
      startDate,
      endDate,
      granularity: mapGranularity(granularity),
      tableGranularity: getTableGranularity(granularity, period),
    },
  });

  const applicationTopEnvironmentsCostTableTimePoints = useMemo(
    () =>
      (data?.applicationTopEnvironmentsTableCost.timePoints ?? []).filter(
        isNotNil
      ),
    [data?.applicationTopEnvironmentsTableCost]
  );

  const costPerEnvironmentTableData: TableData[] = useMemo(
    () =>
      generateTopGroupData(applicationTopEnvironmentsCostTableTimePoints ?? []),
    [applicationTopEnvironmentsCostTableTimePoints]
  );

  const applicationTopEnvironmentsCostChartData = useMemo(
    () =>
      (data?.applicationTopEnvironmentsCost?.timePoints ?? []).filter(isNotNil),
    [data]
  );

  const applicationTopEnvironmentsCostTotal = useMemo(
    () => Number(data?.applicationTopEnvironmentsTableCost?.total ?? 0),
    [data]
  );

  const environments = getCostSplitGroupNames(costPerEnvironmentTableData);

  return {
    applicationTopEnvironmentsCostChartData,
    costPerEnvironmentTableData,
    applicationTopEnvironmentsCostTotal,
    loading,
    error,
    environments,
  };
}
