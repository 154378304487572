/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FiltersProp } from "~/models/estateRecordRow";

export enum ACTION {
  CLEAR = "CLEAR",
  UPDATE = "UPDATE",
}

type Action =
  | {
      type: ACTION.CLEAR;
    }
  | {
      type: ACTION.UPDATE;
      payload: {
        filters: FiltersProp;
      };
    };

export const reducer = (state: FiltersProp, action: Action): FiltersProp => {
  switch (action.type) {
    case ACTION.CLEAR:
      return {};

    case ACTION.UPDATE:
      return {
        ...state,
        ...action.payload.filters,
      };

    default:
      throw new Error(`Unknown action: ${action}`);
  }
};
