/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { UseFormRegister } from "react-hook-form";
import { Textarea } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { SlackFields } from "../../AddWorkflow/constants";
import { WorkflowDataType } from "../../constants";
import { WorkflowFormData } from "../../types";
import { MessageMarkersLabel } from "../MessageMarkersLabel";
import { SlackErrors } from "./types";

type Props = {
  selectDataComponent?: WorkflowDataType;
  isReadMode?: boolean;
  slackErrors?: SlackErrors;
  register: UseFormRegister<WorkflowFormData>;
};

export function RegularMessage({
  isReadMode,
  selectDataComponent,
  slackErrors,
  register,
}: Props) {
  return (
    <>
      <MessageMarkersLabel
        type={selectDataComponent}
        name="Message"
        required={!isReadMode}
      />
      <FormGroup error={slackErrors?.[SlackFields.MESSAGE]}>
        <Textarea
          disabled={isReadMode}
          id="slackMessage"
          placeholder="Slack message"
          {...register("slack.message")}
          css={{ height: "5rem" }}
        />
      </FormGroup>
    </>
  );
}
