/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Cell } from "react-table";
import { EstateRecordRow } from "~/models/estateRecordRow";
import { ProviderIcons } from "./ProviderIcons";

export const PROVIDER_ICON_COLUMN = {
  accessor: "provider",
  disableSortBy: true,
  width: "3%",
  Cell: ({ row }: Cell<EstateRecordRow>) => {
    const { provider, type } = row.original;
    return <ProviderIcons provider={provider} type={type} />;
  },
};
