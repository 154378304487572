/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import styled from "styled-components";
import { FlexContainer, Text, theme } from "@nordcloud/gnui";
import { SolidLine } from "~/components/Styled/SolidLine";

type Props = {
  hasStartSaving: boolean;
};
export function BreakevenLegend({ hasStartSaving }: Props) {
  return (
    <FlexContainer alignContent="flex-start">
      <FlexContainer alignContent="center">
        <SolidLine color={theme.color.text.text01} />
        <Text color={theme.color.text.text02} size="xs" m="0">
          On-Demand Cost
        </Text>
        <SolidLine color={theme.color.support.cyan} />
        <Text color={theme.color.text.text02} size="xs" m="0">
          RI Cost
        </Text>
        <When condition={hasStartSaving}>
          <Point />
          <Text color={theme.color.text.text02} size="xs" m="0">
            Start Saving
          </Text>
        </When>
      </FlexContainer>
    </FlexContainer>
  );
}

const Point = styled.span`
  width: 10px;
  background-color: ${theme.color.support.cyan};
  height: 10px;
  margin-right: ${theme.spacing.spacing02};
  margin-left: ${theme.spacing.spacing04};
  border-radius: 50%;
`;
