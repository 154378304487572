/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FieldError, useFormContext } from "react-hook-form";
import { When } from "react-if";
import { Input, Label, Text, theme } from "@nordcloud/gnui";
import { FormGroup, LinkButton } from "~/components";
import { ContactsSelector } from "~/components/Inputs/ContactsSelector/ContactsSelector";
import { useToggle } from "~/hooks";
import { AdditionalSettingsFields } from "~/views/cloudAccounts/types";

export function AdditionalCloudAccountSettings() {
  const {
    setValue,
    register,
    formState: { errors },
  } = useFormContext();

  const [showAdditionalSettings, toggleShowAdditionalSettings] =
    useToggle(false);

  return (
    <>
      <LinkButton type="button" onClick={toggleShowAdditionalSettings}>
        <Text my={0} size="sm" color={theme.color.text.info}>
          {showAdditionalSettings
            ? "- Hide additional settings"
            : "+ Show additional settings"}
        </Text>
      </LinkButton>
      <When condition={showAdditionalSettings}>
        <FormGroup
          error={errors[AdditionalSettingsFields.DISPLAY_NAME] as FieldError}
          customCss={{ marginBottom: 0 }}
        >
          <Label
            htmlFor={AdditionalSettingsFields.DISPLAY_NAME}
            name="Display Name"
          />
          <Input
            placeholder="Display Name"
            {...register(AdditionalSettingsFields.DISPLAY_NAME)}
          />
        </FormGroup>
        <FormGroup
          error={errors[AdditionalSettingsFields.DESCRIPTION] as FieldError}
          customCss={{ marginBottom: 0 }}
        >
          <Label
            htmlFor={AdditionalSettingsFields.DESCRIPTION}
            name="Account Description"
          />
          <Input
            placeholder="Account Description"
            {...register(AdditionalSettingsFields.DESCRIPTION)}
          />
        </FormGroup>
        <FormGroup
          customCss={{ marginBottom: 0 }}
          error={
            (errors[AdditionalSettingsFields.CONTACT_PERSON] as FieldError) ??
            []
          }
        >
          <Label
            htmlFor={AdditionalSettingsFields.CONTACT_PERSON}
            name="Contact Person"
          />
          <ContactsSelector
            onChange={(selectedContact) => {
              setValue(
                AdditionalSettingsFields.CONTACT_PERSON,
                selectedContact
              );
            }}
          />
        </FormGroup>
      </When>
    </>
  );
}
