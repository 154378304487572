/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useSetEnvironmentYearlyBudgetMutation } from "~/generated/graphql";
import { showSuccess } from "~/services/toast";
import { generateActionSuccessText, handleError, toInputError } from "~/tools";

export function useEditBudget() {
  const [editEnvironmentBudgetMutation, state] =
    useSetEnvironmentYearlyBudgetMutation();

  const update = async ({ nid, budgetByMonth, year, currency }: EditProps) => {
    try {
      await editEnvironmentBudgetMutation({
        variables: {
          nid,
          budget: {
            budgetByMonth,
            year,
            currency,
          },
        },
      });

      showSuccess(generateActionSuccessText("Budget")()("updated")());
    } catch (error) {
      handleError(toInputError(error));
    }
  };

  return [update, state] as const;
}

type EditProps = {
  nid: string;
  year: string;
  budgetByMonth: number[];
  currency: string;
};
