/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { UnpackNestedValue } from "react-hook-form";
import { WorkflowDataType, WorkflowFrequency } from "~/generated/graphql";
import { isNotEmpty, isNotNil } from "~/tools";
import { EmailFormData, SlackFormData, WorkflowFormData } from "./types";

export function getServiceNowInput(
  serviceNow: UnpackNestedValue<WorkflowFormData>["serviceNow"]
) {
  return {
    url: serviceNow.url,
    username: serviceNow.username,
    password: serviceNow.password,
    description: serviceNow.description,
    shortDescription: serviceNow.shortDescription,
    additionalFields: isNotEmpty(serviceNow.additionalMessageFields)
      ? serviceNow.additionalMessageFields
      : undefined,
    costsOptions: isNotNil(serviceNow.costsOptions)
      ? {
          totalMonthlyCost: serviceNow.costsOptions.totalMonthlyCost ?? false,
          totalMonthlyForecast:
            serviceNow.costsOptions.totalMonthlyForecast ?? false,
          costsByProviders: serviceNow.costsOptions.costsByProviders ?? false,
          costsByCategories: serviceNow.costsOptions.costsByCategories ?? false,
        }
      : null,
    kpiOptions: isNotNil(serviceNow.kpiOptions)
      ? {
          costAllocation: serviceNow.kpiOptions.costAllocation ?? false,
          nonProdHours: serviceNow.kpiOptions.nonProdHours ?? false,
          overallWaste: serviceNow.kpiOptions.overallWaste ?? false,
        }
      : null,
    savingSuggestionsOptions: isNotNil(serviceNow.savingSuggestionsOptions)
      ? {
          totalMonthlySavings:
            serviceNow.savingSuggestionsOptions.totalMonthlySavings ?? false,
          monthlySavingsByPolicies:
            serviceNow.savingSuggestionsOptions.monthlySavingsByPolicies ??
            false,
          monthlySavingsByProviders:
            serviceNow.savingSuggestionsOptions.monthlySavingsByProviders ??
            false,
          monthlySavingsByCategories:
            serviceNow.savingSuggestionsOptions.monthlySavingsByCategories ??
            false,
        }
      : null,
  };
}

export function getEmailInput(email: EmailFormData) {
  return {
    mailTo: email.mailTo
      ? email.mailTo?.split(";").map((mail) => mail.replaceAll(/\s/g, ""))
      : undefined,
    sendToContactPersons: email?.sendToContactPersons,
    subject: email.subject,
    message: email.message,
    messageType: email.messageType,
  };
}

export function getSlackInput(slack: SlackFormData) {
  return {
    token: slack.token,
    channel: slack.channel,
    message: slack.message ?? "",
    costsOptions: slack.costsOptions
      ? {
          totalMonthlyCost: slack.costsOptions.totalMonthlyCost ?? false,
          totalMonthlyForecast:
            slack.costsOptions.totalMonthlyForecast ?? false,
          costsByProviders: slack.costsOptions.costsByProviders ?? false,
          costsByCategories: slack.costsOptions.costsByCategories ?? false,
        }
      : null,
    kpiOptions: slack.kpiOptions
      ? {
          costAllocation: slack.kpiOptions.costAllocation ?? false,
          overallWaste: slack.kpiOptions.overallWaste ?? false,
          nonProdHours: slack.kpiOptions.nonProdHours ?? false,
        }
      : null,
    savingSuggestionsOptions: slack.savingSuggestionsOptions
      ? {
          totalMonthlySavings:
            slack.savingSuggestionsOptions.totalMonthlySavings ?? false,
          monthlySavingsByProviders:
            slack.savingSuggestionsOptions.monthlySavingsByProviders ?? false,
          monthlySavingsByCategories:
            slack.savingSuggestionsOptions.monthlySavingsByCategories ?? false,
          monthlySavingsByPolicies:
            slack.savingSuggestionsOptions.monthlySavingsByPolicies ?? false,
        }
      : null,
  };
}

function mapWeekdays(weekdays: { selected: boolean }[]) {
  return weekdays.reduce<number[]>(
    (acc, { selected }, index) => (selected ? [...acc, index] : acc),
    []
  );
}

export function mapSchedulerSettings(
  scheduler: UnpackNestedValue<WorkflowFormData>["scheduler"]
) {
  const [hour, minute] = scheduler.executionTime.split(":").map(Number);

  const shouldRepeat = scheduler.repeat;

  const getDayAfterStartDate = (startDate: string) =>
    dayjs(startDate).add(1, "day").startOf("day").utc().format();
  const getUserDefinedEndDate = (endDate: string) =>
    endDate === "" ? undefined : dayjs(endDate).startOf("day").utc().format();

  return {
    startDate: dayjs(scheduler.startDate)
      .hour(hour)
      .minute(minute)
      .utc()
      .format(),
    frequency: shouldRepeat ? scheduler.frequency : WorkflowFrequency.Daily,
    byWeekday:
      shouldRepeat && scheduler.frequency === WorkflowFrequency.Weekly
        ? mapWeekdays(scheduler.weekdays)
        : undefined,
    endDate: shouldRepeat
      ? getUserDefinedEndDate(scheduler.endDate)
      : getDayAfterStartDate(scheduler.startDate),
    interval: shouldRepeat ? scheduler.interval : undefined,
  };
}

export function mapSelectData({
  savingsSuggestions,
  selectDataComponent,
  kpiOptions,
  costsOptions,
  budgetsOptions,
  anomalyCostsOptions,
}: Pick<
  UnpackNestedValue<WorkflowFormData>,
  | "anomalyCostsOptions"
  | "budgetsOptions"
  | "costsOptions"
  | "kpiOptions"
  | "savingsSuggestions"
  | "selectDataComponent"
>) {
  switch (selectDataComponent) {
    case "SAVING_SUGGESTIONS":
      return {
        type: WorkflowDataType.SavingSuggestions,
        savingSuggestionsOptions: savingsSuggestions,
      };
    case "UNALLOCATED_RESOURCES":
      return {
        type: WorkflowDataType.UnallocatedResources,
      };
    case "CLOUD_CREDENTIALS":
      return {
        type: WorkflowDataType.CloudCredentials,
      };
    case "KPI":
      return {
        type: WorkflowDataType.Kpi,
        kpiOptions: {
          businessContextId: kpiOptions?.businessContextId ?? "",
        },
      };
    case "COSTS":
      return {
        type: WorkflowDataType.Costs,
        costsOptions,
      };
    case "BUDGETS":
      return {
        type: WorkflowDataType.Budgets,
        budgetsOptions,
      };
    case "ANOMALY_COSTS":
      return {
        type: WorkflowDataType.AnomalyCosts,
        anomalyCostsOptions: {
          applicationIds: anomalyCostsOptions.applications,
        },
      };
    default:
      throw new Error(
        `Unknown select data component type "${
          selectDataComponent ?? "undefined"
        }"`
      );
  }
}
