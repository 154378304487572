/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as queryString from "query-string";
import { Else, If, Then } from "react-if";
import { Link } from "react-router-dom";
import {
  Box,
  BrickLoader,
  Button,
  FlexContainer,
  Message,
  Text,
  theme,
} from "@nordcloud/gnui";
import { UniversalWrap } from "~/components";
import { ROUTES } from "~/routing/routes";
import { getFirstItem, getOSName, UsageOperationType } from "~/tools";
import { useReservedInstancesRecommendations } from "./hooks";

type Props = {
  usageOperation: UsageOperationType;
  region: string;
  instanceType: string;
};

export function ReservedInstancesRecommendations({
  usageOperation,
  region,
  instanceType,
}: Props) {
  const resourceType = getFirstItem(instanceType.split("."));
  const platform = getOSName(usageOperation);

  const { hasRecommendations, resultPageNumber, limit, loading, error } =
    useReservedInstancesRecommendations({
      platform,
      resourceType,
      region,
    });

  const getCheckRecommendationLink = () => {
    const params = {
      instanceFamily: [resourceType],
      platform: [platform],
      region: [region],
      page: resultPageNumber ?? 0,
      limit,
    };

    return `${ROUTES.reservedInstances.index}?${queryString.stringify(params, {
      arrayFormat: "index",
    })}`;
  };

  return (
    <UniversalWrap
      loaderProps={{ loading, Component: BrickLoader }}
      errorProps={{ error }}
    >
      <Text isTitle weight="medium" as="header">
        Reserved Instances Recommendations
      </Text>
      <If condition={hasRecommendations}>
        <Then>
          <Box boxStyle="lightGrey">
            <FlexContainer justifyContent="space-between">
              <section>
                <Text mb={theme.spacing.spacing01}>
                  IBM Multicloud Accelerator has the Recommendations for{" "}
                  {instanceType} family instances running{" "}
                  {getOSName(usageOperation)} in {region}.
                </Text>
                <Text
                  size="sm"
                  color={theme.color.text.text02}
                  mb={theme.spacing.spacing02}
                >
                  You can also go to the{" "}
                  <Link to={ROUTES.reservedInstances.index}>
                    Reservations and Commitments
                  </Link>{" "}
                  page and check all recommendations for other types of compute
                  resources.
                </Text>
              </section>
              <Link
                to={getCheckRecommendationLink()}
                css={{
                  "&:hover": {
                    textDecoration: "none",
                  },
                }}
              >
                <Button type="button">Check Recommendation</Button>
              </Link>
            </FlexContainer>
          </Box>
        </Then>
        <Else>
          <Message status="notification" image="info">
            There are no Reserved Instances Recommendations associated with this
            instance.
          </Message>
        </Else>
      </If>
    </UniversalWrap>
  );
}
