/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then } from "react-if";
import { LoaderWrap } from "~/components";
import { Application } from "../../Application";
import { ApplicationsList } from "../ApplicationsList";
import { ApplicationProps } from "../types";

type Props = ApplicationProps;

export function Ec2InstanceApplications({
  loading,
  showApplicationsList,
  id = "",
  onApplicationUpdate,
  provider,
  resource,
}: Props) {
  return (
    <LoaderWrap loading={loading}>
      <If condition={showApplicationsList}>
        <Then>
          <ApplicationsList
            recordNid={id}
            refetch={onApplicationUpdate}
            resourceProvider={provider}
          />
        </Then>
        <Else>
          <Application refetch={onApplicationUpdate} estateRecord={resource} />
        </Else>
      </If>
    </LoaderWrap>
  );
}
