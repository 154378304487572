/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { capitalize } from "lodash";
import { theme } from "@nordcloud/gnui";
import {
  ReservationsAndCommitmentsCoverageOfferingType as OfferingType,
  useReservationBenefitsEstateRecordsQuery,
} from "~/generated/graphql";
import { dateFormat } from "~/constants";
import { useCurrency } from "~/hooks";
import { formatMoney } from "~/tools";

type Props = {
  nid: string;
};

export function useExistingReservedInstances({ nid }: Props) {
  const { currency } = useCurrency();

  const { data, loading, error } = useReservationBenefitsEstateRecordsQuery({
    variables: {
      instanceId: nid,
      provider: "AWS",
      offeringType: OfferingType.ReservedInstances,
    },
  });

  const reservations =
    data?.reservationsBenefitsEstateRecords?.reservations ?? [];

  const formatted = reservations.map((item) => {
    const total = 100 * Number(item.yearlyUtilization?.totalUtilisation);

    const utilization = [
      { value: total, color: theme.color.interactive.success },
      { value: 100 - total, color: theme.color.background.ui04 },
    ];

    return {
      id: item.nid,
      instanceType: item.instanceType ?? "",
      units: item.unitsCount ?? 0,
      region: item.location ?? "",
      operatingSystem: item.description ?? "",
      offeringClass: capitalize(item.offeringClass ?? ""),
      paymentOption: item.paymentOption ?? "",
      endDate: dayjs(item.to).format(dateFormat.dayMonthYear),
      benefit: formatMoney(item.yearlyUtilization?.benefit, currency),
      utilization,
    };
  });

  const reservedInstanceBenefit = reservations.reduce(
    (prev, reservation) =>
      prev + Number(reservation.yearlyUtilization?.benefit ?? "0"),
    0
  );

  return { reservations: formatted, reservedInstanceBenefit, loading, error };
}
