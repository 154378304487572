/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Checkbox, FlexContainer, SVGIcon, Text } from "@nordcloud/gnui";
import { SidebarItemWrapper } from "~/components";
import { FilterHeader, Mode } from "~/components/SidebarWithMultiSelect";
import { getSelectedMode } from "~/components/SidebarWithMultiSelect/utils";
import { isNotEmpty } from "~/tools";
import { useSavingSuggestionsSelector } from "../hooks";
import { CloudWasteOption } from "../types";
import { SelectableSavingSuggestionRow } from "./SelectableSavingSuggestionRow";
import { SwitchWrap } from "./styles";

type Props = {
  categories: CloudWasteOption[];
  selectedOptions: string[];
  onMultiSelect: (ids: string[]) => void;
  isCleared: boolean;
  isExcludeSelected?: boolean;
  onModeChange?: (mode: Mode) => void;
};

export function SavingSuggestionAccordion({
  selectedOptions = [],
  categories,
  onMultiSelect,
  isCleared,
  isExcludeSelected = false,
  onModeChange,
}: Props) {
  const [search, setSearch] = useState("");

  const {
    filteredCategories,
    selectCategory,
    selectPolicy,
    selectedCategories,
    selectedPolicies,
    isAllSelected,
    selectAll,
  } = useSavingSuggestionsSelector({
    categories,
    isCleared,
    search,
    selectedOptions,
    onMultiSelect,
  });

  return (
    <>
      <FilterHeader
        showSearchBar
        allowModeChange
        defaultMode={getSelectedMode(isExcludeSelected)}
        onSetSearch={setSearch}
        onModeChange={onModeChange}
      />
      <div
        css={{
          maxHeight: "10rem",
          overflowY: "scroll",
          overflowX: "hidden",
          alignItems: "start",
          flexDirection: "column",
        }}
      >
        <FlexContainer>
          <SVGIcon
            name="chevronDown"
            data-testid="category-expander"
            css={{ visibility: "hidden" }}
          />
          <SidebarItemWrapper
            hideBottomBorder
            title="title"
            onClick={selectAll}
          >
            <SwitchWrap>
              <Checkbox
                withoutLabel
                readOnly
                data-testid="aelect-all-checkbox"
                checked={isAllSelected}
              />
            </SwitchWrap>
            <Text mb="0" align="left">
              Select all
            </Text>
          </SidebarItemWrapper>
        </FlexContainer>
        {filteredCategories.map((item) => (
          <SelectableSavingSuggestionRow
            key={item.category}
            cloudWaste={item}
            selectedCategories={selectedCategories}
            selectedPolicies={selectedPolicies}
            isFiltered={isNotEmpty(search)}
            onCategoryCheck={selectCategory}
            onPolicyCheck={selectPolicy}
          />
        ))}
      </div>
    </>
  );
}
