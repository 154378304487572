/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  AzureBillingDataDocument,
  AzureBillingDataQuery,
  AzureBillingDataQueryVariables,
  useAddAzureBillingConfigV2Mutation,
} from "~/generated/graphql";
import { ERROR_TEXT } from "~/constants";
import { isNotNil } from "~/tools";
import { FormData } from "~/views/cloudAccounts/BillingData/azure/validators";

export function useAddAzureBillingData() {
  const [addAzureCapacity, { client, ...mutationState }] =
    useAddAzureBillingConfigV2Mutation();

  const addAzureBillingData = async (data: FormData) => {
    const response = await addAzureCapacity({ variables: { input: data } });
    const newItem = response.data?.addAzureBillingConfigV2;

    if (isNotNil(newItem)) {
      client.cache.updateQuery<
        AzureBillingDataQuery,
        AzureBillingDataQueryVariables
      >(
        {
          query: AzureBillingDataDocument,
        },
        (billingData) => ({
          azureBillingData: [...(billingData?.azureBillingData ?? []), newItem],
        })
      );

      return;
    }

    throw new Error(ERROR_TEXT.default);
  };

  return [addAzureBillingData, mutationState] as const;
}
