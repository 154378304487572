/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloError } from "@apollo/client";
import { Spacer, theme } from "@nordcloud/gnui";
import { UsageOperationType } from "~/tools";
import { ExistingReservedInstances } from "./ExistingReservedInstances";
import { ReservedInstancesRecommendations } from "./ReservedInstancesRecommendations";
import { Reservation } from "./types";

type Props = {
  reservations: Reservation[];
  isLoading: boolean;
  error: ApolloError | undefined;
  usageOperation: UsageOperationType;
  region: string;
  instanceType: string;
};

export function ReservedInstances({
  reservations,
  isLoading,
  error,
  usageOperation,
  region,
  instanceType,
}: Props) {
  return (
    <>
      <ReservedInstancesRecommendations
        usageOperation={usageOperation}
        region={region}
        instanceType={instanceType}
      />
      <Spacer height={theme.spacing.spacing06} />
      <ExistingReservedInstances
        reservations={reservations}
        error={error}
        loading={isLoading}
      />
    </>
  );
}
