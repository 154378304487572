/* *
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FieldError, UseFormRegister } from "react-hook-form";
import { useToggle } from "react-use";
import {
  Checkbox,
  FlexContainer,
  Label,
  Modal,
  Text,
  theme,
} from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { WorkflowFormData } from "../../types";
import { SLACK_MSG_PREVIEW_MODAL_TITLE } from "../constants";
import { PreviewButton } from "../EmailSettingsForm/PreviewButton";
import { CostsPreviewMessage } from "../PreviewMessages";
import { SlackErrors } from "./types";

type Props = {
  isReadMode?: boolean;
  register: UseFormRegister<WorkflowFormData>;
  slackErrors: SlackErrors;
};

export function CostsSelectDataType({
  isReadMode,
  register,
  slackErrors,
}: Props) {
  const [showPreview, setShowPreview] = useToggle(false);

  return (
    <>
      <FlexContainer
        paddingBottom={theme.spacing.spacing02}
        alignItems="center"
        justifyContent="space-between"
      >
        <Label
          css={{ marginBottom: theme.spacing.spacing00, textTransform: "none" }}
          name="Select Data Type(s)"
          required={!isReadMode}
        />
        <PreviewButton type="button" size="sm" onClick={setShowPreview}>
          Sample Message
        </PreviewButton>
      </FlexContainer>
      <Text size="sm" color={theme.color.text.text03}>
        Costs for each Business Context, Organizational Unit, application, and
        environment will be combined. Please select one or more data types to be
        sent to your Slack Channel.
      </Text>
      <FormGroup error={slackErrors?.costsOptions as FieldError}>
        <Checkbox
          disabled={isReadMode}
          {...register("slack.costsOptions.totalMonthlyCost")}
          labelText="Total Monthly Cost"
        />
        <Checkbox
          disabled={isReadMode}
          {...register("slack.costsOptions.totalMonthlyForecast")}
          labelText="Total Monthly Forecast"
        />
        <Checkbox
          disabled={isReadMode}
          {...register("slack.costsOptions.costsByProviders")}
          labelText="Cost By Providers"
        />
        <Checkbox
          disabled={isReadMode}
          {...register("slack.costsOptions.costsByCategories")}
          labelText="Cost By Categories"
        />
      </FormGroup>
      <Modal
        isOpen={showPreview}
        actions={[]}
        contentMaxHeight="40rem"
        contentLabel={SLACK_MSG_PREVIEW_MODAL_TITLE}
        onClose={setShowPreview}
      >
        <CostsPreviewMessage />
      </Modal>
    </>
  );
}
