/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Link } from "react-router-dom";
import { Box, Button, Col, Row, Tab, Tabs } from "@nordcloud/gnui";
import { BreadcrumbsBox } from "~/components";
import { useTabsWithUrl } from "~/hooks";
import { ROUTES } from "~/routing/routes";
import { SortableWorkflowHistoryList } from "./WorkflowHistory/SortableWorkflowHistoryList";
import { WorkflowsList } from "./WorkflowsList/WorkflowsList";

export function WorkflowsPage() {
  const { activeTab, setActiveTab } = useTabsWithUrl();

  return (
    <Row>
      <Col>
        <BreadcrumbsBox title="Workflows">
          <Link
            to={ROUTES.workflows.create}
            css={{ "&:hover": { textDecoration: "none" } }}
          >
            <Button icon="plus">Add New Workflow</Button>
          </Link>
        </BreadcrumbsBox>
        <Row>
          <Col>
            <Box innerSpacing="spacing00">
              <Tabs step={activeTab} handleTab={setActiveTab}>
                <Tab heading="All Workflows">
                  <WorkflowsList />
                </Tab>
                <Tab heading="History">
                  <SortableWorkflowHistoryList />
                </Tab>
              </Tabs>
            </Box>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
