import { ReservationsAndCommitmentsFilters } from "~/generated/graphql";
import { useQueryState } from "~/hooks";
import { getTypedKeys, isEmptyObj, isNotEmpty } from "~/tools";

type Props = {
  filters: ReservationsAndCommitmentsFilters;
  onApply: () => void;
};

export function useSetFilters({ filters, onApply }: Props) {
  const { updateQueryState, removeFields } = useQueryState();

  const commit = () => {
    updateQueryState({ ...filters, page: 0 });
    onApply();
  };

  const reset = () => {
    removeFields([...getTypedKeys(filters), "page"]);
    onApply();
  };

  const hasEmptyFilters =
    isEmptyObj(filters) ||
    !Object.values(filters).some((v) => Array.isArray(v) && isNotEmpty(v));

  return {
    commit,
    reset,
    hasEmptyFilters,
  };
}
