/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCloudAccountStatusQuery } from "~/generated/graphql";
import { isCloudResource } from "~/tools";

type Props = {
  nid: string;
};

export function useCloudAccountStatus({ nid }: Props) {
  const { data, loading } = useCloudAccountStatusQuery({ variables: { nid } });

  const estateRecord = data?.estateRecord ?? {};
  const cloudAccount = isCloudResource(estateRecord)
    ? estateRecord.cloudAccount
    : null;

  const status = cloudAccount?.status ?? "inactive";
  const isActivated = cloudAccount?.activated ?? false;
  const name = cloudAccount?.displayName ?? cloudAccount?.name ?? "";

  return {
    status,
    isActivated,
    name,
    loading,
    message: getMessage(isActivated),
  };
}

function getMessage(isActivated: boolean) {
  if (!isActivated) {
    return "IBM Multicloud Accelerator now has access only to billing resources. To view more data, please provide cloud credentials to this account.";
  }

  return "";
}
