/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { useCloudServicesQuery } from "~/generated/graphql";
import { isNotNil } from "~/tools";

export function useSelectService() {
  const [selectedService, setSelectedService] = useState("");

  const { data: cloudServices, loading, error } = useCloudServicesQuery();

  const services = cloudServices?.services ?? [];
  const serviceLabels = services.map((service) => service.name);

  const onSelect = (label: string) => {
    if (isNotNil(services.find((service) => service.name === label))) {
      setSelectedService(label);
    }
  };

  return {
    serviceLabels,
    selectedService,
    loading,
    error,
    onSelect,
  };
}
