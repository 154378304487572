/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { When } from "react-if";
import {
  Box,
  FlexContainer,
  Label,
  Switch,
  Text,
  theme,
} from "@nordcloud/gnui";
import { WorkflowExternalIntegrationType } from "~/generated/graphql";
import { FormGroup } from "~/components";
import { capitalize } from "~/tools";
import { WorkflowFormData } from "../types";
import { PreviewButton } from "./EmailSettingsForm/PreviewButton";

type Props = {
  isReadMode?: boolean;
  shouldBeSelected?: boolean;
  onPreviewClick?: () => void;
};

export function BudgetsConditions({
  shouldBeSelected,
  isReadMode,
  onPreviewClick,
}: Props) {
  const {
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<WorkflowFormData>();

  const sendDataComponents = getValues("sendDataComponents");
  const showPreviewButton = [
    WorkflowExternalIntegrationType.Slack,
    WorkflowExternalIntegrationType.ServiceNow,
  ].some((integrationType) => sendDataComponents.includes(integrationType));

  const { forecastGtBudget, costGtBudget } = getValues("budgetsOptions");
  const summary = useMemo(
    () => getSummary(forecastGtBudget, costGtBudget),
    [forecastGtBudget, costGtBudget]
  );

  const budgetsErrors = errors.budgetsOptions;

  const showSummary = shouldBeSelected
    ? forecastGtBudget || costGtBudget
    : true;

  const handleConditionChange = useCallback(
    ({ value, type }: Pick<ConditionSwitchProps, "type" | "value">) =>
      setValue(`budgetsOptions.${type}GtBudget`, !value),
    [setValue]
  );

  return (
    <FormGroup error={budgetsErrors?.costGtBudget}>
      <FlexContainer justifyContent="space-between" alignItems="start">
        <Label
          name="Send Email When"
          required={shouldBeSelected && !isReadMode}
        />
        <When condition={showPreviewButton}>
          <PreviewButton
            type="button"
            size="sm"
            onClick={() => onPreviewClick?.()}
          >
            Sample Message
          </PreviewButton>
        </When>
      </FlexContainer>
      <ConditionSwitch
        value={costGtBudget}
        type="cost"
        disabled={isReadMode}
        onChange={handleConditionChange}
      />
      <ConditionSwitch
        value={forecastGtBudget}
        type="forecast"
        disabled={isReadMode}
        onChange={handleConditionChange}
      />
      <When condition={showSummary}>
        <Box boxStyle="lightGrey" mb={theme.spacing.spacing04}>
          <Text size="sm" weight="bold" m="0">
            Conditions Summary
          </Text>
          <Text size="sm">{summary}</Text>
        </Box>
      </When>
    </FormGroup>
  );
}

function getSummary(forecastGtBudget: boolean, costGtBudget: boolean) {
  const prefix = (() => {
    if (costGtBudget && forecastGtBudget) {
      return "When a cost or forecast exceeds";
    }

    return costGtBudget ? "When costs exceed" : "When a forecast exceeds";
  })();

  return `${prefix} the current month's budget, an email will be sent`;
}

type ConditionSwitchProps = {
  type: "cost" | "forecast";
  value: boolean;
  onChange: (change: Pick<ConditionSwitchProps, "type" | "value">) => void;
  disabled?: boolean;
};

function ConditionSwitch({
  type,
  value,
  onChange,
  disabled = false,
}: ConditionSwitchProps) {
  const handleChange = useCallback(() => {
    onChange({ value, type });
  }, [value, type, onChange]);

  return (
    <FlexContainer mb={theme.spacing.spacing02}>
      <Switch checked={value} disabled={disabled} onChange={handleChange} />
      <Text pl={theme.spacing.spacing02} m="0">
        <Text tag="span" weight="medium">
          {capitalize(type)}
          <Text tag="span"> exceeds </Text>
          Budget
        </Text>
      </Text>
    </FlexContainer>
  );
}
