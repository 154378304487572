/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export enum EstatePeriod {
  PREVIOUS_MONTH = 0,
  DEFAULT_MONTH = 1,
  RANGE = 13,
}

export enum PreviousPeriod {
  PREV_3_MONTHS = 3,
  PREV_6_MONTHS = 6,
  PREV_12_MONTHS = 12,
  THIS_YEAR = 0,
  PREV_YEAR = 1,
  PREV_2_YEAR = 2,
}
