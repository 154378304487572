/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ERROR_TEXT, SUCCESS_TEXT } from "~/constants";
import { showError, showSuccess } from "~/services/toast";
import { HybridForm } from "../../components";
import { HybridFormData } from "../../components/HybridSidebar/types";
import { CloudProviderType, Provider, ProviderTab } from "../../types";
import { useAddAccount } from "./useAddAccount";

type Props = {
  provider: ProviderTab;
  onClose: () => void;
};

export function HybridSidebarContent({ provider, onClose }: Props) {
  const { addAccount, loading } = useAddAccount(provider);

  const onSubmit = async (formData: HybridFormData) => {
    if (loading) {
      return;
    }

    try {
      await addAccount({
        variables: {
          input: {
            name: formData.clusterName,
            ownerId: formData.ownerId,
            provider: Provider.Hybrid,
            providerType:
              provider === ProviderTab.KUBERNETES
                ? CloudProviderType.Kubernetes
                : CloudProviderType.Openshift,
          },
        },
      });

      showSuccess(SUCCESS_TEXT.accountAdded);
      onClose();
    } catch {
      showError(ERROR_TEXT.failedAddingAccount);
    }
  };

  return <HybridForm loading={loading} onSubmit={onSubmit} onClose={onClose} />;
}
