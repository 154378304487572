/**
 * Copyright 2021-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ReactNode } from "react";
import { Else, If, Then } from "react-if";
import { FlexContainer, Text, theme, Tooltip } from "@nordcloud/gnui";

type Props = {
  title: string | number;
  tooltipCaption?: string;
  children: ReactNode | ReactNode[];
};

export function GridItemWrapper({ title, children, tooltipCaption }: Props) {
  return (
    <FlexContainer direction="column" alignItems="flex-start">
      <If condition={tooltipCaption}>
        <Then>
          <Tooltip status="notification" caption={tooltipCaption}>
            <Text mb={0} color={theme.color.border.border02} size="sm">
              {title}
            </Text>
          </Tooltip>
        </Then>
        <Else>
          <Text mb={0} color={theme.color.border.border02} size="sm">
            {title}
          </Text>
        </Else>
      </If>
      {children}
    </FlexContainer>
  );
}
