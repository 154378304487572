/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEstateDetails } from "~/views/estate/EstateDetailsPage/hooks";

export const enum CustomComponents {
  BUDGET = "BUDGET",
  ORIGINAL_COST = "ORIGINAL_COST",
}

export const enum HiddenComponents {
  COST = "cost",
  CARBON_FOOTPRINT = "carbonFootprint",
  FORECAST = "forecast",
  SAVINGS_SUGGESTIONS = "savingsSuggestions",
}

export type LinkedRecords = ReturnType<
  typeof useEstateDetails
>["data"]["links"];
