import {
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
} from "~/generated/graphql";
import { UsageCoverageData } from "../types";

type CoverageInfomationProps = {
  resources: UsageCoverageData;
  offeringType?: ReservationsAndCommitmentsCoverageOfferingType;
  serviceUsageType?: ReservationsAndCommitmentsCoverageServiceUsageType;
};

export function useGetCoverageInformation({
  resources,
  offeringType,
  serviceUsageType,
}: CoverageInfomationProps) {
  const rowData = { ...resources, offeringType, serviceUsageType };
  const isReservedInstances =
    offeringType !==
    ReservationsAndCommitmentsCoverageOfferingType.SavingsPlans;

  return {
    isReservedInstances,
    rowData,
  };
}
