/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Else, If, Then } from "react-if";
import styled from "styled-components";
import { Box, BrickLoader, Spacer, theme } from "@nordcloud/gnui";
import { CollapsibleBox, UniversalWrap } from "~/components";
import { getFirstItem, isEmpty } from "~/tools";
import {
  CloudAccountMissing,
  Period,
  PeriodSwitch,
  Utilization,
} from "./components";
import { UtilizationLegendConfig, UtilizationTooltipConfig } from "./constants";
import { useEc2Utilization } from "./hooks";
import { VolumeSelectionData } from "./types";

type Props = {
  nid: string;
  isActivated: boolean;
  volumeIds: VolumeSelectionData[];
};

export function Ec2InstanceUtilization({ nid, isActivated, volumeIds }: Props) {
  const [period, setPeriod] = useState(Period.MONTH);
  const [selectedVolumeId, setSelectedVolumeId] = useState(
    getFirstItem(volumeIds)?.volumeId
  );

  const {
    cpu,
    memory,
    networkIn,
    networkOut,
    diskRead,
    diskWrite,
    loading,
    diskLoading,
    error,
    customerError,
    timeTicks,
    getDiskUtilizationByVolumeId,
  } = useEc2Utilization({
    nid,
    period,
    isActivated,
    volumeId: selectedVolumeId ?? "",
  });

  return (
    <CollapsibleBox title="Instance Utilization">
      <If condition={isActivated}>
        <Then>
          <UniversalWrap
            errorProps={{ error: isEmpty(customerError) ? error : undefined }}
            loaderProps={{
              loading,
              Component: BrickLoader,
              viewBox: "0 0 3 1",
            }}
          >
            <If condition={isEmpty(customerError)}>
              <Then>
                <Box boxStyle="lightGrey">
                  <PeriodSwitch period={period} onPeriodChanged={setPeriod} />
                </Box>
                <Spacer height={theme.spacing.spacing04} />
                <Wrapper>
                  <Utilization
                    seriesOneData={cpu}
                    title="CPU"
                    subtitle="utilization (%)"
                    legend={UtilizationLegendConfig.cpu}
                    tooltip={UtilizationTooltipConfig.cpu}
                    timeTicks={timeTicks}
                  />
                  <Utilization
                    seriesOneData={memory}
                    title="Memory"
                    subtitle="utilization (%)"
                    legend={UtilizationLegendConfig.memory}
                    tooltip={UtilizationTooltipConfig.memory}
                    timeTicks={timeTicks}
                    emptyReason="No CloudWatch agent installed."
                  />
                  <Utilization
                    seriesOneData={networkIn}
                    seriesTwoData={networkOut}
                    title="Network"
                    subtitle="in and out in bytes"
                    legend={UtilizationLegendConfig.network}
                    tooltip={UtilizationTooltipConfig.network}
                    timeTicks={timeTicks}
                  />
                  <Utilization
                    seriesOneData={diskRead}
                    seriesTwoData={diskWrite}
                    title="Disk"
                    subtitle="read and write in KiB/s"
                    legend={UtilizationLegendConfig.disk}
                    tooltip={UtilizationTooltipConfig.disk}
                    timeTicks={timeTicks}
                    emptyReason="No instance store volumes attached."
                    selectData={volumeIds}
                    selectedData={selectedVolumeId}
                    isLoading={diskLoading}
                    onSelectChanged={(value) => {
                      setSelectedVolumeId(value);
                      void getDiskUtilizationByVolumeId(value);
                    }}
                  />
                </Wrapper>
              </Then>
              <Else>
                <CloudAccountMissing message={customerError} />
              </Else>
            </If>
          </UniversalWrap>
        </Then>
        <Else>
          <CloudAccountMissing message={MESSAGE} />
        </Else>
      </If>
    </CollapsibleBox>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${theme.spacing.spacing08};

  @media screen and (max-width: ${theme.breakpoints.lg}px) {
    height: 100%;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: 1fr;
    row-gap: ${theme.spacing.spacing04};
  }
`;

const MESSAGE =
  "We cannot show the Utilization due to the lack of resource scanning. Please provide cloud credentials.";
