/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useFormState,
} from "react-hook-form";
import { When } from "react-if";
import styled from "styled-components";
import {
  Button,
  Sidebar,
  Label,
  Input,
  theme,
  Text,
  Row,
  Col,
  Radio,
  Select,
  FlexContainer,
} from "@nordcloud/gnui";
import { WorkflowFrequency } from "~/generated/graphql";
import { ButtonWrap, FormGroup } from "~/components";
import { Option } from "~/models";
import { capitalize, getTypedKeys, inflect, noop } from "~/tools";
import {
  SchedulerFields,
  WorkflowFields,
  WeekdayLabelMap,
  tomorrowsDate,
  FrequencyTextMap,
} from "~/views/workflows/AddWorkflow/constants";
import { WorkflowFormData, getDefaultValues, WorkflowFormMode } from "../types";
import { getDefaultValues as getDefaultWorkflowValues } from "../WorkflowDetails/helpers";
import { WorkflowProps } from "../WorkflowPropsMapping";

type Props = {
  formMode: WorkflowFormMode;
  isOpen: boolean;
  workflow?: WorkflowProps;
  onClose: () => void;
  onSave?: () => void;
};

export function SchedulerSettingsForm({
  formMode,
  isOpen,
  workflow,
  onClose,
  onSave = noop,
}: Props) {
  const { control, watch, register, trigger, setValue, resetField } =
    useFormContext<WorkflowFormData>();
  const { fields } = useFieldArray<WorkflowFormData>({
    name: "scheduler.weekdays",
  });
  const { errors, touchedFields } = useFormState<WorkflowFormData>();
  const [scheduler, schedulerSubmissionSnapshot] = watch([
    "scheduler",
    "schedulerSubmissionSnapshot",
  ]);
  const isWeekly = scheduler.frequency === WorkflowFrequency.Weekly;
  const isReadMode = formMode === "read";

  const handleSaveButtonClick = async () => {
    const touchedFieldKeys = touchedFields.scheduler
      ? getTypedKeys(touchedFields.scheduler)
      : [];
    const fieldsToValidate = [
      ...touchedFieldKeys.map((key) => `scheduler.${key}` as const),
      ...getRequiredFields(scheduler.repeat),
    ];
    const isValid = await trigger(fieldsToValidate);

    if (isValid) {
      setValue("schedulerSubmissionSnapshot", JSON.stringify(scheduler), {
        shouldDirty: true,
      });
      setValue("schedulerSubmissionId", dayjs().unix().toString());
      resetField("scheduler", { defaultValue: scheduler });
      onSave();
    }
  };

  const handleClose = () => {
    if (formMode === "add") {
      resetField("scheduler", {
        defaultValue: schedulerSubmissionSnapshot
          ? JSON.parse(schedulerSubmissionSnapshot)
          : (getDefaultValues().slack as unknown as Pick<
              WorkflowFormData,
              "scheduler"
            >),
      });
    }
    if (formMode === "edit") {
      resetField("scheduler", {
        defaultValue: schedulerSubmissionSnapshot
          ? JSON.parse(schedulerSubmissionSnapshot)
          : workflow &&
            (getDefaultWorkflowValues(workflow).slack as unknown as Pick<
              WorkflowFormData,
              "scheduler"
            >),
      });
    }

    onClose();
  };

  const schedulerErrors = errors[WorkflowFields.SCHEDULER];
  const intervalError = schedulerErrors?.[SchedulerFields.INTERVAL];
  const weekdaysError = schedulerErrors?.[SchedulerFields.WEEKDAYS];

  const timezoneString = `UTC/GMT ${dayjs().format("Z")}`;

  return (
    <Sidebar
      title="Scheduler Settings"
      isOpen={isOpen}
      width="42rem"
      onClick={handleClose}
    >
      <FormGroup error={schedulerErrors?.[SchedulerFields.START_DATE]}>
        <FlexContainer justifyContent="space-between">
          <Label
            name="Start Date"
            htmlFor="schedulerStartDate"
            required={!isReadMode}
          />
          <Text size="sm" color={theme.color.text.text02}>
            {timezoneString}
          </Text>
        </FlexContainer>
        <Input
          disabled={isReadMode}
          id="schedulerStartDate"
          type="date"
          {...register("scheduler.startDate")}
        />
      </FormGroup>
      <FormGroup error={schedulerErrors?.[SchedulerFields.EXECUTION_TIME]}>
        <Label
          name="Execution Time"
          htmlFor="serviceNowExecutionTime"
          required={!isReadMode}
        />
        <Input
          disabled={isReadMode}
          id="serviceNowExecutionTime"
          type="time"
          {...register("scheduler.executionTime")}
        />
      </FormGroup>
      <RepeatRadioButtonArray isReadonly={isReadMode} />
      <When condition={scheduler?.repeat}>
        <div css={{ marginLeft: theme.spacing.spacing06 }}>
          <Row>
            <Col lg={6}>
              <FormGroup error={schedulerErrors?.[SchedulerFields.FREQUENCY]}>
                <Controller
                  control={control}
                  name="scheduler.frequency"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={FREQUENCY_OPTIONS}
                      isDisabled={isReadMode}
                      placeholder={capitalize(value)}
                      onChange={(option) => {
                        onChange(option?.value);
                      }}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
          <Label
            name="Repeat Every"
            htmlFor="schedulerInterval"
            required={!isReadMode}
          />
          <Row>
            <Col sm={1} md={1} lg={2}>
              <Input
                disabled={isReadMode}
                id="schedulerInterval"
                type="number"
                status={intervalError && "danger"}
                min={1}
                {...register("scheduler.interval", {
                  valueAsNumber: true,
                })}
              />
            </Col>
            <Col sm={2} md={2} lg={2}>
              <FlexContainer
                justifyContent="center"
                alignItems="center"
                css={{ height: "100%" }}
              >
                <Text ml={theme.spacing.spacing01}>
                  {scheduler?.frequency &&
                    getFrequencyLabel(scheduler.frequency, scheduler.interval)}
                </Text>
              </FlexContainer>
            </Col>
            <When condition={isWeekly}>
              <Col sm={5} md={5} lg={8}>
                <FlexContainer justifyContent="center" alignItems="center">
                  {fields.map((field, index) => (
                    <WeekdayArrayItem
                      key={field.id}
                      isReadonly={isReadMode}
                      index={index}
                    />
                  ))}
                </FlexContainer>
              </Col>
            </When>
          </Row>
          <Text mt={theme.spacing.spacing01} color="danger">
            {intervalError?.message ?? weekdaysError?.message}
          </Text>
          <FormGroup error={schedulerErrors?.[SchedulerFields.END_DATE]}>
            <Label name="End Date" htmlFor="schedulerEndDate" />
            <Input
              disabled={isReadMode}
              id="schedulerEndDate"
              type="date"
              min={tomorrowsDate}
              {...register("scheduler.endDate")}
            />
          </FormGroup>
        </div>
      </When>
      <When condition={!isReadMode}>
        <Button
          px={theme.spacing.spacing08}
          type="button"
          mt={theme.spacing.spacing04}
          onClick={handleSaveButtonClick}
        >
          Apply
        </Button>
      </When>
    </Sidebar>
  );
}

const FREQUENCY_OPTIONS: Option[] = [
  {
    value: WorkflowFrequency.Daily,
    label: "Daily",
  },
  {
    value: WorkflowFrequency.Weekly,
    label: "Weekly",
  },
  {
    value: WorkflowFrequency.Monthly,
    label: "Monthly",
  },
  {
    value: WorkflowFrequency.Yearly,
    label: "Yearly",
  },
];

const getFrequencyLabel = (frequency: WorkflowFrequency, interval = 0) => {
  const label = capitalize(inflect(FrequencyTextMap[frequency])(interval));

  if (frequency === WorkflowFrequency.Weekly) {
    return `${label} on`;
  }

  return label;
};

type RepeatRadioButtonArrayProps = {
  isReadonly: boolean;
};

function RepeatRadioButtonArray({ isReadonly }: RepeatRadioButtonArrayProps) {
  return (
    <Controller
      name="scheduler.repeat"
      render={({ field: { onChange, value } }) => (
        <>
          <StyledRadio
            isReadonly={isReadonly}
            disabled={isReadonly}
            checked={!value}
            labelText={
              <ButtonWrap
                role="textbox"
                disabled={isReadonly}
                css={{ textAlign: "start" }}
                type="button"
                onClick={() => onChange(!value)}
              >
                <span>Run Once</span>
                <Text color={theme.color.text.text02} size="sm">
                  The workflow will be run once on the predefined start date
                </Text>
              </ButtonWrap>
            }
            onChange={() => onChange(!value)}
          />
          <StyledRadio
            isReadonly={isReadonly}
            disabled={isReadonly}
            checked={value}
            labelText={
              <ButtonWrap
                role="textbox"
                disabled={isReadonly}
                css={{ textAlign: "start" }}
                type="button"
                onClick={() => onChange(!value)}
              >
                <span>Repeat</span>
                <Text color={theme.color.text.text02} size="sm">
                  You can define automatic repetition of the workflow
                </Text>
              </ButtonWrap>
            }
            onChange={() => onChange(!value)}
          />
        </>
      )}
    />
  );
}

function getRequiredFields(repeat: boolean) {
  const baseFields = [
    "scheduler.startDate",
    "scheduler.executionTime",
  ] as const;

  if (!repeat) {
    return baseFields;
  }

  return [...baseFields, "scheduler.repeat"] as const;
}

const StyledRadio = styled(Radio)<{ isReadonly: boolean }>`
  opacity: ${(isReadonly) => (isReadonly ? 0.7 : 1)};
  &:hover: {
    cursor: ${(isReadonly) => (isReadonly ? "default" : "pointer")};
  }
`;

type WeekdayArrayItemProps = {
  index: number;
  isReadonly: boolean;
};

function WeekdayArrayItem({ index, isReadonly }: WeekdayArrayItemProps) {
  return (
    <Controller
      name={`scheduler.weekdays.${index}.selected` as const}
      render={({ field: { onChange, value } }) => (
        <Button
          type="button"
          severity={value ? "high" : "medium"}
          size="md"
          role="checkbox"
          css={{
            marginLeft: theme.spacing.spacing01,
            opacity: isReadonly ? ".7" : "1",
            "user-select": isReadonly ? "none" : "default",
            ":hover": {
              cursor: isReadonly ? "default" : "pointer",
            },
          }}
          onClick={() => {
            !isReadonly && onChange(!value);
          }}
        >
          {WeekdayLabelMap[index]}
        </Button>
      )}
    />
  );
}
