/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { theme } from "@nordcloud/gnui";
import {
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsProvider,
} from "~/generated/graphql";
import { DataItem } from "~/components";
import { useGetServiceTypeCount } from "../hooks";

type Props = {
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  offeringType: ReservationsAndCommitmentsCoverageOfferingType;
  coverageLabel: string;
  coverageValue: string;
  onClick: () => void;
  padding: string;
  provider: ReservationsProvider;
};

export function ReservationExistingLinkCounter({
  serviceUsageType,
  offeringType,
  coverageLabel,
  coverageValue,
  onClick,
  padding,
  provider,
}: Props) {
  const { linkText, countLoading } = useGetServiceTypeCount(
    offeringType,
    serviceUsageType,
    provider
  );

  const linkMessage = parseFloat(coverageValue) ? linkText : "";

  return (
    <DataItem
      size="md"
      label={coverageLabel}
      value={coverageValue}
      linkText={linkMessage}
      loading={countLoading}
      mb={theme.spacing.spacing01}
      itemWidth="20%"
      padding={padding}
      grow={false}
      onClick={onClick}
    />
  );
}
