/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { useFormContext } from "react-hook-form";
import { WorkflowDataType } from "../../constants";
import { WorkflowFormData } from "../../types";

export function useValidateFields() {
  const { trigger, watch } = useFormContext<WorkflowFormData>();
  const selectDataComponent = watch("selectDataComponent");

  return React.useCallback(() => {
    const fieldName =
      selectDataComponent === WorkflowDataType.BUDGETS
        ? (["serviceNow", "budgetsOptions.costGtBudget"] as const)
        : "serviceNow";

    return trigger(fieldName);
  }, [selectDataComponent, trigger]);
}
