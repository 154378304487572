/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  AddBusinessContextMutationVariables,
  BusinessContextResponse,
  RemoveBusinessContextMutationVariables,
  useAddBusinessContextMutation,
  useRemoveBusinessContextMutation,
  Query,
} from "~/generated/graphql";
import { removeFromApolloCache } from "~/tools";

export function useAddBusinessContext() {
  const [
    updateFn,
    {
      client: { cache },
      ...state
    },
  ] = useAddBusinessContextMutation();

  const addBusinessContext = async (
    variables: AddBusinessContextMutationVariables
  ) => {
    const { data } = await updateFn({ variables });
    const addedBusinessContext = data?.addBusinessContextV2;

    if (addedBusinessContext) {
      cache.modify<Query>({
        fields: {
          // @ts-expect-error TODO: fix
          businessContexts(previous: BusinessContextResponse, { toReference }) {
            return [
              ...(previous.businessContexts || []),
              toReference(addedBusinessContext),
            ];
          },
        },
      });
    }

    return addedBusinessContext;
  };

  return [addBusinessContext, state] as const;
}

export function useRemoveBusinessContext(
  options?: Parameters<typeof useRemoveBusinessContextMutation>[0]
) {
  const [remove, { client, ...rest }] = useRemoveBusinessContextMutation({
    ...options,
  });

  const removeBusinessContext = async (
    variables: RemoveBusinessContextMutationVariables
  ) => {
    const response = await remove({ variables });

    removeFromApolloCache(client.cache, {
      item: `BusinessContext:${variables.id}`,
    });

    return response;
  };

  return [removeBusinessContext, { client, ...rest }] as const;
}
