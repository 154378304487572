/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Spacer } from "@nordcloud/gnui";
import { CollapsibleBox } from "~/components";
import { capitalize } from "~/tools";
import { getUrl } from "../../helpers";
import { DefaultEc2GridWrapper, Item } from "../components";

type Props = {
  vpcId: string;
  vpcNid: string;
  ipAddress: string;
  state: string;
  cidrBlock: string;
  availabilityZone: string;
  firstSeen: string;
  lastSeen: string;
};

export function Ec2SubnetDetails({
  vpcId,
  vpcNid,
  ipAddress,
  state,
  cidrBlock,
  availabilityZone,
  firstSeen,
  lastSeen,
}: Props) {
  return (
    <CollapsibleBox title="Details">
      <DefaultEc2GridWrapper>
        <Item
          label="VPC"
          value={vpcId}
          url={getUrl(vpcId, vpcNid, "ec2/vpc")}
        />
        <Item label="Available IPv4 addresses" value={ipAddress} />
        <Item label="State" value={capitalize(state)} />
        <Item label="Route table" value="" />
        <Item label="Network ACL" value="" />
        <Item label="IPv4 CIDR" value={cidrBlock} />
        <Item label="Availability Zone" value={availabilityZone} />
        <Spacer />
        <Spacer />
        <Item label="First Seen" value={firstSeen} />
        <Item label="Last Seen" value={lastSeen} />
      </DefaultEc2GridWrapper>
    </CollapsibleBox>
  );
}
