/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Message, Spacer, Text, theme } from "@nordcloud/gnui";

export function BillingDataPendingNotification() {
  return (
    <>
      <Message status="notification" image="info">
        <div>
          <Text
            weight="medium"
            color={theme.color.text.info}
            mb={theme.spacing.spacing00}
          >
            Your billing account has been added.
          </Text>
          <Spacer margin={theme.spacing.spacing02} />
          <Text mb="0" color={theme.color.text.text02}>
            Please note that the onboarding of billing data can take some time.
            It usually takes up to 48 hours for the data to become available.
          </Text>
        </div>
      </Message>
      <Spacer margin={theme.spacing.spacing04} />
    </>
  );
}
