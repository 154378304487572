/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import {
  GetEnvironmentAccumulatedCostQuery,
  TimePoint as TimePointType,
  useGetEnvironmentAccumulatedCostQuery,
} from "~/generated/graphql";
import {
  getTableGranularity,
  mapGranularity,
} from "~/components/Charts/CostAnalysis";
import {
  CostAnalysisFields,
  Granularity,
  Period,
  TableData,
  TimePoint,
} from "~/components/Charts/CostAnalysis/types";
import { isNotNil } from "~/tools";

type Props = {
  envId: string;
  startDate: string;
  endDate: string;
  granularity: Granularity;
  period: Period;
};

export function useEnvAccumulatedCostChart({
  envId,
  startDate,
  endDate,
  granularity,
  period,
}: Props) {
  const { data, loading, error } = useGetEnvironmentAccumulatedCostQuery({
    variables: {
      environmentId: envId,
      startDate,
      endDate,
      granularity: mapGranularity(granularity),
      tableGranularity: getTableGranularity(granularity, period),
    },
  });
  const envAccumulatedCostChartData = useMemo(
    () => generateAccumulatedCostGroups(data),
    [data]
  );

  const envAccumulatedCostBudgetChartData = useMemo(
    () =>
      (data?.environmentAccumulatedCost.timePoints ?? [])
        .filter(isNotNil)
        .map((item) => mapTimePointValue(item)),
    [data]
  );

  const envAccumulatedCostTimePoints = useMemo(
    () =>
      (data?.environmentAccumulatedCostTable?.timePoints ?? [])
        .filter(isNotNil)
        .map((item) => mapTimePointValue(item)),
    [data]
  );

  const envAccumulatedCostTotal = Number(
    data?.environmentAccumulatedCost?.total ?? 0
  );

  const envAccumulatedCostTableData: TableData[] = useMemo(
    () => [
      {
        field: "Cost",
        total: envAccumulatedCostTotal,
        ...Object.fromEntries(
          envAccumulatedCostTimePoints.map((item: TimePoint) => [
            item.date,
            item.value,
          ])
        ),
      },
    ],
    [envAccumulatedCostTotal, envAccumulatedCostTimePoints]
  );

  return {
    envAccumulatedCostChartData,
    envAccumulatedCostTableData,
    envAccumulatedCostTotal,
    envAccumulatedCostTimePoints,
    envAccumulatedCostBudgetChartData,
    loading,
    error,
  };
}

function mapTimePointValue(timePoint: TimePointType) {
  return {
    ...timePoint,
    value: Number(timePoint.value),
  };
}
function generateAccumulatedCostGroups(
  data: GetEnvironmentAccumulatedCostQuery | undefined
) {
  return (data?.environmentAccumulatedCost.timePoints ?? [])
    .filter(isNotNil)
    .map((timepoint) => {
      return {
        ...timepoint,
        groups: [
          {
            id: "accumulatedDailyCost",
            value: timepoint.value,
            name: CostAnalysisFields.DAILY_COST,
          },
        ],
      };
    });
}
