/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import {
  FlexContainer,
  theme,
  Text,
  Label,
  Input,
  Button,
  Message,
  Spacer,
} from "@nordcloud/gnui";
import { ExternalLink, FormGroup, FormHint } from "~/components";
import { ERROR_TEXT, SUCCESS_TEXT } from "~/constants";
import { showError, showSuccess } from "~/services/toast";
import { Provider } from "../../types";
import {
  AWS_ABOUT_EXTERNAL_IDS,
  AWS_CLOUD_FORMATION_LINK,
  AWS_INFO_ONBOARDING,
  AWS_MANAGEMENT_CONSOLE_LINK,
  AwsFormField,
  URL_AWS_TEMPLATE,
} from "../constants";
import { useBatchUpdateAwsAccountCredentials } from "../hooks/useBatchUpdateAwsAccountCredentials";
import { UpdateHandlerVariables } from "../types";
import { awsCredentialsSchema } from "../validators";

type Props = {
  onClose: () => void;
  nids: string[];
};

export function AwsUpdateCredentialsForm({ onClose, nids }: Props) {
  const [updateCredentials] = useBatchUpdateAwsAccountCredentials();
  const formMethods = useForm<UpdateHandlerVariables<Provider.Aws>>({
    resolver: yupResolver(awsCredentialsSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const onSubmit = async ({
    arn,
    externalId,
  }: UpdateHandlerVariables<Provider.Aws>) => {
    try {
      await updateCredentials(
        {
          arn,
          externalId,
        },
        nids
      );
      showSuccess(SUCCESS_TEXT.accountCredentialsUpdated);
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form
        css={{ width: "100%" }}
        id="AWS-cred-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Message image="info" status="notification">
          <FlexContainer direction="column" alignItems="flex-start">
            <Text
              weight="medium"
              tag="div"
              paddingBottom={theme.spacing.spacing02}
              color={theme.colors.notification}
            >
              On-boarding process
            </Text>
            <Text color={theme.color.text.text02}>{AWS_INFO_ONBOARDING}</Text>
          </FlexContainer>
        </Message>
        <Spacer height={theme.spacing.spacing04} />
        <FlexContainer
          css={{ width: "100%" }}
          alignItems="flex-start"
          direction="column"
          gap={theme.spacing.spacing03}
        >
          <FormHint stepNumber={1}>
            <Text tag="div">
              Sign in to the{" "}
              <ExternalLink href={AWS_MANAGEMENT_CONSOLE_LINK}>
                AWS Management Console
              </ExternalLink>
            </Text>
          </FormHint>
          <FormHint stepNumber={2}>
            <Text tag="div">
              In AWS Management Console go to{" "}
              <ExternalLink href={AWS_CLOUD_FORMATION_LINK}>
                CloudFormation
              </ExternalLink>
            </Text>
          </FormHint>
          <FormHint stepNumber={3}>
            <FlexContainer direction="column" alignItems="flex-start">
              <Text tag="div">
                Create a CloudFormation Stack based on{" "}
                <ExternalLink href={URL_AWS_TEMPLATE}>
                  CloudFormation Template
                </ExternalLink>
              </Text>
              <Text
                size="sm"
                pt={theme.spacing.spacing01}
                color={theme.color.text.text02}
              >
                The AWS External ID should be a randomly generated long string
                of different characters. It will work as a password between MCA
                and the AWS account.
              </Text>
              <FormGroup error={errors[AwsFormField.EXTERNAL_ID]}>
                <Label
                  required
                  name="Provide Role External ID"
                  htmlFor={AwsFormField.EXTERNAL_ID}
                />
                <Input
                  {...register(AwsFormField.EXTERNAL_ID)}
                  id={AwsFormField.EXTERNAL_ID}
                />
              </FormGroup>
              <ExternalLink
                href={AWS_ABOUT_EXTERNAL_IDS}
                css={{
                  fontSize: theme.fontSizes.sm,
                }}
              >
                More information about External IDs
              </ExternalLink>
            </FlexContainer>
          </FormHint>
          <FormHint stepNumber={4}>
            <FlexContainer
              css={{ width: "100%" }}
              direction="column"
              alignItems="flex-start"
            >
              <Text tag="div" width="100%">
                In AWS Management Console go to IAM and find role by name
              </Text>
              <Text>(NordcloudMCAScannerServiceRole)</Text>
              <FormGroup error={errors[AwsFormField.ARN]}>
                <Label
                  required
                  name="Provide Role ARN"
                  htmlFor={AwsFormField.ARN}
                />
                <Input
                  {...register(AwsFormField.ARN)}
                  id={AwsFormField.ARN}
                  width="100%"
                  placeholder="e.g. arn:aws:iam::123456789012:role/NordcloudMCReadOnlyServiceRole"
                />
              </FormGroup>
            </FlexContainer>
          </FormHint>
        </FlexContainer>
        <FlexContainer gap={theme.spacing.spacing04}>
          <Button type="submit" icon="checkmark">
            Apply
          </Button>
          <Button severity="low" onClick={onClose}>
            Cancel
          </Button>
        </FlexContainer>
      </form>
    </FormProvider>
  );
}
