/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { buildChartTheme, TooltipData } from "@visx/xychart";
import dayjs from "dayjs";
import { When } from "react-if";
import { Text, theme } from "@nordcloud/gnui";
import { TimePointDetailsTable } from "~/components/Charts/components";
import { percentageFormat } from "~/components/Charts/utils";
import { dateFormat } from "~/constants";
import {
  convertToPercent,
  generateNumericArray,
  getFirstItem,
  getLastItem,
} from "~/tools";
import { CostAllocationTimePoint } from "./types";

type GenerateDataSeriesProps = {
  start: string;
  end: string;
  timePoints: CostAllocationTimePoint[];
};

function generateDataSeries({
  start,
  end,
  timePoints,
}: GenerateDataSeriesProps) {
  const startDate = dayjs(start);
  const endDate = dayjs(end);

  const seriesFrequency = "day";
  const arraySize = endDate.diff(startDate, seriesFrequency);
  const marginSize = Math.ceil(arraySize * 0.05);
  const dates = generateNumericArray(arraySize + 1 + marginSize);

  return dates.map((dayNumber) => {
    const date = startDate
      .add(dayNumber, seriesFrequency)
      .format(dateFormat.shortDate);

    return {
      date,
      ...getValueFromTimePoint(date, timePoints),
    };
  });
}

export function generateSegmentedDataSeries(
  props: GenerateDataSeriesProps
): GenerateDataSeriesProps["timePoints"][] {
  const dataSeries = generateDataSeries(props);

  return (
    dataSeries
      .reduce<ReturnType<typeof generateDataSeries>[]>(
        // eslint-disable-next-line max-params
        (acc, curr, index, array) => {
          const lastSegment = getLastItem(acc) ?? [];
          const modifiedLastSegment =
            array[index - 1]?.isEstimated === curr.isEstimated
              ? [[...lastSegment, curr]]
              : [lastSegment, [curr]];
          return [...acc.slice(0, -1), ...modifiedLastSegment];
        },
        []
      )
      // Remove empty first series
      .slice(1)
      .map((segment, index, array) => {
        // Add padding for estimated values
        if (getFirstItem(segment).isEstimated) {
          // Don't pad segment if it's first segment to avoid TypeError on `-1` index
          const leftPadding =
            index === 0
              ? []
              : [getEstimationPadding(getLastItem(array[index - 1]))];

          // Don't pad if it is the last segment
          const rightPadding =
            index === array.length - 1
              ? []
              : [getEstimationPadding(getFirstItem(array[index + 1]))];

          return [...leftPadding, ...segment, ...rightPadding];
        }

        return segment;
      })
      // Filter out segments with only one element, when one not estimated value pops in the chain of estimated ones
      // it'd overlap areas and create darker backgrounds
      .filter((segment) => segment.length !== 1)
  );
}

function getEstimationPadding(
  item: ReturnType<typeof generateDataSeries>[number]
) {
  return {
    ...item,
    isEstimationPadding: true,
  };
}

function getValueFromTimePoint(
  date: string,
  timePoints: CostAllocationTimePoint[]
) {
  const timePoint = timePoints.find(({ date: timePointDate }) =>
    dayjs(timePointDate).isSame(date, "day")
  );
  if (!timePoint) {
    return { value: undefined, isEstimated: false, isEstimationPadding: false };
  }
  const { value, ...rest } = timePoint;
  return { value: convertToPercent(Number(value)), ...rest };
}

export function renderTooltip(
  tooltipData: TooltipData<CostAllocationTimePoint> | undefined
) {
  if (!tooltipData?.nearestDatum?.datum) {
    return;
  }

  const { date, value, isEstimated } = tooltipData.nearestDatum.datum;

  if (value === undefined) {
    return;
  }

  return (
    <>
      <Text size="sm" mb="0" color={theme.color.text.text04}>
        {dayjs(date).format(dateFormat.dayShortMonthYear)}
      </Text>
      <TimePointDetailsTable
        values={[
          {
            key: "Allocation",
            value: `${percentageFormat(value)}${isEstimated ? "*" : ""}`,
            color: theme.color.text.text04,
          },
        ]}
      />
      <When condition={isEstimated}>
        <Text
          size="sm"
          mb="0"
          mt={theme.spacing.spacing02}
          color={theme.color.text.text04}
        >
          *This KPI Value has been estimated based on the previous days&apos;
          data
        </Text>
      </When>
    </>
  );
}

export const chartTheme = buildChartTheme({
  backgroundColor: theme.color.interactive.primary,
  colors: ["", theme.color.text.text01],
  gridColor: theme.color.border.focus,
  gridColorDark: theme.color.border.focus,
  tickLength: 0,
});
