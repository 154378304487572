/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { When } from "react-if";
import {
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsAndCommitmentsFilters,
  ReservationsAndCommitmentsLookbackPeriod,
  ReservationsProvider,
} from "~/generated/graphql";
import { isNotNil } from "~/tools";
import { MULTI_REGIONS_RESOURCE } from "../../constants";
import { UsageCoverageData } from "../../types";
import { ReservationCoverageChild } from "./ReservationCoverageChild";
import { TableItems } from "./TableItems";

type Props = {
  resources: UsageCoverageData;
  offeringType?: ReservationsAndCommitmentsCoverageOfferingType;
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  timePeriod?: ReservationsAndCommitmentsLookbackPeriod;
  openSidebar: (rowDetails: UsageCoverageData) => void;
  filters?: ReservationsAndCommitmentsFilters;
  provider: ReservationsProvider;
};

export function CoverageTabAccordionItem({
  resources,
  offeringType,
  serviceUsageType,
  timePeriod,
  provider,
  openSidebar,
}: Props) {
  const isExpandedStatus =
    isNotNil(MULTI_REGIONS_RESOURCE[resources.platform]) &&
    resources.hasRecommendations;

  const [isExpanded, setIsExpanded] = useState(isExpandedStatus);

  const accordionStateUpdate = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <>
      <TableItems
        isParent
        resources={resources}
        iconClick={accordionStateUpdate}
        isExpanded={isExpanded}
        openSidebar={openSidebar}
        offeringType={offeringType}
        serviceUsageType={serviceUsageType}
        provider={provider}
      />

      <When condition={isExpanded}>
        <ReservationCoverageChild
          resources={resources}
          serviceUsageType={serviceUsageType}
          timePeriod={timePeriod}
          offeringType={offeringType}
          openSidebar={openSidebar}
          provider={provider}
        />
      </When>
    </>
  );
}
