/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { Box, theme } from "@nordcloud/gnui";
import { Costs } from "../../types";
import { Ec2Header } from "../Ec2Header";

type Props = {
  name: string;
  providerId: string;
  providerType: string;
  account: string;
  costs: Costs;
};

export function DefaultEc2Header({
  name,
  providerId,
  providerType,
  account,
  costs,
}: Props) {
  return (
    <Box>
      <HeaderWrapper>
        <Ec2Header
          name={name}
          providerId={providerId}
          providerType={providerType}
          account={account}
          costs={costs}
        />
      </HeaderWrapper>
    </Box>
  );
}

const HeaderWrapper = styled.div`
  display: grid;
  width: 100%;
  row-gap: ${theme.spacing.spacing02};
  grid-template-rows: 1fr 1px 2fr;
  grid-template-columns: 60% 40%;
  grid-template-areas:
    "name costs"
    "line costs"
    "details costs";

  @media screen and (max-width: ${theme.breakpoints.lg}px) {
    height: 100%;
    grid-template-rows: 1fr 1px 2fr 3fr;
    grid-template-columns: 1fr;
    grid-template-areas:
      "name"
      "line"
      "details"
      "costs";
  }
`;
