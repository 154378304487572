/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { inflect } from "~/tools";
import { AppEnvResult } from "./types";

export function getInputLabel<T>(apps: T[], envs: T[]) {
  const numApps = apps.length;
  const numEnvs = envs.length;
  const appNoun = inflect("application")(numApps);
  const envNoun = inflect("environment")(numEnvs);

  if (numApps && numEnvs) {
    return `${numApps} ${appNoun} and ${numEnvs} ${envNoun} selected`;
  }
  if (numApps) {
    return `${numApps} ${appNoun} selected`;
  }
  if (numEnvs) {
    return `${numEnvs} ${envNoun} selected`;
  }
  return "";
}

export function getButtonLabel<T>(apps: T[], envs: T[]) {
  return getInputLabel(apps, envs) || "Apply";
}

const createEmptyAppObject = (nid: string) => ({
  id: "",
  nid,
  name: "",
  environments: [],
});

export function generateSelectedApps(
  selectedAppNids: string[],
  data?: AppEnvResult
) {
  return selectedAppNids.map((nid) => {
    if (!data) {
      return createEmptyAppObject(nid);
    }

    const app = data?.applicationsPaginated?.results?.find(
      (a) => a.nid === nid
    );

    return app ?? createEmptyAppObject(nid);
  });
}

export function generateSelectedEnvs(selectedEnvNids: string[]) {
  return selectedEnvNids.map((selectedEnvNid) => ({
    id: "",
    nid: selectedEnvNid,
    name: "",
  }));
}
