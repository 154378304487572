/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Box, Spacer, theme } from "@nordcloud/gnui";
import { SavingsPlansCommitment } from "~/generated/graphql";
import { DataContainer, DataItem } from "~/components";
import { useCurrency } from "~/hooks";
import { formatMoney } from "~/tools";

type Props = {
  savingsPlanData: SavingsPlansCommitment;
  withSpacing: boolean;
};

export function CommitmentContainer({ savingsPlanData, withSpacing }: Props) {
  const { currency } = useCurrency();
  const {
    account,
    savingsPlansHourlyCommitmentToPurchase,
    onDemandCostPerHour,
    savingsPerHour,
  } = savingsPlanData;

  const accountValue = account ?? "";

  return (
    <>
      <Box boxStyle="lightGrey">
        <DataContainer marginBottom="0">
          <DataItem
            size="md"
            label="Account"
            value={accountValue}
            grow={false}
            mb={theme.spacing.spacing01}
            padding="0"
            itemWidth="20%"
          />
          <DataItem
            size="md"
            label="SP Commitment / hour"
            itemWidth="20%"
            grow={false}
            mb={theme.spacing.spacing01}
            value={formatMoney(
              savingsPlansHourlyCommitmentToPurchase,
              currency
            )}
          />
          <DataItem
            size="md"
            label="Avg. Cost / hour"
            value={formatMoney(onDemandCostPerHour, currency)}
            itemWidth="20%"
            grow={false}
            mb={theme.spacing.spacing01}
          />
          <DataItem
            size="md"
            label="Savings / hour"
            value={formatMoney(savingsPerHour, currency)}
            itemWidth="20%"
            grow={false}
            mb={theme.spacing.spacing01}
          />
        </DataContainer>
      </Box>
      <When condition={withSpacing}>
        <Spacer height={theme.spacing.spacing02} />
      </When>
    </>
  );
}
