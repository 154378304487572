/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import {
  Button,
  ExtendedTooltip,
  FlexContainer,
  Spacer,
  SVGIcon,
  Text,
  theme,
} from "@nordcloud/gnui";
import { Co2eSavings } from "~/components";
import { useCurrency } from "~/hooks";
import { useBillingPeriod } from "~/services/customers";
import { formatMoney } from "~/tools";
import { getCo2eSavingsTitle, getIconName, getUrlButtonText } from "./helpers";
import { Item } from "./Item";
import {
  Action,
  Actions,
  Carbon,
  ExternalLinkWrapper,
  Icon,
  Line,
  Savings,
  Wrapper,
} from "./styles";
import { CostSaving, DismissModalPayload } from "./types";

type Props = {
  costSaving: CostSaving;
  provider: string;
  isLast: boolean;
  onSetOpenModal: (v: DismissModalPayload) => void;
};

export function SavingSuggestionsItem({
  costSaving,
  provider,
  isLast,
  onSetOpenModal,
}: Props) {
  const { currency } = useCurrency();
  const { isCurrentBillingPeriod } = useBillingPeriod();

  const { iconName } = getIconName(costSaving.policyId);

  const showActions =
    isCurrentBillingPeriod && costSaving.cloudUrl && costSaving.detectionId;

  return (
    <Wrapper isLast={isLast}>
      <Icon>
        <SVGIcon name={iconName} size="lg" />
      </Icon>
      <Action>
        <Item title={costSaving.problem} content={costSaving.action} />
      </Action>
      <Savings>
        <Item
          title={<SavingsTitle />}
          content={
            <SavingsContent
              cost={costSaving.cost}
              annualCost={costSaving.annualCost}
              currency={currency}
            />
          }
          align="end"
        />
      </Savings>
      <Carbon>
        <When condition={parseFloat(costSaving.co2e ?? "0") > 0}>
          <Item
            title={getCo2eSavingsTitle(costSaving.co2e)}
            content={
              <Co2eSavings
                co2eSavings={parseFloat(costSaving.co2e ?? "0")}
                fontSize="md"
                hideSuffix
              />
            }
            align="end"
          />
        </When>
      </Carbon>
      <When condition={showActions}>
        <Actions>
          <FlexContainer columnGap={theme.spacing.spacing02}>
            <Button severity="high" size="md" icon="externalLink">
              <ExternalLinkWrapper href={costSaving.cloudUrl ?? ""}>
                {getUrlButtonText(costSaving.policyId, provider)}
              </ExternalLinkWrapper>
            </Button>
            <ExtendedTooltip caption="Dismiss">
              <Button
                severity="low"
                size="md"
                icon="cancel"
                css={{
                  height: "2.125rem",
                }}
                onClick={() =>
                  onSetOpenModal({
                    isOpen: true,
                    detectionId: costSaving.detectionId ?? undefined,
                  })
                }
              />
            </ExtendedTooltip>
          </FlexContainer>
        </Actions>
      </When>
      <When condition={!isLast}>
        <Line>
          <Spacer border={`solid 1px ${theme.color.border.border01}`} />
        </Line>
      </When>
    </Wrapper>
  );
}

function SavingsTitle() {
  return (
    <FlexContainer columnGap={theme.spacing.spacing01}>
      <Text mb={0} size="sm" color={theme.color.text.text02} nowrap>
        Monthly Savings
      </Text>
      <Text mb={0} size="xs" color={theme.color.text.text02} nowrap>
        (Next 12 Months)
      </Text>
    </FlexContainer>
  );
}

type SavingsContentProps = {
  cost: string;
  annualCost: string;
  currency: string;
};

function SavingsContent({ cost, annualCost, currency }: SavingsContentProps) {
  return (
    <FlexContainer columnGap={theme.spacing.spacing02}>
      <Text weight="medium" color="success" mb={0}>
        {formatMoney(Number(cost).toFixed(2), currency)}
      </Text>
      <Text color="success" mb={0} size="sm">
        ({formatMoney(Number(annualCost).toFixed(2), currency)})
      </Text>
    </FlexContainer>
  );
}
