/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState, useMemo, useCallback } from "react";
import { When } from "react-if";
import {
  Box,
  Col,
  Dropdown,
  ExtendedPaginationBox,
  FlexContainer,
  Row,
  Sidebar,
  theme,
} from "@nordcloud/gnui";
import {
  ReservationAndMetadataFilters,
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsProvider,
  ReservationsRecommendationPaymentOption,
  ReservationsRecommendationTerm,
} from "~/generated/graphql";
import { ReactTable, UniversalWrap } from "~/components";
import { TermOptions } from "../constants";
import { useReservationsExistingLinks } from "../hooks";
import { valueFormatter, getPaymentOptions } from "../utils";
import { ReservationsExistingLinkColumns } from "./ReservationsLinkSidebarColumns";

const enum FILTER_TYPE {
  RESOURCE = "resource",
  PLATFORM = "platform",
  PAYMENT_OPTION = "paymentOption",
  TERM = "term",
}

type Props = {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  offeringType: ReservationsAndCommitmentsCoverageOfferingType;
  showLinks: boolean;
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  provider: ReservationsProvider;
  hasUtilization: boolean;
};

export function ReservationExistingLink({
  isSidebarOpen,
  toggleSidebar,
  offeringType,
  showLinks,
  serviceUsageType,
  provider,
  hasUtilization,
}: Props) {
  const [filters, setFilters] = useState<ReservationAndMetadataFilters>();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const columns = useMemo(
    () =>
      ReservationsExistingLinkColumns({
        showLinks,
        offeringType,
        provider,
        serviceUsageType,
        hasUtilization,
      }),
    [offeringType, showLinks, provider, serviceUsageType]
  );

  const { data, loading, error, instanceTypeOptions, platformOptions } =
    useReservationsExistingLinks({
      serviceUsageType,
      offeringType,
      page,
      pageSize,
      filters,
      provider,
    });
  const isReservedInstance =
    offeringType ===
    ReservationsAndCommitmentsCoverageOfferingType.ReservedInstances;

  const title = isReservedInstance
    ? "List of Existing RIs"
    : "List of Existing SPs";

  const handleResetFilter = useCallback(() => {
    toggleSidebar();
    setPage(0);
    setFilters({
      ...filters,
      resource: undefined,
      platform: undefined,
      term: undefined,
      paymentOption: undefined,
    });
  }, [filters, toggleSidebar, setPage, setFilters]);

  const updateFilter = useCallback(
    (
      filterType: FILTER_TYPE,
      value:
        | ReservationsRecommendationPaymentOption
        | ReservationsRecommendationTerm
        | string
        | undefined = undefined
    ) => {
      const filterValue = {
        ...filters,
        [filterType]: value,
      } as ReservationAndMetadataFilters;
      setPage(0);
      setFilters(filterValue);
    },
    [filters, setPage, setFilters]
  );

  const handleInstanceTypeClear = useCallback(
    (): void => updateFilter(FILTER_TYPE.RESOURCE),
    [updateFilter]
  );
  const handlePlatformClear = useCallback(
    (): void => updateFilter(FILTER_TYPE.PLATFORM),
    [updateFilter]
  );
  const handleTermClear = useCallback(
    (): void => updateFilter(FILTER_TYPE.TERM),
    [updateFilter]
  );
  const handlePaymentOptionsClear = useCallback(
    (): void => updateFilter(FILTER_TYPE.PAYMENT_OPTION),
    [updateFilter]
  );

  const handleInstanceTypeChange = useCallback(
    (value: string): void => updateFilter(FILTER_TYPE.RESOURCE, value),
    [updateFilter]
  );
  const handlePlatformChange = useCallback(
    (value: string): void => updateFilter(FILTER_TYPE.PLATFORM, value),
    [updateFilter]
  );
  const handleTermChange = useCallback(
    (value: string): void => updateFilter(FILTER_TYPE.TERM, value),
    [updateFilter]
  );
  const handlePaymentOptionsChange = useCallback(
    (value: string): void => updateFilter(FILTER_TYPE.PAYMENT_OPTION, value),
    [updateFilter]
  );

  const setNewPage = useCallback(
    (newPage: number) => {
      setPage(newPage / pageSize);
    },
    [setPage, pageSize]
  );

  const isProviderAws = provider === ReservationsProvider.Aws;

  return (
    <Sidebar
      title={title}
      isOpen={isSidebarOpen}
      width="65rem"
      onClick={handleResetFilter}
    >
      <UniversalWrap
        loaderProps={{ loading, inContent: true }}
        errorProps={{ error }}
      >
        <Box
          boxStyle="lightGrey"
          p={theme.spacing.spacing02}
          mb={theme.spacing.spacing04}
        >
          <FlexContainer>
            <Row>
              <When condition={isReservedInstance && isProviderAws}>
                <Col>
                  <Dropdown
                    name="Instance Type"
                    options={instanceTypeOptions}
                    size="sm"
                    value={valueFormatter(filters?.resource)}
                    onClear={handleInstanceTypeClear}
                    onChange={handleInstanceTypeChange}
                  />
                </Col>
                <Col>
                  <Dropdown
                    name="OS"
                    options={platformOptions}
                    size="sm"
                    value={valueFormatter(filters?.platform)}
                    onClear={handlePlatformClear}
                    onChange={handlePlatformChange}
                  />
                </Col>
              </When>
              <Col>
                <Dropdown
                  name="Term"
                  options={TermOptions ?? []}
                  size="sm"
                  value={valueFormatter(filters?.term)}
                  onChange={handleTermChange}
                  onClear={handleTermClear}
                />
              </Col>
              <Col>
                <Dropdown
                  name="Payment Option"
                  options={getPaymentOptions(provider)}
                  size="sm"
                  value={valueFormatter(filters?.paymentOption)}
                  onClear={handlePaymentOptionsClear}
                  onChange={handlePaymentOptionsChange}
                />
              </Col>
            </Row>
          </FlexContainer>
        </Box>
        <ReactTable
          columns={columns}
          data={data?.reservationsAndMetadata?.reservations ?? []}
          mb={theme.spacing.spacing04}
          sort={false}
        />

        <ExtendedPaginationBox
          count={data?.reservationsAndMetadata?.count ?? 0}
          from={page * pageSize}
          setSize={setPageSize}
          size={pageSize}
          setPage={setNewPage}
        />
      </UniversalWrap>
    </Sidebar>
  );
}
