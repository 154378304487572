/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then } from "react-if";
import { Button, ButtonProps, ExtendedTooltip, theme } from "@nordcloud/gnui";
import { useBillingPeriod } from "~/services/customers";

type Props = Pick<ButtonProps, "onClick">;

export function LinkEditButton({ onClick }: Props) {
  const { isPreviousBillingPeriod } = useBillingPeriod();

  return (
    <If condition={isPreviousBillingPeriod}>
      <Then>
        <ExtendedTooltip caption="Operation is not allowed in the previous billing period.">
          <LinkButton disabled onClick={onClick} />
        </ExtendedTooltip>
      </Then>
      <Else>
        <LinkButton onClick={onClick} />
      </Else>
    </If>
  );
}

type LinkButtonProps = Pick<
  Extract<ButtonProps, { disabled?: boolean }>,
  "disabled" | "onClick"
>;

function LinkButton({ onClick, disabled }: LinkButtonProps) {
  return (
    <Button
      disabled={disabled}
      data-testid="btn-edit"
      severity="low"
      size="sm"
      icon="edit"
      css={{
        marginLeft: theme.spacing.spacing01,
        borderRadius: "22px",
        transition: theme.transition,
      }}
      onClick={onClick}
    />
  );
}
