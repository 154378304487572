/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import dayjs from "dayjs";
import { useApplicationBudgetQuery } from "~/generated/graphql";
import {
  Period,
  TimePoint,
  TableData,
} from "~/components/Charts/CostAnalysis/types";
import {
  calculateTotalBudget,
  mapCostChartData,
  mapPeriodForCostChartData,
} from "~/components/Charts/CostAnalysis/utils";
import { dateFormat } from "~/constants";
import { isNotEmpty } from "~/tools";

type Props = {
  applicationId: string;
  currentYear: string;
  period: Period;
  accumulatedCostBudgetChartData: TimePoint[];
  startDate: string;
  endDate: string;
  accumulatedCostTimePoints: TimePoint[];
  accumulatedCostTotal: number;
};

export function useCostVersusBudgetChart({
  applicationId,
  currentYear,
  period,
  accumulatedCostBudgetChartData,
  startDate,
  endDate,
  accumulatedCostTimePoints,
  accumulatedCostTotal,
}: Props) {
  const { data, loading, error } = useApplicationBudgetQuery({
    variables: {
      id: applicationId,
      year: currentYear,
      previousYear: (parseInt(currentYear, 10) - 1).toString(),
    },
  });

  const applicationBudgetData =
    data?.application?.budgetYearly.budgetByMonth.map((value, index) => {
      const date = dayjs()
        .startOf("year")
        .add(index, "month")
        .format(dateFormat.shortDate);
      return {
        date,
        value: Number(value),
      };
    });

  const costVersusBudgetChartData = useMemo(
    () =>
      mapCostChartData(
        mapPeriodForCostChartData(period, startDate, endDate),
        accumulatedCostBudgetChartData
      ),
    [period, accumulatedCostBudgetChartData, startDate, endDate]
  );

  const applicationBudgetTotal = useMemo(
    () =>
      Number(
        calculateTotalBudget(
          startDate,
          endDate,
          data?.application?.budgetYearly
        )
      ),
    [startDate, endDate, data]
  );

  const applicationBudgetTable: TableData[] = useMemo(
    () => [
      {
        field: "Cost",
        total: Number(accumulatedCostTotal),
        ...accumulatedCostTimePoints?.reduce(
          (acc, { date, value }) => ({ ...acc, [date]: Number(value ?? 0) }),
          {}
        ),
      },
      {
        field: "Budget",
        total: applicationBudgetTotal,
        ...data?.application?.budgetYearly.budgetByMonth?.reduce(
          (acc, budget, index) => {
            const date = dayjs()
              .startOf("year")
              .add(index, "month")
              .format(dateFormat.shortDate);
            return { ...acc, [date]: budget };
          },
          {}
        ),
      },
    ],
    [
      accumulatedCostTotal,
      applicationBudgetTotal,
      data,
      accumulatedCostTimePoints,
    ]
  );

  const hasBudget =
    isNotEmpty(applicationBudgetData ?? []) &&
    applicationBudgetData?.some(({ value }) => value !== 0);

  return {
    costVersusBudgetChartData,
    applicationBudgetTable,
    applicationBudgetData,
    applicationBudgetTotal,
    hasBudget,
    loading,
    error,
  };
}
