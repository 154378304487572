/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { SVGIcon, theme, FlexContainer } from "@nordcloud/gnui";
import { StyledTd } from "./styles";

type Props = {
  hasCoverageResourceList: boolean;
  isExpanded?: boolean;
  iconClick?: () => void;
};

export function CoverageTableExpandable({
  hasCoverageResourceList,
  isExpanded,
  iconClick,
}: Props) {
  return (
    <StyledTd hasRightBorder withPadding width="2%">
      <When condition={hasCoverageResourceList}>
        <FlexContainer
          justifyContent="center"
          alignContent="start"
          direction="column"
        >
          <SVGIcon
            data-testid="resourceListIcon"
            name="chevronDown"
            title="chevronDown"
            size="md"
            style={{
              display: "flex",
              marginRight: theme.spacing.spacing04,
              cursor: "pointer",
              transition: "transform .1s linear",
              transform: isExpanded ? "rotate(0deg)" : "rotate(-90deg)",
            }}
            onClick={iconClick}
          />
        </FlexContainer>
      </When>
    </StyledTd>
  );
}
