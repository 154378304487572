/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ColumnDef } from "@tanstack/react-table";
import { Else, If, Then, When } from "react-if";
import styled from "styled-components";
import { theme } from "@nordcloud/gnui";
import { formatMoney, sum } from "~/tools";
import { ChartType, LinkParameters, Period, TableData } from "../types";
import { anomaliesChartColors } from "./consts";
import { TableCell } from "./TableCell";
import { formatChartDates } from "./utils";

type ColumnsProps = {
  startDate: string;
  endDate: string;
  currency: string;
  hasFooter: boolean;
  data: TableData[];
  chartType: ChartType;
  period: Period;
  colorsPalette?: string[];
  linkParameters?: LinkParameters;
};

type GeneratedColumnProps = {
  header: { date: string; displayDate: string };
  currency: string;
  hasFooter: boolean;
  chartType: ChartType;
  data: TableData[];
};

function generateDateColumn({
  header,
  currency,
  hasFooter,
  chartType,
  data,
}: GeneratedColumnProps): ColumnDef<TableData>[] {
  const totalSum = data
    .map((item) => Number(item[header.date] ?? 0))
    .reduce((a, b) => a + b, 0);

  const budgetOutcome = data
    .map((item) => Number(item[header.date] ?? 0))
    .reduce((a, b) => b - a, 0);

  const isCostVsBudget = chartType === ChartType.COST_VS_BUDGET;
  const value = isCostVsBudget ? budgetOutcome : totalSum;
  const valueFormat = value === 0 ? "-" : formatMoney(value, currency);

  return [
    {
      header: header.displayDate,
      accessorKey: header.date,
      footer: () => (
        <When condition={hasFooter}>
          <StyledText chartType={chartType} value={value} isFooter={hasFooter}>
            {valueFormat}
          </StyledText>
        </When>
      ),
      cell: ({ row }) => getStyledText(row?.original, currency, header.date),
    },
  ];
}

export function getTableColumns({
  startDate,
  endDate,
  currency,
  hasFooter,
  data,
  chartType,
  colorsPalette,
  period,
  linkParameters,
}: ColumnsProps): ColumnDef<TableData>[] {
  const headers = formatChartDates(startDate, endDate, period);

  const totalSum = sum(data.map((item) => Number(item.total ?? 0)));
  const budgetOutcome = data
    .map((item) => Number(item.total ?? 0))
    .reduce((a, b) => b - a, 0);

  const finalValue =
    chartType === ChartType.COST_VS_BUDGET ? budgetOutcome : totalSum;

  return [
    {
      header: "",
      accessorKey: "field",
      footer: () => (
        <When condition={hasFooter}>
          {chartType === ChartType.COST_VS_BUDGET ? "Outcome" : "Total"}
        </When>
      ),
      cell: ({ row }) => {
        return (
          <TableCell
            row={row}
            showColorIcon={showColorBox(chartType, colorsPalette)}
            chartType={chartType}
            color={colorsPalette ? colorsPalette[row.index] : "primary"}
            linkParameters={linkParameters}
          />
        );
      },
    },
    ...headers.flatMap((header) =>
      generateDateColumn({
        header,
        currency,
        data,
        hasFooter,
        chartType,
      })
    ),
    {
      header: "Total",
      accessorKey: "total",
      footer: () => (
        <When condition={hasFooter}>
          <StyledText
            chartType={chartType}
            value={finalValue}
            isFooter={hasFooter}
          >
            {formatMoney(finalValue, currency)}
          </StyledText>
        </When>
      ),
      cell: ({ row }) => getStyledText(row?.original, currency),
    },
  ];
}

const StyledText = styled.div<{
  chartType: ChartType;
  value: number;
  isFooter?: boolean;
}>`
  color: ${({ chartType, value, isFooter }) => {
    if (chartType === ChartType.COST_VS_BUDGET && value !== 0) {
      return Math.sign(value) === -1
        ? theme.color.text.error
        : theme.color.text.success;
    }

    if (chartType === ChartType.ANOMALY_COST && value > 0 && !isFooter) {
      return anomaliesChartColors.barWithAnomaly;
    }
    return theme.color.text.text01;
  }};
`;

function getStyledText(
  tableData: TableData | undefined,
  currency: string,
  date?: string
) {
  const showAnomaly = tableData?.field === "Anomaly Cost" && tableData?.total;
  const value = date
    ? Number(tableData?.[date] ?? 0)
    : Number(tableData?.total ?? 0);
  const anomalyPrefix = showAnomaly && value > 0 ? "+" : "";
  const textFormat = value ? formatMoney(value, currency) : "-";

  return (
    <If condition={showAnomaly}>
      <Then>
        <StyledText chartType={ChartType.ANOMALY_COST} value={value}>
          {`${anomalyPrefix}${textFormat}`}
        </StyledText>
      </Then>
      <Else>{textFormat}</Else>
    </If>
  );
}

function showColorBox(
  chartType: ChartType,
  colorsPalette: string[] | undefined
) {
  return (
    colorsPalette !== undefined &&
    !(
      chartType === ChartType.COST_VS_BUDGET ||
      chartType === ChartType.ANOMALY_COST
    )
  );
}
