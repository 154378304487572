/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Text, theme } from "@nordcloud/gnui";

type Props = {
  title: string;
};

export function SubmenuTitle({ title }: Props) {
  return (
    <Text color={theme.color.text.text02} mb={theme.spacing.spacing02}>
      {title}
    </Text>
  );
}
