/* *
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FieldError, UseFormRegister } from "react-hook-form";
import {
  Checkbox,
  FlexContainer,
  Label,
  Modal,
  Text,
  theme,
} from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { useDisclosure } from "~/hooks";
import { WorkflowFormData } from "../../types";
import { SLACK_MSG_PREVIEW_MODAL_TITLE } from "../constants";
import { PreviewButton } from "../EmailSettingsForm/PreviewButton";
import { KpiPreviewMessage } from "../PreviewMessages";
import { SlackErrors } from "./types";

type Props = {
  isReadMode?: boolean;
  register: UseFormRegister<WorkflowFormData>;
  slackErrors: SlackErrors;
};

export function KpiSelectDataType({
  isReadMode,
  register,
  slackErrors,
}: Props) {
  const { isOpen, close, open } = useDisclosure();

  return (
    <>
      <FlexContainer
        paddingBottom={theme.spacing.spacing02}
        alignItems="center"
        justifyContent="space-between"
      >
        <Label
          css={{ marginBottom: theme.spacing.spacing00, textTransform: "none" }}
          name="Select Data Type(s)"
          required={!isReadMode}
        />
        <PreviewButton type="button" size="sm" onClick={open}>
          Sample Message
        </PreviewButton>
      </FlexContainer>
      <Text size="sm" color={theme.color.text.text03}>
        KPIs for selected Business Context will be sent. Please select one or
        more data types to be sent to your Slack Channel.
      </Text>
      <FormGroup error={slackErrors?.kpiOptions as FieldError}>
        <Checkbox
          disabled={isReadMode}
          {...register("slack.kpiOptions.costAllocation")}
          labelText="Cost Allocation Coverage KPI"
        />
        <Checkbox
          disabled={isReadMode}
          {...register("slack.kpiOptions.overallWaste")}
          labelText="Overall Waste KPI"
        />
        <Checkbox
          disabled={isReadMode}
          {...register("slack.kpiOptions.nonProdHours")}
          labelText="Non-Production Environments Shutdown Time KPI"
        />
      </FormGroup>
      <Modal
        isOpen={isOpen}
        actions={[]}
        contentMaxHeight="40rem"
        contentLabel={SLACK_MSG_PREVIEW_MODAL_TITLE}
        onClose={close}
      >
        <KpiPreviewMessage />
      </Modal>
    </>
  );
}
