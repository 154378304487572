/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ReservationsAndCommitmentsCoverageOfferingType } from "~/generated/graphql";
import { CoverageChartFilters } from "../types";

type TabsProps = {
  isComputeTab: boolean;
  showFilters: boolean;
  offeringType: ReservationsAndCommitmentsCoverageOfferingType;
  reservationOfferingType: ReservationsAndCommitmentsCoverageOfferingType;
  filters: CoverageChartFilters;
};

export function useGetTabsData({
  isComputeTab,
  showFilters,
  offeringType,
  reservationOfferingType,
  filters,
}: TabsProps) {
  const currentOfferingType = isComputeTab
    ? offeringType
    : reservationOfferingType;
  const selectedFilters = showFilters ? filters : {};

  return {
    currentOfferingType,
    selectedFilters,
    showFilters,
  };
}
