/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Provider } from "~/generated/graphql";
import { Costs } from "../../types";
import { EstateRecord } from "../types";

export function useHeaderData(estateRecord: EstateRecord, costs: Costs) {
  return {
    name: estateRecord.name ?? "",
    providerId: estateRecord.providerId ?? "",
    providerType: estateRecord.providerType ?? Provider.Aws,
    account: estateRecord.cloudAccountId ?? "",
    costs,
    cloudAccountName: "",
  };
}
