/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as yup from "yup";
import { ThresholdGranularity } from "../types";
import { REQUIRED_FIELD_MESSAGE } from "./constants";

export const enum ThresholdFormFields {
  NAME = "name",
  THRESHOLD_AMOUNT = "thresholdAmount",
  GRANULARITY = "granularity",
  USER_RECIPIENTS = "userRecipients",
  EXTERNAL_RECIPIENTS = "externalRecipients",
  PROVIDER = "provider",
  ACCOUNTS_IDS = "accountsIds",
  SERVICE_IDS = "serviceIds",
}

export type ThresholdFormData = {
  [ThresholdFormFields.NAME]: string;
  [ThresholdFormFields.THRESHOLD_AMOUNT]: number;
  [ThresholdFormFields.GRANULARITY]: ThresholdGranularity;
  [ThresholdFormFields.USER_RECIPIENTS]: string[];
  [ThresholdFormFields.EXTERNAL_RECIPIENTS]: string[];
  [ThresholdFormFields.PROVIDER]: string[];
  [ThresholdFormFields.ACCOUNTS_IDS]: string[];
  [ThresholdFormFields.SERVICE_IDS]: string[];
};

const emailRegex = /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,}$/i;

export const thresholdSchema = yup.object().shape({
  [ThresholdFormFields.NAME]: yup.string().required(REQUIRED_FIELD_MESSAGE),
  [ThresholdFormFields.THRESHOLD_AMOUNT]: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .test("is-number", "Invalid number", (value) => {
      if (!value) {
        return true;
      }

      return !isNaN(Number(value));
    }),
  [ThresholdFormFields.GRANULARITY]: yup.string().required(),
  [ThresholdFormFields.USER_RECIPIENTS]: yup
    .array()
    .of(yup.string())
    .required(REQUIRED_FIELD_MESSAGE),
  [ThresholdFormFields.EXTERNAL_RECIPIENTS]: yup
    .array()
    .of(yup.string().matches(emailRegex, "Provided emails must be valid"))
    .required(REQUIRED_FIELD_MESSAGE),
  [ThresholdFormFields.PROVIDER]: yup.array().of(yup.string()).required(),
  [ThresholdFormFields.ACCOUNTS_IDS]: yup.array().of(yup.string()).required(),
  [ThresholdFormFields.SERVICE_IDS]: yup.array().of(yup.string()).required(),
});

export const ADD_THRESHOLDS_DEFAULT_VALUES = {
  [ThresholdFormFields.NAME]: "",
  [ThresholdFormFields.THRESHOLD_AMOUNT]: undefined,
  [ThresholdFormFields.GRANULARITY]: ThresholdGranularity.Daily,
  [ThresholdFormFields.USER_RECIPIENTS]: [],
  [ThresholdFormFields.EXTERNAL_RECIPIENTS]: [],
  [ThresholdFormFields.PROVIDER]: ["Aws"],
  [ThresholdFormFields.ACCOUNTS_IDS]: [],
  [ThresholdFormFields.SERVICE_IDS]: [],
};
