/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  Person,
  WorkflowExternalIntegrationType,
  WorkflowTriggerType,
  WorkflowFrequency,
  useGetWorkflowQuery,
  WorkflowEmailOptionsMessageType,
} from "~/generated/graphql";
import { Maybe } from "~/tools";
import { WorkflowDataType } from "./constants";

type WorkflowResponse = NonNullable<
  NonNullable<ReturnType<typeof useGetWorkflowQuery>["data"]>["workflow"]
>;

export type WorkflowProps = {
  active: boolean;
  contactPersons: Person[];
  createdAt: string;
  selectData: {
    type: WorkflowDataType;
    savingSuggestionsOptions?: {
      applications?: string[] | null;
      environments?: string[] | null;
      orgUnitIds?: string[] | null;
    } | null;
    kpiOptions?: {
      businessContextId: string;
    } | null;
    costsOptions?: {
      applications?: string[] | null;
      environments?: string[] | null;
      orgUnitIds?: string[] | null;
    } | null;
    budgetsOptions?: {
      applications?: string[] | null;
      environments?: string[] | null;
      forecastGtBudget: boolean;
      costGtBudget: boolean;
      orgUnitIds?: string[] | null;
    } | null;
    anomalyCostsOptions?: {
      applicationIds: string[];
    } | null;
  };
  description?: Maybe<string>;
  externalIntegrations: WorkflowExternalIntegrationProps[];
  id: string;
  lastRunDate: string;
  lastRunStatus: string;
  name: string;
  triggers: WorkflowTriggerProps[];
};

type WorkflowTriggerProps = {
  schedulerOptions?: Maybe<WorkflowSchedulerOptionsProps>;
  type: WorkflowTriggerType;
};

type WorkflowSchedulerOptionsProps = {
  byWeekday?: Maybe<number[]>;
  endDate?: Maybe<string>;
  frequency: WorkflowFrequency;
  interval?: Maybe<number>;
  startDate: string;
};

type WorkflowExternalIntegrationProps = {
  emailOptions?: Maybe<WorkflowEmailOptionsProps>;
  serviceNowOptions?: Maybe<WorkflowServiceNowOptionsProps>;
  slackOptions?: Maybe<WorkflowSlackOptionsProps>;
  id?: string;
  type: WorkflowExternalIntegrationType;
};

export type WorkflowSlackOptionsProps = {
  channel: string;
  message?: Maybe<string>;
  costsOptions?: Maybe<{
    totalMonthlyCost: boolean;
    totalMonthlyForecast: boolean;
    costsByProviders: boolean;
    costsByCategories: boolean;
  }>;
  kpiOptions?: Maybe<{
    costAllocation: boolean;
    overallWaste: boolean;
    nonProdHours: boolean;
  }>;
  savingSuggestionsOptions?: Maybe<{
    totalMonthlySavings: boolean;
    monthlySavingsByProviders: boolean;
    monthlySavingsByCategories: boolean;
    monthlySavingsByPolicies: boolean;
  }>;
};

export type WorkflowServiceNowOptionsProps = {
  additionalFields?: Maybe<WorkflowAdditionalFieldProps[]>;
  description?: Maybe<string>;
  shortDescription: string;
  url: string;
  username: string;
  costsOptions?: Maybe<{
    totalMonthlyCost: boolean;
    totalMonthlyForecast: boolean;
    costsByProviders: boolean;
    costsByCategories: boolean;
  }>;
  kpiOptions?: Maybe<{
    costAllocation: boolean;
    overallWaste: boolean;
    nonProdHours: boolean;
  }>;
  savingSuggestionsOptions?: Maybe<{
    totalMonthlySavings: boolean;
    monthlySavingsByPolicies: boolean;
    monthlySavingsByProviders: boolean;
    monthlySavingsByCategories: boolean;
  }>;
};

type WorkflowEmailOptionsProps = {
  sendToContactPersons?: Maybe<boolean>;
  mailTo?: Maybe<string[]>;
  subject: string;
  message: string;
  messageType: WorkflowEmailOptionsMessageType;
};

type WorkflowAdditionalFieldProps = {
  key: string;
  value: string;
};

export const mapWorkflowProps = (workflow: WorkflowResponse): WorkflowProps => {
  return {
    ...workflow,
    selectData: {
      ...workflow.selectData,
      type: mapSelectDataType(workflow.selectData.type),
    },
  };
};

function mapSelectDataType(type: WorkflowResponse["selectData"]["type"]) {
  switch (type) {
    case "SAVING_SUGGESTIONS":
      return WorkflowDataType.SAVING_SUGGESTIONS;
    case "UNALLOCATED_RESOURCES":
      return WorkflowDataType.UNALLOCATED_RESOURCES;
    case "KPI":
      return WorkflowDataType.KPI;
    case "COSTS":
      return WorkflowDataType.COSTS;
    case "BUDGETS":
      return WorkflowDataType.BUDGETS;
    case "ANOMALY_COSTS":
      return WorkflowDataType.ANOMALY_COSTS;
    case "CLOUD_CREDENTIALS":
      return WorkflowDataType.CLOUD_CREDENTIALS;
    default:
      throw new Error(`Unknown select data component type "${type}"`);
  }
}
