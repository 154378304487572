/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { formatMoney } from "~/tools";
import { AnomalyDetailVisual } from "~/views/applications/ApplicationCostTabs/components/AnomaliesTableConfig";
import {
  AnomalyEstateRecord,
  AnomalyService,
  AnomalyServiceSubtype,
} from "./types";

export function formatResources(
  anomalyDetails: AnomalyService[],
  currency: string,
  isInCorrectBillingPeriod: boolean
): AnomalyDetailVisual[] {
  return anomalyDetails.map((resource) => {
    const cost = resource.cost ?? 0;
    const anomalyCost = resource.anomalyCost ?? 0;
    const maxDailyCost = resource.maxDailyCost ?? 0;
    const minDailyCost = resource.minDailyCost ?? 0;

    return {
      ...resource,
      type: resource.type ?? "",
      cost: formatMoney(cost, currency),
      anomalyCost: formatMoney(anomalyCost, currency),
      maxDailyCost: formatMoney(maxDailyCost, currency),
      minDailyCost: formatMoney(minDailyCost, currency),
      anomalyCostRaw: `${anomalyCost.toFixed(12)} ${currency}`,
      subRows: formatSubtypes(
        resource.subRows ?? [],
        currency,
        isInCorrectBillingPeriod
      ),
    };
  });
}

function formatSubtypes(
  anomalySubTypes: AnomalyServiceSubtype[],
  currency: string,
  isInCorrectBillingPeriod: boolean
): AnomalyDetailVisual[] {
  return anomalySubTypes.map((subType) => {
    const cost = subType.cost ?? 0;

    return {
      anomaly: false,
      type: subType.subType,
      cost: formatMoney(cost, currency),
      anomalyCost: "-",
      maxDailyCost: "-",
      minDailyCost: "-",
      anomalyCostRaw: "-",
      subRows: formatEstateRecords(
        subType.subRows ?? [],
        currency,
        isInCorrectBillingPeriod
      ),
    };
  });
}

function formatEstateRecords(
  estateRecords: AnomalyEstateRecord[],
  currency: string,
  isInCorrectBillingPeriod: boolean
): AnomalyDetailVisual[] {
  return estateRecords.map((estateRecord) => {
    const cost = estateRecord.cost ?? 0;

    return {
      anomaly: false,
      type: estateRecord.name ?? "",
      cost: formatMoney(cost, currency),
      anomalyCost: "-",
      maxDailyCost: "-",
      minDailyCost: "-",
      anomalyCostRaw: "-",
      nid: estateRecord.nid ?? "",
      isLink: isInCorrectBillingPeriod && estateRecord.nid != null,
    };
  });
}
