/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  useGetAllAccountsWithDetailsQuery,
  CloudProviderType,
} from "~/generated/graphql";
import { FiltersProp } from "~/models/estateRecordRow";
import { isNotNil, isEmpty, getAccountIconStatus } from "~/tools";

export function useAccountsOptions(providers: FiltersProp["provider"] = []) {
  const { data, loading, error } = useGetAllAccountsWithDetailsQuery();

  const accounts = (data?.accounts ?? []).filter(isNotNil);

  const accountsOptions = accounts
    .filter(
      (account) =>
        isEmpty(providers) ||
        providers.find((filtersProvider) =>
          [account.provider, account.providerType].includes(filtersProvider)
        )
    )
    .map((account) => ({
      value: account.providerId ?? account.id,
      label: account.displayName ?? account.name,
      status: getAccountIconStatus({
        provider: account.provider,
        active: account.activated,
        status: account.status ?? "",
        providerType: account.providerType ?? CloudProviderType.Cloud,
      }),
    }));

  return {
    accountsOptions,
    loading,
    error,
  };
}
