/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useFormContext } from "react-hook-form";
import { Label, Input } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { ThresholdFormData, ThresholdFormFields } from "../validators";

export function ThresholdName() {
  const {
    register,
    formState: { errors },
  } = useFormContext<ThresholdFormData>();

  return (
    <div css={{ width: "100%" }}>
      <FormGroup error={errors[ThresholdFormFields.NAME]}>
        <Label required name="Threshold Name" />
        <Input {...register(ThresholdFormFields.NAME)} />
      </FormGroup>
    </div>
  );
}
