/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { useFormContext } from "react-hook-form";
import { Spacer, theme, FlexContainer } from "@nordcloud/gnui";
import { FormWizardFooter } from "~/components";
import { SUCCESS_TEXT } from "~/constants";
import { showSuccess } from "~/services/toast";
import { WorkflowSidebars } from "../constants";
import { FlowComponents } from "../EditWorkflow/components/FlowComponents";
import { WorkflowFormData } from "../types";
import {
  AppsEnvsSettingsForm,
  BudgetsSettingsForm,
  KpiSettingsForm,
  mapSelectDataComponent,
  mapSendDataComponents,
  mapTriggerComponents,
  SchedulerSettingsForm,
  ServiceNowSettingsForm,
  SlackSettingsForm,
  WorkflowCanvas,
  AppsEnvsOrgsSettingsForm,
} from "../WorkflowCanvas";
import { EmailSettingsForm } from "../WorkflowCanvas/EmailSettingsForm/EmailSettingsForm";

type Props = {
  onPrevButtonClick: () => void;
};

export function WorkflowSettingsForm({ onPrevButtonClick }: Props) {
  const [openSidebar, setOpenSidebar] = React.useState<WorkflowSidebars>(
    WorkflowSidebars.NONE
  );
  const closeSidebar = () => setOpenSidebar(WorkflowSidebars.NONE);
  const { watch } = useFormContext<WorkflowFormData>();
  const [
    sendDataComponents,
    selectDataComponent,
    triggerComponents,
    anomalyCostsOptions,
  ] = watch([
    "sendDataComponents",
    "selectDataComponent",
    "triggerComponents",
    "anomalyCostsOptions",
  ]);

  const handleSchedulerSettingsSave = () => {
    showSuccess(SUCCESS_TEXT.schedulerSettingsApplied);
    closeSidebar();
  };

  const handleServiceNowSettingsSave = () => {
    showSuccess(SUCCESS_TEXT.serviceNowSettingsApplied);
    closeSidebar();
  };

  const handleEmailSettingsSave = () => {
    showSuccess(SUCCESS_TEXT.emailSettingsApplied);
    closeSidebar();
  };

  const handleSlackSettingsSave = () => {
    showSuccess(SUCCESS_TEXT.slackSettingsApplied);
    closeSidebar();
  };

  return (
    <>
      <FlexContainer alignItems="flex-start">
        <FlowComponents />
        <div css={{ width: "100%" }}>
          <WorkflowCanvas
            formMode="add"
            title="Workflow Canvas"
            triggers={mapTriggerComponents({
              types: triggerComponents,
              setOpenSidebar,
            })}
            selectDataComponent={mapSelectDataComponent({
              type: selectDataComponent,
              setOpenSidebar,
            })}
            sendDataComponents={mapSendDataComponents({
              types: sendDataComponents,
              setOpenSidebar,
            })}
          />
        </div>
      </FlexContainer>
      <Spacer height={theme.spacing.spacing07} />
      <FormWizardFooter
        nextButtonProps={{ type: "submit", children: "Add Workflow" }}
        onPrevClick={onPrevButtonClick}
      />
      <SchedulerSettingsForm
        isOpen={openSidebar === "scheduler"}
        formMode="add"
        onClose={closeSidebar}
        onSave={handleSchedulerSettingsSave}
      />
      <ServiceNowSettingsForm
        requirePassword
        isOpen={openSidebar === "serviceNow"}
        formMode="add"
        onClose={closeSidebar}
        onSave={handleServiceNowSettingsSave}
      />
      <EmailSettingsForm
        isOpen={openSidebar === "email"}
        formMode="add"
        onClose={closeSidebar}
        onSave={handleEmailSettingsSave}
      />
      <SlackSettingsForm
        requireToken
        isOpen={openSidebar === "slack"}
        formMode="add"
        onClose={closeSidebar}
        onSave={handleSlackSettingsSave}
      />
      <AppsEnvsOrgsSettingsForm
        isOpen={openSidebar === "savingsSuggestions"}
        formMode="add"
        type="savingsSuggestions"
        onClose={closeSidebar}
      />
      <AppsEnvsOrgsSettingsForm
        isOpen={openSidebar === "costs"}
        formMode="add"
        type="costsOptions"
        onClose={closeSidebar}
      />
      <AppsEnvsSettingsForm
        type="anomalyCostsOptions"
        formMode="add"
        isOpen={openSidebar === "anomalyCosts"}
        applications={anomalyCostsOptions.applications}
        environments={[]}
        onClose={closeSidebar}
      />
      <KpiSettingsForm isOpen={openSidebar === "kpi"} onClose={closeSidebar} />
      <BudgetsSettingsForm
        isOpen={openSidebar === "budgets"}
        formMode="add"
        onClose={closeSidebar}
      />
    </>
  );
}
