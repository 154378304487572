/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  DetailsDescription,
  DetailsItem,
  DetailsTitle,
} from "~/components/Details/styles";
import { isNotNil } from "~/tools";
import { LinkedResource } from "~/views/estate/EstateDetailsPage/components/ReallocatedCostsSidebar";
import {
  getCloudResource,
  isVMInstance,
} from "~/views/estate/EstateDetailsPage/utils";
import { InlineLink } from "./components";

type NodeProp = {
  nordcloudId: string;
  cloudAccountId?: string;
  onChange: () => void;
  linkedResources: LinkedResource[];
};

export function NodeDetails({ linkedResources, ...rest }: NodeProp) {
  const vm = linkedResources
    ?.map(({ sourceRecord }) => getCloudResource(sourceRecord))
    .filter(isNotNil)
    .find(({ providerType }) => isVMInstance(providerType ?? ""));

  return (
    <DetailsItem>
      <DetailsTitle>VM</DetailsTitle>
      <DetailsDescription>
        <InlineLink
          cloudResource={vm}
          providerTypes={[
            "ec2/instance", // AWS VM
            "microsoft.compute/virtualmachines", // Azure VM
            "microsoft.compute/virtualmachinescalesets", // Azure VM Set
            "compute.googleapis.com/instances", // GCP VM
            "containers-kubernetes/container_instance", // IBM Cloud VM
            "external/virtualmachine", // External Integration
          ]}
          {...rest}
        />
      </DetailsDescription>
    </DetailsItem>
  );
}
