/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export function usePercentageDifference(previous: number, current: number) {
  const percentageDifference = getPercentageDifference(previous, current);

  return {
    percentageDifference,
    isFinite: isFinite(percentageDifference),
    progress: Math.abs(Math.min(percentageDifference, 100)),
    quantifier:
      percentageDifference <= 0 ? ("less" as const) : ("more" as const),
  };
}

// Taken from https://www.mathsisfun.com/numbers/percentage-change.html
function getPercentageDifference(previous: number, current: number) {
  return ((current - previous) / Math.abs(previous)) * 100;
}
