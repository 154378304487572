/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { MouseEventHandler, MouseEvent, useState } from "react";
import { scaleOrdinal } from "@visx/scale";
import { BarRounded, BarStack } from "@visx/shape";
import { Accessor } from "@visx/shape/lib/types";
import { ScaleBand, ScaleLinear } from "d3-scale";
import { theme } from "@nordcloud/gnui";
import { filterStackBarColors, mapSwitcherGroupLabel } from "../helpers";
import { Anomaly, ChartType, CostAnalysisFields } from "../types";
import {
  accumulatedCostChartColors,
  invoiceCorrectionColor,
  savingsPlanColor,
  anomaliesChartColors,
} from "./consts";

type Props = {
  keys: string[];
  onMouseLeave: MouseEventHandler<SVGPathElement>;
  onMouseOver: (event: MouseEvent<SVGPathElement>, index: number) => void;
  onMouseEnter?: (event: MouseEvent<SVGPathElement>, index: number) => void;
  yScale: ScaleLinear<number, number>;
  xScale: ScaleBand<number>;
  x: Accessor<{ date: string }, number>;
  data: { date: string }[];
  colors?: { [k: string]: string };
  chartType?: ChartType;
  anomalies?: Anomaly[];
};

export function BarStackRenderer({
  keys,
  onMouseLeave,
  onMouseEnter,
  onMouseOver,
  xScale,
  yScale,
  x,
  data,
  chartType,
  colors,
  anomalies,
}: Props) {
  const [hoveredBarIndex, setHoveredBarIndex] = useState(-1);

  const filteredStackBarColors =
    chartType === ChartType.ACCUMULATED_COST
      ? accumulatedCostChartColors
      : filterStackBarColors(
          keys.includes(mapSwitcherGroupLabel(chartType)),
          keys.length
        );

  const colorScale = scaleOrdinal({
    domain: keys,
    range: filteredStackBarColors,
  });

  return (
    <BarStack
      data={data}
      keys={keys}
      x={x}
      xScale={xScale}
      yScale={yScale}
      color={colorScale}
      offset="diverging"
    >
      {(barStacks) =>
        barStacks.map((barStack) =>
          barStack.bars.map((bar, index) => (
            <BarRounded
              key={`${bar.key}-${bar.index}`}
              data-testid="stackbar-chart-bar"
              radius={0}
              x={bar.x}
              y={bar.y}
              width={xScale.bandwidth()}
              height={bar.height}
              opacity={index === hoveredBarIndex ? theme.opacity : undefined}
              fill={
                chartType === ChartType.ANOMALY_COST
                  ? getAnomalyBarFill(anomalies ?? [], bar.bar.data.date)
                  : getBarFill(bar.key, colors)
              }
              onMouseOver={(event) => onMouseOver(event, index)}
              onMouseEnter={(event) => onMouseEnter?.(event, index)}
              onMouseLeave={onMouseLeave}
              onMouseMove={() => setHoveredBarIndex(index)}
              onPointerOut={() => setHoveredBarIndex(-1)}
            />
          ))
        )
      }
    </BarStack>
  );
}

function getBarFill(
  key: string,
  colors?: {
    [k: string]: string;
  }
) {
  if (key === CostAnalysisFields.CORRECTION_COST) {
    return invoiceCorrectionColor;
  }

  if (key === CostAnalysisFields.SAVINGS_PLANS) {
    return savingsPlanColor;
  }

  return colors ? colors[key] ?? "primary" : "primary";
}

function getAnomalyBarFill(anomalies: Anomaly[], date: string) {
  const hasAnomaly =
    (anomalies.find((anomaly) => anomaly.date === date)?.anomalyCost ?? 0) > 0;

  return hasAnomaly
    ? anomaliesChartColors.barWithAnomaly
    : anomaliesChartColors.bar;
}
