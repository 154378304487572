/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Controller, useFormContext } from "react-hook-form";
import {
  Label,
  FlexContainer,
  theme,
  Input,
  MultipleSelect,
  SelectButton,
  Text,
} from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { ThresholdGranularity } from "../../types";
import { AMOUNT_DESCRIPTION } from "../constants";
import { ThresholdFormData, ThresholdFormFields } from "../validators";

export function AmountSelector() {
  const {
    register,
    formState: { errors },
  } = useFormContext<ThresholdFormData>();

  return (
    <section>
      <Label required name="Threshold Amount" css={{ margin: 0 }} />
      <Text size="sm" color={theme.color.text.text03}>
        {AMOUNT_DESCRIPTION}
      </Text>
      <FormGroup error={errors[ThresholdFormFields.THRESHOLD_AMOUNT]}>
        <FlexContainer gap={theme.spacing.spacing05}>
          <Input
            {...register(ThresholdFormFields.THRESHOLD_AMOUNT)}
            css={{ width: "8rem" }}
            placeholder="e.g., $1,000"
          />
          <Controller
            name={ThresholdFormFields.GRANULARITY}
            render={({ field: { value, onChange } }) => (
              <MultipleSelect>
                <SelectButton
                  style={{ paddingInline: theme.spacing.spacing08 }}
                  isActive={value === ThresholdGranularity.Daily}
                  name="daily"
                  value={ThresholdGranularity.Daily}
                  labelText="Daily"
                  onClick={onChange}
                />
                <SelectButton
                  style={{ paddingInline: theme.spacing.spacing07 }}
                  isActive={value === ThresholdGranularity.Monthly}
                  name="monthly"
                  value={ThresholdGranularity.Monthly}
                  labelText="Monthly"
                  onClick={onChange}
                />
              </MultipleSelect>
            )}
          />
        </FlexContainer>
      </FormGroup>
    </section>
  );
}
