/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";

type Props = {
  trigger: () => boolean;
  ref: React.RefObject<HTMLElement>;
  once?: boolean;
  smooth?: boolean;
  onFinish?: () => void;
};

export function useScroll({
  trigger,
  ref,
  once = false,
  smooth = false,
  onFinish,
}: Props) {
  const [invoked, setInvoked] = React.useState(false);

  const onScroll = () => {
    const hasScrolledToRef =
      window.pageYOffset.toFixed(0) === ref.current?.offsetTop.toFixed(0);
    const hasScrolledToBottom =
      window.innerHeight + window.scrollY >= document.body.offsetHeight;
    if (hasScrolledToRef || hasScrolledToBottom) {
      window.removeEventListener("scroll", onScroll);
      onFinish?.();
    }
  };

  React.useLayoutEffect(() => {
    if (invoked === false && trigger() && ref.current) {
      if (once) {
        setInvoked(true);
      }

      if (onFinish != null) {
        window.addEventListener("scroll", onScroll);
        onScroll();
      }

      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: smooth ? "smooth" : "auto",
      });
    }
  }, [ref, trigger, once, invoked]);
}
