/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { If, Then, Else } from "react-if";
import { Cell } from "react-table";
import { EstateRecordRow } from "~/models/estateRecordRow";

export const ForecastColumn = [
  {
    Header: "Monthly Forecast",
    accessor: "forecast",
    disableSortBy: true,
    width: "10rem",
    Cell: ({ row }: Cell<EstateRecordRow>) => (
      <If
        condition={
          row?.original?.cost?.forecast !== null ||
          row?.original?.forecast !== null
        }
      >
        <Then>{row?.original?.cost?.forecast || row?.original?.forecast}</Then>
        <Else>Unavailable</Else>
      </If>
    ),
  },
];
