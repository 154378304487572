/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { Button, theme } from "@nordcloud/gnui";

export const PreviewButton = styled(Button)`
  background: ${theme.color.background.body};
  color: ${theme.color.text.text01};

  &:hover {
    background: ${theme.color.interactive.secondaryHover};
    color: ${theme.color.text.text01};
  }
`;
