/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Link } from "react-router-dom";
import { Text, theme } from "@nordcloud/gnui";
import { Details } from "~/components";
import { ROUTES } from "~/routing/routes";
import { parseMetadata } from "~/views/estate/EstateDetailsPage/utils";

type Props = {
  externalIntegrationName: string;
  externalIntegrationId: string;
  enrichments: ReturnType<typeof parseMetadata>[];
};

export function ExternalIntegrationTabContents({
  externalIntegrationName,
  externalIntegrationId,
  enrichments,
}: Props) {
  return (
    <>
      <Text
        color={theme.color.text.text01}
        mb={theme.spacing.spacing06}
        css={{
          paddingBottom: theme.spacing.spacing03,
          borderBottom: `1px solid ${theme.color.border.border01}`,
        }}
      >
        Enriched data from{" "}
        <Link
          to={`${ROUTES.externalIntegrations.index}/${externalIntegrationId}`}
        >
          {externalIntegrationName}
        </Link>
      </Text>
      <Details details={enrichments} />
    </>
  );
}
