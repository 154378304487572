/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ExtendedTooltip, SVGIcon } from "@nordcloud/gnui";
import { isNotNil } from "~/tools";
import { RecalculationItemTooltip } from "./RecalculationItemTooltip";
import { RecalculationItemWrapper } from "./styles";

type Props = {
  caption: string;
  icon: "countdown" | "refresh" | "refreshSuccess";
  summary: string;
  onClick?: () => void;
};

export function RecalculationItem({ caption, icon, summary, onClick }: Props) {
  const isClickable = isNotNil(onClick);

  return (
    <ExtendedTooltip
      placement="bottom"
      position="center"
      caption={<RecalculationItemTooltip summary={summary} caption={caption} />}
    >
      <RecalculationItemWrapper
        isClickable={isClickable}
        onClick={() => onClick?.()}
      >
        <SVGIcon name={icon} />
      </RecalculationItemWrapper>
    </ExtendedTooltip>
  );
}
