/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { NumericFormat } from "react-number-format";
import { theme } from "@nordcloud/gnui";
import { useCurrency } from "~/hooks";
import { formatMoney, getCurrencySymbol, isNotNil } from "~/tools";
import { Editing } from "../helpers";

type Props = {
  value: number;
  hasChanged?: boolean;
  hasWarning?: boolean;
  onChange: (value: number) => void;
  onSetType: (value: Editing) => void;
  type: Editing;
};

export function BudgetNumberInput({
  value,
  onChange,
  hasChanged = false,
  hasWarning = false,
  onSetType,
  type,
}: Props) {
  const { currency } = useCurrency();

  return (
    <NumericFormat
      fixedDecimalScale
      placeholder={`${formatMoney(20_000)}`}
      displayType="input"
      value={value}
      decimalScale={2}
      prefix={isNotNil(value) ? getCurrencySymbol(currency) : ""}
      allowNegative={false}
      css={{
        border: `1px solid ${theme.color.border.input}`,
        width: hasWarning ? "60%" : "80%",
        height: "16px",
        background: "transparent",
        backgroundColor: `${theme.color.field.default}`,
        fontFamily: `${theme.typography.fonts.body}`,
        fontSize: `${theme.fontSizes.md}`,
        borderRadius: `${theme.radiusDefault}`,
        padding: `${theme.spacing.spacing02}`,
        color: `${
          hasChanged ? theme.color.text.warning : theme.color.text.text01
        }`,
        outline: "none",
        transition: "all 0.26s ease-in-out;",
        ":focus, :hover": {
          borderColor: `${theme.color.border.focus}`,
        },
        "::placeholder": {
          color: `${theme.color.text.text03}`,
        },
      }}
      onFocus={() => onSetType(type)}
      onValueChange={(newValue) =>
        onChange(Number((newValue.floatValue ?? 0).toFixed(2)))
      }
    />
  );
}
