/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsProvider,
} from "~/generated/graphql";
import { ResourceLabel } from "../constants";
import { useGetAzureServiceLabel } from "./useGetAzureServiceLabel";
import { useGetCurrentTab } from "./useGetCurrentTab";

type Props = {
  hasCoverageResourceList: boolean;
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  provider: ReservationsProvider;
};

export function useGetCheckRecommendationTitle({
  hasCoverageResourceList,
  serviceUsageType,
  provider,
}: Props) {
  const { isComputeTab, isStorageTab } = useGetCurrentTab(serviceUsageType);
  const isAws = provider === ReservationsProvider.Aws;
  const azureResourceLabel = useGetAzureServiceLabel(serviceUsageType);
  const resourceFamily = isAws ? "Family Details" : azureResourceLabel;
  const resourceDetails = isAws ? "Instance Details" : azureResourceLabel;
  const getType = () => {
    if (isAws) {
      return ResourceLabel.AWSResources;
    }
    switch (true) {
      case isComputeTab:
        return ResourceLabel.Others;
      case isStorageTab:
        return ResourceLabel.AzureStorage;
      default:
        return ResourceLabel.AzureDatabaseAndAnalytics;
    }
  };
  const resourceType = getType();
  const headerText = hasCoverageResourceList ? resourceFamily : resourceDetails;
  const resourceLabel =
    !isAws || hasCoverageResourceList ? resourceType : ResourceLabel.Others;

  return {
    headerText,
    resourceLabel,
    isAws,
  };
}
