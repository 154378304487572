/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { theme } from "@nordcloud/gnui";
import { ResponsiveBrickLoader, TableLoader } from "~/components";

export function EstateRecordsLoader() {
  return (
    <>
      <ResponsiveBrickLoader
        height={100}
        css={{ marginBottom: theme.spacing.spacing04 }}
      />
      <TableLoader
        gapY={10}
        gapX={6}
        rows={[
          {
            count: 8,
            height: 60,
            items: [
              {
                widthPercent: 35,
              },
              {
                widthPercent: 45,
              },
              {
                widthPercent: 15,
              },
              {
                widthPercent: 5,
              },
            ],
          },
        ]}
      />
    </>
  );
}
