import { ReservationsAndCommitmentsFilters } from "~/generated/graphql";
import { useQueryStateWithFilters } from "~/hooks/useQueryState";

function removeValueFromFilter(
  filters: ReservationsAndCommitmentsFilters,
  key: keyof ReservationsAndCommitmentsFilters,
  tagIndexToRemove: number
) {
  const reservationTagFilters = filters[key];
  if (Array.isArray(reservationTagFilters)) {
    return (reservationTagFilters as unknown[]).filter(
      (_, filterTagIndex) => filterTagIndex !== tagIndexToRemove
    );
  }

  return reservationTagFilters;
}

export function useReservationFilters(
  filters: ReservationsAndCommitmentsFilters
) {
  const { setFilters, ...rest } = useQueryStateWithFilters(filters);

  const removeFilter = (
    key: keyof ReservationsAndCommitmentsFilters,
    tagIndexToRemove: number
  ) => {
    setFilters({
      ...filters,
      [key]: removeValueFromFilter(filters, key, tagIndexToRemove),
    });
  };

  return {
    removeFilter,
    setFilters,
    ...rest,
  };
}
