/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

type IconProps = {
  color: string;
};

export const TooltipIconBox = styled.span<IconProps>`
  background-color: ${({ color }) => color};
  height: 0.6rem;
  width: 0.6rem;
  margin-right: ${theme.spacing.spacing01};
  border-radius: ${theme.radius.sm};
`;
