/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  Control,
  Controller,
  UnpackNestedValue,
  useFieldArray,
  useFormContext,
  useFormState,
} from "react-hook-form";
import { When } from "react-if";
import { Button, FlexContainer, Label, theme } from "@nordcloud/gnui";
import { ContactsInput, FormGroup } from "~/components";
import { ERROR_TEXT, SUCCESS_TEXT } from "~/constants";
import { showError, showSuccess } from "~/services/toast";
import { IntegrationPluginFormData } from "./types";

export function IntegrationPluginContactSelect() {
  const { trigger, control } =
    useFormContext<UnpackNestedValue<IntegrationPluginFormData>>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "contactPersonIds",
  });

  const { errors } = useFormState<IntegrationPluginFormData>();

  const handleFieldRemove = (index: number) => {
    remove(index);
    validateContactPersonIds();
  };

  const handleAddContactPerson = () => {
    append({ value: "" });
    validateContactPersonIds();
  };

  const validateContactPersonIds = () => {
    trigger(["contactPersonIds"]);
  };

  return (
    <FormGroup error={errors.contactPersonIds}>
      <Label required name="Contact Person" htmlFor="contactPersonIds" />
      <>
        {fields.map((item, index) => (
          <ContactPersonFieldArrayItem
            key={item.id}
            index={index}
            displayRemoveButton={fields.length > 1}
            hasError={errors.contactPersonIds != null}
            control={control}
            onRemoveButtonClick={() => handleFieldRemove(index)}
            onPersonChange={validateContactPersonIds}
          />
        ))}
      </>
      <Button type="button" severity="low" onClick={handleAddContactPerson}>
        Add Contact Person
      </Button>
    </FormGroup>
  );
}

type ContactPersonFieldArrayItemProps = {
  index: number;
  hasError: boolean;
  displayRemoveButton: boolean;
  onRemoveButtonClick: () => void;
  onPersonChange: () => void;
  control: Control<UnpackNestedValue<IntegrationPluginFormData>>;
};

function ContactPersonFieldArrayItem({
  index,
  hasError,
  displayRemoveButton,
  onRemoveButtonClick,
  onPersonChange,
  control,
}: ContactPersonFieldArrayItemProps) {
  const handleChange = (id: string, onChange: (id: string) => void) => {
    try {
      showSuccess(SUCCESS_TEXT.contactPersonAdded);
      onChange(id);
      onPersonChange();
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  return (
    <FlexContainer marginBottom={theme.spacing.spacing02}>
      <Controller
        control={control}
        name={`contactPersonIds.${index}.value` as const}
        render={({ field: { onChange, value } }) => (
          <ContactsInput
            aria-label={`contactPersonIds${index}`}
            name={`contactPersonIds.${index}.value`}
            value={[value]}
            placeholder="Select Contact Person"
            status={hasError ? "error" : undefined}
            onChange={(id: string) => handleChange(id, onChange)}
          />
        )}
      />
      <When condition={displayRemoveButton}>
        <Button
          ml={theme.spacing.spacing02}
          severity="low"
          type="button"
          icon="trash"
          onClick={onRemoveButtonClick}
        />
      </When>
    </FlexContainer>
  );
}
