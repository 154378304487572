/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export function useIconStyle(isValidForecast: boolean, higher: boolean) {
  if (!isValidForecast) {
    return { color: undefined, icon: "minus" } as const;
  }

  return {
    icon: higher ? "caretUp" : "caretDown",
    color: higher ? "danger" : "success",
  } as const;
}
