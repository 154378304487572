/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch, SetStateAction, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { When, If, Then, Else } from "react-if";
import { Label, theme, Text, FlexContainer, Modal } from "@nordcloud/gnui";
import { WorkflowEmailOptionsMessageType } from "~/generated/graphql";
import { useDisclosure } from "~/hooks/useDisclosure/useDisclosure";
import { isNotNil } from "~/tools/filter";
import {
  combinedPreviewImage,
  ownerPreviewMessage,
  individualPreviewImage,
  previewMessage,
  WorkflowDataType,
} from "../../constants";
import { WorkflowFormData } from "../../types";
import { EMAIL_MSG_PREVIEW_MODAL_TITLE } from "../constants";
import { getDefaultEmailMessageRadioValue } from "../utils";
import { EmailInput } from "./EmailInput";
import { PreviewButton } from "./PreviewButton";
import { PreviewImage } from "./PreviewImage";
import { RadioOption } from "./RadioOption";
import { SendToOption } from "./SendToOption";

type Props = {
  isReadMode: boolean;
  selectedRadioValue: WorkflowEmailOptionsMessageType;
  setSelectedRadioValue: Dispatch<
    SetStateAction<WorkflowEmailOptionsMessageType>
  >;
  showToggleError: boolean;
  setShowToggleError: (value: SetStateAction<boolean>) => void;
  isRegularEmail: boolean;
  isPredefinedEmails: boolean;
  preview?: string;
  type?: WorkflowDataType;
};

export function MailTo({
  isReadMode,
  type,
  selectedRadioValue,
  setSelectedRadioValue,
  showToggleError,
  setShowToggleError,
  isRegularEmail,
  isPredefinedEmails,
  preview,
}: Props) {
  const { isOpen, close, open } = useDisclosure();
  const { setValue, getValues, clearErrors } =
    useFormContext<WorkflowFormData>();
  useEffect(() => {
    if (isRegularEmail) {
      const email = getValues("email");
      setValue("email", {
        ...email,
        sendToAdditionalUsers: isRegularEmail,
      });
    } else {
      setValue("email", {
        ...getValues("email"),
        sendToAdditionalUsers: isRegularEmail,
        mailTo: undefined,
      });
      clearErrors("email.mailTo");
    }
  }, [isRegularEmail, setValue, clearErrors, getValues]);

  useEffect(() => {
    setValue("email", {
      ...getValues("email"),
      mailTo: getValues("email.mailTo") ?? undefined,
      sendToContactPersons: isPredefinedEmails,
    });
  }, [isPredefinedEmails, setValue, getValues]);

  function handleSendToOwnersClick() {
    const email = getValues("email");
    setValue("email", {
      ...email,
      sendToContactPersons: !email.sendToContactPersons,
    });

    setShowToggleError(false);
  }

  function handleSendToAdditionalUsersClick() {
    const email = getValues("email");
    setValue("email", {
      ...email,
      sendToAdditionalUsers: !email.sendToAdditionalUsers,
    });
    clearErrors("email.mailTo");

    setShowToggleError(false);

    const defaultRadioValue =
      getDefaultEmailMessageRadioValue(selectDataComponent);
    setSelectedRadioValue(defaultRadioValue);
  }

  const selectDataComponent = type ?? WorkflowDataType.COSTS;

  return (
    <>
      <FlexContainer
        justifyContent="space-between"
        pb={theme.spacing.spacing02}
      >
        <div>
          <Label
            name="Send Email to"
            htmlFor="mailTo"
            required={!isReadMode}
            css={{
              marginBottom: theme.spacing.spacing00,
              textTransform: "none",
            }}
          />
          <When condition={isNotNil(preview)}>
            <Text color={theme.color.text.text02} size="sm">
              Send email to selected recipients
            </Text>
          </When>
        </div>
        <When condition={isNotNil(preview)}>
          <PreviewButton type="button" size="sm" onClick={open}>
            Sample Message
          </PreviewButton>
        </When>
      </FlexContainer>
      <If
        condition={
          type === WorkflowDataType.BUDGETS ||
          type === WorkflowDataType.COSTS ||
          type === WorkflowDataType.ANOMALY_COSTS ||
          type === WorkflowDataType.KPI ||
          type === WorkflowDataType.SAVING_SUGGESTIONS
        }
      >
        <Then>
          <SendToOption
            isReadMode={isReadMode}
            checked={isPredefinedEmails}
            title="Owners and Contacts"
            details={getStandardSendOptionDetailsText(type)}
            previewImage={type && ownerPreviewMessage[type]}
            onChange={handleSendToOwnersClick}
          />
          <SendToOption
            isReadMode={isReadMode}
            checked={isRegularEmail}
            previewImage={type && previewMessage[type]}
            title="Other Recipients"
            details="Specify the recipients who will receive an email"
            onChange={handleSendToAdditionalUsersClick}
          />
          <When condition={showToggleError}>
            <Text color={theme.colors.danger}>
              At least one option is required.
            </Text>
          </When>
          <When condition={isRegularEmail}>
            <div css={{ paddingLeft: "3.5rem" }}>
              <EmailInput
                name="email.mailTo"
                disabled={isReadMode}
                placeholder="Email address (or multiple addresses, separated by semicolon)"
              />
              <When
                condition={
                  type === WorkflowDataType.SAVING_SUGGESTIONS ||
                  type === WorkflowDataType.COSTS
                }
              >
                <FlexContainer
                  direction="column"
                  alignItems="flex-start"
                  mt={theme.spacing.spacing04}
                >
                  <Text>Select a message</Text>
                  <RadioOption
                    labelText="Send individual data"
                    details={getRadioOptionDetailsText("individual", type)}
                    isReadMode={isReadMode}
                    checked={isIndividualChecked(selectedRadioValue)}
                    previewImage={individualPreviewImage[selectDataComponent]}
                    onChange={() => {
                      onIndividualRadioChange(setSelectedRadioValue, type);
                    }}
                  />
                  <RadioOption
                    labelText="Send combined data"
                    details={getRadioOptionDetailsText("combined", type)}
                    isReadMode={isReadMode}
                    checked={isCombinedChecked(selectedRadioValue)}
                    previewImage={combinedPreviewImage[selectDataComponent]}
                    onChange={() => {
                      onCombinedRadioChange(setSelectedRadioValue, type);
                    }}
                  />
                </FlexContainer>
              </When>
            </div>
          </When>
        </Then>
        <Else>
          <EmailInput
            name="email.mailTo"
            disabled={isReadMode}
            placeholder="Email address (or multiple addresses, separated by semicolon)"
          />
        </Else>
      </If>
      <Modal
        isOpen={isOpen}
        actions={[]}
        contentMaxHeight="40rem"
        contentLabel={EMAIL_MSG_PREVIEW_MODAL_TITLE}
        onClose={close}
      >
        <PreviewImage src={preview} />
      </Modal>
    </>
  );
}

function getStandardSendOptionDetailsText(
  selectDataComponent?: WorkflowDataType
) {
  const suffix = "will get an email individually";

  switch (selectDataComponent) {
    case WorkflowDataType.BUDGETS:
    case WorkflowDataType.COSTS:
    case WorkflowDataType.SAVING_SUGGESTIONS:
      return `Owners and contacts for selected applications, contacts for selected environments and contacts for selected Organizational Units ${suffix}`;
    case WorkflowDataType.KPI:
      return `Contacts for selected Business Context and contacts for active KPI target ${suffix}`;
    case WorkflowDataType.ANOMALY_COSTS:
      return `Owners and contacts for selected applications ${suffix}`;
    default:
      return "";
  }
}

function getRadioOptionDetailsText(
  type: "combined" | "individual",
  selectDataComponent?: WorkflowDataType
) {
  const typeText = {
    individual:
      " for each Business Context, Organizational Unit, application and environment will be divided into their noted groups",
    combined:
      " for each Business Context, Organizational Unit, application and environment will be combined into 1 group by providers",
  }[type];

  if (selectDataComponent === WorkflowDataType.SAVING_SUGGESTIONS) {
    return `Savings suggestions ${typeText}`;
  }
  if (selectDataComponent === WorkflowDataType.COSTS) {
    return `Costs data ${typeText}`;
  }

  return "";
}

function isIndividualChecked(
  selectedRadioValue: WorkflowEmailOptionsMessageType
) {
  return [
    WorkflowEmailOptionsMessageType.SavingSuggestionsIndividual,
    WorkflowEmailOptionsMessageType.CostsIndividual,
  ].includes(selectedRadioValue);
}

function isCombinedChecked(
  selectedRadioValue: WorkflowEmailOptionsMessageType
) {
  return [
    WorkflowEmailOptionsMessageType.SavingSuggestionsCombined,
    WorkflowEmailOptionsMessageType.CostsTopCosts,
  ].includes(selectedRadioValue);
}

function onIndividualRadioChange(
  setSelectedRadioValue: Dispatch<
    SetStateAction<WorkflowEmailOptionsMessageType>
  >,
  selectDataComponent?: WorkflowDataType
) {
  if (selectDataComponent === WorkflowDataType.COSTS) {
    setSelectedRadioValue(WorkflowEmailOptionsMessageType.CostsIndividual);
  }

  if (selectDataComponent === WorkflowDataType.SAVING_SUGGESTIONS) {
    setSelectedRadioValue(
      WorkflowEmailOptionsMessageType.SavingSuggestionsIndividual
    );
  }
}

function onCombinedRadioChange(
  setSelectedRadioValue: Dispatch<
    SetStateAction<WorkflowEmailOptionsMessageType>
  >,
  selectDataComponent?: WorkflowDataType
) {
  if (selectDataComponent === WorkflowDataType.COSTS) {
    setSelectedRadioValue(WorkflowEmailOptionsMessageType.CostsTopCosts);
  }

  if (selectDataComponent === WorkflowDataType.SAVING_SUGGESTIONS) {
    setSelectedRadioValue(
      WorkflowEmailOptionsMessageType.SavingSuggestionsCombined
    );
  }
}
