/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Box, Button, Col, Row } from "@nordcloud/gnui";
import { BreadcrumbsBox, UnstyledLink } from "~/components";
import { ROUTES } from "~/routing/routes";
import { IntegrationPluginsList } from "./IntegrationPluginsList";

export function IntegrationPluginsPage() {
  return (
    <Row>
      <Col>
        <BreadcrumbsBox title="Integration Plugins">
          <UnstyledLink to={ROUTES.integrationPlugins.create}>
            <Button icon="plus">Add New Integration</Button>
          </UnstyledLink>
        </BreadcrumbsBox>
        <Row>
          <Col>
            <Box innerSpacing="spacing04">
              <IntegrationPluginsList />
            </Box>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
