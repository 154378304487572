/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo, useState } from "react";
import { useApplicationsPaginatedByApplicationNameQuery } from "~/generated/graphql";
import { formatDataObject } from "~/utils";

export function useApplicationsPagination() {
  const pageSize = 20;
  const [page, setPage] = useState(0);
  const [applicationName, setApplicationName] = useState("");

  const { data, loading, error } =
    useApplicationsPaginatedByApplicationNameQuery({
      variables: {
        limit: pageSize,
        page,
        applicationName,
      },
    });

  return {
    data: useMemo(() => formatDataObject(data), [data]),
    loading,
    error,
    page,
    pageSize,
    setPage,
    setApplicationName,
  };
}
