/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  useState,
  createContext,
  useContext,
  PropsWithChildren,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { YbugApi, YbugSettings } from "./types";
import { useScript } from "./useScript";

/* eslint-disable @typescript-eslint/naming-convention */

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    Ybug: YbugApi;
    ybug_settings: YbugSettings;
  }
}

type YbugContextType = {
  ybug: YbugApi | null;
  setYbugSettings: (settings: Partial<YbugSettings>) => void;
  loadingStatus: ReturnType<typeof useScript>;
};

type YbugProviderProps = PropsWithChildren<{
  ybugId: string;
  settings?: YbugSettings;
  enabled: boolean;
}>;

const YbugContext = createContext<YbugContextType | undefined>(undefined);
YbugContext.displayName = "YbugContext";

export function useYbug(): YbugContextType {
  const context = useContext(YbugContext);

  if (context === undefined) {
    throw new Error("useYbug must be used within a YbugProvider");
  }

  return context;
}

export function YbugProvider({
  ybugId,
  children,
  settings,
  enabled,
}: YbugProviderProps) {
  const [ybugApi, setYbugApi] = useState<YbugApi | null>(null);

  const setYbugSettings = useCallback(
    (ybugSettings: Partial<YbugSettings>) => {
      window.ybug_settings = {
        id: ybugId,
        ...ybugSettings,
      };

      if (ybugApi != null) {
        ybugApi.destroy();
        ybugApi.boot();
      }
    },
    [ybugId, ybugApi]
  );

  const loadingStatus = useScript(
    `https://widget.ybug.io/button/${ybugId}.js`,
    {
      removeOnUnmount: false,
      beforeAppendHook: () => {
        setYbugSettings(settings ?? {});
      },
      shouldPreventLoad: !enabled,
    }
  );

  useEffect(() => {
    if (loadingStatus === "ready") {
      setYbugApi(window.Ybug);
    }
  }, [loadingStatus]);

  const value = useMemo(
    () => ({
      ybug: ybugApi,
      setYbugSettings,
      loadingStatus,
    }),
    [ybugApi, loadingStatus, setYbugSettings]
  );

  return <YbugContext.Provider value={value}>{children}</YbugContext.Provider>;
}
