/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useChargeTypesQuery } from "~/generated/graphql";
import { isNotNil, isNotEmpty } from "~/tools";
import { ProviderTab } from "../types";

export function useChargeTypes(provider: ProviderTab) {
  const {
    data: chargeTypes,
    loading: isLoadingChargeTypes,
    error: chargeTypesError,
  } = useChargeTypesQuery({
    skip: !provider,
    variables: {
      provider,
    },
  });

  const chargeTypesResult = chargeTypes?.chargeTypes?.chargeTypes
    ?.map((chargeType) => chargeType.name)
    .filter((chargeType) => isNotNil(chargeType) && isNotEmpty(chargeType));

  return {
    chargeTypes: chargeTypesResult ?? [],
    isLoadingChargeTypes,
    chargeTypesError,
  };
}
