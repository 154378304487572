/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { UnpackNestedValue } from "react-hook-form";
import {
  WorkflowSchedulerOptions,
  WorkflowFrequency,
  WorkflowExternalIntegrationType,
  WorkflowEmailOptionsMessageType,
} from "~/generated/graphql";
import { dateFormat } from "~/constants";
import { generateNumericArray, Maybe } from "~/tools";
import { WorkflowFormData } from "../types";
import {
  WorkflowProps,
  WorkflowServiceNowOptionsProps,
  WorkflowSlackOptionsProps,
} from "../WorkflowPropsMapping";

export function getDefaultValues(
  workflow: WorkflowProps
): UnpackNestedValue<WorkflowFormData> {
  const triggerSchedulerOptions = workflow.triggers.find(
    (trigger) => trigger.type === "SCHEDULER"
  )?.schedulerOptions;
  const serviceNowOptions = workflow.externalIntegrations.find(
    (integration) => integration.type === "SERVICE_NOW"
  )?.serviceNowOptions;
  const emailOptions = workflow.externalIntegrations.find(
    (integration) => integration.type === WorkflowExternalIntegrationType.Email
  )?.emailOptions;
  const slackOptions = workflow.externalIntegrations.find(
    (integration) => integration.type === WorkflowExternalIntegrationType.Slack
  )?.slackOptions;

  return {
    name: workflow.name,
    description: workflow.description ?? "",
    contactPersonIds: workflow.contactPersons.map(({ id }) => ({
      value: id,
    })),
    active: workflow.active,
    serviceNow: getServiceNowDefaultValues(serviceNowOptions),
    serviceNowSaved: false,
    email: {
      mailTo: emailOptions?.mailTo
        ? emailOptions?.mailTo.join("; ")
        : undefined,
      sendToContactPersons: emailOptions?.sendToContactPersons ?? false,
      sendToAdditionalUsers: Boolean(emailOptions?.mailTo),
      subject: emailOptions?.subject ?? "",
      message: emailOptions?.message ?? "",
      messageType:
        emailOptions?.messageType ?? WorkflowEmailOptionsMessageType.Custom,
    },
    emailSaved: false,
    emailSubmissionSnapshot: undefined,
    scheduler: getSchedulerValues(triggerSchedulerOptions),
    schedulerSubmissionId: "",
    schedulerSubmissionSnapshot: undefined,
    triggerComponents: workflow.triggers.map(({ type }) => type),
    sendDataComponents: workflow.externalIntegrations.map(({ type }) => type),
    selectDataComponent: workflow.selectData.type,
    slack: getSlackDefaultValues(slackOptions),
    slackSaved: false,
    slackSubmissionSnapshot: undefined,
    savingsSuggestions: getSavingsSuggestionsValues(workflow),
    kpiOptions: getkpiOptions(workflow),
    costsOptions: getCostsOptionsValues(workflow),
    budgetsOptions: getBudgetsOptionsValues(workflow),
    anomalyCostsOptions: {
      applications:
        workflow.selectData.anomalyCostsOptions?.applicationIds ?? [],
      environments: [],
    },
    budgetsSubmissionSnapshot: undefined,
  };
}

function getSlackDefaultValues(slackOptions: Maybe<WorkflowSlackOptionsProps>) {
  return {
    channel: slackOptions?.channel ?? "",
    message: slackOptions?.message ?? "",
    token: "",
    costsOptions: getSlackCostsOptionsDefaultValues(slackOptions),
    kpiOptions: getSlackKpiOptionsDefaultValues(slackOptions),
    savingSuggestionsOptions:
      getSlackSavingSuggestionsOptionsDefaultValues(slackOptions),
  };
}

function getSlackSavingSuggestionsOptionsDefaultValues(
  slackOptions: Maybe<WorkflowSlackOptionsProps>
) {
  return slackOptions?.savingSuggestionsOptions
    ? {
        totalMonthlySavings:
          slackOptions.savingSuggestionsOptions.totalMonthlySavings ?? false,
        monthlySavingsByProviders:
          slackOptions.savingSuggestionsOptions.monthlySavingsByProviders ??
          false,
        monthlySavingsByCategories:
          slackOptions.savingSuggestionsOptions.monthlySavingsByCategories ??
          false,
        monthlySavingsByPolicies:
          slackOptions.savingSuggestionsOptions.monthlySavingsByPolicies ??
          false,
      }
    : null;
}

function getSlackKpiOptionsDefaultValues(
  slackOptions: Maybe<WorkflowSlackOptionsProps>
) {
  return slackOptions?.kpiOptions
    ? {
        costAllocation: slackOptions.kpiOptions.costAllocation ?? false,
        overallWaste: slackOptions.kpiOptions.overallWaste ?? false,
        nonProdHours: slackOptions.kpiOptions.nonProdHours ?? false,
      }
    : null;
}

function getSlackCostsOptionsDefaultValues(
  slackOptions: Maybe<WorkflowSlackOptionsProps>
) {
  return slackOptions?.costsOptions
    ? {
        totalMonthlyCost: slackOptions.costsOptions.totalMonthlyCost ?? false,
        totalMonthlyForecast:
          slackOptions.costsOptions.totalMonthlyForecast ?? false,
        costsByProviders: slackOptions.costsOptions.costsByProviders ?? false,
        costsByCategories: slackOptions.costsOptions.costsByCategories ?? false,
      }
    : null;
}

function getServiceNowDefaultValues(
  serviceNowOptions: Maybe<WorkflowServiceNowOptionsProps>
) {
  return {
    url: serviceNowOptions?.url ?? "",
    username: serviceNowOptions?.username ?? "",
    password: "",
    shortDescription: serviceNowOptions?.shortDescription ?? "",
    description: serviceNowOptions?.description ?? "",
    additionalMessageFields: (serviceNowOptions?.additionalFields ?? []).map(
      ({ key, value }) => ({ key, value })
    ),
    costsOptions: serviceNowOptions?.costsOptions
      ? {
          totalMonthlyCost:
            serviceNowOptions.costsOptions.totalMonthlyCost ?? false,
          totalMonthlyForecast:
            serviceNowOptions.costsOptions.totalMonthlyForecast ?? false,
          costsByProviders:
            serviceNowOptions.costsOptions.costsByProviders ?? false,
          costsByCategories:
            serviceNowOptions.costsOptions.costsByCategories ?? false,
        }
      : null,
    kpiOptions: serviceNowOptions?.kpiOptions
      ? {
          costAllocation: serviceNowOptions.kpiOptions.costAllocation ?? false,
          nonProdHours: serviceNowOptions.kpiOptions.nonProdHours ?? false,
          overallWaste: serviceNowOptions.kpiOptions.overallWaste ?? false,
        }
      : null,
    savingSuggestionsOptions: serviceNowOptions?.savingSuggestionsOptions
      ? {
          totalMonthlySavings:
            serviceNowOptions.savingSuggestionsOptions.totalMonthlySavings ??
            false,
          monthlySavingsByCategories:
            serviceNowOptions.savingSuggestionsOptions
              .monthlySavingsByCategories ?? false,
          monthlySavingsByPolicies:
            serviceNowOptions.savingSuggestionsOptions
              .monthlySavingsByPolicies ?? false,
          monthlySavingsByProviders:
            serviceNowOptions.savingSuggestionsOptions
              .monthlySavingsByProviders ?? false,
        }
      : null,
  };
}

function getSavingsSuggestionsValues({ selectData }: WorkflowProps) {
  const options = selectData.savingSuggestionsOptions;

  return {
    applications: options?.applications ?? [],
    environments: options?.environments ?? [],
    orgUnitIds: options?.orgUnitIds ?? [],
  };
}

function getkpiOptions({ selectData }: WorkflowProps) {
  if (selectData.kpiOptions == null) {
    return undefined;
  }

  return {
    businessContextId: selectData.kpiOptions.businessContextId,
  };
}

function getCostsOptionsValues({ selectData }: WorkflowProps) {
  const options = selectData.costsOptions;

  return {
    applications: options?.applications ?? [],
    environments: options?.environments ?? [],
    orgUnitIds: options?.orgUnitIds ?? [],
  };
}

function getBudgetsOptionsValues({ selectData }: WorkflowProps) {
  const options = selectData.budgetsOptions;

  return {
    applications: options?.applications ?? [],
    environments: options?.environments ?? [],
    forecastGtBudget: options?.forecastGtBudget ?? true,
    costGtBudget: options?.costGtBudget ?? true,
    orgUnitIds: options?.orgUnitIds ?? [],
  };
}

function getSchedulerValues(
  triggerSchedulerOptions: Maybe<WorkflowSchedulerOptions>
) {
  if (triggerSchedulerOptions == null) {
    return {
      startDate: "",
      executionTime: "",
      repeat: false,
      frequency: WorkflowFrequency.Daily,
      weekdays: [
        { selected: true },
        { selected: true },
        { selected: true },
        { selected: true },
        { selected: true },
        { selected: false },
        { selected: false },
      ],
      interval: 1,
      endDate: "",
    };
  }

  const { startDate, frequency, interval, byWeekday, endDate } =
    triggerSchedulerOptions;
  const startDateDayjs = dayjs(startDate);
  const endDateDayjs = endDate ? dayjs(endDate) : dayjs(startDate);
  const getHoursBetweenDates = () =>
    dayjs.duration(endDateDayjs.diff(startDateDayjs)).asHours();

  const executeOnce =
    frequency === WorkflowFrequency.Daily &&
    endDate &&
    getHoursBetweenDates() < 24;

  return {
    startDate: startDateDayjs.format(dateFormat.shortDate),
    executionTime: startDateDayjs.format(dateFormat.hourMinute),
    repeat: !executeOnce,
    frequency,
    interval: interval ?? 1,
    weekdays: generateNumericArray(7).map((_, index) => ({
      selected: byWeekday?.includes(index) ?? false,
    })),
    endDate: endDate ? endDateDayjs.format(dateFormat.shortDate) : "",
  };
}
