/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { CurrencyLSKeys, kantorQuery } from "~/context/CustomerConfig/utils";
import { Currency } from "./currencies";

export async function getExchangeRate(
  base: Currency,
  quote: Currency,
  defaultCurrency = Currency.Eur
): Promise<number> {
  let baseRate = { exchangeRate: 1 };
  let quoteRate = { exchangeRate: 1 };

  if (base === defaultCurrency && quote !== defaultCurrency) {
    try {
      quoteRate = await kantorQuery(quote);

      return 1 / Number(quoteRate.exchangeRate);
    } catch (error) {
      throw new Error(error);
    }
  }

  if (base !== defaultCurrency && quote === defaultCurrency) {
    try {
      baseRate = await kantorQuery(base);

      return Number(baseRate.exchangeRate);
    } catch (error) {
      throw new Error(error);
    }
  }

  if (base === defaultCurrency && quote === defaultCurrency) {
    return 1;
  }

  try {
    [baseRate, quoteRate] = await Promise.all([
      kantorQuery(base),
      kantorQuery(quote),
    ]);

    const exchangeRate = Number(quoteRate.exchangeRate);

    if (exchangeRate === 0) {
      throw new Error("Cannot get exchange rate, quoteRate returned 0");
    }

    return Number(baseRate.exchangeRate) * (1 / exchangeRate);
  } catch (error) {
    throw new Error(error);
  }
}

export const applyRate = (
  val: number,
  currency: string,
  defaultCurrency = Currency.Eur
) => {
  if (currency === defaultCurrency) {
    return Number(val);
  } else {
    const { rate: cachedRate } = JSON.parse(
      localStorage.getItem(`${CurrencyLSKeys.RATE}${currency}`) ?? "{}"
    );
    const rate = parseFloat(cachedRate || "1");

    return Number((Math.round(val * rate * 100) / 100).toFixed(2));
  }
};
