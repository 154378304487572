/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  AwsBillingDataDocument,
  AwsBillingDataQuery,
  AwsBillingDataQueryVariables,
  useUpdateAwsBillingConfigV2Mutation,
} from "~/generated/graphql";
import { ERROR_TEXT } from "~/constants";
import { isNotNil } from "~/tools";
import { FormData } from "../validators";

export function useUpdateAwsBillingData() {
  const [updateBillingDataItem, { ...mutationState }] =
    useUpdateAwsBillingConfigV2Mutation();

  const updateAwsBillingData = async (data: FormData) => {
    const response = await updateBillingDataItem({
      variables: { input: data },
    });
    const updatedItem = response.data?.updateAwsBillingConfigV2;

    if (isNotNil(updatedItem)) {
      mutationState.client.cache.updateQuery<
        AwsBillingDataQuery,
        AwsBillingDataQueryVariables
      >(
        {
          query: AwsBillingDataDocument,
        },
        (billingData) => ({
          awsBillingData: (billingData?.awsBillingData ?? []).map((item) =>
            item?.accountId === updatedItem.accountId ? updatedItem : item
          ),
        })
      );

      return;
    }

    throw new Error(ERROR_TEXT.default);
  };

  return [updateAwsBillingData, mutationState] as const;
}
