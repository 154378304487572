/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer, Tag, Text, theme } from "@nordcloud/gnui";

type ResourceTagsHeaderProps = {
  title: string;
  count: number;
};

export function CountHeader({ title, count }: ResourceTagsHeaderProps) {
  return (
    <FlexContainer
      columnGap={theme.spacing.spacing02}
      alignItems="center"
      justifyContent="space-between"
      as="span"
    >
      <Text mb={0} color={theme.color.text.text01} weight="medium">
        {title}
      </Text>
      <Tag text={count.toString()} css={{ marginBottom: 0 }} />
    </FlexContainer>
  );
}
