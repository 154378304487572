/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Provider } from "~/generated/graphql";
import { ResourceLifecycleDates } from "~/tools";
import { mapMetadata, Metadata } from "../../components";
import { useEstateNidsByProviderDetails } from "../../hooks/useEstateNidsByProviderDetails";
import { useResourceLifeSpanDates } from "../../hooks/useResouceLifeSpanDates";
import { MappedSecurityGroupMetadata } from "../../types";

export function useEc2SecurityGroupDetails(
  metadata: Metadata[],
  resourceLifecycleDates?: ResourceLifecycleDates
) {
  const mapped = mapMetadata<MappedSecurityGroupMetadata>(metadata);

  const { firstSeenDate, lastSeenDate } = useResourceLifeSpanDates({
    lastUpdateDate: mapped.lastUpdateDate,
    resourceLifecycleDates,
  });

  const vpcId = mapped.vpcId ?? "";

  const providerIdToNid = useEstateNidsByProviderDetails(Provider.Aws, [
    { providerId: vpcId, typeSubtype: "ec2/vpc" },
  ]);

  return {
    vpcId,
    vpcNid: providerIdToNid[vpcId] ?? "",
    groupName: mapped.groupName ?? "",
    groupId: mapped.groupId ?? "",
    description: mapped.description ?? "",
    owner: mapped.ownerId ?? "",
    firstSeen: firstSeenDate,
    lastSeen: lastSeenDate,
  };
}
