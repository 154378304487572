/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Input, Label } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { useDisclosure } from "~/hooks";
import { AppsEnvsSidebar } from "./AppsEnvsSidebar";
import { AppsEnvsFieldState, useAppsEnvsField } from "./useAppsEnvsField";

const title = "Select Applications and Environments";

type Props = {
  disabled: boolean;
  onChange: (state: AppsEnvsFieldState) => void;
  state?: AppsEnvsFieldState;
  nodeBehavior?: "independent" | "linkedToParent";
  compressState?: boolean;
  displayTags: boolean;
};

export function AppsEnvsField({
  disabled,
  onChange,
  state,
  nodeBehavior,
  compressState,
  displayTags,
}: Props) {
  const { isOpen, open, close } = useDisclosure();
  const {
    nodes,
    loading,
    error,
    count,
    page,
    pageSize,
    setPage,
    handleSearchChange,
    handleCheck,
    innerState,
    buttonText,
    inputText,
    handleSelectAll,
    handleDeselectAll,
    handleClose,
  } = useAppsEnvsField({
    outerState: state,
    fetchAllIds: !disabled,
    nodeBehavior,
    compressState,
    onClose: close,
    displayTags,
  });

  const handleSave = () => {
    onChange(innerState);
    handleClose();
  };

  return (
    <FormGroup>
      <Label
        name={title}
        htmlFor="appsEnvsField"
        css={{ textTransform: "none" }}
      />
      <Input
        readOnly
        title={title}
        value={inputText}
        placeholder={title}
        icon="sidebar"
        css={{ cursor: "pointer" }}
        id="appsEnvsField"
        onClick={open}
      />
      <AppsEnvsSidebar
        isOpen={isOpen}
        title={title}
        disabled={disabled}
        nodes={nodes}
        loading={loading}
        error={error}
        count={count}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        saveButtonText={buttonText}
        onSearchChange={handleSearchChange}
        onCheck={handleCheck}
        onSave={handleSave}
        onSelectAll={handleSelectAll}
        onClear={handleDeselectAll}
        onClose={handleClose}
      />
    </FormGroup>
  );
}
