/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Text } from "@nordcloud/gnui";
import { TableRow } from "../styles";

export function TableRowHeader() {
  return (
    <TableRow>
      <Text size="sm" weight="bold" width="40%" tag="span">
        Key
      </Text>
      <Text size="sm" weight="bold" width="60%" tag="span">
        Value
      </Text>
    </TableRow>
  );
}
