/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ChangeEvent } from "react";
import { ApolloError } from "@apollo/client";
import { When } from "react-if";
import styled from "styled-components";
import {
  Button,
  ExtendedPaginationBox,
  FlexContainer,
  Sidebar,
  Text,
  Input,
  theme,
  ListLoader,
} from "@nordcloud/gnui";
import {
  DataDisplayWrapper,
  UniversalWrap,
  TreeEvent,
  TreeActionBox,
  TreeSelector,
  Node,
} from "~/components";
import { isNotEmpty, noop } from "~/tools";

type Props = {
  isOpen: boolean;
  title: string;
  disabled: boolean;
  nodes: Node[];
  loading: boolean;
  error?: ApolloError;
  count: number;
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
  onSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onCheck: (treeEvent: TreeEvent) => void;
  onSave: () => void;
  onSelectAll: () => void;
  onClear: () => void;
  saveButtonText: string;
  onClose: () => void;
  disableExpanding?: boolean;
};

export function AppsEnvsSidebar({
  isOpen,
  title,
  disabled,
  nodes,
  loading,
  error,
  count,
  page,
  pageSize,
  setPage,
  onSearchChange,
  onCheck,
  onSave,
  saveButtonText,
  onSelectAll,
  onClear,
  onClose,
  disableExpanding,
}: Props) {
  const treeActionBoxTitle = `Applications ${
    disableExpanding ? "" : "and Environments"
  }`;

  return (
    <Sidebar
      isOpen={isOpen}
      title={title}
      contentStyles={{ padding: "0" }}
      width="35rem"
      headerStyles={{ zIndex: 2 }}
      footer={
        <Footer
          count={count}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          disabled={disabled}
          buttonText={saveButtonText}
          onSave={onSave}
          onClear={onClear}
        />
      }
      onClick={onClose}
    >
      <SidebarContentWrapper>
        <InputWrapper>
          <Input
            type="text"
            placeholder="Search Applications"
            pl={theme.spacing.spacing04}
            onChange={onSearchChange}
          />
        </InputWrapper>
        <TreeActionBox
          title={treeActionBoxTitle}
          disabled={disabled || loading}
          onSelectAll={onSelectAll}
        />
        <UniversalWrap
          loaderProps={{
            loading,
            Component: ListLoader,
          }}
          errorProps={{
            error,
          }}
        >
          <DataDisplayWrapper
            hasData={isNotEmpty(nodes)}
            messageComponent="No Applications Found"
          >
            <TreeSelector
              data={nodes}
              disabled={disabled}
              disableExpanding={disableExpanding}
              onCheck={onCheck}
            />
          </DataDisplayWrapper>
        </UniversalWrap>
      </SidebarContentWrapper>
    </Sidebar>
  );
}

const SidebarContentWrapper = styled.div`
  padding: 0 ${theme.spacing.spacing04};
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: ${theme.spacing.spacing02};
`;

type FooterProps = {
  disabled: boolean;
  count: number;
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
  onSave: () => void;
  onClear: () => void;
  buttonText: string;
};

function Footer({
  disabled,
  count,
  page,
  pageSize,
  setPage,
  onSave,
  onClear,
  buttonText,
}: FooterProps) {
  return (
    <div css={{ width: "100%" }}>
      <ExtendedPaginationBox
        small
        sidebar
        count={count}
        from={page * pageSize}
        setSize={noop}
        size={pageSize}
        setPage={(newPage) => {
          setPage(newPage / pageSize);
        }}
      />
      <When condition={!disabled}>
        <FlexContainer
          direction="column"
          alignItems="flex-start"
          p={theme.spacing.spacing04}
          css={{
            borderTop: `solid 1px ${theme.color.border.border01}`,
          }}
        >
          <Text mb={theme.spacing.spacing02} size="sm">
            {buttonText}
          </Text>
          <FlexContainer columnGap={theme.spacing.spacing04}>
            <Button px={theme.spacing.spacing06} type="button" onClick={onSave}>
              Apply
            </Button>
            <Button severity="medium" type="button" onClick={onClear}>
              Clear
            </Button>
          </FlexContainer>
        </FlexContainer>
      </When>
    </div>
  );
}
