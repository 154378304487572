/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Case, Switch } from "react-if";
import { ProviderTab } from "../../types";
import { AwsUpdateCredentialsForm } from "./AwsUpdateCredentialsForm";
import { AzureUpdateCredentialsForm } from "./AzureUpdateCredentialsForm";
import { GcpUpdateCredentialsForm } from "./GcpUpdateCredentialsForm";
import { IbmUpdateCredentialsForm } from "./IbmUpdateCredentialsForm";

type Props = {
  provider: ProviderTab;
  nids: string[];
  onClose: () => void;
};

export function FormSwitch({ onClose, nids, provider }: Props) {
  return (
    <Switch>
      <Case condition={provider === ProviderTab.AWS}>
        <AwsUpdateCredentialsForm nids={nids} onClose={onClose} />
      </Case>
      <Case condition={provider === ProviderTab.AZURE}>
        <AzureUpdateCredentialsForm nids={nids} onClose={onClose} />
      </Case>
      <Case condition={provider === ProviderTab.GCP}>
        <GcpUpdateCredentialsForm nids={nids} onClose={onClose} />
      </Case>
      <Case condition={provider === ProviderTab.IBMCLOUD}>
        <IbmUpdateCredentialsForm nids={nids} onClose={onClose} />
      </Case>
    </Switch>
  );
}
