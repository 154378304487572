/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { When } from "react-if";
import {
  Button,
  Select,
  Spinner,
  SVGIcon,
  Text,
  theme,
  FlexContainer,
} from "@nordcloud/gnui";
import { EnvironmentType } from "~/generated/graphql";
import { EditButton } from "~/components";
import { useBillingPeriod } from "~/services/customers";
import { ENV_TYPES } from "./EnvTypeSelect.config";

type Props = {
  onChange: (envType: EnvironmentType | null) => void;
  loading: boolean;
  type?: EnvironmentType;
};

export function EnvTypeSelect({ type, onChange, loading }: Props) {
  const [editing, setEditing] = React.useState(false);
  const [env, setEnv] = React.useState<EnvironmentType | null>(type ?? null);
  const [invoked, setInvoked] = React.useState(false);

  const envConfig = ENV_TYPES.find(({ value }) => value === env);

  const { isCurrentBillingPeriod } = useBillingPeriod();

  React.useEffect(() => {
    if (editing && invoked && !loading) {
      setEditing(false);
      setInvoked(false);
    }
  }, [editing, invoked, loading]);

  if (!editing) {
    return (
      <FlexContainer columnGap={theme.spacing.spacing02}>
        <SVGIcon size="smd" name={envConfig?.icon ?? "danger"} />
        <Text tag="span" css={{ textTransform: "capitalize" }}>
          {type?.toLowerCase()}
        </Text>
        <When condition={isCurrentBillingPeriod}>
          <EditButton onClick={() => setEditing(true)} />
        </When>
      </FlexContainer>
    );
  }

  if (loading) {
    return (
      <FlexContainer columnGap={theme.spacing.spacing04}>
        <Spinner size="sm" />
        <Text weight="regular" tag="div">
          Loading...
        </Text>
      </FlexContainer>
    );
  }

  return (
    <FlexContainer columnGap={theme.spacing.spacing02}>
      <Select
        isSearchable={false}
        options={ENV_TYPES}
        defaultValue={envConfig}
        formatOptionLabel={EnvTypeOption}
        styles={{
          control: (base) => ({
            ...base,
            width: "12rem",
          }),
        }}
        onChange={(opt) => setEnv(opt?.value ?? null)}
      />
      <FlexContainer columnGap={theme.spacing.spacing01}>
        <Button
          icon="save"
          size="md"
          severity="low"
          onClick={() => {
            setInvoked(true);
            onChange(env);
          }}
        >
          Save
        </Button>
        <Button
          icon="close"
          size="md"
          severity="low"
          onClick={() => setEditing(false)}
        >
          Cancel
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
}

type EnvTypeOptionProps = (typeof ENV_TYPES)[number];

function EnvTypeOption({ icon, label }: EnvTypeOptionProps) {
  return (
    <FlexContainer columnGap={theme.spacing.spacing02}>
      <SVGIcon size="smd" name={icon} />
      <Text tag="span">{label}</Text>
    </FlexContainer>
  );
}
