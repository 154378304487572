/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { noop } from "~/tools";
import { RenderComposition } from "./RenderComposition";
import { TreeWrap } from "./styled";
import { CheckboxTreeProps } from "./types";

export function CheckboxTree({
  composition,
  expanded,
  selected,
  onChange = noop,
  onExpand = noop,
  separator = "->",
  disabled,
}: CheckboxTreeProps) {
  return (
    <TreeWrap disabled={disabled}>
      {composition.map((comp) => (
        <RenderComposition
          key={comp.uid}
          selectedList={selected ?? []}
          expandedList={expanded ?? []}
          setSelectedList={onChange}
          setExpandedList={onExpand}
          separator={separator}
          {...comp}
        />
      ))}
    </TreeWrap>
  );
}
